import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { appear } from 'src/utils/animationstyles';
import { Button, CircularProgress, Link, Stack, styled, Typography, useTheme } from '@mui/material';
import LogoHeader from 'src/components/utils/LogoSign';
import { RootState } from 'src/redux/store';
import { connect, useDispatch } from 'react-redux';
import { nav } from 'src/statics/navigations';
import { useNavigate } from 'react-router';
import { logoutUser } from 'src/redux/thunks/user.thunk';

const AnimatedTypography = styled(Typography)(
    ({ theme }) => `
        position: absolute;
        opacity: 1;
        transition: 0.6s ease-in-out 0.6s;
        line-height: 1;
        &.hide {
            transition-delay: 0s;
            opacity: 0;
            transform: translateY(-60%);
        }
    `
);

interface Props {
    fullRegistrationException?: boolean;
    loadingStep?: number;
}

const FullRegistrationLoadingPage: FC<Props> = ({ fullRegistrationException, loadingStep }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToLogin = () => {
        dispatch(logoutUser());
        navigate(nav.BASE.sub.INIT.path);
    };

    return (
        <Stack justifyContent="center" alignItems="center" position="fixed" left={0} right={0} top={0} bottom={0} bgcolor={theme.palette.background.default} zIndex={2000}>
            {appear(
                <Stack spacing={4} justifyContent="center" alignItems="center">
                    <LogoHeader />
                    {fullRegistrationException ? (
                        <Stack width="60rem" maxWidth="100%">
                            <Typography mb={0.5} variant="h4" textAlign="center" fontSize={18}>
                                {t('unexpectedErrorHasOccurred')}
                            </Typography>
                            <Button variant="text" sx={{ fontSize: 15, margin: 'auto' }} onClick={goToLogin}>
                                {t('goToLogin')}
                            </Button>

                            <Typography mt={5} mb={1} variant="h4" fontSize={14}>
                                {t('pleaseCopyTheError')}
                            </Typography>
                            <Typography component="pre" whiteSpace="pre-wrap" sx={{ wordBreak: 'break-word', fontSize: 13, background: 'white', p: 1, maxHeight: '50vh', overflow: 'auto' }}>
                                {JSON.stringify(fullRegistrationException, null, 4)}
                            </Typography>
                        </Stack>
                    ) : (
                        <>
                            <CircularProgress />
                            <Stack position="relative" alignItems="center">
                                <AnimatedTypography variant="h4" className={loadingStep !== 0 && 'hide'} noWrap>
                                    {t('fullRegistrationLoadingText1')}
                                </AnimatedTypography>
                                <AnimatedTypography variant="h4" className={loadingStep !== 1 && 'hide'} noWrap>
                                    {t('fullRegistrationLoadingText2')}
                                </AnimatedTypography>
                                <AnimatedTypography variant="h4" className={loadingStep !== 2 && 'hide'} noWrap>
                                    {t('fullRegistrationLoadingText3')}
                                </AnimatedTypography>
                                <AnimatedTypography variant="h4" className={loadingStep !== 3 && 'hide'} noWrap>
                                    {t('fullRegistrationLoadingText4')}
                                </AnimatedTypography>
                            </Stack>
                            <Typography variant="subtitle2">{t('thisOperationMayTakeAWhile')}</Typography>
                        </>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        fullRegistrationException: state.user.fullRegistrationException,
        loadingStep: state.user.fullRegistrationLoadingStep
    };
};
export default connect(mapStateToProps)(FullRegistrationLoadingPage);
