import { AccordionDetails, Box, Button, Divider, IconButton, Popover, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetObjectMetadataResource } from 'src/backend/internalCalc';
import store, { RootState } from 'src/redux/store';
import ItemFragment from './ItemFragment';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ItemSelector from '../ItemSelector/ItemSelector';
import { CalculationFragmentResource } from 'src/backend/internalCalc/models/CalculationFragmentResource';
import { connect } from 'react-redux';
import { SidewaysConnector } from '../../ProCalc.styles';
import { TMP_LOADER_FRAGMENT } from 'src/statics/statics';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        overflow: visible;
        margin-left: 12rem;

        ${theme.breakpoints.down('xl')} {
            margin-left: 6rem;
        }
    `
);

const Header = styled(Box)(
    ({ theme }) => `
        border-left: 1px solid ${theme.colors.alpha.black[10]};
        position: relative;
        align-items: center;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
    `
);

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => `
        margin: 0;
        .MuiAccordionDetails-root {
            padding: 0;
        }
    `
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ExpandMoreTwoToneIcon />} {...props} />)(
    ({ theme }) => `
        min-height: unset;
        padding: 0;
        padding-right: ${theme.spacing(2)};
        & .MuiAccordionSummary-expandIconWrapper.Mui-expanded: {
            transform: rotate(180deg),
        }
        & .MuiAccordionSummary-content {
            margin: 0;
        }
    `
);

interface Props {
    partId?: number;
    fragments?: Array<CalculationFragmentResource>;
    metadata?: Array<SetObjectMetadataResource>;
}

const ItemFragments: React.FC<Props> = ({ partId, fragments, metadata }) => {
    const theme = useTheme();
    const s = store.getState();
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const popoverRef = useRef<any>(null);
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleOpen = () => {
        setOpen(true);

        setTimeout(() => {
            if (popoverRef.current) popoverRef.current.updatePosition();
        }, 1);
    };
    return (
        <>
            {fragments?.length > 0 ? (
                <StyledStack>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Header>
                                <SidewaysConnector />
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="h4">{t('fragments')}</Typography>
                                    <Typography variant="subtitle2" ml={0.6}>
                                        ({fragments.length})
                                    </Typography>
                                </Stack>
                            </Header>
                        </AccordionSummary>
                        <AccordionDetails>
                            {fragments.map((fragment, index) => (
                                <Stack key={fragment.id + index}>
                                    <Divider />
                                    <ItemFragment fragment={fragment} partId={partId} metadata={metadata} last={index === fragments.length - 1} />
                                </Stack>
                            ))}
                            <Divider />
                            <Stack alignItems="center" justifyContent="center">
                                <Button sx={{ px: 1, py: 0.5 }} startIcon={<AddTwoToneIcon />} onClick={handleOpen} ref={ref}>
                                    {t('addFragment')}
                                </Button>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </StyledStack>
            ) : (
                <Stack alignItems="center" justifyContent="center">
                    <Button sx={{ px: 1, py: 0.5 }} startIcon={<AddTwoToneIcon />} onClick={handleOpen} ref={ref}>
                        {t('addFragment')}
                    </Button>
                </Stack>
            )}
            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                action={popoverRef}
            >
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500], zIndex: 105 }}>
                    <CloseTwoToneIcon />
                </IconButton>
                <ItemSelector maxWidth="75rem" partId={partId} closeOnLoad={() => setOpen(false)} />
            </Popover>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {};
};
export default connect(mapStateToProps)(ItemFragments);
