import {
    useTheme} from '@mui/material';

import { useTranslation } from 'react-i18next';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import StatisticCard from './StatisticCard';
import {useState} from "react";



interface Props {
    value: string | number;
    monthlyIncrease: number;
}

const InternalCalcsAmount: React.FC<Props> = ({ value, monthlyIncrease }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();


    return (
        <StatisticCard title={t('calculationsMade')} value={value + ''} monthlyIncrease={monthlyIncrease} icon={<FileUploadTwoToneIcon />} />
    );
}

export default InternalCalcsAmount;
