import { Box, IconButton, InputAdornment, Popover, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdditionalStorageResource, CalculationConfigurationParameterResource, EnumerationCalcParamResource } from 'src/backend/internalCalc';
import SettingsSuggestTwoToneIcon from '@mui/icons-material/SettingsSuggestTwoTone';
import { COLOR_SYSTEMS_GUI } from 'src/statics/statics';
import InfoLabel from './InfoLabel';
import { wT, wTf } from 'src/utils/wizardTranslations';
import store, { useDispatch } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { getHex } from 'src/utils/RalConverter';
import { measureInputValueWidth } from 'src/utils/CalcHelpers';
import { StyledAutocomplete } from '../CalcInputs.styles';
import { setDisablePartValidation } from 'src/redux/slices/proCalc.reducer';
import ExtractedIndicator from './ExtractedIndicator';

interface Props {
    group: CalculationConfigurationParameterResource[];
    isSmall?: boolean;
    disabled?: boolean;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const ColorParameter: React.FC<Props> = ({ group, isSmall, disabled, additionalStorage }) => {
    const { values, setFieldValue, errors, submitForm } = useFormikContext();
    const dispatch = useDispatch();
    const s = store.getState();
    const buttonRef = useRef<any>(null);
    const inputRef = useRef<any>(null);
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [left, setLeft] = useState<number>(0);

    const colorSystemParam = useMemo(() => {
        return group.find((param) => param.name.startsWith(COLOR_SYSTEMS_GUI));
    }, [group]) as EnumerationCalcParamResource;

    const selectedColorParam = useMemo(() => {
        return group.find((param) => param.name === values[colorSystemParam?.name]);
    }, [group, values]);

    useEffect(() => {
        if (inputRef.current) setLeft(measureInputValueWidth(inputRef.current));
    }, [inputRef.current, values[selectedColorParam.name]]);

    const getName = (id: string) => {
        if (id === 'noSelection') {
            return wTf('noSelection', s, t(id));
        }
        return wTf(id, s, t(id));
    };

    const changeColorSystem = (event, value) => {
        if (!value) return;
        dispatch(setDisablePartValidation(true));
        setFieldValue(colorSystemParam.name, value).then((errors) => {
            const oldColorSystemValue = values[colorSystemParam.name];
            const hasError = !!errors[oldColorSystemValue];

            if (hasError) {
                // ignore error and save despite error
                submitForm();
            }
            dispatch(setDisablePartValidation(false));
        });
    };

    const renderDefault = (value, shownInTextField = true) => {
        const isDefault = value === ((selectedColorParam as any).defaultEnumerationItemId || (selectedColorParam as any).defaultValue);
        if (!isDefault) return <></>;

        return (
            <span
                className="default-indicator"
                style={{
                    position: shownInTextField ? 'absolute' : 'unset',
                    left,
                    marginTop: 3,
                    marginLeft: shownInTextField ? 0 : -4,
                    opacity: 0.5,
                    fontSize: 12,
                    pointerEvents: 'none'
                }}
            >
                (Default)
            </span>
        );
    };

    const renderColorSettings = () => {
        return (
            <InputAdornment position="end">
                {renderDefault(values[selectedColorParam.name])}
                <IconButton onClick={() => setOpen(true)} ref={buttonRef} disabled={disabled} sx={{ padding: '6px' }}>
                    <SettingsSuggestTwoToneIcon />
                </IconButton>
                <Popover
                    anchorEl={buttonRef.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Stack p={3} pt={2} spacing={1}>
                        <Typography variant="h3">{wT(colorSystemParam.name, s)}</Typography>
                        <ToggleButtonGroup value={values[colorSystemParam.name]} onChange={changeColorSystem} exclusive disabled={disabled}>
                            {colorSystemParam.items.map((item) => (
                                <ToggleButton key={item.id} value={item.id}>
                                    {getName(item.id)}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Stack>
                </Popover>
            </InputAdornment>
        );
    };
    const renderColorDrop = (ral_color: string, hideIfNotPresent?: boolean) => {
        const color = getHex(ral_color);
        if (color) {
            return (
                <Box
                    width={20}
                    height={20}
                    sx={(theme) => ({
                        borderRadius: 10,
                        backgroundColor: color,
                        border: `2px solid ${theme.palette.grey['300']}`
                    })}
                />
            );
        } else {
            if (hideIfNotPresent) return <></>;
            return <Typography>RAL{ral_color}: </Typography>;
        }
    };

    const getOptions = () => {
        if (selectedColorParam.type !== 'enumeration') return [];
        const items = (selectedColorParam as EnumerationCalcParamResource).items;
        return items.map((item) => item.name);
    };

    const changeColorParam = (event, value) => {
        setFieldValue(selectedColorParam.name, value);
    };
    const handleTextFieldChange = (event) => {
        if (selectedColorParam.type === 'enumeration') return;
        changeColorParam(null, event?.target?.value);
    };
    const handleTextFieldBlur = (event) => {
        if (selectedColorParam.type !== 'enumeration') return;
        const colorValue = event.target.value;

        if (getOptions().includes(colorValue) && colorValue != values[selectedColorParam.name]) {
            changeColorParam(null, colorValue);
        }
    };

    //endregion

    return (
        colorSystemParam &&
        selectedColorParam && (
            <StyledAutocomplete
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={
                            <InfoLabel
                                label={group[0].guiDescriptor}
                                labelSuffix={values[colorSystemParam.name]}
                                guiDescriptor={group[0].guiDescriptor}
                                guiDescriptorExplanation={group[0].explanationTranslationKey}
                            />
                        }
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <ExtractedIndicator param={selectedColorParam} additionalStorage={additionalStorage} />
                                    {renderColorDrop(values[selectedColorParam.name], true)}
                                </>
                            ),
                            endAdornment: renderColorSettings()
                        }}
                        inputRef={inputRef}
                        error={Boolean(errors[selectedColorParam.name])}
                        helperText={errors[selectedColorParam.name]}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextFieldChange}
                        onBlur={handleTextFieldBlur}
                    />
                )}
                noOptionsText={t('noOptions')}
                forcePopupIcon={false}
                disableClearable
                freeSolo={selectedColorParam.type !== 'enumeration'}
                value={values[selectedColorParam.name]}
                renderOption={(props, option: string, state) => (
                    <li {...props}>
                        <Stack direction={'row'} gap={1}>
                            {renderColorDrop(option, true)}
                            {option}
                            {renderDefault(option, false)}
                        </Stack>
                    </li>
                )}
                onChange={changeColorParam}
                options={getOptions()}
                size={isSmall ? 'small' : 'medium'}
                disabled={disabled}
            />
        )
    );
};
export default ColorParameter;
