import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'src/components/display/PageHeader';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { nav } from 'src/statics/navigations';
import CalculationStatus from 'src/components/display/CalculationStatus/CalculationStatus';
import { Summary } from 'src/components/display/Summary/Summary';
import { SummaryResource, SummaryRestControllerService } from 'src/backend/market';
import useDialog from 'src/hooks/useDialog';

const CalculationSummary: React.FC = ({}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const dialog = useDialog();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [internalCalcSummary, setInternalCalcSummary] = useState<SummaryResource>(null);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!id) return;
        setLoading(true);
        SummaryRestControllerService.getInternalCalculationSummary(id)
            .then((summary) => {
                setInternalCalcSummary(summary);
                setLoading(false);
            })
            .catch((error) => {
                const errorMessage = error.body?.message || '';
                if (errorMessage.includes('InternalCalculationNotFoundException')) {
                    dialog.openDialog({
                        severity: 'error',
                        okText: t('toCalcOverview'),
                        showCancel: false,
                        disableClickAway: true,
                        component: <Typography>{t('calcNotFoundExplanation')}</Typography>,
                        title: t('calcNotFoundTitle'),
                        okCallback: () => {
                            dialog.closeDialog();
                            navigate(nav.CALCULATIONS.path);
                        }
                    });
                } else if (errorMessage.startsWith('[403] during [GET]')) {
                    dialog.openDialog({
                        severity: 'error',
                        okText: t('toCalcOverview'),
                        showCancel: false,
                        disableClickAway: true,
                        component: <Typography>{t('notAllowedCalcErrorExplanation', { id })}</Typography>,
                        title: t('notAllowedCalcErrorTitle'),
                        okCallback: () => {
                            dialog.closeDialog();
                            navigate(nav.CALCULATIONS.path);
                        }
                    });
                } else {
                    dialog.openDialog({
                        severity: 'error',
                        okText: t('toCalcOverview'),
                        showCancel: false,
                        disableClickAway: true,
                        component: <Typography>{t('unexpectedCalcErrorExplanation', { id })}</Typography>,
                        title: t('unexpectedCalcErrorTitle'),
                        okCallback: () => {
                            dialog.closeDialog();
                            navigate(nav.CALCULATIONS.path);
                        }
                    });
                }
            });
    }, []);

    const handleBack = () => {
        navigate(nav.CALCULATIONS.path);
    };

    const renderTitle = () => {
        const title = t(nav.CALCULATIONS.sub.VIEW.caption, { id });
        if (!internalCalcSummary) return title;

        return (
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                sx={{
                    '& > div': {
                        xs: {
                            'margin-left': -4
                        },
                        md: {
                            'margin-left': 18
                        }
                    }
                }}
            >
                <span style={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{title}</span>
                <CalculationStatus status={internalCalcSummary.status} importedWizardId={internalCalcSummary.calculationFromImportedWizardId} />
            </Stack>
        );
    };

    const handleEditCalc = () => {
        navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', id));
    };

    return (
        <Stack flexGrow={1}>
            <PageHeader sx={{ mb: 2, displayPrint: 'none' }} handleBack={handleBack} mainTitle={renderTitle()} secondaryTitle={t('details')} tabTitle={t(nav.CALCULATIONS.sub.VIEW.tabTitle, { id })}>
                {/* <Button variant="text" startIcon={<AutoAwesomeTwoToneIcon/>} onClick={handleEditCalc}>
                {t('insights')}
            </Button> */}
                {isSmallScreen ? (
                    <IconButton onClick={handleEditCalc} color="primary">
                        <EditTwoToneIcon />
                    </IconButton>
                ) : (
                    <Button variant="text" startIcon={<EditTwoToneIcon />} onClick={handleEditCalc}>
                        {t('kalkulationEditieren')}
                    </Button>
                )}
            </PageHeader>
            <Summary summary={internalCalcSummary} isLoading={isLoading} />
        </Stack>
    );
};

export default CalculationSummary;
