import _ from 'lodash';
import { CalculationMessageResource, CalculationParameterResource, CalculationResultResource, GeneralSettingResource } from 'src/backend/market';
import {
    BooleanCalcParamResource,
    ConstantDoubleCalcParamResource,
    ConstantIntegerCalcParamResource,
    DoubleCalcParamResource,
    EnumerationCalcParamResource,
    IntegerCalcParamResource,
    SectionDefinitionResource,
    StringCalcParamResource
} from 'src/backend/internalCalc';
import { GeneralParameterSummaryResource, GuiStatusResource, SummarySectionDefinitionResource } from 'src/backend/market';
import store from 'src/redux/store';
import { COLOR_GUI_IDENTIFIER, COLOR_SYSTEMS_GUI, EXCLUDED_EDITOR_FIELDS } from 'src/statics/statics';
import { wT } from 'src/utils/wizardTranslations';
import { Fragment } from 'react';
import { formatValue } from './FormatHelpers';
import { BooleanValueResource, ListValueResource, StringValueResource, ValueResource, VariableResource, VariableValueResource } from 'src/backend/coreCalc';

export type CalcParamResource =
    | BooleanCalcParamResource
    | ConstantDoubleCalcParamResource
    | ConstantIntegerCalcParamResource
    | DoubleCalcParamResource
    | EnumerationCalcParamResource
    | IntegerCalcParamResource
    | StringCalcParamResource;

export interface ParameterArray extends Array<CalcParamResource> {}

export interface Section {
    id: string;
    groupedParameters: ParameterArray | Array<GeneralParameterSummaryResource>;
    numberOfCells: number;
}

export const transformToCalculationParameter = (params: { [key: string]: any }): CalculationParameterResource[] => {
    const calculationParameters: Array<CalculationParameterResource> = [];
    _.forEach(params, (value, key) => {
        calculationParameters.push({
            name: key,
            value: value + ''
        });
    });
    return calculationParameters;
};

export const displayField = (
    param: CalcParamResource | GeneralParameterSummaryResource,
    value: string,
    guiStates: Array<GuiStatusResource>,
    numberFormat: string,
    renderField: (label: string, value: string, key?: string, unit?: string) => JSX.Element
) => {
    if (value == '') return <></>;
    const s = store.getState();

    const fieldState = guiStates?.find((x) => x.affectedObject === param.name);
    if (fieldState?.status === 'INVISIBLE') return <></>;

    if (param.type === 'boolean' || param.type === 'enumeration') {
        return renderField(wT(param.name, s), wT(value, s));
    }

    if (param.type === 'integer' || param.type === 'double') {
        value = formatValue(value, numberFormat);
    }

    return renderField(wT(param.name, s), value, null, wT((param as IntegerCalcParamResource).unit, s));
};

export const renderParameters = (
    parameters: ParameterArray | Array<GeneralParameterSummaryResource>,
    values: { [name: string]: string } | null,
    guiStates: Array<GuiStatusResource>,
    numberFormat: string,
    renderField: (label: string, value: string, key?: string, unit?: string) => JSX.Element
) => {
    const s = store.getState();

    const params = [...parameters];
    params.sort((a, b) => {
        if (a.sequence === b.sequence) {
            if (!('subSequence' in a) || !('subSequence' in b) || a.subSequence == undefined) return 1;
            return a.subSequence - b.subSequence;
        }
        return a.sequence - b.sequence;
    });

    //Group params per descriptor groups
    let groupedParams = _.groupBy(params, 'guiDescriptor');
    //remove excluded groupsCalcParamResource
    groupedParams = _.omitBy(groupedParams, (group, key) => EXCLUDED_EDITOR_FIELDS.includes(key));

    return _.map(groupedParams, (group, key) => {
        //if every member is a boolean member, render a multiple choice param
        if (group.every((x) => x.type === 'boolean')) {
            const value = group
                .filter((param) => {
                    const booleanValue = 'value' in param ? param.value : values[param.name];
                    return booleanValue === 'true';
                })
                .map((param) => wT(param.name, s))
                .join(', ');

            return renderField(wT(key, s), value, key);
        }

        if (key === COLOR_GUI_IDENTIFIER) {
            let colorSystem, colorValue;
            if (values) {
                colorSystem = values[COLOR_SYSTEMS_GUI];
                colorValue = values[colorSystem];
            } else {
                const colorSystemParam = group.find((param) => param.name === COLOR_SYSTEMS_GUI) as GeneralParameterSummaryResource;
                colorSystem = colorSystemParam?.value;
                const colorValueParam = group.find((param) => param.name === colorSystem) as GeneralParameterSummaryResource;
                colorValue = colorValueParam?.value;
            }

            return renderField(wT(key, s) + ' (' + wT(colorSystem, s) + ')', colorValue, key);
        }

        //else render all the params in a single group
        return (
            <Fragment key={key}>
                {group.map((parameter, index) => {
                    const parameterValue = 'value' in parameter ? parameter.value : values[parameter.name];
                    return <Fragment key={key + index}>{displayField(parameter, parameterValue, guiStates, numberFormat, renderField)}</Fragment>;
                })}
            </Fragment>
        );
    });
};

const getNumberOfCells = (guiDescriptor, parameters) => {
    if (guiDescriptor === COLOR_GUI_IDENTIFIER) return 1;
    if (parameters.every((x) => x.type === 'boolean')) return 1;
    return parameters.length;
};

export const getSections = (
    parameters: ParameterArray | Array<GeneralParameterSummaryResource>,
    sectionDefinitions: Array<SectionDefinitionResource> | Array<SummarySectionDefinitionResource>
): Array<Section> => {
    const sectionSortInfos = {};
    (sectionDefinitions || []).forEach((definition) => {
        sectionSortInfos[definition.id ?? definition.sectionDefinitionId] = definition.sequence;
    });
    const parametersClone = _.cloneDeep(parameters) || [];
    //@ts-ignore
    const calcTypeParam = parametersClone.find((param) => param.name === 'kalkulationsart');
    if (calcTypeParam) calcTypeParam.section = 'geometryPackageSection';

    const allParameters = _.sortBy(parametersClone, ['sequence']) as ParameterArray | Array<GeneralParameterSummaryResource>;
    let groupedParameters = _.groupBy(allParameters, 'guiDescriptor') as { [guiDescriptor: string]: ParameterArray | Array<GeneralParameterSummaryResource> };
    groupedParameters = _.omitBy(groupedParameters, (group, key) => EXCLUDED_EDITOR_FIELDS.includes(key));

    const sections: { [sectionId: string]: Section } = Object.entries(groupedParameters).reduce((accumulator, [guiDescriptor, parameters]) => {
        const id = parameters[0].section || 'general';

        if (accumulator[id]) {
            accumulator[id].groupedParameters.push(...parameters);
            accumulator[id].numberOfCells += getNumberOfCells(guiDescriptor, parameters);
        } else accumulator[id] = { id, groupedParameters: [...parameters], numberOfCells: getNumberOfCells(guiDescriptor, parameters) };
        return accumulator;
    }, {});

    const sortedEntries = Object.entries(sections).sort(([idA], [idB]) => {
        return sectionSortInfos[idA] - sectionSortInfos[idB];
    });
    const sortedObject = Object.fromEntries(sortedEntries);
    return Object.values(sortedObject);
};

export const getAllMessages = (costResult: CalculationResultResource) => {
    const messages = {};

    (costResult?.messages || []).forEach((message) => {
        messages[message.messageId] = message;
    });

    (costResult?.subCalculationResults || []).forEach((subCalcResult) => {
        (subCalcResult?.messages || []).forEach((message) => {
            messages[message.messageId] = message;
        });
    });

    return Object.values(messages) as Array<CalculationMessageResource>;
};

export const findGeneralSetting = (settingName: string, generalSettings: Array<GeneralSettingResource> = []) => {
    if (!settingName) return;
    return generalSettings.find((setting) => setting.name === settingName)?.value;
};

export const getGeneralSetting = (settingName: string, costResult: CalculationResultResource, partCostResult: CalculationResultResource = null) => {
    if (!settingName) return;
    if (partCostResult) {
        const generalSettingValue = findGeneralSetting(settingName, partCostResult.generalSettings);
        if (generalSettingValue) return generalSettingValue;
    }
    return findGeneralSetting(settingName, costResult?.generalSettings);
};

export const measureInputValueWidth = (input: HTMLInputElement): number => {
    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.padding = window.getComputedStyle(input, null).getPropertyValue('padding');
    span.style.fontFamily = input.style.fontFamily;
    span.style.fontSize = input.style.fontSize;
    span.style.fontWeight = input.style.fontWeight;
    span.style.fontStyle = input.style.fontStyle;
    span.style.letterSpacing = input.style.letterSpacing;
    span.style.textTransform = input.style.textTransform;
    span.style.boxSizing = input.style.boxSizing;
    span.style.position = 'fixed';
    span.innerText = input.value;
    document.body.appendChild(span);
    const width = span.getBoundingClientRect().width + input.offsetLeft;
    document.body.removeChild(span);
    return width;
};

export const getVariable = (variableName: string, variables: Array<VariableResource>) => {
    return (variables || []).find((variable) => variable.name === variableName);
};

export const getValue = (value: ValueResource, variables: Array<VariableResource> = []) => {
    switch (value.type) {
        case ValueResource.type.VARIABLE_VALUE:
            return getVariable((value as VariableValueResource).variableName, variables)?.name || (value as VariableValueResource).variableName;

        case ValueResource.type.STRING_VALUE:
            return (value as StringValueResource).stringValue;

        case ValueResource.type.LIST_VALUE:
            return (value as ListValueResource).listValue;

        case ValueResource.type.BOOLEAN_VALUE:
            return (value as BooleanValueResource).booleanValue;
    }
};
