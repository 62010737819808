import { Alert, Box, Button, Card, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PageHeader from 'src/components/display/PageHeader';
import { logoutUser } from 'src/redux/thunks/user.thunk';
import AccountDataInputForm from '../../../components/input/AccountDataInput/AccountDataInputForm';
import { useDispatch, useSelector } from '../../../redux/store';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { nav } from 'src/statics/navigations';
import { startWalkthrough } from '../../../redux/thunks/guidethrough.thunk';

function EditProfile() {
    const [successfulSaved, setSuccessfulSaved] = useState(false);
    const user = useSelector((state) => state.user.currentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        dispatch(startWalkthrough('profile'));
    }, []);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    function success(): void {
        setSuccessfulSaved(true);
        window.scrollTo(0, 0);
        setTimeout(() => {
            setSuccessfulSaved(false);
        }, 3000);
    }

    const handleLogout = async (): Promise<void> => {
        try {
            dispatch(logoutUser());
            navigate(nav.BASE.sub.INIT.path);
        } catch (err) {
            console.error(err);
        }
    };

    const renderUserInfo = () => {
        return (
            <Card>
                <Stack
                    direction="row"
                    divider={<Divider sx={{ background: `${theme.colors.alpha.black[10]}` }} orientation="vertical" flexItem />}
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={0}
                >
                    <Box px={3} py={1.4}>
                        <Typography variant="h5" fontWeight="normal" color="text.secondary">
                            {t('emailAddressOrUsername')}
                        </Typography>
                        <Typography color="primary" variant="h4" position="relative">
                            {user.email}
                        </Typography>
                    </Box>
                    <Box px={3} py={1.4}>
                        <Typography variant="h5" fontWeight="normal" color="text.secondary">
                            {t('uidNumber')}
                        </Typography>
                        <Typography color="primary" variant="h4" position="relative">
                            {user.company.uid}
                        </Typography>
                    </Box>
                </Stack>
            </Card>
        );
    };

    return (
        <>
            <PageHeader sx={{ mb: 2 }}>
                {isSmallScreen ? (
                    <Button color="primary" startIcon={<LockOpenTwoToneIcon />} onClick={handleLogout}>
                        {t('SIGN_OUT')}
                    </Button>
                ) : (
                    renderUserInfo()
                )}
            </PageHeader>

            {isSmallScreen && renderUserInfo()}
            {successfulSaved && (
                <Box mt={2}>
                    <Alert severity="success">{t('The data has been saved successfully!')}</Alert>
                </Box>
            )}
            <Box mt={2}>
                <AccountDataInputForm successCallback={success} />
            </Box>
        </>
    );
}

export default EditProfile;
