import { Button, Divider, IconButton, Popover, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InternalCalculationResource } from 'src/backend/internalCalc';
import MasterAdditionalSettings from './MasterAdditionalSettings';
import { BRING_DATE, DELIVERY_DATE, DELIVERY_TIME, UTILIZATION } from 'src/statics/statics';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import MasterUtilization from './MasterUtilization';
import MasterDelivery from './MasterDelivery';
import CalcParameters from 'src/components/input/CalcInputs/CalcParameters';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { RootState } from 'src/redux/store';
import { connect } from 'react-redux';

interface Props {
    calculation?: InternalCalculationResource;
}

const MasterSettings: React.FC<Props> = ({ calculation }) => {
    const { t } = useTranslation();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const allParameters = calculation?.masterCalculationParameters?.allParameters || [];
    const filteredParameters = allParameters.filter((param) => param.name !== UTILIZATION && param.name !== DELIVERY_TIME && param.name !== DELIVERY_DATE && param.name !== BRING_DATE);

    return (
        <>
            <Button ref={ref} color="secondary" startIcon={<SettingsTwoToneIcon />} onClick={() => setOpen(true)}>
                {t('orderSettings')}
            </Button>
            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                <Stack sx={{ p: 3, width: '30rem', maxWidth: '100%' }} id={'masterCalcDetailsCard'}>
                    <Typography variant="h3" textAlign="center">
                        {t('details')}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom textAlign="center" mb={0}>
                        {t('generalCalcSettings')}
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <MasterDelivery calculation={calculation} />

                    <MasterUtilization calculation={calculation} />

                    <Stack pt={1} spacing={3}>
                        <CalcParameters id={calculation.id} parameters={filteredParameters} guiStates={calculation.costResult?.guiStates} disableParameterFilter createdAt={calculation.createdAt} />
                    </Stack>

                    <MasterAdditionalSettings calculation={calculation} />
                </Stack>
            </Popover>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(MasterSettings);
