import { Typography, Box, Avatar, Card, CardContent, styled, alpha, useTheme, Stack, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Link } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import StatisticCard from './StatisticCard';

interface Props {
    value: string | number;
    monthlyIncrease: number;
}

const InquiriesPriceCalcs: React.FC<Props> = ({ value, monthlyIncrease }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();

    return (
        <StatisticCard
            title={t('priceCalculations')}
            value={
                <Box display={'flex'}>
                    <Typography variant="h3" noWrap>
                        {value}
                    </Typography>
                    <Button component={Link} to={nav.WIZARD.sub.PRICE_CALCS.path} variant="text" endIcon={<ArrowForwardTwoToneIcon />} size="small" sx={{ whiteSpace: 'nowrap' }}>
                        {t('viewAll')}
                    </Button>
                </Box>
            }
            monthlyIncrease={monthlyIncrease}
            icon={<CalculateIcon />}
        />
    );
};

export default InquiriesPriceCalcs;
