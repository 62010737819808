import {Card, Modal, Stack, Typography} from "@mui/material";
import React from "react"
import {useTranslation} from "react-i18next";
import {useSelector} from "../../../redux/store";
import SurfLoader from "../../display/Loader/SurfLoader";

const ImportLoaderOverlay: React.FC = () => {
    //region STATE
    const isOpen = useSelector(state => state.inquiries.importLoading)
    const {t} = useTranslation()
    //endregion

    //region LIFECYCLE 
    //endregion

    //region RENDER
    //endregion

    return <Modal open={isOpen}>
        <Stack height={"100vh"} alignItems={"center"} justifyContent={"center"}>
            <Card sx={{p: 3}}>
                <Stack alignItems={"center"} justifyContent={"center"}>
                    <SurfLoader/>
                    <Typography sx={{mt: 1}} variant={"h3"}>{t('anfrageWirdImportiert')}</Typography>
                    <Typography textAlign="center" width={270}
                                variant={"caption"}>{t('dieserVorgangKannJeNachAnzahlDerTeileEtwasDauern')}</Typography>
                </Stack>
            </Card>
        </Stack>
    </Modal>
}
export default ImportLoaderOverlay