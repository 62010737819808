import { CheckCircleTwoTone, WarningTwoTone } from '@mui/icons-material';
import { Button, CircularProgress, Container, Stack, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { OpenAPI, UserManagementRestControllerService } from '../../../backend/market';
import LogoHeader from '../../../components/utils/LogoSign';
import i18n from '../../../i18n/i18n';

enum Status {
    PENDING,
    SUCCESS,
    FAILED
}

const CenterContainer = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
`;

const appear = (children) => {
    return (
        <motion.div animate={{ opacity: [0, 1], y: [-100, 0] }} transition={{ duration: 2, ease: 'anticipate' }}>
            {children}
        </motion.div>
    );
};

const VerifyEmail: React.FC = () => {
    const { verificationId } = useParams();
    const { t } = useTranslation();
    const [status, setStatus] = useState<Status>(Status.PENDING);
    const [message, setMessage] = useState();
    const [lcParams, setLcParams] = useSearchParams(); // language code params

    useEffect(() => {
        i18n.changeLanguage(lcParams.get('lc') || 'en');

        OpenAPI.TOKEN = '';
        UserManagementRestControllerService.verifyUser(verificationId)
            .then(() => {
                setStatus(Status.SUCCESS);
            })
            .catch((e) => {
                setStatus(Status.FAILED);
                setMessage(e.toString() ?? '');
            });
    }, []);

    const handleLoginNav = () => {
        window.location.href = nav.ACCOUNT.sub.LOGIN.path;
    };

    function renderContent() {
        if (status === Status.PENDING) {
            return (
                <Stack direction={'column'} alignItems={'center'} gap={2}>
                    <CircularProgress />
                    <Typography variant={'h4'}>{t('deineEmailAdresseWirdVerifiziert')}</Typography>
                </Stack>
            );
        } else if (status === Status.FAILED) {
            return (
                <Stack direction={'column'} alignItems={'center'} gap={2}>
                    <WarningTwoTone style={{ fontSize: 80 }} color={'error'} />
                    <Typography variant={'h4'}>{t('deineEmailAdresseKonnteNichtVerifiziertWerden')}</Typography>
                    <Button variant={'contained'} onClick={handleLoginNav}>
                        {t('to Login')}
                    </Button>
                </Stack>
            );
        } else if (status === Status.SUCCESS) {
            return (
                <Stack direction={'column'} alignItems={'center'} gap={2}>
                    <CheckCircleTwoTone style={{ fontSize: 80 }} color={'success'} />
                    <Typography variant={'h4'}>{t('deineEmailAdresseWurdeVerifiziert')}</Typography>
                    <Button variant={'contained'} onClick={handleLoginNav}>
                        {t('to Login')}
                    </Button>
                </Stack>
            );
        }
    }

    return (
        <CenterContainer>
            {appear(
                <Stack direction={'column'} spacing={4} alignItems={'center'}>
                    <LogoHeader />
                    {renderContent()}
                </Stack>
            )}
            <Stack direction={'column'} alignItems={'center'} position={'absolute'} bottom={0}>
                <Typography variant={'caption'}>{t('vid', { verificationId: verificationId })}</Typography>

                <Typography variant={'caption'}>{message}</Typography>
            </Stack>
        </CenterContainer>
    );
};
export default VerifyEmail;
