import { slice } from '../slices/templates.reducer';
import { AppDispatch, RootState } from '../store';
import { TemplateRestControllerService } from 'src/backend/coreCalc';

export const loadTemplates = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const templateCollection = getState().templates?.templateCollection;
    if (templateCollection) return templateCollection;
    const response = await TemplateRestControllerService.getTemplateCollection();
    dispatch(slice.actions.setTemplateCollection(response));
};
