//INTERFACE AND INIT STATE
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IWizardStati {
    wholeWizardState: boolean;
    walkInCustomerWizardState: boolean;
    regularCustomerWizardState: boolean;
}

export interface IWizardStatiReducer {
    stati: IWizardStati;
    loading: boolean;
}

const initialState: IWizardStatiReducer = {
    stati: {
        wholeWizardState: false,
        walkInCustomerWizardState: false,
        regularCustomerWizardState: false,
    },
    loading: true,
}

//REDUCERS
const reducers = {
    setAllStati: (state: IWizardStatiReducer, action: PayloadAction<IWizardStati>) => {
        return {
            ...state,
            loading: false,
            stati: action.payload,
        }
    },
    setWholeWizardState: (state: IWizardStatiReducer, action: PayloadAction<boolean>) => {
        return {
            ...state,
            stati: Object.assign({}, state.stati, {wholeWizardState: action.payload})
        }
    },
    setWalkInCustomerWizardState: (state: IWizardStatiReducer, action: PayloadAction<boolean>) => {
        return {
            ...state,
            stati: Object.assign({}, state.stati, {walkInCustomerWizardState: action.payload})
        }
    },
    setRegularCustomerWizardState: (state: IWizardStatiReducer, action: PayloadAction<boolean>) => {
        console.log("set regular customer wizard state", action.payload);
        return {
            ...state,
            stati: Object.assign({}, state.stati, {regularCustomerWizardState: action.payload})
        }
    },
    setLoading: (state: IWizardStatiReducer, action: PayloadAction<boolean>) => {
        return {
            ...state,
            loading: action.payload
        }
    }
}

//EXPORT
export const slice = createSlice({
    name: 'wizardStati',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;