import { DialogContent, DialogTitle, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import store from 'src/redux/store';
import { AiMailConversationResource } from 'src/backend/market';
import AiEmailSingleOutput from './AiEmailSingleOutput';

interface Props {
    mailConversation: AiMailConversationResource;
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    suffix?: string;
}

const AiEmailConversationViewer: React.FC<Props> = ({ mailConversation, isOpen, setOpen, suffix }) => {
    const { t } = useTranslation();
    const s = store.getState();
    const isDraggingRef = useRef<boolean>(false);
    const paperRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    const extractNumberFromPx = (value: string) => {
        const match = value.match(/\d+/);
        return match ? parseInt(match[0]) : 0;
    };

    const handleMouseDown = (e) => {
        isDraggingRef.current = true;

        const div = paperRef.current;
        if (!div) return;

        const offsetX = e.clientX - div.offsetLeft;
        const offsetY = e.clientY - div.offsetTop;

        const handleMouseMove = (e: MouseEvent) => {
            if (isDraggingRef.current) {
                let x = e.clientX - offsetX;
                let y = e.clientY - offsetY;

                // Calculate boundaries
                const minX = div.offsetWidth / 2;
                const minY = div.offsetHeight / 2 + extractNumberFromPx(theme.header.height);
                const maxX = window.innerWidth - div.offsetWidth / 2;
                const maxY = window.innerHeight - div.offsetHeight / 2;

                // Ensure the element stays within the screen boundaries
                x = Math.min(maxX, Math.max(minX, x));
                y = Math.min(maxY, Math.max(minY, y));

                div.style.left = `${x}px`;
                div.style.top = `${y}px`;
            }
        };

        const handleMouseUp = () => {
            isDraggingRef.current = false;
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const visibleMailsAmount = useMemo(() => {
        if (!mailConversation) return 0;
        const visibleMails = mailConversation.conversation.filter((mail) => mail.mailType !== 'OutgoingAiCalculationMail' || mail.sent);
        return visibleMails.length;
    }, [mailConversation]);

    return (
        <>
            {isOpen && mailConversation && (
                <Paper
                    sx={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '35rem',
                        zIndex: 99999,
                        textAlign: 'left',
                        color: theme.colors.alpha.white[100],
                        boxShadow: '0px 9px 16px rgba(159, 162, 191, .25), 0px 2px 2px rgba(159, 162, 191, 0.3352), 0 0 0 4px rgba(34, 51, 84, 0.3), 0 0 0 4px white'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    ref={paperRef}
                >
                    <DialogTitle sx={{ cursor: 'move', userSelect: 'none', background: theme.colors.alpha.black[30], borderTopLeftRadius: 6, borderTopRightRadius: 6 }} onMouseDown={handleMouseDown}>
                        {t('emailConversation')} {suffix}
                        <IconButton
                            aria-label="close"
                            onMouseDown={(event) => event.stopPropagation()}
                            onClick={() => setOpen(false)}
                            sx={{ position: 'absolute', right: 8, top: 6, color: theme.colors.alpha.white[100] }}
                        >
                            <CloseTwoToneIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '35rem', height: '35rem', overflowX: 'hidden' }}>
                        <Typography variant="h3" pt={2.4}>
                            {mailConversation.conversation[0]?.subject}
                        </Typography>
                        <Stack sx={{ my: 2.4 }}>
                            {mailConversation.conversation.map((mail, index) => (
                                <AiEmailSingleOutput key={mail.id} mail={mail} senderName={mailConversation.metadata.senderName} isExpandedDefault={visibleMailsAmount === 1 && index === 0} />
                            ))}
                        </Stack>
                    </DialogContent>
                </Paper>
            )}
        </>
    );
};

export default AiEmailConversationViewer;
