import {Box, Card, styled} from "@mui/material";

export const StyledCard = styled(Card)`
  padding: 30px;

  ${({theme}) => theme.breakpoints.down('md')} {
    padding: 15px;
  }
  
`

export const LoadingImageBox = styled(Box)`
  border-radius: 10px;
  height: 125px;
  width: 125px;
  background-color: ${({theme}) => theme.palette.background.default};
  display: flex;
  align-items: center;
  justify-content: center;
`