import { AdminPanelSettingsTwoTone } from '@mui/icons-material';
import {
    Box,
    Typography,
    useTheme,
    styled,
    Avatar,
    Stack,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { UserResource } from 'src/backend/market';
import { RootState } from 'src/redux/store';
import ExplanationVideo from './ExplanationVideo';
import Weather from './Weather';


const ProfileWrapperBox = styled(Box)(
    ({ theme }) => `
        position: relative;
        display: flex;
        align-item: center;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: ${theme.general.borderRadius};
        
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            // background-image: linear-gradient(180deg, #3d8eff 0%, #1e275a 100%);
            background-image: linear-gradient(0deg, ${theme.colors.primary.main}, ${theme.colors.info.main});
            border-radius: inherit;
            z-index: -2;
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0.15;
            background-image: url("https://www.surface-solutions.pro//wp-content/uploads/2022/04/Powderexplosion.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: inherit;
            z-index: -1;
        }
    `
);

const ProfileWelcomeBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 36px;
        text-align: center;

        ${theme.breakpoints.between('md', 'xl')} {
            flex-direction: row;
            justify-content: center;
            margin-bottom: 0;
            text-align: left;
        }
        ${theme.breakpoints.down('md')} {
            margin-bottom: 18px;
        }
    `
);
const ProfileAvatar = styled(Avatar)(
    ({ theme }) => `
        margin-top: 36px;
        margin-bottom: 14px;
        font-size: 20px;

        ${theme.breakpoints.between('md', 'xl')} {
            margin: ${theme.spacing(0, 2)};
        }
    `
);

const InfoWrapper = styled(Stack)(
    ({ theme }) => `
        padding: ${theme.spacing(2.7)};
        margin: ${theme.spacing(1)};
        margin-top: ${theme.spacing(2)};
        border-radius: ${theme.general.borderRadius};
        background: ${theme.colors.alpha.white[30]};
        color: ${theme.colors.alpha.white[100]};
        backdrop-filter: blur(8px);
    `
);

interface Props {
    user: UserResource;
}

const Profile: React.FC<Props> = ({ user }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();;

    return (
        <ProfileWrapperBox id={"profileWrapperDashboard"} flexDirection={{ xs: 'column', md: 'row', xl: 'column' }} px={2} py={{ xs: 0.4, xl: 2 }}>
            <ProfileWelcomeBox>
                <ProfileAvatar alt={user.fullName} src="/broken-image.jpg" sx={{ width: 68, height: 68 }} />
                <Stack>
                    <Typography variant="subtitle2" style={{fontSize: '20px', color: theme.colors.alpha.white[100]}}>{t('welcome')}</Typography>
                    <Typography variant="h5" style={{fontSize: '20px', color: theme.colors.alpha.white[100]}}>{user.firstName} {user.lastName}</Typography>

                    <Stack direction="row" alignItems="center" gap={0.5} m={{ xs: 'auto', md: 0, xl: 'auto' }}>
                        {user.userRoleResource.roleType === 'ADMIN' &&
                            <Tooltip title={t('administrator')}>
                                <AdminPanelSettingsTwoTone sx={{ color: theme.colors.alpha.white[100] }} fontSize="small"/>
                            </Tooltip>}
                        <Typography variant="subtitle1" sx={{ display: 'flex', color: theme.colors.alpha.white[100]}}>
                            {user.company.name}
                        </Typography>
                    </Stack>
                </Stack>
            </ProfileWelcomeBox>
            <Weather/>
            {/* <Typography variant="h4" style={{fontSize: '20px', padding: '20px 0 5px', textAlign: 'center', color: theme.colors.alpha.white[100]}}>Latest News</Typography>
            <News/> */}
            
            <Stack mt="auto" ml={{ xs: 0, md: 'auto', xl: 0 }} flexGrow={{ xs: 1, md: 0, xl: 1 }} display={{ xs: 'none', xl: 'flex' }} justifyContent="flex-end">
                <InfoWrapper>
                    <ExplanationVideo/>
                </InfoWrapper>
            </Stack>
        </ProfileWrapperBox>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user.currentUser
    }
}
export default connect(mapStateToProps)(Profile);
