import * as yup from 'yup';
import { CalculationParameterResource, GuiStatusResource } from '../backend/market';
import { ALTERNATIVE_GUI, BRING_DATE, COLOR_GUI_IDENTIFIER, COLOR_SYSTEMS_GUI, COLOR_SYSTEMS_RAL, DELIVERY_DATE, QUANTITY } from '../statics/statics';
import { TFunction } from 'i18next';
import {
    CalculationConfigurationParameterResource,
    CalculationPartResource,
    CalculationResultResource,
    DoubleCalcParamResource,
    IntegerCalcParamResource,
    InternalCalculationResource
} from 'src/backend/internalCalc';
import { ParameterArray } from './CalcHelpers';

export class CalcValidator {
    private readonly vString;

    private vNumber;

    private readonly vAny;

    private readonly vBoolean;

    private readonly vEnum;

    constructor(t: TFunction) {
        this.vString = yup.string().required(t('Dieses Feld wird benötigt'));

        this.vNumber = yup.number().required(t('Dieses Feld wird benötigt')).positive();

        this.vAny = yup.object().required(t('Dieses Feld wird benötigt'));

        this.vBoolean = yup.boolean().required(t('Dieses Feld wird benötigt'));

        this.vEnum = yup.string().required(t('Dieses Feld wird benötigt'));
    }

    public static buildValidator(t: TFunction, calculation: InternalCalculationResource): yup.ObjectSchema<any> {
        const validator = new CalcValidator(t);

        const parts = calculation.parts;
        const partValidations = {};
        for (const part of parts) {
            const partFieldDefinition = part.calculationParameters.allParameters;
            let validatorFields = {};
            const colorField = part.setParameters.find((x) => x.name.startsWith(COLOR_SYSTEMS_GUI));
            let invisibleObjects = [];
            if (calculation.costResult) {
                const partSubCalc = calculation.costResult.subCalculationResults.find((x) => x.additionalInformation === part.subCalculationIdentificationKey);
                invisibleObjects = partSubCalc.guiStates.filter((x) => x.status === 'INVISIBLE').map((x) => x.affectedObject);
            }
            partFieldDefinition.forEach((field) => {
                //Remove alternative Color field if not selected
                if (field.name === ALTERNATIVE_GUI && colorField.value === COLOR_SYSTEMS_RAL) {
                    return;
                }
                if (invisibleObjects.includes(field.name)) {
                    return;
                }

                var baseFieldValidator = validator.getValidatorFieldType(t, field);
                validatorFields = {
                    ...validatorFields,
                    [field.name]: baseFieldValidator
                };
            });
            partValidations[part.id] = yup.object().shape(validatorFields);
        }
        return yup.object().shape({ ...partValidations });
    }

    getValidatorFieldType(t: TFunction, field: CalculationConfigurationParameterResource): yup.AnySchema {
        switch (field.type) {
            case 'boolean':
                return this.vBoolean;
            case 'string':
                return this.vString;
            case 'enumeration':
                return this.vEnum;
            case 'double':
                const dField = field as DoubleCalcParamResource;
                return this.vNumber.min(dField.min, t('numberHasToBeGreaterThan', { minValue: dField.min })).max(dField.max, t('numberHasToBeSmallerThan', { maxValue: dField.max }));
            case 'integer':
                const iField = field as IntegerCalcParamResource;
                return this.vNumber
                    .integer()
                    .min(iField.min, t('numberHasToBeGreaterThan', { minValue: iField.min }))
                    .max(iField.max, t('numberHasToBeSmallerThan', { maxValue: iField.max }));
            default:
                return this.vAny;
        }
    }

    public static buildParameterValidator(
        t: TFunction,
        allParameters: ParameterArray = [],
        setParameters: CalculationParameterResource[] = [],
        guiStates: GuiStatusResource[] = []
    ): yup.ObjectSchema<any> {
        const validator = new CalcValidator(t);

        let validatorFields = {};
        const colorSystem = setParameters.find((x) => x.name === COLOR_SYSTEMS_GUI)?.value;
        const invisibleObjects = guiStates.filter((x) => x.status === 'INVISIBLE').map((x) => x.affectedObject);
        allParameters.forEach((field) => {
            //Remove unselected Color field if not selected
            if (field.guiDescriptor === COLOR_GUI_IDENTIFIER && field.name !== COLOR_SYSTEMS_GUI && field.name !== colorSystem) {
                return;
            }
            if (field.name === BRING_DATE || field.name === DELIVERY_DATE) {
                return;
            }
            if (invisibleObjects.includes(field.name)) {
                return;
            }

            validatorFields = {
                ...validatorFields,
                [field.name]: validator.getValidatorFieldType(t, field)
            };
        });
        return yup.object().shape(validatorFields);
    }

    public static buildPartValidator(t: TFunction, part: CalculationPartResource, partCostResult: CalculationResultResource): yup.ObjectSchema<any> {
        const partFieldDefinition = part.calculationParameters.allParameters.filter((parameter) => parameter.name !== QUANTITY);
        return CalcValidator.buildParameterValidator(t, partFieldDefinition, part.setParameters, partCostResult.guiStates);
    }

    public static buildErrorMessages(errors: yup.ValidationError[]) {
        console.log(errors);
    }
}
