import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringValueResource, ValueResource, VariableResource, VariableValueResource } from 'src/backend/coreCalc';
import { getVariable } from 'src/utils/CalcHelpers';
import { formatVariable } from 'src/utils/FormatHelpers';

interface Props {
    dynamicValue: ValueResource;
    setDynamicValue: (dynamicValue: ValueResource) => void;
    variables: Array<VariableResource>;
    label?: string;
    disabled?: boolean;
}

const DynamicValueInput: React.FC<Props> = ({ dynamicValue, setDynamicValue, variables, label, disabled }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>('');

    const mappedVariables = useMemo(() => {
        const vars: { [label: string]: VariableResource } = {};
        variables.forEach((variable) => {
            vars[variable.name] = variable;
        });
        return vars;
    }, [variables]);

    useEffect(() => {
        if (dynamicValue.type === ValueResource.type.VARIABLE_VALUE) {
            setValue((dynamicValue as VariableValueResource).variableName);
        } else if (dynamicValue.type === ValueResource.type.STRING_VALUE) {
            setValue((dynamicValue as StringValueResource).stringValue);
        }
    }, [dynamicValue]);

    const hasError = useMemo(() => {
        if (dynamicValue.type !== ValueResource.type.STRING_VALUE) return;
        return (dynamicValue as StringValueResource).stringValue == null;
    }, [dynamicValue]);

    const handleAutocompleteChange = (event, value) => {
        if (!event) return;

        const isVariable = value.id != null || !!mappedVariables[value];
        if (isVariable) {
            const variable = value.id != null ? (value as VariableResource) : mappedVariables[value];
            setDynamicValue({ type: ValueResource.type.VARIABLE_VALUE, variableName: variable.name } as VariableValueResource);
        } else {
            value = value.replaceAll(',', '.');
            value = value === '' || value == null || isNaN(value) ? null : value;
            setDynamicValue({ type: ValueResource.type.STRING_VALUE, stringValue: value } as StringValueResource);
        }
    };

    const handleAutocompleteBlur = () => {
        if (hasError) {
            setDynamicValue({ type: ValueResource.type.STRING_VALUE, stringValue: '0' } as StringValueResource);
        }
    };

    return (
        <Autocomplete
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={hasError}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        title: value
                    }}
                />
            )}
            noOptionsText={t('noOptions')}
            forcePopupIcon={false}
            disableClearable
            freeSolo
            value={value}
            onInputChange={handleAutocompleteChange}
            onBlur={handleAutocompleteBlur}
            options={Object.values(mappedVariables)}
            getOptionLabel={(option: any) => option?.name || option}
            renderOption={(props, option: VariableResource) => (
                <li {...props} key={option.id}>
                    <Stack alignItems="flex-start" overflow="hidden">
                        <Typography fontSize={14} fontWeight={700} lineHeight={1.3} sx={{ textOverflow: 'ellipsis', maxWidth: '100%', overflow: 'hidden' }}>
                            {option.name}
                        </Typography>
                        <Typography variant="subtitle2" fontSize={12} lineHeight={1.47} color="inherit" sx={{ opacity: 0.9 }}>
                            {formatVariable(option, variables)}
                        </Typography>
                    </Stack>
                </li>
            )}
        />
    );
};
export default DynamicValueInput;
