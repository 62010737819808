import { MailTwoTone, PhoneTwoTone } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Badge, Box, IconButton, LinearProgress, Link, MenuItem, Select, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid, deDE, GridCallbackDetails, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactImageFallback from 'react-image-fallback';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomerTypeResource, RegularCustomerFilterDto, RegularCustomerResource } from '../../../../backend/market';
import useDialog from '../../../../hooks/useDialog';
import { transformPriceParameterValuesForFormik } from 'src/pages/Core/Customers/Customer';
import { RootState } from '../../../../redux/store';
import { deleteCustomer, resetSeenPages, updateCustomerFilter, updateCustomers, updateCustomerStatus, updatePage, updatePageSize, updateSort } from '../../../../redux/thunks/customers.thunk';
import usePrevious from '../../../../utils/usePrevious';
import CustomerFilterRow from '../../../input/CustomerFilterRow/CustomerFilterRow';
import CustomerMobileCell from '../../CustomerMobileCell/CustomerMobileCell';
import { t } from 'i18next';
import { nav } from 'src/statics/navigations';

interface ICustomerTable {
    sortable?: boolean;
    requestCount?: 'all' | number;
    customersState: { [key: string]: RegularCustomerResource };
    loading: boolean;
    maxHeight?: number;
    isFullList?: boolean;
    page?: number;
    pageSize?: number;
    rowCount?: number;
    types?: { [key: number]: CustomerTypeResource };
    filter?: RegularCustomerFilterDto;
}

const CustomerTable: React.FC<ICustomerTable> = ({ sortable = true, requestCount = 'all', customersState, loading, page, pageSize, rowCount, types, filter }) => {
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const dialog = useDialog();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'companyName', sort: 'asc' }]);
    const prevSortModel = usePrevious(sortModel);

    useEffect(() => {
        return () => {
            dispatch(updatePage(0));
            dispatch(resetSeenPages());
        };
    }, []);

    useEffect(() => {
        if (mediaQuery) {
            dispatch(updatePageSize(5));
        }
    }, [mediaQuery]);

    useEffect(() => {
        if (!prevSortModel) return;
        if (_.isEqual(sortModel, prevSortModel)) {
            console.log('eq to prev model');
            return;
        }
        console.log(' ** CUSTOMER SORT CALL ** ', sortModel, prevSortModel);
        dispatch(updateSort(sortModel));
    }, [sortModel]);

    //FILTER FUNCTIONS
    const filterCallback = (nFilter: RegularCustomerFilterDto) => {
        console.log('Update Customer filter', nFilter);
        dispatch(updateCustomerFilter(nFilter));
    };

    //VALUE GETTERS
    function getName(params) {
        const customer = params.row;
        return `${customer.contactPersonFirstName} ${customer.contactPersonLastName}`;
    }

    function getType(params) {
        const customer = params.row as RegularCustomerResource;
        return `${customer.customerType?.name}`;
    }

    //COL RENDERERS
    function renderStatus(params: GridRenderCellParams<string>) {
        const customer: RegularCustomerResource = params.row;

        function getBadgeColor(name: string): 'error' | 'warning' | 'success' | 'default' | 'primary' | 'secondary' | 'info' {
            switch (name) {
                case 'AKTIV':
                    return 'success';
                case 'INAKTIV':
                    return 'error';
                default:
                    return 'default';
            }
        }

        return (
            <Select onChange={(event) => handleStatusUpdate(customer.id, event.target.value)} sx={{ '&:focus': { outline: 'none' } }} fullWidth value={params.value}>
                <MenuItem value={'ACTIVE'}>
                    <Box gap={'10px'} display={'flex'} alignItems={'center'}>
                        <Badge variant={'dot'} color={getBadgeColor('AKTIV')}></Badge> {t('aktiviert')}
                    </Box>
                </MenuItem>
                <MenuItem value={'INACTIVE'}>
                    <Box gap={'10px'} display={'flex'} alignItems={'center'}>
                        <Badge variant={'dot'} color={getBadgeColor('INAKTIV')}></Badge> {t('deaktiviert')}
                    </Box>
                </MenuItem>
            </Select>
        );
    }

    function renderActions(params) {
        const customer = params.row;
        return (
            <IconButton color={'error'} onClick={(e) => handleDelete(e, customer.id)}>
                <DeleteTwoToneIcon />
            </IconButton>
        );
    }

    function renderIconAndLink(text, link, icon) {
        return (
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                {icon}
                <Link onClick={(e) => e.stopPropagation()} color={'inherit'} href={link}>
                    <Typography variant={'body2'}>{text}</Typography>
                </Link>
            </Box>
        );
    }

    function renderWithIcon(icon: ReactElement, params: GridRenderCellParams<string>) {
        return (
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                {icon}
                <Typography variant={'body2'}>{params.value}</Typography>
            </Box>
        );
    }

    function renderBold(params: GridRenderCellParams<string>) {
        return (
            <Typography variant={'body2'} fontWeight={'bold'} noWrap>
                {params.value}
            </Typography>
        );
    }

    function renderNullable(params: GridRenderCellParams<string>) {
        return <Typography variant={'body2'}>{params.value ?? '-'}</Typography>;
    }

    function renderMoney(params: GridRenderCellParams<string>) {
        const num = Number.parseFloat(params.value);
        if (params.value == null) return <Typography variant={'body2'}>-</Typography>;
        return (
            <Typography variant={'body2'}>
                {new Intl.NumberFormat('de-AT', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(num)}
            </Typography>
        );
    }

    function renderImage(params: GridRenderCellParams<string>) {
        const customer = params.row as RegularCustomerResource;
        return (
            <ReactImageFallback
                src={params.value}
                fallbackImage={`${process.env.PUBLIC_URL}/static/images/avatars/blank-profile-picture.svg`}
                style={{ maxHeight: 40, maxWidth: 60, borderRadius: 5 }}
                alt={`Logo ${customer.companyName}`}
            />
        );
    }

    function renderType(params: GridRenderCellParams<string>) {
        function reduceCustomerValues(customer: RegularCustomerResource, onlyValues: boolean = false): string {
            const customerType = _.find(types, { id: customer.customerType?.id }) as CustomerTypeResource;
            if (customerType && customerType.priceParameterValues?.length > 0) {
                const typesString = customerType.priceParameterValues
                    .map((value) => {
                        let val = value.value;
                        const overwrittenVal = customer.overwrittenPriceValues.find((o) => o.parameter.id === value.parameter.id);
                        if (overwrittenVal) {
                            val = overwrittenVal.value;
                        }
                        if (value.parameter.type === 'BOOLEAN') {
                            if (onlyValues) {
                                return `${val === 'true' ? t('yes') : t('no')}`;
                            }
                            return `${value.parameter.label}: ${val === 'true' ? t('yes') : t('no')}`;
                        }
                        if (onlyValues) {
                            return `${val}${value.parameter.unit}`;
                        }
                        return `${value.parameter.label}: ${val}${value.parameter.unit}`;
                    })
                    .join(', ');
                return `(${typesString})`;
            } else {
                return '';
            }
        }

        const customer = params.row as RegularCustomerResource;
        const discount = transformPriceParameterValuesForFormik(customer);
        return (
            <Tooltip title={reduceCustomerValues(customer)}>
                <div>
                    <Typography variant={'body2'} fontWeight={'bold'}>
                        {customer.customerType?.name}{' '}
                    </Typography>
                    <Typography variant={'caption'} style={{ fontSize: 10 }}>
                        {reduceCustomerValues(customer, true)}
                    </Typography>
                </div>
            </Tooltip>
        );
    }

    //GRID DEFINITION
    const customerGridColumns = (): GridColDef[] => {
        let columns: GridColDef[] = [
            { field: 'logo', headerName: t('logo'), flex: 0, minWidth: 60, renderCell: renderImage },
            { field: 'companyName', headerName: t('firma'), flex: 2, minWidth: 100, renderCell: renderBold },
            {
                field: 'customerType',
                headerName: t('customerType'),
                flex: 2,
                valueGetter: getType,
                renderCell: renderType,
                minWidth: 100
            },
            { field: 'contactPersonLastName', headerName: t('name'), flex: 2, valueGetter: getName, minWidth: 200 },
            {
                field: 'email',
                headerName: t('EMAIL'),
                flex: 2,
                minWidth: 250,
                renderCell: (params) => renderIconAndLink(params.value, 'mailto:' + params.value, <MailTwoTone />)
            },
            {
                field: 'phone',
                headerName: t('telefon'),
                flex: 2,
                minWidth: 200,
                renderCell: (params) => renderIconAndLink(params.value, 'tel:' + params.value, <PhoneTwoTone />)
            },
            { field: 'inquiryCount', headerName: t('anfragen'), flex: 2, minWidth: 100, renderCell: renderNullable },
            {
                field: 'salesVolume',
                headerName: t('gesamterAnfragewert'),
                flex: 2,
                minWidth: 150,
                renderCell: renderMoney
            },
            {
                field: 'wizardOpenedCount',
                headerName: t('wizardGeffnet'),
                flex: 2,
                minWidth: 150,
                renderCell: renderNullable
            },
            { field: 'state', headerName: t('status'), flex: 2, renderCell: renderStatus, minWidth: 200 },
            { field: 'actions', headerName: '', renderCell: renderActions, sortable: false }
        ];
        //COLUMN MODIFIERS
        columns = columns.map((column: GridColDef) => {
            if (column.sortable === false) {
                column.sortable = false;
            } else {
                column.sortable = sortable;
            }
            column.headerAlign = 'left';
            column.align = 'left';
            return column;
        });
        return columns;
    };

    //RENDER MOBILE
    function renderCustomerMobileCells() {
        const rows = _.values(customersState);

        return (
            <InfiniteScroll
                next={handleLoadNext}
                endMessage={
                    requestCount === 'all' ? (
                        <Typography sx={{ mt: 2, mb: 2 }} textAlign={'center'}>
                            {t('keineWeiterenKunden')}
                        </Typography>
                    ) : (
                        <div />
                    )
                }
                hasMore={(rows.length !== rowCount && requestCount === 'all') || loading}
                loader={<LinearProgress variant={'indeterminate'} />}
                dataLength={rows.length}
            >
                {rows.map((customer) => (
                    <Box key={customer.id + 'card'} sx={{ marginTop: 5 }}>
                        <CustomerMobileCell customer={customer} />
                    </Box>
                ))}
            </InfiniteScroll>
        );
    }

    function handleLoadNext() {
        console.log('HANDLING NEXT!', page);
        dispatch(updatePage(page + 1));
        dispatch(updateCustomers());
    }

    //RENDER DESKTOP
    function renderDataGrid() {
        const rows = _.values(customersState).slice(page * pageSize, requestCount == 'all' ? (page + 1) * pageSize : requestCount);
        return (
            <DataGrid
                //STYLING
                sx={{
                    border: 0,
                    backgroundColor: 'background.paper',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'background.default',
                        borderBottom: (theme: Theme) => `2px solid ${theme.palette.divider}`
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: (theme: Theme) => theme.typography.fontWeightBold,
                        color: (theme: Theme) => theme.palette.text.secondary
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none',
                        '&:focus': {
                            outline: 'none'
                        },
                        '&:focus-within': {
                            outline: 'none'
                        }
                    },
                    '& .MuiDataGrid-row': {
                        borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`
                    }
                }}
                //CONFIG
                headerHeight={50}
                density={'comfortable'}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                isRowSelectable={() => false}
                localeText={{
                    noRowsLabel: t('dgNoRowsLabel'),
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} ${t('von')} ${to}`
                    }
                }}
                autoPageSize={true}
                //STATE
                rows={loading ? [] : rows}
                columns={customerGridColumns()}
                rowCount={rowCount}
                loading={loading}
                page={page}
                //SORTING
                onSortModelChange={onSortModelChanged}
                sortModel={sortModel}
                sortingMode={'server'}
                //EVENTS
                onCellClick={(cell) => handleCellClicked(cell)}
                //PAGINATION
                onPageChange={onPageChangedCallback}
                onPageSizeChange={onPageSizeChanged}
                paginationMode={'server'}
                getRowId={(row) => row.profileKey}
            />
        );
    }

    //HANDLER
    const handleCellClicked = (cell) => navigate(nav.TOOLS.sub.CUSTOMERS.sub.DETAIL.path.replace(':id', cell.row?.id));
    const handleDelete = (event, customerId) => {
        event.stopPropagation();

        return dialog.openDialog({
            severity: 'error',
            okCallback(): void {
                dispatch(
                    deleteCustomer(customerId, () => {
                        const key = enqueueSnackbar(t('Kunde wurde gelöscht'), {
                            variant: 'success',
                            action: (
                                <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
                                    <CloseRoundedIcon />
                                </IconButton>
                            )
                        });
                    })
                );
                console.log('delete customer', customerId);
                dialog.closeDialog();
            },
            title: t('kundenLschen2'),
            okText: t('kundenLschen'),
            component: <Typography variant={'body2'}>{t('kundelschen')}</Typography>
        });
    };
    const handleStatusUpdate = (customerId, status) => {
        dispatch(
            updateCustomerStatus(customerId, status, () => {
                const key = enqueueSnackbar(t('statusHasBeenChanged'), {
                    variant: 'success',
                    action: (
                        <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon />
                        </IconButton>
                    )
                });
            })
        );
    };

    function onPageChangedCallback(page: number, details: GridCallbackDetails) {
        dispatch(updatePage(page));
        dispatch(updateCustomers());
    }

    function onPageSizeChanged(pageSize) {
        dispatch(updatePageSize(pageSize));
    }

    function onSortModelChanged(sortModel) {
        setSortModel(sortModel);
    }

    return (
        <Box>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={12}>
                    <CustomerFilterRow filterCallback={filterCallback} />
                </Grid>
            </Grid>
            <Box height={700} sx={{ marginTop: 1, cursor: 'pointer' }}>
                {mediaQuery ? renderCustomerMobileCells() : renderDataGrid()}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        customersState: state.customers.customers,
        loading: state.customers.loading,
        pageSize: state.customers.customersTablePaging.pageSize,
        page: state.customers.customersTablePaging.page,
        rowCount: state.customers.customersTablePaging.rowCount,
        filter: state.customers.customerFilter,
        types: state.constants.customerTypes
    };
};
export default connect(mapStateToProps)(CustomerTable);
