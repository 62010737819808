import { Box, Button, List, ListItem, ListItemText, Popover, styled, Tooltip, useTheme } from "@mui/material";
import React, { useMemo, useRef, useState } from "react"
import _ from "lodash";
import { t } from "i18next";
import MarkEmailUnreadTwoToneIcon from '@mui/icons-material/MarkEmailUnreadTwoTone';
import { wT } from "src/utils/wizardTranslations";
import store from "src/redux/store";
import { CalculationResultResource } from "src/backend/market";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import GppBadTwoToneIcon from '@mui/icons-material/GppBadTwoTone';
import { getAllMessages } from "src/utils/CalcHelpers";



const IconWrapper = styled(Box)(
    ({ theme }) => `
        display: inline-flex;
        justify-content: center;
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(0.4)};
        .MuiSvgIcon-root {
            font-size: 15px;
            margin: auto;
        }
    `
);
const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        .MuiListItemText-primary {
            display: flex;
        }
    `
);


interface Props {
    costResult?: CalculationResultResource;
}

const GeneralMessagesSummary: React.FC<Props> = ({ costResult }) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const s = store.getState();
    const theme = useTheme();

    const allMessages = useMemo(() => getAllMessages(costResult), [costResult]);

    const hints = useMemo(() => allMessages.filter(message => message.messageType === 'HINT').length, [allMessages]);
    const warnings = useMemo(() => allMessages.filter(message => message.messageType === 'WARNING').length, [allMessages]);
    const errors = useMemo(() => allMessages.filter(message => message.messageType.endsWith('ERROR')).length, [allMessages]);

    return allMessages.length > 0 && <>
        <Button sx={{ ml: 2, px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<MarkEmailUnreadTwoToneIcon />} variant="text" color="secondary" onClick={() => setOpen(true)} ref={ref}>
            {t('allGeneralMessages')}
            {hints > 0 && <><IconWrapper><InfoTwoToneIcon/></IconWrapper> {hints}</>}
            {warnings > 0 && <><IconWrapper><WarningTwoToneIcon color="warning"/></IconWrapper> <span style={{ color: theme.colors.warning.main }}>{warnings}</span></>}
            {errors > 0 && <><IconWrapper><GppBadTwoToneIcon color="error"/></IconWrapper> <span style={{ color: theme.colors.error.main }}>{errors}</span></>}
        </Button>

        <Popover
            anchorEl={ref.current}
            onClose={() => setOpen(false)}
            open={isOpen}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List sx={{ maxWidth: '30rem', maxHeight: '50rem' }}>
                {allMessages.map((message, index) => (
                    <ListItem key={index}>
                        <StyledListItemText primary={<>
                            {wT(message.messageId + '.short', s)}
                            <Tooltip title={t(message.messageType)}>
                                {message.messageType === 'HINT' ?
                                <IconWrapper><InfoTwoToneIcon/></IconWrapper> :
                                message.messageType === 'WARNING' ? <IconWrapper><WarningTwoToneIcon color="warning"/></IconWrapper> :
                                <IconWrapper><GppBadTwoToneIcon color="error"/></IconWrapper>}
                            </Tooltip>
                        </>
                        } secondary={wT(message.messageId + '.long', s)}/>
                    </ListItem>
                ))}
            </List>
        </Popover>
    </>
}

export default GeneralMessagesSummary