import {WizardStatisticRestControllerService} from "../../backend/market";
import {slice} from "../slices/statistics.reducer";
import {AppDispatch, RootState} from "../store";

export const loadStatistic = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().user.currentUser.id;
    const statistic = await WizardStatisticRestControllerService.getWizardStatistic(userId)
    dispatch(slice.actions.setStatistic(statistic))
}
export const loadDelta = (callback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().user.currentUser.id;
    try {
        const delta = await WizardStatisticRestControllerService.getWizardDelta(userId)
        dispatch(slice.actions.setDelta(delta))

    } catch (e) {
        console.log(e)
    }
    if (callback) callback()
}