import { Stack } from "@mui/material";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import {useParams} from "react-router-dom";
import {SummaryResource, SummaryRestControllerService} from "src/backend/market";
import { BackendService } from "src/backend/summary/BackendService";
import { Summary } from "src/components/display/Summary/Summary";
import { useQuery } from "src/hooks/useQuery";
import i18n from "src/i18n/i18n";
import { slice } from "src/redux/slices/constants.reducer";


const PublicInquirySummary: React.FC = ({}) => {
    const {id} = useParams();
    const query = useQuery();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [wizardSummary, setWizardSummary] = useState<SummaryResource>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!id) return;
        setLoading(true);

        const queryParams = new URLSearchParams(location.search);
        const languageCodeQuery = queryParams.get('languageCode');
        const languageCode = languageCodeQuery || i18n.language;

        if(languageCodeQuery) {
            i18n.changeLanguage(languageCodeQuery);
        }
        
        BackendService.loadDictionary(languageCode).then(dictionary => dispatch(slice.actions.setWizardTranslations(dictionary)));

        SummaryRestControllerService.getWizardSummary(id).then(summary => {
            setWizardSummary(summary);
            setLoading(false);
        });
    }, [])


    return <Stack p={4}>
        <Summary summary={wizardSummary} isLoading={isLoading} isPublic/>
    </Stack>
}
export default PublicInquirySummary;
