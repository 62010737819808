import { Button } from '@mui/material';
import React, { useState } from 'react';
import _ from 'lodash';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import { t } from 'i18next';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/store';
import SurchargesDialog from './SurchargesDialog';
import { VariableResource } from 'src/backend/coreCalc';

interface Props {
    variables: Array<VariableResource>;
    isSmall?: boolean;
    createVariable: (variable: VariableResource) => void;
    saveVariable: (variable: VariableResource) => void;
}

const Surcharges: React.FC<Props> = ({ variables, isSmall, saveVariable, createVariable }) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <>
            <Button sx={{ px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<LocalOfferTwoToneIcon />} variant="text" color="secondary" onClick={() => setOpen(true)} size={isSmall ? 'small' : 'medium'}>
                {t('surcharges')}
            </Button>

            <SurchargesDialog variables={variables} isOpen={isOpen} setOpen={setOpen} createVariable={createVariable} saveVariable={saveVariable} />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        surchargeOverrides: state.proCalc.surchargeOverrides
    };
};
export default connect(mapStateToProps)(Surcharges);
