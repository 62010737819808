import { Typography, Card, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import PriceFinder from './PriceFinder';

interface Props {
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    plants?: Array<{ name: string; width: number; height: number; length: number }>;
}

const PriceFinderDialog: React.FC<Props> = ({ isOpen, setOpen, plants }) => {
    const theme = useTheme();

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xl" PaperProps={{ sx: { width: '100%', height: '100%' } }} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontSize: 24, fontWeight: 600, pr: 7 }} gutterBottom>
                    Preisfindung
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ bgcolor: theme.palette.background.default }}>
                <Divider sx={{ mx: '-24px', mb: '20px' }} />
                <PriceFinder plants={plants} />
            </DialogContent>
        </Dialog>
    );
};

export default PriceFinderDialog;
