import { Stack, useTheme, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeclaredVariableResource, StringValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { getValue } from 'src/utils/CalcHelpers';

interface Props {
    variable: DeclaredVariableResource;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
}

const DeclarationVariableForm: React.FC<Props> = ({ variable, setVariable, setValid }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (variable) return;
        setVariable({
            type: VariableResource.type.DECLARATION_VARIABLE,
            name: '',
            value: { type: ValueResource.type.STRING_VALUE, stringValue: '' }
        });
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.name == null || variable.name.trim() === '' || variable.value == null || (variable.value as StringValueResource)?.stringValue === '';
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            name: event.target.value
        });
    };

    const handleValueChange = (event) => {
        setVariable({
            ...variable,
            value: { type: ValueResource.type.STRING_VALUE, stringValue: event.target.value } as StringValueResource
        });
    };

    const isDisabled = variable?.id === -1;

    return (
        variable && (
            <Stack gap={3}>
                <TextField label="Name" value={variable.name} onChange={handleNameChange} variant="outlined" fullWidth disabled={isDisabled} />
                <TextField label="Wert" value={getValue(variable.value)} onChange={handleValueChange} variant="outlined" fullWidth disabled={isDisabled} />
            </Stack>
        )
    );
};

export default DeclarationVariableForm;
