import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { AdditionalStorageResource, BooleanCalcParamResource } from 'src/backend/internalCalc';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { StyledMultiSelect } from '../CalcInputs.styles';
import InfoLabel from './InfoLabel';
import ExtractedIndicator from './ExtractedIndicator';

interface Props {
    guiDescriptor: string;
    params: BooleanCalcParamResource[];
    id: number;
    disabled?: boolean;
    isSmall?: boolean;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const MultipleBooleanParameter: React.FC<Props> = ({ guiDescriptor, params, id, disabled, isSmall, additionalStorage }) => {
    const { values, setFieldValue, validateForm } = useFormikContext();
    const s = store.getState();

    const getValue = () => {
        return params.filter((param) => values[param.name] === 'true').map((param) => param.name);
    };

    const handleChange = (event) => {
        const paramNames = event.target.value;

        params.forEach((param) => {
            const isSet = paramNames.includes(param.name);
            setFieldValue(param.name, isSet + '', false);
        });

        setTimeout(validateForm, 10);
    };

    return (
        <FormControl fullWidth disabled={disabled}>
            <InputLabel id={id + guiDescriptor + '-label'} sx={{ marginLeft: '-0.35em' }} shrink>
                <InfoLabel label={guiDescriptor} guiDescriptor={guiDescriptor} guiDescriptorExplanation={params[0].explanationTranslationKey} />
            </InputLabel>
            <StyledMultiSelect
                multiple
                input={
                    <OutlinedInput
                        notched
                        size={isSmall ? 'small' : 'medium'}
                        style={{ paddingLeft: 0 }}
                        label={<InfoLabel label={guiDescriptor} guiDescriptor={guiDescriptor} guiDescriptorExplanation={params[0].explanationTranslationKey} />}
                        // startAdornment={'test'}
                        startAdornment={<ExtractedIndicator params={params} additionalStorage={additionalStorage} />}
                    />
                }
                labelId={id + guiDescriptor + '-label'}
                label={<InfoLabel label={guiDescriptor} guiDescriptor={guiDescriptor} guiDescriptorExplanation={params[0].explanationTranslationKey} />}
                value={getValue()}
                onChange={handleChange}
                options={params.map((param) => param.name)}
                getTranslation={(value) => wT(value, s)}
                size={isSmall ? 'small' : 'medium'}
            />
        </FormControl>
    );
};
export default MultipleBooleanParameter;
