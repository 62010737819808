import PROCalc from 'src/pages/Core/PROCalc/PROCalc';
import { NavItems } from './navigations';
import Calculations from 'src/pages/Core/Calculations/Overview/Calculations';
import CalculationSummary from 'src/pages/Core/Calculations/CalculationSummary';

const calculationNavigation: NavItems = {
    BASE: {
        caption: 'calculations',
        tabTitle: 'calculationsTabTitle',
        path: '/calculations/',
        element: <Calculations />
    },
    VIEW: {
        caption: 'calculationsDetail',
        tabTitle: 'calculationsDetailTabTitle',
        path: '/calculations/:id',
        element: <CalculationSummary />
    },
    EDIT: {
        caption: 'calculationsEdit',
        tabTitle: 'calculationsEditTabTitle',
        path: '/calculations/:id/edit/',
        hideFooter: true,
        element: <PROCalc />
    }
};

export default calculationNavigation;
