/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternalCalculationResource } from '../models/InternalCalculationResource';
import type { WizardCalcMergeFilterDto } from '../models/WizardCalcMergeFilterDto';
import type { WizardCalcMergePageResultResource } from '../models/WizardCalcMergePageResultResource';
import type { WizardInquiryResource } from '../models/WizardInquiryResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WizardCalcMergingRestControllerService {

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateInternalCalc(
        requestBody: WizardInquiryResource,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardCalcMerging/wizard`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateInternalCalc1(
        requestBody: InternalCalculationResource,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardCalcMerging/internalCalc`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static createNewMigration(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/wizardCalcMerging/migrations`,
        });
    }

    /**
     * @param page
     * @param size
     * @param requestBody
     * @param sortBy
     * @returns WizardCalcMergePageResultResource OK
     * @throws ApiError
     */
    public static getWizardCalcsByCompanyFilter(
        page: number,
        size: number,
        requestBody: WizardCalcMergeFilterDto,
        sortBy: string = 'idAsc',
    ): CancelablePromise<WizardCalcMergePageResultResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/wizardCalcMerging/companyWizardCalcs/filter`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uniqueId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMigration(
        uniqueId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/wizardCalcMerging/${uniqueId}`,
        });
    }

}