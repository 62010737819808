import {
    Typography,
    Box,
    Avatar,
    Card,
    CardContent,
    styled,
    alpha,
    useTheme,
    Stack,
    CircularProgress
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import { ApexOptions } from 'apexcharts';


const Started = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.success.main};
        height: 6px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    `
);
const Sent = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.warning.main};
        height: 6px;
    `
);
const Finished = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.warning.main};
        height: 6px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    `
);
const DotLegend = styled('span')(
    ({ theme }) => `
        border-radius: 22px;
        width: ${theme.spacing(1.5)};
        height: ${theme.spacing(1.5)};
        display: inline-block;
        margin-right: ${theme.spacing(0.5)};
    `
);

interface Props {
    finishedWizardsPercent: number;
    isNoData: boolean;
}

const InquiriesStatus: React.FC<Props> = ({ finishedWizardsPercent, isNoData }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();

    const chartData = {
        datasets: [{ backgroundColor: [theme.colors.success.main, theme.colors.warning.main] }],
        labels: [t('started'), t('finished')]
    };

    const chartSeries = [100 - finishedWizardsPercent, finishedWizardsPercent];

    return (
        <Card sx={{ p: 2.7, pb: 1, height: '100%' }} className="inquiries-status">
            {(finishedWizardsPercent === undefined || Number.isNaN(finishedWizardsPercent)) ?
                <Stack height={"100%"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Typography gutterBottom component="div" variant="caption">
                        {t('inquiriesStatus')}
                    </Typography>
        
                    {isNoData ?
                        <Typography variant="subtitle2" sx={{ py: 1.2 }}>{t('noDataYet')}</Typography>
                    : <>
                        <Box sx={{ display: 'flex', flexGrow: 1 }} alignItems="center" pt={0.7}>
                            <Started width={chartSeries[0] + '%'}/>
                            {/* <Sent width={chartSeries[1] + '%'}/> */}
                            <Finished width={chartSeries[1] + '%'}/>
                        </Box>
        
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}} pt={1}>
                            {chartData.labels.map((label: string, i: number) => (
                                <Typography key={label} variant="body2" sx={{ py: 0.6, display: 'flex', alignItems: 'center', mr: 2}}>
                                    <DotLegend style={{ background: `${chartData.datasets[0].backgroundColor[i]}` }}/>
                                    <span style={{ paddingRight: 10, color: `${chartData.datasets[0].backgroundColor[i]}`}}>
                                        {chartSeries[i]}%
                                    </span>
                                    {label}
                                </Typography>
                            ))}
                        </Box>
                    </>}
                </>
                }
        </Card>

    );
}

export default InquiriesStatus;
