/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewTranslationByKeyDto } from '../models/NewTranslationByKeyDto';
import type { TranslationResource } from '../models/TranslationResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TranslationsRestControllerService {

    /**
     * @param requestBody
     * @returns TranslationResource OK
     * @throws ApiError
     */
    public static reportNewTranslationKey(
        requestBody: NewTranslationByKeyDto,
    ): CancelablePromise<Array<TranslationResource>> {
        return __request({
            method: 'POST',
            path: `/marketplace/translations/keys`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getLanguageCodes(): CancelablePromise<Array<string>> {
        return __request({
            method: 'GET',
            path: `/marketplace/translations/languageCodes`,
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getAllIso3166LanguageCodes(): CancelablePromise<Array<string>> {
        return __request({
            method: 'GET',
            path: `/marketplace/translations/iso3166languageCodes`,
        });
    }

}