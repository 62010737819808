import {
    CalculationMetadataDto,
    InternalCalculationResource,
    InternalCalculationRestControllerService,
    SurchargeOverrideStorageResource,
    EnumerationCalcParamResource,
    CalculationParameterDto,
    InternalCalculationAdditionalStorageRestControllerService,
    AdditionalStorageDto,
    ExtractedValuesDto,
    CreateCalculationPartDto,
    CalculationMetadataResource,
    InternalCalcPriceManipulationParameterValueResource,
    CalculationFragmentResource,
    CalculationPartResource
} from '../../backend/internalCalc';
import { AppDispatch, RootState, snackContext } from '../store';
import i18n from '../../i18n/i18n';
import {
    EXCLUDED_PARAMETERS_FOR_AUTOSET,
    FRAGMENT_MATERIAL,
    HOLES,
    HOLES_DIMENSIONS,
    MATERIAL,
    QUANTITY,
    THREADS,
    THREADS_DIMENSIONS,
    TMP_LOADER_FRAGMENT,
    TMP_LOADER_ITEM
} from 'src/statics/statics';
import { API_CONFIG } from 'src/config';
import {
    AiMailConversationResource,
    AiMailConversationRestControllerService,
    AttachmentRestControllerService,
    FileResource,
    HoleRecognitionAfterburnerRestControllerService,
    MailConfigurationDto,
    ThumbnailRestControllerService
} from 'src/backend/market';
import { addFileWithThumbnail, dataURLtoFile } from './fileManager.thunk';
import { differenceBy } from 'lodash';
import {
    setLoading,
    handleError,
    setProCalc,
    setSurchargeOverrides,
    setMailConversation,
    setProCalcValue,
    setProCalcSelectively,
    setPart,
    setAttachmentInMetadata,
    setAttachmentInPart,
    setHoleRecognitionLoader,
    setPartParameter,
    addAdditionalStorageToPart,
    setFragment,
    setCategories,
    setSecondLayerItems,
    ProCalcHoleRecognitionResult
} from '../slices/proCalc.reducer';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { BackendService } from 'src/backend/summary/BackendService';
import { ILayer } from 'src/backend/summary/resources/ILayer';
import { TechnicalDrawingRestControllerService } from 'src/backend/market/services/TechnicalDrawingRestControllerService';
import { TechnicalDrawingDto } from 'src/backend/market/models/TechnicalDrawingDto';

/*
###############
##  GENERAL  ##
###############
*/
export const createProCalc = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(true));
        const calc = await InternalCalculationRestControllerService.createCalculation(getState().user.currentUser.id);
        dispatch(setProCalc(calc));
        dispatch(setLoading(false));
        return calc;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const loadProCalc = (uniqueId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        const calc = await InternalCalculationRestControllerService.getInternalCalculation1(uniqueId);
        await dispatch(loadSurchargeOverrides(calc.id));
        await dispatch(loadMailConversation(uniqueId));
        dispatch(setProCalc(calc));
        dispatch(setLoading(false));
        return calc;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};
export const getProCalcById = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        const calc = await InternalCalculationRestControllerService.getInternalCalculation(id);
        dispatch(setLoading(false));
        return calc;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const loadSurchargeOverrides = (internalCalcId: number) => async (dispatch: AppDispatch) => {
    const surchargeOverrides = await InternalCalculationRestControllerService.getSurchargeOverrides(internalCalcId);
    dispatch(setSurchargeOverrides(surchargeOverrides));
};

export const loadMailConversation = (uniqueId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const loadedMailConversations: { [uniqueId: string]: AiMailConversationResource } = getState().calculations.mailConversations || {};
    let mailConversation = loadedMailConversations[uniqueId];
    if (!mailConversation) {
        mailConversation = await AiMailConversationRestControllerService.getConversationStreamOfCalculation(uniqueId);
    }
    if (mailConversation?.conversation?.[0]) await AiMailConversationRestControllerService.setMailRead(uniqueId, mailConversation.conversation[0].id);
    dispatch(setMailConversation(mailConversation));
};
export const loadGeneratedAnswerMail = (uniqueId: string) => async (dispatch: AppDispatch) => {
    const mail = await AiMailConversationRestControllerService.getGeneratedAnswerToMail(uniqueId);
    return mail;
};
export const sendAnswerMail = (uniqueId: string) => async (dispatch: AppDispatch) => {
    await AiMailConversationRestControllerService.sendAnswer(uniqueId);
    await dispatch(loadMailConversation(uniqueId));
};
export const updateAnswerMail = (uniqueId: string, updatedAnswerMail: MailConfigurationDto) => async (dispatch: AppDispatch) => {
    await AiMailConversationRestControllerService.updateAnswer(uniqueId, updatedAnswerMail);
};

export const getProCalc = (internalCalcId: number) => async () => {
    const calc = await InternalCalculationRestControllerService.getInternalCalculation(internalCalcId);
    return calc;
};

export const duplicateAndUpdateProCalc = (calcId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(true));
        return await InternalCalculationRestControllerService.createInternalCalculationDuplicateAndUpdateDuplicate(calcId);
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};
export const duplicateProCalc = (calcId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(true));
        return await InternalCalculationRestControllerService.createInternalCalculationDuplicateAndUpdateDuplicate(calcId);
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};
export const duplicateWizardCalc = (wizardId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const userId = getState().user.currentUser.id;
        dispatch(setLoading(true));
        return await InternalCalculationRestControllerService.createCalculationFromWizardAndDuplicateIt(userId, { wizardId });
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const deleteProCalc = (calcId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(true));
        await InternalCalculationRestControllerService.deleteCalculation(calcId);
        snackContext.enqueueSnackbar(i18n.t('Kalkulation wurde gelöscht'), { variant: 'success' });
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const loadCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().proCalc.categories.length > 0) return;

    const nameResponse = await (await fetch(API_CONFIG.SUMMARY.BACKEND_URL + '/globalSettings/ALL_CATEGORY_CAPTION')).json();
    const imageResponse = await (await fetch(API_CONFIG.SUMMARY.BACKEND_URL + '/globalSettings/ALL_CATEGORY_IMAGE')).json();

    const allCategory: ILayer = {
        availableSubGeometryPackagesForFragmentation: [],
        calculationId: -1,
        hasChildren: false,
        imageUri: '',
        itemCaption: '',
        parent: null,
        rank: -1,
        supported: false,
        nameKey: nameResponse?.value,
        id: -1,
        children: [],
        itemUiImage: imageResponse?.value,
        subtitleKey: null
    };

    const profileId = getState().wizardProfile.currentProfile.wizardKey;
    const categories = await BackendService.loadFirstLevel(profileId);
    dispatch(setCategories([...categories, allCategory]));
};

export const loadSecondLayerItems = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().proCalc.secondLayerItems.length > 0) return;

    const profileId = getState().wizardProfile.currentProfile.wizardKey;
    const secondLayerItems = await BackendService.loadSecondLayer(profileId);
    dispatch(setSecondLayerItems(secondLayerItems));
};

export const getRecentProCalcs = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    const companyId = getState().user.currentUser.company.companyId;
    try {
        const result = await InternalCalculationRestControllerService.getAllInternalCalculations(companyId, 0, 20, 'createdAtDesc');
        dispatch(setLoading(false));
        return result.data;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

/*
###############
##  HELPERS  ##
###############
*/

export const getInitParameters = (calc: InternalCalculationResource, newPart: CalculationPartResource, initParameters?: Array<CalculationParameterDto>) => {
    let parameters: Array<CalculationParameterDto> = [];

    const filteredParts = (calc.parts || []).filter((part) => part.itemName !== TMP_LOADER_ITEM);
    const mappedParameters = newPart.calculationParameters.allParameters.reduce((accumulator, parameter) => {
        if (parameter.name !== QUANTITY) accumulator[parameter.name] = parameter;
        return accumulator;
    }, {});

    if (filteredParts.length === 0 && !initParameters) return null;

    if (filteredParts.length > 0) {
        const previousPart = filteredParts[filteredParts.length - 1];
        const previousGeometryParameters = previousPart.calculationParameters.allParameters.filter((parameter) => !!parameter.geometry).map((parameter) => parameter.name);

        const mappedPreviousParameters = previousPart.calculationParameters.allParameters.reduce((accumulator, parameter) => {
            if (parameter.name !== QUANTITY && !previousGeometryParameters.includes(parameter.name)) accumulator[parameter.name] = parameter;
            return accumulator;
        }, {});

        const mappedPreviousSetParameters = previousPart.setParameters.reduce((accumulator, parameter) => {
            if (parameter.name !== QUANTITY && !previousGeometryParameters.includes(parameter.name)) accumulator[parameter.name] = parameter.value;
            return accumulator;
        }, {});

        parameters = newPart.setParameters.map((setParameter) => {
            const foundInitParam = (initParameters || []).find((param) => param.name === setParameter.name);
            if (foundInitParam) return foundInitParam;
            if (EXCLUDED_PARAMETERS_FOR_AUTOSET.includes(setParameter.name)) return setParameter;
            const parameter = mappedParameters[setParameter.name];
            const previousParameter = mappedPreviousParameters[setParameter.name];
            const previousValue = mappedPreviousSetParameters[setParameter.name];

            if (previousValue == null || previousParameter?.type !== parameter?.type) return setParameter;

            if (parameter?.type === 'enumeration' && !parameter.items.some((item) => item.id === previousValue)) return setParameter;

            return {
                name: setParameter.name,
                value: previousValue
            };
        });
    } else if (initParameters) parameters.push(...initParameters);

    const partCostResult = (newPart.costResult.subCalculationResults || []).find((partCostResult) => partCostResult.additionalInformation === newPart.subCalculationIdentificationKey);
    if (partCostResult?.parameterValueChangeCommands?.length > 0) {
        const parametersToChange = partCostResult.parameterValueChangeCommands.reduce((accumulator, parameterValueChangeCommand) => {
            accumulator[parameterValueChangeCommand.parameterName] = parameterValueChangeCommand.newValue;
            return accumulator;
        }, {});

        parameters = parameters.map((parameter) => {
            if (parametersToChange[parameter.name]) return { ...parameter, value: parametersToChange[parameter.name] };
            return parameter;
        });
    }

    return parameters;
};

export const loadAttachmentPaths = (attachmentId: string) => async (dispatch: AppDispatch) => {
    try {
        const results = (await AttachmentRestControllerService.getAttachmentsWithPaths({ requests: [{ attachmentId }] })) || [];
        const foundResult = results.find((result) => result?.attachment?.attachmentId === attachmentId);
        return foundResult.paths;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getHolesAndThreads = (fileUrl: string) => async (): Promise<ProCalcHoleRecognitionResult> => {
    const key = await HoleRecognitionAfterburnerRestControllerService.getHoleRecognitionAfterburnerApiKey();
    const response = await fetch(API_CONFIG.HOLE_RECOGNITION.HOLE_RECOGNITION_PATH, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': key.apiKey },
        body: JSON.stringify({ url: fileUrl })
    });
    const result = await response.json();
    return result;
};

export const uploadThumbnail = (screenshot: string, fileName: string) => async () => {
    const result = await ThumbnailRestControllerService.createNewPresignedUrlForMetadata({ name: fileName, fileType: 'image/png' });

    const response = await fetch(result.presignedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'image/png' },
        body: dataURLtoFile(screenshot, fileName)
    });
    if (response.status !== 200) throw new Error(response.statusText);

    return result.assignedImageName;
};

export const addStorageEntriesToPart = (calc: InternalCalculationResource, partId: number, additionalStorage: Array<AdditionalStorageDto>) => async () => {
    for (const storageEntry of additionalStorage) {
        await InternalCalculationAdditionalStorageRestControllerService.addAdditionalStorageToPart(calc.id, partId, storageEntry);
    }
};

export const updateCostResult = (calc: InternalCalculationResource) => async (dispatch: AppDispatch) => {
    const costResult = await InternalCalculationRestControllerService.getInternalCalculationCostResult(calc.id);
    dispatch(setProCalcValue({ calcId: calc.id, path: 'costResult', value: costResult }));
    return costResult;
};

/*
####################
##  CALC UPDATES  ##
####################
*/
export const updateMasterParameters = (calc: InternalCalculationResource, parameters: Array<CalculationParameterDto>) => async (dispatch: AppDispatch) => {
    await InternalCalculationRestControllerService.setMasterCalculationParameters(calc.id, { parameters });
    await dispatch(updateCostResult(calc));
};

export const updateMetadata = (calc: InternalCalculationResource, metadata: CalculationMetadataResource) => async (dispatch: AppDispatch) => {
    const dto: CalculationMetadataDto = {
        companyName: metadata.companyName,
        contactPerson: metadata?.contactPerson,
        note: metadata?.note,
        contactedVia: metadata.contactedVia || null
    };
    if (metadata.placeId) {
        dto.manualAddressEntry = null;
        dto.placeId = metadata.placeId;
    } else {
        dto.manualAddressEntry = {
            street: metadata.address?.street || '',
            houseNumber: metadata.address?.houseNumber || '',
            zipcode: metadata.address?.zipcode || '',
            city: metadata.address?.city || '',
            country: metadata.address?.country || null
        };
        dto.placeId = null;
    }
    const result = await InternalCalculationRestControllerService.setMetadata(calc.id, dto);

    const valuesToChange = metadata.placeId
        ? ['calculationMetadata.address', 'calculationMetadata.companyName', 'calculationMetadata.placeId', 'calculationMetadata.contactedVia']
        : ['calculationMetadata.address.latitude', 'calculationMetadata.address.longitude'];

    dispatch(setProCalcSelectively({ calcId: calc.id, updatedCalculation: result, paths: valuesToChange }));
};

export const updateRegularCustomer = (calc: InternalCalculationResource, regularCustomerId: number) => async () => {
    await InternalCalculationRestControllerService.setRegularCustomer(calc.id, { regularCustomerId });
};

export const updateNote = (calc: InternalCalculationResource, note: string | null) => async () => {
    await InternalCalculationRestControllerService.setNote(calc.id, { note });
};

export const updateCommission = (calc: InternalCalculationResource, commission: string) => async () => {
    await InternalCalculationRestControllerService.setCommission(calc.id, { commission });
};

export const updatePriceManipulationParameter =
    (calc: InternalCalculationResource, value: { index: number; paramValue: InternalCalcPriceManipulationParameterValueResource }) => async (dispatch: AppDispatch) => {
        const paramValue = value.paramValue;
        await InternalCalculationRestControllerService.setPriceManipulationParameterValue(calc.id, paramValue.valueId, { newValue: paramValue.value });
        await dispatch(updateCostResult(calc));
    };

export const updateSurchargeOverrides = (calc: InternalCalculationResource, surchargeStorage: Array<SurchargeOverrideStorageResource>) => async (dispatch: AppDispatch) => {
    await InternalCalculationRestControllerService.updateSurchargeStorage(calc.id, { surchargeStorage });
    await dispatch(updateCostResult(calc));
};

/*
####################
##  PART UPDATES  ##
####################
*/
export const createPart =
    (
        calc: InternalCalculationResource,
        value: {
            itemId: number;
            initParameters?: Array<CalculationParameterDto>;
            fileResource?: FileResource;
            extractedValuesDto?: ExtractedValuesDto;
            additionalStorage?: Array<AdditionalStorageDto>;
            userDefinedPartName?: string;
            thumbnail?: {
                base64String: string;
                name: string;
            };
        }
    ) =>
    async (dispatch: AppDispatch) => {
        const partDto: CreateCalculationPartDto = { itemId: value.itemId };
        const fileName = value.fileResource?.name || '';
        if (fileName.endsWith('.pdf')) partDto.pdfFileId = value.fileResource.nodeId;
        else if (fileName.endsWith('.dwg')) partDto.dwgFileId = value.fileResource.nodeId;
        else if (fileName.endsWith('.dxf')) partDto.dxfFileId = value.fileResource.nodeId;
        else if (fileName) partDto.threeDFileId = value.fileResource.nodeId;

        if (fileName) partDto.dataSourceOriginalFileName = value.fileResource.name;

        let newPart = await InternalCalculationRestControllerService.addPart(calc.id, partDto);
        const initParameters = getInitParameters(calc, newPart, value?.initParameters);
        if (initParameters) {
            newPart = await InternalCalculationRestControllerService.updatePart(calc.id, newPart.id, {
                itemId: newPart.itemId,
                parameters: initParameters,
                extractedValuesDto: value?.extractedValuesDto
            });
        }
        if (value?.additionalStorage) {
            newPart.additionalStorage = [...value?.additionalStorage];
            await dispatch(addStorageEntriesToPart(calc, newPart.id, value.additionalStorage));
        }
        if (value?.userDefinedPartName) {
            newPart = await dispatch(updateUserDefinedPartName(calc, { partId: newPart.id, name: value.userDefinedPartName }));
        }
        if (value?.thumbnail) {
            const assignedImageName = await dispatch(uploadThumbnail(value.thumbnail.base64String, value.thumbnail.name));
            newPart = await InternalCalculationRestControllerService.addThumbnailToPart(calc.id, newPart.id, { thumbnailName: assignedImageName });
        }

        await dispatch(updateCostResult(calc));
        const partIndex = calc.parts.findIndex((part) => part.itemName === TMP_LOADER_ITEM);
        dispatch(setPart({ index: partIndex, part: newPart, calcId: calc.id }));
        return newPart.id;
    };
export const duplicatePart = (calc: InternalCalculationResource, partId: number) => async (dispatch: AppDispatch) => {
    const newPart = await InternalCalculationRestControllerService.duplicatePart(calc.id, partId);
    await dispatch(updateCostResult(calc));
    const partIndex = calc.parts.findIndex((part) => part.itemName === TMP_LOADER_ITEM);
    dispatch(setPart({ index: partIndex, part: newPart, calcId: calc.id }));
};
export const removePart = (calc: InternalCalculationResource, partId: number) => async (dispatch: AppDispatch) => {
    await InternalCalculationRestControllerService.deletePart(calc.id, partId);
    await dispatch(updateCostResult(calc));
};
export const updatePartParameter = (calc: InternalCalculationResource, value: { partId: number; itemId: number; parameter: CalculationParameterDto }) => async (dispatch: AppDispatch) => {
    const updatedPart = await InternalCalculationRestControllerService.updatePart(calc.id, value.partId, { itemId: value.itemId, parameters: [value.parameter] });
    dispatch(setProCalcValue({ calcId: calc.id, path: 'status', value: updatedPart.calculationStatus }));
    dispatch(setProCalcValue({ calcId: calc.id, path: 'invalidStatus', value: updatedPart.calculationInvalidStatus }));
    await dispatch(updateCostResult(calc));
};
export const updatePartParameters = (calc: InternalCalculationResource, value: { partId: number; itemId: number; parameters: Array<CalculationParameterDto> }) => async (dispatch: AppDispatch) => {
    const updatedPart = await InternalCalculationRestControllerService.updatePart(calc.id, value.partId, { itemId: value.itemId, parameters: value.parameters });
    dispatch(setProCalcValue({ calcId: calc.id, path: 'status', value: updatedPart.calculationStatus }));
    dispatch(setProCalcValue({ calcId: calc.id, path: 'invalidStatus', value: updatedPart.calculationInvalidStatus }));
    await dispatch(updateCostResult(calc));
};

export const updateUserDefinedPartName = (calc: InternalCalculationResource, value: { partId: number; name: string }) => async () => {
    const part = await InternalCalculationRestControllerService.updateUserDefinedPartName(calc.id, value.partId, { userDefinedPartName: value.name });
    return part;
};

export const updatePartForcePrice = (calc: InternalCalculationResource, value: { partId: number; force: boolean; price: number }) => async (dispatch: AppDispatch) => {
    const updatedCalculation = await InternalCalculationRestControllerService.setPartForcePrice(calc.id, value.partId, { force: value.force, price: value.price });
    dispatch(setProCalcSelectively({ updatedCalculation, paths: ['costResult'], calcId: calc.id }));
};

export const addAttachmentToMetadata = (calc: InternalCalculationResource, file: File) => async (dispatch: AppDispatch) => {
    const folderName = calc.attachmentFolder.folderName;
    const result = await AttachmentRestControllerService.createNewPresignedUrlForMetadata1({ folderName, fileTypeAsMimeType: file.type });

    const response = await fetch(result.presignedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file
    });

    if (response.status !== 200) throw new Error(response.statusText);

    const attachment = await InternalCalculationRestControllerService.addAttachmentToMetadata(calc.id, {
        attachmentId: result.attachmentId,
        originalFileName: file.name,
        fileTypeAsMimeType: file.type
    });
    const attachmentIndex = (calc?.calculationMetadata?.attachments || []).findIndex((a) => a.attachmentId === 'uploadLoading' && a.originalFileName === file.name);
    dispatch(setAttachmentInMetadata({ index: attachmentIndex, attachment, calcId: calc.id }));
};

export const removeAttachmentFromMetadata = (calc: InternalCalculationResource, attachmentId: string) => async (dispatch: AppDispatch) => {
    await InternalCalculationRestControllerService.deleteAttachment(attachmentId);
};

export const addAttachmentToPart = (calc: InternalCalculationResource, value: { partId: number; file: File }) => async (dispatch: AppDispatch) => {
    const fileName = value.file.name.toLowerCase();
    const mimeType = !value.file.type && (fileName.endsWith('stp') || fileName.endsWith('step')) ? 'application/step' : value.file.type;
    const folderName = calc.attachmentFolder.folderName;
    const result = await AttachmentRestControllerService.createNewPresignedUrlForMetadata1({ folderName, fileTypeAsMimeType: mimeType });

    const response = await fetch(result.presignedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': mimeType },
        body: value.file
    });

    if (response.status !== 200) throw new Error(response.statusText);

    const attachment = await InternalCalculationRestControllerService.addAttachmentToPart(calc.id, value.partId, {
        attachmentId: result.attachmentId,
        originalFileName: value.file.name,
        fileTypeAsMimeType: mimeType
    });
    const partIndex = calc.parts.findIndex((part) => part.id === value.partId);
    const attachmentIndex = (calc.parts[partIndex].attachments || []).findIndex((a) => a.attachmentId === 'uploadLoading' && a.originalFileName === value.file.name);
    dispatch(setAttachmentInPart({ partId: value.partId, index: attachmentIndex, attachment, calcId: calc.id }));
};
export const removeAttachmentFromPart = (calc: InternalCalculationResource, value: { partId: number; attachmentId: string }) => async () => {
    await InternalCalculationRestControllerService.deleteAttachment(value.attachmentId);
};

const isFileResource = (file: File | FileResource): file is FileResource => {
    return (file as FileResource).nodeId !== undefined;
};

export const create3dPart =
    (
        calc: InternalCalculationResource,
        value: {
            itemId: number;
            file: File | FileResource;
            stats: ModelStats;
            disableHoleRecognition?: boolean;
        }
    ) =>
    async (dispatch: AppDispatch) => {
        const { file, stats, itemId } = value;

        const dimensions: Array<CalculationParameterDto> = [
            { name: 'groessteLaenge', value: Math.round(stats.boundingBox.length.baseValue) + '' },
            { name: 'groessteBreite', value: Math.round(stats.boundingBox.width.baseValue) + '' },
            { name: 'groessteHoehe', value: Math.round(stats.boundingBox.height.baseValue) + '' }
        ];

        const additionalStorage = dimensions.map((dimension) => ({
            key: 'extracted_' + dimension.name,
            value: dimension.value
        }));

        let fileResource;
        if (isFileResource(file)) {
            fileResource = file;
        } else {
            fileResource = await dispatch(addFileWithThumbnail(file, file.name, stats.screenshot));
        }

        const partInfos = {
            itemId,
            initParameters: dimensions,
            fileResource,
            extractedValuesDto: {
                objectSurfaceArea: stats.surfaceArea.value,
                boundingBoxSurfaceArea: stats.boundingBox.surfaceArea.value,
                objectConvexHullSurfaceArea: stats.convexHull.surfaceArea.value,
                objectVolume: stats.volume.value
            },
            additionalStorage,
            userDefinedPartName: file.name,
            thumbnail: {
                base64String: stats.screenshot,
                name: file.name + '_screenshot.png'
            }
        };

        const partId = await dispatch(createPart(calc, partInfos));

        const filename = file.name.toLocaleLowerCase();
        if (value.disableHoleRecognition !== true && (filename.endsWith('.step') || filename.endsWith('.stp'))) {
            dispatch(setHoleRecognitionLoader({ partId, isLoading: true }));
            const result = await dispatch(getHolesAndThreads(fileResource.url));

            if (result?.holes != null && result?.threads != null) {
                const parameters = [
                    { name: HOLES, value: result.holes + '' },
                    { name: THREADS, value: result.threads + '' }
                ];
                const additionalData = parameters.map((param) => ({ key: 'extracted_' + param.name, value: param.value }));
                if (Array.isArray(result.holeDimensions) && result.holeDimensions.length > 0) {
                    additionalData.push({ key: 'extracted_' + HOLES_DIMENSIONS, value: JSON.stringify(result.holeDimensions) });
                }
                if (Array.isArray(result.threadDimensions) && result.threadDimensions.length > 0) {
                    additionalData.push({ key: 'extracted_' + THREADS_DIMENSIONS, value: JSON.stringify(result.threadDimensions) });
                }
                await dispatch(addStorageEntriesToPart(calc, partId, additionalData));
                await dispatch(updatePartParameters(calc, { partId, itemId, parameters }));

                dispatch(addAdditionalStorageToPart({ partId, additionalStorage: additionalData, calcId: calc.id }));
                dispatch(setPartParameter({ partId, itemId, parameter: parameters[0], calcId: calc.id }));
                dispatch(setPartParameter({ partId, itemId, parameter: parameters[1], calcId: calc.id }));
            }

            setTimeout(() => dispatch(setHoleRecognitionLoader({ partId, isLoading: false })), 500);
        }
    };

export const createFragment =
    (
        calc: InternalCalculationResource,
        value: {
            partId: number;
            itemId: number;
            geometryPackage: string;
            geometryPackageName: string;
        }
    ) =>
    async (dispatch: AppDispatch) => {
        const { partId, itemId, geometryPackage, geometryPackageName } = value;
        const part = calc.parts.find((part) => part.id === partId);
        const material = (part.setParameters || []).find((param) => param.name === MATERIAL)?.value;
        let updatedPart = await InternalCalculationRestControllerService.addFragment(calc.id, value.partId, { itemId, geometryPackage, geometryPackageName });

        let newFragment = differenceBy(updatedPart.fragments, part.fragments, 'id')?.[0];
        if (!material || !newFragment) return;

        const materialFragmentParameter: EnumerationCalcParamResource = (newFragment.fragmentParameters || []).find((param) => param.name === FRAGMENT_MATERIAL);
        const hasMaterial = (materialFragmentParameter?.items || []).some((item) => item.id === material);
        if (hasMaterial) {
            updatedPart = await InternalCalculationRestControllerService.updateFragment(calc.id, partId, newFragment.id, {
                geometryPackage: geometryPackage,
                geometryPackageName: geometryPackageName,
                parameters: [{ name: FRAGMENT_MATERIAL, value: material }]
            });
        }
        newFragment = updatedPart.fragments.find((fragment) => fragment.id === newFragment.id);
        await dispatch(updateCostResult(calc));
        const tmpFragmentIndex = part.fragments.findIndex((fragment) => fragment.itemName === TMP_LOADER_FRAGMENT);
        dispatch(setFragment({ partId, index: tmpFragmentIndex, fragment: newFragment, calcId: calc.id }));
    };

export const duplicateFragment = (calc: InternalCalculationResource, value: { partId: number; fragmentId: number }) => async (dispatch: AppDispatch) => {
    const { partId, fragmentId } = value;
    const part = calc.parts.find((part) => part.id === partId);
    const updatedPart = await InternalCalculationRestControllerService.duplicateFragment(calc.id, partId, fragmentId);
    const newFragment = differenceBy(updatedPart.fragments, part.fragments, 'id')?.[0];
    const tmpFragmentIndex = part.fragments.findIndex((fragment) => fragment.itemName === TMP_LOADER_FRAGMENT);
    await dispatch(updateCostResult(calc));
    dispatch(setFragment({ partId, index: tmpFragmentIndex, fragment: newFragment, calcId: calc.id }));
};

export const removeFragment = (calc: InternalCalculationResource, value: { partId: number; fragmentId: number }) => async (dispatch: AppDispatch) => {
    await InternalCalculationRestControllerService.deleteFragment(calc.id, value.partId, value.fragmentId);
    await dispatch(updateCostResult(calc));
};

export const updateFragmentParameter =
    (calc: InternalCalculationResource, value: { partId: number; fragment: CalculationFragmentResource; parameter: CalculationParameterDto }) => async (dispatch: AppDispatch) => {
        await InternalCalculationRestControllerService.updateFragment(calc.id, value.partId, value.fragment.id, {
            geometryPackage: value.fragment.geometryPackage,
            geometryPackageName: value.fragment.geometryPackageName,
            parameters: [value.parameter]
        });
        await dispatch(updateCostResult(calc));
    };
export const updateFragmentParameters =
    (calc: InternalCalculationResource, value: { partId: number; fragment: CalculationFragmentResource; parameters: Array<CalculationParameterDto> }) => async (dispatch: AppDispatch) => {
        await InternalCalculationRestControllerService.updateFragment(calc.id, value.partId, value.fragment.id, {
            geometryPackage: value.fragment.geometryPackage,
            geometryPackageName: value.fragment.geometryPackageName,
            parameters: value.parameters
        });
        await dispatch(updateCostResult(calc));
    };

export const changeGeometryPackageOfFragment = (calc: InternalCalculationResource, value: { partId: number; fragment: CalculationFragmentResource }) => async (dispatch: AppDispatch) => {
    const part = calc.parts.find((part) => part.id === value.partId);
    const updatedPart = await InternalCalculationRestControllerService.updateFragment(calc.id, value.partId, value.fragment.id, {
        geometryPackage: value.fragment.geometryPackage,
        geometryPackageName: value.fragment.geometryPackageName
    });
    await dispatch(updateCostResult(calc));

    const fragmentIndex = part.fragments.findIndex((fragment) => fragment.id === value.fragment.id);
    const newFragment = updatedPart.fragments.find((fragment) => fragment.id === value.fragment.id);

    dispatch(setFragment({ partId: value.partId, index: fragmentIndex, fragment: newFragment, calcId: calc.id }));
};

export const extractTechnicalDrawingData = (technicalDrawingDto: TechnicalDrawingDto) => async (dispatch: AppDispatch) => {
    await TechnicalDrawingRestControllerService.extractData(technicalDrawingDto);
};
