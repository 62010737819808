import { Button, Collapse, Stack, Typography, styled } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';

const AccordionWrapper = styled(Stack)(
    ({ theme }) => `
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border: 1px solid transparent;
        border-left: none;
        border-right: none;
        transition: ${theme.transitions.create(['background-color', 'border-color'])};
      
        &.hide-first:first-of-type {
            border-top: none;
        }
        &.hide-last:last-of-type {
            border-bottom: none;
        }
        &:last-of-type {
            margin-bottom: -1px;
        }
        &:hover {
            border-color: ${theme.colors.alpha.black[10]};
        }
        &.open {
            border-color: ${theme.colors.alpha.black[10]};
            background-color: ${theme.colors.alpha.black[5]};
        }
        &.open .arrow {
            transition: ${theme.transitions.create(['transform'])};
            transform: rotate(180deg);
        }
        .accordion-button {
            padding: ${theme.spacing(1.5, 2)};
            border-radius: 0;
        }

        &:hover .select-accordion,
        &.open .select-accordion,
        .accordion-button:focus-visible + div .select-accordion,
        .select-accordion:focus-visible {
            opacity: 1;
        }
    `
);
const SelectAccordionItem = styled(Button)<{ opacity?: number }>(
    ({ theme, opacity }) => `
        padding: ${theme.spacing(0.1, 1.4)};
        margin-right: ${theme.spacing(1)};
        border-radius: 5rem;
        opacity: ${opacity};
        transition: ${theme.transitions.create(['opacity'], { duration: theme.transitions.duration.short })};
    `
);
const SelectedAccordionItem = styled(Typography)(
    ({ theme }) => `
        background: ${theme.colors.primary.main};
        color: ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(0.4, 1.4)};
        margin-right: ${theme.spacing(1)};
        border-radius: 5rem;
    `
);

interface Props {
    accordionHeader: JSX.Element | string;
    accordionId: string;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    hideFirstBorder?: boolean;
    hideLastBorder?: boolean;
    isLoading?: boolean;
    isSelected?: boolean;
    onSelection?: () => void;
}

const Accordion: React.FC<Props> = ({ accordionHeader, accordionId, openAccordionId, setOpenAccordionId, children, hideFirstBorder, hideLastBorder, isLoading, isSelected, onSelection }) => {
    const isOpen = isLoading ? false : accordionId === openAccordionId;

    return (
        <AccordionWrapper className={'accordion' + (isOpen ? ' open ' : '') + (hideFirstBorder ? ' hide-first ' : '') + (hideLastBorder ? ' hide-last ' : '')}>
            <Stack position="relative">
                <Button
                    className="accordion-button"
                    color="inherit"
                    onClick={() => {
                        setOpenAccordionId(isOpen ? null : accordionId);
                    }}
                    sx={{ maxWidth: '100%' }}
                    disabled={isLoading}
                >
                    {accordionHeader}
                    <KeyboardArrowDownTwoToneIcon className="arrow" sx={{ ml: 'auto' }} />
                </Button>
                {onSelection && (
                    <Stack position="absolute" top={0} bottom={0} justifyContent="center" right={45} sx={{ pointerEvents: isSelected ? 'none' : 'all' }}>
                        {isSelected ? (
                            <SelectedAccordionItem>Ausgewählt</SelectedAccordionItem>
                        ) : (
                            <SelectAccordionItem className="select-accordion" variant="outlined" opacity={isSelected ? 1 : 0} onClick={onSelection}>
                                Auswählen
                            </SelectAccordionItem>
                        )}
                    </Stack>
                )}
            </Stack>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </AccordionWrapper>
    );
};

export default Accordion;
