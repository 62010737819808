import { Suspense } from 'react';
import { RouteObject } from 'react-router';
import { matchRoutes } from 'react-router-dom';
import SuspenseLoader from 'src/components/utils/SuspenseLoader';
import routes, { ExtendedRouteObject } from 'src/router/routes';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { UserResource } from 'src/backend/market';
import store from 'src/redux/store';

export const ScrollToTopOnMobile = () => {
    const { pathname } = useLocation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (isSmallScreen) window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

export const getCurrentRouteConstant = () => {
    if (!routes) return;
    //@ts-ignore
    const foundRoutes = matchRoutes(routes as RouteObject[], window.location.pathname);
    if (!foundRoutes) return;
    const foundRoute: ExtendedRouteObject = foundRoutes[foundRoutes.length - 1]?.route;
    return foundRoute?.meta;
};

export const canUserAccessRoute = (route = window.location.pathname) => {
    const user: UserResource = store.getState().user?.currentUser;
    if (!user) return false;
    if (!routes) return true;
    //@ts-ignore
    const foundRoutes = matchRoutes(routes as RouteObject[], route);
    if (!foundRoutes) return true;

    // check if user has insufficient roles to access the route (including parent routes) --> starting with the top parent route down to the last sub route
    const isForbidden = foundRoutes.some((routeObject) => {
        const route: ExtendedRouteObject = routeObject.route;
        const requiredRoles = route.meta?.requiredRoles;
        const areRolesRequired = requiredRoles != null && requiredRoles.length > 0;
        if (!areRolesRequired) return false;

        const hasUserRequiredRole = requiredRoles.includes(user.userRoleResource.roleType);
        return !hasUserRequiredRole;
    });

    return !isForbidden;
};
