import {FC} from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Button,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";

interface IBookedPortalAccessDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose: () => void;
    hasError: boolean;
}

const BookedPortalAccessDialog: FC<IBookedPortalAccessDialogProps> = ({
                                                                          open, setOpen, onClose, hasError
                                                                      }) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClose = () => {
        setOpen(false);
    };


    return <Dialog open={open} onClose={onClose} onBackdropClick={handleClose} fullWidth={true} maxWidth={"md"} sx={{
        '& .MuiDialog-paper': {
            p: 2,
        }
    }}>
        <DialogTitle>
            <Typography
                variant={"h1"}>{hasError ? t('BookedPortalUnfinishedTitle') : t('BookedPortalFinishedTitle')}</Typography>
        </DialogTitle>
        <DialogContent sx={{mt: 3, mb: 7}}>
            <Typography variant={"subtitle1"}>{hasError ? t('BookedPortalUnfinishedSubtitle') : t('BookedPortalFinishedSubtitle')}</Typography>
            <Typography variant={"body1"}>{hasError ? t('BookedPortalUnfinishedText') : t('BookedPortalFinishedText')}</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color={hasError ? "secondary" : "primary"}
                    variant={isSmallScreen ? 'text' : 'contained'}
                    fullWidth
                    size={(isMediumScreen && !isSmallScreen) ? 'small' : 'medium'}>{t('okay')}</Button>
        </DialogActions>
    </Dialog>;
}

export default BookedPortalAccessDialog;
