import { Alert, Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomerTable from 'src/components/display/Tables/CustomerTable/CustomerTable';
import { ModuleRestControllerService } from 'src/backend/market';
import { useSelector } from 'src/redux/store';
import { refetchModuleManagement } from 'src/redux/thunks/constants.thunk';
import { slideInLeft } from 'src/utils/animationstyles';
import { nav } from 'src/statics/navigations';
import PageHeader from 'src/components/display/PageHeader';
import { startWalkthrough } from '../../../redux/thunks/guidethrough.thunk';

function Customers() {
    const navigate = useNavigate();
    const { regularCustomersPortal, regularCustomersPortalBookingInitiated } = useSelector((state) => state.constants.moduleManagement);
    const userID = useSelector((state) => state.user.currentUser.id);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if (!regularCustomersPortal) dispatch(startWalkthrough('clientConfiguration'));
    }, []);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleCellClicked = (cell) => navigate(nav.TOOLS.sub.CUSTOMERS.sub.DETAIL.path.replace(':id', cell.id));

    const handleBuyModule = async () => {
        await ModuleRestControllerService.bookRegularCustomerModule(userID);
        dispatch(refetchModuleManagement());
    };
    const renderBuyAlert = () => {
        return (
            <Alert
                sx={{ fontWeight: 'bold', my: 1 }}
                severity={'warning'}
                action={
                    <Button color={'warning'} onClick={handleBuyModule} disabled={regularCustomersPortalBookingInitiated}>
                        {t('freischalten')}
                    </Button>
                }
            >
                {regularCustomersPortalBookingInitiated ? t('waitingForActivation') : t('customersNotActivated')}
            </Alert>
        );
    };

    const handleBack = () => {
        navigate(nav.TOOLS.path);
    };

    return (
        <>
            <PageHeader handleBack={isSmallScreen && handleBack}>
                <Button size={isSmallScreen ? 'small' : 'large'} variant="contained" onClick={() => handleCellClicked({ id: 'new' })} sx={{ ml: 1 }}>
                    {t('kundenHinzufgen')}
                </Button>
            </PageHeader>

            <Box mt={{ md: 5, sm: 1, xs: 1 }}>
                {slideInLeft(
                    !regularCustomersPortal ? (
                        renderBuyAlert()
                    ) : (
                        <>
                            <CustomerTable isFullList requestCount={'all'} />
                        </>
                    )
                )}
            </Box>
        </>
    );
}

export default Customers;
