/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WizardDashboardStatisticResource } from '../models/WizardDashboardStatisticResource';
import type { WizardUserStatisticResource } from '../models/WizardUserStatisticResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WizardStatisticRestControllerService {

    /**
     * @param userId
     * @returns WizardUserStatisticResource OK
     * @throws ApiError
     */
    public static getWizardDelta(
        userId: number,
    ): CancelablePromise<WizardUserStatisticResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardStatistic/${userId}/delta`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static updateWizardDelta(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardStatistic/${userId}/delta`,
        });
    }

    /**
     * @param userId
     * @returns WizardUserStatisticResource OK
     * @throws ApiError
     */
    public static getWizardStatistic(
        userId: number,
    ): CancelablePromise<WizardUserStatisticResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardStatistic/${userId}`,
        });
    }

    /**
     * @param userId
     * @returns WizardDashboardStatisticResource OK
     * @throws ApiError
     */
    public static getWizardDashboardStatistic(
        userId: number,
    ): CancelablePromise<WizardDashboardStatisticResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardStatistic/${userId}/dashboard`,
        });
    }

    /**
     * @param wizardProfileKey
     * @returns WizardUserStatisticResource OK
     * @throws ApiError
     */
    public static getWizardStatisticByWizardProfileKey(
        wizardProfileKey: string,
    ): CancelablePromise<WizardUserStatisticResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardStatistic/profiles/${wizardProfileKey}`,
        });
    }

}