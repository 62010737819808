import { Typography, Stack, useTheme, styled } from '@mui/material';
import { useState } from 'react';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import { VariablesInTerm } from '../CalcEditor.types';
import CalcBuilderUsedVariablesItem from './CalcBuilderUsedVariablesItem';
import { VariableResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        .MuiButtonBase-root {
            font-size: 14px;
        }
    `
);

interface Props {
    variables: Array<VariableResource>;
    variablesInTerm: VariablesInTerm;
    saveVariable?: (variable: VariableResource) => void;
}

const CalcBuilderUsedVariables: React.FC<Props> = ({ variables, variablesInTerm, saveVariable }) => {
    const theme = useTheme();
    const [isOpen, setOpen] = useState<{ [type: string]: boolean }>({});

    const sortedVariables = Array.from(variablesInTerm.entries());

    return (
        <Stack>
            <Typography variant="h4" sx={{ fontSize: 20, mt: 7 }} gutterBottom>
                Verwendete Variablen
            </Typography>
            <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 3 }} variant="subtitle1">
                Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
            </Typography>
            {sortedVariables.length > 0 ? (
                <Stack gap={1}>
                    {sortedVariables.map(([variableName, details]) => (
                        <CalcBuilderUsedVariablesItem
                            key={variableName}
                            count={details.count}
                            variableName={variableName}
                            variable={details.variable}
                            variables={variables}
                            saveVariable={saveVariable}
                        />
                    ))}
                </Stack>
            ) : (
                <Stack bgcolor={theme.colors.alpha.black[7]} alignItems="center" justifyContent="center" height="8rem" fontSize={14} sx={{ opacity: 0.6, borderRadius: 1 }}>
                    <GridViewTwoToneIcon sx={{ fontSize: 28, opacity: 0.6 }} />
                    Keine Variablen verwendet
                </Stack>
            )}
        </Stack>
    );
};

export default CalcBuilderUsedVariables;
