export const EXCLUDED_EDITOR_FIELDS: string[] = ['gui.stueckzahl', 'gui.lieferdatum', 'gui.anlieferdatum'];
export const EXCLUDED_FRAGMENT_FIELD_NAMES: string[] = ['stueckzahl_fragment', 'laufmeter'];
export const DATE_EDITOR_FIELDS: string[] = ['gui.lieferdatum', 'gui.anlieferdatum'];
export const GEOMETRY_GUI_IDENTIFIER: string = 'gpkg.quader_konstruktion';
export const COLOR_GUI_IDENTIFIER: string = 'gui.farbe';
export const COLOR_SYSTEMS_GUI: string = 'farbSysteme';
export const RAL_GUI: string = 'RAL_color';
export const ALTERNATIVE_GUI: string = 'alternativeFarbangabe';
export const COLOR_SYSTEMS_RAL: string = 'ral';
export const COLOR_SYSTEMS_METALLIC: string = 'metallic';
export const COLOR_SYSTEMS_ALTERNATIVE: string = 'pulverVorgeben';
export const DELIVERY_DATE: string = 'lieferdatum';
export const BRING_DATE: string = 'anlieferdatum';
export const QUANTITY: string = 'stueckzahl';
export const MATERIAL: string = 'material';
export const PRICE_DISPLAY: string = 'priceDisplay';
export const PRICE_DISPLAY_TOTAL_PRICE: string = 'totalPrice';
export const UTILIZATION: string = 'auslastung';
export const DELIVERY_TIME: string = 'lieferzeit';

export const CONDITION_KEYS = ['neu', 'gebraucht', 'blank', 'entlackt', 'beschichtet', 'beschaedigt', 'rostig'];

export const DIMENSIONS = ['groessteLaenge', 'groessteBreite', 'groessteHoehe', 'zoll'];

export const EXCLUDED_PARAMETERS_FOR_AUTOSET: string[] = ['einzelgewicht', 'anwendung'];

export const NO_FRAGMENT: string = 'NO_FRAGMENT';
export const MAIN_FRAGMENT: string = 'MAIN';
export const SUB_FRAGMENT: string = 'SUB';
export const FRAGMENT_PARAMETER_SUFFIX: string = '_fragment';
export const FRAGMENT_QUANTITY: string = 'stueckzahl_fragment';
export const FRAGMENT_RUNNING_METER: string = 'laufmeter';
export const FRAGMENT_MATERIAL: string = 'material_fragment';

export const VALID: string = 'VALID';
export const INVALID: string = 'INVALID';
export const CREATED: string = 'CREATED';
export const IMPORTED: string = 'IMPORTED';
export const DIRTY: string = 'DIRTY';
export const ANALYZING: string = 'ANALYZING';

export const OBJECT_METADATA_PART_IDENTIFIER: string = 'main';
export const GEOMETRY_DIMENSION_LABEL: string = 'dimensionLabel';

export const VARIABLE_VALUE_BASE_PRICE: string = 'basePrice';
export const VARIABLE_VALUE_MASTER_PRICE: string = 'masterPrice';
export const VARIABLE_VALUE_CUSTOMER_PRICE: string = 'customerPrice';
export const VARIABLE_VALUE_POSITIONS_PRICE: string = 'subPricesSum';
export const VARIABLE_VALUE_GEOMETRY_POWDER: string = 'geometry_pulververbrauch';
export const VARIABLE_VALUE_GEOMETRY_WEIGHT: string = 'geometry_gewicht';
export const VARIABLE_VALUE_GEOMETRY_SURFACE: string = 'geometry_flaeche';
export const VARIABLE_VALUE_GEOMETRY_VOLUME: string = 'geometry_volumen';
export const VARIABLE_VALUE_DELIVERY_TIME: string = 'deliveryOption';
export const VARIABLE_VALUE_UTILIZATION: string = 'utilization';

export const CURRENCY_SETTING_NAME: string = 'currencySign';
export const NUMBER_FORMAT_SETTING_NAME: string = 'numberFormat';

export const HOLES: string = 'bohrungen';
export const HOLES_DIMENSIONS: string = 'holeDimensions';
export const THREADS: string = 'gewinde';
export const THREADS_DIMENSIONS: string = 'threadDimensions';

export const IMG_MIME_TYPES: string[] = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp', 'image/avif'];
export const PDF_MIME_TYPE: string = 'application/pdf';

export const INITIAL_DEMO_DIALOG: string = 'initialDemoDialog';

export const SPECIAL_GENERIC_ITEM: string = '$GENERIC';
export const SPECIAL_3D_ITEM: string = '$3D_ITEM';
export const TMP_LOADER_ITEM: string = '$TMP_LOADER';
export const TMP_LOADER_FRAGMENT: string = '$TMP_LOADER_FRAGMENT';

export const ALLOWED_GENERAL_FILE_TYPES = ['.dxf', '.dwg', '.pdf'];
export const ALLOWED_3D_FILE_TYPES = ['.step', '.stp', '.obj', '.igs', '.3dm', '.3ds', '.bim', '.stl'];

export const MathRegex = /^((\d([\.\,]\d?)?)[-+/*]?)*/g;

export const TMP_LOADER_CATEGORY: string = '$TMP_LOADER';
