import { Stack, Typography, useTheme } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { ANALYZING, DIRTY } from 'src/statics/statics';
import CalcOverviewGrid from './CalcOverviewGrid';
import CalcOverviewTable from './CalcOverviewTable';
import { deleteCalculation, duplicateCalculation, loadCalculations, updateSortModel } from 'src/redux/thunks/calculations.thunk';
import { AiMailConversationResource, WizardCalcMergeResource } from 'src/backend/market';
import { importInquiries } from 'src/redux/thunks/inquiries.thunk';
import useDialog from 'src/hooks/useDialog';
import { RootState } from 'src/redux/store';

interface Props {
    paging: { page: number; pageSize: number; total: number; rowCount: number };
    calculations: Array<WizardCalcMergeResource>;
    mailConversations: { [uniqueId: string]: AiMailConversationResource };
    isLoading: boolean;
    sort: GridSortModel;
    mode: String | null;
}

const CalcOverview: React.FC<Props> = ({ calculations, mailConversations, paging, isLoading, sort, mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dialog = useDialog();
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        dispatch(loadCalculations());
        const intervalId = setInterval(() => dispatch(loadCalculations()), 60000); // every minute

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleEditClicked = (row: WizardCalcMergeResource) => {
        if (row.calcId) {
            navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', row.uniqueId));
        } else {
            dispatch(
                importInquiries(row.wizardId, (internalId) => {
                    navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', internalId));
                })
            );
        }
    };

    const handleViewClicked = (row: WizardCalcMergeResource) => {
        if (row.calcId && row.status === ANALYZING) return;
        const basePath = row.calcId ? nav.CALCULATIONS.sub.VIEW.path : nav.WIZARD.sub.DETAIL.path;
        navigate(basePath.replace(':id', row.uniqueId));
    };

    const handleDuplicateClicked = (row: WizardCalcMergeResource) => {
        if (row.calcId && row.status === ANALYZING) return;
        if (row.calcId && row.status === DIRTY) {
            return dialog.openDialog({
                severity: 'info',
                okText: t('duplicateOldCalculation'),
                cancelText: t('doNotDuplicateOldCalculation'),
                component: <Typography>{t('duplicateOldCalculationExplanation')}</Typography>,
                title: t('duplicateOldCalculationExplanationTitle'),
                okCallback: () => {
                    dispatch(duplicateCalculation(row));
                    dialog.closeDialog();
                }
            });
        }

        dialog.openDialog({
            severity: 'info',
            okText: t('duplizieren'),
            cancelText: t('nichtDuplizieren'),
            component: <Typography>{t('bistDuSicherDasDuDieseKalkulationDuplizierenMchtes')}</Typography>,
            title: t('kalulationDuplizieren'),
            okCallback: async () => {
                dispatch(duplicateCalculation(row));
                dialog.closeDialog();
            }
        });
    };

    const handleDeleteClicked = (uniqueId: string) => {
        dialog.openDialog({
            severity: 'error',
            okText: t('lschen'),
            cancelText: t('nichtLschen'),
            component: <Typography>{t('bistDuSicherDassDuDieseKalkulationLschenMchtest')}</Typography>,
            title: t('kalulationLschen'),
            okCallback: () => {
                dispatch(deleteCalculation(uniqueId));
                dialog.closeDialog();
            }
        });
    };

    const handleSortModelChange = (sortModel: GridSortModel) => {
        dispatch(updateSortModel(sortModel));
    };

    return (
        <>
            {mode === 'gridView' ? (
                <CalcOverviewGrid
                    calculations={calculations}
                    paging={paging}
                    isLoading={isLoading}
                    mailConversations={mailConversations}
                    handleEditClicked={handleEditClicked}
                    handleViewClicked={handleViewClicked}
                    handleDuplicateClicked={handleDuplicateClicked}
                    handleDeleteClicked={handleDeleteClicked}
                />
            ) : (
                <Stack height="100%" minHeight="25rem">
                    <CalcOverviewTable
                        calculations={calculations}
                        paging={paging}
                        isLoading={isLoading}
                        sort={sort}
                        mailConversations={mailConversations}
                        handleEditClicked={handleEditClicked}
                        handleViewClicked={handleViewClicked}
                        handleDuplicateClicked={handleDuplicateClicked}
                        handleDeleteClicked={handleDeleteClicked}
                        handleSortModelChange={handleSortModelChange}
                    />
                </Stack>
            )}
        </>
    );
};
const mapStateToProps = (state: RootState) => ({
    calculations: state.calculations.calculations,
    mailConversations: state.calculations.mailConversations,
    paging: state.calculations.paging,
    isLoading: state.calculations.isLoading,
    sort: state.calculations.sort
});
export default connect(mapStateToProps)(CalcOverview);
