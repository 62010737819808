/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternalCalculationDashboardStatisticResource } from '../models/InternalCalculationDashboardStatisticResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InternalCalculationStatisticRestControllerService {

    /**
     * @param userId
     * @returns InternalCalculationDashboardStatisticResource OK
     * @throws ApiError
     */
    public static getInternalCalculationStatistic(
        userId: number,
    ): CancelablePromise<InternalCalculationDashboardStatisticResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/internalCalculations/statistics/${userId}/dashboard`,
        });
    }

}