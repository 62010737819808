import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { IntegerCalcParamResource } from 'src/backend/internalCalc';
import store from 'src/redux/store';
import { BRING_DATE, DELIVERY_DATE } from 'src/statics/statics';
import { wT } from 'src/utils/wizardTranslations';

interface Props {
    param: IntegerCalcParamResource;
    disabled?: boolean;
    isSmall?: boolean;
    createdAt?: string;
}

const DateParameter: React.FC<Props> = ({ param, disabled, isSmall, createdAt }) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext();
    const s = store.getState();
    //region STATE
    //endregion

    //region LIFECYCLE
    //endregion

    //region HELPER
    const onChangeDate = (e) => {
        const dateValue = e.target.value;
        const luxonDate = DateTime.fromFormat(dateValue, 'yyyy-MM-dd');
        const daysFromNow = Math.ceil(luxonDate.diffNow('days').days);
        setFieldValue(param.name, _.isNaN(daysFromNow) ? 0 : daysFromNow);
    };

    const getDateFromDays = (days: number) => {
        const luxonDate = DateTime.now().plus({ days });
        return luxonDate.toFormat('yyyy-MM-dd');
    };

    const minValue = useMemo(() => {
        return param.name === DELIVERY_DATE ? getDateFromDays(values[BRING_DATE]) : null;
    }, [values[BRING_DATE]]);

    const maxValue = useMemo(() => {
        return param.name === BRING_DATE ? getDateFromDays(values[DELIVERY_DATE]) : null;
    }, [values[DELIVERY_DATE]]);

    //endregion
    return (
        <TextField
            type="date"
            label={wT(param.name, s)}
            name={param.name}
            onChange={onChangeDate}
            value={getDateFromDays(values[param.name])}
            error={Boolean(errors[param.name])}
            onFocus={(e) => {
                e.target.select();
            }}
            title={wT(param.name, s)}
            fullWidth
            disabled={disabled}
            inputProps={{ sx: { outline: 'none' }, min: minValue, max: maxValue }}
            size={isSmall ? 'small' : 'medium'}
        />
    );
};
export default DateParameter;
