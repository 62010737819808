import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { CalculationFragmentResource, GuiStatusResource, IntegerCalcParamResource } from 'src/backend/internalCalc';
import _ from 'lodash';
import { renderParameters } from 'src/utils/CalcHelpers';
import { EXCLUDED_EDITOR_FIELDS, EXCLUDED_FRAGMENT_FIELD_NAMES, FRAGMENT_QUANTITY, FRAGMENT_RUNNING_METER } from 'src/statics/statics';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';


const SidewaysConnector = styled(Box)<{ last?: number }>(
    ({ theme, last }) => `
        position: absolute;
        left: -28px;
        top: -9px;
        height: ${last ? 'calc(50% + 12px)' : 'calc(100% + 9px)'};
        width: 3px;
        background-color: ${theme.colors.alpha.black[10]};
        @media print {
            background-color: ${theme.colors.alpha.black[50]};
        }
    `
);
const SidewaysHorizontalConnector = styled(Box)(
    ({ theme }) => `
        position: absolute;
        left: -31px;
        bottom: 50%;
        height: 3px;
        margin-left: 6px;
        width: calc(2rem - 5px);
        background-color: ${theme.colors.alpha.black[10]};
        transform: translateY(3px);
        @media print {
            background-color: ${theme.colors.alpha.black[50]};
        }
    `
);

interface Props {
    fragment?: CalculationFragmentResource;
    guiStates?: Array<GuiStatusResource>;
    renderParameter?: (label: string, value: string, key?: string, unit?: string) => JSX.Element;
    last?: boolean;
    numberFormat?: string;
}

export const FragmentSummary: React.FC<Props> = ({ fragment, guiStates, renderParameter, last, numberFormat }) => {
    const theme = useTheme();
    const s = store.getState();

    const parameters = useMemo(() => fragment?.fragmentParameters || [], [fragment?.id, fragment?.fragmentParameters]);

    const filteredParameters = useMemo(() => {
        return parameters.filter((parameter) => !EXCLUDED_EDITOR_FIELDS.includes(parameter.guiDescriptor) && !EXCLUDED_FRAGMENT_FIELD_NAMES.includes(parameter.name));
    }, [parameters]);
    
    const values: { [name: string]: string } = useMemo(() => fragment.setParameters.reduce((accumulator, param) => {
        accumulator[param.name] = param.value;
        return accumulator;
    }, {}), [fragment.setParameters]);

    const quantityParameter = useMemo(() => parameters.find((parameter) => parameter.name === FRAGMENT_QUANTITY), [parameters]);
    const runningMeterParameter = useMemo(() => parameters.find((parameter) => parameter.name === FRAGMENT_RUNNING_METER), [parameters]);
    
    const renderFragmentPrefix = () => {
        if(quantityParameter != null) {
            return values[quantityParameter.name] + ' ×';
        }
        else {
            const param = runningMeterParameter as IntegerCalcParamResource;
            return values[param?.name] + param?.unit;
        }
    }

    return <Stack direction="row" flexWrap="wrap" alignItems="center" position="relative">
        <SidewaysConnector last={last ? 1 : 0}/>
        <SidewaysHorizontalConnector/>
        <Stack direction="row" ml={0.8} flexWrap="wrap">
            <Typography fontSize="0.929em" lineHeight={1.4} noWrap mr={0.8} fontWeight="bold">
                {renderFragmentPrefix()} {wT(fragment.itemName, s)}:
            </Typography>
            {renderParameters(filteredParameters, values, guiStates, numberFormat, renderParameter)}
        </Stack>
    </Stack>
};
