import { Box, Divider, IconButton, Stack, styled, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SwapHorizTwoToneIcon from '@mui/icons-material/SwapHorizTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useTranslation } from 'react-i18next';
import { getImageFromFragment } from 'src/utils/wizardImages';
import { connect, useDispatch } from 'react-redux';
import ItemQuantity from './ItemQuantity';
import { wT } from 'src/utils/wizardTranslations';
import store, { RootState } from 'src/redux/store';
import { CalculationFragmentResource } from 'src/backend/internalCalc/models/CalculationFragmentResource';
import { EXCLUDED_EDITOR_FIELDS, EXCLUDED_FRAGMENT_FIELD_NAMES, FRAGMENT_QUANTITY, FRAGMENT_RUNNING_METER, TMP_LOADER_FRAGMENT } from 'src/statics/statics';
import { CalcValidator } from 'src/utils/CalcValidator';
import { Formik } from 'formik';
import { transformToCalculationParameter } from 'src/utils/CalcHelpers';
import { FormikAutoSave } from 'src/utils/FormikAutoSave';
import { ILayer } from 'src/backend/summary/resources/ILayer';
import ItemFragmentRunningMeter from './ItemFragmentRunningMeter';
import { SetObjectMetadataResource } from 'src/backend/internalCalc';
import { SidewaysConnector, SidewaysHorizontalConnector } from '../../ProCalc.styles';
import CalcMobileParameters from 'src/components/input/CalcInputs/CalcMobileParameters';
import CalcParameters from 'src/components/input/CalcInputs/CalcParameters';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        .mobile-parameters {
            flex-grow: 1;
            margin-right: ${theme.spacing(-6)};
        }

        @media screen and (max-width: 1200px) {
            img, .img-divider {
                display: none;
            }
        }
        @media screen and (max-width: 650px) {
            form {
                width: 100%;
            }
            form .mobile-parameters {
                flex-grow: 1;
                flex-shrink: 0;
                padding-left: 0;
                width: auto;
            }
            .mobile-parameters-display {
                display: none;
            }
        }
        @media screen and (min-width: 650px) and (max-width: 1200px), 
               screen and (max-width: 420px)
        {
            .fragment-info-inner-stack > .MuiBox-root > .MuiFormControl-root {
                max-width: 100%;
                width: auto;
            }
            .fragment-info-inner-stack > .MuiBox-root > .MuiFormControl-root > .MuiInputBase-root > .MuiInputAdornment-positionEnd > .MuiTypography-subtitle1 {
                display: none;
            }
        }
    `
);
const StyledImage = styled('img')(
    ({ theme }) => `
        max-width: 8rem;
        object-fit: contain;
        padding: 0 ${theme.spacing(3)};
        background: ${theme.colors.alpha.white[100]};
        border-radius: ${theme.general.borderRadius};
        transition: ${theme.transitions.create(['transform'], { duration: theme.transitions.duration.short })};
        &:hover {
            transform: scale(3);
            z-index: 100;
            box-shadow: ${theme.header.boxShadow}
        }
    `
);

const ParametersWrapper = styled('div')(
    ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-grow: 1;
        gap: ${theme.spacing(2.5)} ${theme.spacing(3)};
        padding: ${theme.spacing(2)};
        max-width: 100%;

        & > *,
        .MuiFormControl-root,
        .MuiOutlinedInput-root,
        .dimensions-infos {
            width: 13rem;
            min-width: 0;
        }
    `
);

interface Props {
    fragment?: CalculationFragmentResource;
    partId?: number;
    last?: boolean;
    secondLayerItems: Array<ILayer>;
    metadata?: Array<SetObjectMetadataResource>;
}

const ItemFragment: React.FC<Props> = ({ fragment, partId, last, secondLayerItems, metadata }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [name, setName] = useState<string>(wT(fragment?.itemName, s));

    const parameters = useMemo(() => fragment?.fragmentParameters || [], [fragment?.id, fragment?.fragmentParameters]);

    const filteredMetadata = useMemo(() => {
        return (metadata || []).filter((data) => data.geometryPackageIdentificationKey === fragment.geometryPackageIdentificationKey);
    }, [metadata]);

    const filteredParameters = useMemo(() => {
        return parameters.filter((parameter) => !EXCLUDED_EDITOR_FIELDS.includes(parameter.guiDescriptor) && !EXCLUDED_FRAGMENT_FIELD_NAMES.includes(parameter.name));
    }, [fragment?.id, fragment?.fragmentParameters]);

    const setParameters = fragment?.setParameters || [];
    const filteredSetParameters = useMemo(() => (setParameters || []).filter((parameter) => parameter.name !== FRAGMENT_QUANTITY), [fragment?.id, setParameters]);

    const imgUrl = useMemo(() => getImageFromFragment(fragment), [fragment?.id, fragment?.fragmentParameters]);

    const availableSubGeometryPackages = useMemo(() => {
        return secondLayerItems.find((item) => item.id === fragment.itemId)?.availableSubGeometryPackagesForFragmentation || [];
    }, [fragment?.id, fragment?.itemId, secondLayerItems]);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const hasQuantity = useMemo(() => parameters.some((parameter) => parameter.name === FRAGMENT_QUANTITY), [parameters]);
    const hasRunningMeter = useMemo(() => parameters.some((parameter) => parameter.name === FRAGMENT_RUNNING_METER), [parameters]);

    const getDefaultParams = useCallback(() => {
        const defaultParams = {};
        [...setParameters].forEach((param) => {
            defaultParams[param.name] = param.value;
        });
        return defaultParams;
    }, [fragment?.id, partId, fragment?.fragmentParameters, setParameters]);

    const saveParams = (params) => {
        const newParams = transformToCalculationParameter(params);
        dispatch(updateProCalc(ProCalcUpdateTypes.FRAGMENT_PARAMETERS, { partId, fragment: fragment, parameters: newParams }));
    };

    const duplicate = () => {
        dispatch(updateProCalc(ProCalcUpdateTypes.DUPLICATE_FRAGMENT, { partId, fragmentId: fragment.id }));
    };
    const remove = () => {
        dispatch(updateProCalc(ProCalcUpdateTypes.DELETE_FRAGMENT, { partId, fragmentId: fragment.id }));
    };

    const handleChangeGeometry = () => {
        const index = availableSubGeometryPackages.findIndex((geometryPackage) => geometryPackage.pkg === fragment.geometryPackage && geometryPackage.name === fragment.geometryPackageName);
        const newGeometryPackage = index === 0 ? availableSubGeometryPackages[1] : availableSubGeometryPackages[0];
        dispatch(
            updateProCalc(ProCalcUpdateTypes.FRAGMENT_GEOMETRY_PACKAGE, {
                partId,
                fragment: {
                    ...fragment,
                    geometryPackage: newGeometryPackage.pkg,
                    geometryPackageName: newGeometryPackage.name
                }
            })
        );
    };

    const renderGeometrySwitcher = (isSmall?: boolean) => {
        return (
            <>
                <Tooltip title={t('changeGeometryPackageOfFragment')}>
                    <IconButton sx={isSmall ? { ml: 0.5, mt: -0.5 } : { ml: 0.5, mr: -1 }} onClick={handleChangeGeometry} size={isSmall ? 'small' : 'medium'}>
                        <SwapHorizTwoToneIcon sx={{ fontSize: isSmall ? '18px' : '20px' }} />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <StyledStack direction="row" alignItems="center" position="relative" borderLeft={'1px solid ' + theme.colors.alpha.black[10]}>
            <SidewaysConnector last={last ? 1 : 0} />
            <SidewaysHorizontalConnector />
            {fragment.itemName === TMP_LOADER_FRAGMENT ? (
                <Stack sx={{ p: 2, minHeight: '2rem' }}>{t('loadingFragment')}</Stack>
            ) : (
                <>
                    <StyledImage src={imgUrl} alt={t('imageOfItem')} />

                    <Divider flexItem orientation="vertical" className="img-divider" />

                    <Stack direction={{ sm: 'row' }} alignItems="center" flexGrow={1} overflow="hidden">
                        <Stack p={2}>
                            <Typography variant="h5" noWrap maxWidth="100%">
                                {name}
                            </Typography>
                            <Box display="flex" maxWidth="100%" pt={hasRunningMeter ? 1 : 0}>
                                {hasQuantity && <ItemQuantity partId={partId} parameters={parameters} setParameters={fragment.setParameters} fragment={fragment} isSmall />}
                                {hasRunningMeter && <ItemFragmentRunningMeter partId={partId} parameters={parameters} setParameters={fragment.setParameters} fragment={fragment} />}
                                {!isSmallScreen && availableSubGeometryPackages.length > 1 && renderGeometrySwitcher()}
                            </Box>
                        </Stack>

                        <Divider flexItem orientation="vertical" />

                        <Formik
                            validateOnChange
                            validateOnMount
                            validateOnBlur={false}
                            validationSchema={CalcValidator.buildParameterValidator(t, filteredParameters, filteredSetParameters, [])}
                            initialValues={getDefaultParams()}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                saveParams(values);
                            }}
                        >
                            <ParametersWrapper>
                                {isSmallScreen ? (
                                    <CalcMobileParameters
                                        id={partId}
                                        guiStates={[]}
                                        parameters={filteredParameters}
                                        dialogTitle={
                                            <Box display="flex" maxWidth="100%">
                                                <Typography variant="h4" noWrap>
                                                    {name} - {t('fragment')}
                                                </Typography>
                                                {availableSubGeometryPackages.length > 1 && renderGeometrySwitcher(true)}
                                            </Box>
                                        }
                                    />
                                ) : (
                                    <CalcParameters id={partId} partId={partId} guiStates={[]} parameters={filteredParameters} metadata={filteredMetadata} isSmall />
                                )}
                                <FormikAutoSave />
                            </ParametersWrapper>
                        </Formik>

                        <Stack direction="row" alignItems="center" sx={{ p: 2 }} className="actions">
                            <Tooltip title={t('delete')}>
                                <IconButton sx={{ ml: 'auto', mr: 0.5 }} color="secondary" onClick={remove}>
                                    <DeleteTwoToneIcon sx={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('duplicate')}>
                                <IconButton color="primary" onClick={duplicate}>
                                    <ContentCopyTwoToneIcon sx={{ fontSize: '16px' }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </>
            )}
        </StyledStack>
    );
};

const mapStateToProps = (state: RootState) => ({
    secondLayerItems: state.proCalc.secondLayerItems
});
export default connect(mapStateToProps)(ItemFragment);
