import React, {useState} from "react"
import {connect, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import InitialDemoUserDialog from "../../components/overlays/InitialDemoUserDialog/InitialDemoUserDialog";
import { UserResource } from "src/backend/market";
import { INITIAL_DEMO_DIALOG } from "src/statics/statics";

type DemoTokenProps = {
    userId?: number;
    initializedAlreadyClicked?: boolean;
}

const InitialDemoUserRoute: React.FC<{ children? }> = ({children}) => {
    const user: UserResource = useSelector((state: RootState) => state.user.currentUser);
    const userId: number | undefined = useSelector((state: RootState) => user?.id);
    const TOKEN = "initializedAlreadyClicked";
    const getToken = (): DemoTokenProps => JSON.parse(localStorage.getItem(TOKEN)) as DemoTokenProps;
    const isCompleted = (user?.completedWalkthroughs || []).some((walkthrough) => walkthrough.walkthroughName === INITIAL_DEMO_DIALOG);
    const isInitializedAlreadyClicked = (): boolean => (userId === getToken()?.userId && getToken()?.initializedAlreadyClicked) || isCompleted;
    const isDemonstrationAccount: boolean = useSelector((state: RootState) => user?.demonstrationAccount);

    //region STATE
    const [initializedAlreadyClicked, setInitializedAlreadyClicked] = useState(isInitializedAlreadyClicked());
    //endregion

    //region Handler
    const handleInitializedAlreadyClicked = () => {
        if (!userId || !isDemonstrationAccount) {
            return;
        }

        localStorage.setItem(TOKEN, JSON.stringify({
            userId: userId,
            initializedAlreadyClicked: true
        } as DemoTokenProps));
    }
    //endregion


    return <>
        {
            (!initializedAlreadyClicked && isDemonstrationAccount) &&
            <InitialDemoUserDialog open={!initializedAlreadyClicked} onClose={null}
                                   onRead={handleInitializedAlreadyClicked}/>
        }
        {children}
    </>
}
const mapStateToProps = (state: RootState) => {
    return {
        user: state.user.currentUser
    }
}
export default connect(mapStateToProps)(InitialDemoUserRoute)