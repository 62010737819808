import {GridSortModel} from "@mui/x-data-grid";
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import _ from "lodash";
import {
    CustomerTypeResource,
    RegularCustomerFilterBoundariesResource,
    RegularCustomerFilterDto,
    RegularCustomerResource
} from "../../backend/market";

//INTERFACE AND INITIAL STATE
interface CustomerReducerState {
    customers: { [key: string]: RegularCustomerResource };
    types: { [key: number]: CustomerTypeResource };
    updatedAt: string | null;
    loading: boolean;
    customersTablePaging: {
        page: number;
        pageSize: number;
        fetchedPages: number[];
        rowCount: number;
    }

    customerFilter: RegularCustomerFilterDto,
    filterBoundary: RegularCustomerFilterBoundariesResource;
    customerSortingModel: GridSortModel;

}

const initFilters : RegularCustomerFilterDto = {
    searchString: null,
    customerTypeIds: [],
    inquiryCount: [{minInquiryCountIncl: 0, maxInquiryCountIncl: 0}],
    salesVolume: [{minSalesVolumeIncl: 0, maxSalesVolumeIncl: 0}],
};

const initialState: CustomerReducerState = {
    customers: {},
    types: {},
    loading: true,
    updatedAt: null,
    customersTablePaging: {
        page: 0,
        pageSize: 0,
        fetchedPages: [],
        rowCount: 0,
    },

    customerFilter: initFilters,
    filterBoundary: {},
    customerSortingModel: []
};

//HELPER FUNCTIONS
const reduceCustomerArrayToMap = (customers: RegularCustomerResource[]): { [key: string]: RegularCustomerResource } => {
    return customers.reduce((acc, customer) => {
        return {...acc, [customer.id+customer.companyName]: customer};
    }, {} as { [key: string]: RegularCustomerResource });
};

const reduceTypesToArray = (types: CustomerTypeResource[]) => {
    return types.reduce((acc, customer) => {
        return {...acc, [customer.id]: customer};
    }, {} as { [key: number]: RegularCustomerResource });
}

//REDUCERS
const reducers = {
    //LOADING
    setLoading: (state: CustomerReducerState, action: PayloadAction<boolean>) => {
        return Object.assign({}, state, {loading: action.payload});
    },
    //CUSTOMER CRUD
    replace: (state: CustomerReducerState, action: PayloadAction<RegularCustomerResource[]>) => {
        const newCustomers = reduceCustomerArrayToMap(action.payload);
        state.updatedAt = new Date().toISOString();
        state.customers = newCustomers;
        state.loading = false;
        return state;
    },
    replaceTypes: (state: CustomerReducerState, action: PayloadAction<CustomerTypeResource[]>) => {
        const newTypes = reduceTypesToArray(action.payload);
        state.updatedAt = new Date().toISOString();
        state.types = newTypes;
        state.loading = false;
        return state;
    },
    updateCustomer: (state: CustomerReducerState, action: PayloadAction<RegularCustomerResource>) => {
        const customer = action.payload;
        state.updatedAt = new Date().toISOString();
        state.customers[customer.id+customer.companyName] = customer;
        state.loading = false;
        return state;
    },
    update: (state: CustomerReducerState, action: PayloadAction<RegularCustomerResource[]>) => {
        const newCustomers = reduceCustomerArrayToMap(action.payload);
        const cState = {...state};
        cState.updatedAt = new Date().toISOString();
        cState.loading = false;
        cState.customers = {...cState.customers, ...newCustomers};
        return cState;
    },
    delete: (state: CustomerReducerState, action: PayloadAction<number>) => {
        state.updatedAt = new Date().toISOString();
        const customer = _.values(state.customers).find(c => c.id === action.payload);
        delete state.customers[customer.id+customer.companyName];
    },
    add: (state: CustomerReducerState, action: PayloadAction<RegularCustomerResource>) => {
        state.updatedAt = new Date().toISOString();
        const customer = action.payload;
        state.customers[customer.id+customer.companyName] = action.payload;
    },
    //CUSTOMER CUSTOM REDUCERS
    updateStatus: (state: CustomerReducerState, action: PayloadAction<{ id: number, status: string }>) => {
        const customer = _.values(state.customers).find(c => c.id === action.payload.id);
        state.customers[customer.id+customer.companyName].state = action.payload.status;
    },
    //PAGING
    updatePageSize: (state: CustomerReducerState, action: PayloadAction<{ pageSize: number }>) => {
        state.customersTablePaging = {...state.customersTablePaging, ...action.payload};
    },
    updatePage: (state: CustomerReducerState, action: PayloadAction<{ page: number }>) => {
        state.customersTablePaging = {...state.customersTablePaging, ...action.payload};
    },
    addFetchedPage: (state: CustomerReducerState, action: PayloadAction<number>) => {
        state.customersTablePaging = {
            ...state.customersTablePaging,
            fetchedPages: [...state.customersTablePaging.fetchedPages, action.payload]
        };
    },
    updateRowCount: (state: CustomerReducerState, action: PayloadAction<number>) => {
        state.customersTablePaging = {...state.customersTablePaging, rowCount: action.payload};
    },
    resetSeenPages: (state: CustomerReducerState) => {
        state.customersTablePaging.fetchedPages = [];
    },

    //FILTERS
    updateFilter: (state: CustomerReducerState, action: PayloadAction<RegularCustomerFilterDto>) => {
        state.customerFilter = action.payload;
    },
    updateBoundary: (state: CustomerReducerState, action: PayloadAction<RegularCustomerFilterBoundariesResource>) => {
        state.filterBoundary = action.payload;
    },

    //SORTING
    updateSorting: (state: CustomerReducerState, action: PayloadAction<GridSortModel>) => {
        state.customerSortingModel = action.payload;
    },
}

//EXPORTS
export const slice = createSlice({
    name: 'customers',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
