import { Stack, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PriceChangeTwoToneIcon from '@mui/icons-material/PriceChangeTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import CalcBuilderDialog from '../CalcBuilder/CalcBuilderDialog';
import { useMemo, useState } from 'react';
import InputFieldsDialog from '../InputFields/InputFieldsDialog';
import SurchargesDialog from '../Surcharges/SurchargesDialog';
import { useDispatch } from 'react-redux';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { BlueprintResource, InputVariableResource, TermResource, VariableResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        & > .MuiButtonBase-root {
            flex-direction: column;
        }
        & > .MuiButtonBase-root > .MuiButton-startIcon {
            margin: 4px 0;
            margin-bottom: 2px;
        }
        & > .MuiButtonBase-root > .MuiButton-startIcon > .MuiSvgIcon-root {
            font-size: 24px;
        }
        
    `
);

interface Props {
    blueprint: BlueprintResource;
}

const MasterCalcButtons: React.FC<Props> = ({ blueprint }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCalcBuilderOpen, setCalcBuilderOpen] = useState<boolean>(false);
    const [isSurchargesDialogOpen, setSurchargesDialogOpen] = useState<boolean>(false);
    const [isInputFieldsDialogOpen, setInputFieldsDialogOpen] = useState<boolean>(false);

    const inputSections = useMemo(() => {
        const sections: { [section: string]: Array<InputVariableResource> } = {};
        (blueprint.masterVariables || []).forEach((variable) => {
            if (variable.type !== VariableResource.type.INPUT_VARIABLE) return;
            const section = (variable as InputVariableResource).attributes?.section;
            if (!sections[section]) sections[section] = [];
            sections[section].push(variable);
        });
        return sections;
    }, [blueprint.masterVariables]);

    const createVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CREATE_MASTER_VARIABLE, variable));
    };
    const saveVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.MASTER_VARIABLE, variable));
    };
    const removeVariable = (variableId: number) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_MASTER_VARIABLE, variableId));
    };

    const saveCalculationBasis = (name: string, term: TermResource) => {
        setCalcBuilderOpen(false);
        dispatch(updateBlueprint(BlueprintUpdateTypes.MASTER_CALCULATION_BASIS, { ...blueprint.calculationBasis, term }));
    };

    return (
        <StyledStack gap={2} direction="row" alignItems="center">
            <Button sx={{ mx: -1 }} color="secondary" startIcon={<PriceChangeTwoToneIcon />} onClick={() => setCalcBuilderOpen(true)}>
                Kalkulation
            </Button>
            {blueprint?.calculationBasis?.term && blueprint?.masterVariables && (
                <CalcBuilderDialog
                    hideName
                    term={blueprint.calculationBasis.term}
                    variables={blueprint.masterVariables}
                    isOpen={isCalcBuilderOpen}
                    setOpen={setCalcBuilderOpen}
                    onSave={saveCalculationBasis}
                    createVariable={createVariable}
                    saveVariable={saveVariable}
                    removeVariable={removeVariable}
                />
            )}

            <Button sx={{ mx: -1 }} color="secondary" startIcon={<LocalOfferTwoToneIcon />} onClick={() => setSurchargesDialogOpen(true)}>
                Aufschläge
            </Button>
            <SurchargesDialog variables={blueprint?.masterVariables} isOpen={isSurchargesDialogOpen} setOpen={setSurchargesDialogOpen} createVariable={createVariable} saveVariable={saveVariable} />

            <Button sx={{ mx: -1 }} color="secondary" startIcon={<RuleTwoToneIcon />} onClick={() => setInputFieldsDialogOpen(true)}>
                Eingabefelder
            </Button>
            <InputFieldsDialog
                variables={blueprint?.masterVariables}
                inputSections={inputSections}
                isOpen={isInputFieldsDialogOpen}
                setOpen={setInputFieldsDialogOpen}
                createVariable={createVariable}
                saveVariable={saveVariable}
                removeVariable={removeVariable}
            />
        </StyledStack>
    );
};

export default MasterCalcButtons;
