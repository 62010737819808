import { Box, Button, Card, CircularProgress, ClickAwayListener, Collapse, Divider, IconButton, Stack, styled, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useTranslation } from 'react-i18next';
import { CalculationPartResource } from 'src/backend/internalCalc';
import { CalculationResultResource } from 'src/backend/market';
import { getImageFromPart } from 'src/utils/wizardImages';
import { connect, useDispatch } from 'react-redux';
import ItemQuantity from './ItemQuantity';
import ItemGeneralMessages from './ItemGeneralMessages';
import ItemSurcharges from './ItemSurcharges';
import ItemCalculatedValues from './ItemCalculatedValues';
import { wT } from 'src/utils/wizardTranslations';
import store, { RootState } from 'src/redux/store';
import ItemFragments from './ItemFragments';
import { HOLES, MAIN_FRAGMENT, QUANTITY, THREADS } from 'src/statics/statics';
import ItemAttachments from './ItemAttachments';
import ItemPrice from './ItemPrice';
import ItemSections from './ItemSections';
import ItemPdfViewerDialog from './ItemPdfViewerDialog';
import ItemDxfViewerDialog from './ItemDxfViewerDialog';
import Item3dViewerDialog from './Item3dViewerDialog';
import ItemShowButton from './ItemShowButton';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { ProCalcUpdateLoading } from 'src/redux/slices/proCalc.reducer';
import ItemNotes from './ItemNotes';

const Header = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: auto 1fr auto;
    `
);
const IconButtonEdit = styled(IconButton)(
    ({ theme }) => `
        margin-top: -1px;
        margin-right: ${theme.spacing(0.5)};
        padding: ${theme.spacing(0.8)};
  
        .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(15)};
        }
    `
);
const Center = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        min-height: 20rem;
        max-height: 60rem;
    `
);
const Footer = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        align-items: center;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
        overflow: auto;
    `
);

const StyledImageWrapper = styled(Stack)(
    ({ theme }) => `
        position: relative;
        max-width: 20rem;
        width: 100%;
        justify-content: center;
        padding: ${theme.spacing(1)};

        ${theme.breakpoints.down('xl')} {
            max-width: 14rem;
        }
    `
);
const StyledImage = styled('img')(
    ({ theme }) => `
        height: 100%;
        width: 100%;
        object-fit: contain;
    `
);

interface Props {
    costResult?: CalculationResultResource;
    part?: CalculationPartResource;
    isUpdateLoading?: ProCalcUpdateLoading;
    showUnitPrice?: boolean;
    showCompact?: boolean;
    viewerDetails?: {
        dialogType?: 'PDF' | '3D' | 'DXF';
        previous?: CalculationPartResource;
        current?: CalculationPartResource;
        next?: CalculationPartResource;
    };
    setViewer?: (viewer: { part: CalculationPartResource; dialogType: 'PDF' | '3D' | 'DXF' }) => void;
}

const Item: React.FC<Props> = ({ costResult, part, isUpdateLoading, showUnitPrice, showCompact, viewerDetails, setViewer }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [name, setName] = useState<string>(part.userDefinedPartName || wT(part.itemName, s));
    const [newName, setNewName] = useState<string>(name);
    const [isRenaming, setRename] = useState<boolean>(false);

    const imgUrl = useMemo(() => {
        if (part.thumbnailUrl) return part.thumbnailUrl;
        return getImageFromPart(part);
    }, [part?.calculationParameters]);

    const partCostResult = useMemo(() => {
        return (costResult?.subCalculationResults || []).find((x) => x.additionalInformation === part.subCalculationIdentificationKey);
    }, [costResult]);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setNewName(event.target.value);
    };

    const handleKeypress = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    };

    const handleRename = async (): Promise<void> => {
        setName(newName);
        dispatch(updateProCalc(ProCalcUpdateTypes.PART_NAME, { partId: part.id, name: newName }));
        setRename(false);
    };

    const removeItem = () => {
        dispatch(updateProCalc(ProCalcUpdateTypes.DELETE_PART, part.id));
    };

    const duplicateItem = () => {
        dispatch(updateProCalc(ProCalcUpdateTypes.DUPLICATE_PART, part.id));
    };

    const quantity = useMemo(() => {
        const param = part?.setParameters || [];
        const value = param.find((parameter) => parameter.name === QUANTITY)?.value;
        if (value) return parseInt(value);
        return 1;
    }, [part?.setParameters]);

    const fileExtensionOfFile = part?.dataSourceOriginalFileName ? part.dataSourceOriginalFileName.split('.').pop() : null;

    const itemMessages = useMemo(() => {
        const partMessages = partCostResult?.messages || [];
        if (!part.threeDFileId) {
            const holes = part.setParameters.find((param) => param.name === HOLES)?.value;
            const threads = part.setParameters.find((param) => param.name === THREADS)?.value;
            if (holes === '0' && threads === '0') {
                return [...partMessages, { messageId: 'zeroOpeningWarning', messageType: 'WARNING', involvedParameters: [] }];
            }
        }
        return partMessages;
    }, [partCostResult?.messages]);

    const getAvailableDialogType = (part: CalculationPartResource, desiredDialogType?: 'PDF' | '3D' | 'DXF') => {
        if (desiredDialogType === 'PDF' && part.pdfFileId) return 'PDF';
        if (desiredDialogType === '3D' && part.threeDFileId) return '3D';
        if (desiredDialogType === 'DXF' && part.dxfFileId) return 'DXF';
        return part.pdfFileId ? 'PDF' : part.threeDFileId ? '3D' : 'DXF';
    };

    const selectNext = (desiredDialogType?: 'PDF' | '3D' | 'DXF') => {
        setViewer({ part: viewerDetails.next, dialogType: getAvailableDialogType(viewerDetails.next, desiredDialogType) });
    };
    const selectPrevious = (desiredDialogType?: 'PDF' | '3D' | 'DXF') => {
        setViewer({ part: viewerDetails.previous, dialogType: getAvailableDialogType(viewerDetails.previous, desiredDialogType) });
    };

    useEffect(() => {
        if (!part?.userDefinedPartName) return;
        if (part.userDefinedPartName !== name) {
            setName(part.userDefinedPartName);
            setNewName(part.userDefinedPartName);
        }
    }, [part?.userDefinedPartName]);

    return (
        <Card sx={{ overflow: 'hidden' }}>
            <Header className="item-header" alignItems="center" px={2} py={1} overflow="auto !important" maxWidth="100%">
                <ItemQuantity
                    partId={part.id}
                    itemId={part.itemId}
                    parameters={part?.calculationParameters?.allParameters}
                    setParameters={part?.setParameters}
                    isSmall={isSmallScreen}
                    additionalStorage={part.additionalStorage}
                />

                <Stack direction="row" alignItems="center" ml={2}>
                    {isRenaming ? (
                        <ClickAwayListener onClickAway={handleRename}>
                            <TextField
                                value={newName}
                                size="small"
                                onBlur={handleRename}
                                onChange={handleChange}
                                onKeyDown={handleKeypress}
                                variant="outlined"
                                sx={{ m: 0 }}
                                inputProps={{ sx: { px: 1, py: 0.5 }, maxLength: 50 }}
                            />
                        </ClickAwayListener>
                    ) : (
                        <Typography
                            id="piecesAndNameStack"
                            variant={isSmallScreen ? 'h5' : 'h4'}
                            mb={isSmallScreen && '-2px'}
                            component="span"
                            onClick={() => {
                                setRename(true);
                            }}
                            noWrap
                        >
                            {name}
                        </Typography>
                    )}
                    <Box display="flex" alignItems="center" pl={0.2}>
                        <Tooltip arrow placement="top" title={t('rename')}>
                            <IconButtonEdit onClick={() => setRename(true)}>
                                <EditTwoToneIcon color="primary" />
                            </IconButtonEdit>
                        </Tooltip>
                    </Box>
                </Stack>

                <Stack direction="row" alignItems="center" gap={2}>
                    <ItemPrice part={part} costResult={partCostResult} quantity={quantity} showUnitPrice={showUnitPrice} />

                    {showCompact && <ItemShowButton costResult={costResult} part={part} showUnitPrice={showUnitPrice} viewerDetails={viewerDetails} setViewer={setViewer} />}
                </Stack>
            </Header>

            <Collapse in={!showCompact} timeout="auto" unmountOnExit>
                <Stack>
                    <Divider />

                    <Center className="item-center" direction="row" id={'allInfosOfProduct'}>
                        <StyledImageWrapper>
                            {imgUrl && (
                                <>
                                    <StyledImage src={imgUrl} alt={t('imageOfItem')} />
                                    {part.threeDFileId && <Button onClick={() => setViewer({ part, dialogType: '3D' })}>{t('openIn3dViewer')}</Button>}
                                    {part.pdfFileId && <Button onClick={() => setViewer({ part, dialogType: 'PDF' })}>{t('openInPdfViewer')}</Button>}
                                    {part.dxfFileId && <Button onClick={() => setViewer({ part, dialogType: 'DXF' })}>{t('openInDxfViewer')}</Button>}
                                </>
                            )}
                            {fileExtensionOfFile && (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        position: 'absolute',
                                        right: theme.spacing(1),
                                        top: theme.spacing(1),
                                        bgcolor: theme.colors.info.dark,
                                        color: theme.colors.alpha.white[100],
                                        py: 0.1,
                                        px: 0.4,
                                        borderRadius: 1
                                    }}
                                >
                                    .{fileExtensionOfFile}
                                </Typography>
                            )}
                        </StyledImageWrapper>

                        <Divider flexItem orientation="vertical" />

                        <ItemSections part={part} partCostResult={partCostResult} />

                        <Divider flexItem orientation="vertical" />

                        <ItemCalculatedValues costResult={costResult} part={part} partCostResult={partCostResult} quantity={quantity} />
                    </Center>

                    <Divider />

                    {part.geometryPackageFragmentType === MAIN_FRAGMENT && (
                        <>
                            <ItemFragments partId={part.id} fragments={part.fragments} metadata={part.calculationParameters.objectMetadataResources} />
                            <Divider />
                        </>
                    )}

                    <Footer className="item-footer">
                        <Stack direction="row" id={'attachmentsAndMoreBox'} gap={isSmallScreen ? 0.5 : 2}>
                            <ItemAttachments partId={part.id} attachments={part.attachments} isSmall={isSmallScreen} />
                            {partCostResult?.surchargeReport?.length > 0 && (
                                <ItemSurcharges subCalculationIdentificationKey={part.subCalculationIdentificationKey} surchargeReport={partCostResult.surchargeReport} isSmall={isSmallScreen} />
                            )}
                            {itemMessages.length > 0 && <ItemGeneralMessages messages={itemMessages} isSmall={isSmallScreen} />}
                            {part.notes && <ItemNotes notes={part.notes} isSmall={isSmallScreen} />}
                        </Stack>

                        <Stack id={'deleteDuplicateButtons'} direction="row" ml="auto">
                            <Button sx={{ px: 1.2, py: 0.5 }} startIcon={<DeleteTwoToneIcon />} variant="text" color="secondary" onClick={removeItem} size={isSmallScreen ? 'small' : 'medium'}>
                                {t('delete')}
                            </Button>
                            <Button
                                sx={{ px: 1.2, py: 0.5 }}
                                disabled={isUpdateLoading[ProCalcUpdateTypes.DUPLICATE_PART]}
                                startIcon={isUpdateLoading[ProCalcUpdateTypes.DUPLICATE_PART] ? <CircularProgress color="inherit" size={20} sx={{ p: 0.25 }} /> : <ContentCopyTwoToneIcon />}
                                variant="text"
                                onClick={duplicateItem}
                                size={isSmallScreen ? 'small' : 'medium'}
                            >
                                {t('duplicate')}
                            </Button>
                        </Stack>
                    </Footer>
                </Stack>
            </Collapse>

            <Item3dViewerDialog
                is3dViewer={viewerDetails?.dialogType === '3D' && viewerDetails?.current?.id === part.id}
                threeDFileId={viewerDetails?.current?.threeDFileId}
                close3dViewer={() => setViewer(null)}
                hasPrevious={!!viewerDetails?.previous}
                hasNext={!!viewerDetails?.next}
                selectPrevious={() => selectPrevious('3D')}
                selectNext={() => selectNext('3D')}
            />

            <ItemPdfViewerDialog
                isPdfViewerOpen={viewerDetails?.dialogType === 'PDF' && viewerDetails?.current?.id === part.id}
                closePdfViewer={() => setViewer(null)}
                part={part}
                costResult={costResult}
                partCostResult={partCostResult}
                quantity={quantity}
                hasPrevious={!!viewerDetails?.previous}
                hasNext={!!viewerDetails?.next}
                selectPrevious={() => selectPrevious('3D')}
                selectNext={() => selectNext('3D')}
            />

            <ItemDxfViewerDialog
                isDxfViewerOpen={viewerDetails?.dialogType === 'DXF' && viewerDetails?.current?.id === part.id}
                closeDxfViewer={() => setViewer(null)}
                part={part}
                costResult={costResult}
                partCostResult={partCostResult}
                quantity={quantity}
                hasPrevious={!!viewerDetails?.previous}
                hasNext={!!viewerDetails?.next}
                selectPrevious={() => selectPrevious('3D')}
                selectNext={() => selectNext('3D')}
            />
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isUpdateLoading: state.proCalc.isUpdateLoading
    };
};
export default connect(mapStateToProps)(Item);
