import { Box, Button, Card, ClickAwayListener, Divider, IconButton, Stack, styled, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import store, { RootState } from 'src/redux/store';
import ItemCalculatedValues from 'src/components/pro-calc/Positions/Item/ItemCalculatedValues';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import InputFieldsDialog from '../InputFields/InputFieldsDialog';
import Surcharges from '../Surcharges/Surcharges';
import CategoryCalculationBasis from './CategoryCalculationBasis';
import { listVariablesInTerm } from 'src/utils/TermHelper';
import { updateBlueprint, BlueprintUpdateTypes } from 'src/redux/shared/calcEditor.common';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import CategorySections from './CategorySections';
import { CategoryResource, InputVariableResource, VariableResource } from 'src/backend/coreCalc';

const Header = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: auto auto 1fr auto;
    `
);
const IconButtonEdit = styled(IconButton)(
    ({ theme }) => `
        margin-top: -1px;
        margin-right: ${theme.spacing(0.5)};
        padding: ${theme.spacing(0.8)};
  
        .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(15)};
        }
    `
);
const Center = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        min-height: 20.2rem;
        max-height: 60rem;
    `
);
const Footer = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        align-items: center;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
        overflow: auto;
    `
);

interface Props {
    category?: CategoryResource;
    showUnitPrice?: boolean;
}

const Category: React.FC<Props> = ({ category, showUnitPrice }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const inputFieldsRef = useRef<any>(null);
    const [name, setName] = useState<string>('Meine Kategorie');
    const [newName, setNewName] = useState<string>(name);
    const [isRenaming, setRename] = useState<boolean>(false);
    const [isCalcDialogOpen, setCalcDialogOpen] = useState<boolean>(false);
    const [isInputFieldsDialogOpen, setInputFieldsDialogOpen] = useState<boolean>(false);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const calcRelevantVariableNames = useMemo(() => {
        const variables = new Set<string>();
        category.calculationBases.forEach((calculationBasis) => {
            const variablesInTerm = listVariablesInTerm(calculationBasis.term, category.variables);
            for (const variableName of variablesInTerm.keys()) {
                variables.add(variableName);
            }
        });
        return [...variables];
    }, [category.calculationBases, category.variables]);

    const inputSections = useMemo(() => {
        const sections: { [section: string]: Array<InputVariableResource> } = {};
        category.variables.forEach((variable) => {
            if (variable.type !== VariableResource.type.INPUT_VARIABLE) return;
            const section = (variable as InputVariableResource).attributes.section;
            if (!sections[section]) sections[section] = [];
            sections[section].push(variable);
        });
        return sections;
    }, [category.variables]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setNewName(event.target.value);
    };

    const handleKeypress = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    };

    const handleRename = async (): Promise<void> => {
        setName(newName);
        // dispatch(updateUserDefinedPartName(part.id, newName));
        setRename(false);
    };

    const removeCategory = () => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_CATEGORY, category.id));
    };

    const duplicateCategory = () => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DUPLICATE_CATEGORY, category.id));
    };

    const createVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CREATE_CATEGORY_VARIABLE, { categoryId: category.id, variable }));
    };
    const saveVariable = (variable: VariableResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, { categoryId: category.id, variable }));
    };
    const removeVariable = (variableId: number) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.DELETE_CATEGORY_VARIABLE, { categoryId: category.id, variableId }));
    };

    useEffect(() => {
        if (!category?.name) return;
        if (category.name !== name) {
            setName(category.name);
            setNewName(category.name);
        }
    }, [category?.name]);

    const usedCalcBasis = category.calculationBases.find((calcBasis) => calcBasis.id === category.calculationBasisId);
    const price = 0;

    return (
        <Card sx={{ overflow: 'hidden' }}>
            <Header alignItems="center" px={2} py={1} overflow="auto !important" maxWidth="100%">
                <div></div>
                {/* <ItemQuantity partId={category.id} itemId={category.id} parameters={category?.calculationParameters?.allParameters} setParameters={category?.setParameters} isSmall={isSmallScreen} /> */}
                <Stack direction="row" alignItems="center" ml={2}>
                    {isRenaming ? (
                        <ClickAwayListener onClickAway={handleRename}>
                            <TextField
                                value={newName}
                                size="small"
                                onBlur={handleRename}
                                onChange={handleChange}
                                onKeyDown={handleKeypress}
                                variant="outlined"
                                sx={{ m: 0 }}
                                inputProps={{ sx: { px: 1, py: 0.5 }, maxLength: 50 }}
                            />
                        </ClickAwayListener>
                    ) : (
                        <Typography
                            id="piecesAndNameStack"
                            variant={isSmallScreen ? 'h5' : 'h4'}
                            mb={isSmallScreen && '-2px'}
                            component="span"
                            onClick={() => {
                                setRename(true);
                            }}
                            noWrap
                        >
                            {name}
                        </Typography>
                    )}
                    <Box display="flex" alignItems="center" pl={0.2}>
                        <Tooltip arrow placement="top" title={t('rename')}>
                            <IconButtonEdit onClick={() => setRename(true)}>
                                <EditTwoToneIcon color="primary" />
                            </IconButtonEdit>
                        </Tooltip>
                    </Box>
                </Stack>

                <CategoryCalculationBasis
                    category={category}
                    calcRelevantVariableNames={calcRelevantVariableNames}
                    createVariable={createVariable}
                    saveVariable={saveVariable}
                    removeVariable={removeVariable}
                />

                <Typography color="primary" variant="h3">
                    € {price}
                </Typography>
                {/* <ItemPrice part={category} costResult={categoryCostResult} quantity={1} showUnitPrice={showUnitPrice} /> */}
            </Header>

            <Stack>
                <Divider />
                <Center direction="row">
                    <Stack p={3} justifyContent="center" width="23rem">
                        {/* <Button
                            startIcon={<AddBoxTwoToneIcon />}
                            sx={{ flexDirection: 'column', '.MuiButton-startIcon': { margin: ' 4px 0', marginBottom: '2px' }, '.MuiButton-startIcon > .MuiSvgIcon-root': { fontSize: 24 } }}
                        >
                            Teil hinzufügen
                        </Button> */}
                        <Typography variant="h4" gutterBottom>
                            Teile
                        </Typography>
                        <Typography variant="subtitle2" fontSize={14} lineHeight={1.2} mb={0.5}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                        </Typography>

                        <Button size="small" sx={{ alignSelf: 'flex-start', ml: '-12px' }} startIcon={<AddBoxTwoToneIcon />}>
                            Teil hinzufügen
                        </Button>
                    </Stack>
                    <Divider flexItem orientation="vertical" />
                    <Stack style={{ display: 'flex', width: '100%' }}>
                        <CategorySections variables={category.variables} inputSections={inputSections} calcRelevantVariableNames={calcRelevantVariableNames} saveVariable={saveVariable} />
                        <Divider sx={{ mt: 'auto' }} />
                        <Button ref={inputFieldsRef} sx={{ mx: 'auto' }} size="small" startIcon={<RuleTwoToneIcon />} onClick={() => setInputFieldsDialogOpen(true)}>
                            Eingabefelder anpassen
                        </Button>
                        <InputFieldsDialog
                            variables={category.variables}
                            inputSections={inputSections}
                            calcRelevantVariableNames={calcRelevantVariableNames}
                            isOpen={isInputFieldsDialogOpen}
                            setOpen={setInputFieldsDialogOpen}
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    </Stack>
                    <Divider flexItem orientation="vertical" />
                    <ItemCalculatedValues costResult={{}} part={{}} partCostResult={{}} quantity={1} />
                </Center>
                <Divider />
                <Footer>
                    <Stack direction="row" gap={isSmallScreen ? 0.5 : 2}>
                        <Surcharges variables={category.variables} createVariable={createVariable} saveVariable={saveVariable} />
                        {/* {itemMessages.length > 0 && <ItemGeneralMessages messages={itemMessages} isSmall={isSmallScreen} />} */}
                    </Stack>

                    <Stack id={'deleteDuplicateButtons'} direction="row" ml="auto">
                        <Button sx={{ px: 1.2, py: 0.5 }} startIcon={<DeleteTwoToneIcon />} variant="text" color="secondary" onClick={removeCategory} size={isSmallScreen ? 'small' : 'medium'}>
                            {t('delete')}
                        </Button>
                        <Button sx={{ px: 1.2, py: 0.5 }} startIcon={<ContentCopyTwoToneIcon />} variant="text" onClick={duplicateCategory} size={isSmallScreen ? 'small' : 'medium'}>
                            {t('duplicate')}
                        </Button>
                    </Stack>
                </Footer>
            </Stack>
        </Card>
    );
};

const mapStateToProps = (state: RootState) => {
    return {};
};
export default connect(mapStateToProps)(Category);
