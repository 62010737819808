import {AlertTitle} from "@mui/lab";
import {Alert} from "@mui/material";
import React from "react"
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {BoughtModulesResource} from "../../../backend/market";
import {RootState} from "../../../redux/store";

const InviteUsersCard: React.FC<{ module: BoughtModulesResource }> = ({module}) => {
    //region STATE
    const {t} = useTranslation()
    //endregion

    //region LIFECYCLE 
    //endregion

    //region RENDER
    //endregion
    if (!module) return <span/>;
    const allowedUsers = module.allowedUsers.maxAllowedCount - module.allowedUsers.currentCount
    if (allowedUsers <= 0 && module.allowedUsers.furtherUsersAllowed) {
        return <span/>
    }
    if (module.allowedUsers.furtherUsersAllowed) {
        return <Alert variant={"standard"} severity={"info"}
        >
            <AlertTitle sx={{fontWeight: "bold"}}>
                {t('duKannstNochBenutzerEinladen', {allowedUsers: allowedUsers})}
            </AlertTitle>
        </Alert>
    } else {
        return <Alert variant={"standard"} severity={"warning"}
        >
            <AlertTitle sx={{fontWeight: "bold"}}>
                {t('duKannstKeineWeiterenBenutzerEinladen')}
            </AlertTitle>
        </Alert>
    }
}
const mapStateToProps = (state: RootState) => {
    return {
        module: state.constants.moduleManagement
    }
}
export default connect(mapStateToProps)(InviteUsersCard)