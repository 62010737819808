import { Stack, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculationPartResource, CalculationResultResource } from 'src/backend/internalCalc';
import store, { RootState, useDispatch } from 'src/redux/store';
import { Formik, FormikProps } from 'formik';
import { CalcValidator } from 'src/utils/CalcValidator';
import { connect } from 'react-redux';
import { getSections, transformToCalculationParameter } from 'src/utils/CalcHelpers';
import { OBJECT_METADATA_PART_IDENTIFIER } from 'src/statics/statics';
import { FormikAutoSave } from 'src/utils/FormikAutoSave';
import { Section } from 'src/utils/CalcHelpers';
import CalcSection from 'src/components/input/CalcInputs/CalcSection';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

interface Props {
    part: CalculationPartResource;
    disablePartValidation: boolean;
    holeRecognitionLoaders: { [partId: number]: boolean };
    partCostResult?: CalculationResultResource;
}

const ItemSections: React.FC<Props> = ({ part, disablePartValidation, holeRecognitionLoaders, partCostResult }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const s = store.getState();
    const formRef = useRef<FormikProps<{}>>();
    const forceUpdate = useForceUpdate();
    const selectedSectionId = useRef<string>();
    const [isInitialSubmit, setInitialSubmit] = useState(false);
    const [defaultParamsRerenderNumber, setDefaultParamsRerenderNumber] = useState(1);

    const sections: Array<Section> = useMemo(() => getSections(part.calculationParameters?.allParameters, part.calculationParameters?.sectionDefinitions), [part.calculationParameters]);
    const setParameters = part?.setParameters || [];

    const filteredMetadata = useMemo(() => {
        return (part?.calculationParameters?.objectMetadataResources || []).filter((data) => data.geometryPackageIdentificationKey === OBJECT_METADATA_PART_IDENTIFIER);
    }, [part?.calculationParameters?.objectMetadataResources]);

    const getDefaultParams = useCallback(() => {
        const defaultParams = {};
        [...setParameters].forEach((param) => {
            defaultParams[param.name] = param.value;
        });
        return defaultParams;
    }, [part?.id, holeRecognitionLoaders[part?.id], setParameters, defaultParamsRerenderNumber]);

    const saveParams = (params) => {
        const newParams = transformToCalculationParameter(params);
        dispatch(updateProCalc(ProCalcUpdateTypes.PART_PARAMETERS, { partId: part.id, itemId: part.itemId, parameters: newParams }));
    };

    const checkParamValueChange = () => {
        if (!formRef.current || !partCostResult?.parameterValueChangeCommands || partCostResult.parameterValueChangeCommands.length < 1) return;
        setInitialSubmit(true);
        partCostResult.parameterValueChangeCommands.forEach((param) => {
            formRef.current.setFieldValue(param.parameterName, param.newValue);
        });
        setDefaultParamsRerenderNumber(defaultParamsRerenderNumber + 1);
    };

    useEffect(() => {
        if (!sections) return;
        selectedSectionId.current = sections[0]?.id;
    }, []);

    useEffect(() => {
        setTimeout(() => checkParamValueChange(), 100);
    }, [partCostResult?.parameterValueChangeCommands]);

    useEffect(() => {
        if (!formRef.current || holeRecognitionLoaders[part?.id]) return;
        formRef.current.resetForm();
    }, [holeRecognitionLoaders[part?.id]]);

    return (
        <Formik
            key={part.id}
            validateOnChange
            validateOnMount
            validateOnBlur={false}
            validationSchema={disablePartValidation ? null : CalcValidator.buildPartValidator(t, part, partCostResult)}
            initialValues={getDefaultParams()}
            enableReinitialize={true}
            innerRef={formRef}
            onSubmit={(values) => {
                if (!isInitialSubmit) return setInitialSubmit(true);
                saveParams(values);
            }}
        >
            <form style={{ display: 'flex', width: '100%' }}>
                <Stack width="100%" maxWidth="100%">
                    {sections.map((section) => (
                        <CalcSection
                            key={part.id + section.id}
                            part={part}
                            partCostResult={partCostResult}
                            metadata={filteredMetadata}
                            section={section}
                            selectedSectionId={selectedSectionId.current}
                            setSelectedSectionId={(newId) => {
                                selectedSectionId.current = newId;
                                forceUpdate();
                            }}
                        />
                    ))}
                </Stack>

                <FormikAutoSave />
            </form>
        </Formik>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        disablePartValidation: state.proCalc.disablePartValidation,
        holeRecognitionLoaders: state.proCalc.holeRecognitionLoaders
    };
};
export default connect(mapStateToProps)(ItemSections);

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    return React.useCallback(() => {
        setTick((tick) => tick + 1);
    }, []);
}
