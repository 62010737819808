import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface ILanguageReducer {
    languageCodes: string[];
    allIso3166LanguageCodes: string[];
}

const initialState: ILanguageReducer = {
    languageCodes: [],
    allIso3166LanguageCodes: []
}

const reducers = {
    setLanguageCodes: (state: ILanguageReducer, action: PayloadAction<string[]>) => {
        state.languageCodes = action.payload
    },
    setAllIso3166LanguageCodes: (state: ILanguageReducer, action: PayloadAction<string[]>) => {
        state.allIso3166LanguageCodes = action.payload
    },
}

export const slice = createSlice({
    name: 'language',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;