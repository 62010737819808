import { Box, Card, CircularProgress, Collapse, Stack, Typography, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { CalculationPartResource, CalculationResultResource, InternalCalculationResource } from 'src/backend/internalCalc';
import PaletteTwoToneIcon from '@mui/icons-material/PaletteTwoTone';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import { CalculatedValues, renderCalculatedValue } from 'src/components/display/CalculatedValues';
import { DelayedRenderOfComponent } from 'src/utils/DelayRenderOfComponent';
import SummarizeCalcBar from '../SummaryBar/SummarizeCalcBar';
import { COLOR_SYSTEMS_GUI, MATERIAL, PRICE_DISPLAY, PRICE_DISPLAY_TOTAL_PRICE, TMP_LOADER_ITEM, VARIABLE_VALUE_POSITIONS_PRICE } from 'src/statics/statics';
import store, { RootState } from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { formatPrice } from 'src/utils/FormatHelpers';
import Item from './Item/Item';
import ItemSelector from './ItemSelector/ItemSelector';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';

const CalculatedValuesWrapper = styled(Box)(
    ({ theme }) => `
        display: grid;
        gap: ${theme.spacing(2)};
        max-width: 100%;
        overflow: hidden;
        justify-content: space-between;
        grid-template-columns: repeat(6, auto);
        margin-left: auto;
        text-align: left;
        font-size: 0.9rem;

        .calculated-value {
            max-width: 15rem;
        }

        .MuiAvatar-root {
            color: ${theme.colors.secondary.light};
            background-color: ${theme.colors.secondary.lighter};
        }

        ${theme.breakpoints.down('md')} {
            grid-template-columns: repeat(3, auto);
        }
        ${theme.breakpoints.down('sm')} {
            grid-template-columns: repeat(2, auto);
        }
        
        @media print {
            grid-template-columns: repeat(6, auto);
        }
    `
);

interface Props {
    calculation?: InternalCalculationResource;
}

const Positions: React.FC<Props> = ({ calculation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const [isOpen, setOpen] = useState<boolean>(true);
    const [viewer, setViewer] = useState<{ part: CalculationPartResource; dialogType: 'PDF' | '3D' | 'DXF' }>();

    const parts = useMemo(() => {
        return calculation?.parts || [];
    }, [calculation?.parts]);

    const partsWithFile = useMemo(() => (calculation.parts || []).filter((part) => part.threeDFileId || part.dxfFileId || part.pdfFileId), [calculation.parts]);
    const viewerDetails = useMemo(() => {
        if (!viewer) return {};
        const currentIndex = partsWithFile.findIndex((part) => part.id === viewer.part.id);

        return {
            dialogType: viewer.dialogType,
            previous: partsWithFile[currentIndex - 1],
            current: viewer.part,
            next: partsWithFile[currentIndex + 1]
        };
    }, [partsWithFile, viewer]);

    const delay = parts.length > 10 ? 700 : parts.length > 5 ? 300 : 0;

    const calculatedPartValues = useMemo(() => {
        const materials = new Set();
        const colors = new Set();

        parts.forEach((part) => {
            const values = {};
            (part.setParameters || []).forEach((param) => (values[param.name] = param.value));
            materials.add(wT(values[MATERIAL], s));
            const colorSystem = values[COLOR_SYSTEMS_GUI];
            const color = values[colorSystem];
            colors.add(`${wT(color, s)} (${wT(colorSystem, s)})`);
        });

        return {
            materials: [...materials].join(', '),
            colors: [...colors].join(', ')
        };
    }, [parts]);

    const calculatedValues = useMemo(() => {
        if (!calculation.costResult) return;
        return {
            powder: calculation.costResult.totalAmountOfPowder,
            weight: calculation.costResult.totalWeight,
            surface: calculation.costResult.surface?.value,
            volume: calculation.costResult.totalVolume
        };
    }, [calculation.costResult]);

    const showUnitPrice = useMemo(() => {
        const masterParameter = calculation?.masterCalculationSetParameters || [];
        const foundParameter = masterParameter.find(({ name }) => name === PRICE_DISPLAY);
        return foundParameter?.value === PRICE_DISPLAY_TOTAL_PRICE ? false : true;
    }, [calculation?.masterCalculationSetParameters]);

    const positionsPrice = useMemo(() => {
        if (!calculation.costResult || !calculation.costResult.variableValues) return;
        const positions = calculation.costResult.variableValues.find((value) => value.name === VARIABLE_VALUE_POSITIONS_PRICE);
        return positions?.value;
    }, [calculation.costResult]);

    const renderLoaderItem = (key?: any) => (
        <Card key={key} sx={{ p: 2, minHeight: '25rem' }}>
            <div style={{textAlign: 'center'}}><br/><br/>
                <CircularProgress />
                <Typography mt={2} variant="h5">
                    {t('loadingItem')}
                </Typography>
            </div>
        </Card>
    );

    return (
        calculation && (
            <Stack>
                <HeadlineButton isOpen={isOpen} setOpen={setOpen} variant="page">
                    {t('positions')}

                    <CalculatedValuesWrapper>
                        {calculatedPartValues.colors && renderCalculatedValue(<PaletteTwoToneIcon />, t('colors'), calculatedPartValues.colors)}
                        {calculatedPartValues.materials && renderCalculatedValue(<ViewInArTwoToneIcon />, t('materials'), calculatedPartValues.materials)}
                        <CalculatedValues calculatedValues={calculatedValues} costResult={calculation.costResult} />
                    </CalculatedValuesWrapper>

                    {positionsPrice != null && (
                        <Stack ml="auto">
                            <Typography color="primary" fontSize={20}>
                                {formatPrice(positionsPrice, true)}
                            </Typography>
                        </Stack>
                    )}
                </HeadlineButton>

                <Stack mb={3} flexGrow={1} gap={isOpen ? 4 : 2}>
                    {parts.map((part, index) =>
                        part.itemName === TMP_LOADER_ITEM ? (
                            renderLoaderItem(index)
                        ) : (
                            <DelayedRenderOfComponent
                                key={part.id}
                                wait={index * delay}
                                component={
                                    <Item costResult={calculation.costResult} part={part} showUnitPrice={showUnitPrice} viewerDetails={viewerDetails} setViewer={setViewer} showCompact={!isOpen} />
                                }
                                loadingComponent={renderLoaderItem()}
                            />
                        )
                    )}
                    <ItemSelector activate3dUpload={true} />
                </Stack>

                <SummarizeCalcBar
                    costResult={calculation.costResult}
                    parts={parts}
                    masterAttachments={calculation?.calculationMetadata?.attachments}
                    uniqueId={calculation?.uniqueId}
                    validationStatus={status}
                    showUnitPrice={showUnitPrice}
                />
            </Stack>
        )
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(Positions);
