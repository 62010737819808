import { Typography, Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useState } from 'react';
import CreateCustomCategoryDialog from './CreateCustomCategoryDialog';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import TextureTwoToneIcon from '@mui/icons-material/TextureTwoTone';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import ViewModuleTwoToneIcon from '@mui/icons-material/ViewModuleTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import LinearScaleTwoToneIcon from '@mui/icons-material/LinearScaleTwoTone';
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';
import HorizontalRuleTwoToneIcon from '@mui/icons-material/HorizontalRuleTwoTone';
import ViewWeekTwoToneIcon from '@mui/icons-material/ViewWeekTwoTone';
import CallMadeTwoToneIcon from '@mui/icons-material/CallMadeTwoTone';
import CreateNewFolderTwoToneIcon from '@mui/icons-material/CreateNewFolderTwoTone';
import TemplateSelectionItem from 'src/components/display/TemplateSelectionItem/TemplateSelectionItem';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { connect, useDispatch } from 'react-redux';
import { CategoryDTO, CategoryResource, CategoryTemplateResource, NewCategoryDTO, TemplateCollectionResource, TemplateResource, TermDTO, VariableDTO } from 'src/backend/coreCalc';
import { RootState } from 'src/redux/store';

interface Props {
    templateCollection: TemplateCollectionResource;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
}

const CreateCategoryDialog: React.FC<Props> = ({ templateCollection, isOpen, setOpen }) => {
    const dispatch = useDispatch();
    const [isCustomCategoryDialog, setCustomCategoryDialog] = useState<boolean>(false);
    const customCategory: TemplateResource = { id: 'customCategory', name: 'Eigene Kategorie', description: 'Wert aus dem Eingabefeld', isHighlight: true, icon: 'CreateNewFolderTwoTone' };

    const createCategory = (template: CategoryTemplateResource) => {
        dispatch(updateBlueprint(BlueprintUpdateTypes.CREATE_CATEGORY, template));
    };

    return (
        <>
            <Dialog open={isOpen} onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle sx={{ pt: 3.1, pb: 2.7 }}>
                    <Typography variant="h4">Kategorie erstellen</Typography>
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
                        {(templateCollection?.categoryTemplates || []).map((template) => (
                            <TemplateSelectionItem
                                key={template.name}
                                onClick={() => {
                                    setOpen(false);
                                    createCategory(template);
                                }}
                                template={template}
                            />
                        ))}
                        <TemplateSelectionItem
                            onClick={() => {
                                setOpen(false);
                                setCustomCategoryDialog(true);
                            }}
                            template={customCategory}
                        />
                        {/* <TemplateSelectionItem
                            onClick={() => {
                                setOpen(false);
                                for (let index = 0; index < 83; index++) {
                                    createCategory(categoryTemplates[0]);
                                }
                            }}
                            template={{ ...categoryTemplates[0], name: '83 Kategorien' }}
                        /> */}
                    </Box>
                </DialogContent>
            </Dialog>

            <CreateCustomCategoryDialog isOpen={isCustomCategoryDialog} setOpen={setCustomCategoryDialog} onCreate={() => createCategory(customCategory)} />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        templateCollection: state.templates.collection
    };
};
export default connect(mapStateToProps)(CreateCategoryDialog);
