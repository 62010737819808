import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    styled,
    Tooltip
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { t } from 'i18next';
import DragAndDropFileInput from '../DragAndDropFileInput';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import { useDispatch } from 'react-redux';
import { AttachmentResource } from 'src/backend/market';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import { IMG_MIME_TYPES, PDF_MIME_TYPE } from 'src/statics/statics';
import { loadAttachmentPaths } from 'src/redux/thunks/proCalc.thunk';

const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        padding-right: ${theme.spacing(4)};
        &.uploadError .MuiListItemText-secondary,
        &.unsupportedExtensionError .MuiListItemText-secondary {
            color: ${theme.colors.error.main};
        }
        .MuiTypography-root {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    `
);

export interface Attachment {
    attachmentId?: string;
    originalFileName?: string;
    fileTypeAsMimeType?: string;
    fileUrl?: string;
    isImageLoaded?: boolean;
}

interface Props {
    attachments: Array<AttachmentResource>;
    addAttachment: Function;
    removeAttachment: Function;
    handleWrongFileExtension?: Function;
}

const AttachmentsInput: React.FC<Props> = ({ attachments = [], addAttachment, removeAttachment, handleWrongFileExtension }) => {
    const dispatch = useDispatch();
    const [errorAttachments, setErrorAttachments] = useState<Array<AttachmentResource>>([]);
    const [openedAttachment, setOpenedAttachment] = useState<Attachment>(null);
    const [isDownloading, setDownloading] = useState<boolean>(false);

    const handleFileUpload = (fileList: FileList) => {
        const files = Array.from(fileList);
        if (!files) return;

        files.forEach((file) => {
            const fileName = file.name.toLowerCase();
            if (!fileName.endsWith('.pdf') && !fileName.endsWith('.jpg') && !fileName.endsWith('.png')) {
                return handleWrongFileExtension(file);
            }
            addAttachment(file);
        });
    };
    const handleDownloadAttachment = async (attachment: AttachmentResource) => {
        setDownloading(true);
        const paths = await dispatch(loadAttachmentPaths(attachment.attachmentId));
        const originalPath = paths.find((path) => path.fileVariant === 'ORIGINAL');
        if (!originalPath?.path) return;

        const response = await fetch(originalPath?.path, {
            headers: {
                'Content-Type': attachment.fileTypeAsMimeType
            }
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.originalFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setDownloading(false);
    };

    const downloadAll = () => {
        filteredAttachments.forEach((attachment) => {
            handleDownloadAttachment(attachment);
        });
    };

    const handleRemoveAttachment = (attachment: AttachmentResource) => {
        removeAttachment(attachment.attachmentId);
    };

    const openAttachment = async (attachment: AttachmentResource) => {
        setOpenedAttachment(attachment);
        const paths = await dispatch(loadAttachmentPaths(attachment.attachmentId));
        const originalPath = paths.find((path) => path.fileVariant === 'ORIGINAL');
        setTimeout(() => {
            setOpenedAttachment((currentAttachment) => {
                // trick to get updated openedAttachment
                if (!!currentAttachment) setOpenedAttachment({ ...currentAttachment, fileUrl: originalPath?.path, isImageLoaded: false });
                return currentAttachment;
            });
        }, 1000);
    };

    const isUploading = (attachment: AttachmentResource) => {
        return attachment.attachmentId === 'uploadLoading';
    };
    const hasError = (attachment: AttachmentResource) => {
        return attachment.attachmentId === 'unsupportedExtensionError';
    };
    const getAttachmentStatus = (attachment: AttachmentResource) => {
        if (isUploading(attachment) || hasError(attachment)) return attachment.attachmentId;
        return null;
    };
    const getAttachmentStatusText = (attachment: AttachmentResource) => {
        const status = getAttachmentStatus(attachment);
        if (status) return t(status);
        return null;
    };
    const filteredAttachments = useMemo(() => {
        return attachments.filter((attachment) => !isUploading(attachment) && !hasError(attachment));
    }, [attachments]);

    return (
        <>
            {attachments?.length > 0 && (
                <>
                    <Divider sx={{ my: 2, mb: 1.5 }}></Divider>
                    <List disablePadding sx={{ maxHeight: '50rem', mx: -2, p: 0, pb: 1.5, overflow: 'auto' }}>
                        {attachments.map((attachment, index) => (
                            <ListItem
                                key={index}
                                sx={{ p: 0 }}
                                secondaryAction={
                                    <>
                                        <IconButton
                                            edge="end"
                                            aria-label="download"
                                            sx={{ mr: -0.25 }}
                                            onClick={() => handleDownloadAttachment(attachment)}
                                            disabled={isDownloading || isUploading(attachment) || hasError(attachment)}
                                        >
                                            {isDownloading ? <CircularProgress color="inherit" size="24px" sx={{ p: '3px' }} /> : <FileDownloadTwoToneIcon />}
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" sx={{ mr: -0.5 }} onClick={() => handleRemoveAttachment(attachment)} disabled={isUploading(attachment)}>
                                            <DeleteTwoToneIcon />
                                        </IconButton>
                                    </>
                                }
                            >
                                <Tooltip title={attachment.originalFileName}>
                                    <ListItemButton sx={{ pl: 2.5, borderRadius: 0 }} onClick={() => openAttachment(attachment)} disabled={getAttachmentStatus(attachment) != null}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <InsertDriveFileTwoToneIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <StyledListItemText primary={attachment.originalFileName} secondary={getAttachmentStatusText(attachment)} className={getAttachmentStatus(attachment)} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                        {filteredAttachments?.length > 2 && (
                            <ListItem sx={{ p: 0 }}>
                                <Button size="small" color="inherit" variant="outlined" sx={{ m: 'auto' }} onClick={downloadAll}>
                                    Download all
                                </Button>
                            </ListItem>
                        )}
                    </List>
                </>
            )}
            <DragAndDropFileInput style={{ marginTop: attachments?.length > 0 ? 0 : 18 }} fileSelected={handleFileUpload} />

            <Dialog
                onClose={() => setOpenedAttachment(null)}
                open={!!openedAttachment}
                maxWidth="xl"
                PaperProps={openedAttachment?.fileTypeAsMimeType === PDF_MIME_TYPE ? { sx: { width: '100%', height: '100%' } } : {}}
            >
                {!!openedAttachment && (
                    <>
                        <DialogTitle sx={{ pr: 7 }}>
                            {openedAttachment.originalFileName}
                            <IconButton aria-label="close" onClick={() => setOpenedAttachment(null)} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                                <CloseTwoToneIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{ overflow: 'hidden', minHeight: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {openedAttachment.fileUrl ? (
                                IMG_MIME_TYPES.includes(openedAttachment.fileTypeAsMimeType) ? (
                                    <img
                                        src={openedAttachment?.fileUrl}
                                        width="100%"
                                        onLoad={() => {
                                            setOpenedAttachment({ ...openedAttachment, isImageLoaded: true });
                                        }}
                                    />
                                ) : openedAttachment.fileTypeAsMimeType === PDF_MIME_TYPE ? (
                                    <object data={openedAttachment?.fileUrl} type={openedAttachment.fileTypeAsMimeType} width="100%" height="100%">
                                        <a href={openedAttachment?.fileUrl}>{openedAttachment?.fileUrl}</a>
                                    </object>
                                ) : (
                                    <Button href={openedAttachment?.fileUrl} target="_blank" download={true} variant="contained" color="primary">
                                        {t('downloadFile')}
                                    </Button>
                                )
                            ) : (
                                <Stack alignItems="center" justifyContent="center" height="100%">
                                    <SurfLoader />
                                </Stack>
                            )}
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default AttachmentsInput;
