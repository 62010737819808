import { Typography, Card, Stack, Divider, Button, Collapse, TextField, Box, styled } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import HeadlineButton from '../display/HeadlineButton/HeadlineButton';
import { useState } from 'react';

const CardWrapper = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
        gap: ${theme.spacing(3)}
    `
);
const StyledBox = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: ${theme.spacing(4)}
    `
);

interface Props {}

const PriceFinderGeneral: React.FC<Props> = ({}) => {
    const [isOpen, setOpen] = useState<boolean>(true);

    return (
        <Stack>
            <HeadlineButton isOpen={isOpen} setOpen={setOpen} variant="page">
                Allgemein
            </HeadlineButton>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <CardWrapper mb={3}>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="h4" mt={-0.5} mb={2}>
                            Grundpreis
                        </Typography>
                        <StyledBox>
                            <TextField size="small" label="Strompreis" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Gaspreis" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Pulverkosten / kg" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Gesamtmietkosten" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Wasser" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Abwasser" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Entsorgung" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                        </StyledBox>
                    </Card>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="h4" mt={-0.5} mb={2}>
                            Betrieb
                        </Typography>
                        <StyledBox>
                            <TextField size="small" label="Produktionsfläche" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Anschaffungskosten" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Versicherung" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Personal" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="KFZ" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="LKW / Logistik" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Sonstige Kosten" value={'12'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                        </StyledBox>
                    </Card>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="h4" mt={-0.5} mb={2}>
                            Berechnungsgrundlagen
                        </Typography>
                        <StyledBox>
                            <TextField size="small" label="Belegungsfaktor" value={'Gering (80%)'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Anzahl Schichten" value={'Zweischichtig'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Taktzeit" value={'Mittel (10min)'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Durchsatz" value={'Hoch (2100 h/a)'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Seiten" value={'Einseitig'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                            <TextField size="small" label="Produktivität" value={'80%'} variant="outlined" InputProps={{ endAdornment: '€' }} />
                        </StyledBox>
                    </Card>
                </CardWrapper>
            </Collapse>
        </Stack>
    );
};

export default PriceFinderGeneral;
