import { alpha, BottomNavigation, BottomNavigationAction, Box, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import { useTranslation } from 'react-i18next';

const FooterWrapper = styled(Box)(
    ({ theme }) => `
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: ${theme.header.height};
        padding: ${theme.spacing(0, 1)};
        color: ${theme.header.textColor};
        z-index: 6;
        background-color: ${theme.colors.alpha.white[100]};
        box-shadow: 0 0 10px 0 ${theme.colors.alpha.black[10]};
        justify-content: space-between;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            width: auto;
        }
`
);

function Footer() {
    const { t } = useTranslation();
    const [value, setValue] = useState(null);

    const isDashboard = useMatch(nav.DASHBOARD.path + '/*');
    const isCalculation = useMatch(nav.CALCULATIONS.path + '/*');
    // const isWizard = useMatch(nav.WIZARD.path + '/*');
    const isTools = useMatch(nav.TOOLS.path + '/*');
    const isProfile = useMatch(nav.EDIT_PROFILE.path + '/*');

    useEffect(() => {
        if (isDashboard) setValue(0);
        if (isCalculation) setValue(1);
        // if(isWizard) setValue(2);
        if (isTools) setValue(2);
        if (isProfile) setValue(3);
    }, []);

    return (
        <FooterWrapper displayPrint="none" display="flex" alignItems="center">
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    '.MuiButtonBase-root': {
                        minWidth: '50px'
                    },
                    '.MuiBottomNavigationAction-label': {
                        fontSize: '12px'
                    }
                }}
            >
                <BottomNavigationAction id={'homeLink'} component={Link} to={nav.DASHBOARD.path} label={t('footerDashboard')} icon={<HomeTwoToneIcon />} />
                <BottomNavigationAction id={'calculationLink'} component={Link} to={nav.CALCULATIONS.path} label={t('footerCalculation')} icon={<CalculateTwoToneIcon />} />
                {/* <BottomNavigationAction id={"wizardLink"} component={Link} to={nav.WIZARD.path} label={t('footerWizard')} icon={<DisplaySettingsTwoToneIcon />} /> */}
                <BottomNavigationAction id={'toolsLink'} component={Link} to={nav.TOOLS.path} label={t('footerTools')} icon={<HandymanTwoToneIcon />} />
                <BottomNavigationAction id={'userBoxDashboard'} component={Link} to={nav.EDIT_PROFILE.path} label={t('footerProfile')} icon={<PersonOutlineTwoToneIcon />} />
            </BottomNavigation>
        </FooterWrapper>
    );
}

export default Footer;
