import { useEffect, useState } from 'react';
import { GoogleMap, DirectionsRenderer, InfoWindow, Marker } from '@react-google-maps/api';
import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import { DistanceDurationCacheRestControllerService, UserResource } from 'src/backend/market';
import SurfLoader from 'src/components/display/Loader/SurfLoader';

const LoadingOverlay = styled(Stack)(
    ({ theme }) => `
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        background: ${theme.colors.alpha.white[70]};
    `
);

const containerStyle = {
    maxWidth: '100%',
    height: '100%'
};

interface Props {
    userPos: google.maps.LatLngLiteral;
    customerPos?: google.maps.LatLngLiteral;
    user?: UserResource;
    isRequestLoading?: boolean;
}
const CustomerDetailsMap: React.FC<Props> = ({ userPos, customerPos, user, isRequestLoading }) => {
    const options = {
        // mapId: 'e02dbfda52e6088e',
        mapId: 'ee6e6f6e1fdd8fca'
    };
    const [directions, setDirections] = useState<any>(null);
    const [center, setCenter] = useState<google.maps.LatLngLiteral>(userPos);
    const [zoom, setZoom] = useState<number>(12);
    const [bestRoute, setBestRoute] = useState<google.maps.DirectionsLeg>(null);

    useEffect(() => {
        if (!customerPos) return setDirections(null);
        loadDirections();
    }, [customerPos]);

    const loadDirections = () => {
        const directionsService = new google.maps.DirectionsService();

        const origin = userPos;
        const destination = customerPos;

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    const route = result.routes?.[0]?.legs?.[0];
                    setDirections(result);
                    setBestRoute(route);

                    DistanceDurationCacheRestControllerService.addDistanceDurationToCache({
                        companyId: user.company.companyId,
                        longitude: userPos.lng,
                        latitude: userPos.lat,
                        distance: route?.distance?.value,
                        duration: route?.duration?.value
                    });
                } else setDirections(null);
            }
        );
    };
    useEffect(() => {
        if (!directions)
            setTimeout(() => {
                setCenter(userPos);
                setZoom(12);
            }, 1);
    }, [directions]);

    const getDirectionCenterPos = (directions) => {
        const centerPoint = (directions.routes[0].overview_path.length / 2) | 0;
        return directions.routes[0].overview_path[centerPoint];
    };

    return (
        <Box id="mapImage" p={3} position="relative" width="45rem" maxWidth="100%" height="100%" sx={{ '.gm-ui-hover-effect': { display: 'none !important' } }}>
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom} options={options}>
                {!!directions && !isRequestLoading ? (
                    <>
                        <DirectionsRenderer directions={directions} />

                        <InfoWindow position={getDirectionCenterPos(directions)}>
                            <Stack>
                                <Stack direction="row" alignItems="center">
                                    <LocalShippingTwoToneIcon />
                                    <Typography variant="h4" ml={0.4} lineHeight={1}>
                                        {bestRoute?.distance?.text}
                                    </Typography>
                                </Stack>
                                <Typography variant="subtitle2">{bestRoute?.duration?.text}</Typography>
                            </Stack>
                        </InfoWindow>
                    </>
                ) : (
                    <Marker position={!!directions ? undefined : userPos} />
                )}
            </GoogleMap>
            {isRequestLoading && (
                <LoadingOverlay>
                    <SurfLoader />
                </LoadingOverlay>
            )}
        </Box>
    );
};

export default CustomerDetailsMap;
