import { FormControl, InputAdornment, InputLabel, MenuItem, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InternalCalculationResource } from 'src/backend/internalCalc';
import { PriceManipulationParameterResource, RegularCustomerRestControllerService } from 'src/backend/market';
import store, { useSelector } from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { StyledSelect, StyledTextField } from 'src/components/input/CalcInputs/CalcInputs.styles';
import InfoLabel from 'src/components/input/CalcInputs/ParameterFields/InfoLabel';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

interface Props {
    calculation?: InternalCalculationResource;
}

const MasterAdditionalSettings: React.FC<Props> = ({ calculation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const s = store.getState();
    const [discountParams, setDiscountParams] = useState<{ [id: number]: PriceManipulationParameterResource }>({});

    const userId = useSelector((state) => state.user?.currentUser.id);
    const discountParamsValues = calculation?.priceManipulationParameterValues || [];

    const discountFormikValues = useMemo(
        () =>
            discountParamsValues.reduce((acc, curr) => {
                acc[curr.parameterId + ''] = curr.value;
                return acc;
            }, {}),
        [discountParamsValues]
    );

    const formik = useFormik({
        initialValues: {
            note: calculation.note ?? '',
            ...discountFormikValues
        },
        onSubmit: () => {}
    });

    useEffect(() => {
        RegularCustomerRestControllerService.getAllPriceManipulationParameters(userId).then((allPriceManipulationParameters) => {
            const newDiscountParams = allPriceManipulationParameters.reduce((accumulator, param) => {
                accumulator[param.id] = param;
                return accumulator;
            }, {});
            setDiscountParams(newDiscountParams);
        });
    }, []);

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            ...discountFormikValues
        });
    }, [discountParamsValues]);

    const handleNoteChange = (event) => {
        const newValue = event.target.value;
        formik.setFieldValue('note', newValue);
        if (calculation.note === newValue) return;
        dispatch(updateProCalc(ProCalcUpdateTypes.NOTE, newValue));
    };

    const handlePriceParamChange = (event, index) => {
        const newValue = event.target.value;
        if (!newValue) return;

        const paramValue = { ...discountParamsValues[index] };
        paramValue.value = newValue;
        formik.setFieldValue(paramValue.parameterId + '', newValue);

        dispatch(updateProCalc(ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER, { index, paramValue }));
    };

    const handlePriceParamBlur = (event, index) => {
        const newValue = event.target.value;
        if (newValue == '') {
            const paramValue = { ...discountParamsValues[index] };
            paramValue.value = '0';
            formik.setFieldValue(paramValue.parameterId + '', paramValue.value);
            dispatch(updateProCalc(ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER, { index, paramValue }));
        }
    };

    const getParam = (paramValue) => {
        return discountParams[paramValue.parameterId] || {};
    };

    const renderDiscountParameter = () => {
        return discountParamsValues.map((paramValue, index) => {
            if (getParam(paramValue).type === 'BOOLEAN') {
                return (
                    <FormControl fullWidth key={'metadataParam' + paramValue.parameterId}>
                        <InputLabel id={calculation.id + paramValue.parameterId + '-label'} sx={{ marginLeft: '-0.35em' }} shrink>
                            <InfoLabel
                                label={getParam(paramValue).label}
                                guiDescriptor={getParam(paramValue).label}
                                guiDescriptorExplanation={getParam(paramValue).description}
                                showExplanationFallback={true}
                            />
                        </InputLabel>
                        <StyledSelect
                            labelId={calculation.id + paramValue.parameterId + '-label'}
                            label={
                                <InfoLabel
                                    label={wT(getParam(paramValue).label, s)}
                                    guiDescriptor={getParam(paramValue).label}
                                    guiDescriptorExplanation={getParam(paramValue).description}
                                    showExplanationFallback={true}
                                />
                            }
                            value={formik.values[paramValue.parameterId]}
                            onChange={(event) => {
                                const newValue = event.target.value + '';
                                formik.setFieldValue(paramValue.parameterId + '', newValue);
                                dispatch(updateProCalc(ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER, { index: index, paramValue: { ...paramValue, value: newValue } }));
                            }}
                        >
                            <MenuItem value="true">{t('true')}</MenuItem>
                            <MenuItem value="false">{t('false')}</MenuItem>
                        </StyledSelect>
                    </FormControl>
                );
            }
            return (
                <StyledTextField
                    key={'metadataParam' + paramValue.parameterId}
                    type="number"
                    label={
                        <InfoLabel
                            label={wT(getParam(paramValue).label, s)}
                            guiDescriptor={getParam(paramValue).label}
                            guiDescriptorExplanation={getParam(paramValue).description}
                            showExplanationFallback={true}
                        />
                    }
                    onChange={(event) => handlePriceParamChange(event, index)}
                    value={formik.values[paramValue.parameterId]}
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    onBlur={(event) => handlePriceParamBlur(event, index)}
                    InputProps={{ endAdornment: <InputAdornment position="end">{getParam(paramValue).unit}</InputAdornment> }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                />
            );
        });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3} pt={3}>
                {renderDiscountParameter()}
                <TextField
                    name="note"
                    value={formik.values.note}
                    onChange={handleNoteChange}
                    fullWidth
                    label={t('Notizen')}
                    title={t('Notizen')}
                    rows={4}
                    multiline
                    InputLabelProps={{ shrink: true }}
                />
            </Stack>
        </form>
    );
};

export default MasterAdditionalSettings;
