import { NavItems } from './navigations';
import Viewer3dOverview from 'src/pages/Core/Viewer3d/Viewer3dOverview';
import Viewer3dDetail from 'src/pages/Core/Viewer3d/Viewer3dDetail';

const viewer3dNavigation: NavItems = {
    BASE: {
        caption: 'viewer3d',
        tabTitle: 'viewer3dTabTitle',
        path: '/viewer/',
        element: <Viewer3dOverview />
    },
    VIEW: {
        caption: 'viewer3dDetail',
        tabTitle: 'viewer3dDetailTabTitle',
        path: '/viewer/:id',
        element: <Viewer3dDetail />
    }
};

export default viewer3dNavigation;
