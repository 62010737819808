import { Button, Divider, IconButton, List, Popover, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { InternalCalculationResource, SurchargeOverrideStorageResource } from 'src/backend/internalCalc';
import { API_CONFIG } from 'src/config';
import SurchargeListItem from '../SurchargeListItem';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';

interface Props {
    calculation?: InternalCalculationResource;
    surchargeOverrides?: Array<SurchargeOverrideStorageResource>;
}

const MasterSurcharges: React.FC<Props> = ({ calculation, surchargeOverrides }) => {
    const { t }: { t: any } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const surchargeReport = useMemo(() => calculation?.costResult?.surchargeReport || [], [calculation?.costResult?.surchargeReport]);

    const amountOfAppliedSurcharges = useMemo(() => {
        const amount = (surchargeReport || []).filter((surcharge) => {
            const foundOverride = (surchargeOverrides || []).find(
                (override) =>
                    override.subCalculationIdentificationKey === API_CONFIG.MASTER_SURCHARGE_IDENTIFICATION_KEY &&
                    override.surchargePackage === surcharge.surchargeFunctionPkg &&
                    override.surchargeName === surcharge.surchargeFunctionName
            );

            return foundOverride != null ? !foundOverride.deactivateSurcharge : surcharge.applied;
        })?.length;
        if (!amount) return;
        return ' (' + amount + ')';
    }, [surchargeReport, surchargeOverrides]);

    return (
        surchargeReport?.length > 0 && (
            <>
                <Button sx={{ px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<LocalOfferTwoToneIcon />} variant="text" color="secondary" onClick={() => setOpen(true)} ref={ref}>
                    {t('surcharges')}
                    {amountOfAppliedSurcharges}
                </Button>

                <Popover
                    anchorEl={ref.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                    <Stack p={2} width="25rem" maxWidth="100%">
                        <Typography variant="h3" textAlign="center">
                            {t('surcharges')}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom textAlign="center">
                            {t('surchargesExplanation')}
                        </Typography>

                        <Divider sx={{ mt: 2, mb: 1.5 }}></Divider>

                        <List disablePadding component="div" sx={{ mx: -2 }}>
                            {surchargeReport.map((surcharge, index) => (
                                <SurchargeListItem key={index} surcharge={surcharge} subCalculationIdentificationKey={API_CONFIG.MASTER_SURCHARGE_IDENTIFICATION_KEY} />
                            ))}
                        </List>
                    </Stack>
                </Popover>
            </>
        )
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation,
        surchargeOverrides: state.proCalc.surchargeOverrides
    };
};
export default connect(mapStateToProps)(MasterSurcharges);
