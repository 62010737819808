import { Typography, Stack, Button, IconButton, useTheme, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SurchargeVariableDynamicDialog from './SurchargeVariableDynamicDialog';
import { StringValueResource, SurchargeVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: SurchargeVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
}

const SurchargeVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid, isSmall }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isDynamicSurchargeOpen, setDynamicSurchargeOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!variable)
            setVariable({
                type: VariableResource.type.SURCHARGE_VARIABLE,
                name: '',
                ruleBasedValue: [{ isDefault: true, value: { type: ValueResource.type.STRING_VALUE, stringValue: '0' } }],
                criteria: []
            } as SurchargeVariableResource);
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.name == null || variable.name.trim() === '' || variable.ruleBasedValue == null || variable.ruleBasedValue.length < 1;
        setValid(!isInvalid);
    }, [variable]);

    const isDynamicSurcharge = variable?.ruleBasedValue?.length > 1;
    const surcharge = useMemo(() => {
        if (isDynamicSurcharge) {
            const values = variable.ruleBasedValue.map((rule) => (rule.value as StringValueResource)?.stringValue);
            return values.join(' | ');
        }
        const defaultValue = variable.ruleBasedValue.find((rule) => rule.isDefault)?.value as StringValueResource;
        return defaultValue?.stringValue;
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            name: event.target.value
        });
    };
    const handleSurchargeTypeChange = (event) => {
        setVariable({
            ...variable,
            isAbsoluteSurcharge: event.target.value === 'true'
        } as SurchargeVariableResource);
    };
    const handleSurchargeChange = (event) => {
        if (isDynamicSurcharge) return;
        const newValue = event.target.value;

        const rules = cloneDeep(variable.ruleBasedValue);
        const defaultRuleIndex = variable.ruleBasedValue.findIndex((rule) => rule.isDefault);
        (rules[defaultRuleIndex].value as StringValueResource).stringValue = newValue;

        setVariable({
            ...variable,
            ruleBasedValue: rules
        } as SurchargeVariableResource);
    };

    const isDisabled = variable?.id === -1;

    return (
        variable && (
            <Stack>
                <Stack direction="row" gap={3}>
                    <TextField label="Name" value={variable.name} onChange={handleNameChange} variant="outlined" fullWidth size={isSmall ? 'small' : 'medium'} disabled={isDisabled} />
                    <FormControl fullWidth>
                        <InputLabel id="surcharge-type">Aufschlagsart</InputLabel>
                        <Select
                            labelId="surcharge-type"
                            label="Aufschlagsart"
                            value={variable.isAbsoluteSurcharge ? 'true' : 'false'}
                            onChange={handleSurchargeTypeChange}
                            size={isSmall ? 'small' : 'medium'}
                            disabled={isDisabled}
                        >
                            <MenuItem value="false">Relativer Aufschlag (%)</MenuItem>
                            <MenuItem value="true">Absoluter Aufschlag (€)</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Aufschlagswert"
                        value={surcharge}
                        variant="outlined"
                        disabled={isDisabled || isDynamicSurcharge}
                        onChange={handleSurchargeChange}
                        InputProps={{
                            sx: { pr: 0 },
                            startAdornment: isDynamicSurcharge ? null : <InputAdornment position="start">{variable.isAbsoluteSurcharge ? '€' : '%'}</InputAdornment>,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton color="primary" onClick={() => setDynamicSurchargeOpen(true)} size={isSmall ? 'small' : 'medium'} disabled={isDisabled}>
                                        <SettingsTwoToneIcon />
                                    </IconButton>
                                    <SurchargeVariableDynamicDialog
                                        variable={variable}
                                        variables={variables}
                                        isOpen={isDynamicSurchargeOpen}
                                        setOpen={setDynamicSurchargeOpen}
                                        setVariable={setVariable}
                                    />
                                </InputAdornment>
                            )
                        }}
                        fullWidth
                        size={isSmall ? 'small' : 'medium'}
                    />
                </Stack>
                <Typography variant="h4" sx={{ fontSize: 17, mt: 4, mb: 0.5, opacity: 0.7 }} gutterBottom>
                    Wann soll der Wert angewandt werden?
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 0.5 }} variant="subtitle1">
                    Wähle aus, wie der Wert für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <Button startIcon={<AddBoxTwoToneIcon />} sx={{ mr: 'auto', ml: -1.6 }} disabled={isDisabled}>
                    Kriterium hinzufügen
                </Button>
            </Stack>
        )
    );
};

export default SurchargeVariableForm;
