import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { InternalCalculationFilterBoundariesResource, InternalCalculationStatusResource } from "src/backend/internalCalc";
import {
    BoughtModulesResource,
    CustomerTypeResource,
    InquiryStateResource,
    RegularCustomerFilterBoundariesResource,
    UsedItemTypesResource,
    WizardInquiryFilterBoundariesResource
} from "../../backend/market";

export interface IConstantsReducer {
    //TYPES AND STATES
    inquiryStates: InquiryStateResource[],
    customerTypes: CustomerTypeResource[],
    usedItemTypes: UsedItemTypesResource,
    internalCalculationStates: InternalCalculationStatusResource[],
    //FILTER BOUNDARIES
    inquiryFilterBoundaries: WizardInquiryFilterBoundariesResource | undefined,
    customerFilterBoundaries: RegularCustomerFilterBoundariesResource | undefined,
    internalCalculationBoundaries: InternalCalculationFilterBoundariesResource | undefined,
    //OTHERS
    wizardTranslations: { [key: string]: string } | undefined,
    moduleManagement: BoughtModulesResource | undefined,

}

const initialState: IConstantsReducer = {
    inquiryStates: [],
    usedItemTypes: {},
    customerTypes: [],
    internalCalculationStates: [],

    inquiryFilterBoundaries: undefined,
    customerFilterBoundaries: undefined,
    internalCalculationBoundaries: undefined,

    moduleManagement: undefined,
    wizardTranslations: undefined,
}

const reducers = {
    //STATES
    setInquiryStates: (state: IConstantsReducer, action: PayloadAction<InquiryStateResource[]>) => {
        state.inquiryStates = action.payload;
    },
    setCustomerTypes: (state: IConstantsReducer, action: PayloadAction<CustomerTypeResource[]>) => {
        state.customerTypes = action.payload;
    },
    setUsedItemTypes: (state: IConstantsReducer, action: PayloadAction<UsedItemTypesResource>) => {
        state.usedItemTypes = action.payload;
    },
    setInternalCalculationStates: (state: IConstantsReducer, action: PayloadAction<InternalCalculationStatusResource[]>) => {
        state.internalCalculationStates = action.payload;
    },
    //BOUNDARIES
    setInquiryFilterBoundaries: (state: IConstantsReducer, action: PayloadAction<WizardInquiryFilterBoundariesResource>) => {
        state.inquiryFilterBoundaries = action.payload;
    },
    setInternalCalculationBoundaries: (state: IConstantsReducer, action: PayloadAction<InternalCalculationFilterBoundariesResource>) => {
        state.internalCalculationBoundaries = action.payload;
    },
    setCustomerFilterBoundaries: (state: IConstantsReducer, action: PayloadAction<RegularCustomerFilterBoundariesResource>) => {
        state.customerFilterBoundaries = action.payload;
    },
    //OTHERS
    setModuleManagement: (state: IConstantsReducer, action: PayloadAction<BoughtModulesResource>) => {
        state.moduleManagement = action.payload;
    },
    setWizardTranslations: (state: IConstantsReducer, action: PayloadAction<{ [key: string]: string }>) => {
        state.wizardTranslations = action.payload;
    },
}

export const slice = createSlice({
    name: "constants",
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;