import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useState } from 'react';
import SurchargeVariableDynamicItem from './SurchargeVariableDynamicItem';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { SurchargeVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        .accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    variable: SurchargeVariableResource;
    variables?: Array<VariableResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    setVariable: (variable: VariableResource) => void;
}

const SurchargeVariableDynamicDialog: React.FC<Props> = ({ variable, variables, isOpen, setOpen, setVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();

    const addRule = () => {
        setVariable({
            ...variable,
            ruleBasedValue: [...variable.ruleBasedValue, { value: { type: ValueResource.type.STRING_VALUE, stringValue: '0' } }]
        } as SurchargeVariableResource);
    };
    const changeRuleValue = (ruleId: number, value: ValueResource) => {
        const rules = cloneDeep(variable.ruleBasedValue);
        const ruleIndex = variable.ruleBasedValue.findIndex((rule) => rule.id === ruleId);
        rules[ruleIndex].value = value;

        setVariable({
            ...variable,
            ruleBasedValue: rules
        } as SurchargeVariableResource);
    };
    const removeRule = (ruleId: number) => {
        setVariable({
            ...variable,
            ruleBasedValue: variable.ruleBasedValue.filter((rule) => rule.id !== ruleId)
        } as SurchargeVariableResource);
    };

    const isDisabled = variable?.id === -1;

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Dynamischer Aufschlagswert
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }} disabled={isDisabled}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <StyledStack>
                    <Divider />
                    {(variable?.ruleBasedValue || []).map((rule, index) => (
                        <SurchargeVariableDynamicItem
                            key={rule.id + '' + index}
                            rule={rule}
                            isAbsolute={variable.isAbsoluteSurcharge}
                            variables={variables}
                            openAccordionId={openAccordionId}
                            setOpenAccordionId={setOpenAccordionId}
                            changeRuleValue={changeRuleValue}
                            removeRule={removeRule}
                            disabled={isDisabled}
                        />
                    ))}
                    <Divider sx={{ mt: 'auto' }} />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={addRule} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} disabled={isDisabled}>
                        Weiteren Aufschlagswert hinzufügen
                    </Button>
                </StyledStack>
            </DialogContent>
        </Dialog>
    );
};

export default SurchargeVariableDynamicDialog;
