import { Box, Breadcrumbs, Button, Card, CircularProgress, Stack, styled, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { BackendService } from 'src/backend/summary/BackendService';
import { ILayer, ILayerSubGeometryPackage } from 'src/backend/summary/resources/ILayer';
import store, { RootState } from 'src/redux/store';
import { itemImage } from 'src/utils/wizardImages';
import { wT } from 'src/utils/wizardTranslations';

const ItemListBox = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        gap: ${theme.spacing(1.5)};
    `
);
const Item = styled(Card)<{ selected?: boolean }>(
    ({ theme, selected }) => `
        display: flex;
        justify-content: center;
        height: 5rem;

        .MuiButtonBase-root {
            color: unset;
            border-radius: ${theme.shape.borderRadius}px;
            height: 5rem;
            padding: ${theme.spacing(0.8)};
            padding-right: ${theme.spacing(2)};
            flex-grow: 1;
            justify-content: flex-start;
        }

        .MuiTypography-root {
            font-weight: normal;
        }

        img {
            height: 100%;
            margin-left: ${theme.spacing(1)};
            margin-right: ${theme.spacing(2)};
            object-fit: contain;
        }
    `
);

interface Props {
    category: number;
    selectedItem: (itemId: number, subGeometryPackage: ILayerSubGeometryPackage) => void;
    searchText?: string;
    secondLayerItems: Array<ILayer>;
    isFragment: boolean;
}

const ItemArea: React.FC<Props> = ({ category, selectedItem, searchText, secondLayerItems, isFragment }) => {
    //region STATE
    const [loadedItems, setLoadedItems] = useState<{ [key: number]: ILayer }>();
    const [activeParents, setActiveParent] = useState<ILayer[]>([]);
    const wizardKey = useSelector((state: RootState) => state.wizardProfile.currentProfile.wizardKey);
    const { t } = useTranslation();
    const s = store.getState();
    //endregion

    //region LIFECYCLE
    useEffect(() => {
        setLoadedItems(
            secondLayerItems.reduce((acc, item) => {
                acc[item.id] = _.clone(item);
                return acc;
            }, {})
        );
    }, [secondLayerItems]);

    useEffect(() => {
        setActiveParent([]);
    }, [category]);
    //endregion

    //region HANDLER
    const handleItemClick = (item: ILayer) => {
        if (item.hasChildren) {
            if ((item.children ?? []).length > 0) {
                setActiveParent([...(activeParents ?? []), item]);
            } else {
                BackendService.loadChildLayer(wizardKey, item.id).then((items) => {
                    const cItems = { ...loadedItems };
                    cItems[item.id].children = items;
                    setLoadedItems(cItems);
                });
                setActiveParent([...(activeParents ?? []), item]);
            }
        } else {
            const subGeometryPackage = isFragment ? item.availableSubGeometryPackagesForFragmentation[0] : null;
            selectedItem(item.id, subGeometryPackage);
        }
    };
    //endregion

    //region RENDER
    const renderParents = () => {
        let items: ILayer[] = [];

        if (activeParents.length > 0) {
            items = activeParents[activeParents.length - 1].children ?? [];
        } else {
            items = (_.values(loadedItems) ?? []).filter((item) => {
                if (category < 1) return true;
                return item.parent === category;
            });
        }
        items = items.sort((a, b) => {
            if (a.rank !== b.rank) return a.rank - b.rank;
            const aName = wT(a.nameKey, s) || '';
            const bName = wT(b.nameKey, s) || '';
            return aName.localeCompare(bName);
        });
        if (searchText && searchText !== '') {
            items = items.filter((x) => (wT(x.nameKey, s) || '').toLowerCase().includes(searchText.trim().toLowerCase()));
        }
        if (isFragment) {
            items = items.filter((x) => x.availableSubGeometryPackagesForFragmentation?.length > 0);
        }
        return items.map((item) => {
            if (item.nameKey.startsWith('$')) return;
            return (
                <Item key={'item' + item.id}>
                    <Button onClick={() => handleItemClick(item)} variant="text" color="secondary">
                        <img src={itemImage(item.imageUri, item.itemUiImage)} alt={'item'} />
                        <Typography variant={'h5'} textOverflow="ellipsis" overflow="hidden" textAlign="left">
                            {wT(item.nameKey, s, true)}
                        </Typography>
                    </Button>
                </Item>
            );
        });
    };
    const buildBreadcrumbs = () => {
        return [
            ...activeParents.map((layer: ILayer, index: number) => {
                return (
                    <Typography
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }
                        }}
                        onClick={() => {
                            setActiveParent(activeParents.slice(0, index));
                        }}
                        variant={'caption'}
                        key={'breadcrumb' + layer.id}
                    >
                        {wT(layer.nameKey, s)}
                    </Typography>
                );
            }),
            <Typography key={'breadcrumb' + 0} variant={'caption'}>
                ...
            </Typography>
        ];
    };
    //endregion
    return (
        <Stack id={'itemAreaAllParts'} mt={1} minHeight={isFragment ? '5rem' : '25rem'} maxHeight="25rem" width={isFragment ? '65rem' : 'auto'} maxWidth="100%">
            {activeParents.length > 0 && (
                <Box p={2} pt={1}>
                    <Breadcrumbs>{buildBreadcrumbs()}</Breadcrumbs>
                </Box>
            )}

            <ItemListBox p={2} pt={1} overflow="auto" maxHeight="100%">
                {renderParents()}
            </ItemListBox>
        </Stack>
    );
};

const mapStateToProps = (state: RootState) => ({
    secondLayerItems: state.proCalc.secondLayerItems
});
export default connect(mapStateToProps)(ItemArea);
