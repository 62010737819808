import {
    AdminPanelSettingsTwoTone,
    DeleteForeverTwoTone,
    ReplayTwoTone,
    VerticalAlignBottom,
    VerticalAlignTop
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react"
import {Trans, useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {UserResource} from "../../../backend/market";
import useDialog from "../../../hooks/useDialog";
import {useSelector} from "../../../redux/store";
import {degradeToUser, deleteUser, promoteToAdmin, reinviteUser} from "../../../redux/thunks/admin.thunk";

enum UserStatus {
    "REGISTERED" = "REGISTERED",
    "ACTIVE" = "ACTIVE",
    "DELETED" = "DELETED",
    "INVITED" = "INVITED",
    "INVITE_ACCEPTED" = "INVITE_ACCEPTED"
}

const StyledAdminIcon = styled(AdminPanelSettingsTwoTone)`
  color: white;
  bottom: -5px;
  height: 20px;
  right: 10px;
  width: 20px;
  position: absolute;
  border-radius: 6px;
  padding: 2px;
  background-color: ${props => props.theme.palette.primary.main};
`;
const StyledStatusIcon = styled(Box)<{ status: UserStatus }>`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${({status, theme}) => {
    switch (status) {
      case UserStatus.REGISTERED:
        return theme.palette.success.dark;
      case UserStatus.ACTIVE:
        return theme.palette.success.main;
      case UserStatus.DELETED:
        return theme.palette.error.main;
      case UserStatus.INVITED:
        return theme.palette.grey[200];
      case UserStatus.INVITE_ACCEPTED:
        return theme.palette.success.light;
      default:
        return "orange";
    }
  }};
  position: absolute;
  right: 12.5px;
  bottom: -3px;

`

const ManageUserTable: React.FC<{ users: UserResource[] }> = ({users}) => {
    //region STATE
    const myUserId = useSelector(state => state.user.currentUser.id);
    const dispatch = useDispatch();
    const dialog = useDialog();
    const {t} = useTranslation();
    //endregion

    //region LIFECYCLE 
    //endregion

    //region HANDLER
    const resendInvitation = (user) => {
        dispatch(reinviteUser(user));
    }
    const handleDeleteUser = (user) => {
        dialog.openDialog({
            title: t('benutzerLschen'),
            component: <Typography><Trans
                i18nKey={"deleteUserDialogContent"}
                components={{
                    b: <br/>,
                    bold: <b/>
                }}
                values={{fullName: user.fullName, email: user.email}}
            /></Typography>,
            okText: t('lschen'),
            cancelText: t('nichtLschen'),
            severity: "error",
            okCallback: () => {
                dispatch(deleteUser(user));
                dialog.closeDialog();
            },
            cancelCallback: () => {
                dialog.closeDialog();
            }
        })
    }
    const handlePromoteToAdmin = (user) => {
        dialog.openDialog({
            title: t('benutzerAdministratorrechteErteilen'),
            component: <Typography><Trans
                i18nKey="addAdminRightsDialogContent"
                components={{
                    b: <br/>,
                    bold: <b/>
                }}
                values={{fullName: user.fullName, email: user.email}}
            /></Typography>,
            okText: t('zumAdminMachen'),
            cancelText: t('abbrechen'),
            severity: "info",
            okCallback: () => {
                dispatch(promoteToAdmin(user));
                dialog.closeDialog();
            },
            cancelCallback: () => {
                dialog.closeDialog();
            }
        })
    }
    const handleDegradeToUser = (user) => {
        dialog.openDialog({
            title: t('adminrechteEntfernen'),
            component: <Typography><Trans
                i18nKey="removeAdminRightsDialogContent"
                components={{
                    b: <br/>,
                    bold: <b/>
                }}
                values={{fullName: user.fullName, email: user.email}}
            /></Typography>,
            okText: t('entfernen'),
            cancelText: t('nichtEntfernen'),
            severity: "error",
            okCallback: () => {
                dispatch(degradeToUser(user));
                dialog.closeDialog();
            },
            cancelCallback: () => {
                dialog.closeDialog();
            }
        })
    }
    //endregion
    return <List sx={{ px: 0 }}>
        {users.sort((a, b) => (a.userStatus ?? "").localeCompare(b.userStatus)).map(user => {
            return <ListItem divider key={"userTableRow" + user.id}>
                <ListItemAvatar>
                    <Box position={"relative"}>
                        <Avatar alt={user.fullName} variant={"rounded"} src="/broken-image.jpg"/>
                        {user.userRoleResource.roleType === "ADMIN" ? <StyledAdminIcon/> :
                            <StyledStatusIcon status={user.userStatus as UserStatus}/>}
                    </Box>
                </ListItemAvatar>
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <ListItemText
                        primary={
                            <Typography noWrap>
                                {user.userStatus !== UserStatus.ACTIVE && <strong style={{ textTransform: 'uppercase', marginRight: 4 }}>{t(user.userStatus)}:</strong>}
                                {user.firstName} {user.lastName}
                            </Typography>
                        }
                        secondary={<Typography variant="subtitle2" noWrap>{user.email}</Typography>}
                    />
                    {user.id !== myUserId && <Stack gap={1} direction="row">
                        <Tooltip arrow title={t("benutzerLschen")}>
                            <IconButton onClick={() => handleDeleteUser(user)} color={"error"}>
                                <DeleteForeverTwoTone/>
                            </IconButton>
                        </Tooltip>
                        {user.userRoleResource.roleType === "ADMIN" ?
                        <Tooltip arrow title={t("adminrechteEntfernen")}>
                            <IconButton onClick={() => handleDegradeToUser(user)}>
                                <VerticalAlignBottom/>
                            </IconButton>
                        </Tooltip>
                        : user.userStatus === UserStatus.INVITED || user.userStatus === UserStatus.INVITE_ACCEPTED ?
                            <Tooltip arrow title={t("resendInvitation")}>
                                <IconButton onClick={() => resendInvitation(user)}><ReplayTwoTone/></IconButton>
                            </Tooltip> :
                            <Tooltip arrow title={t("zumAdminMachen")}>
                                <IconButton onClick={() => handlePromoteToAdmin(user)}><VerticalAlignTop/></IconButton>
                            </Tooltip>}
                    </Stack>}
                </Stack>
            </ListItem>
        })
        }
    </List>
}
export default ManageUserTable