import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TemplateCollectionResource } from 'src/backend/coreCalc';

export interface ITemplatesReducer {
    collection: TemplateCollectionResource;
}

const initialState: ITemplatesReducer = {
    collection: null
};

const reducers = {
    setTemplateCollection: (state: ITemplatesReducer, action: PayloadAction<TemplateCollectionResource>) => {
        state.collection = action.payload;
    }
};

export const slice = createSlice({
    name: 'templates',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
