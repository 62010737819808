import { Box, Button, Dialog, IconButton, Popover, styled, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculationPartResource } from 'src/backend/internalCalc';
import { CalculationResultResource } from 'src/backend/market';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Item from './Item';

const StyledBox = styled(Box)(
    ({ theme }) => `
        .item-header {
            padding-right: 45px;
        }
    `
);

interface Props {
    costResult?: CalculationResultResource;
    part?: CalculationPartResource;
    showUnitPrice?: boolean;
    viewerDetails?: {
        dialogType?: 'PDF' | '3D' | 'DXF';
        previous?: CalculationPartResource;
        current?: CalculationPartResource;
        next?: CalculationPartResource;
    };
    setViewer?: (viewer: { part: CalculationPartResource; dialogType: 'PDF' | '3D' | 'DXF' }) => void;
}

const ItemShowButton: React.FC<Props> = ({ costResult, part, showUnitPrice, viewerDetails, setViewer }) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);

    return (
        <>
            <Button size="small" variant="outlined" startIcon={<VisibilityTwoToneIcon />} onClick={() => setOpen(true)} ref={ref}>
                Ansehen
            </Button>
            <Dialog fullWidth maxWidth="xl" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)} open={isOpen}>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 10, top: 10, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                {isOpen && (
                    <StyledBox>
                        <Item costResult={costResult} part={part} showUnitPrice={showUnitPrice} viewerDetails={viewerDetails} setViewer={setViewer} />
                    </StyledBox>
                )}
            </Dialog>
        </>
    );
};

export default ItemShowButton;
