import { Typography, Stack, IconButton, styled, useTheme, ListItemButton, ListItemIcon, Checkbox, ListItemText, lighten } from '@mui/material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { useState } from 'react';
import ChangeVariableDialog from '../Variable/ChangeVariableDialog';
import { useDispatch } from 'react-redux';
import { InputVariableResource, VariableResource } from 'src/backend/coreCalc';

const StyledListItemButton = styled(ListItemButton)(
    ({ theme }) => `
        &.active {
            background: ${theme.colors.primary.lighter};
        }
        &.active:hover {
            background: ${lighten(theme.colors.primary.main, 0.8)};
        }
        &.active.Mui-disabled {
            opacity: 1;
            background: ${theme.colors.alpha.black[7]};
        }
        &.active.Mui-disabled .Mui-checked {
            color: ${theme.colors.alpha.black[30]};
        }
    `
);

interface Props {
    variable: InputVariableResource;
    variables: Array<VariableResource>;
    calcRelevantVariableNames?: Array<string>;
    saveVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const InputFieldListItem: React.FC<Props> = ({ variable, variables, calcRelevantVariableNames, saveVariable, removeVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isOpen, setOpen] = useState<boolean>(false);

    const isCalcRelevant = (calcRelevantVariableNames || []).includes(variable.name);
    const isActive = variable.isAlwaysActive || isCalcRelevant;

    const handleClick = () => {
        if (isCalcRelevant) return;
        saveVariable({
            ...variable,
            isAlwaysActive: !variable.isAlwaysActive
        } as InputVariableResource);
    };

    return (
        <>
            <StyledListItemButton className={isActive && 'active'} disabled={isCalcRelevant} onClick={handleClick}>
                <ListItemIcon sx={{ minWidth: 32, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Checkbox edge="start" checked={isActive} tabIndex={-1} disableRipple />
                    {/* {isLoading && <Stack sx={{ position: 'absolute', background: theme.colors.alpha.white[100] }}><CircularProgress size={20}/></Stack>} */}
                </ListItemIcon>
                <ListItemText primary={variable.name} primaryTypographyProps={{ variant: 'body1' }} />
                {isCalcRelevant && (
                    <Stack textAlign="right">
                        <Typography variant="subtitle2" fontSize={11} lineHeight={1.1}>
                            Kalkulationsrelevant
                        </Typography>
                        <Typography variant="subtitle2" fontSize={11} lineHeight={1.1}>
                            Nicht deaktivierbar
                        </Typography>
                    </Stack>
                )}
                <IconButton
                    color="secondary"
                    sx={{ mr: -0.5, pointerEvents: 'all' }}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setOpen(true);
                    }}
                >
                    <SettingsTwoToneIcon />
                </IconButton>
            </StyledListItemButton>
            <ChangeVariableDialog
                variable={variable}
                variables={variables}
                isOpen={isOpen}
                setOpen={setOpen}
                saveVariable={saveVariable}
                removeVariable={removeVariable}
                isCalcRelevant={isCalcRelevant}
            />
        </>
    );
};

export default InputFieldListItem;
