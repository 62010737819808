import {WizardProfileRestControllerService, WizardStatusRestControllerService} from "../../backend/market";
import {IWizardStati, slice} from "../slices/wizardStati.reducer";
import {AppThunk} from "../store";

export const fetchAllWizardStati = (): AppThunk => async (dispatch, getState): Promise<void> => {
    const userId = getState().user.currentUser.id;
    try {
        const walkInWizardState = (await WizardStatusRestControllerService.getWalkInCustomerWizardState(userId));
        const wholeWizardState = (await WizardStatusRestControllerService.getWholeWizardState1(userId));
        const regularWizardState = (await WizardStatusRestControllerService.getRegularCustomerWizardState(userId));
        console.log(":: FETCH :: ALL WIZARD STATI", regularWizardState, wholeWizardState, walkInWizardState);

        const stati: IWizardStati = {
            walkInCustomerWizardState: walkInWizardState.active,
            regularCustomerWizardState: regularWizardState.active,
            wholeWizardState: wholeWizardState.active
        }

        dispatch(slice.actions.setAllStati(stati))
    } catch (e) {
        console.log(e);
    }
}

export const setAllWizardStati = (states: IWizardStati, languageCode: string, callback?: () => void): AppThunk => async (dispatch, getState): Promise<void> => {
    const userId = getState().user.currentUser.id;
    const promises = [];
    console.log("Update Status: ", states);
    promises.push(WizardStatusRestControllerService.updateWalkInWizardState(userId, {active: states.walkInCustomerWizardState}));
    promises.push(WizardStatusRestControllerService.updateRegularCustomerWizardState(userId, {active: states.regularCustomerWizardState}));
    promises.push(WizardProfileRestControllerService.setWizardProfileLanguage(userId, { languageCode }));
    console.log(":: SET :: WALK-IN WIZARD STATI");

    if (states.walkInCustomerWizardState && states.regularCustomerWizardState) {
        promises.push(WizardStatusRestControllerService.getWholeWizardState(userId, {active: true}));
    } else {
        promises.push(WizardStatusRestControllerService.getWholeWizardState(userId, {active: false}));
    }

    dispatch(slice.actions.setWalkInCustomerWizardState(states.walkInCustomerWizardState))
    dispatch(slice.actions.setRegularCustomerWizardState(states.regularCustomerWizardState))
    dispatch(slice.actions.setWholeWizardState(states.wholeWizardState))
    Promise.all(promises).then(() => {
        if (callback) {
            callback();
        }
    })
}

export const setWalkInWizardState = (walkInActive: boolean, callback?: () => void): AppThunk => async (dispatch, getState): Promise<void> => {
    const userId = getState().user.currentUser.id;
    const walkInWizardState = (await WizardStatusRestControllerService.updateWalkInWizardState(userId, {active: walkInActive})).active;


    console.log(":: SET :: WALK-IN WIZARD STATI");

    dispatch(slice.actions.setWalkInCustomerWizardState(walkInActive))
    callback();

}

export const setRegularCustomerWizardState = (regularCustomerActive: boolean, callback?: () => void): AppThunk => async (dispatch, getState): Promise<void> => {
    const userId = getState().user.currentUser.id;
    const regularCustomerState = (await WizardStatusRestControllerService.updateRegularCustomerWizardState(userId, {active: regularCustomerActive})).active;

    console.log(":: SET :: REGULAR-CUSTOMER WIZARD STATI");

    dispatch(slice.actions.setRegularCustomerWizardState(regularCustomerActive))
    callback();

}