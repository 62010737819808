import { Typography, Stack, useTheme, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TermBuilder from '../../TermBuilder/TermBuilder';
import { TermVariableResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: TermVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
}

const DeclarationVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (!variable) setVariable({ type: VariableResource.type.TERM_VARIABLE, name: '', term: {} } as TermVariableResource);
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.name == null || variable.name.trim() === '' || variable.term == null || JSON.stringify(variable.term) === '{}';
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({ ...variable, name: event.target.value });
    };

    const setTerm = (term) => {
        setVariable({ ...variable, term } as TermVariableResource);
    };

    const isDisabled = variable?.id === -1;

    return (
        variable && (
            <Stack>
                <TextField label="Name" value={variable.name} onChange={handleNameChange} variant="outlined" fullWidth disabled={isDisabled} />
                <Typography variant="h4" sx={{ fontSize: 20, mt: 3, mb: 0.5 }} gutterBottom>
                    Wie soll dein Wert berechnet werden?
                </Typography>
                <TermBuilder term={variable.term} setTerm={setTerm} variables={variables} isAlwaysFullWidth={true} minInputWidth="12rem" disabled={isDisabled} />
            </Stack>
        )
    );
};

export default DeclarationVariableForm;
