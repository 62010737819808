import {
    Typography,
    Box,
    Avatar,
    Card,
    useTheme,
    Stack, CircularProgress
} from '@mui/material';

import {useTranslation} from 'react-i18next';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import {formatValue} from 'src/utils/FormatHelpers';


interface Props {
    title: string | JSX.Element;
    value: string | number | JSX.Element;
    monthlyIncrease: string | number;
    icon: JSX.Element;
}

const StatisticCard: React.FC<Props> = ({title, value, monthlyIncrease, icon}) => {
    const {t}: { t: any } = useTranslation();
    const theme = useTheme();

    const monthlyIncreaseText = typeof monthlyIncrease === 'string' ? monthlyIncrease : formatValue(monthlyIncrease * 100) + '%';

    return (
        <Card sx={{p: 2.7}}>
            {(title === undefined || monthlyIncrease === undefined || Number.isNaN(monthlyIncrease) || Number.isNaN(value)) ?
                <Stack height={"100%"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Box pb={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Stack overflow="hidden">
                            <Typography gutterBottom component="div" variant="caption" noWrap>
                                {title}
                            </Typography>
                            {typeof value === 'string' ? <Typography variant="h3" noWrap title={value}>{value}</Typography> : value}
                        </Stack>
                        <Avatar
                            sx={{
                                width: theme.spacing(5.5),
                                height: theme.spacing(5.5),
                                background: theme.colors.gradients.blue2,
                                color: theme.palette.getContrastText(theme.colors.error.dark)
                            }}
                        >
                            {icon}
                        </Avatar>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="subtitle2"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                pr: 0.5,
                                color: theme.colors.success.main
                            }}
                        >
                            <ArrowUpwardTwoToneIcon fontSize="small" sx={{mr: 0.2}}/>
                            <span title={monthlyIncreaseText}>{monthlyIncreaseText}</span>
                        </Typography>
                        <Typography variant="subtitle2" noWrap>
                            {t('increaseThisMonth')}
                        </Typography>
                    </Box>
                </>}
        </Card>
    );
}

export default StatisticCard;
