import {CloseRounded, ContentCopyRounded} from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {Box, Button, Card, Divider, Grid, IconButton, InputAdornment, Link, TextField, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import useDialog from "src/hooks/useDialog";
import {updateApiKey} from "src/redux/thunks/wizardProfile.thunk";
import {RootState} from "src/redux/store";
import { API_CONFIG } from "src/config";


const PublicApiSettings = ({profile}) => {
    const dispatch = useDispatch();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {t}: { t: any } = useTranslation();
    const dialog = useDialog();

    const [apiKey, setApiKey] = useState(profile.apiKey ?? t('REST_API_NO_KEY'));


    const handleCopyApiKey = () => {
        navigator.clipboard.writeText(apiKey);
        const key = enqueueSnackbar(t('REST_API_KEY_COPIED'), {
            variant: "info",
            action: <IconButton color="inherit"
                                onClick={() => closeSnackbar(key)}><CloseRounded/></IconButton>,
        });
    }

    const handleApiKeyGeneration = () => {

        dialog.openDialog({
            severity: "warning",
            title: t('REST_API_GENERATION_DIALOG_TITLE'),
            component: <Typography>{ t('REST_API_GENERATION_DIALOG_TEXT') }</Typography>,
            okText: t('REST_API_CONFIRM_GENERATION'),
            cancelText: t('REST_API_CANCEL_GENERATION'),
            okCallback: () => {
                dispatch(updateApiKey((newApiKey) => {
                    const key = enqueueSnackbar(t('REST_API_KEY_GENERATED'), {
                        variant: "success",
                        action: <IconButton color="inherit" onClick={() => closeSnackbar(key)}><CloseRoundedIcon/></IconButton>,
                    });
                    setApiKey(newApiKey);
                }));
                dialog.closeDialog();
            }
        })

    }

    const restApiDocUrl = API_CONFIG.MARKETPLACE.API_BASE_PATH + '/swagger-ui/index.html'


    return <Card sx={{ mt: 4 }}>
        <Grid item xs={12} p={1}>
            <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt' sx={{display: "flex", flexDirection: "column"}}>
                <Typography
                    variant="caption"
                >
                    {t('REST_API_SUBTITLE')}
                </Typography>
                {t('REST_API_TITLE')}

            </Box>
            <Divider sx={{mt: 1, mb: 2}}/>

            <Box pl={1} pb={3}>
                <Typography variant='subtitle2'>
                    {t('REST_API_DESCRIPTION')}
                </Typography>
                <Link href={restApiDocUrl} target="_blank" rel="noopener">{t('REST_API_DOC_LINK')}</Link>
            </Box>
            <Grid container spacing={3} p={1}>
                <Grid item xs={12} lg={7.5} xl={8.8}>
                    <TextField
                        fullWidth
                        label={t('REST_API_KEY')}
                        value={apiKey}
                        variant='outlined'
                        inputProps={{ readOnly: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleCopyApiKey}
                                        edge="end"
                                    >
                                        <ContentCopyRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={4.5} xl={3.2}>
                    <Button
                        id={'generateApiKeyButton'}
                        onClick={handleApiKeyGeneration}
                        fullWidth
                        variant='contained'
                        style={ { height: '100%', whiteSpace: 'nowrap' } }
                    >
                        {t('REST_API_GENERATE_NEW_KEY')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Card>
}
const mapStateToProps = (state: RootState) => {
    return {
        profile: state.wizardProfile.currentProfile
    }
}
export default connect(mapStateToProps)(PublicApiSettings);