import { Box, Button, ListItemText, Popover, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { t } from 'i18next';
import MarkEmailUnreadTwoToneIcon from '@mui/icons-material/MarkEmailUnreadTwoTone';
import store from 'src/redux/store';
import NotesTwoToneIcon from '@mui/icons-material/NotesTwoTone';

const IconWrapper = styled(Box)(
    ({ theme }) => `
        display: inline-flex;
        justify-content: center;
        margin-left: ${theme.spacing(1)};
        margin-right: ${theme.spacing(0.4)};
        .MuiSvgIcon-root {
            font-size: 15px;
            margin: auto;
        }
    `
);
const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        .MuiListItemText-primary {
            display: flex;
        }
    `
);

interface Props {
    notes: string;
    isSmall?: boolean;
}

const ItemNotes: React.FC<Props> = ({ notes, isSmall }) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const s = store.getState();
    const theme = useTheme();

    return (
        <>
            <Button
                sx={{ px: 1.2, py: 0.5, flexShrink: 0 }}
                startIcon={<NotesTwoToneIcon />}
                variant="text"
                color="secondary"
                onClick={() => setOpen(true)}
                ref={ref}
                size={isSmall ? 'small' : 'medium'}
            >
                {t('notes')}
            </Button>

            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Stack p={2} maxWidth="35rem">
                    <Typography variant="h5">{t('notes')}</Typography>
                    <Typography>{notes}</Typography>
                </Stack>
            </Popover>
        </>
    );
};

export default ItemNotes;
