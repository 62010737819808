import { Avatar, Badge, Box, Button, Stack, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import _ from 'lodash';
import store from 'src/redux/store';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import { RegularCustomerResource } from 'src/backend/market';
import { getAddressLine1Set, getAddressLine2Set } from 'src/utils/FormatHelpers';

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        font-size: 24px;
        width: ${theme.spacing(6.4)};
        height: ${theme.spacing(6.4)};
    `
);
const StyledIconBox = styled(Box)(
    ({ theme }) => `
        margin-top: -3px;
        margin-left: -3px;
        & .MuiSvgIcon-root {
            padding: 2px;
            background: ${theme.colors.primary.main};
            color: ${theme.colors.alpha.white[100]};
            font-size: 18px;
            border-radius: 50%;
        }
    `
);


interface Props {
    values: any;
    linkedRegularCustomer?: RegularCustomerResource;
    buttonRef: any;
    setOpen: Function;
    disabled?: boolean;
}

const CustomerDetailsDisplay: React.FC<Props> = ({ values = {}, linkedRegularCustomer, buttonRef, setOpen, disabled }) => {
    const { t } = useTranslation();
    const s = store.getState();

    const isCustomerUnset = () => {
        if(!values) return true;
        
        return (!values.contactedVia || values.contactedVia === 'NOT_SET') && 
            !values.companyName &&
            !values.note &&
            _.every(values.address, _.isEmpty) &&
            _.every(values.contactPerson, _.isEmpty);
    }
    // const priceParameter = useMemo(() => {
    //     if(!linkedRegularCustomer) return {};
    //     const parameters = {};
    //     linkedRegularCustomer.customerType.priceParameterValues.forEach((param) => {
    //         const overwrittenPriceValue = linkedRegularCustomer.overwrittenPriceValues.find(x => x.parameter.name === param.parameter.name);
    //         parameters[param.parameter.name] = overwrittenPriceValue || param;
    //     });
    //     return parameters;
    // }, [linkedRegularCustomer]);

    const getFullName = ()=> {
        return `${values?.contactPerson?.firstName} ${values?.contactPerson?.lastName}`.trim()
    }


    return <>
        <Stack direction={{ md: 'column', xl: 'row' }} textAlign={{ xs: 'center', xl: 'left' }} alignItems="center" overflow="hidden">
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={values?.contactedVia &&
                <StyledIconBox display="flex" justifySelf="flex-end" alignItems="center">
                    {values.contactedVia === 'PHONE' && <CallTwoToneIcon/>}
                    {values.contactedVia === 'EMAIL' && <AlternateEmailTwoToneIcon/>}
                    {values.contactedVia === 'IN_PERSON' && <RecordVoiceOverTwoToneIcon/>}
                    {values.contactedVia === 'WEB' && <LanguageTwoToneIcon/>}
                </StyledIconBox>}
            >
                <AvatarWrapper alt={values?.companyName || '?'} src={linkedRegularCustomer?.logo || '/static/images/avatars/3.jpg' } />
            </Badge>
            <Stack mt={{ xs: 1.5, xl: 0 }} ml={{ xs: 0, xl: 1.8 }} alignItems={{ xs: 'center', xl: 'flex-start' }} alignSelf="center" overflow="hidden" maxWidth="100%">
                <Stack direction="row" overflow="hidden" alignItems="center" maxWidth="100%">
                    <Typography variant="h4" fontWeight="bold" noWrap lineHeight={1.5} maxWidth="100%">
                        {isCustomerUnset()
                            ? t('unspecifiedCustomer')
                            : values?.companyName || getFullName() || values?.contactPerson?.email || t('unspecifiedCompany')}
                    </Typography>
                    {linkedRegularCustomer && <Tooltip title={t('regularCustomerIsLinked', { companyName: linkedRegularCustomer.companyName })}>
                        <LinkTwoToneIcon sx={{ ml: 0.5 }} fontSize="small"/>
                    </Tooltip>}
                </Stack>
                {
                    getAddressLine1Set(values.address) !== '' &&
                    <Typography variant="subtitle2" lineHeight={1.2} fontSize={14} noWrap>
                        {getAddressLine1Set(values.address)}
                    </Typography>
                }
                {
                    getAddressLine2Set(values.address) !== '' &&
                    <Typography variant="subtitle2" lineHeight={1.2} fontSize={14} noWrap>
                        {getAddressLine2Set(values.address)}
                    </Typography>
                }
            </Stack>
            <Box
                display="flex"
                justifySelf="flex-end"
                alignItems="center"
                maxWidth="100%"
                pl={{ xs: 0, xl: 1.5 }}
                pt={{ xs: 1, xl: 0 }}
                ml={{ md: 0, xl: 'auto' }}>
                <Button variant="outlined" onClick={() => setOpen(true)} ref={buttonRef} sx={{ whiteSpace: "nowrap" }} disabled={disabled}>
                    { isCustomerUnset() ? t('addCustomer') : t('editCustomer') }
                </Button>
            </Box>
        </Stack>
        {/* {(values?.contactPerson?.firstName || values?.contactPerson?.lastName ||
          values?.contactPerson?.phone || values?.note || !_.isEmpty(priceParameter)) && <Divider sx={{ my: 2 }}></Divider>}
        {(values?.contactPerson?.firstName || values?.contactPerson?.lastName) && <Stack direction="row">
            <Typography variant="subtitle2" fontWeight="bold">{t('contactPerson')}:&nbsp;</Typography>
            <Typography variant="subtitle2">{values.contactPerson.firstName} {values.contactPerson.lastName}</Typography>
        </Stack>}
        {values?.contactPerson?.phone && <Stack direction="row">
            <Typography variant="subtitle2" fontWeight="bold">{t('phone')}:&nbsp;</Typography>
            <Typography variant="subtitle2">{values.contactPerson.phone}</Typography>
        </Stack>}
        {values?.note && <Stack direction="row">
            <Typography variant="subtitle2" fontWeight="bold">{t('note')}:&nbsp;</Typography>
            <Typography variant="subtitle2">{values.note}</Typography>
        </Stack>}
        {!_.isEmpty(priceParameter) && Object.values(priceParameter).map((param: PriceManipulationParameterValueResource, index) => 
            <Stack direction="row" key={index}>
                <Typography variant="subtitle2" fontWeight="bold">{wT(param.parameter.label, s)}:&nbsp;</Typography>
                <Typography variant="subtitle2">{param.value}{param.parameter.unit}</Typography>
            </Stack>
        )} */}
    </>;
};

export default CustomerDetailsDisplay;
