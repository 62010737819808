import { Button, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';

export const DefaultHeadline = styled(Button)<{ open?: number }>(
    ({ theme, open }) => `
        justify-content: flex-start;
        font-size: 18px;
        margin-left: ${theme.spacing(-1)};
        margin-right: ${theme.spacing(-1)};
        margin-top: ${theme.spacing(-1)};
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};

        .MuiSvgIcon-root {
            font-size: 1.6rem !important;
            transform: ${open ? 'none' : 'rotate(-90deg)'};
            transition: ${theme.transitions.create(['transform'])};
        }
    `
);

interface Props {
    children?: React.ReactNode;
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    variant?: 'default' | 'page';
    disabled?: boolean;
}

const HeadlineButton: React.FC<Props> = ({ children, isOpen, setOpen, variant = 'default', disabled }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();

    return variant === 'page' ? (
        <Button
            color="secondary"
            sx={{ justifyContent: 'flex-start', fontSize: 18, px: 0, mb: 1 }}
            startIcon={<KeyboardArrowDownTwoToneIcon sx={{ fontSize: '1.6rem !important', transform: isOpen ? 'none' : 'rotate(-90deg)', transition: theme.transitions.create(['transform']) }} />}
            onClick={() => setOpen(!isOpen)}
            disabled={disabled}
        >
            {children}
        </Button>
    ) : (
        <DefaultHeadline color="inherit" open={isOpen ? 1 : 0} onClick={() => setOpen(!isOpen)} endIcon={<KeyboardArrowDownTwoToneIcon />} disabled={disabled}>
            {children}
        </DefaultHeadline>
    );
};

export default HeadlineButton;
