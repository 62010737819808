import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack, Theme,
    Typography, useMediaQuery
} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {startWalkthrough, stopWalkthrough} from "../../../redux/thunks/guidethrough.thunk";
import {useDispatch} from "react-redux";
import { updateUserWalkthroughs } from "src/redux/thunks/user.thunk";
import { INITIAL_DEMO_DIALOG } from "src/statics/statics";

const ListWrapper = styled.ul`
  margin: 0;
  padding-left: 1rem;
  list-style-type: "-";
`;

const ListItem = styled.li`
  font-size: 20px;
  font-weight: bolder;
  margin-top: 5px;
`;

interface IInitialDemoUserDialogProps {
    open: boolean;
    onClose: () => void;
    onRead: () => void;
}

const InitialDemoUserDialog: FC<IInitialDemoUserDialogProps> = ({open, onClose, onRead}) => {
    const {t} = useTranslation();
    const mediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    //region STATE
    const [step, setStep] = useState(0);
    const [isOpen, setIsOpen] = useState(open);
    //endregion

    //region LIFECYCLE
    //endregion

    //region HANDLER
    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleClose = () => {
        onRead();
        setIsOpen(false);
        dispatch(updateUserWalkthroughs({ completedWalkthroughs: [INITIAL_DEMO_DIALOG] }));
    }
    //endregion

    //region RENDER
    const renderHeader = (): JSX.Element => {
        return <Stack direction={"row"} width={"100%"} alignItems={"center"}>
            <Box flex={2}>
                <Typography variant={"h1"} fontWeight={"normal"}>{t('welcomeTo')}</Typography>
                <Typography display={"inline"} variant={"h1"}>{t('demoPortal')} </Typography>
                <Typography display={"inline"} variant={"h1"} fontWeight={"normal"}>{t('von')}</Typography>
            </Box>
            <Box flex={1}>
                <img style={{width: "100%", height: "auto"}} alt='Amplify' src='/static/images/logo/surfSolLogo.svg'/>
            </Box>
        </Stack>
    };

    const renderContent = (): JSX.Element => {
        switch (step) {
            case 0:
                return <Stack width={"100%"} gap={4} divider={<Divider/>}>
                    <div>
                        <Typography variant={"h4"}>{t('demoUserIsFor')}</Typography>
                        <ListWrapper>
                            <ListItem> <Typography
                                pl={"10px"}>{t('demoUserIsForInfo1')}</Typography>
                            </ListItem>
                            <ListItem> <Typography
                                pl={"10px"}>{t('demoUserIsForInfo2')}</Typography>
                            </ListItem>
                            <ListItem> <Typography
                                pl={"10px"}>{t('demoUserIsForInfo3')}</Typography>
                            </ListItem>
                        </ListWrapper>
                    </div>

                    <div>
                        <Typography variant={"h4"}>{t('whatIsPossibleWithDemoPortal')}</Typography>
                        <ListWrapper>
                            <ListItem> <Typography
                                pl={"10px"}>{t('whatIsPossibleWithDemoPortalInfo1')}</Typography>
                            </ListItem>
                            <ListItem> <Typography
                                pl={"10px"}>{t('whatIsPossibleWithDemoPortalInfo2')}</Typography>
                            </ListItem>
                            <ListItem> <Typography
                                pl={"10px"}>{t('whatIsPossibleWithDemoPortalInfo3')}</Typography>
                            </ListItem>
                        </ListWrapper>
                    </div>
                </Stack>
            case 1:
                return <Stack width={"100%"} gap={4} divider={<Divider/>}>
                    <div>
                        <Typography variant={"h4"}>{t('whatIsPossibleWithDemoPortal2')}</Typography>
                        <ListWrapper>
                            <ListItem>
                                <Stack direction={mediaQuery ? "column" : "row"}
                                       justifyContent={!mediaQuery && "space-between"}
                                       alignItems={!mediaQuery && "center"}
                                       gap={mediaQuery && 2}>
                                    <Typography pl={"10px"}>{t('personalGuide1')}</Typography>
                                    <img style={{width: mediaQuery ? "100%" : "30%", height: "auto", paddingLeft: !mediaQuery && "0.5rem"}}
                                         src='/static/images/demo/demo_boarding_2_1.png'/>
                                </Stack>
                            </ListItem>
                        </ListWrapper>
                    </div>

                    <div>
                        <Typography variant={"h4"}>{t('personalGuide')}</Typography>
                        <ListWrapper>
                            <ListItem> <Stack direction={mediaQuery ? "column" : "row"}
                                              justifyContent={!mediaQuery && "space-between"}
                                              alignItems={!mediaQuery && "center"}
                                              gap={mediaQuery && 2}>
                                <Typography pl={"10px"}>{t('personalGuide3')}</Typography>
                                <img style={{width: mediaQuery ? "100%" : "30%", height: "auto", paddingLeft: !mediaQuery && "0.5rem"}}
                                     src='/static/images/demo/demo_boarding_2_2.png'/>
                            </Stack>
                            </ListItem>
                        </ListWrapper>
                    </div>
                </Stack>
            case 2:
                return <Stack width={"100%"} gap={4} divider={<Divider/>}>
                    <div>
                        <img src={"/static/images/demo/demo_boarding_3.svg"} style={{
                            width: "100%",
                            height: "auto"
                        }} alt={"Demo Boarding 3"}/>
                    </div>
                </Stack>
            case 3:
                return <Stack width={"100%"} gap={4} divider={<Divider/>}>
                    <div>
                        <Stack direction={mediaQuery ? "column" : "row"}
                               justifyContent={!mediaQuery && "space-between"}
                               gap={mediaQuery && 2}>
                            <Stack>
                                <Typography variant={"h4"}>{t('whatIsPossibleWithDemoPortal4')}</Typography>
                                <ListWrapper>
                                    <ListItem>
                                        <Typography pl={"10px"}>{t('personalGuide4')}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography pl={"10px"} fontWeight={"bolder"}>{t('personalGuide5')}</Typography>
                                    </ListItem>
                                </ListWrapper>
                            </Stack>

                            <img style={{
                                width: mediaQuery ? "100%" : "50%",
                                height: mediaQuery ? "100%": "50%",
                                objectFit: "fill",
                                paddingLeft: !mediaQuery && "2rem"
                            }}
                                 src='/static/images/demo/demo_boarding_4.png'/>
                        </Stack>

                    </div>
                </Stack>
        }

        return <>error</>
    };

    const renderActions = (): JSX.Element => {
        switch (step) {
            case 3:
                return <Stack direction={"row"} width={"100%"} justifyContent={"space-between"} gap={3}>
                    <Button onClick={handleBack} variant={"contained"} color={"inherit"}
                            sx={{minWidth: "20%"}}>{t('back')}</Button>
                    <Button onClick={handleClose} color={"success"}
                            sx={{minWidth: "20%"}}
                            variant={"contained"}>{t('iHaveRedAndUnderstoodEverything')}</Button>
                </Stack>
            default:
                return <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}  gap={3}>
                    {
                        step !== 0 ?
                            <Button onClick={handleBack} variant={"contained"} color={"inherit"}
                                    sx={{minWidth: "20%"}}>{t('back')}</Button> : <div/>
                    }
                    <Button onClick={handleNext} variant={"contained"} sx={{minWidth: "20%"}}>{t('further')}</Button>
                </Stack>
        }
    };
    //endregion


    return <Dialog open={isOpen} onClose={onClose} fullWidth={true} sx={{
        "& .MuiDialog-paper": {
            padding: 0.5,
            minHeight: "50vh",
            minWidth: "50vw"
        }
    }}>
        <DialogTitle>
            {renderHeader()}
        </DialogTitle>
        <DialogContent sx={{mt: 5, mb: 9, ml: 2, mr: 2}}>
            {renderContent()}
        </DialogContent>
        <DialogActions sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {renderActions()}
        </DialogActions>
    </Dialog>
}

export default InitialDemoUserDialog;