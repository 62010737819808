import { Card, CircularProgress, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import CategoryArea from './CategoryArea';
import ItemArea from './ItemArea';
import { t } from 'i18next';
import { ILayer, ILayerSubGeometryPackage } from 'src/backend/summary/resources/ILayer';
import DragAndDropFileInput from 'src/components/input/DragAndDropFileInput';
import { RootState, snackContext } from 'src/redux/store';
import { ALLOWED_3D_FILE_TYPES, ALLOWED_GENERAL_FILE_TYPES, SPECIAL_3D_ITEM, SPECIAL_GENERIC_ITEM } from 'src/statics/statics';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { addFile } from 'src/redux/thunks/fileManager.thunk';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
//import { addStorageEntriesToPart, updatePartParameters } from 'src/redux/shared/proCalc.thunk';
import { TechnicalDrawingDto } from 'src/backend/market/models/TechnicalDrawingDto';
import { TechnicalDrawingRestControllerService } from 'src/backend/market/services/TechnicalDrawingRestControllerService'; // Import the service
import { AppDispatch } from 'src/redux/store';
import { CalculationParameterDto, AdditionalStorageDto, InternalCalculationRestControllerService } from '../../../../backend/internalCalc';

interface ICategorySelector {
    maxWidth?: number | string;
    partId?: number;
    secondLayerItems: Array<ILayer>;
    closeOnLoad?: () => void;
    activate3dUpload?: boolean;
}

const ItemSelector: React.FC<ICategorySelector> = ({ maxWidth, partId, secondLayerItems = [], closeOnLoad, activate3dUpload = false }) => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState<number>(-1);
    const [searchText, setSearchText] = useState<string>('');
    const [tmp3dFiles, setTmp3dFiles] = useState<File[]>([]);
    const [amountOfFiles, setAmountOfFiles] = useState<number>(0);
    const [is3dFileLoading, set3dFileLoading] = useState<boolean>(false);
    const [areFilesLoading, setFilesLoading] = useState<boolean>(false);
    const [isTechnicalDrawingLoading, setTechnicalDrawingLoading] = useState<boolean>(false);
    const [amountOfTechnicalDrawingFiles, setAmountOfTechnicalDrawingFiles] = useState<number>(0);
    const [technicalDrawingFileAnalyzed, setTechnicalDrawingFileAnalyzed] = useState<number>(0);
    const theme = useTheme();

    const allowedGeneralFileTypes = ALLOWED_GENERAL_FILE_TYPES;
    const allowed3DFileTypes = ALLOWED_3D_FILE_TYPES;
    const allowedFileTypes = [...allowed3DFileTypes, ...allowedGeneralFileTypes];

    const handleItemClick = (itemId: number, subGeometryPackage: ILayerSubGeometryPackage) => {
        if (partId) dispatch(updateProCalc(ProCalcUpdateTypes.CREATE_FRAGMENT, { partId, itemId, geometryPackage: subGeometryPackage.pkg, geometryPackageName: subGeometryPackage.name }));
        else dispatch(updateProCalc(ProCalcUpdateTypes.CREATE_PART, { itemId }));
        if (closeOnLoad) closeOnLoad();
    };

    const handleFileUpload = async (fileList: FileList) => {
        const files = Array.from(fileList);
        if (!files) return;

        const threeDFiles = [];
        const pdfFiles = [];
        const technicalDrawingFiles = [];
        const specialGenericItem = secondLayerItems.find((secondLayerItem) => secondLayerItem.nameKey === SPECIAL_GENERIC_ITEM);
        if (!specialGenericItem) return;

        setFilesLoading(true);

        for (const file of files) {
            const fileExtension = '.' + file.name.split('.').pop().toLowerCase();

            if (allowedGeneralFileTypes.includes(fileExtension)) {
                technicalDrawingFiles.push(file);
            } else if (allowed3DFileTypes.includes(fileExtension)) {
                threeDFiles.push(file);
            } else {
                snackContext.enqueueSnackbar(t('fileHasInvalidFileExtension'), { variant: 'error' });
            }
        }
        setFilesLoading(false);

        if (threeDFiles.length > 0) {
            set3dFileLoading(true);
            setTmp3dFiles(threeDFiles);
            setAmountOfFiles(threeDFiles.length);
        }

        if (technicalDrawingFiles.length > 0) {
            setAmountOfTechnicalDrawingFiles(technicalDrawingFiles.length);
            setTechnicalDrawingFileAnalyzed(0);
            let cnt = 0;
            for (const file of technicalDrawingFiles) {
                setTechnicalDrawingFileAnalyzed(cnt);
                await new Promise(async (resolve) => {
                    setTechnicalDrawingLoading(true);
                    const fileResource = await dispatch(addFile(file, file.name, file.name));

                    const additionalStorage: Array<AdditionalStorageDto> = [{ key: 'originalFileName', value: file.name }];

                    //pdf files push
                    resolve(fileResource);
                    console.log("File Resource resolved");
                    console.log(fileResource);

                    let fileType = TechnicalDrawingDto.fileType.PDF;
                    if (fileResource.fileType == 'application/dwg' || fileResource.fileType == 'application/acad' || fileResource.fileType == 'image/vnd.dwg' || fileResource.fileType == 'image/x-dwg') {
                        fileType = TechnicalDrawingDto.fileType.DWG;
                    } else if (fileResource.fileType == 'application/dxf' || fileResource.fileType == 'image/vnd.dwg' || fileResource.fileType == 'image/x-dwg') {
                        fileType = TechnicalDrawingDto.fileType.DXF;
                    }

                    const technicalDrawing: TechnicalDrawingDto = {
                        url: fileResource.url,
                        fileType: fileType //TechnicalDrawingDto.fileType.PDF,
                    };

                    const technicalDrawingData = await dispatch(extractTechnicalDrawingData(technicalDrawing));
                    console.log("Tecnical drawing data extracted");
                    console.log(technicalDrawingData);

                    const calculationParameters: Array<CalculationParameterDto> = [];
                    for (const key in technicalDrawingData) {
                      if (technicalDrawingData.hasOwnProperty(key) && technicalDrawingData[key] != null && technicalDrawingData[key] != 0 && technicalDrawingData[key] != 'nicht angegeben' && key != 'name_des_teils') {
                        const calculationParameter: CalculationParameterDto = { name: key, value: technicalDrawingData[key] };
                        calculationParameters.push(calculationParameter);
                        additionalStorage.push({ key: 'extracted_' + key, value: technicalDrawingData[key] });                  
                      }
                    }  

                    setTechnicalDrawingLoading(false);

                    let partName = file.name;
                    if (technicalDrawingData.hasOwnProperty('name_des_teils')) {
                        partName = technicalDrawingData['name_des_teils'];
                    }

                    const partId = await dispatch(
                        updateProCalc(ProCalcUpdateTypes.CREATE_PART, {
                            itemId: specialGenericItem.id,
                            initParameters: calculationParameters,
                            additionalStorage,
                            fileResource,
                            userDefinedPartName: partName
                        })
                    );    

                    //await InternalCalculationRestControllerService.addThumbnailToPart(calc.id, partId, { thumbnailName: fileResource.thumbnail });         
                }); 
                cnt++;               
            }            
        }
    };

    const handleOnViewerLoader = (stats: ModelStats) => {
        const file = tmp3dFiles[0];
        if (!file) return;

        const special3DItem = secondLayerItems.find((secondLayerItem) => secondLayerItem.nameKey === SPECIAL_3D_ITEM);
        if (!special3DItem) return;

        dispatch(updateProCalc(ProCalcUpdateTypes.CREATE_3D_PART, { itemId: special3DItem.id, file, stats }));

        const files = [...tmp3dFiles];
        files.shift(); // remove file
        setTmp3dFiles(files);
        if (files.length === 0) set3dFileLoading(false);
    };

    useEffect(() => {
        const handleDragEvent = (event) => {
            event.preventDefault();
        };
        const handleDrop = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
                handleFileUpload(event.dataTransfer.files);
            }
        };
        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragenter', handleDragEvent);
        window.addEventListener('dragleave', handleDragEvent);
        window.addEventListener('dragover', handleDragEvent);

        return () => {
            window.removeEventListener('drop', handleDrop);
            window.removeEventListener('dragenter', handleDragEvent);
            window.removeEventListener('dragleave', handleDragEvent);
            window.removeEventListener('dragover', handleDragEvent);
        };
    }, []);

    return (
        <Card sx={{ maxWidth }}>
            <Stack direction="row" position="relative">
                {activate3dUpload && (
                    <Stack p={2} flexGrow={1}>
                        <DragAndDropFileInput
                            style={{ flexGrow: 1 }}
                            accept={allowedFileTypes.join(', ')}
                            primaryText={t('dragAndDropYour3dFilesHere')}
                            secondaryText={allowedFileTypes.join(' | ').toUpperCase()}
                            fileSelected={handleFileUpload}
                        />
                        {tmp3dFiles[0] && (
                            <div style={{ position: 'absolute', opacity: 0 }}>
                                <SSA3DViewer file={tmp3dFiles[0]} onViewerLoaded={handleOnViewerLoader} />
                            </div>
                        )}
                    </Stack>
                )}
                <Stack flexGrow={1}>
                    <Stack id={'categoriesAndSearchBar'}>
                        <CategoryArea selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} isFragment={!!partId} />
                        <TextField
                            id={'searchPartsTextField'}
                            sx={{ px: 2 }}
                            variant="outlined"
                            placeholder={t('searchItems')}
                            value={searchText}
                            size="small"
                            onChange={(e) => setSearchText(e.target.value)}
                            fullWidth
                        />
                    </Stack>
                    <ItemArea searchText={searchText} category={selectedCategory} selectedItem={handleItemClick} isFragment={!!partId} />
                </Stack>
                {(areFilesLoading || is3dFileLoading) && (
                    <Stack position="absolute" left={0} right={0} bottom={0} top={0} alignItems="center" justifyContent="center" bgcolor={theme.colors.alpha.white[70]}>
                        <CircularProgress />
                        <Typography mt={2} variant="h5">
                            {amountOfFiles > 1 ? t('analyzeFileXOfZ', { x: amountOfFiles - tmp3dFiles.length + 1, z: amountOfFiles }) : t('analyzeFile')}
                        </Typography>
                    </Stack>
                )}
                {isTechnicalDrawingLoading && (
                    <Stack position="absolute" left={0} right={0} bottom={0} top={0} alignItems="center" justifyContent="center" bgcolor={theme.colors.alpha.white[70]}>
                        <CircularProgress />
                        <Typography mt={2} variant="h5">
                            {amountOfTechnicalDrawingFiles > 1 ? t('analyzeFileXOfZ', { x: technicalDrawingFileAnalyzed + 1, z: amountOfTechnicalDrawingFiles }) : t('analyzeFile')}
                        </Typography>
                    </Stack>
                )}                
            </Stack>
        </Card>
    );
};

const extractTechnicalDrawingData = (technicalDrawingDto: TechnicalDrawingDto) => async (dispatch: AppDispatch) => {
    const result = await TechnicalDrawingRestControllerService.extractData(technicalDrawingDto);
    return result;
};

const mapStateToProps = (state: RootState) => ({
    secondLayerItems: state.proCalc.secondLayerItems
});
export default connect(mapStateToProps)(ItemSelector);
