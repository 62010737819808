import StraightenTwoToneIcon from '@mui/icons-material/StraightenTwoTone';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import FormatPaintTwoToneIcon from '@mui/icons-material/FormatPaintTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import FormatColorFillTwoToneIcon from '@mui/icons-material/FormatColorFillTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import TextureTwoToneIcon from '@mui/icons-material/TextureTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import ViewModuleTwoToneIcon from '@mui/icons-material/ViewModuleTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import LinearScaleTwoToneIcon from '@mui/icons-material/LinearScaleTwoTone';
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';
import HorizontalRuleTwoToneIcon from '@mui/icons-material/HorizontalRuleTwoTone';
import ViewWeekTwoToneIcon from '@mui/icons-material/ViewWeekTwoTone';
import CallMadeTwoToneIcon from '@mui/icons-material/CallMadeTwoTone';
import CreateNewFolderTwoToneIcon from '@mui/icons-material/CreateNewFolderTwoTone';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import CallSplitTwoToneIcon from '@mui/icons-material/CallSplitTwoTone';
import FormatAlignJustifyTwoToneIcon from '@mui/icons-material/FormatAlignJustifyTwoTone';
import InputTwoToneIcon from '@mui/icons-material/InputTwoTone';

interface Props {
    icon: string;
}

const MuiIcon: React.FC<Props> = ({ icon }) => {
    switch (icon) {
        case 'StraightenTwoTone':
            return <StraightenTwoToneIcon />;
        case 'ViewInArTwoTone':
            return <ViewInArTwoToneIcon />;
        case 'FormatPaintTwoTone':
            return <FormatPaintTwoToneIcon />;
        case 'BallotTwoTone':
            return <BallotTwoToneIcon />;
        case 'FormatColorFillTwoTone':
            return <FormatColorFillTwoToneIcon />;
        case 'CloseTwoTone':
            return <CloseTwoToneIcon />;
        case 'TextureTwoTone':
            return <TextureTwoToneIcon />;
        case 'CheckBoxOutlineBlankTwoTone':
            return <CheckBoxOutlineBlankTwoToneIcon />;
        case 'ViewModuleTwoTone':
            return <ViewModuleTwoToneIcon />;
        case 'CircleTwoTone':
            return <CircleTwoToneIcon />;
        case 'LinearScaleTwoTone':
            return <LinearScaleTwoToneIcon />;
        case 'BuildTwoTone':
            return <BuildTwoToneIcon />;
        case 'DonutSmallTwoTone':
            return <DonutSmallTwoToneIcon />;
        case 'HorizontalRuleTwoTone':
            return <HorizontalRuleTwoToneIcon />;
        case 'ViewWeekTwoTone':
            return <ViewWeekTwoToneIcon />;
        case 'CallMadeTwoTone':
            return <CallMadeTwoToneIcon />;
        case 'CreateNewFolderTwoTone':
            return <CreateNewFolderTwoToneIcon />;
        case 'GpsFixedTwoTone':
            return <GpsFixedTwoToneIcon />;
        case 'CalculateTwoTone':
            return <CalculateTwoToneIcon />;
        case 'CallSplitTwoTone':
            return <CallSplitTwoToneIcon />;
        case 'FormatAlignJustifyTwoTone':
            return <FormatAlignJustifyTwoToneIcon />;
        case 'InputTwoTone':
            return <InputTwoToneIcon />;
        default:
            return <></>;
    }
};

export default MuiIcon;
