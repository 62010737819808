/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AiDataExtraction } from './AiDataExtraction';
import type { CoaterUserResource } from './CoaterUserResource';
import type { PriceManipulationParameterResource } from './PriceManipulationParameterResource';
import type { ThreeDAiDataExtraction } from './ThreeDAiDataExtraction';

export type InternalCalculationMailUpdateDataDto = {
    calculationUid?: string;
    coaterUser?: CoaterUserResource;
    priceManipulationParameterResources?: Array<PriceManipulationParameterResource>;
    extractionType?: InternalCalculationMailUpdateDataDto.extractionType;
    aiDataExtraction?: AiDataExtraction;
    threeDAiDataExtraction?: ThreeDAiDataExtraction;
    firstName?: string;
    lastName?: string;
    email?: string;
}

export namespace InternalCalculationMailUpdateDataDto {

    export enum extractionType {
        AI = 'AI',
        THREE_D_FILES = 'THREE_D_FILES',
        TECHNICAL_DRAWING_FILES = 'TECHNICAL_DRAWING_FILES',
    }


}
