import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {WizardUserStatisticResource} from "../../backend/market";


export interface IStatisticReducer {
    statistic: WizardUserStatisticResource | undefined;
    delta: WizardUserStatisticResource | undefined;
}

const initialState: IStatisticReducer = {
    statistic: undefined,
    delta: undefined
}

const reducers = {
    setStatistic: (state: IStatisticReducer, action: PayloadAction<WizardUserStatisticResource>) => {
        state.statistic = action.payload
    },
    setDelta: (state: IStatisticReducer, action: PayloadAction<WizardUserStatisticResource>) => {
        state.delta = action.payload
    }
}

export const slice = createSlice({
    name: "statistics",
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;