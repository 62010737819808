import { CircularProgress, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CalculationPartResource, CalculationResultResource, SetObjectMetadataResource } from 'src/backend/internalCalc';
import store, { RootState, useDispatch, useSelector } from 'src/redux/store';
import { useFormikContext } from 'formik';
import { wT } from 'src/utils/wizardTranslations';
import { renderParameters } from 'src/utils/CalcHelpers';
import { ParameterArray, Section } from 'src/utils/CalcHelpers';
import { renderToStaticMarkup } from 'react-dom/server';
import Accordion from 'src/components/display/Accordion/Accordion';
import CalcParameters from './CalcParameters';
import CalcMobileParameters from './CalcMobileParameters';
import { selectProCalcSettings } from 'src/redux/shared/proCalc.common';
import { connect } from 'react-redux';
import MuiIcon from 'src/components/display/MuiIcon/MuiIcon';

const ParametersWrapper = styled('div')(
    ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(2.5)} ${theme.spacing(3)};
        padding: ${theme.spacing(2)};
        padding-bottom: ${theme.spacing(2.5)};
        
        & > *,
        .MuiFormControl-root,
        .MuiOutlinedInput-root,
        .dimensions-infos {
            width: 13rem;
            min-width: 0;
        }
    `
);

interface Props {
    part: CalculationPartResource;
    partCostResult?: CalculationResultResource;
    section: Section;
    selectedSectionId: string;
    setSelectedSectionId: (newId: string) => void;
    metadata: SetObjectMetadataResource[];
    holeRecognitionLoaders: { [partId: number]: boolean };
}

const CalcSection: React.FC<Props> = ({ part, partCostResult, section, selectedSectionId, setSelectedSectionId, metadata, holeRecognitionLoaders }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const s = store.getState();
    const { values } = useFormikContext();
    const settings = useSelector(selectProCalcSettings);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const sectionDefinition = useMemo(() => {
        return (part.calculationParameters?.sectionDefinitions || []).find((definition) => definition.id === section.id);
    }, [section]);

    const isLoading = useMemo(() => {
        return sectionDefinition?.image === 'BallotTwoTone' && holeRecognitionLoaders[part.id];
    }, [sectionDefinition, holeRecognitionLoaders[part.id]]);

    const sectionTitle = useMemo(() => {
        if (section.id === 'geometryPackageSection') {
            return (
                <>
                    <MuiIcon icon="StraightenTwoTone" />
                    <Typography ml={1} variant="h4">
                        {t('geometry')}
                    </Typography>
                </>
            );
        }

        return (
            <>
                {isLoading ? <CircularProgress color="inherit" size={22} sx={{ p: '2px' }} /> : <MuiIcon icon={sectionDefinition.image} />}
                <Typography ml={1} variant="h4" noWrap>
                    {sectionDefinition.nameDictId ? wT(sectionDefinition.nameDictId, s) : sectionDefinition.name}
                </Typography>
            </>
        );
    }, [sectionDefinition, section, isLoading]);

    const renderPreviewParams = () => {
        const params = renderParameters(section.groupedParameters, values as { [key: string]: string }, partCostResult.guiStates, settings.numberFormat, getField);
        let paramsText = renderToStaticMarkup(<>{params}</>);
        if (paramsText.endsWith(', ')) paramsText = paramsText.slice(0, -2);

        return (
            <Typography variant="subtitle2" ml={2} sx={{ opacity: 0.7 }} noWrap>
                ({paramsText})
            </Typography>
        );
    };

    const getField = (label, value, key = null, unit = null) => {
        if (value === '') return <Fragment key={key}></Fragment>;
        const text = `${value} ${unit || ''}`.trim();
        return <Fragment key={key}>{text}, </Fragment>;
    };

    const isOpen = section.id === selectedSectionId;

    return isSmallScreen ? (
        <CalcMobileParameters
            parameters={section.groupedParameters as ParameterArray}
            sectionTitle={sectionTitle}
            guiStates={partCostResult.guiStates}
            id={part.id}
            additionalStorage={part.additionalStorage}
        />
    ) : (
        <Accordion
            accordionHeader={
                <>
                    {sectionTitle}
                    {!isOpen && renderPreviewParams()}
                </>
            }
            accordionId={section.id}
            openAccordionId={selectedSectionId}
            setOpenAccordionId={setSelectedSectionId}
            hideFirstBorder={true}
            isLoading={isLoading}
        >
            <ParametersWrapper>
                <CalcParameters
                    id={part.id}
                    partId={part.id}
                    guiStates={partCostResult.guiStates}
                    parameters={section.groupedParameters as ParameterArray}
                    metadata={metadata}
                    isSmall={true}
                    additionalStorage={part.additionalStorage}
                />
            </ParametersWrapper>
        </Accordion>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        holeRecognitionLoaders: state.proCalc.holeRecognitionLoaders
    };
};
export default connect(mapStateToProps)(CalcSection);
