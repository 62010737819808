import { Button, Divider, IconButton, List, Popover, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { t } from 'i18next';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/store';
import { SurchargeOverrideStorageResource, SurchargeReportEntryResource } from 'src/backend/internalCalc';
import SurchargeListItem from '../../SurchargeListItem';

interface Props {
    subCalculationIdentificationKey: string;
    surchargeReport: Array<SurchargeReportEntryResource>;
    surchargeOverrides?: Array<SurchargeOverrideStorageResource>;
    isSmall?: boolean;
    disabled?: boolean;
}

const ItemSurcharges: React.FC<Props> = ({ subCalculationIdentificationKey, surchargeReport, surchargeOverrides, isSmall, disabled }) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);

    const amountOfAppliedSurcharges = useMemo(() => {
        const amount = (surchargeReport || []).filter((surcharge) => {
            const foundOverride = (surchargeOverrides || []).find(
                (override) =>
                    override.subCalculationIdentificationKey === subCalculationIdentificationKey &&
                    override.surchargePackage === surcharge.surchargeFunctionPkg &&
                    override.surchargeName === surcharge.surchargeFunctionName
            );

            return foundOverride != null ? !foundOverride.deactivateSurcharge : surcharge.applied;
        })?.length;
        if (!amount) return;
        return ' (' + amount + ')';
    }, [surchargeReport, surchargeOverrides]);

    return (
        <>
            <Button
                sx={{ px: 1.2, py: 0.5, flexShrink: 0 }}
                startIcon={<LocalOfferTwoToneIcon />}
                variant="text"
                color="secondary"
                onClick={() => setOpen(true)}
                ref={ref}
                disabled={disabled}
                size={isSmall ? 'small' : 'medium'}
            >
                {t('surcharges')}
                {amountOfAppliedSurcharges}
            </Button>

            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                <Stack p={3} maxWidth="32rem" maxHeight="50rem">
                    <Typography variant="h3" textAlign="center">
                        {t('surcharges')}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom textAlign="center" mb={0}>
                        {t('subSurchargesExplanation')}
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 1.5 }}></Divider>
                    <List disablePadding component="div" sx={{ mx: -2 }}>
                        {surchargeReport.map((surcharge, index) => (
                            <SurchargeListItem key={index} surcharge={surcharge} subCalculationIdentificationKey={subCalculationIdentificationKey} />
                        ))}
                    </List>
                </Stack>
            </Popover>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        surchargeOverrides: state.proCalc.surchargeOverrides
    };
};
export default connect(mapStateToProps)(ItemSurcharges);
