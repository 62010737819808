import {GridSortModel} from "@mui/x-data-grid";
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import _ from "lodash";
import {InquiryFilterDto, InquiryResource} from '../../backend/market';

//INTERFACE AND INITIAL STATE
interface RequestReducerState {
    inquiries: { [key: string]: InquiryResource };
    updatedAt: string | null;
    loading: boolean;
    loaders: { [page: number]: boolean },
    importLoading: boolean;
    inquiriesTablePaging: {
        page: number;
        pageSize: number;
        fetchedPages: number[];
        rowCount: number;
    },
    inquiriesTableSort: GridSortModel;
    inquiryFilter: InquiryFilterDto;
}

const initFilters = {
    states: [],
    itemTypes: [],
    partCount: [{partCountMinIncl: 0, partCountMaxIncl: 0}],
    searchString: "",
    prices: [{fromIncl: 0, toIncl: 0}],
};
const initialState: RequestReducerState = {
    inquiries: {},
    loading: true,
    loaders: {},
    importLoading: false,
    updatedAt: null,
    inquiriesTablePaging: {
        page: 0,
        pageSize: 0,
        fetchedPages: [],
        rowCount: 0,
    },
    inquiriesTableSort: [],
    inquiryFilter: initFilters,
};

//HELPER FUNCTIONS
const reduceInquiryArrayToMap = (inquiries: InquiryResource[]): { [key: string]: InquiryResource } => {
    return inquiries.reduce((acc, inquiry) => {
        return {...acc, [inquiry.id]: inquiry};
    }, {} as { [key: string]: InquiryResource });
};

//REDUCERS
const reducers = {
    setLoading: (state: RequestReducerState, action: PayloadAction<boolean>) => {
        return Object.assign({}, state, {loading: action.payload});
    },
    resetLoaders: (state: RequestReducerState) => {
        state.loaders = {};
    },
    setLoader: (state: RequestReducerState, action: PayloadAction<{ page: number, isLoading: boolean }>) => {
        state.loaders[action.payload.page] = action.payload.isLoading;
    },
    replaceInquiries: (state: RequestReducerState, action: PayloadAction<InquiryResource[]>) => {
        const newInquiries = {};

        for (let i = 0; i < action.payload.length; i++) {
            newInquiries[action.payload[i].id] = action.payload[i];
        }

        state.updatedAt = new Date().toISOString();
        state.inquiries = newInquiries;
        state.loading = false;
        return state;
    },

    updateInquiries: (state: RequestReducerState, action: PayloadAction<InquiryResource[]>) => {
        const newInquiries = reduceInquiryArrayToMap(action.payload);
        const cState = {...state};
        cState.updatedAt = new Date().toISOString();
        cState.loading = false;
        cState.inquiries = {...cState.inquiries, ...newInquiries};
        return cState;
    },
    updateSeen: (state: RequestReducerState, action: PayloadAction<{ inquiryId: string, seen: boolean }>) => {
        state.updatedAt = new Date().toISOString();
        state.inquiries[action.payload.inquiryId].seen = action.payload.seen;
    },
    updateStatus: (state: RequestReducerState, action: PayloadAction<{ inquiryId: string, status: string }>) => {
        state.updatedAt = new Date().toISOString();
        if (_.has(state.inquiries, action.payload.inquiryId)) {
            state.inquiries[action.payload.inquiryId].state = action.payload.status;
        }
    },
    deleteInquiry: (state: RequestReducerState, action: PayloadAction<string>) => {
        state.updatedAt = new Date().toISOString();
        delete state.inquiries[action.payload];
    },

    updatePageSize: (state: RequestReducerState, action: PayloadAction<{ pageSize: number }>) => {
        state.inquiriesTablePaging = {...state.inquiriesTablePaging, ...action.payload};
    },
    updatePage: (state: RequestReducerState, action: PayloadAction<{ page: number }>) => {
        state.inquiriesTablePaging = {...state.inquiriesTablePaging, ...action.payload};
    },
    addFetchedPage: (state: RequestReducerState, action: PayloadAction<number>) => {
        state.inquiriesTablePaging = {
            ...state.inquiriesTablePaging,
            fetchedPages: [...new Set([...state.inquiriesTablePaging.fetchedPages, action.payload])]
        };
    },
    updateRowCount: (state: RequestReducerState, action: PayloadAction<number>) => {
        state.inquiriesTablePaging = {...state.inquiriesTablePaging, rowCount: action.payload};
    },
    resetSeenPages: (state: RequestReducerState) => {
        state.inquiriesTablePaging.fetchedPages = [];
    },

    updateGridSortModel: (state: RequestReducerState, action: PayloadAction<GridSortModel>) => {
        state.inquiriesTableSort = action.payload;
    },
    updateGridFilter: (state: RequestReducerState, action: PayloadAction<InquiryFilterDto>) => {
        state.inquiryFilter = action.payload;
    },
    updateImportLoading: (state: RequestReducerState, action: PayloadAction<boolean>) => {
        state.importLoading = action.payload;
    }
}

//EXPORTS
export const slice = createSlice({
    name: 'inquiries',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
