import {GridSortModel} from "@mui/x-data-grid";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    CancelablePromise,
    InquiryPageResultResource,
    PriceCalcRestControllerService
} from "../../backend/market";
import {slice} from "../slices/priceCalc.reducer";

import {AppDispatch, RootState} from "../store";
//INQUIRIES CRUD
const runningPromises = [];
export const loadPriceCalcs = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log("PRICE CALCS :: LOAD")
    const sortModel = getState().priceCalc.priceCalcsTableSort;
    const page = getState().priceCalc.priceCalcsTablePaging.page;
    const pageSize = getState().priceCalc.priceCalcsTablePaging.pageSize;

    dispatch(slice.actions.setLoading(true));
    const userId = getState().user.currentUser?.id;
    if (!userId) {
        console.log("User id not there")
        return
    }
    if (pageSize === 0) {
        console.log("No Page size!")
        return
    }
    try {
        let sortingString;
        if (sortModel) {
            sortingString = buildSortingString(sortModel);
            console.log("sortingString", sortingString);
        }
        let promise: CancelablePromise<InquiryPageResultResource>;
        promise = PriceCalcRestControllerService.getAllPriceCalcs(userId, page, pageSize, sortingString);

        //Cancel all running promises!
        if (runningPromises.length > 0) {
            runningPromises.forEach(p => p.cancel());
        }
        runningPromises.push(promise);
        const priceCalcs = await promise;
        console.log("FETCH :: PRICE CALCS :: INITIAL", priceCalcs);
        dispatch(slice.actions.addFetchedPage(page));
        dispatch(slice.actions.replacePriceCalcs(priceCalcs.data));
        dispatch(slice.actions.updateRowCount(priceCalcs.totalElements));
    } catch (e) {
        console.log("FETCH :: PRICE CALCS :: ERROR", e);
    }
}
export const updatePriceCalcs = (page: number = 0, pageSize: number = 10) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const sortModel = getState().priceCalc.priceCalcsTableSort;

    const fetchedPages = getState().priceCalc.priceCalcsTablePaging.fetchedPages;
    if (pageSize === 0) {
        return
    }

    if (fetchedPages.includes(page)) {
        return;
    }
    dispatch(slice.actions.setLoading(true));
    const userId = getState().user.currentUser.id;
    try {
        let sortingString;
        if (sortModel) {
            sortingString = buildSortingString(sortModel);
        }
        const priceCalcs = await PriceCalcRestControllerService.getAllPriceCalcs(userId, page, pageSize, sortingString);
        console.log("FETCH :: PRICE CALCS :: UPDATE", priceCalcs);
        dispatch(slice.actions.updatePriceCalcs(priceCalcs.data));
        dispatch(slice.actions.updateRowCount(priceCalcs.totalElements));
        dispatch(slice.actions.addFetchedPage(page));
        dispatch(slice.actions.setLoading(false));
    } catch (e) {
        console.log("FETCH :: PRICE CALCS :: ERROR", e);
    }
}
export const deletePriceCalc = (priceCalcId: number, callback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.deletePriceCalc(priceCalcId));
    dispatch(slice.actions.updateRowCount(getState().priceCalc.priceCalcsTablePaging.rowCount - 1));
    await PriceCalcRestControllerService.deletePriceCalc(priceCalcId);
    dispatch(loadPriceCalcs());
    callback();
}

//PAGING
export const updatePage = (page?: number) => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.updatePage({page: page}));
}
export const updatePageSize = (pageSize?: number) => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.updatePageSize({pageSize: pageSize}));
    dispatch(loadPriceCalcs());
}

//SORTING
export const updateSort = (sortModel: GridSortModel) => async (dispatch: AppDispatch, getState) => {
    console.log("UPDATE SORT", sortModel);
    dispatch(slice.actions.updateGridSortModel(sortModel));
    dispatch(resetPageAndLoadFirst());
}

//HELPER
export const buildSortingString = (sortModel: GridSortModel): string | null => {
    if (sortModel.length === 0) {
        return null;
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return sortModel[0].field + capitalizeFirstLetter(sortModel[0].sort);
}
export const resetPageAndLoadFirst = createAsyncThunk<void, null, { state: RootState }>('priceCalcs/resetPageAndLoadFirst', async (_, {dispatch}) => {
    dispatch(updatePage(0));
    dispatch(loadPriceCalcs());
});
