import { BinaryTermResource, VariableResource } from 'src/backend/coreCalc';

export type TermOperatorType = {
    symbol: '+' | '-' | '×' | '÷';
    name: string;
    type: BinaryTermResource.operator;
};

export const TermOperators: Array<TermOperatorType> = [
    { symbol: '+', name: 'Plus-Verkettung', type: BinaryTermResource.operator.ADD },
    { symbol: '-', name: 'Minus-Verkettung', type: BinaryTermResource.operator.SUBTRACT },
    { symbol: '×', name: 'Mal-Verkettung', type: BinaryTermResource.operator.MULTIPLY },
    { symbol: '÷', name: 'Durch-Verkettung', type: BinaryTermResource.operator.DIVIDE }
];
export const TermOperatorTypes = TermOperators.map((operator) => operator.type.toString());

export type VariablesInTerm = Map<string, { count: number; minDepth: number; variable: VariableResource }>;
