import { Button, Stack, styled, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { useTranslation } from 'react-i18next';

const DropArea = styled('label')<{ dragActive: number }>(
    ({ theme, dragActive }) => `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dashed;
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(2)};
        border-color: ${dragActive ? theme.colors.alpha.black[30] : theme.colors.alpha.black[10]};
        transition: ${theme.transitions.create(['border-color'], { duration: theme.transitions.duration.short })};

        &.disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }

        :hover {
            border-color: ${theme.colors.alpha.black[30]};
        }
        :active {
            border-color: ${theme.colors.alpha.black[70]};
        }
    `
);
const FileInput = styled('input')(
    ({ theme }) => `
        position: absolute;
        opacity: 0;
        pointer-events: none;
    `
);

interface Props {
    fileSelected?: Function;
    style?: Object;
    isMultiple?: boolean;
    disabled?: boolean;
    accept?: string;
    primaryText?: string;
    secondaryText?: string;
}

const DragAndDropFileInput: React.FC<Props> = ({ fileSelected, style, isMultiple = true, disabled, accept, primaryText, secondaryText }) => {
    const [dragActive, setDragActive] = useState(false);
    const { t } = useTranslation();
    const inputRef = useRef(null);

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'dragenter' || event.type === 'dragover') {
            setDragActive(true);
        } else if (event.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            fileSelected(event.dataTransfer.files);
            event.target.value = null; // reset
        }
    };

    const handleChange = (event) => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            fileSelected(event.target.files);
            event.target.value = null; // reset
        }
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <DropArea dragActive={dragActive ? 1 : 0} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} style={style} className={disabled ? 'disabled' : ''}>
            <FileInput ref={inputRef} type="file" id="input-file-upload" multiple={isMultiple} accept={accept} disabled={disabled} onChange={handleChange} />
            <CloudUploadTwoToneIcon sx={{ fontSize: 40, mt: -0.8, mb: 0.2 }} />
            <Typography variant="subtitle2" fontSize={14} textAlign="center">
                {primaryText || t('dragAndDropYourFileHere')}
            </Typography>
            {secondaryText && (
                <Typography variant="subtitle2" fontSize={14} textAlign="center">
                    {secondaryText}
                </Typography>
            )}
            <Typography variant="subtitle2" fontSize={12}>
                {t('or')}
            </Typography>
            <Button className="upload-button" onClick={onButtonClick} sx={{ fontWeight: 400, fontSize: 14, my: -0.6, py: 0 }} disabled={disabled}>
                {t('browseFile')}
            </Button>
        </DropArea>
    );
};
export default DragAndDropFileInput;
