import { Box, Checkbox, CircularProgress, IconButton, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Tooltip, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { t } from 'i18next';
import { formatSurchargeValue } from 'src/utils/FormatHelpers';
import Label from '../display/Label';
import DriveFileRenameOutlineTwoToneIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';
import { API_CONFIG } from 'src/config';
import { RootState } from 'src/redux/store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SurchargeOverrideStorageResource, SurchargeReportEntryResource } from 'src/backend/internalCalc';
import { ProCalcUpdateTypes, selectProCalcSettings, updateProCalc } from 'src/redux/shared/proCalc.common';

const StyledListItemButton = styled(ListItemButton)(
    () => `
        display: grid;
        grid-template-columns: auto 1fr minmax(20%, min-content) auto;
    `
);

const IconButtonReset = styled(IconButton)(
    ({ theme }) => `
        .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(18)};
            transform: rotate(45deg);
        }
    `
);

const IconButtonEdit = styled(IconButton)(
    ({ theme }) => `
        padding: ${theme.spacing(0.2)};
  
        .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(15)};
            color: ${theme.colors.primary.main};
        }
    `
);

interface Props {
    surcharge: SurchargeReportEntryResource;
    surchargeOverrides: Array<SurchargeOverrideStorageResource>;
    subCalculationIdentificationKey: string;
    disabled?: boolean;
}

const SurchargeListItem: React.FC<Props> = ({ surcharge, surchargeOverrides, subCalculationIdentificationKey, disabled }) => {
    // const [newSurchargeValue, setNewSurchargeValue] = useState<string>('');
    // const [isChanging, setIsChanging] = useState<boolean>(false);
    // const ref = useRef<any>(null);
    const dispatch = useDispatch();
    const theme = useTheme();
    const settings = useSelector(selectProCalcSettings);

    const clonedSurchargeOverrides = useMemo(() => _.cloneDeep(surchargeOverrides), [surchargeOverrides]);
    const localSurchargeOverride = useMemo(() => {
        return clonedSurchargeOverrides.find((override) => {
            return (
                override.subCalculationIdentificationKey === subCalculationIdentificationKey &&
                override.surchargePackage === surcharge.surchargeFunctionPkg &&
                override.surchargeName === surcharge.surchargeFunctionName
            );
        });
    }, [surcharge, clonedSurchargeOverrides]);

    // useEffect(() => {
    //     if(!localSurchargeOverride?.id) return;

    //     const isLocalSurchargeOverrideApplied = !localSurchargeOverride.deactivateSurcharge;

    //     if(isLocalSurchargeOverrideApplied === surcharge.applied && localSurchargeOverride.overrideValue === surcharge.value) {
    //         dispatch(removeSurchargeOverride(localSurchargeOverride.id));
    //     }
    // }, [surcharge, localSurchargeOverride]);

    const updateSurchargeOverrides = (newOverrides: Array<SurchargeOverrideStorageResource>) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.SURCHARGE_OVERRIDES, newOverrides));
    };

    const isSurchargeSelected = useMemo(() => (localSurchargeOverride ? !localSurchargeOverride?.deactivateSurcharge : surcharge.applied), [localSurchargeOverride, surcharge.applied]);
    const surchargeValue = useMemo(() => localSurchargeOverride?.overrideValue || surcharge.value, [surcharge, localSurchargeOverride?.overrideValue]);

    // const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    //     event.persist();
    //     setNewSurchargeValue(event.target.value);
    // };

    // const startValueChange = (event) => {
    //     if(!isSurchargeSelected) return;
    //     event.stopPropagation();
    //     setIsChanging(true);
    //     const value = surcharge.absoluteSurcharge ? surchargeValue : surchargeValue * 100;
    //     setNewSurchargeValue(value + '');
    //     setTimeout(() => {
    //         ref.current.click(); // click in order to trigger clickAwayListener of other SurchargeListItems
    //         ref.current.select();
    //     }, 10);
    // };
    // const endValueChange = () => {
    //     setIsChanging(false);
    //     if(isSurchargeSelected) {
    //         let value = parseFloat(newSurchargeValue);
    //         if(!surcharge.absoluteSurcharge) value /= 100;
    //         if(localSurchargeOverride) {
    //             localSurchargeOverride.overrideValue = value;
    //         }
    //         else {
    //             clonedSurchargeOverrides.push({
    //                 surchargePackage: surcharge.surchargeFunctionPkg,
    //                 surchargeName: surcharge.surchargeFunctionName,
    //                 subCalculationIdentificationKey,
    //                 surchargeAppliesToMasterCalc: subCalculationIdentificationKey === API_CONFIG.MASTER_SURCHARGE_IDENTIFICATION_KEY,
    //                 overrideValue: value,
    //                 deactivateSurcharge: false
    //             })
    //         }
    //         updateSurchargeOverrides(clonedSurchargeOverrides);
    //     }
    // };
    // const handleKeypress = (event) => {
    //     if (event.key === 'Enter') endValueChange();
    // };

    // const handleRemoveSurchargeOverride = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     if(localSurchargeOverride?.id) {
    //         dispatch(removeSurchargeOverride(localSurchargeOverride.id));
    //     }
    // };

    const toggleSurcharge = () => {
        // if(isChanging) return;

        if (localSurchargeOverride) {
            // localSurchargeOverride.deactivateSurcharge = !localSurchargeOverride.deactivateSurcharge;

            const index = clonedSurchargeOverrides.indexOf(localSurchargeOverride);
            clonedSurchargeOverrides.splice(index, 1);
        } else {
            clonedSurchargeOverrides.push({
                surchargePackage: surcharge.surchargeFunctionPkg,
                surchargeName: surcharge.surchargeFunctionName,
                subCalculationIdentificationKey,
                surchargeAppliesToMasterCalc: subCalculationIdentificationKey === API_CONFIG.MASTER_SURCHARGE_IDENTIFICATION_KEY,
                // overrideValue: surcharge.value,
                deactivateSurcharge: !!isSurchargeSelected
            });
        }
        updateSurchargeOverrides(clonedSurchargeOverrides);
    };

    return (
        <StyledListItemButton sx={{ py: 0, px: 2, my: 1 }} onClick={toggleSurcharge} disabled={disabled}>
            <ListItemIcon sx={{ minWidth: 32, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Checkbox edge="start" checked={isSurchargeSelected} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText primary={surcharge.description} primaryTypographyProps={{ variant: 'body1' }} />
            <Box display="flex" justifyContent="flex-end" pl={1.5}>
                {localSurchargeOverride && (
                    <Box display="flex" alignItems="center" pr={1}>
                        <Tooltip arrow placement="top" title={t('surchargeWasOverwritten')}>
                            <DriveFileRenameOutlineTwoToneIcon fontSize="small" color="secondary" sx={{ opacity: 0.7 }} />
                        </Tooltip>
                    </Box>
                )}
                {(localSurchargeOverride?.deactivateSurcharge === false || surcharge.applied) && (
                    <Label color="primary">{formatSurchargeValue(surchargeValue, surcharge.absoluteSurcharge, settings.numberFormat, settings.currency)}</Label>
                )}
            </Box>
            {/* {isChanging ?
            <ClickAwayListener onClickAway={endValueChange}>
                <TextField
                    value={newSurchargeValue}
                    size="small"
                    onChange={handleChange}
                    onKeyUp={handleKeypress}
                    variant="outlined"
                    margin="none"
                    sx={{ pl: 0.5, minWidth: '60px' }}
                    inputProps={{ sx: { px: 1, py: 0.5 }}}
                    inputRef={ref}
                />
            </ClickAwayListener>
            :
            <Box display="flex" justifyContent="flex-end" onClick={startValueChange} pl={0.5}>
                {localSurchargeOverride?.id &&
                <Box display="flex" alignItems="center" pr={1}>
                    <Tooltip arrow placement="top" title={t('surchargeWasOverwritten')}>
                        <IconButtonReset onClick={handleRemoveSurchargeOverride} size="small" color="secondary">
                            <CachedTwoToneIcon />
                        </IconButtonReset>
                    </Tooltip>
                </Box>}
                <Label color="primary">
                    {formatSurchargeValue(surchargeValue, surcharge.absoluteSurcharge)}
                
                    {isSurchargeSelected &&
                    <Box display="flex" alignItems="center" pl={0.5}>
                        <Tooltip arrow placement="top" title={t('changeValue')}>
                            <IconButtonEdit onClick={startValueChange}>
                                <EditTwoToneIcon />
                            </IconButtonEdit>
                        </Tooltip>
                    </Box>}
                </Label>
            </Box>
        } */}
        </StyledListItemButton>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        surchargeOverrides: state.proCalc.surchargeOverrides
    };
};
export default connect(mapStateToProps)(SurchargeListItem);
