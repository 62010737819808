import {Box, styled, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

const LogoWrapper = styled(Box)(
    ({theme}) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        font-weight: ${theme.typography.fontWeightBold};
`
);


function LogoHeader() {
    const {t}: { t: any } = useTranslation();
    const theme = useTheme();

    return (
        <LogoWrapper>
            <img style={{height: 45}} alt='Surface Solutions Logo' src='/static/images/logo/surfSolLogo.svg'/>
        </LogoWrapper>
    );
}

export default LogoHeader;
