import React, { useState, useEffect } from 'react';
import DxfViewerCanvas from './DxfViewerCanvas';
import { Stack } from '@mui/material';
// import LayersList from './LayersList';
// import { DxfViewer } from 'dxf-viewer';

interface Props {
    url: string;
}

const DxfViewer: React.FC<Props> = ({ url }) => {

    useEffect(() => {
        // Assuming your DxfViewer component has a ref prop "ref='viewer'"
        // dxfViewerRef.current = React.createRef();
    }, []);

    const fonts = [];

    return (
        <Stack flexGrow={1}>
            <DxfViewerCanvas url={url} fonts={fonts} />
            {/* <div className="col-auto layersCol">
                <LayersList layers={layers} onToggleLayer={_OnToggleLayer} onToggleAll={_OnToggleAll} />
            </div> */}
        </Stack>
    );
}

export default DxfViewer;
