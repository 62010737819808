/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PartMissingDataResource } from './PartMissingDataResource';

export type AiMailConversationMetadataResource = {
    dataComplete?: boolean;
    senderName?: string;
    gender?: AiMailConversationMetadataResource.gender;
    aiDataType?: AiMailConversationMetadataResource.aiDataType;
    missingData?: Array<PartMissingDataResource>;
}

export namespace AiMailConversationMetadataResource {

    export enum gender {
        UNKNOWN = 'UNKNOWN',
        MALE = 'MALE',
        FEMALE = 'FEMALE',
    }

    export enum aiDataType {
        NO_AI_DATA = 'NO_AI_DATA',
        THREE_D_AI_DATA = 'THREE_D_AI_DATA',
        PART_AI_DATA = 'PART_AI_DATA',
    }


}
