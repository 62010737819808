import {Box} from '@mui/material';
import React from "react";

import LogoHeader from 'src/components/utils/LogoSign';
import SurfLoader from "../../display/Loader/SurfLoader";

function AppInit() {
    return (
        <Box
            sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%'
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <SurfLoader/>
        </Box>
    );
}

export default AppInit;
