/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { WizardCalcColorResource } from './WizardCalcColorResource';
import type { WizardCalcMaterialResource } from './WizardCalcMaterialResource';
import type { WizardCalcPartResource } from './WizardCalcPartResource';

export type WizardCalcMergeResource = {
    id?: number;
    companyId?: number;
    wizardCalcDataType?: WizardCalcMergeResource.wizardCalcDataType;
    uniqueId?: string;
    wizardId?: string;
    calcId?: number;
    opened?: boolean;
    customerType?: WizardCalcMergeResource.customerType;
    requestType?: string;
    requester?: string;
    distance?: number;
    commission?: string;
    parts?: Array<WizardCalcPartResource>;
    colors?: Array<WizardCalcColorResource>;
    materials?: Array<WizardCalcMaterialResource>;
    materialSearchIndex?: string;
    surfaceValue?: number;
    surfaceUnit?: string;
    lastAction?: string;
    createdAt?: string;
    price?: string;
    status?: string;
    attachmentCount?: number;
    note?: string;
    summaryPersonDataType?: WizardCalcMergeResource.summaryPersonDataType;
    company?: string;
    name?: string;
    email?: string;
    phone?: string;
    street?: string;
    houseNumber?: string;
    city?: string;
    zipcode?: string;
    country?: string;
    contactNote?: string;
    wishDate?: string;
    deliveryDate?: string;
    throughputDays?: number;
    regularCustomerLogoUrl?: string;
}

export namespace WizardCalcMergeResource {

    export enum wizardCalcDataType {
        WIZARD = 'WIZARD',
        CALC = 'CALC',
    }

    export enum customerType {
        REGULAR_CUSTOMER = 'REGULAR_CUSTOMER',
        WALK_IN_CUSTOMER = 'WALK_IN_CUSTOMER',
    }

    export enum summaryPersonDataType {
        COATER = 'COATER',
        WIZARD_CUSTOMER = 'WIZARD_CUSTOMER',
        INTERNAL_CALCULATION_CONTACT = 'INTERNAL_CALCULATION_CONTACT',
        REGULAR_CUSTOMER = 'REGULAR_CUSTOMER',
    }


}
