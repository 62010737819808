import { Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import store, { RootState } from 'src/redux/store';
import CategorySection from './CategorySection';
import { VariableResource, InputVariableResource } from 'src/backend/coreCalc';

interface Props {
    variables?: Array<VariableResource>;
    inputSections: { [section: string]: Array<InputVariableResource> };
    calcRelevantVariableNames?: Array<string>;
    saveVariable: (variable: VariableResource) => void;
}

const CategorySections: React.FC<Props> = ({ variables, inputSections, calcRelevantVariableNames, saveVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [openAccordionId, setOpenAccordionId] = useState<string>('geometryPackageSection');

    return (
        <Stack width="100%" maxWidth="100%">
            {Object.entries(inputSections).map(([section, inputVariables]) => (
                <CategorySection
                    key={section}
                    section={section}
                    variables={variables}
                    inputVariables={inputVariables}
                    calcRelevantVariableNames={calcRelevantVariableNames}
                    saveVariable={saveVariable}
                    openAccordionId={openAccordionId}
                    setOpenAccordionId={setOpenAccordionId}
                />
            ))}
        </Stack>
    );
};

const mapStateToProps = (state: RootState) => {
    return {};
};
export default connect(mapStateToProps)(CategorySections);
