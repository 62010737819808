import { Typography, Stack, useTheme, Button } from '@mui/material';
import Accordion from 'src/components/display/Accordion/Accordion';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { useDispatch } from 'react-redux';
import DynamicValueInput from 'src/components/input/DynamicValueInput/DynamicValueInput';
import { RuleResource, VariableResource, ValueResource } from 'src/backend/coreCalc';
import { getValue } from 'src/utils/CalcHelpers';

interface Props {
    rule: RuleResource;
    variables: Array<VariableResource>;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    changeRuleValue?: (id: number, value: ValueResource) => void;
    removeRule?: (id: number) => void;
    disabled?: boolean;
}

const RulesBasedVariableItem: React.FC<Props> = ({ rule, variables, openAccordionId, setOpenAccordionId, changeRuleValue = () => {}, removeRule = () => {}, disabled }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <Accordion
            key={rule.id}
            accordionHeader={
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Stack alignItems="flex-start">
                        <Typography variant="h4" lineHeight={1.2}>
                            {(rule.value.type === ValueResource.type.VARIABLE_VALUE ? 'Variable ' : 'Wert ') + getValue(rule.value)}
                        </Typography>
                        <Typography variant="subtitle2" fontSize={13} lineHeight={1.3}>
                            {rule.isDefault ? (
                                'Standard'
                            ) : rule.criteria?.length > 0 ? (
                                <>Wenn {(rule.criteria || []).map((criterion) => `${getValue(criterion.left)} ${criterion.operator} ${getValue(criterion.right)}`).join(', ')}</>
                            ) : (
                                <span style={{ color: theme.colors.error.main }}>Keine Kriterien. Wert wird ignoriert</span>
                            )}
                        </Typography>
                    </Stack>
                </Stack>
            }
            accordionId={rule.id + ''}
            openAccordionId={openAccordionId}
            setOpenAccordionId={setOpenAccordionId}
            hideFirstBorder={true}
        >
            <Stack px="24px" py="16px">
                <DynamicValueInput
                    label="Wert/Variable"
                    dynamicValue={rule.value}
                    setDynamicValue={(dynamicValue) => changeRuleValue(rule.id, dynamicValue)}
                    variables={variables}
                    disabled={disabled}
                />

                {rule.isDefault ? (
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 3, mb: 1, fontWeight: 600, fontSize: 13, lineHeight: 1.2 }} variant="subtitle1">
                        <InfoTwoToneIcon sx={{ fontSize: '1.7rem' }} />
                        Der Standardwert kann nicht gelöscht werden. Er wird dann angewendet, wenn kein anderer Wert anwendbar ist.
                    </Typography>
                ) : (
                    <>
                        <Typography variant="h4" sx={{ fontSize: 17, mt: 4, mb: 0.5, opacity: 0.7 }} gutterBottom>
                            Wann soll der Wert angewandt werden?
                        </Typography>
                        <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 0.5 }} variant="subtitle1">
                            Wähle aus, wie der Wert für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation
                            erstellen.
                        </Typography>

                        <Button startIcon={<AddBoxTwoToneIcon />} sx={{ mr: 'auto', ml: -1.6 }} disabled={disabled}>
                            Kriterium hinzufügen
                        </Button>

                        <Button startIcon={<DeleteForeverTwoToneIcon />} color="error" sx={{ ml: 'auto', mt: 4 }} onClick={() => removeRule(rule.id)} disabled={disabled}>
                            Wert löschen
                        </Button>
                    </>
                )}
            </Stack>
        </Accordion>
    );
};

export default RulesBasedVariableItem;
