import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { appear } from 'src/utils/animationstyles';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import LogoHeader from 'src/components/utils/LogoSign';
import { useDispatch } from 'react-redux';
import { nav } from 'src/statics/navigations';
import { useNavigate } from 'react-router';
import { logoutUser } from 'src/redux/thunks/user.thunk';

const CalculationMigrationWaitingPage: FC = ({}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToLogin = () => {
        dispatch(logoutUser());
        navigate(nav.BASE.sub.INIT.path);
    };

    return (
        <Stack justifyContent="center" alignItems="center" position="fixed" left={0} right={0} top={0} bottom={0} bgcolor={theme.palette.background.default} zIndex={2000}>
            {appear(
                <Stack spacing={4} justifyContent="center" alignItems="center">
                    <LogoHeader />
                    <CircularProgress />
                    <Stack position="relative" alignItems="center">
                        <Typography variant="h4" noWrap>
                            {t('isMigrating')}
                        </Typography>
                    </Stack>
                    <Typography variant="subtitle2">{t('thisOperationMayTakeAWhile')}</Typography>
                </Stack>
            )}
        </Stack>
    );
};

export default CalculationMigrationWaitingPage;
