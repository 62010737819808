import { Typography, Card, Stack, Divider, Button, Collapse, TextField, styled, Box } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import HeadlineButton from '../display/HeadlineButton/HeadlineButton';
import { useState } from 'react';

interface Props {
    result?: any;
}

const PriceFinderPlants: React.FC<Props> = ({ result }) => {
    return (
        <Stack>
            <Typography color="secondary" fontWeight="bold" fontSize="24px" mt={1} mb={1.5}>
                Ergebnis
            </Typography>

            <Card sx={{ mb: 3, p: 3 }}>{JSON.stringify(result)}</Card>
        </Stack>
    );
};

export default PriceFinderPlants;
