import { EditTwoTone } from '@mui/icons-material';
import { Avatar, Badge, Box, Button, Chip, Collapse, IconButton, Stack, TableCell, TableRow, Tooltip, Typography, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, formatDistance, formatPrice, formatValue, getAddressLine1Set, getAddressLine2Set, getColors, getMaterials } from 'src/utils/FormatHelpers';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { AiMailConversationResource, WizardCalcMergeResource } from 'src/backend/market';
import { wT } from 'src/utils/wizardTranslations';
import store from 'src/redux/store';
import { ANALYZING } from 'src/statics/statics';
import { createPortal } from 'react-dom';
import AiEmailConversationViewer from 'src/components/pro-calc/AiEmail/AiEmailConversationViewer';
import ColorDrops from '../display/ColorDrops';
import CalculationStatus from '../display/CalculationStatus/CalculationStatus';

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        width: 43px;
        height: 43px;
        font-size: 20px;
    `
);
const StyledIconBox = styled(Box)(
    ({ theme }) => `
        margin-top: -3px;
        margin-left: -3px;
        background: 'Red';
        & .MuiSvgIcon-root {
            padding: 2px;
            background: ${theme.colors.primary.main};
            color: ${theme.colors.alpha.white[100]};
            font-size: 16px;
            border-radius: 50%;
        }
    `
);

const TopIconButton = styled(Button)(
    ({ theme }) => `
        flex-direction: column;
        font-size: 16px;
        .MuiButton-startIcon {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 8px;
        }
        .MuiButton-startIcon .MuiSvgIcon-root {
            font-size: 27px;
        }
    `
);

interface Props {
    row: WizardCalcMergeResource;
    mailConversation: AiMailConversationResource;
    openRow: WizardCalcMergeResource;
    setOpenRow: (row: WizardCalcMergeResource) => void;
    handleEditClicked: Function;
    handleViewClicked: Function;
    handleDuplicateClicked: Function;
    handleDeleteClicked: Function;
}

const CalcOverviewTableRow: React.FC<Props> = ({ row, mailConversation, openRow, setOpenRow, handleEditClicked, handleViewClicked, handleDuplicateClicked, handleDeleteClicked }) => {
    const isOpen = row === openRow;
    const theme = useTheme();
    const { t } = useTranslation();
    const s = store.getState();
    const chipWrapper = useRef(null);
    const chipRefs = useRef([]);
    const [overflowCount, setOverflowCount] = useState<number>(0);
    const [isAiEmailViewerOpen, setAiEmailViewerOpen] = useState<boolean>(false);
    const boxShadow = 'inset 5px 0 0 0 ' + (isOpen ? theme.colors.primary.main : 'transparent');

    const checkChipOverflow = (chip, isPreviousOverflowing, offsetForLabel = 0) => {
        if (!chip) return false;
        chip.style.display = 'inline-flex';
        const isOverflowing = isPreviousOverflowing || chip.offsetLeft + chip.clientWidth + offsetForLabel > chip.parentElement.clientWidth;

        if (isOverflowing) {
            chip.style.display = 'none';
            return true;
        }
        return false;
    };

    const checkChipsOverflow = () => {
        const chips = chipRefs.current || [];
        const offsetForLabel = 30;
        let overflowCount = 0;
        setOverflowCount(0);

        chips.forEach((chip, index) => {
            const isPreviousOverflowing = overflowCount > 0;
            const isOverflowing = checkChipOverflow(chip, isPreviousOverflowing);
            if (isOverflowing) {
                overflowCount++;
                const previousChip = chips[index - 1];
                if (overflowCount === 1 && previousChip) {
                    const isPreviousOverflowing = checkChipOverflow(previousChip, false, offsetForLabel);
                    if (isPreviousOverflowing) overflowCount++;
                }
            }
        });

        setOverflowCount(overflowCount);
    };

    const getPartNames = (row: WizardCalcMergeResource) => {
        return row.parts.map((part) => part.userDefinedPartName || wT(part.itemNameKey, s));
    };
    const getTotalPieces = (row: WizardCalcMergeResource) => {
        let partCount = 0;
        row.parts.forEach((part) => {
            partCount += part.partCount;
        });
        return partCount;
    };

    const getArea = (row: WizardCalcMergeResource) => {
        let surfaceUnit = row.surfaceUnit;
        if (!surfaceUnit || surfaceUnit === 'NOT_SET') return '-';
        if (surfaceUnit === 'm2') surfaceUnit = 'm²';
        return formatValue(row.surfaceValue) + ' ' + surfaceUnit;
    };

    const isSet = (value: string) => {
        return !!value && !!value.trim();
    };
    const isCustomerUnset = (row) => {
        return (
            (row.requester === 'NOT_SET' || !isSet(row.requester)) &&
            getAddressLine1Set(row) === '' &&
            getAddressLine2Set(row) === '' &&
            !isSet(row.phone) &&
            !isSet(row.email) &&
            !isSet(row.contactNote) &&
            !row.distance
        );
    };

    useEffect(() => {
        checkChipsOverflow();
    }, [isOpen]);

    const debounceCheckChipsOverflow = _.debounce(checkChipsOverflow, 150);
    useEffect(() => {
        if (!chipWrapper.current) return;
        const resizeObserver = new ResizeObserver(debounceCheckChipsOverflow);
        resizeObserver.observe(chipWrapper.current);
        return () => resizeObserver.disconnect();
    }, [chipWrapper.current]);

    return (
        <React.Fragment>
            <TableRow
                hover
                sx={{
                    '& > .MuiTableCell-body': { borderBottom: 'unset', cursor: 'pointer' },
                    '&.MuiTableRow-hover:hover': { background: isOpen ? 'rgba(26, 117, 255, 0.16)' : theme.colors.primary.lighter },
                    background: isOpen ? 'rgba(26, 117, 255, 0.07)' : '',
                    boxShadow,
                    transition: '0.4s'
                }}
                onClick={() => setOpenRow(isOpen ? null : row)}
            >
                <TableCell sx={{ width: 50, maxWidth: 50 }}>
                    <IconButton aria-label="expand row" size="small">
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ maxWidth: 50 }}>{row.uniqueId}</TableCell>
                <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Tooltip title={t('xAttachments', { x: row.attachmentCount })}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '0.9rem',
                                            height: '0.9rem',
                                            textAlign: 'center',
                                            borderRadius: '50%',
                                            fontSize: 11,
                                            color: theme.colors.alpha.white[100],
                                            bgcolor: theme.colors.primary.main
                                        }}
                                    >
                                        {row.attachmentCount}
                                    </Box>
                                }
                            >
                                <AttachFileTwoToneIcon />
                            </Badge>
                        </Tooltip>
                        {mailConversation?.conversation?.length > 0 && (
                            <Tooltip title={t('emailConversation')}>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAiEmailViewerOpen(true);
                                    }}
                                >
                                    <EmailTwoToneIcon />
                                    {createPortal(
                                        <AiEmailConversationViewer mailConversation={mailConversation} suffix={'#' + row.uniqueId} isOpen={isAiEmailViewerOpen} setOpen={setAiEmailViewerOpen} />,
                                        document.body
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </TableCell>
                <TableCell sx={{ width: 350, p: 1 }}>
                    <Stack direction="row" textAlign="left" alignItems="center" overflow="hidden">
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                row.requestType && (
                                    <StyledIconBox display="flex" justifySelf="flex-end" alignItems="center">
                                        {row.requestType === 'PHONE' && <CallTwoToneIcon />}
                                        {row.requestType === 'EMAIL' && <AlternateEmailTwoToneIcon />}
                                        {row.requestType === 'IN_PERSON' && <RecordVoiceOverTwoToneIcon />}
                                        {row.requestType === 'WEB' && <LanguageTwoToneIcon />}
                                    </StyledIconBox>
                                )
                            }
                        >
                            <AvatarWrapper alt={row.requester && row.requester != 'NOT_SET' ? row.requester : '?'} src={row.regularCustomerLogoUrl || '/static/images/avatars/3.jpg'} />
                        </Badge>
                        <Stack ml={1.4} alignItems="flex-start" alignSelf="center" overflow="hidden" maxWidth="100%">
                            <Stack direction="row" overflow="hidden" alignItems="center" maxWidth="100%">
                                <Typography variant="subtitle2" fontWeight="bold" noWrap lineHeight={1.3} fontSize={13} maxWidth="100%">
                                    {isCustomerUnset(row) ? t('unspecifiedCustomer') : row.requester && row.requester !== 'NOT_SET' ? row.requester : row.name || t('unspecifiedCompany')}
                                </Typography>
                                {row.summaryPersonDataType === WizardCalcMergeResource.summaryPersonDataType.REGULAR_CUSTOMER && (
                                    <Typography
                                        noWrap
                                        ml={0.6}
                                        fontSize="0.85em"
                                        fontWeight="bold"
                                        lineHeight={1}
                                        bgcolor={theme.colors.warning.lighter}
                                        color={theme.colors.warning.main}
                                        p={0.4}
                                        borderRadius={0.6}
                                        className="regular-customer"
                                    >
                                        {t('regularCustomer')}
                                    </Typography>
                                )}
                                {row.wizardId && (
                                    <Typography ml={0.6} sx={{ bgcolor: theme.colors.primary.light, color: 'white', px: 0.6, py: 0.15, fontSize: 9, fontWeight: 'bold', borderRadius: 1 }}>
                                        WEB
                                    </Typography>
                                )}
                            </Stack>
                            {getAddressLine1Set(row) !== '' && (
                                <Typography variant="subtitle2" lineHeight={1.2} fontSize={13} noWrap>
                                    {getAddressLine1Set(row)}
                                </Typography>
                            )}
                            {getAddressLine2Set(row) !== '' && (
                                <Typography variant="subtitle2" lineHeight={1.2} fontSize={13} noWrap>
                                    {getAddressLine2Set(row)}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell sx={{ minWidth: '10rem', width: '12vw', maxWidth: '12vw' }}>
                    {isOpen ? (
                        <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap>
                            {t('totalQuantity')}: {getTotalPieces(row)}
                        </Typography>
                    ) : row.parts.length > 0 ? (
                        <Stack ref={chipWrapper} direction="row" overflow="hidden" gap={1} pr={4}>
                            {getPartNames(row).map((partName, index) => (
                                <Chip
                                    key={index}
                                    label={partName}
                                    sx={{ height: 25, overflow: index === 0 && overflowCount > 0 ? 'unset' : 'unset', '.MuiChip-label': { p: 1, fontSize: 12 } }}
                                    ref={(element) => {
                                        if (index === 0) return;
                                        chipRefs.current[index - 1] = element;
                                    }}
                                />
                            ))}

                            {overflowCount > 0 && (
                                <Typography alignSelf="center" color="secondary" ml={0.5} fontWeight="bold" fontSize={13}>
                                    +{overflowCount}
                                </Typography>
                            )}
                        </Stack>
                    ) : (
                        '-'
                    )}
                </TableCell>
                <TableCell>
                    <Typography fontWeight="bold" color="primary">
                        {formatPrice(row.price)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: 50, maxWidth: 50 }}>{formatDate(row.createdAt)}</TableCell>
                <TableCell sx={{ width: 150, maxWidth: 150 }}>{row.colors?.length > 0 ? <ColorDrops colors={row.colors} /> : '-'}</TableCell>
                <TableCell>
                    {getMaterials(row.materials)
                        .map((material) => wT(material, s))
                        .join(', ') || '-'}
                </TableCell>
                <TableCell>{getArea(row) || '-'}</TableCell>
                <TableCell sx={{ maxWidth: 120 }}>
                    <CalculationStatus status={row.status} importedWizardId={row.wizardId} showUnopened={true} mailConversation={mailConversation} />
                </TableCell>
                <TableCell sx={{ width: 155 }}>
                    <Button
                        startIcon={<EditTwoTone />}
                        variant="contained"
                        onClick={(event) => {
                            event.stopPropagation();
                            handleEditClicked(row);
                        }}
                        disabled={row.status === ANALYZING}
                    >
                        {t('edit')}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow
                sx={{
                    boxShadow,
                    background: isOpen ? 'rgba(26, 117, 255, 0.07)' : '',
                    transition: 'box-shadow 0.4s'
                }}
            >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top', maxWidth: 80 }} colSpan={2}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                    {t('calcId')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13}>
                                    {row.uniqueId || '-'}
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                    {t('wizardId')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13}>
                                    {row.wizardId || '-'}
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                    {t('kommission')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {row.commission || '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {isSet(row.name) && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('contactPerson')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {row.name}
                                    </Typography>
                                </Stack>
                            )}
                            {isSet(row.phone) && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('phone')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {row.phone}
                                    </Typography>
                                </Stack>
                            )}
                            {isSet(row.contactNote) && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('note')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {row.contactNote}
                                    </Typography>
                                </Stack>
                            )}
                            {!!row.distance && (
                                <Stack direction="row">
                                    <Typography variant="subtitle2" fontWeight="bold" fontSize={13}>
                                        {t('distance')}:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={13}>
                                        {formatDistance(row.distance)}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top', minWidth: '10rem', width: '12vw', maxWidth: '12vw' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {row.parts.map((part, index) => (
                                <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13} noWrap>
                                    {part.partCount} × {part.userDefinedPartName || wT(part.itemNameKey, s)}
                                </Typography>
                            ))}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {row.parts.map((part, index) => (
                                <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13} color="primary">
                                    {formatPrice(part.price)}
                                </Typography>
                            ))}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top', width: 50, maxWidth: 50 }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap overflow="unset">
                                    {t('anlieferung')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {formatDate(row.deliveryDate, 'dd.MM.yyyy') || '-'}
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap overflow="unset">
                                    {t('abholung')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {formatDate(row.wishDate, 'dd.MM.yyyy') || '-'}
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="subtitle2" fontWeight="bold" fontSize={13} noWrap overflow="unset">
                                    {t('duration')}:&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" fontSize={13} noWrap>
                                    {row.throughputDays || ''} {t(row.throughputDays === 0 ? 'sameDay' : row.throughputDays > 1 ? 'days' : 'day')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack py={1}>
                            {getColors(row.colors).map((color, index) => (
                                <Typography key={index} variant="subtitle2" fontWeight="bold" fontSize={13}>
                                    {color}
                                </Typography>
                            ))}
                        </Stack>
                    </Collapse>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Stack direction="row" justifyContent="flex-end">
                            <Button
                                startIcon={<ArticleTwoToneIcon />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleViewClicked(row);
                                }}
                                disabled={row.status === ANALYZING}
                            >
                                {t('viewSummary')}
                            </Button>
                            <Button
                                color="secondary"
                                startIcon={<ContentCopyTwoToneIcon />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDuplicateClicked(row);
                                }}
                                disabled={row.status === ANALYZING}
                            >
                                {t('duplicate')}
                            </Button>
                            <Button
                                color="error"
                                startIcon={<DeleteTwoToneIcon />}
                                sx={{ mr: -1 }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteClicked(row.uniqueId);
                                }}
                                disabled={row.status === ANALYZING}
                            >
                                {t('delete')}
                            </Button>
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
export default CalcOverviewTableRow;
