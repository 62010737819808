import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


const ExplanationVideo: React.FC = () => {
    const { t }: { t: any } = useTranslation();

    return <>
        <Typography gutterBottom component="div" variant="h4">
            {t('weHaveSomeTips')}
        </Typography>
        <Typography component="div" variant="subtitle2" color="inherit" pb={1.5}>
            {t('getToKnowTheBasics')}
        </Typography>
        <iframe
            style={{ border: 0, borderRadius: '7px', width: '100%', height: '100%' }}
            frameBorder="0"
            src="https://www.youtube.com/embed/QVCBJn7NDZA"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
        </iframe>
    </>
}

export default ExplanationVideo;
