/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueDTO } from './BooleanValueDTO';
import type { ListValueDTO } from './ListValueDTO';
import type { StringValueDTO } from './StringValueDTO';
import type { ValueDTO } from './ValueDTO';
import type { VariableValueDTO } from './VariableValueDTO';

export type VariableDTO = {
    type: VariableDTO.type;
    name: string;
    translationKey?: string;
    value?: (ValueDTO | BooleanValueDTO | ListValueDTO | StringValueDTO | VariableValueDTO);
}

export namespace VariableDTO {

    export enum type {
        INPUT_VARIABLE = 'INPUT_VARIABLE',
        TERM_VARIABLE = 'TERM_VARIABLE',
        PROVIDED_VARIABLE = 'PROVIDED_VARIABLE',
        DECLARATION_VARIABLE = 'DECLARATION_VARIABLE',
        SURCHARGE_VARIABLE = 'SURCHARGE_VARIABLE',
        ROUND_VARIABLE = 'ROUND_VARIABLE',
        RULE_BASED_VARIABLE = 'RULE_BASED_VARIABLE',
    }


}
