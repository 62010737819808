import { Box, IconButton, InputAdornment, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdditionalStorageResource, IntegerCalcParamResource } from 'src/backend/internalCalc';
import store from 'src/redux/store';
import { StyledTextField, GeometryLabelCircle } from '../CalcInputs.styles';
import InfoLabel from './InfoLabel';
import { wT } from 'src/utils/wizardTranslations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { HOLES, HOLES_DIMENSIONS, THREADS, THREADS_DIMENSIONS } from 'src/statics/statics';
import { formatDimensionsInfos } from 'src/utils/FormatHelpers';
import { measureInputValueWidth } from 'src/utils/CalcHelpers';
import ExtractedIndicator from './ExtractedIndicator';

interface Props {
    partId?: number;
    param: IntegerCalcParamResource;
    disabled?: boolean;
    isSmall?: boolean;
    hide?: boolean;
    geometryDimensionLabel?: string;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const IntegerParameter: React.FC<Props> = ({ partId, param, disabled, isSmall, hide, geometryDimensionLabel, additionalStorage = [] }) => {
    const { values, handleChange, errors, setFieldValue } = useFormikContext();
    const s = store.getState();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const ref = useRef<any>(null);
    const [left, setLeft] = useState<number>(0);

    useEffect(() => {
        if (hide) setFieldValue(param.name, param.defaultValue || param.min || 0);
    }, [hide]);

    useEffect(() => {
        if (ref.current) setLeft(measureInputValueWidth(ref.current));
    }, [ref.current, values[param.name]]);

    const dimensionsInfos = useMemo(() => {
        let key;

        if (param.name === HOLES) key = 'extracted_' + HOLES_DIMENSIONS;
        else if (param.name === THREADS) key = 'extracted_' + THREADS_DIMENSIONS;
        else return;

        const value = additionalStorage.find((entry) => entry.key === key)?.value;
        if (!value) return;
        return JSON.parse(value) as Array<{ radius: number }>;
    }, [param.name, additionalStorage]);

    const renderDefault = () => {
        const value = parseInt(values[param.name]);
        const isDefault = param.defaultValue ? value === param.defaultValue : param.min === value;
        if (!isDefault) return <></>;

        return (
            <span
                className="default-indicator"
                style={{
                    position: 'absolute',
                    left,
                    marginTop: 3,
                    marginLeft: 4,
                    opacity: 0.5,
                    fontSize: 12,
                    pointerEvents: 'none'
                }}
            >
                (Default)
            </span>
        );
    };

    const renderTextField = () => {
        return (
            <StyledTextField
                type="number"
                label={
                    <InfoLabel
                        label={param.name}
                        labelPrefix={geometryDimensionLabel && <GeometryLabelCircle>{geometryDimensionLabel}</GeometryLabelCircle>}
                        guiDescriptor={param.guiDescriptor}
                        guiDescriptorExplanation={param.explanationTranslationKey}
                    />
                }
                name={param.name}
                onChange={handleChange}
                onKeyDown={(event) => {
                    if (event.key === '.' || event.key === ',') event.preventDefault();
                }}
                onBlur={(event) => {
                    if (!Boolean(errors[param.name])) return;
                    if (values[param.name] > param.max) return setFieldValue(param.name, param.max);
                    if (values[param.name] < param.min) return setFieldValue(param.name, param.min);
                }}
                value={values[param.name]}
                error={Boolean(errors[param.name])}
                helperText={errors[param.name]}
                onFocus={(e) => {
                    e.target.select();
                }}
                InputProps={{
                    startAdornment: <ExtractedIndicator param={param} additionalStorage={additionalStorage} />,
                    endAdornment: (
                        <InputAdornment position="end">
                            {renderDefault()}
                            {wT(param.unit, s)}
                        </InputAdornment>
                    )
                }}
                inputProps={{
                    min: param.min,
                    max: param.max
                }}
                inputRef={ref}
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                size={isSmall ? 'small' : 'medium'}
            />
        );
    };

    return dimensionsInfos ? (
        <Stack>
            {renderTextField()}
            <Tooltip title={formatDimensionsInfos(dimensionsInfos, param.name === THREADS)} placement="left">
                <Box className="dimensions-infos" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                    <InfoTwoToneIcon sx={{ fontSize: 18, mx: 0.5, opacity: 0.7 }} />
                    <Typography variant="subtitle2" sx={{ fontSize: 14 }} noWrap>
                        {formatDimensionsInfos(dimensionsInfos, param.name === THREADS)}
                    </Typography>
                </Box>
            </Tooltip>
        </Stack>
    ) : (
        renderTextField()
    );
};

export default IntegerParameter;
