import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PageHeader from 'src/components/display/PageHeader';
import { nav } from 'src/statics/navigations';
import PublicApiSettings from './PublicApiSettings';
import { useEffect } from 'react';
import { useDispatch } from '../../../redux/store';
import { startWalkthrough } from '../../../redux/thunks/guidethrough.thunk';

function DeveloperSettings() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startWalkthrough('developerSettings'));
    }, []);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleBack = () => {
        navigate(nav.TOOLS.path);
    };

    return (
        <>
            <PageHeader handleBack={isSmallScreen && handleBack} />
            <PublicApiSettings />
        </>
    );
}

export default DeveloperSettings;
