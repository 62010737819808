/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueDTO } from './BooleanValueDTO';
import type { ListValueDTO } from './ListValueDTO';
import type { StringValueDTO } from './StringValueDTO';
import type { ValueDTO } from './ValueDTO';
import type { VariableValueDTO } from './VariableValueDTO';

export type CriterionDTO = {
    left?: (ValueDTO | BooleanValueDTO | ListValueDTO | StringValueDTO | VariableValueDTO);
    operator?: CriterionDTO.operator;
    right?: (ValueDTO | BooleanValueDTO | ListValueDTO | StringValueDTO | VariableValueDTO);
    connector?: CriterionDTO.connector;
}

export namespace CriterionDTO {

    export enum operator {
        EQUALS = 'EQUALS',
        NOT_EQUALS = 'NOT_EQUALS',
        LESS_THAN = 'LESS_THAN',
        GREATER_THAN = 'GREATER_THAN',
        LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
        GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
    }

    export enum connector {
        AND = 'AND',
        OR = 'OR',
    }


}
