import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react"
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {UserManagementRestControllerService} from "../../../backend/market";

export interface InviteFormikValues {
    email: string;
    firstName: string;
    lastName: string;
}

interface InviteProps {
    finishedCallback: (values: InviteFormikValues) => void;
}

export interface InviteHandle {
    open: () => void;
    close: () => void;
}

const InviteAccountDialog: React.ForwardRefRenderFunction<InviteHandle, InviteProps> = (props, ref) => {
    const {t} = useTranslation();
    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        isValid,
        resetForm,
        touched,
        handleBlur
    } = useFormik<InviteFormikValues>({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
        },
        isInitialValid: false,
        onSubmit: (values) => {
            props.finishedCallback(values);
            handleClose();
        },
        validateOnChange: true,
        validationSchema: yup.object().shape({
            email: yup.string().email(t("Bitte gib eine korrekte E-Mail ein!")).test("checkEmailAlreadyUsed", t("Diese E-Mail Adresse ist bereits registriert!"), async (value) => {
                if (value === "" || !value) return;
                const result = await UserManagementRestControllerService.checkIfMailAlreadyUsed({mail: value});
                return !result.used;
            }).required(t("Bitte gib eine E-Mail ein!")),
            firstName: yup.string().required(t("Bitte gib deinen Vornamen ein!")),
            lastName: yup.string().required(t("Bitte gib deinen Nachnamen ein!")),
        })
    })
    //region STATE
    const [open, setOpen] = useState(false);
    //endregion

    //region LIFECYCLE
    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
        close: () => {
            setOpen(false);
        },
    }), []);
    useEffect(() => {
        resetForm();
    }, [open])
    //endregion

    //region HANDLER
    const handleClose = () => {
        setOpen(false);
    }
    const clickedOnSubmit = () => {
        handleSubmit();
    }
    //endregion
    return <Dialog open={open} maxWidth={"sm"} fullWidth onBackdropClick={() => setOpen(false)}>
        <DialogTitle>
            <Typography variant={"h4"}>{t('benutzerEinladen')}</Typography>
            <Typography variant={"subtitle1"}>{t('diesemBenutzerWirdEineEmailMitEinemEinmallinkGesen')}</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <Stack gap={1}>

                    <TextField label={t("E-Mail")} value={values.email}
                               onBlur={handleBlur}
                               onChange={handleChange} name={"email"}
                               error={touched["email"] && Boolean(errors["email"])}
                               helperText={touched["email"] && errors["email"]}/>
                    <TextField label={t("Vorname")} value={values.firstName}
                               onChange={handleChange} name={"firstName"}
                               onBlur={handleBlur}
                               error={touched["firstName"] && Boolean(errors["firstName"])}
                               helperText={touched["firstName"] && errors["firstName"]}/>
                    <TextField label={t("Nachname")} value={values.lastName}
                               onChange={handleChange} name={"lastName"}
                               onBlur={handleBlur}
                               error={touched.lastName && Boolean(errors["lastName"])}
                               helperText={touched["lastName"] && errors["lastName"]}/>

                </Stack>
            </DialogContent>
        </form>
        <DialogActions>
            <Button onClick={handleClose}>
                {t('abbrechen')}
            </Button>
            <Button onClick={clickedOnSubmit} disabled={!isValid} variant={"contained"}>
                {t('benutzerEinladen')}
            </Button>

        </DialogActions>
    </Dialog>
}
const InviteAccountDialogRef = forwardRef(InviteAccountDialog);
export default InviteAccountDialogRef;
