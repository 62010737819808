import {UserManagementRestControllerService, UserResource} from "../../backend/market";
import {InviteFormikValues} from "../../components/overlays/InviteAccount/InviteAccountDialog";
import {slice} from "../slices/admin.reducer";
import {AppDispatch, RootState, snackContext} from "../store";
import {refetchModuleManagement} from "./constants.thunk";
import i18n from '../../i18n/i18n';

export const loadUsers = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().user.currentUser.id;
    dispatch(slice.actions.setCompanyUsersLoading(true));

    try {
        const users = await UserManagementRestControllerService.getUnderlings(userId);
        dispatch(slice.actions.setCompanyUsers(users));
        dispatch(slice.actions.setCompanyUsersLoading(false));

    } catch (e) {
        dispatch(slice.actions.setCompanyUsersLoading(false));
        snackContext.enqueueSnackbar("Fehler beim Laden der Benutzer!", {variant: "error"});
        return;
    }

}
export const inviteUser = (values: InviteFormikValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(slice.actions.setCompanyUsersLoading(true));
        const userId = getState().user.currentUser.id;
        const user = await UserManagementRestControllerService.inviteUser(userId, {
            firstName: values.firstName,
            lastName: values.lastName,
            mail: values.email
        });
        snackContext.enqueueSnackbar(i18n.t("Benutzer erfolgreich eingeladen!"), {variant: "success"});
        dispatch(slice.actions.addUser(user.userResource));
        dispatch(slice.actions.setCompanyUsersLoading(false));
        dispatch(refetchModuleManagement());
    } catch (e) {
        dispatch(slice.actions.setCompanyUsersLoading(false));
        snackContext.enqueueSnackbar(i18n.t("Fehler beim Einladen des Benutzers!"), {variant: "error"});
    }
}
export const reinviteUser = (user: UserResource) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const invitedUserId = user.id;
        const userId = getState().user.currentUser.id
        await UserManagementRestControllerService.inviteUserAgain(userId, invitedUserId)
        snackContext.enqueueSnackbar(i18n.t("Benutzer erfolgreich erneut eingeladen!"), {variant: "success"});
    } catch (e) {
        dispatch(slice.actions.setCompanyUsersLoading(false));
        snackContext.enqueueSnackbar(i18n.t("Fehler beim Erneuten einladen des Benutzers!"), {variant: "error"});
    }
}
export const deleteUser = (user: UserResource) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(slice.actions.setCompanyUsersLoading(true));
        await UserManagementRestControllerService.deleteUser(user.id);
        dispatch(slice.actions.deleteUser(user.id));
        dispatch(slice.actions.setCompanyUsersLoading(false));
        dispatch(refetchModuleManagement());
    } catch (e) {
        dispatch(slice.actions.setCompanyUsersLoading(false));
        snackContext.enqueueSnackbar(i18n.t("Fehler beim Löschen des Benutzers!"), {variant: "error"});
    }
}
const changeRole = (user: UserResource, roleId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const userId = getState().user.currentUser.id;
        dispatch(slice.actions.setCompanyUsersLoading(true));
        await UserManagementRestControllerService.setNewUserRoleToUser(userId, {
            newUserRoleId: roleId,
            userIdToChangeRole: user.id,
        })
        dispatch(loadUsers());
    } catch (e) {
        dispatch(slice.actions.setCompanyUsersLoading(false));
        snackContext.enqueueSnackbar(i18n.t("Fehler beim Ändern der Benutzerrolle!"), {variant: "error"});
    }
}
export const promoteToAdmin = (user: UserResource) => async (dispatch: AppDispatch) => {
    dispatch(changeRole(user, 1));
}
export const degradeToUser = (user: UserResource) => async (dispatch: AppDispatch) => {
    dispatch(changeRole(user, 2))
}
