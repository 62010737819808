import { Alert, Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState, useDispatch } from 'src/redux/store';
import PageHeader from 'src/components/display/PageHeader';
import { useEffect, useState } from 'react';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { FileResource } from 'src/backend/market';
import { loadFile } from 'src/redux/thunks/fileManager.thunk';
import i18n from 'src/i18n/i18n';
import { connect } from 'react-redux';
import useDialog from 'src/hooks/useDialog';
import { CalculationPartResource, InternalCalculationResource, InternalCalculationRestControllerService } from 'src/backend/internalCalc';
import { useNavigate } from 'react-router';
import { nav } from 'src/statics/navigations';
import { startUserLockChecker, stopUserLockChecker } from 'src/redux/thunks/user.thunk';
import { getProCalcById, create3dPart } from 'src/redux/thunks/proCalc.thunk';

interface Props {}

const AutoAnalyze3d: React.FC<Props> = ({}) => {
    const navigate = useNavigate();
    const [calculationId, setCalculationId] = useState<number>();
    const [calc, setCalc] = useState<InternalCalculationResource>();
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [file, setFile] = useState<FileResource>();
    const [part, setPart] = useState<CalculationPartResource>();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const { t } = useTranslation();
    const theme = useTheme();

    const errorDialogSettings = {
        severity: 'error' as any,
        okText: t('toDashboard'),
        showCancel: false,
        disableClickAway: true,
        component: <Typography>{t('fileOrCalcNotFoundExplanation')}</Typography>,
        title: t('fileOrCalcNotFoundTitle'),
        okCallback: () => {
            dialog.closeDialog();
            navigate(nav.DASHBOARD.path);
        }
    };

    useEffect(() => {
        dispatch(stopUserLockChecker());
        const queryParams = new URLSearchParams(location.search);
        const nodeId = queryParams.get('nodeId');
        const calculationIdQuery = queryParams.get('calculationId');
        if (!nodeId || !calculationIdQuery || isNaN(Number(calculationIdQuery))) {
            return dialog.openDialog(errorDialogSettings);
        }
        const calculationId = parseInt(calculationIdQuery);
        setCalculationId(calculationId);

        dispatch(
            loadFile(nodeId, async (file: FileResource) => {
                if (file) setFile(file);
                else dialog.openDialog(errorDialogSettings);
            })
        );

        dispatch(getProCalcById(calculationId)).then((calc) => {
            setCalc(calc);
            const found = (calc?.parts || []).find((part) => part.threeDFileId === nodeId);
            if (found) setPart(found);
            else dialog.openDialog(errorDialogSettings);
        });

        return () => {
            dispatch(startUserLockChecker());
        };
    }, []);

    const handleOnViewerLoader = async (stats: ModelStats) => {
        if (!stats || !part) return;
        setSuccess(false);
        await dispatch(create3dPart(calc, { itemId: part.itemId, stats, file, disableHoleRecognition: true }));
        setSuccess(true);
    };

    return (
        <>
            <PageHeader>{isSuccess && <Alert id="success-alert">{t('successfullySavedModelDataToCalc')}</Alert>}</PageHeader>
            <Stack direction="row" mx={{ xs: -2, md: -4 }} mt={{ xs: -1.5, md: 0 }} mb={-4} flexGrow={1} overflow="hidden">
                {file && part && <SSA3DViewer url={file.url} fileName={file.name} language={i18n.language} onViewerLoaded={handleOnViewerLoader} />}
            </Stack>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {};
};
export default connect(mapStateToProps)(AutoAnalyze3d);
