import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    MenuItem,
    Popover,
    Select,
    Stack,
    styled,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import React, { ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculationResultResource, SurchargeReportEntryResource } from 'src/backend/market';
import { useDispatch, useSelector } from 'react-redux';
import { formatPrice, formatSurchargeValue } from 'src/utils/FormatHelpers';
import store from 'src/redux/store';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import InfoTwoTone from '@mui/icons-material/InfoTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import AdjustTwoToneIcon from '@mui/icons-material/AdjustTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { CalculationPartResource } from 'src/backend/internalCalc';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { ProCalcUpdateTypes, selectProCalcSettings, updateProCalc } from 'src/redux/shared/proCalc.common';

const StyledList = styled(List)(
    ({ theme }) => `
        width: 27rem;
        max-width: 100%;
        .MuiListItem-root {
            min-height: 4.5rem;
        }
        .MuiListSubheader-root {
            display: flex;
            align-items: center;
            min-height: 2.5rem;
            background: ${theme.colors.alpha.black[5]};
            font-weight: bold;
        }
    `
);

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.info.lighter};
        color: ${theme.colors.info.main};
    `
);

interface Props {
    part?: CalculationPartResource;
    costResult?: CalculationResultResource;
    quantity?: number;
    showUnitPrice?: boolean;
    disabled?: boolean;
}

const ItemPrice: React.FC<Props> = ({ part, costResult, quantity, showUnitPrice, disabled }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [forcedPrice, setForcedPrice] = useState<string>(Math.round(showUnitPrice ? costResult?.priceNonRounded / quantity : costResult?.priceNonRounded * 100) / 100 + '');
    const [isEditing, setEditing] = useState<boolean>(false);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const refManipulation = useRef<any>(null);
    const [isManipulationOpen, setManipulationOpen] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState('category');
    const settings = useSelector(selectProCalcSettings);
    const min = 0;
    const max = 1500000;

    const isPriceOverwritten = part?.forcePrice?.force;

    const basePrice = costResult?.basePrice;
    const appliedSurcharges = useMemo(() => (costResult?.surchargeReport || []).filter((surcharge) => surcharge.applied), [costResult?.surchargeReport]);
    const currentTotalPrice = useMemo(() => Math.round(costResult?.priceNonRounded * 100) / 100, [isPriceOverwritten, costResult?.priceNonRounded]);
    const currentUnitPrice = useMemo(() => currentTotalPrice / quantity, [currentTotalPrice, quantity]);

    useEffect(() => {
        if (isEditing) return;
        setForcedPrice((showUnitPrice ? currentUnitPrice : currentTotalPrice) + '');
    }, [costResult]);

    const startEditing = () => {
        if (isLoading || disabled) return;
        setEditing(true);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setForcedPrice(event.target.value);
    };

    const handleKeypress = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    const saveForcePrice = () => {
        let forcedPriceNumber = parseFloat(forcedPrice);
        if (forcedPriceNumber > max) forcedPriceNumber = max;
        if (forcedPriceNumber < min) forcedPriceNumber = min;
        if (showUnitPrice) forcedPriceNumber *= quantity;
        const isDifferent = Math.round(costResult.unspoiledPrice * 100) / 100 !== forcedPriceNumber;
        dispatch(updateProCalc(ProCalcUpdateTypes.FORCE_PART_PRICE, { partId: part.id, force: isDifferent, price: forcedPriceNumber }));
        setEditing(false);
    };
    const removeForcePrice = () => {
        setLoading(true);
        const forcedPriceNumber = parseFloat(forcedPrice);
        dispatch(updateProCalc(ProCalcUpdateTypes.FORCE_PART_PRICE, { partId: part.id, force: false, price: forcedPriceNumber }));
        setOpen(false);
    };

    const getPrice = (price, showPrefix = false) => formatPrice(price, showPrefix, settings.numberFormat, settings.currency);

    const getAbsoluteSurchargePrice = (surcharge: SurchargeReportEntryResource) => {
        const price = surcharge.absoluteSurcharge ? surcharge.value : basePrice * surcharge.value;
        return getPrice(showUnitPrice ? price / quantity : price, true);
    };

    return (
        <Box id={'itemPriceBox'} display="flex" alignItems="center" ml="auto" height="36px">
            {basePrice != null && (
                <Button sx={{ mr: 1, px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<InfoTwoTone />} variant="text" color="secondary" onClick={() => setOpen(true)} size="small" ref={ref}>
                    {t('priceInfo')}
                </Button>
            )}

            {costResult?.priceNonRounded > 0 && (
                <Typography color="secondary" variant="subtitle2" mr={2}>
                    ({showUnitPrice ? getPrice(currentTotalPrice) + ' ' + t('gesamtpreis') : getPrice(currentUnitPrice) + ' ' + t('pricePerUnit')})
                </Typography>
            )}
            {isEditing ? (
                <ClickAwayListener onClickAway={saveForcePrice}>
                    <TextField
                        value={forcedPrice}
                        size="small"
                        type="number"
                        onBlur={saveForcePrice}
                        onChange={handleChange}
                        onKeyDown={handleKeypress}
                        onFocus={(e) => {
                            e.target.select();
                        }}
                        variant="outlined"
                        sx={{ m: 0, minWidth: '7rem' }}
                        inputProps={{ sx: { px: 1, py: 0.5 }, min, max }}
                    />
                </ClickAwayListener>
            ) : (
                <Box display="flex" onClick={startEditing} alignItems="center">
                    <Typography color="primary" variant="h3">
                        {getPrice(showUnitPrice ? currentUnitPrice : currentTotalPrice)}
                    </Typography>
                    {isPriceOverwritten && (
                        <Typography color="primary" variant="h3" ml={1} sx={{ textDecoration: 'line-through', fontSize: '18px !important', fontWeight: 'normal' }}>
                            {showUnitPrice ? getPrice(costResult?.unspoiledPrice / quantity) : getPrice(costResult?.unspoiledPrice)}
                        </Typography>
                    )}
                    <Box display="flex" alignItems="center" pl={0.4}>
                        <Tooltip arrow placement="top" title={t('overridePrice')}>
                            <span>
                                <IconButton color="primary" onClick={startEditing} disabled={isLoading || disabled}>
                                    {isLoading ? <CircularProgress size={20} /> : <EditTwoToneIcon fontSize="small" />}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            {/* <Box display="flex" alignItems="center">
                <Typography color="primary" variant="h3">
                    {getPrice(showUnitPrice ? currentUnitPrice : currentTotalPrice)}
                </Typography>
                {isPriceOverwritten && (
                    <Typography color="primary" variant="h3" ml={1} sx={{ textDecoration: 'line-through', fontSize: '18px !important', fontWeight: 'normal' }}>
                        {showUnitPrice ? getPrice(costResult?.unspoiledPrice / quantity) : getPrice(costResult?.unspoiledPrice)}
                    </Typography>
                )}
                <Box display="flex" alignItems="center" pl={0.4}>
                    <Tooltip arrow placement="top" title={t('overridePrice')}>
                        <span>
                            <IconButton color="primary" onClick={() => setManipulationOpen(true)} disabled={isLoading || disabled} ref={refManipulation}>
                                {isLoading ? <CircularProgress size={20} /> : <EditTwoToneIcon fontSize="small" />}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Popover
                anchorEl={refManipulation.current}
                onClose={() => setManipulationOpen(false)}
                open={isManipulationOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Stack direction="row" sx={{ p: 3 }} gap={3} divider={<Divider flexItem orientation="vertical" sx={{ my: -3 }} />}>
                    <Stack>
                        <Typography variant="h3">Preismanipulation</Typography>

                        <Stack direction="row" gap={2} my={2} mx={-0.7}>
                            <Stack p={0.7}>
                                <Typography variant="subtitle2">Gewicht</Typography>
                                <Typography variant="h3" color="primary">
                                    € 65,52
                                </Typography>
                            </Stack>
                            <Stack p={0.7}>
                                <Typography variant="subtitle2">Warenträger</Typography>
                                <Typography variant="h3" color="primary">
                                    € 73,52
                                </Typography>
                            </Stack>
                            <Stack p={0.7} sx={{ border: '2px solid', borderColor: theme.colors.primary.main, borderRadius: 1 }}>
                                <Typography variant="subtitle2">Quadratmeter</Typography>
                                <Typography variant="h3" color="primary">
                                    € 78,80
                                </Typography>
                            </Stack>
                        </Stack>

                        <Typography variant="h4" display="flex" alignSelf="flex-start" alignItems="center" gutterBottom mt="auto">
                            <InfoTwoToneIcon sx={{ mr: 0.6 }} />
                            Quadratmeter Kalkulation
                        </Typography>
                        <Typography>Kalkulation: Beschichtungsfläche x m² Preis</Typography>
                        <Typography>Beschichtungsfläche: Konvexe Hülle</Typography>
                    </Stack>
                    <Stack>
                        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" sx={{ minWidth: '22vw', pb: 2, mt: -1 }}>
                            <Tab label="Kategorie" value="category" />
                            <Tab label="Aufschläge" value="surcharges" />
                            <Tab label="Pulver" value="powder" />
                        </Tabs>
                        <Divider sx={{ mx: -3 }} />
                        <Stack gap={3} mt={3}>
                            <TextField value={'7'} label="Quadratmeter Preis" size="small" />

                            <FormControl fullWidth>
                                <InputLabel id="calc-foundation-label">Berechnungsgrundlage</InputLabel>
                                <Select labelId="calc-foundation-label" label="Berechnungsgrundlage" value={'nut.step'} size="small" fullWidth>
                                    <MenuItem value="nut.step">Konvexe Hülle</MenuItem>
                                    <MenuItem value="complex.stp">Komplex</MenuItem>
                                    <MenuItem value="Gelaender.step">Geländer</MenuItem>
                                </Select>
                            </FormControl>

                            <Button sx={{ alignSelf: 'flex-end' }} variant="outlined" startIcon={<SaveTwoToneIcon />} size="small">
                                Als Default speichern
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Popover> */}

            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                <Stack>
                    <Typography variant="h4" p={2}>
                        {t('priceInfo')}
                    </Typography>
                    <StyledList disablePadding>
                        <ListSubheader>
                            <Typography fontWeight="bold">{t('general')}</Typography>
                        </ListSubheader>
                        <ListItem>
                            <ListItemAvatar>
                                <AvatarWrapper>
                                    <AdjustTwoToneIcon />
                                </AvatarWrapper>
                            </ListItemAvatar>
                            <ListItemText primary={t('basePrice')} />
                            <Typography fontWeight="bold" noWrap flexShrink={0}>
                                {getPrice(showUnitPrice ? basePrice / quantity : basePrice)}
                            </Typography>
                        </ListItem>

                        <ListSubheader>
                            <Typography fontWeight="bold">{t('surcharges')}</Typography>
                        </ListSubheader>

                        {appliedSurcharges.length === 0 && (
                            <ListItem>
                                <ListItemAvatar>
                                    <AvatarWrapper>
                                        <LocalOfferTwoToneIcon />
                                    </AvatarWrapper>
                                </ListItemAvatar>
                                <ListItemText primary={t('noSurchargeApplied')} />
                                <Typography fontWeight="bold" noWrap flexShrink={0}>
                                    {getPrice(0, true)}
                                </Typography>
                            </ListItem>
                        )}
                        {appliedSurcharges.map((surcharge, index) => (
                            <Fragment key={index}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <AvatarWrapper>
                                            <LocalOfferTwoToneIcon />
                                        </AvatarWrapper>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={surcharge.description}
                                        secondary={!surcharge.absoluteSurcharge && formatSurchargeValue(surcharge.value, surcharge.absoluteSurcharge, settings.numberFormat, settings.currency)}
                                    />
                                    <Typography fontWeight="bold" noWrap flexShrink={0}>
                                        {getAbsoluteSurchargePrice(surcharge)}
                                    </Typography>
                                </ListItem>
                                {appliedSurcharges.length - 1 !== index && <Divider component="li" />}
                            </Fragment>
                        ))}

                        <ListSubheader>
                            <Typography fontWeight="bold">{t('result')}</Typography>
                        </ListSubheader>
                        <ListItem>
                            <ListItemAvatar>
                                <AvatarWrapper>
                                    <FactCheckTwoToneIcon />
                                </AvatarWrapper>
                            </ListItemAvatar>
                            <ListItemText
                                primary={t(showUnitPrice ? 'finalPricePerUnit' : 'finalPrice')}
                                secondary={t(showUnitPrice ? 'finalPrice' : 'finalPricePerUnit')}
                                primaryTypographyProps={{ fontWeight: !isPriceOverwritten && 'bold' }}
                            />
                            <Stack alignItems="flex-end">
                                <Typography fontWeight="bold" sx={{ textDecoration: isPriceOverwritten && 'line-through' }} noWrap flexShrink={0}>
                                    {getPrice(showUnitPrice ? costResult?.unspoiledPrice / quantity : costResult?.unspoiledPrice)}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ textDecoration: isPriceOverwritten && 'line-through' }} noWrap flexShrink={0}>
                                    {getPrice(showUnitPrice ? costResult?.unspoiledPrice : costResult?.unspoiledPrice / quantity)}
                                </Typography>
                            </Stack>
                        </ListItem>

                        {isPriceOverwritten && (
                            <>
                                <Divider component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <AvatarWrapper>
                                            <EditTwoToneIcon />
                                        </AvatarWrapper>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={t(showUnitPrice ? 'overwrittenPricePerUnit' : 'overwrittenPrice')}
                                        secondary={t(showUnitPrice ? 'overwrittenPrice' : 'overwrittenPricePerUnit')}
                                        primaryTypographyProps={{ fontWeight: 'bold' }}
                                    />
                                    <Stack alignItems="flex-end" mr={1}>
                                        <Tooltip title={t('deletePriceOverride')}>
                                            <IconButton color="error" disabled={isLoading || disabled} onClick={removeForcePrice}>
                                                <DeleteTwoToneIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <Stack alignItems="flex-end">
                                        <Typography fontWeight="bold" noWrap flexShrink={0}>
                                            {getPrice(showUnitPrice ? costResult.priceNonRounded / quantity : costResult.priceNonRounded)}
                                        </Typography>
                                        <Typography variant="subtitle1" noWrap flexShrink={0}>
                                            {getPrice(showUnitPrice ? costResult.priceNonRounded : costResult.priceNonRounded / quantity)}
                                        </Typography>
                                    </Stack>
                                </ListItem>
                            </>
                        )}
                    </StyledList>
                </Stack>
            </Popover>
        </Box>
    );
};

export default ItemPrice;
