import { Global } from '@emotion/react';
import { CloseTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import 'nprogress/nprogress.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import './i18n/i18n';
import './index.css';
import { ScrollToTopOnMobile } from './utils/RouteHelper';

const notistackRef = React.createRef<SnackbarProvider>();
const root = createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
        <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            action={
                <IconButton style={{ color: 'white' }} onClick={() => notistackRef.current.closeSnackbar()}>
                    <CloseTwoTone />
                </IconButton>
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        >
            <BrowserRouter>
                <ScrollToTopOnMobile />
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </HelmetProvider>
);

serviceWorker.unregister();
