/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdditionalStorageDto } from '../models/AdditionalStorageDto';
import type { AdditionalStorageResource } from '../models/AdditionalStorageResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InternalCalculationAdditionalStorageRestControllerService {

    /**
     * @param partId
     * @param calculationId
     * @returns AdditionalStorageResource OK
     * @throws ApiError
     */
    public static getAdditionalStorageOfCalculation(
        partId: number,
        calculationId: number,
    ): CancelablePromise<Array<AdditionalStorageResource>> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/${calculationId}/parts/${partId}/additionalStorage`,
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns AdditionalStorageResource OK
     * @throws ApiError
     */
    public static addAdditionalStorageToPart(
        calculationId: number,
        partId: number,
        requestBody: AdditionalStorageDto,
    ): CancelablePromise<AdditionalStorageResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/parts/${partId}/additionalStorage`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @returns AdditionalStorageResource OK
     * @throws ApiError
     */
    public static getAdditionalStorageOfCalculation1(
        calculationId: number,
    ): CancelablePromise<Array<AdditionalStorageResource>> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/${calculationId}/calculationMetadata/additionalStorage`,
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns AdditionalStorageResource OK
     * @throws ApiError
     */
    public static addAdditionalStorageToCalculation(
        calculationId: number,
        requestBody: AdditionalStorageDto,
    ): CancelablePromise<AdditionalStorageResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/calculationMetadata/additionalStorage`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param key
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAdditionalStorageFromPart(
        calculationId: number,
        partId: number,
        key: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/internalCalculations/${calculationId}/parts/${partId}/additionalStorage/${key}`,
        });
    }

    /**
     * @param calculationId
     * @param key
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAdditionalStorageFromCalculationMetadata(
        calculationId: number,
        key: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/internalCalculations/${calculationId}/calculationMetadata/additionalStorage/${key}`,
        });
    }

}