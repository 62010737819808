import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface IGuidethroughReducer {
    run: boolean;
    step: number;
    screens: {
            name: string;
            completed: boolean;
            steps: { target: string; content: string; }[];
    }[];
    currentScreen: string;
}

const initialState: IGuidethroughReducer = {
    run: false,
    step: 0,
    currentScreen: "dashboard",
    screens: [
        {
            name: "dashboard",
            completed: false,
            steps: [
                {
                    target: ".MuiTypography-h3",
                    content: 'dashboardExplanationStep1'
                },
                {
                    target: "#internalCalcDashboard",
                    content: "dashboardExplanationStep2"
                },
                {
                    target: "#inquiriesComponentDashboard",
                    content: "dashboardExplanationStep3"
                },
                {
                    target: "#profileWrapperDashboard",
                    content: "dashboardExplanationStep4"
                },
                {
                    target: "#homeLink",
                    content: "dashboardExplanationStep5"
                },
                {
                    target: "#calculationLink",
                    content: "dashboardExplanationStep6"
                },
                {
                    target: "#wizardLink",
                    content: "dashboardExplanationStep7"
                },
                {
                    target: "#toolsLink",
                    content: "dashboardExplanationStep8"
                },
                {
                    target: "#userBoxDashboard",
                    content: "dashboardExplanationStep9"
                },

            ]
        },
        {
            name: "toolsAndSettings",
            completed: false,
            steps: [
                {
                    target: "#toolsAndSettingsBox",
                    content: "toolsAndSettingsExplanationStep1"
                },
                {
                    target: "a[href=\"/tools/developer-settings/\"]",
                    content: "toolsAndSettingsExplanationStep2"
                },
                {
                    target: "a[href=\"/tools/onboarding/\"]",
                    content: "toolsAndSettingsExplanationStep3"
                },
                {
                    target: "a[href=\"/tools/customers\"]",
                    content: "toolsAndSettingsExplanationStep4"
                },
                {
                    target: "a[href=\"/tools/admin/\"]",
                    content: "toolsAndSettingsExplanationStep5"
                },
            ]
        },
        {
            name: "developerSettings",
            completed: false,
            steps: [
                {
                    target: ".MuiInputBase-input",
                    content: "developerSettingsExplanationStep1"
                },
                {
                    target: "#generateApiKeyButton",
                    content: "developerSettingsExplanationStep2"
                },
            ]
        },
        {
            name: "calculationConfiguration",
            completed: false,
            steps: [
                {
                    target: "#activeCalculationCards",
                    content: "calculationExplanationStep1"
                },
                {
                    target: ".MuiDataGrid-root",
                    content: "calculationExplanationStep2"
                },
                {
                    target: "#createCalculationButton",
                    content: "calculationExplanationStep3"
                },
            ]
        },
        {
            name: "clientConfiguration",
            completed: false,
            steps: [
                {
                    target: "#filterCustomerBox",
                    content: "clientExplanationStep1"
                },
                {
                    target: ".MuiDataGrid-row",
                    content: "clientExplanationStep2"
                },
                {
                    target: "div[data-colindex=\"9\"]",
                    content: "clientExplanationStep3"
                },
                {
                    target: "div[data-colindex=\"10\"]",
                    content: "clientExplanationStep4"
                },
                {
                    target: ".MuiButton-containedPrimary",
                    content: "clientExplanationStep5"
                },
            ]
        },
        {
            name: "administration",
            completed: false,
            steps: [
                {
                    target: ".MuiAlert-message",
                    content: "administrationExplanationStep1"
                },
                {
                    target: "li",
                    content: "administrationExplanationStep2"
                },
                {
                    target: ".MuiButton-outlined",
                    content: "administrationExplanationStep3"
                },
            ]
        },
        {
            name: "userbox",
            completed: false,
            steps: [
                {
                    target: "#userBoxPopover",
                    content: "userboxExplanationStep1"
                },
                {
                    target: "#logoutUserButton",
                    content: "userboxExplanationStep2"
                },
            ]
        },
        {
            name: "profile",
            completed: false,
            steps: [
                {
                    target: "#userInformationCard",
                    content: "profileExplanationStep1"
                },
                {
                    target: "#companyInformationCard",
                    content: "profileExplanationStep2"
                },
                {
                    target: "#passwordInformationCard",
                    content: "profileExplanationStep3"
                },
                {
                    target: "#saveUserDataButton",
                    content: "profileExplanationStep4"
                }
            ]
        },
        {
            name: "internalCalculation",
            completed: false,
            steps: [
                {
                    target: ".MuiDataGrid-virtualScrollerContent",
                    content: "internalCalculationExplanationStep1"
                },
                {
                    target: "div[data-colindex=\"8\"]",
                    content: "internalCalculationExplanationStep2"
                },
                {
                    target: "#filterInternalCalc",
                    content: "internalCalculationExplanationStep3"
                },
                {
                    target: "#createInternalCalculationButton",
                    content: "internalCalculationExplanationStep4"
                }
            ]
        },
        {
            name: "internalCalculationEdit",
            completed: false,
            steps: [
                {
                    target: ".MuiIconButton-sizeMedium",
                    content: "internalCalculationEditExplanationStep1"
                },
                {
                    target: "#titleEditCalcHeader",
                    content: "internalCalculationEditExplanationStep2"
                },
                {
                    target: "#actionButtonsEditCalcHeader",
                    content: "internalCalculationEditExplanationStep3"
                },
                {
                    target: "#customerDetailsCard",
                    content: "internalCalculationEditExplanationStep4"
                },
                {
                    target: "#masterCalcDetailsCard",
                    content: "internalCalculationEditExplanationStep5"
                },
                {
                    target: "#categoryAreaList",
                    content: "internalCalculationEditExplanationStep6"
                },
                {
                    target: "#searchPartsTextField",
                    content: "internalCalculationEditExplanationStep7"
                },
                {
                    target: "#itemAreaAllParts",
                    content: "internalCalculationEditExplanationStep8"
                }
            ]
        },
        {
            name: "newPartInCalculation",
            completed: false,
            steps: [
                {
                    target: "#piecesAndNameStack",
                    content: "newPartExplanationStep1"
                },
                {
                    target: "#deleteDuplicateButtons",
                    content: "newPartExplanationStep2"
                },
                {
                    target: "#allInfosOfProduct",
                    content: "newPartExplanationStep3"
                },
                {
                    target: "#itemPriceBox",
                    content: "newPartExplanationStep4"
                },
                {
                       target: "#attachmentsAndMoreBox",
                          content: "newPartExplanationStep5"
                },
                {
                    target: "#categoriesAndSearchBar",
                    content: "newPartExplanationStep6"
                },
                {
                    target: "#summarizeCalcBar",
                    content: "newPartExplanationStep7"
                }
            ]
        },
        {
            name: "customerDetails",
            completed: false,
            steps: [
                {
                    target: "#toggleButtonGroup",
                    content: "customerDetailsExplanationStep1"
                },
                {
                    target: "#companyNameTextField",
                    content: "customerDetailsExplanationStep2"
                },
                {
                    target: "#mapImage",
                    content: "customerDetailsExplanationStep3"
                },
                {
                    target: "#dataBox",
                    content: "customerDetailsExplanationStep4"
                }
            ]
        },
        {
            name: "wizard",
            completed: false,
            steps: [
                {
                    target: "#incomingRequestsBox",
                    content: "wizardExplanationStep1"
                },
                {
                    target: ".MuiDataGrid-main",
                    content: "wizardExplanationStep2"
                },
                {
                    target: "div[data-colindex=\"8\"]",
                    content: "wizardExplanationStep3"
                },
                {
                    target: "div[data-colindex=\"9\"]",
                    content: "wizardExplanationStep4"
                },
                {
                    target: "#notFinishedInquiriesBox",
                    content: "wizardExplanationStep5"
                }
                ]
        },
        {
            name: "wizardSettings",
            completed: false,
            steps: [
                {
                    target: ".MuiIconButton-sizeMedium",
                    content: "wizardSettingsExplanationStep1"
                },
                {
                    target: ".MuiPaper-root",
                    content: "wizardSettingsExplanationStep2"
                },
                {
                    target: ".MuiTab-textColorPrimary",
                    content: "wizardSettingsExplanationStep3"
                }
                ]
        },
        {
            name: "wizardSettingsWeb",
            completed: false,
            steps: [
                {
                    target: ".MuiIconButton-sizeMedium",
                    content: "wizardSettingsWebExplanationStep1"
                },
                {
                    target: ".Mui-selected",
                    content: "wizardSettingsWebExplanationStep2"
                },
                {
                    target: ".MuiPaper-root",
                    content: "wizardSettingsWebExplanationStep3"
                },
                {
                    target: "#wizardThemePicker",
                    content: "wizardSettingsWebExplanationStep4"
                },
                {
                    target: "#wizardGeneralMessages",
                    content: "wizardSettingsWebExplanationStep5"
                },
                {
                    target: "#wizardCodeSnippet",
                    content: "wizardSettingsWebExplanationStep6"
                },

            ]
        }
    ]
}

const reducers = {

    startWalkthrough: (state: IGuidethroughReducer, action: PayloadAction<string>) => {
        state.step = 0;
        state.currentScreen = action.payload;
        state.run = true;
    },

    stopWalkthrough: (state: IGuidethroughReducer) => {
        state.run = false;
    },

    finishWalkthrough: (state: IGuidethroughReducer) => {
        state.run = false;
        //find screen by currentScreen name
        const screen = state.screens.find(s => s.name === state.currentScreen);
        screen.completed = true;

        //replace screen in screens
        const screens = state.screens.filter(s => s.name !== state.currentScreen);
        screens.push(screen);

        //create a copy of screens
        state.screens = [...screens];
    },

    nextStep: (state: IGuidethroughReducer) => {
        var temp = state.step+1;
        state.step = JSON.parse(JSON.stringify(temp));
    },

    stepBack: (state: IGuidethroughReducer) => {
        var temp = state.step-1;
        state.step = JSON.parse(JSON.stringify(temp));
    }

}

export const slice = createSlice({
    name: 'Guidethrough',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;