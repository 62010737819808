import _ from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { AdditionalStorageResource, SetObjectMetadataResource } from 'src/backend/internalCalc';
import { GEOMETRY_DIMENSION_LABEL } from 'src/statics/statics';
import { getField } from '../CalcParameters';

interface Props {
    id: number;
    partId?: number;
    group: Array<any>;
    isSmall: boolean;
    metadata?: Array<SetObjectMetadataResource>;
    disabled?: boolean;
    createdAt?: string;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const GeometryParameter: React.FC<Props> = ({ id, partId, group, metadata, isSmall, disabled, createdAt, additionalStorage }) => {
    const geometryMetadata = useMemo(() => {
        return (metadata || []).reduce((accumulator, data) => {
            const dimensionLabel = data.metadata.find((entry) => entry.key === GEOMETRY_DIMENSION_LABEL);
            if (dimensionLabel) accumulator[data.objectName] = dimensionLabel.value;
            return accumulator;
        }, {});
    }, [metadata]);

    return (
        <>
            {group.map((param, index) => (
                <Fragment key={param.geometryPackageIdentificationKey + id + index}>
                    {getField(param, param.geometryPackageIdentificationKey + id + index, [], isSmall, geometryMetadata[param.name], disabled, createdAt, partId, additionalStorage)}
                </Fragment>
            ))}
        </>
    );
};
export default GeometryParameter;
