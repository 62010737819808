/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TechnicalDrawingDto = {
    url?: string;
    fileType?: TechnicalDrawingDto.fileType;
}

export namespace TechnicalDrawingDto {

    export enum fileType {
        STEP = 'STEP',
        OBJ = 'OBJ',
        IGES = 'IGES',
        THREE_DM = 'THREE_DM',
        THREE_DS = 'THREE_DS',
        BIM = 'BIM',
        STL = 'STL',
        PDF = 'PDF',
        DWG = 'DWG',
        DXF = 'DXF',
        PNG = 'PNG',
        JPG = 'JPG',
    }


}
