import {ExpandMoreTwoTone, SearchTwoTone} from "@mui/icons-material";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    LinearProgress,
    MenuItem,
    OutlinedInput,
    Select,
    Slider,
    Stack,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Grid';
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {CustomerTypeResource, RegularCustomerFilterDto} from "../../../backend/market";
import {RootState} from "../../../redux/store";
import usePrevious from "../../../utils/usePrevious";

function CustomerFilterRow({filterCallback, customerTypes, loading, customers, filter, filterBoundary}) {
    const {t} = useTranslation();
    const [cFilter, setCFilter] = useState<RegularCustomerFilterDto>(filter);
    const prevFilter = usePrevious(cFilter);
    const debouncedFilterCallback = useCallback(_.debounce(filterCallback, 250), []);

    useEffect(() => {
        if (prevFilter == null) {
            return;
        }
        debouncedFilterCallback(cFilter);
    }, [cFilter])


    useEffect(() => {
        if (!_.isEqual(cFilter, filter)) {
            setCFilter(filter);
        }
    }, [filter])


    //UPDATE METHODS
    const updateSearchFilter = (event) => {
        setCFilter({...filter, searchString: event.target.value});
    };

    const updateSelectFilter = (event, name) => {
        setCFilter({...filter, [name]: event.target.value});
    };

    const updateInquiryFilter = (event, newValue) => {
        setCFilter({...filter, inquiryCount: [{minInquiryCountIncl: newValue[0], maxInquiryCountIncl: newValue[1]}]});
    };

    const updateSalesVolumeFilter = (event, newValue) => {
        setCFilter({...filter, salesVolume: [{minSalesVolumeIncl: newValue[0], maxSalesVolumeIncl: newValue[1]}]});
    };

    function mapDictToArray(dict: any): number | number[] {
        return Object.values(dict);
    }

    function renderValue(value: number[], arrayLength: number, translate?: boolean): string {
        const cTypes = customerTypes as CustomerTypeResource[];
        if (value.length === 0) {
            return t('keineAusgewhlt');
        }
        if (value.length === arrayLength) {
            return t('alle');
        }
        if (translate) {
            return value.map(item => t(cTypes.find(i => i.id === item)?.name ?? "")).join(", ");
        }
        return value.map(item => (cTypes.find(i => i.id === item)?.name ?? "")).join(", ");
    }

    //HELPER
    function numberFormat(value: number) {
        return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(value);
    }

    const resetFilter = (e) => {
        e.stopPropagation();
        setCFilter({
            searchString: '',
            customerTypeIds: [],
            salesVolume: [{
                minSalesVolumeIncl: filterBoundary.minSalesVolumeIncl === filterBoundary.maxSalesVolumeIncl ? 0 : filterBoundary.minSalesVolumeIncl,
                maxSalesVolumeIncl: filterBoundary.maxSalesVolumeIncl,
            }],
            inquiryCount: [{
                minInquiryCountIncl: filterBoundary.minInquiryCountIncl === filterBoundary.maxInquiryCountIncl ? 0 : filterBoundary.minInquiryCountIncl,
                maxInquiryCountIncl: filterBoundary.maxInquiryCountIncl,
            }]
        });
    }

    const hasEmptyFilter = useMemo<boolean>(() => {
        if (!filterBoundary) return false;
        const emptyFilter = {
            searchString: '',
            customerTypeIds: [],
            salesVolume: [{
                minSalesVolumeIncl: filterBoundary.salesVolumeBoundaries.min === filterBoundary.salesVolumeBoundaries.max ? 0 : filterBoundary.salesVolumeBoundaries.min,
                maxSalesVolumeIncl: filterBoundary.salesVolumeBoundaries.max,
            }],
            inquiryCount: [{
                minInquiryCountIncl: filterBoundary.inquiryCountBoundaries.min === filterBoundary.inquiryCountBoundaries.max ? 0 : filterBoundary.inquiryCountBoundaries.min,
                maxInquiryCountIncl: filterBoundary.inquiryCountBoundaries.max,
            }]
        }
        return _.isEqual(cFilter, emptyFilter)
    }, [filterBoundary, cFilter]);

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: "5"
        }
    ];
    const createMarks = (max: number) => {
        for (let i = 5; i <= max; i += 2) {
            marks.push({
                value: i,
                label: i.toString()
            })
        }
        return marks;
    }

    return <Accordion sx={{overflow: "hidden"}}>
        <AccordionSummary sx={{
            "& .MuiAccordionSummary-content": {
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "20px",
            }
        }} expandIcon={<ExpandMoreTwoTone/>}>
            <Box id={"filterCustomerBox"} sx={{
                display: "flex", alignItems: "center"
            }} gap={1}>
                <FilterAltTwoToneIcon color={!hasEmptyFilter ? "primary" : "inherit"}/>
                <Stack>
                    {!hasEmptyFilter && <Typography variant={"caption"} color={"primary"}>{t('filterActive')}</Typography>}
                    <Typography variant={"h4"}>{t('kundenFiltern')}</Typography>
                </Stack>
            </Box>
            <span><Button onClick={resetFilter}>{t('filterZurcksetzen')}</Button>
                {customers.length === 0 && <Typography variant={"caption"}>{t('noCustomersFound')}</Typography>}</span>
        </AccordionSummary>
        <AccordionDetails>
            {customerTypes && customerTypes.length > 0 ? <Grid container spacing={1}>
                <Grid item lg={6} md={6} xs={12}>
                    <Typography variant={"caption"}>{t('kundenDurchsuchen')}</Typography>
                    <OutlinedInput value={cFilter.searchString}
                                   fullWidth
                                   onChange={updateSearchFilter}
                                   startAdornment={<SearchTwoTone/>}
                                   placeholder={t('kundenDurchsuchen')}></OutlinedInput>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <Typography variant={"caption"}>{t('kundentypen')}</Typography>
                    <Select value={cFilter.customerTypeIds ?? []} multiple
                            fullWidth displayEmpty
                            onChange={(e) => updateSelectFilter(e, "customerTypeIds")}
                            renderValue={(value) => renderValue(value, customerTypes.length)}
                            placeholder={"Kundentypen"}>{customerTypes.map(itemType => (
                        <MenuItem key={itemType.id} value={itemType.id}>
                            <Checkbox checked={cFilter.customerTypeIds.indexOf(itemType.id) > -1}/>
                            {itemType.name}
                        </MenuItem>))}</Select>
                </Grid>
                {filterBoundary?.salesVolumeBoundaries && filterBoundary.salesVolumeBoundaries.max !== 0 &&
                    <Grid item lg={6} md={6} xs={12}>
                        <Typography variant={"caption"}>gesamter Anfragewert</Typography>
                        <Box ml={4} mr={4} mt={4}>
                            <Slider
                                min={filterBoundary.salesVolumeBoundaries.min === filterBoundary.salesVolumeBoundaries.max ? 0 : filterBoundary.salesVolumeBoundaries.min}
                                max={filterBoundary.salesVolumeBoundaries.max}
                                marks
                                step={(filterBoundary.salesVolumeBoundaries.max - (filterBoundary.salesVolumeBoundaries.min === filterBoundary.salesVolumeBoundaries.max ? 0 : filterBoundary.salesVolumeBoundaries.min)) / 10}
                                onChange={updateSalesVolumeFilter}
                                value={mapDictToArray(cFilter.salesVolume[0])}
                                valueLabelDisplay={"on"} size={"medium"}
                                valueLabelFormat={numberFormat}
                            />
                        </Box>
                    </Grid>}
                {filterBoundary?.inquiryCountBoundaries && filterBoundary.inquiryCountBoundaries.max !== 0 &&
                    <Grid item lg={6} md={6} xs={12}>
                        <Typography variant={"caption"}>Anfragen</Typography>
                        <Box ml={4} mr={4} mt={4}>
                            <Slider
                                min={0}
                                max={filterBoundary.inquiryCountBoundaries.max}
                                value={mapDictToArray(cFilter.inquiryCount[0])}
                                marks={createMarks(filterBoundary.inquiryCountBoundaries.max)}
                                onChange={updateInquiryFilter}
                                valueLabelDisplay={"on"}
                                size={"medium"}/>
                        </Box>
                    </Grid>}
            </Grid> : <Typography variant={"body1"}>{t('filternOhneKundentypenNichtMglich')}</Typography>}
        </AccordionDetails>
        {loading && <LinearProgress sx={{borderRadius: 0}}/>}
    </Accordion>

}

const mapStateToProps = (state: RootState) => {
    return {
        customerTypes: _.values(state.constants.customerTypes) ?? [],
        loading: state.customers.loading,
        customers: _.values(state.customers.customers),
        filter: state.customers.customerFilter,
        filterBoundary: state.constants.customerFilterBoundaries,
    }
};
export default connect(mapStateToProps)(CustomerFilterRow);
