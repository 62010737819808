import {useTheme} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import StatisticCard from './StatisticCard';
import { formatPrice, formatValue } from 'src/utils/FormatHelpers';
import { InternalCalculationPriceResource, UserResource } from 'src/backend/market';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';



interface Props {
    value: InternalCalculationPriceResource[];
    monthlyIncrease: InternalCalculationPriceResource[];
}

const InternalCalcsTotalValue: React.FC<Props> = ({ value, monthlyIncrease }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const user: UserResource = useSelector((state: RootState) => state.user.currentUser);

    const price = (value || []).map(item => formatPrice(item.price, false, user.numberFormat, item.currency)).join(' | ');
    const increase = (monthlyIncrease || []).map(item => formatValue(item.price * 100) + '%').join(' | ');

    return (
        <StatisticCard title={t('calculationsTotalValue')} value={price} monthlyIncrease={increase} icon={<ShoppingCartCheckoutIcon />} />
    );
}

export default InternalCalcsTotalValue;
