import { alpha, Button, Card, Grid, Stack, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ILayer } from 'src/backend/summary/resources/ILayer';
import store, { RootState } from 'src/redux/store';
import { itemImage } from 'src/utils/wizardImages';
import { wT } from 'src/utils/wizardTranslations';

const Category = styled(Button)<{ selected?: boolean }>(
    ({ theme, selected }) => `
        position: relative;
        flex-direction: row;
        align-items: center;
        height: 2.5rem;
        padding: ${theme.spacing(0.8)};
        margin-bottom: ${theme.spacing(0.8)};
        color: ${selected ? theme.colors.primary.main : 'unset'};
        border-radius: ${selected ? 0 : theme.shape.borderRadius}px;
        margin-right: ${theme.spacing(2)};

        .MuiTypography-root {
            font-weight: ${selected ? '700' : '500'};
        }

        img {
            height: 100%;
            margin-right: ${theme.spacing(1)};
            object-fit: contain;
            filter: ${selected ? 'invert(29%) sepia(100%) saturate(1755%) hue-rotate(208deg) brightness(102%) contrast(101%)' : 'none'};
        }

        ${
            selected &&
            `
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1px;
                background: ${theme.colors.primary.main};
                height: 1px;
                width: 100%;
            }
        `
        }
    `
);

interface Props {
    categories: Array<ILayer>;
    secondLayerItems: Array<ILayer>;
    selectedCategory?: number;
    isFragment?: boolean;
    setSelectedCategory?: (category: number) => void;
}

const CategoryArea: React.FC<Props> = ({ categories, selectedCategory, setSelectedCategory, secondLayerItems, isFragment }) => {
    const { t } = useTranslation();
    const s = store.getState();

    const handleCategorySelect = (category: number) => {
        setSelectedCategory(category);
    };

    const countedItemsPerCategory = useMemo(
        () =>
            (secondLayerItems || []).reduce((accumulator, item) => {
                if (accumulator[item.parent]) accumulator[item.parent]++;
                else accumulator[item.parent] = 0;
                return accumulator;
            }, {}),
        [secondLayerItems]
    );

    const shownCategories = useMemo(() => {
        let cCat = [...categories];
        const sortedCategories = cCat.sort((a, b) => a.rank - b.rank);
        return isFragment ? sortedCategories.filter((category) => category.id < 1 || !!countedItemsPerCategory[category.id]) : sortedCategories;
    }, [categories]);

    return (
        <Stack direction="row" flexWrap="wrap" p={2} pb={1.2} id={'categoryAreaList'}>
            {shownCategories.map(
                (category) =>
                    !category.nameKey.startsWith('$') && (
                        <Category key={category.id} onClick={() => handleCategorySelect(category.id)} selected={selectedCategory === category.id} variant="text" color="secondary">
                            <img src={itemImage(category.imageUri, category.itemUiImage)} alt={'item'} />
                            <Typography variant={'h5'}>{wT(category.nameKey, s)}</Typography>
                        </Category>
                    )
            )}
        </Stack>
    );
};
const mapStateToProps = (state: RootState) => ({
    categories: state.proCalc.categories,
    secondLayerItems: state.proCalc.secondLayerItems
});
export default connect(mapStateToProps)(CategoryArea);
