import {alpha, Box, Chip, styled, Typography} from '@mui/material';

import {Link} from 'react-router-dom';
import {nav} from 'src/statics/navigations';
import HeaderButtons from './Buttons';
import TopNavigator from './NavigatorLinks';
import HeaderTools from './Tools';
import HeaderUserbox from './Userbox';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

const HeaderWrapper = styled(Box)(
    ({theme}) => `
        position: fixed;
        justify-content: center;
        height: 50px;
        left: 0px;
        right: 0;
        width: 100%;
        max-width: 100%;
        color: ${theme.header.textColor};
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(8px);
        box-shadow: ${theme.header.boxShadow};
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            width: auto;
        }
`
);

function MobileDemoHeader() {

    return (
        <HeaderWrapper displayPrint="none" display="flex" alignItems="center">
            <Box display="flex" justifyContent="center" sx={{ position: "absolute", bottom: -13, left: 0, right: 0 }}>
                <Chip
                    label={<Typography variant={"caption"} color={"white"} fontWeight={"bolder"}>Demo</Typography>}
                    color={"primary"}
                    sx={{
                        maxHeight: 25,
                        minWidth: 75,
                        zIndex: 110000,

                        "& .MuiChip-label": {
                            textOverflow: "inherit",
                            overflow: "inherit",
                        }
                    }}/>

            </Box>
            <Box display="flex" alignItems="center" maxWidth="100%" width="100%" overflow="auto" py={0.8}>
                <HeaderButtons/>
            </Box>
        </HeaderWrapper>
    );
}

export default MobileDemoHeader;
