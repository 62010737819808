/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AiDataExtraction } from './AiDataExtraction';

export type TechnicalDrawingFileDescriptionDto = {
    fileManagerFileId?: string;
    fileType?: string;
    supportingFileFileManagerId?: string;
    originalFileName?: string;
    rawFileName?: string;
    thumbnailFileName?: string;
    aiDataExtraction?: AiDataExtraction;
    technicalDrawingFileType?: TechnicalDrawingFileDescriptionDto.technicalDrawingFileType;
}

export namespace TechnicalDrawingFileDescriptionDto {

    export enum technicalDrawingFileType {
        PDF = 'PDF',
        DWG_DXF = 'DWG_DXF',
    }


}
