/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKeyResource } from '../models/ApiKeyResource';
import type { CompanyResource } from '../models/CompanyResource';
import type { GeneralMessageDto } from '../models/GeneralMessageDto';
import type { GeneralMessagePositionAssignmentsDTO } from '../models/GeneralMessagePositionAssignmentsDTO';
import type { GeneralMessagePositionSwitchDto } from '../models/GeneralMessagePositionSwitchDto';
import type { GeneralMessageResource } from '../models/GeneralMessageResource';
import type { MarketplaceWizardProfileResource } from '../models/MarketplaceWizardProfileResource';
import type { NewGeneralFileDto } from '../models/NewGeneralFileDto';
import type { PresignedUrlResource } from '../models/PresignedUrlResource';
import type { WizardBackendRawProfileResource } from '../models/WizardBackendRawProfileResource';
import type { WizardKeyDto } from '../models/WizardKeyDto';
import type { WizardProfileDto } from '../models/WizardProfileDto';
import type { WizardProfileLanguageDto } from '../models/WizardProfileLanguageDto';
import type { WizardThemeIdDto } from '../models/WizardThemeIdDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WizardProfileRestControllerService {

    /**
     * @param userId
     * @returns MarketplaceWizardProfileResource OK
     * @throws ApiError
     */
    public static getMarketplaceWizardResource(
        userId: number,
    ): CancelablePromise<MarketplaceWizardProfileResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardProfile/${userId}`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateWizardProfile(
        userId: number,
        requestBody: WizardProfileDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setTheme(
        userId: number,
        requestBody: WizardThemeIdDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}/theme`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setLogo(
        userId: number,
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}/logo`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setWizardProfileLanguage(
        userId: number,
        requestBody: WizardProfileLanguageDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}/language`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param generalMessageId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static editGeneralMessage(
        userId: number,
        generalMessageId: number,
        requestBody: GeneralMessageDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}/generalMessages/${generalMessageId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param generalMessageId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteGeneralMessage(
        userId: number,
        generalMessageId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/wizardProfile/${userId}/generalMessages/${generalMessageId}`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static switchGeneralMessagePositions(
        userId: number,
        requestBody: GeneralMessagePositionSwitchDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}/generalMessages/positionSwitch`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static reAssignGeneralMessagePositions(
        userId: number,
        requestBody: GeneralMessagePositionAssignmentsDTO,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}/generalMessages/positionAssignment`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns ApiKeyResource OK
     * @throws ApiError
     */
    public static getApiKey(
        userId: number,
    ): CancelablePromise<ApiKeyResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardProfile/${userId}/apiKey`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static updateApiKey(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardProfile/${userId}/apiKey`,
        });
    }

    /**
     * @param userId
     * @returns GeneralMessageResource OK
     * @throws ApiError
     */
    public static getGeneralMessages(
        userId: number,
    ): CancelablePromise<Array<GeneralMessageResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardProfile/${userId}/generalMessages`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns GeneralMessageResource OK
     * @throws ApiError
     */
    public static addGeneralMessage(
        userId: number,
        requestBody: GeneralMessageDto,
    ): CancelablePromise<GeneralMessageResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/wizardProfile/${userId}/generalMessages`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createWizardAssignment(
        companyId: number,
        requestBody: WizardKeyDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/wizardProfile/${companyId}/wizardAssignments`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PresignedUrlResource OK
     * @throws ApiError
     */
    public static getRegularCustomerUploadUrl(
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<PresignedUrlResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/wizardProfile/imageUploadUrl`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns WizardBackendRawProfileResource OK
     * @throws ApiError
     */
    public static getUnassignedWizardProfiles(): CancelablePromise<Array<WizardBackendRawProfileResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardProfile/unassigned`,
        });
    }

    /**
     * @param wizardProfileKey
     * @returns CompanyResource OK
     * @throws ApiError
     */
    public static getCompanyByWizardProfileKey(
        wizardProfileKey: string,
    ): CancelablePromise<CompanyResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardProfile/company/${wizardProfileKey}`,
        });
    }

    /**
     * @param companyId
     * @param wizardProfileKey
     * @returns any OK
     * @throws ApiError
     */
    public static removeWizardAssignment(
        companyId: number,
        wizardProfileKey: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/wizardProfile/${companyId}/wizardAssignments/${wizardProfileKey}`,
        });
    }

}