import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import i18n from 'src/i18n/i18n';
import {NumberFormatLocaleResource, UserResource} from '../../backend/market';

//INTERFACE AND INITIAL STATE
export interface RegularCustomerState {
    currentUser: UserResource | null;
    currentAuthToken: string | null;
    isLocked: boolean;
    checkLockInterval: NodeJS.Timer | null;
    isFullRegistrationLoading: boolean;
    fullRegistrationLoadingStep: number;
    fullRegistrationException: any;
    numberFormats: NumberFormatLocaleResource[];
}

const initialState: RegularCustomerState = {
    currentUser: null,
    currentAuthToken: null,
    isLocked: false,
    checkLockInterval: null,
    isFullRegistrationLoading: false,
    fullRegistrationLoadingStep: 0,
    fullRegistrationException: null,
    numberFormats: [],
};

//REDUCERS
const reducers = {
    setCurrentUser: (state: RegularCustomerState, action: PayloadAction<UserResource>) => {
        state.currentUser = action.payload;
        if(state.currentUser?.languageCode) i18n.changeLanguage(state.currentUser.languageCode);
    },
    setCurrentAuthToken: (state: RegularCustomerState, action: PayloadAction<string>) => {
        state.currentAuthToken = action.payload;
    },
    setUserLock: (state: RegularCustomerState, action: PayloadAction<boolean>) => {
        state.isLocked = action.payload;
    },
    setCheckLockInterval: (state: RegularCustomerState, action: PayloadAction<NodeJS.Timer>) => {
        state.checkLockInterval = action.payload;
    },
    setFullRegistrationLoading: (state: RegularCustomerState, action: PayloadAction<boolean>) => {
        state.isFullRegistrationLoading = action.payload;
    },
    setFullRegistrationLoadingStep: (state: RegularCustomerState, action: PayloadAction<number>) => {
        state.fullRegistrationLoadingStep = action.payload;
    },
    setFullRegistrationException: (state: RegularCustomerState, action: PayloadAction<any>) => {
        state.fullRegistrationException = action.payload;
    },
    setNumberFormats(state: RegularCustomerState, action: PayloadAction<NumberFormatLocaleResource[]>) {
        state.numberFormats = action.payload;
    },
    setMergeTableMigrated: (state: RegularCustomerState, action: PayloadAction<any>) => {
        if(state.currentUser?.company) {
            state.currentUser.company.mergeTableMigrated = action.payload;
        }
    },
}

//EXPORTS
export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
