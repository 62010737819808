import { Box, Chip, Divider, Link, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import StraightenTwoToneIcon from '@mui/icons-material/StraightenTwoTone';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import FormatPaintTwoToneIcon from '@mui/icons-material/FormatPaintTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import FormatColorFillTwoToneIcon from '@mui/icons-material/FormatColorFillTwoTone';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import { CURRENCY_SETTING_NAME, NUMBER_FORMAT_SETTING_NAME, VARIABLE_VALUE_GEOMETRY_POWDER, VARIABLE_VALUE_GEOMETRY_SURFACE, VARIABLE_VALUE_GEOMETRY_VOLUME, VARIABLE_VALUE_GEOMETRY_WEIGHT } from 'src/statics/statics';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { CalculatedValues } from '../CalculatedValues';
import _ from 'lodash';
import { getGeneralSetting, getSections, renderParameters, Section } from 'src/utils/CalcHelpers';
import { FragmentSummary } from './FragmentSummary';
import { formatPrice, formatSurchargeValue } from 'src/utils/FormatHelpers';
import { useTranslation } from 'react-i18next';
import { CalculationResultResource, GeneralParameterSummaryResource, GeneralPartSummaryResource } from 'src/backend/market';
import OpenAttachment from 'src/utils/OpenAttachment';



const SurchargesBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        margin: ${theme.spacing(-0.4, -0.4)};

        .MuiChip-root {
            margin: ${theme.spacing(0.4, 0.4)};
        }
    `
);


const renderParameter = (name, value, key = null, unit = null) => {
    if(value === '' || value === '0') return <Fragment key={key}></Fragment>;

    return <Stack direction="row" key={key} sx={{
        fontSize: "0.929em",
        lineHeight: 1.4,
        '::after': {
            content: '"|"',
            margin: '0 5.4px'
        },
        ':last-child::after': {
            display: 'none'
        }
    }}>
        <Typography fontSize="1em" lineHeight={1.4} noWrap color="text.secondary" mr={0.4}>{name}:</Typography>
        <Typography fontSize="1em" lineHeight={1.4} noWrap fontWeight="bold">{value} {unit}</Typography>
    </Stack>
}

interface Props {
    part: GeneralPartSummaryResource;
    numberFormat?: string,
    currency?: string,
    showPriceRange?: boolean;
    hidePrice?: boolean;
    className?: string;
    costResult?: CalculationResultResource;
}

export const PartSummary: React.FC<Props> = ({ part, numberFormat, currency, showPriceRange, hidePrice, className, costResult }) => {
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();

    const name = part.userDefinedParameterName || wT(part.partSummary.title, s);
    
    const partCostResult = part.calculationResult
    
    const quantity = parseInt(part.partSummary.numberOfPieces);

    const calculatedValues = useMemo(() => {
        const result = partCostResult;
        const variableValues = result.variableValues || [];
        return {
            powder: result.totalAmountOfPowder || parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_POWDER)?.value),
            weight: result.totalWeight || parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_WEIGHT)?.value),
            surface: result.surface?.value || parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_SURFACE)?.value),
            volume: result.totalVolume || parseFloat(variableValues.find(value => value.name === VARIABLE_VALUE_GEOMETRY_VOLUME)?.value),
            jigAmount: result.totalJigAmount,
        }
    }, [partCostResult]);

    const hasValues = (parameters: GeneralParameterSummaryResource[]) => {
        return parameters.some(param => param.value !== '' && param.value !== '0');
    }
    const hasSurcharge = (partCostResult.surchargeReport || []).some(surcharge => surcharge.value != 0);
    
    const sections: Array<Section> = getSections(part.parameters, part.sectionDefinitions);
    

    const getPrice = (price) => formatPrice(price, false, numberFormat, currency);


    const getSectionIcon = (section) => {
        if(section.id === 'geometryPackageSection') return <StraightenTwoToneIcon fontSize="small"/>

        const sectionDefinition = (part.sectionDefinitions || []).find(definition => definition.dictId.slice(4) === section.id);
        if(!sectionDefinition) return <></>;
           
        return <>
            {sectionDefinition.image === 'StraightenTwoTone' && <StraightenTwoToneIcon fontSize="small"/>}
            {sectionDefinition.image === 'ViewInArTwoTone' && <ViewInArTwoToneIcon fontSize="small"/>}
            {sectionDefinition.image === 'FormatPaintTwoTone' && <FormatPaintTwoToneIcon fontSize="small"/>}
            {sectionDefinition.image === 'BallotTwoTone' && <BallotTwoToneIcon fontSize="small"/>}
            {sectionDefinition.image === 'FormatColorFillTwoTone' && <FormatColorFillTwoToneIcon fontSize="small"/>}
        </>;
    }
    
    const price = partCostResult.priceNonRounded || partCostResult.price;
    const minPrice = partCostResult.priceMinNonRounded || partCostResult.priceMin;
    const maxPrice = partCostResult.priceMaxNonRounded || partCostResult.priceMax;
    const hasPrice = showPriceRange ? !!maxPrice && !!minPrice : !!price;

    const pricePerUnit = showPriceRange
        ? <>{getPrice(minPrice / quantity)} - {getPrice(maxPrice / quantity)}</>
        : getPrice(price / quantity)

    const priceTotal = showPriceRange
        ? <>{getPrice(minPrice)} - {getPrice(maxPrice)}</>
        : getPrice(price)
    
    
    return <Stack mt={2} mb={3} className={className + " part-summary"}>
        <Stack className="part-summary-header" direction="row" columnGap={2} rowGap={1} alignItems="center" bgcolor={theme.colors.alpha.black[7]} px={1} borderRadius={2}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <img src={part.itemImageUri} width="55" height="55"/>
                <Typography variant="h6" fontSize={{ xs: '1em', md: '1.286em' }} maxWidth="25rem">{quantity} × {name}</Typography>
            </Stack>
            <Stack direction="row" columnGap={4} rowGap={2} justifyContent="flex-end" alignItems="center" flexGrow={1} flexWrap="wrap" maxWidth="100%" overflow="hidden">
                <Stack direction="row" columnGap={4} rowGap={2} flexWrap="wrap" maxWidth="100%" overflow="hidden" justifyContent="flex-end">
                    <CalculatedValues calculatedValues={calculatedValues} quantity={quantity} showIcon={false} costResult={costResult}/>
                </Stack>

                {(!hidePrice &&  hasPrice) &&
                    <Stack textAlign="right" width="10rem" whiteSpace="nowrap">
                        {quantity > 1
                        ? <>
                            <Typography variant="h6" fontWeight="bold" color="primary" lineHeight={1.4}>
                                {pricePerUnit} {t('pricePerUnit')}
                            </Typography>
                            <Typography variant="subtitle2" fontWeight="bold" lineHeight={1.4}>{priceTotal}</Typography>
                        </>
                        : <Typography variant="h6" fontWeight="bold" color="primary" lineHeight={1.4}>
                            {priceTotal}
                        </Typography>}
                    </Stack>
                }
            </Stack>
        </Stack>
        
        <Stack justifyContent="space-between" spacing={1} ml={1} mt={1} className="part-parameters">
            {sections.map(section => hasValues(section.groupedParameters) &&
                <Stack key={section.id} direction="row" alignItems="center" spacing={0.8}>
                    {getSectionIcon(section)}
                    <Stack direction="row" flexWrap="wrap">
                        {renderParameters(section.groupedParameters, null, partCostResult.guiStates, numberFormat, renderParameter)}
                    </Stack>
                </Stack>
            )}
            {part.attachments?.length > 0 &&
                <Stack direction="row" alignItems="center" spacing={0.8}>
                    <AttachFileTwoToneIcon fontSize="small"/>
                    <Stack direction="row" flexWrap="wrap">
                        <Typography fontSize="0.929em" lineHeight={1.4} color="text.secondary">{t('attachments')}:</Typography>
                        <Typography fontSize="0.929em" lineHeight={1.4}>
                            <Box display="flex" flexWrap="wrap">
                                {part.attachments.map((attachment, index) => <Box key={index} display="flex" ml={0.4}>
                                    <OpenAttachment attachment={attachment} displayElement={(openAttachment) =>
                                        <Link component="button" variant="body1" fontSize="1em" lineHeight={1.4} onClick={() => { openAttachment() } }>
                                            {attachment.originalFileName}
                                        </Link>
                                    }/>
                                    {part.attachments.length - 1 !== index && ', '}
                                </Box>)}
                            </Box>
                        </Typography>
                    </Stack>
                </Stack>
            }
        </Stack>

        {(part.fragments && part.fragments.length > 0) && <>
            <Divider sx={{ my: 1 }}/>
            <Stack spacing={1} ml={5}>
                {part.fragments.map((fragment, index) => 
                    <FragmentSummary key={fragment.id} last={index === part.fragments.length - 1} fragment={fragment} guiStates={partCostResult.guiStates} renderParameter={renderParameter}/>
                )}
            </Stack>
        </>}

        {hasSurcharge && <>
            <Divider sx={{ my: 1 }}/>
            <SurchargesBox>
                {partCostResult.surchargeReport.map((surcharge, index) => surcharge.value != 0 &&
                    <Chip
                        key={index}
                        label={<>
                            {surcharge.description} <span style={{ fontWeight: 'bold' }}>
                                {formatSurchargeValue(surcharge.value, surcharge.absoluteSurcharge, numberFormat, currency)}
                            </span>
                        </>}
                        sx={{ fontSize: "0.929em", height: 26, px: 0.2, backgroundColor: theme.colors.alpha.black[7] }}
                    />
                )}
            </SurchargesBox>
        </>}
    </Stack>
};
