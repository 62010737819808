import { UserResource } from "src/backend/market";

export const bitrixUrl = 'https://cdn.bitrix24.de/b16016209/crm/site_button/loader_3_13645e.js';
export const bitrixScriptTagName = 'bitrix-script-tag';

let widget = null;

export const initBitrix = (user: UserResource) => {
    // Bitrix Chat Widget
    const foundScript = document.querySelector('.' + bitrixScriptTagName);
    if(foundScript) return;
        
    const script = document.createElement('script');
    script.src = bitrixUrl + '?' + (Date.now()/60000|0);
    script.async = true;
    script.classList.add(bitrixScriptTagName);
    
    document.body.appendChild(script);

    const style = document.createElement('style');
    style.textContent = `
        .b24-widget-button-wrapper, .b24-widget-button-shadow, .bx-livechat-wrapper {
            display: none !important;
        }
    `;
    document.body.appendChild(style);

    const handleBitrixLiveChatEvent = (event) => {
        widget = event.detail.widget;
        const bx = (window as any).BX;
        bx.SiteButton.hide();

        widget.setUserRegisterData({
            hash: user.bitrixKey,
            name: user.firstName,
            lastName: user.lastName,
            email: user.email,
            www: window.location.origin,
        });    
        setWidgetData(widget, user);

        widget.subscribe({
            type: bx.LiveChatWidget.SubscriptionType.widgetOpen,
            callback() {
                if(document.body.contains(style)) document.body.removeChild(style);
            }
        });
        widget.subscribe({
            type: bx.LiveChatWidget.SubscriptionType.widgetClose,
            callback() {
                setTimeout(() => bx.SiteButton.hide(), 0);
            }
        });
    }

    window.addEventListener('onBitrixLiveChat', handleBitrixLiveChatEvent);

    window.addEventListener('b24:form:init', (event: any) => {
        const form = event.detail.object;
        form.setValues({ name: user.firstName, "last-name": user.lastName, email: user.email, phone: user.phoneNumber || '' })
    });
    window.addEventListener('b24:form:hide', () => {
        const bx = (window as any).BX;
        bx.SiteButton.hide();
    });
};

export const openChat = (user) => {
    setWidgetData(widget, user);
    widget.open();
}

export const openContactForm = () => {
    const bx = (window as any).BX;
    bx.LiveChat.closeLiveChat();
    const crmForm = (bx?.SiteButton?.buttons?.list || []).find(obj => obj.id === 'crmform');
    crmForm?.onclick();
}

const setWidgetData = (widget, user) => {
    widget.customData = [];
    widget.setCustomData([
        {
            USER: {
                NAME : user.fullName,
            }
        },
        {
            GRID: [
                {
                    NAME : 'Email',
                    VALUE : user.email,
                    DISPLAY : 'LINE',
                },
                {
                    NAME : 'Company',
                    VALUE: user.company?.name || 'Not set',
                    DISPLAY : 'LINE',
                },
                {
                    NAME : 'User ID',
                    VALUE: user.id,
                    DISPLAY : 'LINE',
                },
                {
                    NAME : 'User Phone Number',
                    VALUE: user.phoneNumber || 'Not set',
                    DISPLAY : 'LINE',
                },
                {
                    NAME : 'User Role',
                    VALUE: user?.userRoleResource?.roleName || 'Not set',
                    DISPLAY : 'LINE',
                },
                {
                    NAME : 'User Language',
                    VALUE: user.languageCode || 'Not set',
                    DISPLAY : 'LINE',
                },
                {
                    NAME: 'Website',
                    VALUE: window.location.hostname,
                    DISPLAY : 'LINE',
                },
                {
                    NAME: 'Page',
                    VALUE: '[url=' + window.location.href + ']' + (document.title || window.location.href) + '[/url]',
                    DISPLAY : 'LINE',
                },
            ]
        }
    ]);    
}