import {Add, DeleteTwoTone, DragHandleTwoTone, EditTwoTone} from "@mui/icons-material";
import {
    Box,
    Card,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import _ from "lodash";
import React, {createRef, useEffect} from "react"
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {GeneralMessageResource} from "src/backend/market";
import SurfLoader from "src/components/display/Loader/SurfLoader";
import GeneralMessageDialog, {
    GeneralMessageFormValues,
    GeneralMessagesDialogHandle
} from "src/components/overlays/GeneralMessageDialog/GeneralMessageDialog";
import useDialog from "src/hooks/useDialog";
import {useSelector} from "src/redux/store";
import {
    createMessage,
    deleteMessage,
    editMessage,
    loadGeneralMessages,
    reorderGeneralMessages
} from "src/redux/thunks/wizardProfile.thunk";

const GeneralMessages: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const dialogRef = createRef<GeneralMessagesDialogHandle>()
    const {generalMessages, generalMessagesLoading} = useSelector(state => state.wizardProfile)
    //region STATE
    //endregion

    //region LIFECYCLE
    useEffect(() => {
        if (generalMessages.length === 0 && generalMessagesLoading) {
            dispatch(loadGeneralMessages());
        }
    }, [])
    //endregion

    //region HELPER
    const onDrop = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        dispatch(reorderGeneralMessages(result.source.index, result.destination.index));
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        borderRadius: "10px",


        // change background colour if dragging
        background: isDragging ? "#f1f1f1" : "white",
        transition: "all .2s ease",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const onDialogFinish = (values: GeneralMessageFormValues, prevMessage: GeneralMessageResource) => {
        if (prevMessage) {
            dispatch(editMessage(prevMessage.id, values))
        } else {
            dispatch(createMessage(values))
        }
    }

    const handleCreateMessage = () => {
        dialogRef.current?.open()
    }


    function handleDeleteMessage(generalMessage: GeneralMessageResource) {
        dialog.openDialog({
            severity: "error",
            title: t('nachrichtLschen'),
            cancelText: t('abbrechen'),
            component: <Typography>{t('bistDuSicherDassDuDieseNachrichtLschenMchtest')}</Typography>,
            okText: t("lschen"),
            okCallback: () => {
                dispatch(deleteMessage(generalMessage))
                dialog.closeDialog();
            },
        })
    }

    //endregion


//region RENDER
    function renderItem(generalMessage: GeneralMessageResource, isDragging: boolean,) {
        return <ListItem
            sx={{backgroundColor: isDragging ? "#f4f4f4" : "white", borderRadius: 1, paddingRight: "100px"}}
            secondaryAction={

                <Stack direction={"row"} gap={1}>
                    <Tooltip title={t("nachrichtLöschen")}>
                    <IconButton color={"error"} onClick={() => handleDeleteMessage(generalMessage)}>
                        <DeleteTwoTone/>
                    </IconButton>
                    </Tooltip>
                    <Tooltip title={t("nachrichtBearbeiten")}>
                        <IconButton onClick={() => dialogRef.current.open(generalMessage)}>
                        <EditTwoTone/>
                    </IconButton>
                    </Tooltip>
                </Stack>}
        >
            <ListItemIcon>
                <Tooltip title={t("nachrichtVerschieben")}><DragHandleTwoTone/></Tooltip>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{style: {fontWeight: "bold"}}}
                          primary={generalMessage.heading}
                          secondary={_.truncate(generalMessage.content, {
                              length: 60,
                              separator: " "
                          })}></ListItemText>
        </ListItem>;
    }

    const renderGeneralMessage = (generalMessage: GeneralMessageResource, index) => {
        return <Draggable key={"draggable" + generalMessage.id} draggableId={generalMessage.id + ""} index={index}>
            {(provided, snapshot) =>
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    {renderItem(generalMessage, false)}
                </div>}
        </Draggable>
    }

    const renderList = () => {
        return (
            <DragDropContext onDragEnd={onDrop}>
                <Droppable droppableId={"generalDroppable"} renderClone={(provided, snapshot, rubric) => (
                    <div  {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}>
                        {renderItem(generalMessages[rubric.source.index], true)}
                    </div>
                )}>
                    {(provided, snapshot) => (
                        <div style={{overflow: "auto"}} ref={provided.innerRef} {...provided.droppableProps} >
                            {generalMessages.map((generalMessage, index) => {
                                return renderGeneralMessage(generalMessage, index)
                            })}
                            {provided.placeholder}
                        </div>
                    )}

                </Droppable>
            </DragDropContext>
        )
    }
    //endregion

    return (
        <>
            <GeneralMessageDialog finishedCallback={onDialogFinish} ref={dialogRef}/>
            <Card sx={{p: 2, maxHeight: 400}}>
                <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt' sx={{display: "flex", flexDirection: "column"}}>
                    <Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={"column"}>
                            <Typography
                                variant="caption"
                            >
                                {t('nachrichtenImWizardAnpassen')}
                            </Typography>
                            {t('allgemeineNachrichten')}
                        </Stack>
                        <Tooltip title={t("Nachricht hinzufügen")}>
                            <IconButton onClick={handleCreateMessage}>
                                <Add/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
                <Divider sx={{mt: 1, mb: 2}}/>
                {generalMessagesLoading ? <SurfLoader/> : renderList()}
            </Card>
        </>
    )
}
export default GeneralMessages
