import { Typography, Card, Stack, Divider, Button, Collapse, TextField, styled, Box } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import HeadlineButton from '../display/HeadlineButton/HeadlineButton';
import { ChangeEvent, useState } from 'react';
import { formatArea, formatValue } from 'src/utils/FormatHelpers';

const CardWrapper = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
        gap: ${theme.spacing(3)}
    `
);

const StyledBox = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: ${theme.spacing(4)}
    `
);

interface Props {
    isEditable?: boolean;
    plants?: Array<{ name: string; width: number; height: number; length: number }>;
    setPlants?: (newPlants: Array<{ name: string; width: number; height: number; length: number }>) => void;
    result: any;
}

const PriceFinderPlants: React.FC<Props> = ({ isEditable, plants = [], setPlants, result }) => {
    const [isOpen, setOpen] = useState<boolean>(true);

    const renderTextField = (plantIndex, label, key) => {
        return (
            <TextField
                size="small"
                label={label}
                value={plants[plantIndex]?.[key]}
                onChange={(event) => {
                    setPlantValue(plantIndex, key, parseFloat(event.target.value));
                }}
                onKeyDown={(event) => {
                    if (event.key === '.' || event.key === ',') event.preventDefault();
                }}
                name={key}
                variant="outlined"
                type="number"
                InputProps={{
                    endAdornment: 'mm'
                }}
            />
        );
    };

    const setPlantValue = (index: number, key: string, value: number) => {
        const localPlants = [...plants];
        localPlants[index][key] = value;
        setPlants(localPlants);
    };

    const addPlant = () => {
        const localPlants = [...plants];
        localPlants.push({
            name: 'Anlage ' + (plants.length + 1),
            length: 0,
            width: 0,
            height: 0
        });
        setPlants(localPlants);
    };
    const removePlant = (plantIndex: number) => {
        const localPlants = [...plants];
        localPlants.splice(plantIndex, 1);
        setPlants(localPlants);
    };

    return (
        <Stack>
            <HeadlineButton isOpen={isOpen} setOpen={setOpen} variant="page">
                Anlagen
            </HeadlineButton>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <CardWrapper mb={3}>
                    {plants.map((plant, index) => (
                        <Card sx={{ p: 3 }} key={plant.name + index}>
                            <Stack key={plant.name + index}>
                                <Stack direction="row" alignItems="center" mt={-0.5} mb={2}>
                                    <Typography variant="h4">{plant.name}</Typography>
                                    {index > 0 && index === plants.length - 1 && (
                                        <Button size="small" color="error" startIcon={<DeleteForeverTwoToneIcon />} sx={{ ml: 'auto', my: -1, mr: -1 }} onClick={() => removePlant(index)}>
                                            Löschen
                                        </Button>
                                    )}
                                </Stack>
                                <StyledBox>
                                    {renderTextField(index, 'Länge', 'length')}
                                    {renderTextField(index, 'Breite', 'width')}
                                    {renderTextField(index, 'Höhe', 'height')}
                                </StyledBox>
                                {result.plants[index]?.valid && (
                                    <Stack direction="row" gap={3} mt={2.5} mb={-0.5}>
                                        <Stack flexGrow={1}>
                                            <Typography fontSize={14} variant="subtitle2">
                                                Fläche
                                            </Typography>
                                            <Typography fontSize={15} fontWeight={600}>
                                                {formatArea(result.plants[index]?.area, 'mm2')}
                                            </Typography>
                                        </Stack>
                                        <Stack flexGrow={1}>
                                            <Typography fontSize={14} variant="subtitle2">
                                                Fläche / Stunde
                                            </Typography>
                                            <Typography fontSize={15} fontWeight={600}>
                                                {formatArea(result.plants[index]?.area, 'mm2')}
                                            </Typography>
                                        </Stack>
                                        <Stack flexGrow={1}>
                                            <Typography fontSize={14} variant="subtitle2">
                                                Fläche / Jahr
                                            </Typography>
                                            <Typography fontSize={15} fontWeight={600}>
                                                {formatArea(result.plants[index]?.area, 'mm2')}
                                            </Typography>
                                        </Stack>
                                        <Stack flexGrow={1}>
                                            <Typography fontSize={14} variant="subtitle2">
                                                {plant.name} liefert
                                            </Typography>
                                            <Typography fontSize={15} fontWeight={600}>
                                                {formatArea(result.plants[index]?.area, 'mm2')}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                )}
                            </Stack>
                        </Card>
                    ))}
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={addPlant}>
                        Anlage hinzufügen
                    </Button>
                </CardWrapper>
            </Collapse>
        </Stack>
    );
};

export default PriceFinderPlants;
