import { Dialog, DialogTitle, Stack, IconButton, DialogContent, useTheme, Divider } from '@mui/material';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useEffect, useState } from 'react';
import { FileResource } from 'src/backend/market';
import { loadFile } from 'src/redux/thunks/fileManager.thunk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { CalculationPartResource, CalculationResultResource } from 'src/backend/internalCalc';
import ItemSections from './ItemSections';
import ItemCalculatedValues from './ItemCalculatedValues';
import ItemQuantity from './ItemQuantity';
import { wT } from 'src/utils/wizardTranslations';
import store from 'src/redux/store';

interface Props {
    part: CalculationPartResource;
    partCostResult?: CalculationResultResource;
    costResult?: CalculationResultResource;
    isPdfViewerOpen: boolean;
    quantity?: number;
    hasPrevious?: boolean;
    hasNext?: boolean;
    selectPrevious?: () => void;
    selectNext?: () => void;
    closePdfViewer?: () => void;
}

const ItemPdfViewerDialog: React.FC<Props> = ({ part, partCostResult, costResult, isPdfViewerOpen, quantity, hasPrevious, hasNext, selectPrevious, selectNext, closePdfViewer }) => {
    const dispatch = useDispatch();
    const s = store.getState();
    const theme = useTheme();
    const { t } = useTranslation();
    const [pdfPath, setPdfPath] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!part.pdfFileId || !isPdfViewerOpen) return;
        setLoading(true);

        dispatch(
            loadFile(part.pdfFileId, (file: FileResource) => {
                setFileName(file.name);
                setPdfPath(file.url);
                setLoading(false);
            })
        );
    }, [part.pdfFileId, isPdfViewerOpen]);

    return (
        <Dialog onClose={closePdfViewer} open={isPdfViewerOpen} maxWidth="xl" PaperProps={{ sx: { width: '100%', height: '100%', maxWidth: 'none' } }}>
            <DialogTitle sx={{ pr: 7 }}>
                <Stack direction="row" gap={2} my={-0.6} alignItems="center">
                    <ItemQuantity partId={part.id} itemId={part.itemId} parameters={part?.calculationParameters?.allParameters} setParameters={part?.setParameters} />
                    {part.userDefinedPartName || wT(part.itemName, s)}
                </Stack>
                <IconButton
                    color="primary"
                    aria-label="close"
                    onClick={closePdfViewer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        background: theme.colors.primary.main,
                        color: theme.colors.alpha.white[100],
                        ':hover': {
                            background: theme.colors.primary.dark
                        }
                    }}
                >
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: '20rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Stack direction="row" height="100%" flexGrow={1} sx={{ mr: -3 }}>
                    {pdfPath && !isLoading ? (
                        <object data={pdfPath + '#view=fit'} type="application/pdf" width="100%" height="100%">
                            <a href={pdfPath}>{pdfPath}</a>
                        </object>
                    ) : (
                        <Stack alignItems="center" justifyContent="center" height="100%" flexGrow={1} bgcolor={theme.colors.alpha.black[7]}>
                            <SurfLoader />
                        </Stack>
                    )}
                    <Stack
                        sx={{
                            width: '35%',
                            flexShrink: 0,
                            '.item-calculated-values': { width: '100%', maxWidth: '100%' }
                        }}
                    >
                        <Divider />
                        <ItemSections part={part} partCostResult={partCostResult} />
                        <Divider sx={{ mt: 'auto' }} />
                        <ItemCalculatedValues costResult={costResult} part={part} partCostResult={partCostResult} quantity={quantity} />
                    </Stack>
                </Stack>
                {hasPrevious && (
                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            alignSelf: 'center',
                            borderRadius: 8,
                            background: theme.colors.alpha.white[100]
                        }}
                    >
                        <IconButton color="primary" onClick={selectPrevious}>
                            <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 32 }} />
                        </IconButton>
                    </div>
                )}
                {hasNext && (
                    <IconButton color="primary" onClick={selectNext} sx={{ position: 'absolute', right: 0, alignSelf: 'center' }}>
                        <ArrowForwardIosTwoToneIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ItemPdfViewerDialog;
