/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InquiryFilterDto } from '../models/InquiryFilterDto';
import type { InquiryPageResultResource } from '../models/InquiryPageResultResource';
import type { InquirySeenStateDto } from '../models/InquirySeenStateDto';
import type { InquiryStateDto } from '../models/InquiryStateDto';
import type { InquiryStateResource } from '../models/InquiryStateResource';
import type { UsedItemTypesResource } from '../models/UsedItemTypesResource';
import type { WizardInquiryFilterBoundariesResource } from '../models/WizardInquiryFilterBoundariesResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InquiryRestControllerService {

    /**
     * @param userId
     * @param inquiryId
     * @returns any OK
     * @throws ApiError
     */
    public static resetInquiryForTest(
        userId: number,
        inquiryId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/inquiries/${userId}/${inquiryId}/testResets`,
        });
    }

    /**
     * @param inquiryId
     * @returns InquiryStateResource OK
     * @throws ApiError
     */
    public static getInquiryState(
        inquiryId: string,
    ): CancelablePromise<InquiryStateResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/inquiries/${inquiryId}/state`,
        });
    }

    /**
     * @param inquiryId
     * @param requestBody
     * @returns InquiryStateResource OK
     * @throws ApiError
     */
    public static setInquiryState(
        inquiryId: string,
        requestBody: InquiryStateDto,
    ): CancelablePromise<InquiryStateResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/inquiries/${inquiryId}/state`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param inquiryId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setInquirySeenState(
        inquiryId: string,
        requestBody: InquirySeenStateDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/inquiries/${inquiryId}/seen`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param page
     * @param size
     * @param requestBody
     * @param sortBy
     * @returns InquiryPageResultResource OK
     * @throws ApiError
     */
    public static getAllInquiriesOfUser(
        userId: number,
        page: number,
        size: number,
        requestBody: InquiryFilterDto,
        sortBy: string = 'dateDesc',
    ): CancelablePromise<InquiryPageResultResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/inquiries/${userId}/filter`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param page
     * @param size
     * @param sortBy
     * @returns InquiryPageResultResource OK
     * @throws ApiError
     */
    public static getAllInquiriesOfUser1(
        userId: number,
        page: number,
        size: number,
        sortBy: string = 'dateDesc',
    ): CancelablePromise<InquiryPageResultResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/inquiries/${userId}`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * @param userId
     * @returns UsedItemTypesResource OK
     * @throws ApiError
     */
    public static getUsedItemTypes(
        userId: number,
    ): CancelablePromise<UsedItemTypesResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/inquiries/${userId}/usedItemTypes`,
        });
    }

    /**
     * @param userId
     * @returns WizardInquiryFilterBoundariesResource OK
     * @throws ApiError
     */
    public static getInquiryFilterBoundaries(
        userId: number,
    ): CancelablePromise<WizardInquiryFilterBoundariesResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/inquiries/${userId}/filter/boundaries`,
        });
    }

    /**
     * @returns InquiryStateResource OK
     * @throws ApiError
     */
    public static getAllInquiryStates(): CancelablePromise<Array<InquiryStateResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/inquiries/states`,
        });
    }

    /**
     * @param wizardProfileKey
     * @param page
     * @param size
     * @param sortBy
     * @returns InquiryPageResultResource OK
     * @throws ApiError
     */
    public static getAllInquiriesByWizardProfileKey(
        wizardProfileKey: string,
        page: number,
        size: number,
        sortBy: string = 'dateDesc',
    ): CancelablePromise<InquiryPageResultResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/inquiries/profiles/${wizardProfileKey}`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * @param userId
     * @param inquiryId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteInquiry(
        userId: number,
        inquiryId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/inquiries/${userId}/${inquiryId}`,
        });
    }

}