import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";
import { WizardCalcColorResource } from "src/backend/market";
import store from "src/redux/store";
import { RAL_GUI } from "src/statics/statics";
import { getColors } from "src/utils/FormatHelpers";
import { getHex } from "src/utils/RalConverter";
import { wT } from "src/utils/wizardTranslations";

interface Props {
    colors: Array<WizardCalcColorResource>;
}

const ColorDrops: React.FC<Props> = ({ colors }) => {
    const theme = useTheme();
    const s = store.getState();
    
    return <Stack direction="row" spacing={-0.6}>
        {(colors || []).map((color, index) => {
            let hex = null;

            if(color.colorSystem === RAL_GUI) {
                hex = getHex(color.colorName);
            }

            return <Tooltip key={index} title={`${wT(color.colorName, s)} (${wT(color.colorSystem, s)})`}>
                {hex
                    ? <Box sx={{ bgcolor: hex, width: '1.3rem', height: '1.3rem', borderRadius: '50%', border: '1px solid #d8d8d8' }}></Box>
                    : <Box sx={{
                        color: 'white',
                        bgcolor: theme.colors.secondary.light,
                        width: '1.3rem',
                        height: '1.3rem',
                        borderRadius: '50%',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        border: '1px solid #d8d8d8',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        ?
                    </Box>}
            </Tooltip>
        })}
    </Stack>
}

export default ColorDrops;