import { Dialog, DialogTitle, Stack, Typography, CircularProgress, IconButton, DialogContent, useTheme } from '@mui/material';
import { SSA3DViewer } from '@surface-solutions/ssa-3d-viewer';
import { renderCalculatedValue } from 'src/components/display/CalculatedValues';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import i18n from 'src/i18n/i18n';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LooksTwoTwoToneIcon from '@mui/icons-material/LooksTwoTwoTone';
import Looks3TwoToneIcon from '@mui/icons-material/Looks3TwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import { ModelStats, NumberWithUnit } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';
import { useEffect, useState } from 'react';
import { FileResource } from 'src/backend/market';
import { loadFile } from 'src/redux/thunks/fileManager.thunk';
import { useDispatch } from 'react-redux';
import { formatValue } from 'src/utils/FormatHelpers';
import { useTranslation } from 'react-i18next';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';

interface Props {
    is3dViewer: boolean;
    threeDFileId: string;
    hasPrevious?: boolean;
    hasNext?: boolean;
    selectPrevious?: () => void;
    selectNext?: () => void;
    close3dViewer?: () => void;
}

const Item3dViewerDialog: React.FC<Props> = ({ is3dViewer, threeDFileId, hasPrevious, hasNext, selectPrevious, selectNext, close3dViewer }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [pathOf3DViewer, setPathOf3DViewer] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [modelStats, setModelStats] = useState<ModelStats>();

    useEffect(() => {
        if (!threeDFileId) return;
        setModelStats(null);
        dispatch(
            loadFile(threeDFileId, (file: FileResource) => {
                setFileName(file.name);
                setPathOf3DViewer(file.url);
            })
        );
    }, [threeDFileId]);

    const getValue = (numberWithUnit: NumberWithUnit, showUnit: boolean = true) => {
        if (!showUnit) return formatValue(numberWithUnit.value, i18n.language, numberWithUnit.value < 1 ? 6 : 2);
        return formatValue(numberWithUnit.value, i18n.language, numberWithUnit.value < 1 ? 6 : 2) + ' ' + numberWithUnit.unit;
    };

    const handleClose = () => {
        setModelStats(null);
        close3dViewer();
    };

    return (
        <Dialog onClose={handleClose} open={is3dViewer} maxWidth="xl" PaperProps={{ sx: { width: '100%', height: '100%' } }}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', pr: 1.2, height: 56 }}>
                <Stack direction="row" alignItems="center" flexGrow={1}>
                    <Typography variant="h4">{t('3dViewer')}</Typography>
                    <Stack direction="row" justifyContent="center" spacing={2} flexGrow={1}>
                        <Stack direction="row" spacing={2} p={0.6} alignItems="center" borderRadius={1} border="1px solid" borderColor={theme.colors.alpha.black[10]}>
                            <Typography variant="caption">{t('object')}: </Typography>
                            {modelStats ? (
                                <Stack direction="row" spacing={2}>
                                    {modelStats?.surfaceArea && renderCalculatedValue(<LooksTwoTwoToneIcon />, t('surfaceArea'), getValue(modelStats.surfaceArea))}
                                    {modelStats?.volume && renderCalculatedValue(<Looks3TwoToneIcon />, t('volume'), getValue(modelStats.volume))}
                                </Stack>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </Stack>
                        <Stack direction="row" spacing={2} p={0.6} alignItems="center" borderRadius={1} border="1px solid" borderColor={theme.colors.alpha.black[10]}>
                            <Typography variant="caption">{t('boundingBox')}: </Typography>
                            {modelStats ? (
                                <Stack direction="row" spacing={2}>
                                    {modelStats?.boundingBox?.surfaceArea && renderCalculatedValue(<LooksTwoTwoToneIcon />, t('surfaceArea'), getValue(modelStats.boundingBox.surfaceArea))}
                                    {modelStats?.boundingBox &&
                                        renderCalculatedValue(
                                            <AssessmentTwoToneIcon />,
                                            t('lengthWidthHeight'),
                                            `${getValue(modelStats.boundingBox.length, false)} × ` +
                                                `${getValue(modelStats.boundingBox.width, false)} × ` +
                                                `${getValue(modelStats.boundingBox.height, false)} ${modelStats.boundingBox.length.unit}`
                                        )}
                                </Stack>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </Stack>
                        <Stack direction="row" spacing={2} p={0.6} alignItems="center" borderRadius={1} border="1px solid" borderColor={theme.colors.alpha.black[10]}>
                            <Typography variant="caption">{t('convexHull')}: </Typography>
                            {modelStats ? (
                                <Stack direction="row" spacing={2}>
                                    {modelStats?.convexHull?.surfaceArea && renderCalculatedValue(<LooksTwoTwoToneIcon />, t('surfaceArea'), getValue(modelStats.convexHull.surfaceArea))}
                                </Stack>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </Stack>
                    </Stack>
                    <IconButton aria-label="close" onClick={close3dViewer} sx={{ mt: -0.28, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ p: 0, position: 'relative', display: 'flex' }}>
                {pathOf3DViewer ? (
                    <SSA3DViewer url={pathOf3DViewer} fileName={fileName} language={i18n.language} onViewerLoaded={(stats) => setModelStats(stats)} />
                ) : (
                    <Stack alignItems="center" justifyContent="center" height="100%">
                        <SurfLoader />
                    </Stack>
                )}
                {hasPrevious && (
                    <IconButton color="primary" onClick={selectPrevious} sx={{ position: 'absolute', left: 0, alignSelf: 'center' }}>
                        <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                )}
                {hasNext && (
                    <IconButton color="primary" onClick={selectNext} sx={{ position: 'absolute', right: 0, alignSelf: 'center' }}>
                        <ArrowForwardIosTwoToneIcon sx={{ fontSize: 32 }} />
                    </IconButton>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default Item3dViewerDialog;
