import {
    AdditionalStorageResource,
    CalculationParameterResource,
    CalculationParametersResource,
    CalculationResultResource,
    MetadataResource,
    SectionDefinitionResource
} from 'src/backend/internalCalc';
import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import { AppDispatch, AppThunk, RootState } from '../store';
import { Selector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAllMessages } from 'src/utils/CalcHelpers';
import {
    CalcEditorSavingStatus,
    CalcEditorUpdateLoading,
    addCategoryCalculationBasis,
    addCategoryVariable,
    addMasterVariable,
    addTmpCategory,
    deleteCategory,
    deleteCategoryVariable,
    deleteMasterVariable,
    handleUpdateFailure,
    handleUpdateSuccess,
    saveBlueprintRecord,
    setBlueprintChanged,
    setBlueprintValue,
    setCategoryCalculationBasis,
    setCategoryCalculationBasisSelection,
    setCategoryVariable,
    setMasterVariable,
    setSavingStatus,
    setUpdateLoading
} from '../slices/calcEditor.reducer';
import {
    createCategory,
    createCategoryCalculationBasis,
    createCategoryVariable,
    createMasterVariable,
    duplicateCategory,
    removeCategory,
    removeCategoryVariable,
    removeMasterVariable,
    updateCategoryCalculationBasis,
    updateCategoryCalculationBasisSelection,
    updateCategoryVariable,
    updateMasterCalculationBasis,
    updateMasterVariable
} from '../thunks/calcEditor.thunk';
import { CalculationBasisResource, CategoryDTO, CategoryResource, CategoryTemplateResource, NewCategoryDTO, VariableResource } from 'src/backend/coreCalc';

export enum BlueprintUpdateTypes {
    NAME = 'name',
    MASTER_CALCULATION_BASIS = 'masterCalculationBasis',
    MASTER_VARIABLE = 'masterVariable',
    CREATE_MASTER_VARIABLE = 'createMasterVariable',
    DELETE_MASTER_VARIABLE = 'deleteMasterVariable',
    CREATE_CATEGORY = 'createCategory',
    DUPLICATE_CATEGORY = 'duplicateCategory',
    DELETE_CATEGORY = 'deleteCategory',
    CREATE_CATEGORY_CALCULATION_BASIS = 'createCategoryCalculationBasis',
    CATEGORY_CALCULATION_BASIS = 'categoryCalculationBasis',
    CATEGORY_CALCULATION_BASIS_SELECTION = 'categoryCalculationBasisSelection',
    CATEGORY_VARIABLE = 'categoryVariable',
    CREATE_CATEGORY_VARIABLE = 'createCategoryVariable',
    DELETE_CATEGORY_VARIABLE = 'deleteCategoryVariable'
}

type BlueprintUpdateValueMap = {
    [BlueprintUpdateTypes.NAME]: string;
    [BlueprintUpdateTypes.MASTER_CALCULATION_BASIS]: CalculationBasisResource;
    [BlueprintUpdateTypes.MASTER_VARIABLE]: VariableResource;
    [BlueprintUpdateTypes.CREATE_MASTER_VARIABLE]: VariableResource;
    [BlueprintUpdateTypes.DELETE_MASTER_VARIABLE]: number;
    [BlueprintUpdateTypes.CREATE_CATEGORY]: CategoryTemplateResource;
    [BlueprintUpdateTypes.DUPLICATE_CATEGORY]: number;
    [BlueprintUpdateTypes.DELETE_CATEGORY]: number;
    [BlueprintUpdateTypes.CREATE_CATEGORY_CALCULATION_BASIS]: { categoryId: number; calculationBasis: CalculationBasisResource };
    [BlueprintUpdateTypes.CATEGORY_CALCULATION_BASIS]: { categoryId: number; calculationBasis: CalculationBasisResource };
    [BlueprintUpdateTypes.CATEGORY_CALCULATION_BASIS_SELECTION]: { categoryId: number; calculationBasisId: number };
    [BlueprintUpdateTypes.CATEGORY_VARIABLE]: { categoryId: number; variable: VariableResource };
    [BlueprintUpdateTypes.CREATE_CATEGORY_VARIABLE]: { categoryId: number; variable: VariableResource };
    [BlueprintUpdateTypes.DELETE_CATEGORY_VARIABLE]: { categoryId: number; variableId: number };
};

interface BlueprintUpdateInfo<T extends BlueprintUpdateTypes> {
    optimisticUpdateFunction: (value: BlueprintUpdateValueMap[T]) => void;
    updateFunction: (blueprint: any, value: BlueprintUpdateValueMap[T]) => void;
    debounce?: boolean;
}
export const BlueprintUpdateInfos: Record<BlueprintUpdateTypes, BlueprintUpdateInfo<BlueprintUpdateTypes>> = {
    [BlueprintUpdateTypes.NAME]: {
        optimisticUpdateFunction: (value) => setBlueprintValue({ path: 'name', value }),
        updateFunction: () => {},
        debounce: false
    },
    [BlueprintUpdateTypes.MASTER_CALCULATION_BASIS]: {
        optimisticUpdateFunction: (value) => setBlueprintValue({ path: 'calculationBasis', value }),
        updateFunction: updateMasterCalculationBasis
    },
    [BlueprintUpdateTypes.MASTER_VARIABLE]: {
        optimisticUpdateFunction: setMasterVariable,
        updateFunction: updateMasterVariable,
        debounce: false
    },
    [BlueprintUpdateTypes.CREATE_MASTER_VARIABLE]: {
        optimisticUpdateFunction: addMasterVariable,
        updateFunction: createMasterVariable,
        debounce: false
    },
    [BlueprintUpdateTypes.DELETE_MASTER_VARIABLE]: {
        optimisticUpdateFunction: deleteMasterVariable,
        updateFunction: removeMasterVariable,
        debounce: false
    },
    [BlueprintUpdateTypes.CREATE_CATEGORY]: {
        optimisticUpdateFunction: addTmpCategory,
        updateFunction: createCategory
    },
    [BlueprintUpdateTypes.DUPLICATE_CATEGORY]: {
        optimisticUpdateFunction: addTmpCategory,
        updateFunction: duplicateCategory
    },
    [BlueprintUpdateTypes.DELETE_CATEGORY]: {
        optimisticUpdateFunction: deleteCategory,
        updateFunction: removeCategory
    },
    [BlueprintUpdateTypes.CREATE_CATEGORY_CALCULATION_BASIS]: {
        optimisticUpdateFunction: addCategoryCalculationBasis,
        updateFunction: createCategoryCalculationBasis
    },
    [BlueprintUpdateTypes.CATEGORY_CALCULATION_BASIS]: {
        optimisticUpdateFunction: setCategoryCalculationBasis,
        updateFunction: updateCategoryCalculationBasis
    },
    [BlueprintUpdateTypes.CATEGORY_CALCULATION_BASIS_SELECTION]: {
        optimisticUpdateFunction: setCategoryCalculationBasisSelection,
        updateFunction: updateCategoryCalculationBasisSelection
    },
    [BlueprintUpdateTypes.CATEGORY_VARIABLE]: {
        optimisticUpdateFunction: setCategoryVariable,
        updateFunction: updateCategoryVariable
    },
    [BlueprintUpdateTypes.CREATE_CATEGORY_VARIABLE]: {
        optimisticUpdateFunction: addCategoryVariable,
        updateFunction: createCategoryVariable
    },
    [BlueprintUpdateTypes.DELETE_CATEGORY_VARIABLE]: {
        optimisticUpdateFunction: deleteCategoryVariable,
        updateFunction: removeCategoryVariable
    }
};

/*
####################
##  UPDATE LOGIC  ##
####################
*/
type QueueElement = {
    updateType: BlueprintUpdateTypes;
    value: any;
};
type DebouncedUpdate = {
    [updateType in BlueprintUpdateTypes]?: { debounceFunction: DebouncedFunc<() => void>; value: any };
};

let updatesQueue: Array<QueueElement> = [];
let debouncedUpdates: DebouncedUpdate = {};
let isProcessingQueue = false;

const debounceUpdate = (updateType: BlueprintUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number = 500) => {
    // If there's no debounced function for this updateType, create one
    if (!debouncedUpdates[updateType]) {
        debouncedUpdates[updateType] = createDebounceUpdate(updateType, value, dispatch, getState, delay);
    }
    // Update the value for this updateType and invoke the debounced function
    debouncedUpdates[updateType].value = value;
    debouncedUpdates[updateType].debounceFunction();
};

const createDebounceUpdate = (updateType: BlueprintUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number) => {
    const debounceFunction = debounce(async () => {
        if (!debouncedUpdates[updateType]) return;
        // Value can not be used, debouncedUpdates[updateType].value assures that we always get the most recent value
        await enqueueAndProcessUpdate(updateType, debouncedUpdates[updateType].value, dispatch, getState);
        delete debouncedUpdates[updateType];
    }, delay);
    return { debounceFunction, value };
};

const enqueueAndProcessUpdate = async (updateType: BlueprintUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState) => {
    // Add the update to the queue
    updatesQueue.push({ updateType, value });
    if (!isProcessingQueue) {
        isProcessingQueue = true;
        await processQueue(dispatch, getState);
        isProcessingQueue = false;
    }
};

const processQueue = async (dispatch: AppDispatch, getState: () => RootState) => {
    // Process each item in the queue sequentially
    while (updatesQueue.length > 0) {
        const { updateType, value } = updatesQueue.shift()!;
        await processUpdate(dispatch, getState, updateType, value);
    }
};

const processUpdate = async (dispatch: AppDispatch, getState: () => RootState, updateType: BlueprintUpdateTypes, value: any) => {
    try {
        const blueprint = cloneDeep(getState().calcEditor.blueprint);
        const updateFunction = BlueprintUpdateInfos[updateType].updateFunction;
        await dispatch(updateFunction(blueprint, value));
        dispatch(handleUpdateSuccess(updateType));
    } catch (error) {
        dispatch(handleUpdateFailure({ updateType, error }));
    }
};

const optimisticUpdate = <T extends BlueprintUpdateTypes>(dispatch: AppDispatch, updateType: T, value: BlueprintUpdateValueMap[T]) => {
    dispatch(saveBlueprintRecord());
    dispatch(setBlueprintChanged(true));
    const optimisticUpdateFunction = BlueprintUpdateInfos[updateType].optimisticUpdateFunction;
    dispatch(optimisticUpdateFunction(value));
};

export const updateBlueprint = <T extends BlueprintUpdateTypes>(updateType: T, value: BlueprintUpdateValueMap[T]): AppThunk => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setSavingStatus(CalcEditorSavingStatus.LOADING));
        dispatch(setUpdateLoading({ updateType, isLoading: true }));

        // Perform the optimistic update for immediate UI feedback
        optimisticUpdate(dispatch, updateType, value);

        if (BlueprintUpdateInfos[updateType]?.debounce === false) {
            enqueueAndProcessUpdate(updateType, value, dispatch, getState); // don't drop an update
        } else {
            // Debounce the update to prevent rapid successive updates of the same type
            debounceUpdate(updateType, value, dispatch, getState);
        }
    };
};

export const cancelBlueprintUpdates = (): AppThunk => {
    return () => {
        // Clear all debounced updates
        Object.keys(debouncedUpdates).forEach((updateType: BlueprintUpdateTypes) => {
            if (debouncedUpdates[updateType] && debouncedUpdates[updateType].debounceFunction.cancel) {
                debouncedUpdates[updateType].debounceFunction.cancel();
            }
        });
        debouncedUpdates = {}; // Reset the debouncedUpdates object

        // Clear the updates queue
        updatesQueue = [];

        // Reset the queue processing flag
        isProcessingQueue = false;
    };
};

/*
#################
##  SELECTORS  ##
#################
*/

const selectCostResult: Selector<RootState, CalculationResultResource> = (state) => state.calcEditor.blueprint?.costResult || {};
export const selectBlueprintMessages = createSelector([selectCostResult], (costResult) => {
    const allMessages = getAllMessages(costResult);
    return {
        all: allMessages,
        hints: allMessages.filter((message) => message.messageType === 'HINT'),
        warnings: allMessages.filter((message) => message.messageType === 'WARNING'),
        errors: allMessages.filter((message) => message.messageType === 'ERROR' || (message.messageType.startsWith('INVALID') && message.messageType !== 'INVALID_PRICE_ERROR'))
    };
});

const selectIsUpdateLoading: Selector<RootState, CalcEditorUpdateLoading> = (state) => state.calcEditor.isUpdateLoading || {};
export const selectBlueprintPriceLoading = createSelector([selectIsUpdateLoading], (isUpdateLoading) => {
    if (isUpdateLoading[BlueprintUpdateTypes.CREATE_CATEGORY] || isUpdateLoading[BlueprintUpdateTypes.DUPLICATE_CATEGORY] || isUpdateLoading[BlueprintUpdateTypes.DELETE_CATEGORY]) return true;
    return false;
});
