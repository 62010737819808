import {Button, InputAdornment, InputBase, Popover, Stack, styled, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme} from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { LinearProgressWithLabel } from "src/components/display/LinearProgressWithLabel";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { LocalizationProvider, StaticDatePicker  } from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useFormikContext } from "formik";
import store from "src/redux/store";
import { BRING_DATE, DELIVERY_DATE } from "src/statics/statics";
import {wT} from "src/utils/wizardTranslations";
import { DateTime } from "luxon";

interface Props {
}

const MasterDeliveryAndPickup: React.FC<Props> = ({  }) => {
    const {values, setFieldValue} = useFormikContext();
    const { t } = useTranslation();
    const theme = useTheme();
    const bringRef = useRef<any>(null);
    const pickupRef = useRef<any>(null);
    const [isBringOpen, setBringOpen] = useState<boolean>(false);
    const [isPickupOpen, setPickupOpen] = useState<boolean>(false);

    const onChangeDate = (newDate: DateTime, paramName: string) => {
        const daysFromNow = Math.ceil(newDate.diffNow('days').days);
        setFieldValue(paramName, _.isNaN(daysFromNow) ? 0 : daysFromNow);
        if(paramName === BRING_DATE) setBringOpen(false);
        else if(paramName === DELIVERY_DATE) setPickupOpen(false);
    }

    const getDateFromDays = (days: number) => {
        return DateTime.now().plus({ days });
    }
    
    const throughputDays = values[DELIVERY_DATE] - values[BRING_DATE];

    const hoursSinceBringDate = useMemo(() => {
        if(values[BRING_DATE] == null) return 0;
        return getDateFromDays(values[BRING_DATE]).diffNow().as('hours');
    }, [values[BRING_DATE]]);

    const hoursBeforePickupDate = useMemo(() => {
        if(values[DELIVERY_DATE] == null) return 0;
        return getDateFromDays(values[DELIVERY_DATE]).diffNow().as('hours');
    }, [values[DELIVERY_DATE]]);


    const sumHours = Math.abs(hoursSinceBringDate) + Math.abs(hoursBeforePickupDate);
    const deliveryPercentage = hoursSinceBringDate > 0 ? 0 : hoursBeforePickupDate <= 0 ? 100 : (100 * Math.abs(hoursSinceBringDate)) / sumHours;

    return <Stack flexGrow={1}>
        <Stack direction="row" gap={2} justifyContent="space-between" mb={0.2}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Button
                    ref={bringRef}
                    color="inherit"
                    sx={{ flexDirection: 'column', p: 0.4, m: -0.4, textAlign: 'left', alignItems: 'flex-start' }}
                    onClick={() => setBringOpen(true)}
                >
                    <Typography fontSize="0.929em" lineHeight={1.2} noWrap color="text.secondary">{t('bereitstellung')}</Typography>
                    <Typography fontSize="1em" lineHeight={1.4} noWrap fontWeight="bold" display="flex" alignItems="center">
                        {getDateFromDays(values[BRING_DATE]).toLocaleString(DateTime.DATE_SHORT)}
                        <EditTwoToneIcon sx={{ color: theme.colors.primary.main, fontSize: 15, ml: 0.4 }} />
                    </Typography>
                </Button>
                <Popover
                    anchorEl={bringRef.current}
                    onClose={() => setBringOpen(false)}
                    open={isBringOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <StaticDatePicker
                        onChange={(newDate) => onChangeDate(newDate, BRING_DATE)}
                        maxDate={getDateFromDays(values[DELIVERY_DATE])}
                        slots={{ actionBar: () => <></>, toolbar: () => <></> }}
                    />
                </Popover>
                

                <Stack justifyContent="flex-end" alignItems="center">
                    <Typography fontSize="1em" lineHeight={1.4} noWrap color="text.secondary">
                        ⇠ {throughputDays || ''} {t(throughputDays === 0 ? 'sameDay' : throughputDays > 1 ? 'days' : 'day')} ⇢
                    </Typography>
                </Stack>

                <Button
                    ref={pickupRef}
                    color="inherit"
                    sx={{ flexDirection: 'column', p: 0.4, m: -0.4, textAlign: 'right', alignItems: 'flex-end' }}
                    onClick={() => setPickupOpen(true)}
                >
                    <Typography fontSize="0.929em" lineHeight={1.2} noWrap color="text.secondary">{t('abholung')}</Typography>
                    <Typography fontSize="1em" lineHeight={1.4} noWrap fontWeight="bold" display="flex" alignItems="center">
                        {getDateFromDays(values[DELIVERY_DATE]).toLocaleString(DateTime.DATE_SHORT)}
                        <EditTwoToneIcon sx={{ color: theme.colors.primary.main, fontSize: 15, ml: 0.4 }} />
                    </Typography>
                </Button>
                <Popover
                    anchorEl={pickupRef.current}
                    onClose={() => setPickupOpen(false)}
                    open={isPickupOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <StaticDatePicker
                        onChange={(newDate) => onChangeDate(newDate, DELIVERY_DATE)}
                        minDate={getDateFromDays(values[BRING_DATE])}
                        slots={{ actionBar: () => <></>, toolbar: () => <></> }}
                    />
                </Popover>
            </LocalizationProvider>
        </Stack>
        <LinearProgressWithLabel value={deliveryPercentage} className="delivery-percentage"/>
    </Stack>
}

export default MasterDeliveryAndPickup;