import { alpha, Box, Button, Chip, CircularProgress, Grid, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { IWizardStati } from '../../../redux/slices/wizardStati.reducer';
import { RootState } from '../../../redux/store';
import { appear } from '../../../utils/animationstyles';

export const ActiveChip = styled(Chip)(({ theme }) => ({
    background: alpha(theme.palette.success.main, 0.2),
    fontWeight: theme.typography.fontWeightBold,
    borderWidth: 2
}));

export const InactiveChip = styled(Chip)(({ theme }) => ({
    background: alpha(theme.palette.error.main, 0.3),
    fontWeight: theme.typography.fontWeightBold,
    borderWidth: 2
}));
export const LoadingChip = styled(Chip)(({ theme }) => ({
    background: alpha(theme.palette.grey['300'], 0.3),
    fontWeight: theme.typography.fontWeightBold,
    borderWidth: 2,
    display: 'flex',
    alignItems: 'center',
    '> *': {
        marginTop: 5
    }
}));

function WizardStatus({ loading, stati }) {
    const wizardStati = stati as IWizardStati;
    const navigate = useNavigate();
    const { t } = useTranslation();

    function renderChipForStatus(param: boolean) {
        if (loading) {
            return <LoadingChip variant={'outlined'} label={<CircularProgress color={'inherit'} size={23} />} />;
        } else if (param) {
            return <ActiveChip variant={'outlined'} color={'success'} label={t('aktiv')} />;
        } else {
            return <InactiveChip variant={'outlined'} color={'error'} label={t('inaktiv')} />;
        }
    }

    const linkToWizardSettings = () => navigate(nav.WIZARD.sub.SETTINGS.path);

    return (
        <Box py={2}>
            {appear(<Typography variant={'caption'}>{t('status2')}</Typography>)}
            {appear(<Typography variant={'h1'}>{t('dashboard')}</Typography>)}
            <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
                <Grid item xs={12} md={'auto'}>
                    <motion.div animate={{ x: [-50, 0], opacity: [0, 1] }} transition={{ ease: 'easeInOut', duration: 0.5 }}>
                        <Box display={'flex'} gap={1} alignItems={'center'}>
                            <Typography variant={'h4'}>{t('neukunden')}</Typography>
                            {renderChipForStatus(wizardStati.walkInCustomerWizardState)}
                        </Box>
                    </motion.div>
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <motion.div animate={{ x: [-50, 0], opacity: [0, 1] }} transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}>
                        <Box display={'flex'} gap={1} alignItems={'center'}>
                            <Typography variant={'h4'}>{t('stammkunden')}</Typography>
                            {renderChipForStatus(wizardStati.regularCustomerWizardState)}
                        </Box>
                    </motion.div>
                </Grid>
                {!useLocation().pathname.includes('settings') && (
                    <Grid item xs={12} md={'auto'}>
                        <motion.div animate={{ x: [-50, 0], opacity: [0, 1] }} transition={{ ease: 'easeInOut', duration: 0.5, delay: 1 }}>
                            <Button variant={'text'} onClick={linkToWizardSettings}>
                                {t('manageWizardSettings')}
                            </Button>
                        </motion.div>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        stati: state.wizardStati.stati,
        loading: state.wizardStati.loading
    };
};
export default connect(mapStateToProps)(WizardStatus);
