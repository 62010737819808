/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKeyResource } from '../models/ApiKeyResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class HoleRecognitionAfterburnerRestControllerService {

    /**
     * @returns ApiKeyResource OK
     * @throws ApiError
     */
    public static getHoleRecognitionAfterburnerApiKey(): CancelablePromise<ApiKeyResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/holeRecognitionAfterburner/apiKey`,
        });
    }

}