import { Typography, Card, Stack, Divider, Button, Collapse } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import HeadlineButton from '../display/HeadlineButton/HeadlineButton';
import { useMemo, useState } from 'react';
import PriceFinderGeneral from './PriceFinderGeneral';
import PriceFinderPlants from './PriceFinderPlants';
import PriceFinderResult from './PriceFinderResult';

interface Props {
    plants?: Array<{ name: string; width: number; height: number; length: number }>;
}

const PriceFinder: React.FC<Props> = ({ plants }) => {
    const [localPlants, setLocalPlants] = useState<Array<{ name: string; width: number; height: number; length: number }>>([
        {
            name: 'Anlage 1',
            length: 2000,
            width: 1200,
            height: 1600
        }
    ]);
    const result = useMemo(() => {
        const plantsResult = (plants || localPlants || []).map((plant) => {
            const area = 2 * (plant.length * plant.width + plant.length * plant.height + plant.width * plant.height);
            return {
                ...plant,
                area,
                areaPerHour: area * 60,
                areaPerYear: area * 60 * 24 * 365,
                valid: area > 0
            };
        });
        const price = plantsResult.reduce((accumulator, plant) => {
            return accumulator + plant.area;
        }, 0);

        return {
            plants: plantsResult,
            price
        };
    }, [localPlants, plants]);

    return (
        <Stack>
            <PriceFinderGeneral />
            {/* <PriceFinderCalcBasics /> */}
            <PriceFinderPlants plants={plants || localPlants} setPlants={setLocalPlants} isEditable={!plants} result={result} />
            <PriceFinderResult result={result} />
        </Stack>
    );
};

export default PriceFinder;
