import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import {Box, Button, Card, Divider, Grid, styled, Tooltip, Typography} from '@mui/material';
import _ from "lodash";
import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {RootState} from "src/redux/store";


const ButtonWrapper = styled(Box)(
    ({theme}) => `
        cursor: pointer;
        position: relative;
        border-radius: ${theme.general.borderRadiusXl};
        padding: ${theme.spacing(0.8)};
        display: flex;
        flex-direction: row;
        align-items: stretch;
        min-width: 80px;
        box-shadow: 0 0 0 2px ${theme.colors.primary.lighter};

        &:hover {
            box-shadow: 0 0 0 2px ${theme.colors.primary.light};
        }

        &.active {
            box-shadow: 0 0 0 2px ${theme.palette.primary.main};

            .colorSchemeWrapper {
                opacity: .6;
            }
        }
  `
);

const ColorSchemeWrapper = styled(Box)<{ color?: string }>(
    ({theme, color}) => `

    position: relative;

    border-radius: ${theme.general.borderRadiusXl};
    height: 28px;
    background-color: ${color ?? "red"};
    display: flex;
    align-items: stretch;
    width: 100%;
    border-top-left-radius: ${theme.general.borderRadiusXl};
    border-bottom-left-radius: ${theme.general.borderRadiusXl};
    flex: 1;
  `
);

const CheckSelected = styled(Box)(
    ({theme}) => `
    background: ${theme.palette.success.main};
    border-radius: 50px;
    height: 26px;
    width: 26px;
    color: ${theme.palette.success.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -13px;
    z-index: 5;

    .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
    }

  `
);

interface IWizardThemePicker {
    updateTheme: (theme: string) => void;
    updateSelectedTheme: (theme: string) => void;
}

//IDK why this errors, its defined in the interface...
// @ts-ignore
const WizardThemePicker: FC<IWizardThemePicker> = ({updateTheme, profile, updateSelectedTheme}) => {
    const curThemeName = 'PureLightTheme' || 'PureLightTheme';
    const {t} = useTranslation();

    const [onlineTheme, setOnlineTheme] = useState(profile.themeId);
    const [selectedTheme, setSelectedTheme] = useState(profile.themeId);

    const changeTheme = (theme): void => {
        setSelectedTheme(theme);
        updateSelectedTheme(theme);
    };

    function useDesign() {
        setOnlineTheme(selectedTheme);
        updateTheme(selectedTheme);
    }

    const handleDisableSave = (): boolean => {
        return _.isEqual(selectedTheme, onlineTheme);
    };
    return (
        <>
            <Card sx={{p: 2}}>
                <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt' sx={{display: "flex", flexDirection: "column"}}>
                    <Typography
                        variant="caption"
                    >
                        {t('primreFarbeNdern')}
                    </Typography>
                    {t('wizardFarbeinstellungen')}
                </Box>
                <Divider sx={{mt: 1, mb: 2}}/>
                <Grid container spacing={3} p={3}>
                    <Grid item container xs={12} md={12} spacing={2}>
                        <Grid item xl={2} lg={3} md={4}>
                            <Tooltip placement="bottom" title={t('blau')} arrow>
                                <ButtonWrapper
                                    className={selectedTheme === '1' ? 'active' : ''}
                                    onClick={() => {
                                        changeTheme('1');
                                    }}
                                >
                                    {onlineTheme === '1' && (
                                        <CheckSelected>
                                            <CheckTwoToneIcon/>
                                        </CheckSelected>
                                    )}
                                    <ColorSchemeWrapper color={"#3d89f5"}/>
                                </ButtonWrapper>
                            </Tooltip>
                        </Grid>
                        <Grid item xl={2} lg={3} md={4}>
                            <Tooltip placement="bottom" title={t('grn')} arrow>
                                <ButtonWrapper
                                    className={selectedTheme === '2' ? 'active' : ''}
                                    onClick={() => {
                                        changeTheme('2');
                                    }}
                                >
                                    {onlineTheme === '2' && (
                                        <CheckSelected>
                                            <CheckTwoToneIcon/>
                                        </CheckSelected>
                                    )}
                                    <ColorSchemeWrapper color={"#61BC45"}/>
                                </ButtonWrapper>
                            </Tooltip>
                        </Grid>
                        <Grid item xl={2} lg={3} md={4}>
                            <Tooltip placement="bottom" title={t('rot')} arrow>
                                <ButtonWrapper
                                    className={selectedTheme === '3' ? 'active' : ''}
                                    onClick={() => {
                                        changeTheme('3');
                                    }}
                                >
                                    {onlineTheme === '3' && (
                                        <CheckSelected>
                                            <CheckTwoToneIcon/>
                                        </CheckSelected>
                                    )}
                                    <ColorSchemeWrapper color={"#E50613"}/>
                                </ButtonWrapper>
                            </Tooltip>
                        </Grid>
                        <Grid item xl={2} lg={3} md={4}>
                            <Tooltip placement="bottom" title={t('orange')} arrow>
                                <ButtonWrapper
                                    className={selectedTheme === '4' ? 'active' : ''}
                                    onClick={() => {
                                        changeTheme('4');
                                    }}
                                >
                                    {onlineTheme === '4' && (
                                        <CheckSelected>
                                            <CheckTwoToneIcon/>
                                        </CheckSelected>
                                    )}
                                    <ColorSchemeWrapper color={"#FFAB4D"}/>
                                </ButtonWrapper>
                            </Tooltip>
                        </Grid>
                        <Grid item xl={2} lg={3} md={4}>
                            <Tooltip placement="bottom" title={t('dunkelblau')} arrow>
                                <ButtonWrapper
                                    className={selectedTheme === '5' ? 'active' : ''}
                                    onClick={() => {
                                        changeTheme('5');
                                    }}
                                >
                                    {onlineTheme === '5' && (
                                        <CheckSelected>
                                            <CheckTwoToneIcon/>
                                        </CheckSelected>
                                    )}
                                    <ColorSchemeWrapper color={"#344966"}/>
                                </ButtonWrapper>
                            </Tooltip>
                        </Grid>
                        <Grid item xl={2} lg={3} md={4}>
                            <Tooltip placement="bottom" title={t('grau')} arrow>
                                <ButtonWrapper
                                    className={selectedTheme === '6' ? 'active' : ''}
                                    onClick={() => {
                                        changeTheme('6');
                                    }}
                                >
                                    {onlineTheme === '6' && (
                                        <CheckSelected>
                                            <CheckTwoToneIcon/>
                                        </CheckSelected>
                                    )}
                                    <ColorSchemeWrapper color={"#666666"}/>
                                </ButtonWrapper>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box pt={3} display='flex' alignItems='center' justifyContent='flex-end'>
                            <Button
                                onClick={() => {
                                    useDesign()
                                }}
                                type='submit'
                                variant='contained'
                                disabled={handleDisableSave()}
                            >
                                {t('designVerwenden')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        profile: state.wizardProfile.currentProfile,
    }
}
export default connect(mapStateToProps)(WizardThemePicker);

