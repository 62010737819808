import { ApartmentTwoTone, ArticleTwoTone, AttachMoneyTwoTone, MailTwoTone, PersonTwoTone, PhoneTwoTone } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Badge, Box, Button, Card, Divider, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { CustomerTypeResource, PriceManipulationParameterValueResource, RegularCustomerResource } from '../../../backend/market';
import useDialog from '../../../hooks/useDialog';
import { deleteCustomer, updateCustomerStatus } from '../../../redux/thunks/customers.thunk';

const CustomerMobileCell: React.FC<{ customer: RegularCustomerResource }> = ({ customer }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dialog = useDialog();
    const navigate = useNavigate();

    const handleDelete = (event) => {
        event.stopPropagation();

        return dialog.openDialog({
            severity: 'error',
            okCallback(): void {
                dispatch(
                    deleteCustomer(customer.id, () => {
                        const key = enqueueSnackbar(t('customerDeleted'), {
                            variant: 'success',
                            action: (
                                <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
                                    <CloseRoundedIcon />
                                </IconButton>
                            )
                        });
                    })
                );
                console.log('delete customer', customer.id);
                dialog.closeDialog();
            },
            title: t('kundenLschen2'),
            okText: t('kundenLschen'),
            component: <Typography variant={'body2'}>{t('bistDuSicherDasDuDiesenKundenLschenMchtest')}</Typography>
        });
    };

    function renderStatus() {
        function getBadgeColor(name: string): 'error' | 'warning' | 'success' | 'default' | 'primary' | 'secondary' | 'info' {
            switch (name) {
                case 'AKTIV':
                    return 'success';
                case 'INAKTIV':
                    return 'error';
                default:
                    return 'default';
            }
        }

        return (
            <Select
                onChange={(event) => {
                    handleStatusUpdate(customer.id, event.target.value);
                    event.stopPropagation();
                }}
                sx={{ '&:focus': { outline: 'none' } }}
                value={customer.state}
            >
                <MenuItem value={'ACTIVE'}>
                    <Box gap={'10px'} display={'flex'} alignItems={'center'}>
                        <Badge variant={'dot'} color={getBadgeColor('AKTIV')}></Badge> {t('aktiviert')}
                    </Box>
                </MenuItem>
                <MenuItem value={'INACTIVE'}>
                    <Box gap={'10px'} display={'flex'} alignItems={'center'}>
                        <Badge variant={'dot'} color={getBadgeColor('INAKTIV')}></Badge> {t('deaktiviert')}
                    </Box>
                </MenuItem>
            </Select>
        );
    }

    const handleStatusUpdate = (customerId, status) => {
        dispatch(
            updateCustomerStatus(customerId, status, () => {
                const key = enqueueSnackbar(t('statusHasBeenChanged'), {
                    variant: 'success',
                    action: (
                        <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon />
                        </IconButton>
                    )
                });
            })
        );
    };

    const handleCellClicked = (cell) => navigate(nav.TOOLS.sub.CUSTOMERS.sub.DETAIL.path.replace(':id', cell.id));

    const renderPriceParamValues = (customerType: CustomerTypeResource, overwrittenPriceValues: PriceManipulationParameterValueResource[]) => {
        return (
            customerType.name +
            ' / ' +
            customerType.priceParameterValues
                .reduce((acc, curr) => {
                    const overwrittenValue = overwrittenPriceValues.find((value) => value.parameter.id === curr.parameter.id);
                    if (overwrittenValue) {
                        if (overwrittenValue.parameter.type === 'BOOLEAN') {
                            return acc.concat(`${curr.value === 'false' ? t('no') : t('yes')} ${curr.parameter.unit}`);
                        }
                        return acc.concat(`${overwrittenValue.value} ${overwrittenValue.parameter.unit}`);
                    }
                    if (curr.parameter.type === 'BOOLEAN') {
                        return acc.concat(`${curr.value === 'false' ? t('no') : t('yes')} ${curr.parameter.unit}`);
                    }
                    return acc.concat(`${curr.value} ${curr.parameter.unit}`);
                }, [])
                .join(', ')
        );
    };

    const renderWithLabelAndIcon = (icon, text, label, alignRight = false) => {
        return (
            <Stack direction={'column'} alignItems={alignRight ? 'end' : 'start'}>
                {label && <Typography variant={'caption'}>{label}</Typography>}
                <Stack direction={'row'} spacing={1}>
                    {icon}
                    <Typography variant={'body1'} fontWeight={'bold'}>
                        {text}
                    </Typography>
                </Stack>
            </Stack>
        );
    };

    return (
        <Card sx={{ p: 2 }}>
            <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                {renderWithLabelAndIcon(<ApartmentTwoTone />, customer.companyName, 'Firma')}
                {renderWithLabelAndIcon(null, renderPriceParamValues(customer.customerType, customer.overwrittenPriceValues), 'Kundentyp', true)}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Typography variant={'caption'}>{t('kontaktperson')}</Typography>
            <Stack justifyContent={'space-between'} alignItems={'start'} direction={'column'} spacing={1}>
                {renderWithLabelAndIcon(<PersonTwoTone />, `${customer.contactPersonFirstName} ${customer.contactPersonLastName}`, null)}
                {renderWithLabelAndIcon(<MailTwoTone />, `${customer.email}`, null)}
                {renderWithLabelAndIcon(<PhoneTwoTone />, `${customer.phone}`, null)}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                {renderWithLabelAndIcon(<AttachMoneyTwoTone />, `${customer.salesVolume}`, 'Verkaufsvolumen')}
                {renderWithLabelAndIcon(<ArticleTwoTone />, `${customer.inquiryCount}`, 'Anfragen', true)}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                {renderStatus()}
                <Button onClick={() => handleCellClicked(customer)}>{t('kundeAnsehen')}</Button>
            </Stack>
        </Card>
    );
};

export default CustomerMobileCell;
