import React, { useState, useEffect, useRef } from 'react';
import { LinearProgress, Stack } from '@mui/material';
import {DxfViewer, DxfViewerOptions} from "dxf-viewer"
import { Color } from 'three';
const DxfViewerWorker = () => new Worker(new URL('./DxfViewerWorker.js', import.meta.url));

interface Props {
    url: string;    
    fonts?: Array<any>;
    options?: DxfViewerOptions;
}

const DxfViewerCanvas: React.FC<Props> = ({ url, fonts, options }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(null);
    const [progressText, setProgressText] = useState(null);
    const [curProgressPhase, setCurProgressPhase] = useState(null);
    const [error, setError] = useState(null);
    const canvasContainerRef = useRef(null);
    let dxfViewer = useRef(null);

    const Load = async (url) => {
        setIsLoading(true);
        setError(null);
        try {
            await dxfViewer.current.Load({
                url,
                fonts,
                progressCbk: _OnProgress,
                workerFactory: DxfViewerWorker
            });
        } catch (error) {
            console.warn(error);
            setError(error.toString());
        } finally {
            setIsLoading(false);
            setProgressText(null);
            setProgress(null);
            setCurProgressPhase(null);
        }
    };

    const GetViewer = () => {
        return dxfViewer.current;
    };

    const _OnProgress = (phase, size, totalSize) => {
        if (phase !== curProgressPhase) {
            switch (phase) {
                case 'font':
                    setProgressText('Fetching fonts...');
                    break;
                case 'fetch':
                    setProgressText('Fetching file...');
                    break;
                case 'parse':
                    setProgressText('Parsing file...');
                    break;
                case 'prepare':
                    setProgressText('Preparing rendering data...');
                    break;
                default:
                    break;
            }
            setCurProgressPhase(phase);
        }
        if (totalSize === null) {
            setProgress(-1);
        } else {
            setProgress(size / totalSize);
        }
    };

    useEffect(() => {
        dxfViewer.current = new DxfViewer(canvasContainerRef.current, {
            ...options,
            autoResize: true,
            clearColor: new Color('rgb(248, 248, 249)'),
        });
        return () => {
            dxfViewer.current.Destroy();
            dxfViewer.current = null;
        };
    }, [options]);

    useEffect(() => {
        if (url !== null) {
            Load(url);
        } else {
            dxfViewer.current.Clear();
            setError(null);
            setIsLoading(false);
            setProgress(null);
        }
    }, [url]);

    return (
        <Stack className="canvasContainer" ref={canvasContainerRef} flexGrow={1}>
            {isLoading && (
                <LinearProgress color="primary" />
            )}
            {progress !== null && (
                <div className="progress">
                <LinearProgress color="primary" variant={progress < 0 ? 'indeterminate' : 'determinate'} value={progress * 100} />
                {progressText !== null && (
                    <div className="progressText">
                        {progressText}
                    </div>
                )}
                </div>
            )}
            {error !== null && (
                <div className="error" title={error}>
                    Error occurred: {error}
                </div>
            )}
        </Stack>
    );
}

export default DxfViewerCanvas;
