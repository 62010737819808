/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueResource } from './BooleanValueResource';
import type { ListValueResource } from './ListValueResource';
import type { StringValueResource } from './StringValueResource';
import type { VariableValueResource } from './VariableValueResource';

export type VariableResource = {
    id?: number;
    type?: VariableResource.type;
    name?: string;
    translationKey?: string;
    value?: (BooleanValueResource | ListValueResource | StringValueResource | VariableValueResource);
}

export namespace VariableResource {

    export enum type {
        INPUT_VARIABLE = 'INPUT_VARIABLE',
        TERM_VARIABLE = 'TERM_VARIABLE',
        PROVIDED_VARIABLE = 'PROVIDED_VARIABLE',
        DECLARATION_VARIABLE = 'DECLARATION_VARIABLE',
        SURCHARGE_VARIABLE = 'SURCHARGE_VARIABLE',
        ROUND_VARIABLE = 'ROUND_VARIABLE',
        RULE_BASED_VARIABLE = 'RULE_BASED_VARIABLE',
    }


}
