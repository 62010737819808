import {
    AdditionalStorageDto,
    CalculationFragmentResource,
    CalculationMetadataResource,
    CalculationParameterDto,
    CalculationResultResource,
    InternalCalcPriceManipulationParameterValueResource,
    InternalCalculationResource,
    SurchargeOverrideStorageResource
} from 'src/backend/internalCalc';
import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import {
    addAttachmentToMetadata,
    addAttachmentToPart,
    changeGeometryPackageOfFragment,
    create3dPart,
    createFragment,
    createPart,
    duplicateFragment,
    duplicatePart,
    removeAttachmentFromMetadata,
    removeAttachmentFromPart,
    removeFragment,
    removePart,
    updateCommission,
    updateFragmentParameter,
    updateFragmentParameters,
    updateMasterParameters,
    updateMetadata,
    updateNote,
    updatePartForcePrice,
    updatePartParameter,
    updatePartParameters,
    updatePriceManipulationParameter,
    updateRegularCustomer,
    updateSurchargeOverrides,
    updateUserDefinedPartName
} from '../thunks/proCalc.thunk';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
    ProCalcSavingStatus,
    ProCalcUpdateLoading,
    addTmpAttachmentToMetadata,
    addTmpAttachmentToPart,
    addTmpFragment,
    addTmpPart,
    deleteAttachmentFromMetadata,
    deleteAttachmentFromPart,
    deleteFragment,
    deletePart,
    handleUpdateFailure,
    handleUpdateSuccess,
    saveCalculationRecord,
    setFragmentParameter,
    setFragmentParameters,
    setFragmentToTmp,
    setPartForcePrice,
    setPartName,
    setPartParameter,
    setPartParameters,
    setProCalcValue,
    setSavingStatus,
    setSurchargeOverrides,
    setUpdateLoading
} from '../slices/proCalc.reducer';
import { FileResource } from 'src/backend/market';
import { Selector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { getAllMessages, getGeneralSetting } from 'src/utils/CalcHelpers';
import { formatPrice } from 'src/utils/FormatHelpers';
import { CURRENCY_SETTING_NAME, NUMBER_FORMAT_SETTING_NAME } from 'src/statics/statics';
import { ModelStats } from '@surface-solutions/ssa-3d-viewer/dist/context/ViewerContext';

export enum ProCalcUpdateTypes {
    MASTER_PARAMETERS = 'masterParameters',
    METADATA = 'metadata',
    ADD_ATTACHMENT_TO_METADATA = 'addAttachmentToMetadata',
    DELETE_ATTACHMENT_FROM_METADATA = 'removeAttachmentFromMetadata',
    REGULAR_CUSTOMER = 'regularCustomer',
    NOTE = 'note',
    COMMISSION = 'commission',
    PRICE_MANIPULATION_PARAMETER = 'priceManipulationParameter',
    SURCHARGE_OVERRIDES = 'surchargeOverrides',
    CREATE_PART = 'createPart',
    CREATE_3D_PART = 'create3dPart',
    DUPLICATE_PART = 'duplicatePart',
    DELETE_PART = 'deletePart',
    ADD_ATTACHMENT_TO_PART = 'addAttachmentToPart',
    DELETE_ATTACHMENT_FROM_PART = 'removeAttachmentFromPart',
    PART_PARAMETER = 'partParameter',
    PART_PARAMETERS = 'partParameters',
    PART_NAME = 'partName',
    FORCE_PART_PRICE = 'forcePartPrice',
    CREATE_FRAGMENT = 'createFragment',
    DUPLICATE_FRAGMENT = 'duplicateFragment',
    DELETE_FRAGMENT = 'deleteFragment',
    FRAGMENT_PARAMETER = 'fragmentParameter',
    FRAGMENT_PARAMETERS = 'fragmentParameters',
    FRAGMENT_GEOMETRY_PACKAGE = 'fragmentGeometryPackage'
}

type ProCalcUpdateValueMap = {
    [ProCalcUpdateTypes.MASTER_PARAMETERS]: Array<CalculationParameterDto>;
    [ProCalcUpdateTypes.METADATA]: CalculationMetadataResource;
    [ProCalcUpdateTypes.ADD_ATTACHMENT_TO_METADATA]: File;
    [ProCalcUpdateTypes.DELETE_ATTACHMENT_FROM_METADATA]: string;
    [ProCalcUpdateTypes.REGULAR_CUSTOMER]: number;
    [ProCalcUpdateTypes.NOTE]: string | null;
    [ProCalcUpdateTypes.COMMISSION]: string;
    [ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER]: { index: number; paramValue: InternalCalcPriceManipulationParameterValueResource };
    [ProCalcUpdateTypes.SURCHARGE_OVERRIDES]: Array<SurchargeOverrideStorageResource>;
    [ProCalcUpdateTypes.CREATE_PART]: { itemId: number; initParameters?: Array<CalculationParameterDto>, fileResource?: FileResource; additionalStorage?: Array<AdditionalStorageDto>; userDefinedPartName?: string };
    [ProCalcUpdateTypes.CREATE_3D_PART]: { itemId: number; file: File | FileResource; stats: ModelStats };
    [ProCalcUpdateTypes.DUPLICATE_PART]: number;
    [ProCalcUpdateTypes.DELETE_PART]: number;
    [ProCalcUpdateTypes.ADD_ATTACHMENT_TO_PART]: { partId: number; file: File };
    [ProCalcUpdateTypes.DELETE_ATTACHMENT_FROM_PART]: { partId: number; attachmentId: string };
    [ProCalcUpdateTypes.PART_PARAMETER]: { partId: number; itemId: number; parameter: CalculationParameterDto };
    [ProCalcUpdateTypes.PART_PARAMETERS]: { partId: number; itemId: number; parameters: Array<CalculationParameterDto> };
    [ProCalcUpdateTypes.PART_NAME]: { partId: number; name: string };
    [ProCalcUpdateTypes.FORCE_PART_PRICE]: { partId: number; force: boolean; price: number };
    [ProCalcUpdateTypes.CREATE_FRAGMENT]: { partId: number; itemId: number; geometryPackage: string; geometryPackageName: string };
    [ProCalcUpdateTypes.DUPLICATE_FRAGMENT]: { partId: number; fragmentId: number };
    [ProCalcUpdateTypes.DELETE_FRAGMENT]: { partId: number; fragmentId: number };
    [ProCalcUpdateTypes.FRAGMENT_PARAMETER]: { partId: number; fragment: CalculationFragmentResource; parameter: CalculationParameterDto };
    [ProCalcUpdateTypes.FRAGMENT_PARAMETERS]: { partId: number; fragment: CalculationFragmentResource; parameters: Array<CalculationParameterDto> };
    [ProCalcUpdateTypes.FRAGMENT_GEOMETRY_PACKAGE]: { partId: number; fragment: CalculationFragmentResource };
};

interface ProCalcUpdateInfo<T extends ProCalcUpdateTypes> {
    optimisticUpdateFunction: (value: ProCalcUpdateValueMap[T]) => void;
    updateFunction: (calc: InternalCalculationResource, value: ProCalcUpdateValueMap[T]) => void;
    debounce?: boolean;
}
export const ProCalcUpdateInfos: Record<ProCalcUpdateTypes, ProCalcUpdateInfo<ProCalcUpdateTypes>> = {
    [ProCalcUpdateTypes.MASTER_PARAMETERS]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'masterCalculationSetParameters', value }),
        updateFunction: updateMasterParameters
    },
    [ProCalcUpdateTypes.METADATA]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'calculationMetadata', value }),
        updateFunction: updateMetadata
    },
    [ProCalcUpdateTypes.ADD_ATTACHMENT_TO_METADATA]: {
        optimisticUpdateFunction: addTmpAttachmentToMetadata,
        updateFunction: addAttachmentToMetadata,
        debounce: false
    },
    [ProCalcUpdateTypes.DELETE_ATTACHMENT_FROM_METADATA]: {
        optimisticUpdateFunction: deleteAttachmentFromMetadata,
        updateFunction: removeAttachmentFromMetadata,
        debounce: false
    },
    [ProCalcUpdateTypes.REGULAR_CUSTOMER]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'regularCustomer', value }),
        updateFunction: updateRegularCustomer
    },
    [ProCalcUpdateTypes.NOTE]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'note', value }),
        updateFunction: updateNote
    },
    [ProCalcUpdateTypes.COMMISSION]: {
        optimisticUpdateFunction: (value) => setProCalcValue({ path: 'commission', value }),
        updateFunction: updateCommission
    },
    [ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER]: {
        optimisticUpdateFunction: (value: any) => setProCalcValue({ path: `priceManipulationParameterValues[${value.index}]`, value: value.paramValue }),
        updateFunction: updatePriceManipulationParameter
    },
    [ProCalcUpdateTypes.SURCHARGE_OVERRIDES]: {
        optimisticUpdateFunction: setSurchargeOverrides,
        updateFunction: updateSurchargeOverrides
    },
    [ProCalcUpdateTypes.CREATE_PART]: {
        optimisticUpdateFunction: addTmpPart,
        updateFunction: createPart
    },
    [ProCalcUpdateTypes.CREATE_3D_PART]: {
        optimisticUpdateFunction: addTmpPart,
        updateFunction: create3dPart
    },
    [ProCalcUpdateTypes.DUPLICATE_PART]: {
        optimisticUpdateFunction: addTmpPart,
        updateFunction: duplicatePart
    },
    [ProCalcUpdateTypes.DELETE_PART]: {
        optimisticUpdateFunction: deletePart,
        updateFunction: removePart
    },
    [ProCalcUpdateTypes.ADD_ATTACHMENT_TO_PART]: {
        optimisticUpdateFunction: addTmpAttachmentToPart,
        updateFunction: addAttachmentToPart,
        debounce: false
    },
    [ProCalcUpdateTypes.DELETE_ATTACHMENT_FROM_PART]: {
        optimisticUpdateFunction: deleteAttachmentFromPart,
        updateFunction: removeAttachmentFromPart,
        debounce: false
    },
    [ProCalcUpdateTypes.PART_PARAMETER]: {
        optimisticUpdateFunction: setPartParameter,
        updateFunction: updatePartParameter
    },
    [ProCalcUpdateTypes.PART_PARAMETERS]: {
        optimisticUpdateFunction: setPartParameters,
        updateFunction: updatePartParameters
    },
    [ProCalcUpdateTypes.PART_NAME]: {
        optimisticUpdateFunction: setPartName,
        updateFunction: updateUserDefinedPartName
    },
    [ProCalcUpdateTypes.FORCE_PART_PRICE]: {
        optimisticUpdateFunction: setPartForcePrice,
        updateFunction: updatePartForcePrice
    },
    [ProCalcUpdateTypes.CREATE_FRAGMENT]: {
        optimisticUpdateFunction: addTmpFragment,
        updateFunction: createFragment
    },
    [ProCalcUpdateTypes.DUPLICATE_FRAGMENT]: {
        optimisticUpdateFunction: addTmpFragment,
        updateFunction: duplicateFragment
    },
    [ProCalcUpdateTypes.DELETE_FRAGMENT]: {
        optimisticUpdateFunction: deleteFragment,
        updateFunction: removeFragment
    },
    [ProCalcUpdateTypes.FRAGMENT_PARAMETER]: {
        optimisticUpdateFunction: setFragmentParameter,
        updateFunction: updateFragmentParameter
    },
    [ProCalcUpdateTypes.FRAGMENT_PARAMETERS]: {
        optimisticUpdateFunction: setFragmentParameters,
        updateFunction: updateFragmentParameters
    },
    [ProCalcUpdateTypes.FRAGMENT_GEOMETRY_PACKAGE]: {
        optimisticUpdateFunction: setFragmentToTmp,
        updateFunction: changeGeometryPackageOfFragment
    }
};

/*
####################
##  UPDATE LOGIC  ##
####################
*/
type QueueElement = {
    updateType: ProCalcUpdateTypes;
    value: any;
};
type DebouncedUpdate = {
    [updateType in ProCalcUpdateTypes]?: { debounceFunction: DebouncedFunc<() => void>; value: any };
};

let updatesQueue: Array<QueueElement> = [];
let debouncedUpdates: DebouncedUpdate = {};
let isProcessingQueue = false;

const debounceUpdate = (updateType: ProCalcUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number = 500) => {
    // If there's no debounced function for this updateType, create one
    if (!debouncedUpdates[updateType]) {
        debouncedUpdates[updateType] = createDebounceUpdate(updateType, value, dispatch, getState, delay);
    }
    // Update the value for this updateType and invoke the debounced function
    debouncedUpdates[updateType].value = value;
    debouncedUpdates[updateType].debounceFunction();
};

const createDebounceUpdate = (updateType: ProCalcUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState, delay: number) => {
    const debounceFunction = debounce(async () => {
        if (!debouncedUpdates[updateType]) return;
        // Value can not be used, debouncedUpdates[updateType].value assures that we always get the most recent value
        await enqueueAndProcessUpdate(updateType, debouncedUpdates[updateType].value, dispatch, getState);
        delete debouncedUpdates[updateType];
    }, delay);
    return { debounceFunction, value };
};

const enqueueAndProcessUpdate = async (updateType: ProCalcUpdateTypes, value: any, dispatch: AppDispatch, getState: () => RootState) => {
    // Add the update to the queue
    updatesQueue.push({ updateType, value });
    if (!isProcessingQueue) {
        isProcessingQueue = true;
        await processQueue(dispatch, getState);
        isProcessingQueue = false;
    }
};

const processQueue = async (dispatch: AppDispatch, getState: () => RootState) => {
    // Process each item in the queue sequentially
    while (updatesQueue.length > 0) {
        const { updateType, value } = updatesQueue.shift()!;
        await processUpdate(dispatch, getState, updateType, value);
    }
};

const processUpdate = async (dispatch: AppDispatch, getState: () => RootState, updateType: ProCalcUpdateTypes, value: any) => {
    try {
        const calc = cloneDeep(getState().proCalc.calculation);
        const updateFunction = ProCalcUpdateInfos[updateType].updateFunction;
        await dispatch(updateFunction(calc, value));
        dispatch(handleUpdateSuccess(updateType));
    } catch (error) {
        dispatch(handleUpdateFailure({ updateType, error }));
    }
};

const optimisticUpdate = <T extends ProCalcUpdateTypes>(dispatch: AppDispatch, updateType: T, value: ProCalcUpdateValueMap[T]) => {
    dispatch(saveCalculationRecord());
    const optimisticUpdateFunction = ProCalcUpdateInfos[updateType].optimisticUpdateFunction;
    dispatch(optimisticUpdateFunction(value));
};

export const updateProCalc = <T extends ProCalcUpdateTypes>(updateType: T, value: ProCalcUpdateValueMap[T]): AppThunk => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setSavingStatus(ProCalcSavingStatus.LOADING));
        dispatch(setUpdateLoading({ updateType, isLoading: true }));

        // Perform the optimistic update for immediate UI feedback
        optimisticUpdate(dispatch, updateType, value);

        // enqueueAndProcessUpdate(updateType, value, dispatch, getState);
        if (ProCalcUpdateInfos[updateType]?.debounce === false) {
            enqueueAndProcessUpdate(updateType, value, dispatch, getState); // don't drop an update
        } else {
            // Debounce the update to prevent rapid successive updates of the same type
            debounceUpdate(updateType, value, dispatch, getState);
        }
    };
};

export const cancelProCalcUpdates = (): AppThunk => {
    return () => {
        // Clear all debounced updates
        Object.keys(debouncedUpdates).forEach((updateType: ProCalcUpdateTypes) => {
            if (debouncedUpdates[updateType] && debouncedUpdates[updateType].debounceFunction.cancel) {
                debouncedUpdates[updateType].debounceFunction.cancel();
            }
        });
        debouncedUpdates = {}; // Reset the debouncedUpdates object

        // Clear the updates queue
        updatesQueue = [];

        // Reset the queue processing flag
        isProcessingQueue = false;
    };
};

/*
#################
##  SELECTORS  ##
#################
*/

const selectCostResult: Selector<RootState, CalculationResultResource> = (state) => state.proCalc.calculation?.costResult || {};

export const selectProCalcSettings = createSelector([selectCostResult], (costResult) => ({
    numberFormat: getGeneralSetting(NUMBER_FORMAT_SETTING_NAME, costResult),
    currency: getGeneralSetting(CURRENCY_SETTING_NAME, costResult)
}));

export const selectProCalcPriceDetails = createSelector([selectCostResult, selectProCalcSettings], (costResult, settings) => ({
    price: costResult.price,
    priceMin: costResult.priceMin,
    priceMax: costResult.priceMax,
    priceNonRounded: costResult.priceNonRounded,
    priceMinNonRounded: costResult.priceMinNonRounded,
    priceMaxNonRounded: costResult.priceMaxNonRounded,
    unspoiledPrice: costResult.unspoiledPrice,
    unspoiledPriceMin: costResult.unspoiledPriceMin,
    unspoiledPriceMax: costResult.unspoiledPriceMax,
    formattedPriceNonRounded: formatPrice(costResult.priceNonRounded, false, settings.numberFormat, settings.currency)
}));

export const selectProCalcMessages = createSelector([selectCostResult], (costResult) => {
    const allMessages = getAllMessages(costResult);
    return {
        all: allMessages,
        hints: allMessages.filter((message) => message.messageType === 'HINT'),
        warnings: allMessages.filter((message) => message.messageType === 'WARNING'),
        errors: allMessages.filter((message) => message.messageType === 'ERROR' || (message.messageType.startsWith('INVALID') && message.messageType !== 'INVALID_PRICE_ERROR'))
    };
});

const selectIsUpdateLoading: Selector<RootState, ProCalcUpdateLoading> = (state) => state.proCalc.isUpdateLoading || {};
export const selectProCalcPriceLoading = createSelector([selectIsUpdateLoading], (isUpdateLoading) => {
    if (
        isUpdateLoading[ProCalcUpdateTypes.MASTER_PARAMETERS] ||
        isUpdateLoading[ProCalcUpdateTypes.REGULAR_CUSTOMER] ||
        isUpdateLoading[ProCalcUpdateTypes.PRICE_MANIPULATION_PARAMETER] ||
        isUpdateLoading[ProCalcUpdateTypes.SURCHARGE_OVERRIDES] ||
        isUpdateLoading[ProCalcUpdateTypes.CREATE_PART] ||
        isUpdateLoading[ProCalcUpdateTypes.CREATE_3D_PART] ||
        isUpdateLoading[ProCalcUpdateTypes.DUPLICATE_PART] ||
        isUpdateLoading[ProCalcUpdateTypes.DELETE_PART] ||
        isUpdateLoading[ProCalcUpdateTypes.PART_PARAMETER] ||
        isUpdateLoading[ProCalcUpdateTypes.PART_PARAMETERS] ||
        isUpdateLoading[ProCalcUpdateTypes.FORCE_PART_PRICE] ||
        isUpdateLoading[ProCalcUpdateTypes.CREATE_FRAGMENT] ||
        isUpdateLoading[ProCalcUpdateTypes.DUPLICATE_FRAGMENT] ||
        isUpdateLoading[ProCalcUpdateTypes.DELETE_FRAGMENT]
    )
        return true;
    return false;
});
