/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CalculationResultResource } from './CalculationResultResource';
import type { CompanyResource } from './CompanyResource';
import type { GeneralMessageResource } from './GeneralMessageResource';
import type { GeneralPartSummaryResource } from './GeneralPartSummaryResource';
import type { PriceManipulationParameterValueResource } from './PriceManipulationParameterValueResource';
import type { SummaryAttachmentResource } from './SummaryAttachmentResource';
import type { SummaryColorResource } from './SummaryColorResource';
import type { SummaryPersonDataResource } from './SummaryPersonDataResource';

/**
 * List of internal calculations
 */
export type SummaryResource = {
    id?: string;
    wishDate?: string;
    deliveryDate?: string;
    createdAt?: string;
    updatedAt?: string;
    throughputDays?: number;
    summaryType?: SummaryResource.summaryType;
    status?: string;
    name?: string;
    note?: string;
    companyLogo?: string;
    contactPerson?: SummaryPersonDataResource;
    commonAttachments?: Array<SummaryAttachmentResource>;
    priceManipulationParameterValues?: Array<PriceManipulationParameterValueResource>;
    parts?: Array<GeneralPartSummaryResource>;
    calculationResult?: CalculationResultResource;
    calculationFromImportedWizardId?: string;
    generalMessages?: Array<GeneralMessageResource>;
    materialArray?: Array<string>;
    colorSummary?: Array<SummaryColorResource>;
    companyResource?: CompanyResource;
}

export namespace SummaryResource {

    export enum summaryType {
        WIZARD_COATER = 'WIZARD_COATER',
        WIZARD_CUSTOMER = 'WIZARD_CUSTOMER',
        INTERNAL_CALC = 'INTERNAL_CALC',
    }


}
