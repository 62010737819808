import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import {
    Box,
    Button,
    IconButton,
    Stack, Tooltip,
    Typography,
} from "@mui/material";
import {t} from "i18next";
import _ from "lodash";
import {useSnackbar} from "notistack";
import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { deletePriceCalc } from "src/redux/thunks/priceCalc.thunk";
import useDialog from "src/hooks/useDialog";
import PageHeader from "src/components/display/PageHeader";
import { nav } from "src/statics/navigations";
import { GeneralPartSummaryResource, PriceCalcRestControllerService, SummaryResource } from "src/backend/market";
import { Summary } from "src/components/display/Summary/Summary";
import { DateTime } from "luxon";


const PriceCalcDetail: React.FC = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [summary, setSummary] = useState<SummaryResource>(null);


    useEffect(() => {
        if (!id) return;

        const priceCalcId = Number.parseInt(id);
            
        PriceCalcRestControllerService.getPriceCalcSummary(priceCalcId)
        .then((result) => {
            const summaryObject: SummaryResource = {};
            const partsObject: GeneralPartSummaryResource[] = [];
            summaryObject.id = id;
            summaryObject.calculationResult = result.costResult;
            summaryObject.parts = [];
            summaryObject.createdAt = DateTime.fromFormat(result.createdAtDate + ' ' + result.createdAtTime, 'dd.MM.yyyy HH:mm').toJSDate();
            summaryObject.updatedAt = summaryObject.createdAt;
            summaryObject.contactPerson = { contactedVia: 'WEB' };
            result.parts.forEach(part => {
                const partObject: GeneralPartSummaryResource = {};
                partObject.parameters = part.parameters;
                partObject.calculationResult = (result.costResult?.subCalculationResults || []).find(subResult => subResult.calculationId + '' === part.calculationId);
                partObject.partSummary = { title: part.itemNameKey, numberOfPieces: part.quantity + '' };
                partObject.userDefinedParameterName = part.partName;
                partObject.itemImageUri = part.itemImageUri;
                partsObject.push(partObject);
            })
            summaryObject.parts = partsObject;
            
            setSummary(summaryObject);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }, [])

    const handleBack = () => {
        navigate(-1);
    };


    const handleDelete = () => {
        dialog.openDialog({
            severity: "error",
            okCallback(): void {
                dispatch(deletePriceCalc(parseInt(id), () => {
                    const key = enqueueSnackbar(t("priceCalcDeleted"), {
                        variant: "success",
                        action: <IconButton color="inherit"
                                            onClick={() => closeSnackbar(key)}><CloseRoundedIcon/></IconButton>,
                    });
                }));
                console.log("delete price calc", id);
                dialog.closeDialog();
                navigate(-1);
            },
            title: t('deletePriceCalc'),
            okText: t('deletePriceCalcOk'),
            component: <Typography variant={"body2"}>{t('deletePriceCalcAreYouSure')}</Typography>
        })
    }

    return <Box>
        <PageHeader
            sx={{ mb: 2 }}
            mainTitle={t(nav.WIZARD.sub.PRICE_CALCS.sub.DETAIL.caption, { id })}
            secondaryTitle={t('details')}
            tabTitle={t(nav.WIZARD.sub.PRICE_CALCS.sub.DETAIL.tabTitle, { id })}
            handleBack={handleBack}
        >
            <Stack direction="row" gap={2}>
                <Tooltip title={t('priceCalcDetailDelete')}>
                    <Button sx={{ mr: 1 }} startIcon={<DeleteTwoToneIcon />} variant="text" color="error" onClick={handleDelete}>
                        {t('delete')}
                    </Button>
                </Tooltip>
            </Stack>
        </PageHeader>

        <Summary summary={summary} isLoading={isLoading} showPrice={true}/>
        {/* <SummaryComponent isPriceCalc={true} summaryType={"coater"}/> */}
    </Box>
}
export default PriceCalcDetail;