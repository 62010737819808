/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewGeneralFileDto } from '../models/NewGeneralFileDto';
import type { PresignedUrlResource } from '../models/PresignedUrlResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ThumbnailRestControllerService {

    /**
     * @param requestBody
     * @returns PresignedUrlResource OK
     * @throws ApiError
     */
    public static createNewPresignedUrlForMetadata(
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<PresignedUrlResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/thumbnails/presignedUrl`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}