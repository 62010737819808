import { InputAdornment, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdditionalStorageResource, DoubleCalcParamResource, IntegerCalcParamResource } from 'src/backend/internalCalc';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { GeometryLabelCircle, StyledTextField } from '../CalcInputs.styles';
import InfoLabel from './InfoLabel';
import { measureInputValueWidth } from 'src/utils/CalcHelpers';
import ExtractedIndicator from './ExtractedIndicator';

interface Props {
    param: DoubleCalcParamResource | IntegerCalcParamResource;
    disabled?: boolean;
    isSmall?: boolean;
    hide?: boolean;
    geometryDimensionLabel?: string;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const DoubleParameter: React.FC<Props> = ({ param, disabled, isSmall, hide, geometryDimensionLabel, additionalStorage = [] }) => {
    const { values, handleChange, errors, setFieldValue } = useFormikContext();
    const s = store.getState();
    const ref = useRef<any>(null);
    const [left, setLeft] = useState<number>(0);

    useEffect(() => {
        if (hide) setFieldValue(param.name, param.defaultValue || param.min || '0.0');
    }, [hide]);

    useEffect(() => {
        if (ref.current) setLeft(measureInputValueWidth(ref.current));
    }, [ref.current, values[param.name]]);

    const getBeautifiedValue = () => {
        const value = values[param.name];
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? value : parsedValue;
    };

    const renderDefault = () => {
        const value = getBeautifiedValue();
        const isDefault = param.defaultValue ? value === param.defaultValue : param.min === value;
        if (!isDefault) return <></>;

        return (
            <span
                className="default-indicator"
                style={{
                    position: 'absolute',
                    left,
                    marginTop: 3,
                    marginLeft: 4,
                    opacity: 0.5,
                    fontSize: 12,
                    pointerEvents: 'none'
                }}
            >
                (Default)
            </span>
        );
    };

    return (
        <StyledTextField
            type="number"
            label={
                <InfoLabel
                    label={param.name}
                    labelPrefix={geometryDimensionLabel && <GeometryLabelCircle>{geometryDimensionLabel}</GeometryLabelCircle>}
                    guiDescriptor={param.guiDescriptor}
                    guiDescriptorExplanation={param.explanationTranslationKey}
                />
            }
            name={param.name}
            onChange={handleChange}
            onBlur={(event) => {
                event.target.value = getBeautifiedValue();
                if (!Boolean(errors[param.name])) return;
                if (values[param.name] > param.max) return setFieldValue(param.name, param.max);
                if (values[param.name] < param.min) return setFieldValue(param.name, param.min);
            }}
            value={getBeautifiedValue()}
            error={Boolean(errors[param.name])}
            helperText={errors[param.name]}
            onFocus={(event) => {
                event.target.select();
            }}
            InputProps={{
                startAdornment: <ExtractedIndicator param={param} additionalStorage={additionalStorage} />,
                endAdornment: (
                    <InputAdornment position="end">
                        {renderDefault()}
                        {wT(param.unit, s)}
                    </InputAdornment>
                )
            }}
            inputProps={{
                min: param.min,
                max: param.max
            }}
            fullWidth
            inputRef={ref}
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
            size={isSmall ? 'small' : 'medium'}
        />
    );
};
export default DoubleParameter;
