/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TermDTO } from './TermDTO';

export type BinaryTermDTO = (TermDTO & {
    operator?: BinaryTermDTO.operator;
    'a'?: TermDTO;
    'b'?: TermDTO;
} & {
    operator: BinaryTermDTO.operator;
});

export namespace BinaryTermDTO {

    export enum operator {
        ADD = 'ADD',
        SUBTRACT = 'SUBTRACT',
        MULTIPLY = 'MULTIPLY',
        DIVIDE = 'DIVIDE',
    }


}
