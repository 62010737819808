import { Box, Button, Card, CircularProgress, Divider, IconButton, Stack, styled, SwipeableDrawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import ZoomOutMapTwoToneIcon from '@mui/icons-material/ZoomOutMapTwoTone';
import ZoomInMapTwoToneIcon from '@mui/icons-material/ZoomInMapTwoTone';
import SummarizedItem from './SummarizedItem';
import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import { AttachmentResource, CalculationResultResource } from 'src/backend/market';
import { formatPrice } from 'src/utils/FormatHelpers';
import { useTranslation } from 'react-i18next';
import { wT } from 'src/utils/wizardTranslations';
import store, { useDispatch } from 'src/redux/store';
import { ALTERNATIVE_GUI, COLOR_SYSTEMS_GUI, COLOR_SYSTEMS_RAL, CURRENCY_SETTING_NAME, INVALID, NUMBER_FORMAT_SETTING_NAME, QUANTITY, RAL_GUI } from 'src/statics/statics';
import { useNavigate } from 'react-router';
import AttachmentsSummary from './AttachmentsSummary';
import GeneralMessagesSummary from './GeneralMessagesSummary';
import { nav } from 'src/statics/navigations';
import { CalculationPartResource } from 'src/backend/internalCalc';
import { getGeneralSetting } from 'src/utils/CalcHelpers';
import { useSelector } from 'react-redux';
import { selectProCalcPriceDetails, selectProCalcPriceLoading, selectProCalcSettings } from 'src/redux/shared/proCalc.common';

const CardContainer = styled(Box)(
    ({ theme }) => `
        position: fixed;
        left: ${theme.spacing(2)};
        right: ${theme.spacing(2)};
        bottom: ${theme.spacing(2)};
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        z-index: 1000;
        pointer-events: none;
    `
);

const StyledCard = styled(Card)(
    ({ theme }) => `
        display: flex;
        align-items: center;
        box-shadow: 0 0 20px 2px ${theme.colors.alpha.black[10]}, 0 0 10px 1px ${theme.colors.alpha.black[10]}, 0 0 1px 1px ${theme.colors.alpha.black[5]};
        transition: 0.3s;
        background: ${theme.colors.alpha.white[100]};
        min-height: 78px;
        pointer-events: all;
        &.open {
            max-height: 36vh;
            flex-grow: 1;
        }
        &.closed {
            max-height: 78px;
        }
        @supports (backdrop-filter: blur(10px)) {
            & {
                background: ${theme.colors.alpha.white[70]};
                backdrop-filter: blur(10px);
            }
        }
    `
);

const Item = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        max-width: 100%;
        padding: 10px 10px;
        margin-right: 10px;
        border-radius: ${theme.shape.borderRadius}px;
        overflow: hidden;
        & img {
            max-height: 58px;
        }
        & .MuiTypography-subtitle2 {
            font-size: 12px;
            line-height: 12px;
        }
    `
);

const SwipeableDrawerHeader = styled(Box)(
    ({ theme }) => `
        position: absolute;
        right: 0;
        left: 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: ${theme.colors.alpha.white[100]};
        box-shadow: 0 0 10px 5px ${theme.colors.alpha.black[10]};
        visibility: visible;
    `
);
const SwipeableDrawerContent = styled(Box)(
    ({ theme }) => `
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: ${theme.spacing(0, 2)};
        padding-bottom: ${theme.spacing(2)};
        background-color: ${theme.colors.alpha.white[100]};
        z-index: 5000;
        overflow: hidden;
    `
);

const Puller = styled(Box)(
    ({ theme }) => `
        position: absolute;
        top: 8px;
        left: calc(50% - 15px);
        width: 30px;
        height: 5px;
        background-color: ${theme.colors.alpha.black[30]};
        border-radius: 1rem;
    `
);

const ProgressOverlap = styled(Stack)(
    ({ theme }) => `
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${theme.colors.alpha.white[100]};
    `
);

const StyledIconButton = styled(IconButton)(
    ({ theme }) => `
        background: ${theme.colors.alpha.white[70]};
        border-radius: 20rem;
        border: 1px solid ${theme.colors.alpha.black[10]};
        pointer-events: all;
    `
);
const SummarizedItemList = styled(Box)(
    ({ theme }) => `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 18px;
        align-items: flex-start;
    `
);

const drawerBleeding = 56;

interface Props {
    costResult?: CalculationResultResource;
    parts?: CalculationPartResource[];
    masterAttachments?: Array<AttachmentResource>;
    uniqueId: string;
    validationStatus?: string;
    showUnitPrice?: boolean;
}

const SummarizeCalcBar: React.FC<Props> = ({ costResult, parts, masterAttachments, uniqueId, validationStatus, showUnitPrice }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const s = store.getState();
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const isPriceLoading = useSelector(selectProCalcPriceLoading);
    const priceDetails = useSelector(selectProCalcPriceDetails);

    const getColor = (part: CalculationPartResource) => {
        const parameters = part?.setParameters || [];
        const colorSystem = parameters.find((parameter) => parameter.name.startsWith(COLOR_SYSTEMS_GUI));
        if (!colorSystem) return '';
        const colorValue = parameters.find((parameter) => parameter.name === colorSystem.value)?.value;
        return `${colorValue} (${wT(colorSystem.value, s)})`;
    };

    const getQuantity = (part: CalculationPartResource) => {
        const parameters = part?.setParameters || [];
        const quantity = parameters.find((parameter) => parameter.name === QUANTITY)?.value;
        return parseInt(quantity);
    };

    const handleFinish = () => {
        navigate(nav.CALCULATIONS.sub.VIEW.path.replace(':id', uniqueId));
    };

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        parts?.length > 0 &&
        (isSmallScreen ? (
            <>
                <SwipeableDrawer
                    anchor="bottom"
                    open={isExpanded}
                    onClose={() => setIsExpanded(false)}
                    onOpen={() => setIsExpanded(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    disableDiscovery={true}
                    ModalProps={{ keepMounted: true }}
                    SwipeAreaProps={{
                        onTouchStart: (event) => {
                            event.stopPropagation();
                            setIsExpanded(true);
                        }
                    }}
                    PaperProps={{
                        sx: {
                            maxHeight: `calc(95% - ${drawerBleeding}px)`,
                            overflow: 'visible'
                        }
                    }}
                >
                    <SwipeableDrawerHeader sx={{ top: -drawerBleeding }}>
                        <Puller />
                        <Box display="flex">
                            <Typography sx={{ p: 2, color: 'text.secondary' }}>
                                {parts.length} {parts.length === 1 ? t('part') : t('parts')}
                            </Typography>

                            <Stack ml={'auto'} mr={2} pl={2} justifyContent="center">
                                <Typography color="primary" variant="h3" position="relative">
                                    {priceDetails.formattedPriceNonRounded}

                                    {isPriceLoading && (
                                        <ProgressOverlap sx={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                                            <CircularProgress color="inherit" size={25} />
                                        </ProgressOverlap>
                                    )}
                                </Typography>
                            </Stack>
                        </Box>
                    </SwipeableDrawerHeader>
                    <SwipeableDrawerContent mx={-2}>
                        <Stack maxHeight="100%" height="100%" overflow="hidden">
                            <SummarizedItemList maxHeight="100%" mb="auto" p={2} pt={1} overflow="auto">
                                {parts.map((part, index) => (
                                    <SummarizedItem key={part.id + '' + index} part={part} costResult={costResult} color={getColor(part)} quantity={getQuantity(part)} showUnitPrice={showUnitPrice} />
                                ))}
                            </SummarizedItemList>
                            <Button variant="contained" sx={{ mt: 2, mx: 2 }} onClick={handleFinish} disabled={validationStatus === INVALID}>
                                {t('finish')}
                            </Button>
                        </Stack>
                    </SwipeableDrawerContent>
                </SwipeableDrawer>
            </>
        ) : (
            <CardContainer>
                {!(isExpanded && isOpen) && (
                    <StyledIconButton onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? (
                            <ArrowDownwardTwoToneIcon sx={{ color: theme.colors.alpha.black[50], fontSize: '28px' }} />
                        ) : (
                            <ArrowUpwardTwoToneIcon sx={{ color: theme.colors.alpha.black[50], fontSize: '28px' }} />
                        )}
                    </StyledIconButton>
                )}
                {isOpen && (
                    <StyledCard id={'summarizeCalcBar'} sx={{ p: 0, ml: !(isExpanded && isOpen) ? 2 : 0 }} className={isExpanded ? 'open' : 'closed'}>
                        {isExpanded ? (
                            <Box display="flex" flexGrow={1}>
                                <Stack flexGrow={1} height="100%">
                                    <Stack direction="row" alignItems="center" spacing={4} p={4} pb={1.5}>
                                        <Typography variant="h3">{t('summary')}</Typography>
                                        <Stack direction="row" flexGrow={1} ml={theme.spacing(2) + ' !important'}>
                                            <AttachmentsSummary masterAttachments={masterAttachments} parts={parts} />
                                            <GeneralMessagesSummary costResult={costResult} />
                                        </Stack>

                                        <Stack ml="auto">
                                            <Typography variant="caption" lineHeight="10px">
                                                {t('price')}
                                            </Typography>
                                            <Typography color="primary" variant="h3" position="relative">
                                                {priceDetails.formattedPriceNonRounded}
                                                {isPriceLoading && (
                                                    <ProgressOverlap sx={{ justifyContent: 'center', pl: 0.5 }}>
                                                        <CircularProgress color="inherit" size={22} />
                                                    </ProgressOverlap>
                                                )}
                                            </Typography>
                                        </Stack>

                                        {/* <Stack>
                                        <Typography variant="caption" lineHeight="10px">{t('goodsCarrier')}</Typography>
                                        <Typography color="primary" variant="h3">400 €</Typography>
                                    </Stack> */}

                                        <Button variant="contained" sx={{ mr: 2 }} onClick={handleFinish}>
                                            {t('finish')}
                                        </Button>
                                    </Stack>
                                    <SummarizedItemList maxHeight="28vh" p={4} pt={1} overflow="auto">
                                        {parts.map((part, index) => (
                                            <SummarizedItem
                                                key={part.id + '' + index}
                                                part={part}
                                                costResult={costResult}
                                                color={getColor(part)}
                                                quantity={getQuantity(part)}
                                                showUnitPrice={showUnitPrice}
                                            />
                                        ))}
                                    </SummarizedItemList>
                                </Stack>
                                <Divider sx={{ background: `${theme.colors.alpha.black[10]}` }} orientation="vertical" flexItem />
                                <IconButton sx={{ borderRadius: 0 }} onClick={() => setIsExpanded(!isExpanded)}>
                                    <ZoomInMapTwoToneIcon sx={{ m: 1 }} />
                                </IconButton>
                            </Box>
                        ) : (
                            <>
                                {parts.map(
                                    (part, index) =>
                                        index < 3 && (
                                            <Stack direction="row" overflow="hidden" key={part.id + '' + index}>
                                                <Item>
                                                    <img src={part.thumbnailUrl || part.itemImageUrl} alt="Item image" />
                                                    <Stack ml={1} justifyContent="center" maxWidth="12rem" overflow="hidden">
                                                        <Typography variant="h5" gutterBottom noWrap>
                                                            {getQuantity(part)} × {part.userDefinedPartName || wT(part.itemName, s)}
                                                        </Typography>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {part.geometryDataStringForUser}
                                                        </Typography>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {getColor(part)}
                                                        </Typography>
                                                    </Stack>
                                                </Item>
                                                <Divider sx={{ background: `${theme.colors.alpha.black[10]}` }} orientation="vertical" flexItem />
                                            </Stack>
                                        )
                                )}
                                {parts.length > 3 && (
                                    <>
                                        <Stack mx={1} justifyContent="center">
                                            <Typography variant="subtitle2" noWrap>
                                                + {parts.length - 3} {parts.length - 3 === 1 ? t('part') : t('parts')}
                                            </Typography>
                                        </Stack>
                                        <Divider sx={{ background: `${theme.colors.alpha.black[10]}` }} orientation="vertical" flexItem />
                                    </>
                                )}

                                <Stack ml={'auto'} mr={2} pl={2} justifyContent="center">
                                    <Typography color="primary" variant="h3" position="relative">
                                        {priceDetails.formattedPriceNonRounded}

                                        {isPriceLoading && (
                                            <ProgressOverlap sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CircularProgress color="inherit" size={35} />
                                            </ProgressOverlap>
                                        )}
                                    </Typography>
                                </Stack>
                                {/* <Button variant="contained" sx={{ mr: 2 }} onClick={handleFinish}>{t('finish')}</Button> */}

                                <Divider sx={{ background: `${theme.colors.alpha.black[10]}` }} orientation="vertical" flexItem />

                                <IconButton sx={{ alignSelf: 'stretch', borderRadius: 0 }} onClick={() => setIsExpanded(!isExpanded)}>
                                    <ZoomOutMapTwoToneIcon sx={{ m: 1 }} />
                                </IconButton>
                            </>
                        )}
                    </StyledCard>
                )}
            </CardContainer>
        ))
    );
};

export default SummarizeCalcBar;
