import { KeyboardArrowLeftTwoTone, OpenInBrowserTwoTone } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  BoughtModulesResource,
  CustomerTypeResource,
  MarketplaceWizardProfileResource,
  NewGeneralFileDto,
  PriceManipulationParameterValueDto,
  PriceManipulationParameterValueResource,
  RegularCustomerResource,
  RegularCustomerRestControllerService,
  UserResource
} from 'src/backend/market';
import PriceParameterInput from 'src/components/input/PriceParameterInput/PriceParameterInput';
import useDialog from 'src/hooks/useDialog';
import { RootState } from 'src/redux/store';
import {
  addCustomer,
  addCustomerWithLogo,
  deleteCustomer,
  getRegularCustomerWizardUrl,
  reloadCustomers,
  updateCustomer,
  updateCustomerStatus
} from 'src/redux/thunks/customers.thunk';
import { LoadingImageBox, StyledCard } from './Customer.styles';
import { nav } from 'src/statics/navigations';
import PageHeader from 'src/components/display/PageHeader';
import CountrySelect from 'src/components/input/CountrySelect';

interface ICustomer {
  types: { [key: number]: CustomerTypeResource },
  customers: { [key: string]: RegularCustomerResource },
  loading: boolean,
  currentProfile: MarketplaceWizardProfileResource,
  user: UserResource,
  module: BoughtModulesResource
}

export interface IFormikPriceParam {
  [key: string]: { value: string, actualPriceParameter: PriceManipulationParameterValueResource };
}

export interface IFormik {
  companyName: string,
  contactPersonFirstName: string;
  contactPersonLastName: string;
  email: string;
  phone: string;
  street: string;
  houseNumber: string;
  city: string;
  zipcode: string;
  country: string;
  notes: string;
  customerTypeId: number;
  logo: string;
  overwrittenPriceValues: IFormikPriceParam;
}

//HELPER
export function transformPriceParameterValuesForFormik(customer: RegularCustomerResource): IFormikPriceParam {
  if (!customer?.customerType?.priceParameterValues) return;
  return customer.customerType.priceParameterValues.reduce((acc, value: PriceManipulationParameterValueResource) => {
    let val = value.value;
    const overwrittenParam = customer.overwrittenPriceValues.find(x => x.parameter.id === value.parameter.id);
    if (overwrittenParam) {
      val = overwrittenParam.value;
    }
    return { ...acc, [value.parameter.name]: { value: val, actualPriceParameter: value } };
  }, {} as IFormikPriceParam);
}

export function transformFormikToPriceValue(formikValue: IFormikPriceParam, types: { [key: number]: CustomerTypeResource }, selectedCustomerTypeId: number): Array<PriceManipulationParameterValueDto> {
  const overwrittenValues: Array<PriceManipulationParameterValueDto> = [];
  const selectedTypes = _.find(types, { id: selectedCustomerTypeId });
  _.forEach(formikValue, (value, key) => {
    const actualParam = selectedTypes.priceParameterValues.find(x => x.parameter.name === key);
    if (actualParam === null) {
      return;
    }
    if (actualParam?.value == value?.value) return;
    overwrittenValues.push({
      parameterValue: value.value,
      parameterId: actualParam.parameter.id
    } as PriceManipulationParameterValueDto);
  });
  console.log('Overwritten Values: ', overwrittenValues);
  return overwrittenValues;
}

const Customer: React.FC<ICustomer> = ({ types, customers, loading, currentProfile, user, module }) => {
  //Usings
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCustomerLoadingError, setCustomerLoadingError] = useState<boolean>(false);

  const isAddView = Boolean(id === 'new');

  const currentCustomer = useMemo<RegularCustomerResource>(() => {
    if (user && user.id) {
      if (_.size(customers) === 0 && !isAddView) {
        dispatch(reloadCustomers(id, (success) => {
            if(!success) setCustomerLoadingError(true);
        }));
      }
      return _.values(customers).find(x => x.id + '' === id);
    }
  }, [customers, user]);

  //Formik Validation
  const phoneNumberRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  let baseInputFields = {
    companyName: currentCustomer?.companyName ?? '',
    contactPersonFirstName: currentCustomer?.contactPersonFirstName ?? '',
    contactPersonLastName: currentCustomer?.contactPersonLastName ?? '',
    email: currentCustomer?.email ?? '',
    phone: currentCustomer?.phone ?? '',
    street: currentCustomer?.street ?? '',
    houseNumber: currentCustomer?.houseNumber ?? '',
    city: currentCustomer?.city ?? '',
    zipcode: currentCustomer?.zipcode ?? '',
    notes: currentCustomer?.notes ?? '',
    customerTypeId: currentCustomer?.customerType.id ?? '',
    logo: currentCustomer?.logo ?? '',
    overwrittenPriceValues: transformPriceParameterValuesForFormik(currentCustomer),
    country: currentCustomer?.country ?? ''
  };
  const ASYNC_VALIDATION_TIMEOUT = 500;
  const mailValidationAsync = async (value, resolve) => {
    if (value === currentCustomer?.email ?? '') {
      resolve(true);
    } else {
      try {
        const response = await RegularCustomerRestControllerService.checkIfMailAlreadyUsed1(user.id, { mail: value });
        if (response.used) {
          resolve(false);
        } else {
          resolve(true);
        }
      } catch (e) {
        resolve(true);
      }
    }
  };
  const mailValidationDebounced = _.debounce(mailValidationAsync, ASYNC_VALIDATION_TIMEOUT);
  const companyNameValidationAsync = async (value, resolve) => {
    if (value === currentCustomer?.companyName ?? '') {
      resolve(true);
    } else {
      if (value === '' || !value) {
        resolve(false);
      } else {
        try {
          const result = await RegularCustomerRestControllerService.isRegularCustomerNameTaken(user.id, { name: value });
          resolve(!result.taken);
        } catch (e) {
          resolve(false);
        }
      }
    }
  };
  const companyValidationDebounced = _.debounce(companyNameValidationAsync, ASYNC_VALIDATION_TIMEOUT);
  const baseValidationSchema = {
    companyName: Yup.string().test('checkCompanyNameDuplicate', t('Dieser Firmenname ist bereits vergeben!'), value => new Promise(resolve => companyValidationDebounced(value, resolve))).required(t('Bitte gib einen Firmennamen ein!')),
    contactPersonFirstName: Yup.string().required(t('Bitte gib einen Vornamen ein!')),
    contactPersonLastName: Yup.string().required(t('Bitte gib einen Nachnamen ein!')),
    email: Yup.string().email(t('Bitte gib eine gültige E-Mail Adresse ein!')).test('email-duplicate', t('Diese E-Mail Adresse wird bereits verwendet!'), value => new Promise(resolve => mailValidationDebounced(value, resolve))).required(t('Bitte gib eine E-Mail Adresse ein!')),
    phone: Yup.string().matches(phoneNumberRegEx, t('Bitte gib eine gültige Telefonnummer ein!')).required(t('Bitte gib eine Telefonnummer ein!')),
    street: Yup.string().required(t('Bitte gib eine Straße ein!')),
    houseNumber: Yup.string().required(t('Bitte gib eine Hausnummer ein!')),
    city: Yup.string().required(t('Bitte gib eine Stadt ein!')),
    zipcode: Yup.number().required(t('Bitte gib eine Postleitzahl ein!')).positive(t('Muss Positiv sein!')).integer(t('Muss eine ganze Zahl sein!')).typeError(t('Muss eine Zahl sein!')),
    notes: Yup.string(),
    logo: Yup.string(),
    country: Yup.string().required(t('Bitte wähle ein Land aus!')),
    customerTypeId: _.values(types).length === 0 ? Yup.number().nullable() : Yup.number().required(t('Bitte wähle einen Kundentyp aus!'))
  };

  //STATE
  const [customerURL, setCustomerURL] = useState<string>();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
  const [logoLoading, setLogoLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<{ name: string, imageURL: any }>();
  const menuOpen = Boolean(menuAnchor);

  useEffect(() => {
    if (isAddView)
      return;
    const nId = Number.parseInt(id);
    getRegularCustomerWizardUrl(nId, window.location.origin).then(url => {
      console.log(url);
      setCustomerURL(url);
    });
  }, []);

  //RENDER FUNCTIONS
  function renderLogo(values) {
    function getLogo() {
      if (isAddView && imageUrl?.imageURL) {
        return imageUrl.imageURL;
      } else if (currentCustomer?.logo) {
        return currentCustomer.logo;
      } else {
        return `${process.env.PUBLIC_URL}/static/images/avatars/blank-profile-picture.svg`;
      }
    }

    return <>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant={'caption'}>{t('firmenlogo')}</Typography>
        <Box alignItems={'center'} gap={2} display={'flex'}>
          {logoLoading ? <LoadingImageBox>
              <CircularProgress />
            </LoadingImageBox> :
            <img alt={'Kundenlogo'} style={{
              borderRadius: '10px',
              maxHeight: '125px',
              maxWidth: '300px'
            }}
                 loading={'lazy'}
                 src={getLogo()} />}
          <input disabled={logoLoading} type='file'
                 accept='image/png, image/jpg, image/jpeg'
                 onChange={onImageChange} />
        </Box>
      </Box>
    </>;
  }

  function renderInfoCard() {
    return <Formik
      validationSchema={Yup.object().shape(baseValidationSchema)}
      validateOnBlur
      validateOnChange
      initialValues={baseInputFields}
      onSubmit={async (values) => {
        console.log('Submit clicked');
        if (isAddView) {
          handleAdd(values);
        } else {
          handleUpdate({
            ...values,
            inquiryCount: currentCustomer?.inquiryCount,
            salesVolume: currentCustomer?.salesVolume
          });
        }
      }}>
      {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values,
          isValid
        }) => {
        return (
          <form onSubmit={handleSubmit}>
            <StyledCard sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Stack spacing={2}>
                    <Typography variant={'h4'}>{t('infosZumKunden')}</Typography>
                    {renderLogo(values)}
                    <TextField fullWidth
                               id={'c_companyName'}
                               label={t('firma')}
                               name='companyName'
                               onChange={handleChange}
                               value={values.companyName}
                               onBlur={handleBlur}
                               error={Boolean(errors.companyName && touched.companyName)}
                               helperText={errors.companyName && touched.companyName && errors.companyName}
                    />
                    <Grid container gap={1} wrap={'nowrap'}>
                      <Grid item xs={8}>
                        <TextField fullWidth label={t('CITY')}
                                   value={values.city}
                                   name='city'
                                   id={'c_city'}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   error={Boolean(errors.city && touched.city)}
                                   helperText={errors.city && touched.city && errors.city}
                        />

                      </Grid>
                      <Grid item xs={4}>
                        <TextField fullWidth label={t('ZIPCODE')}
                                   value={values.zipcode}
                                   onChange={handleChange}
                                   name='zipcode'
                                   id={'c_zipcode'}
                                   onBlur={handleBlur}
                                   error={Boolean(errors.zipcode && touched.zipcode)}
                                   helperText={errors.zipcode && touched.zipcode && errors.zipcode}
                        />
                      </Grid>
                    </Grid>
                    <Grid container gap={1} wrap={'nowrap'}>
                      <Grid item xs={8}>
                        <TextField fullWidth label={t('STREET')}
                                   value={values.street}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   name='street'
                                   id={'c_street'}
                                   error={Boolean(errors.street && touched.street)}
                                   helperText={errors.street && touched.street && errors.street}
                        />

                      </Grid>
                      <Grid item xs={4}>
                        <TextField fullWidth label={t('HOUSE_NUMBER')}
                                   value={values.houseNumber}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   name='houseNumber'
                                   id={'c_houseNumber'}
                                   error={Boolean(errors.houseNumber && touched.houseNumber)}
                                   helperText={errors.houseNumber && touched.houseNumber && errors.houseNumber}
                        />

                      </Grid>
                    </Grid>
                    <CountrySelect
                        value={values.country}
                        name="country"
                        helperText={errors.country && touched.country && errors.country}
                        onChange={(event, selectedItem, reason) => {
                            if(reason !== 'selectOption' && reason !== 'clear') return;
                            setFieldValue('country', selectedItem || '');
                        }}
                    />
                    {_.values(types).length === 0 ? <div /> :
                      <>
                        <Divider />
                        <Typography gutterBottom={false}
                                    variant={'caption'}>{t('kundentyp')}</Typography>
                        <PriceParameterInput types={types} />
                      </>}
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Stack spacing={2}>
                    <Typography variant={'h4'}>{t('infosZumAnsprechpartner')}</Typography>
                    <TextField fullWidth label={t('FIRSTNAME')}
                               value={values.contactPersonFirstName}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               id={'c_contactFN'}
                               name='contactPersonFirstName'
                               error={Boolean(errors.contactPersonFirstName && touched.contactPersonFirstName)}
                               helperText={errors.contactPersonFirstName && touched.contactPersonFirstName && errors.contactPersonFirstName}
                    />
                    <TextField fullWidth label={t('LASTNAME')}
                               value={values.contactPersonLastName}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               id={'c_contactLN'}
                               name='contactPersonLastName'
                               error={Boolean(errors.contactPersonLastName && touched.contactPersonLastName)}
                               helperText={errors.contactPersonLastName && touched.contactPersonLastName && errors.contactPersonLastName}
                    />
                    <TextField fullWidth label={t('EMAIL')}
                               value={values.email}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               name='email'
                               id={'c_contactEmail'}
                               error={Boolean(errors.email && touched.email)}
                               helperText={errors.email && touched.email && errors.email}
                    />
                    <TextField fullWidth label={t('telefon')}
                               value={values.phone}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               id={'c_contactPhone'}
                               name='phone'
                               error={Boolean(errors.phone && touched.phone)}
                               helperText={errors.phone && touched.phone && errors.phone}
                    />

                    <TextField fullWidth label={t('notes')}
                               value={values.notes}
                               multiline
                               rows={5}
                               id={'c_notes'}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               name='notes'
                               error={Boolean(errors.notes && touched.notes)}
                               helperText={errors.notes && touched.notes && errors.notes}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: 3, justifyContent: 'flex-end' }}>
                <Button type={'submit'} variant={'contained'}
                        color={'primary'}
                        id={'customer-submit'}
                        disabled={handleDisableCheck({ ...values })}>
                  {isAddView ? t('anlegen') : t('SAVE')}
                </Button>
              </Grid>
            </StyledCard>
          </form>
        );
      }}

    </Formik>
      ;
  }

  function renderStatisticCard() {
    return <StyledCard>
      <Stack divider={<Divider />}>
        <Box p={2}>
          <Typography variant='h4'>{t('letzteAktivitten')}</Typography>
        </Box>
        <Box p={2}>
          <Stack direction={'row'} spacing={2}>
            <Avatar sx={{ height: '50px', width: '50px', bgcolor: '#f2f5f9' }}>
              <ShoppingBagTwoToneIcon color={'primary'} />
            </Avatar>
            <Stack>
              <Typography variant='h4'>{t('anzahlAnfragen')}</Typography>
              <Typography variant='caption' sx={{ marginTop: '15px' }}>{t('total')}</Typography>
              {renderNullable(currentCustomer?.inquiryCount)}
            </Stack>
          </Stack>
        </Box>
        <Box p={2}>
          <Stack direction={'row'} spacing={2}>
            <Avatar sx={{ height: '50px', width: '50px', bgcolor: '#f2f5f9' }}>
              <PaidTwoToneIcon color={'primary'} />
            </Avatar>
            <Stack>
              <Typography variant='h4'>{t('gesamtanfragewert')}</Typography>
              <Typography variant='caption' sx={{ marginTop: '15px' }}>{t('total')}</Typography>
              {renderMoney(currentCustomer?.salesVolume)}
            </Stack>
          </Stack>
        </Box>
        <Box p={2}>
          <Stack direction={'row'} spacing={2}>
            <Avatar sx={{ height: '50px', width: '50px', bgcolor: '#f2f5f9' }}>
              <OpenInBrowserTwoTone color={'primary'} />
            </Avatar>
            <Stack>
              <Typography variant='h4'>{t('wizardGeffnet')}</Typography>
              <Typography variant='caption' sx={{ marginTop: '15px' }}>{t('total')}</Typography>
              {renderNullable(currentCustomer?.wizardOpenedCount)}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </StyledCard>;
  }

  function renderHeader() {
    return <>
        <PageHeader
            sx={{ mb: 4 }}
            mainTitle={<>
                {!isAddView ? baseInputFields.companyName : t('kundenhinzufuegen')}
                {(currentCustomer?.dataChangedByRegularCustomer ?? false) && 
                    <Chip color={'info'} variant={'outlined'} label={t('editedByCustomer')} />}
            </>}
            secondaryTitle={t('kundendetails')}
            handleBack={handleBack}>
                {(!isAddView && currentCustomer) &&
                    (isSmallScreen ?
                        <>
                            <IconButton aria-label="settings" onClick={handleMenuOpen}>
                                <MoreVertTwoToneIcon />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchor}
                                open={menuOpen}
                                onClose={handleMenuClose}
                            >
                                {
                                    (currentCustomer?.state === 'ACTIVE') ?
                                    <MenuItem onClick={handleSwitchCustomerState}>{t('deactivateCustomer')}</MenuItem> :
                                    <MenuItem onClick={handleSwitchCustomerState}>{t('activateCustomer')}</MenuItem>
                                }
                                <ListSubheader sx={{ my: 1 }}>
                                    <Typography fontWeight="bold">{t('copyCustomerURL')}</Typography>
                                </ListSubheader>
                                <MenuItem sx={{ ml: 2 }} onClick={handleOpenLink}>{t('openLink')}</MenuItem>
                                <MenuItem sx={{ ml: 2 }} onClick={handleCopyLink}>{t('copyLink')}</MenuItem>
                                <MenuItem sx={{ ml: 2 }} onClick={handleWriteMail}>{t('writeEMail')}</MenuItem>
                            </Menu>
                        </>
                        :
                        <Stack direction={'row'} justifyContent={'end'}>
                            {
                                (currentCustomer?.state === 'ACTIVE') ?
                                <Button onClick={() => handleSwitchCustomerState()} sx={{ mr: 1 }}
                                        variant={'contained'}
                                        color={'error'}>{t('deactivateCustomer')}</Button> :
                                <Button onClick={() => handleSwitchCustomerState()} sx={{ mr: 1 }}
                                        variant={'contained'}
                                        color={'success'}>{t('activateCustomer')}</Button>
                            }

                            <Button sx={{ mr: 1 }} variant={'contained'} color={'secondary'}
                                    onClick={handleMenuOpen}>{t('copyCustomerURL')}</Button>
                            <Menu
                                anchorEl={menuAnchor}
                                open={menuOpen}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleOpenLink}>{t('openLink')}</MenuItem>
                                <MenuItem onClick={handleCopyLink}>{t('copyLink')}</MenuItem>
                                <MenuItem onClick={handleWriteMail}>{t('writeEMail')}</MenuItem>
                            </Menu>
                            <IconButton color={'error'} onClick={handleDelete}>
                                <DeleteTwoToneIcon fontSize={'large'} />
                            </IconButton>
                        </Stack>
                    )
                }
        </PageHeader>
    </>
  }

  function renderNullable(value) {
    return (
      <Typography variant={'h3'}>{value ?? '-'}</Typography>
    );
  }

  function renderMoney(param: number) {
    if (param == null) return <Typography variant={'body2'}>-</Typography>;
    return (
      <Typography variant={'h3'}>{new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'EUR'
      }).format(param)}</Typography>
    );
  }

  function renderBuyAlert() {
    if (!isAddView) return <span />;
    const allowedUsers = module.allowedRegularCustomers.maxAllowedCount - module.allowedRegularCustomers.currentCount;
    if (module.allowedRegularCustomers.furtherUsersAllowed && allowedUsers <= 0) {
      return <span />;
    }
    if (module.allowedRegularCustomers.furtherUsersAllowed) {
      return <Alert severity={'info'} sx={{ fontWeight: 'bold', my: 1 }}>
        {t('duKannstNochStammkundenHinzufgen', { allowedUsers: allowedUsers })}
      </Alert>;
    } else {
      return <Alert severity={'error'} sx={{ fontWeight: 'bold' }}>
        {t('duKannstKeineStammkundenMehrHinzufgenWennDuMehrSta')}
      </Alert>;
    }
  }

  function renderWarnings() {
    if (_.values(types).length === 0) {
      return <Alert severity={'error'}>
        <AlertTitle><strong>{t('keineKundentypenHinterlegt')}</strong></AlertTitle>
        {t('bitteWendeDichAnDasSurfacesolutionsSupportTeam')}
      </Alert>;
    }
  }

  function renderCustomerLoadingError() {
      return <Alert severity={'error'}>
        <AlertTitle><strong>{t('customerLoadingErrorTitle')}</strong></AlertTitle>
        {t('customerLoadingErrorExplanation')}
      </Alert>;
  }

  //HANDLER
  const handleDisableCheck = (customer): boolean => {
    return _.isEqual(customer, baseInputFields);
  };
  const handleBack = (customer) => {
    navigate(nav.TOOLS.sub.CUSTOMERS.path);
  };
  const handleDelete = (customer) => {
    return dialog.openDialog({
      severity: 'error',
      okCallback(): void {
        dispatch(deleteCustomer(parseInt(id), () => {
          const key = enqueueSnackbar(t('customerDeleted'), {
            variant: 'success',
            action: <IconButton color='inherit'
                                onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
          });
          handleBack(customer);
        }));
        console.log('delete customer', parseInt(id));
        dialog.closeDialog();
      },
      title: t('kundenLschen2'),
      okText: t('kundenLschen'),
      component: <Typography variant={'body2'}>{t('bistDuSicherDasDuDiesenKundenLschenMchtest')}</Typography>
    });
  };
  const handleAdd = (customer) => {
    return dialog.openDialog({
      severity: 'primary',
      okCallback(): void {
        customer.overwrittenPriceValues = transformFormikToPriceValue(customer.overwrittenPriceValues, types, customer.customerTypeId);
        if (imageUrl) {
          dispatch(addCustomerWithLogo(customer, imageUrl.name, () => {
            const key = enqueueSnackbar(t('customerCreated'), {
              variant: 'success',
              action: <IconButton color='inherit'
                                  onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
            });
            handleBack(customer);
          }));
        } else {
          dispatch(addCustomer(customer, () => {
            const key = enqueueSnackbar(t('customerCreated'), {
              variant: 'success',
              action: <IconButton color='inherit'
                                  onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
            });
            handleBack(customer);
          }));
        }
        dialog.closeDialog();
      },
      title: t('kundenAnlegen2'),
      okText: t('kundenAnlegen'),
      component: <Typography variant={'body2'}>{t('bistDuSicherDasDuDiesenKundenAnlegenMchtest')}</Typography>
    });
  };
  const handleUpdate = (customer) => {
    console.log('update Called, ', customer);

    customer.overwrittenPriceValues = transformFormikToPriceValue(customer.overwrittenPriceValues, types, customer.customerTypeId);

    dispatch(updateCustomer(parseInt(id), customer, () => {
      const key = enqueueSnackbar(t('customerChanged'), {
        variant: 'success',
        action: <IconButton color='inherit' onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
      });
    }));
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleOpenLink = () => {
    if (!currentProfile.websiteUrl) {
      showErrorMessage();
      return;
    }
    window.open(customerURL, '_blank').focus();
    const key = enqueueSnackbar(t('customerURLOpened'), {
      variant: 'success',
      action: <IconButton color='inherit' onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
    });
    handleMenuClose();
  };
  const handleCopyLink = () => {
    if (!currentProfile.websiteUrl) {
      showErrorMessage();
      return;
    }
    navigator.clipboard.writeText(customerURL);
    const key = enqueueSnackbar(t('customerURLCopied'), {
      variant: 'success',
      action: <IconButton color='inherit' onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
    });
    handleMenuClose();
  };
  const handleWriteMail = () => {
    if (!currentProfile.websiteUrl) {
      showErrorMessage();
      return;
    }
    let customer;
    if (user && user.id) {
      customer = _.values(customers).find(x => x.id + '' === id);
    }
    const mailToLink = t('mailToRegularCustomer', {
      defaultValue: `mailto:${customer.email}?subject=Kalkulationstool Stammkunde&body=Hallo ${customer.name},%0D%0A%0D%0Ahier ist der Link zu deinem Stammkundenbereich:%0D%0A${customerURL}%0D%0A%0D%0AViele Grüße,%0D%0A${user.firstName}`,
      email: customer.email,
      contactPersonFirstName: customer.contactPersonFirstName,
      contactPersonLastName: customer.contactPersonLastName,
      customerURL: customerURL,
      contactPersonName: currentProfile.contactPersonName
    });
    window.location.href = mailToLink;
    handleMenuClose();
  };
  const showErrorMessage = () => {
    handleMenuClose();

    dialog.openDialog({
      title: t('upsKeineWebsiteHinterlegt'),
      component: <Typography variant={'body1'}><Trans
        i18nKey='leiderKannstDuKeinenKundenlinkGenerierenGeheZumHin'
        components={{
          s: <strong />
        }}
      /></Typography>,
      okText: t('okay'),
      severity: 'error',
      showCancel: false,
      okCallback: () => dialog.closeDialog()
    });
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoLoading(true);
      let img = event.target.files[0];
      RegularCustomerRestControllerService
        .getRegularCustomerImageUploadUrl({ fileType: img.type } as NewGeneralFileDto)
        .then(response => {
          const imageURL = response.presignedUrl;
          const requestMetadata = {
            method: 'PUT',
            headers: {
              'Content-Type': img.type
            },
            body: img
          };
          fetch(imageURL, requestMetadata).then(_ => {
            if (isAddView) {
              const fr = new FileReader();
              fr.addEventListener('load', () => {
                setImageUrl({ name: response.assignedImageName, imageURL: fr.result });
                setLogoLoading(false);
              });
              fr.readAsDataURL(img);


            } else {
              RegularCustomerRestControllerService
                .setLogo1(
                  user.id, currentCustomer.id,
                  { name: response.assignedImageName } as NewGeneralFileDto
                ).then(_ => {
                dispatch(reloadCustomers(currentCustomer.id, (success) => {
                  if(!success) setCustomerLoadingError(true);
                  setLogoLoading(false);
                  const key = enqueueSnackbar(t('customerChanged'), {
                    variant: 'success',
                    action: <IconButton color='inherit'
                                        onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
                  });
                }));
              });
            }
          });
        });
    }
  };
  const handleSwitchCustomerState = () => {
    const active = Boolean(currentCustomer.state === 'ACTIVE');
    dispatch(updateCustomerStatus(
      currentCustomer.id,
      active ? 'INACTIVE' : 'ACTIVE',
      () => {
        const key = enqueueSnackbar(t('stateUpdated'), {
          variant: 'success',
          action: <IconButton color='inherit'
                              onClick={() => closeSnackbar(key)}><CloseRoundedIcon /></IconButton>
        });
      }
    ));
  };

  //Customer Return
  return (!currentCustomer && !isAddView) ?
        <>
            {renderHeader()}
            {isCustomerLoadingError
                ? renderCustomerLoadingError()
                : <Box sx={{ marginTop: 5 }}><CircularProgress /></Box>}
        </>
        :
        <>
            <Helmet><title>{t('CustomerDetailTitle')} {!isAddView ? baseInputFields.companyName : t('new')}</title></Helmet>
            {renderHeader()}
            {_.values(types).length !== 0 && renderBuyAlert()}
            {renderWarnings()}
            {(_.values(types).length !== 0 || !isAddView) &&
                (module.allowedRegularCustomers.furtherUsersAllowed || !isAddView) && <Grid container spacing={2}>
                <Grid item lg={true} xs={true}>
                    {renderInfoCard()}
                </Grid>
                {isAddView ? null : <Grid item lg={4} xs={12}>
                    {renderStatisticCard()}
                </Grid>}
                </Grid>
            }
        </>
};

const mapStateToProps = (state: RootState) => {
  return {
    types: state.constants.customerTypes,
    customers: state.customers.customers,
    loading: state.customers.loading,
    currentProfile: state.wizardProfile.currentProfile,
    user: state.user.currentUser,
    module: state.constants.moduleManagement
  };
};

export default connect(mapStateToProps)(Customer);
