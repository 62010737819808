import {Box, Button, Card, Link, Stack, styled, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import React from "react";
import {CopyBlock, dracula} from "react-code-blocks";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {MarketplaceWizardProfileResource} from "src/backend/market";
import useDialog from "src/hooks/useDialog";
import {RootState} from "src/redux/store";

const StyledCodeBlock = styled(Box)`
  > * > * > * {
    background-color: transparent !important;
    font-family: 'IBM Plex Mono', monospace !important;
  }
`

const WizardSnippet = (props: { profile: MarketplaceWizardProfileResource }) => {
    const dialog = useDialog();
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(props.profile.productionSnippet);
        dialog.closeDialog();
        enqueueSnackbar(t("Snippet kopiert!"), {variant: "success"});
    }

    const openWizardHelper = () => {
        dialog.openDialog({
            title: t('wieBindeIchDenWizardEin'),
            okText: t('codeKopieren'),
            cancelText: t('okay'),
            component: <Typography variant={"body1"}>
                <Trans
                    i18nKey="addWizardSnippet"
                    components={{
                        br: <br/>,
                        b: <b/>,
                    }}
                /> <Link href={"mailto:support@surface-solutions.pro"}
                         color={"inherit"}>support@surface-solutions.pro</Link>
            </Typography>,
            okCallback: () => {
                copyToClipboard();
            },
        })
    }

    return <Card sx={{height: "100%", display: "flex", flexDirection: "column"}}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Box pt={2} pl={3} pb={1} fontWeight='bold' fontSize='14pt'>
                {t('wizardSnippet')}
            </Box>
            <Button onClick={openWizardHelper} sx={{mr: 3}}>{t('wieBindeIchDenWizardEin')}</Button>
        </Stack>
        <StyledCodeBlock style={{flex: 1}}>
            <CopyBlock
                style={{height: "100%"}}
                text={props.profile?.productionSnippet ?? ""}
                language="html"
                theme={dracula}
                showLineNumbers
                wrapLines={true}
                codeBlock
            />

        </StyledCodeBlock>
    </Card>
}
const mapStateToProps = (state: RootState) => {
    return {
        profile: state.wizardProfile.currentProfile
    }
}
export default connect(mapStateToProps)(WizardSnippet);
