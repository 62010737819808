import { AdminPanelSettingsTwoTone, PersonAddTwoTone } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { BoughtModulesResource, UserResource } from '../../backend/market';
import { NavLink, useNavigate } from 'react-router-dom';
import PageHeader from 'src/components/display/PageHeader';
import { nav } from 'src/statics/navigations';
import InviteUsersCard from '../../components/display/InviteUsersCard/InviteUsersCard';
import ManageUserTable from '../../components/display/ManageUserTable/ManageUserTable';
import InviteAccountDialog, { InviteFormikValues, InviteHandle } from '../../components/overlays/InviteAccount/InviteAccountDialog';
import { RootState } from '../../redux/store';
import { inviteUser, loadUsers } from '../../redux/thunks/admin.thunk';
import { startWalkthrough } from '../../redux/thunks/guidethrough.thunk';

interface IManageUsers {
    module: BoughtModulesResource;
    user: UserResource;
    companyUsersLoading: boolean;
    companyUsers: UserResource[];
}

const ManageUsers: React.FC<IManageUsers> = ({ module, user, companyUsers, companyUsersLoading }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const inviteDialogRef = useRef<InviteHandle>();
    const navigate = useNavigate();
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleBack = () => {
        navigate(nav.TOOLS.path);
    };

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(startWalkthrough('administration'));
    }, []);
    //endregion

    //region RENDER
    const renderSideNavigation = () => {
        return (
            <Card sx={{ p: 2, minWidth: 300, pt: 3 }}>
                <Typography variant={'h4'}>{t('verwaltung', { name: user.company.name })}</Typography>
                <Typography variant={'caption'}>{user.userRoleResource.roleName}</Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />

                <List>
                    <ListItem button component={NavLink} to={nav.TOOLS.sub.ADMIN.path} selected={true} sx={{ borderRadius: 1 }}>
                        <ListItemIcon color={'primary'}>
                            <AdminPanelSettingsTwoTone />
                        </ListItemIcon>
                        <ListItemText>{t('Usermanagement')}</ListItemText>
                    </ListItem>
                </List>
            </Card>
        );
    };

    function renderUserManagement() {
        return (
            <Box flex={1} height="100%">
                <Card sx={{ p: 2, height: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                    <Stack direction={{ sm: 'row' }} rowGap={1} justifyContent="space-between" alignItems="center">
                        <Typography variant={'h4'}>{t('Usermanagement')}</Typography>
                        <Button variant={'outlined'} startIcon={<PersonAddTwoTone />} disabled={!module.allowedUsers.furtherUsersAllowed} onClick={handleInviteUserClick}>
                            {t('benutzerEinladen')}
                        </Button>
                    </Stack>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                    {companyUsersLoading ? (
                        <Stack height={'100%'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Box>
                            <InviteUsersCard />
                            <ManageUserTable users={[...companyUsers, user]} />
                        </Box>
                    )}
                </Card>
            </Box>
        );
    }

    //endregion

    //region HANDLER
    const handleInviteUser = (values: InviteFormikValues) => {
        dispatch(inviteUser(values));
    };
    const handleInviteUserClick = (_) => {
        inviteDialogRef.current?.open();
    };
    //endregion

    return (
        <>
            <PageHeader mainTitle={t('verwaltung', { name: user.company.name })} secondaryTitle={user.userRoleResource.roleName} handleBack={isSmallScreen && handleBack} />
            <Stack mt={4} gap={1} direction={'row'} height={'100%'} overflow={'none'}>
                {/* {renderSideNavigation()} */}
                {renderUserManagement()}
                <InviteAccountDialog ref={inviteDialogRef} finishedCallback={handleInviteUser} />
            </Stack>
        </>
    );
};
const mapStateToProps = (state: RootState) => {
    return {
        module: state.constants.moduleManagement,
        user: state.user.currentUser,
        companyUsers: state.admin.companyUsers,
        companyUsersLoading: state.admin.companyUsersLoading
    };
};
export default connect(mapStateToProps)(ManageUsers);
