import { Autocomplete, Box, Button, Select, styled, TextField, Typography } from '@mui/material';
import MultiSelect from '../MultiSelect';

export const ParameterLabel = styled(Typography)(
    ({ theme }) => `
        color: unset;
        font-size: unset;
        line-height: unset;
        font-weight: unset;
        min-width: 0;
        max-width: 100%;
        padding: 0;
        padding-left: 0.35em;
        padding-right: 0.35em;
    `
);

export const ParameterLabelButton = styled(Button)(
    ({ theme }) => `
        color: unset;
        font-size: unset;
        line-height: unset;
        font-weight: unset;
        min-width: 0;
        max-width: 100%;
        padding: 0;
        padding-left: 0.35em;
        padding-right: 0.15em;
        transition: ${theme.transitions.create(['background-color', 'box-shadow', 'border-color'], { duration: theme.transitions.duration.short })};
        .MuiTypography-root {
            display: block;
        }
        .MuiSvgIcon-root {
            margin-top: -1px;
            font-size: 1.4em;
            transition: none;
        }
    `
);

export const StyledTextField = styled(TextField)(
    ({ theme }) => `
        .MuiInputLabel-root {
            margin-left: -0.35em;
        }
        .MuiOutlinedInput-notchedOutline legend > span {
            padding: 0;
        }
        .MuiFormHelperText-root {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: ${theme.spacing(1)};
            background: ${theme.colors.alpha.white[100]};
            box-shadow: 0 0 1px 2px ${theme.colors.alpha.black[5]}, 0 2px 3px 2px ${theme.colors.alpha.black[10]}, 0 0 10px 5px ${theme.colors.alpha.black[5]};
            border-radius: ${theme.general.borderRadius};
            margin-bottom: -10px;
            transform: translateY(100%);
            filter: drop-shadow(0 -2px 4px ${theme.colors.alpha.black[10]});
            z-index: 100;
        }
        .MuiFormHelperText-root:after {
            content: '';
            position: absolute;
            top: -9px;
            left: ${theme.spacing(2)};
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid ${theme.colors.alpha.white[100]};
        }
        & .extracted-indicator.is-extracted ~ .MuiInputAdornment-positionEnd .default-indicator {
            display: none;
        }
    `
);

export const StyledSelect = styled(Select)(
    ({ theme }) => `
        .MuiInputLabel-root {
            margin-left: -0.35em;
        }
        .MuiOutlinedInput-notchedOutline legend > span {
            padding: 0;
        }
        & + .MuiFormHelperText-root {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: ${theme.spacing(1)};
            color: ${theme.colors.error.main};
            background: ${theme.colors.alpha.white[100]};
            box-shadow: 0 0 1px 2px ${theme.colors.alpha.black[5]}, 0 2px 3px 2px ${theme.colors.alpha.black[10]}, 0 0 10px 5px ${theme.colors.alpha.black[5]};
            border-radius: ${theme.general.borderRadius};
            margin-bottom: -10px;
            transform: translateY(100%);
            filter: drop-shadow(0 -2px 4px ${theme.colors.alpha.black[10]});
            z-index: 100;
        }
        & + .MuiFormHelperText-root:after {
            content: '';
            position: absolute;
            top: -9px;
            left: ${theme.spacing(2)};
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid ${theme.colors.alpha.white[100]};
        }
        & .extracted-indicator.is-extracted + .MuiSelect-select .default-indicator {
            display: none;
        }
    `
);

export const StyledMultiSelect = styled(MultiSelect)(
    ({ theme }) => `
        .MuiInputLabel-root {
            margin-left: -0.35em;
        }
        .MuiOutlinedInput-notchedOutline legend > span {
            padding: 0;
        }
    `
);

export const StyledAutocomplete = styled(Autocomplete)(
    ({ theme }) => `
        .MuiInputLabel-root {
            margin-left: -0.35em;
        }
        .MuiOutlinedInput-notchedOutline legend > span {
            padding: 0;
        }
        .MuiFormHelperText-root {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: ${theme.spacing(1)};
            background: ${theme.colors.alpha.white[100]};
            box-shadow: 0 0 1px 2px ${theme.colors.alpha.black[5]}, 0 2px 3px 2px ${theme.colors.alpha.black[10]}, 0 0 10px 5px ${theme.colors.alpha.black[5]};
            border-radius: ${theme.general.borderRadius};
            margin-bottom: -10px;
            transform: translateY(100%);
            filter: drop-shadow(0 -2px 4px ${theme.colors.alpha.black[10]});
            z-index: 100;
        }
        .MuiFormHelperText-root:after {
            content: '';
            position: absolute;
            top: -9px;
            left: ${theme.spacing(2)};
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid ${theme.colors.alpha.white[100]};
        }
        .extracted-indicator {
            margin-left: 8px;
        }
        & .extracted-indicator.is-extracted ~ .MuiInputAdornment-positionEnd .default-indicator {
            display: none;
        }
    `
);

export const GeometryLabelCircle = styled('span')(
    ({ theme }) => `
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 1.48em;
        height: 1.48em;
        margin-left: 0.1em;
        margin-right: 0.5em;
        margin-top: -2px;
        vertical-align: middle;
        font-weight: bold;
        font-size: 0.88em;
        line-height: 0.88em;
        border-radius: 50%;
        background: #1e275a;
        color: ${theme.colors.alpha.white[100]};
        overflow: hidden;
    `
);
