import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, useTheme, TextField } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import RulesBasedVariableItem from './RulesBasedVariableItem';
import { RuleBasedVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        margin-left: -24px;
        margin-right: -24px;
        .accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    variable: RuleBasedVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
}

const RulesBasedVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();

    useEffect(() => {
        if (!variable)
            setVariable({
                type: VariableResource.type.RULE_BASED_VARIABLE,
                name: '',
                rules: [{ isDefault: true, value: { type: ValueResource.type.STRING_VALUE, stringValue: '0' } }]
            } as RuleBasedVariableResource);
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.name == null || variable.name.trim() === '' || variable.rules == null || variable.rules.length < 1;
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            name: event.target.value
        });
    };

    const addRule = () => {
        setVariable({
            ...variable,
            rules: [...(variable.rules || []), { value: { type: ValueResource.type.STRING_VALUE, stringValue: '0' } }]
        } as RuleBasedVariableResource);
    };
    const changeRuleValue = (ruleId: number, value: ValueResource) => {
        const rules = cloneDeep(variable.rules);
        const ruleIndex = variable?.rules.findIndex((rule) => rule.id === ruleId);
        rules[ruleIndex].value = value;
        setVariable({
            ...variable,
            rules
        } as RuleBasedVariableResource);
    };
    const removeRule = (ruleId: number) => {
        setVariable({
            ...variable,
            rules: variable.rules.filter((rule) => rule.id !== ruleId)
        } as RuleBasedVariableResource);
    };

    const isDisabled = variable?.id === -1;

    return (
        variable && (
            <Stack gap={3}>
                <TextField label="Name" value={variable.name} onChange={handleNameChange} variant="outlined" fullWidth />
                <StyledStack>
                    <Divider />
                    {(variable?.rules || []).map((rule, index) => (
                        <RulesBasedVariableItem
                            key={rule.id + '' + index}
                            rule={rule}
                            variables={variables}
                            openAccordionId={openAccordionId}
                            setOpenAccordionId={setOpenAccordionId}
                            changeRuleValue={changeRuleValue}
                            removeRule={removeRule}
                            disabled={isDisabled}
                        />
                    ))}
                    <Divider sx={{ mt: 'auto' }} />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={addRule} sx={{ borderRadius: 0 }} disabled={isDisabled}>
                        Weiteren Wert hinzufügen
                    </Button>
                </StyledStack>
            </Stack>
        )
    );
};

export default RulesBasedVariableForm;
