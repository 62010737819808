import { Typography, Stack, useTheme, TextField, InputAdornment, Button } from '@mui/material';
import Accordion from 'src/components/display/Accordion/Accordion';
import { formatPrice } from 'src/utils/FormatHelpers';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { useDispatch } from 'react-redux';
import { RuleResource, StringValueResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import { getValue } from 'src/utils/CalcHelpers';

interface Props {
    rule: RuleResource;
    isAbsolute?: boolean;
    variables?: Array<VariableResource>;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    changeRuleValue?: (id: number, value: ValueResource) => void;
    removeRule?: (id: number) => void;
    disabled?: boolean;
}

const SurchargeVariableDynamicItem: React.FC<Props> = ({ rule, isAbsolute, variables, openAccordionId, setOpenAccordionId, changeRuleValue = () => {}, removeRule = () => {}, disabled }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    if (rule.value.type === ValueResource.type.VARIABLE_VALUE) return <>Rule "{rule.id}" can not be an variable!</>;

    const surcharge = (rule.value as StringValueResource).stringValue || '';

    return (
        <Accordion
            key={rule.id}
            accordionHeader={
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Stack alignItems="flex-start">
                        <Typography variant="h4" lineHeight={1.2}>
                            Aufschlagswert {isAbsolute ? formatPrice(surcharge) : `${surcharge}%`}
                        </Typography>
                        <Typography variant="subtitle2" fontSize={13} lineHeight={1.3}>
                            {rule.isDefault ? (
                                'Standard'
                            ) : rule.criteria?.length > 0 ? (
                                <>Wenn {(rule.criteria || []).map((criterion) => `${getValue(criterion.left)} ${criterion.operator} ${getValue(criterion.right)}`).join(', ')}</>
                            ) : (
                                <span style={{ color: theme.colors.error.main }}>Keine Kriterien. Wert wird ignoriert</span>
                            )}
                        </Typography>
                    </Stack>
                </Stack>
            }
            accordionId={rule.id + ''}
            openAccordionId={openAccordionId}
            setOpenAccordionId={setOpenAccordionId}
            hideFirstBorder={true}
        >
            <Stack px="24px" py="16px">
                <TextField
                    disabled={disabled}
                    label="Aufschlagswert"
                    value={surcharge}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{isAbsolute ? '€' : '%'}</InputAdornment>
                    }}
                    onChange={(event) => changeRuleValue(rule.id, { type: ValueResource.type.STRING_VALUE, stringValue: event.target.value } as StringValueResource)}
                />

                {rule.isDefault ? (
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 3, mb: 1, fontWeight: 600, fontSize: 13, lineHeight: 1.2 }} variant="subtitle1">
                        <InfoTwoToneIcon sx={{ fontSize: '1.7rem' }} />
                        Der Aufschlagswert kann nicht gelöscht werden. Er wird dann angewendet, wenn kein anderer Aufschlagswert anwendbar ist.
                    </Typography>
                ) : (
                    <>
                        <Typography variant="h4" sx={{ fontSize: 17, mt: 4, mb: 0.5, opacity: 0.7 }} gutterBottom>
                            Wann soll der Aufschlagswert angewandt werden?
                        </Typography>
                        <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 0.5 }} variant="subtitle1">
                            Wähle aus, wie der Aufschlagswert für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene
                            Kalkulation erstellen.
                        </Typography>

                        <Button startIcon={<AddBoxTwoToneIcon />} sx={{ mr: 'auto', ml: -1.6 }} disabled={disabled}>
                            Kriterium hinzufügen
                        </Button>

                        <Button startIcon={<DeleteForeverTwoToneIcon />} color="error" sx={{ ml: 'auto', mt: 4 }} onClick={() => removeRule(rule.id)} disabled={disabled}>
                            Aufschlagswert löschen
                        </Button>
                    </>
                )}
            </Stack>
        </Accordion>
    );
};

export default SurchargeVariableDynamicItem;
