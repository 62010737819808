import type { Action } from '@reduxjs/toolkit';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ProviderContext } from 'notistack';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';
// noinspection ES6UnusedImports
import type Extend from 'redux-thunk/extend-redux';
import rootReducer from './rootReducer';
//LOCAL STORE
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};
export const saveState = (store: { getState: () => RootState }) => {
    try {
        const serializedState = JSON.stringify({
            user: store.getState().user
        });
        localStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore write errors.
    }
};

const persistedState = loadState();
let snackContext: ProviderContext = undefined;
const setSnackContext = (snack: ProviderContext) => {
    snackContext = snack;
};
const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,
    devTools: true,
    middleware: getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }).concat(thunk)
});

const dispatch = store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

//USE THESE USE SELECTORS AND USE DISPATCHES THROUGH OUR APP
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
export { snackContext, setSnackContext };
