import { FileManagementRestControllerService, FileResource, PageNodeResource } from 'src/backend/market';
import { AppDispatch, RootState } from '../store';
import { slice } from '../slices/fileManager.reducer';
import { compareDates } from 'src/utils/FormatHelpers';

export const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const addThumbnail = (thumbnailDataUrl: string) => async () => {
    const fileName = 'thumbnail.png';
    const result = await FileManagementRestControllerService.createNewPresignedUrlForThumbnail({ name: fileName, fileType: 'image/png' });

    const response = await fetch(result.presignedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'image/png' },
        body: dataURLtoFile(thumbnailDataUrl, fileName)
    });
    if (response.status !== 200) throw new Error(response.statusText);
    return result.assignedImageName;
};

export const addFile = (file: File, fileName: string, assignedThumbnailFileName: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const companyId = getState().user.currentUser.company.companyId;
    const userId = getState().user.currentUser.id;
    const fileType =
        fileName.endsWith('.step') || fileName.endsWith('.stp')
            ? 'application/step'
            : fileName.endsWith('.dxf')
            ? 'application/dxf'
            : fileName.endsWith('.dwg')
            ? 'application/acad'
            : fileName.endsWith('.pdf')
            ? 'application/pdf'
            : 'image/png';
    const result = await FileManagementRestControllerService.createNewPresignedUrlForFile({ name: fileName, fileType });

    const response = await fetch(result.presignedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': fileType },
        body: file
    });
    if (response.status !== 200) throw new Error(response.statusText);
    const fileResource = await FileManagementRestControllerService.addFileToRootFolder(companyId, userId, {
        assignedFileName: result.assignedImageName,
        originalFileName: fileName,
        fileType,
        assignedThumbnailFileName
    });

    dispatch(slice.actions.addFile(fileResource));

    return fileResource;
};

export const addFileWithThumbnail = (file: File, fileName: string, thumbnailDataUrl: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const assignedThumbnailFileName = await dispatch(addThumbnail(thumbnailDataUrl));
        return await dispatch(addFile(file, fileName, assignedThumbnailFileName));
    } catch (error) {
        console.error(error);
    }
};

export const loadFiles = (callback?: (files: FileResource[]) => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const companyId = getState().user.currentUser.company.companyId;
        const userId = getState().user.currentUser.id;
        const fileNameFilter = getState().fileManager.fileNameFilter;
        const page = getState().fileManager.filesPaging.page;
        const size = getState().fileManager.filesPaging.pageSize;
        const sortBy = getState().fileManager.filesSortBy;
        const result: PageNodeResource = await FileManagementRestControllerService.getRootFolder(companyId, userId, page, size, sortBy, fileNameFilter);
        dispatch(slice.actions.setPaging({ total: result.totalElements }));

        // const files = result.nodes.sort((fileA, fileB) => compareDates(fileB.createdAt, fileA.createdAt))
        const files = result.nodes;
        dispatch(slice.actions.setFiles(files));

        if (callback) callback(files);
    } catch (error) {
        console.error(error);
        if (callback) callback([]);
    }
};

export const loadFile = (nodeId: string, callback: (file?: FileResource) => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const companyId = getState().user.currentUser.company.companyId;
        const userId = getState().user.currentUser.id;
        const result: FileResource = await FileManagementRestControllerService.getFile(companyId, userId, nodeId);
        if (callback) callback(result);
    } catch (error) {
        console.error(error);
        if (callback) callback();
    }
};

export const deleteFile = (nodeId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const companyId = getState().user.currentUser.company.companyId;
        const userId = getState().user.currentUser.id;
        const files = getState().fileManager.files;
        const paging = getState().fileManager.filesPaging;
        await FileManagementRestControllerService.deleteFile(companyId, userId, nodeId);
        dispatch(slice.actions.setFiles(files.filter((file) => file.nodeId !== nodeId)));
        dispatch(
            slice.actions.setPaging({
                total: paging.total--
            })
        );
    } catch (error) {
        console.error(error);
    }
};

export const updateFileNameFilter = (fileName: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setFileNameFilter(fileName));
    dispatch(loadFiles());
};

export const updatePaging = (paging: { page?: number; pageSize?: number; total?: number }) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentPaging = getState().fileManager.filesPaging;
    dispatch(
        slice.actions.setPaging({
            page: paging.page ?? currentPaging.page,
            pageSize: paging.pageSize ?? currentPaging.pageSize,
            total: paging.total ?? currentPaging.total
        })
    );
    dispatch(loadFiles());
};
export const updateSorting = (sortBy: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setFilesSort(sortBy));
    dispatch(loadFiles());
};
