import _ from "lodash";
import {
    GeneralMessageResource,
    MarketplaceWizardProfileResource,
    WizardProfileRestControllerService
} from "../../backend/market";
import {GeneralMessageFormValues} from "../../components/overlays/GeneralMessageDialog/GeneralMessageDialog";
import {slice} from "../slices/wizardProfile.reducer";
import {AppThunk, snackContext} from "../store";
import i18n from '../../i18n/i18n';

export const updateProfile = (currentProfile: MarketplaceWizardProfileResource, callback?: () => void): AppThunk => async (dispatch, getState): Promise<void> => {
    console.log("REDUX :: PROFILE :: UPDATE");
    WizardProfileRestControllerService.updateWizardProfile(getState().user.currentUser.id, currentProfile);
    dispatch(slice.actions.setCurrentProfile(currentProfile));
    callback();
}

export const loadGeneralMessages = (): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setGeneralMessagesLoading(true));
    console.log("REDUX :: PROFILE :: LOAD GENERAL MESSAGES");
    const messages = await WizardProfileRestControllerService.getGeneralMessages(getState().user.currentUser.id);
    dispatch(slice.actions.setGeneralMessages(messages.sort((a, b) => a.position - b.position)));
}

export const reorderGeneralMessages = (startIndex: number, endIndex: number): AppThunk => async (dispatch, getState): Promise<void> => {
    const result: GeneralMessageResource[] = Array.from(getState().wizardProfile.generalMessages);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    WizardProfileRestControllerService.reAssignGeneralMessagePositions(getState().user.currentUser.id, {
        assignments: result.map((message, index) => {
                return {
                    generalMessageId: message.id,
                    newPosition: index
                }
            }
        )
    }).then(() => {
        snackContext.enqueueSnackbar(i18n.t("Die Nachrichten wurden erfolgreich neu geordnet."), {variant: "success"});
    }).catch(() => {
        snackContext.enqueueSnackbar(i18n.t("Ein Fehler beim Neuordnen der Nachrichten ist aufgetreten!"), {variant: "error"});
    });
    dispatch(slice.actions.setGeneralMessages(_.compact(result)));
}
export const deleteMessage = (generalMessage: GeneralMessageResource): AppThunk => async (dispatch, getState): Promise<void> => {
    WizardProfileRestControllerService.deleteGeneralMessage(getState().user.currentUser.id, generalMessage.id)
    dispatch(slice.actions.deleteGeneralMessage(generalMessage));
}
export const editMessage = (messageId: number, values: GeneralMessageFormValues): AppThunk => async (dispatch, getState): Promise<void> => {
    console.log("REDUX :: PROFILE :: EDIT GENERAL MESSAGE");
    const result: GeneralMessageResource[] = Array.from(getState().wizardProfile.generalMessages);
    const messageIndex = result.findIndex(m => m.id === messageId);
    const oldMessage = {...result.find(m => m.id === messageId)};
    if (oldMessage) {
        oldMessage.heading = values.title;
        oldMessage.content = values.message
        result[messageIndex] = oldMessage;
    }
    WizardProfileRestControllerService.editGeneralMessage(getState().user.currentUser.id, messageId, {
        message: values.message,
        heading: values.title
    });
    dispatch(slice.actions.setGeneralMessages(_.compact(result)));
}
export const createMessage = (values: GeneralMessageFormValues): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setGeneralMessagesLoading(true));
    console.log("REDUX :: PROFILE :: CREATE GENERAL MESSAGE");
    const result = Array.from(getState().wizardProfile.generalMessages);
    const message = await WizardProfileRestControllerService.addGeneralMessage(getState().user.currentUser.id, {
        message: values.message,
        heading: values.title
    });
    dispatch(slice.actions.setGeneralMessages([...result, message]))
}

export const loadProfile = (callback?: () => void): AppThunk => async (dispatch, getState): Promise<void> => {
    console.log("REDUX :: PROFILE :: LOAD");
    const profile = await WizardProfileRestControllerService.getMarketplaceWizardResource(getState().user.currentUser.id);
    dispatch(slice.actions.setCurrentProfile(profile));
    if (callback) callback();
}

export const updateApiKey = (callback?: (newApiKey) => void): AppThunk => async (dispatch, getState): Promise<void> => {
    console.log("REDUX :: PROFILE :: UPDATE API KEY");
    try {
        await WizardProfileRestControllerService.updateApiKey(getState().user.currentUser.id);
        const apiKeyResource = await WizardProfileRestControllerService.getApiKey(getState().user.currentUser.id);
        dispatch(slice.actions.setNewApiKey(apiKeyResource));
        callback(apiKeyResource.apiKey);
    } catch (e) {
        console.log(e);
    }
}

export const updateThemeState = (newTheme: string, callback?: () => void): AppThunk => async (dispatch, getState): Promise<void> => {
    console.log("REDUX :: PROFILE :: UPDATE WIZARD THEME");
    await WizardProfileRestControllerService.setTheme(getState().user.currentUser.id, {themeId: newTheme});
    dispatch(slice.actions.setNewTheme(newTheme));
    callback();
}
