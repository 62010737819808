/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TermResource } from './TermResource';

export type BinaryTermResource = (TermResource & {
    operator?: BinaryTermResource.operator;
    'a'?: TermResource;
    'b'?: TermResource;
});

export namespace BinaryTermResource {

    export enum operator {
        ADD = 'ADD',
        SUBTRACT = 'SUBTRACT',
        MULTIPLY = 'MULTIPLY',
        DIVIDE = 'DIVIDE',
    }


}
