import { TextField, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { InputVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';
import store, { RootState } from 'src/redux/store';
import { getValue } from 'src/utils/CalcHelpers';

interface Props {
    variable: InputVariableResource;
    variables?: Array<VariableResource>;
    saveVariable: (variable: VariableResource) => void;
}

const CategoryInputField: React.FC<Props> = ({ variable, variables, saveVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();

    const handleChange = (event) => {
        saveVariable({
            ...variable,
            value: { type: ValueResource.type.STRING_VALUE, stringValue: event.target.value }
        });
    };

    return <TextField label={variable.name} value={getValue(variable.value)} onChange={handleChange} size="small" />;
};

const mapStateToProps = (state: RootState) => {
    return {};
};
export default connect(mapStateToProps)(CategoryInputField);
