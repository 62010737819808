import { alpha, Box, Chip, Stack, styled, Typography } from '@mui/material';

import { Link } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import HeaderButtons from './Buttons';
import TopNavigator from './NavigatorLinks';
import HeaderTools from './Tools';
import HeaderUserbox from './Userbox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 4)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(8px);
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        left: 0px;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            width: auto;
        }
`
);

function Header() {
    const isDemonstrationAccount: boolean = useSelector((state: RootState) => state.user.currentUser.demonstrationAccount);

    return (
        <HeaderWrapper displayPrint="none" display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
                <Box
                    sx={{
                        position: 'relative'
                    }}
                >
                    <Link to={nav.DASHBOARD.path}>
                        <img style={{ height: 45 }} id={'homeLink'} alt="Amplify" src="/static/images/logo/surfSolLogoSmall.svg" />
                    </Link>
                    {isDemonstrationAccount && (
                        <Chip
                            label={
                                <Typography variant={'caption'} color={'white'} fontWeight={'bolder'}>
                                    Demo
                                </Typography>
                            }
                            color={'primary'}
                            sx={{
                                position: 'absolute',
                                bottom: -25,
                                left: 30,

                                maxHeight: 25,
                                minWidth: 75,

                                '& .MuiChip-label': {
                                    textOverflow: 'inherit',
                                    overflow: 'inherit'
                                }
                            }}
                        />
                    )}
                </Box>
                <Box>
                    <TopNavigator />
                </Box>
            </Box>
            <Stack direction={'row'} gap={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <HeaderButtons />
                <HeaderTools />
                <HeaderUserbox />
            </Stack>
        </HeaderWrapper>
    );
}

export default Header;
