import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, Grid, IconButton, Link, Stack, Tab, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import PageHeader from 'src/components/display/PageHeader';
import { RootState } from 'src/redux/store';
import { updateThemeState } from 'src/redux/thunks/wizardProfile.thunk';
import { nav } from 'src/statics/navigations';
import { slideInLeft } from 'src/utils/animationstyles';
import BaseInformationDataInput from './BaseInformationDataInput';
import GeneralMessages from './GeneralMessages';
import WizardActivationInput from './WizardActivationInput';
import WizardSnippet from './WizardSnippet';
import WizardThemePicker from './WizardThemePicker';
import { startWalkthrough } from '../../../redux/thunks/guidethrough.thunk';
import { API_CONFIG } from 'src/config';

function Settings({ loadingProfile, loadingStati, currentProfile }) {
    const [wizardTheme, setWizardTheme] = useState<string>('');
    const [tabValue, setTabValue] = useState<string>('0');
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (wizardTheme !== '') {
            console.log('UPDATE WIZARD THEME');
            destroyWizard();
            initializeWizard();
        }
        return () => {
            destroyWizard();
        };
    }, [wizardTheme, tabValue]);

    useEffect(() => {
        if (currentProfile && currentProfile.themeId) {
            setWizardTheme(currentProfile.themeId);
        }
    }, [currentProfile]);

    useEffect(() => {
        dispatch(startWalkthrough('wizardSettings'));
    }, []);

    const destroyWizard = () => {
        console.log('🧙🏻‍♂️🔥 DESTROY WIZARD :: 🧙🏻‍♂️🔥');
        const wizardRef = document.getElementById('wizard-app-root');
        const wizardScript = document.getElementById('wizard-app-script');
        if (wizardRef) {
            wizardRef.remove();
        }
        if (wizardScript) {
            wizardScript.remove();
        }
    };

    const initializeWizard = () => {
        const wizardRef = document.getElementById('WIZARD_INPUT');
        const wizardDiv = document.getElementById('wizard-app-root');
        if (wizardDiv || wizardRef == null) {
            return;
        }
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.id = 'wizard-app-script';
        scriptElement.src = `${API_CONFIG.WIZARD.WIZARD_PATH}/surface-wizard.umd.js?profile=${currentProfile.wizardKey}&predefinedTheme=${wizardTheme}&settingsWizard=true`;
        wizardRef.appendChild(scriptElement);
    };

    const reloadWizard = () => {
        destroyWizard();
        initializeWizard();
    };

    const updateTheme = (theme: string) => {
        console.log('Update theme');
        dispatch(
            updateThemeState(theme, () => {
                const key = enqueueSnackbar(t('wizardHasBeenUpdated'), {
                    variant: 'success',
                    action: (
                        <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon />
                        </IconButton>
                    )
                });
                setWizardTheme(theme);
            })
        );
    };

    const previewTheme = (theme: string) => {
        setWizardTheme(theme);
    };

    const handleBack = () => {
        navigate(nav.TOOLS.path);
    };

    const renderTabSettings = () => {
        return (
            <TabContext value={tabValue}>
                <PageHeader sx={{ mb: 2 }} handleBack={handleBack}>
                    <Box ml={{ xs: 'auto', lg: 0 }} mr={{ xs: 0, lg: 'auto' }} pr={{ xs: 0, lg: 27 }}>
                        <TabList
                            onChange={(event, value) => {
                                setTabValue(value);
                                dispatch(startWalkthrough('wizardSettingsWeb'));
                            }}
                        >
                            <Tab label={t('profildaten')} value={'0'} />
                            <Tab label={t('wizard')} value={'1'} />
                        </TabList>
                    </Box>
                </PageHeader>
                {slideInLeft(
                    <Box>
                        {loadingProfile || loadingStati ? (
                            <SurfLoader style={{ alignSelf: 'center', width: '100%' }} />
                        ) : (
                            <Box>
                                <TabPanel value={'0'}>
                                    <Stack justifyContent={'center'} direction={'row'} width={'100%'}>
                                        <Box maxWidth={'lg'}>
                                            <BaseInformationDataInput />
                                        </Box>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={'1'}>
                                    <div id={'wizardBox'}>
                                        <span id={'WIZARD_INPUT'} />
                                    </div>
                                    <Grid container spacing={1} sx={{ md: { paddingRight: 10 } }}>
                                        <Grid item sm={12} lg={6} alignContent={'stretch'}>
                                            {' '}
                                            <WizardActivationInput reloadWizard={reloadWizard} />
                                        </Grid>
                                        <Grid id={'wizardGeneralMessages'} item sm={12} lg={6} width={'100%'}>
                                            {' '}
                                            <GeneralMessages />
                                        </Grid>
                                        <Grid id={'wizardThemePicker'} item sm={12} lg={6}>
                                            <WizardThemePicker updateTheme={updateTheme} updateSelectedTheme={previewTheme} />
                                        </Grid>
                                        <Grid id={'wizardCodeSnippet'} item sm={12} lg={6}>
                                            <WizardSnippet />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <Divider sx={{ my: 1 }} />
                                <Typography sx={{ mt: 1 }} variant={'caption'}>
                                    {t('fallsDuDeinenAccountLschenMchtestKontaktiereBitte')}{' '}
                                    <Link color={'inherit'} href={'mailto:support@surface-solutions.pro'}>
                                        support@surface-solutions.pro
                                    </Link>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </TabContext>
        );
    };
    return renderTabSettings();
}

const mapStateToProps = (state: RootState) => {
    return {
        loadingProfile: state.wizardProfile.profileLoading,
        loadingStati: state.wizardStati.loading,
        currentProfile: state.wizardProfile.currentProfile
    };
};
export default connect(mapStateToProps)(Settings);
