import { GridSortModel } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { InquiryResource } from '../../backend/market';

//INTERFACE AND INITIAL STATE
interface PriceCalcReducerState {
    priceCalcs: { [key: string]: InquiryResource };
    updatedAt: string | null;
    loading: boolean;
    priceCalcsTablePaging: {
        page: number;
        pageSize: number;
        fetchedPages: number[];
        rowCount: number;
    };
    priceCalcsTableSort: GridSortModel;
}

const initialState: PriceCalcReducerState = {
    priceCalcs: {},
    loading: true,
    updatedAt: null,
    priceCalcsTablePaging: {
        page: 0,
        pageSize: 0,
        fetchedPages: [],
        rowCount: 0
    },
    priceCalcsTableSort: []
};

//HELPER FUNCTIONS
const reducePriceCalcArrayToMap = (priceCalcs: InquiryResource[]): { [key: string]: InquiryResource } => {
    return priceCalcs.reduce((acc, priceCalc) => {
        return { ...acc, ['#' + priceCalc.id]: priceCalc }; // character '#' necessary for sorting
    }, {} as { [key: string]: InquiryResource });
};

//REDUCERS
const reducers = {
    setLoading: (state: PriceCalcReducerState, action: PayloadAction<boolean>) => {
        return Object.assign({}, state, { loading: action.payload });
    },
    replacePriceCalcs: (state: PriceCalcReducerState, action: PayloadAction<InquiryResource[]>) => {
        const newPriceCalcs = reducePriceCalcArrayToMap(action.payload);
        state.updatedAt = new Date().toISOString();
        state.priceCalcs = newPriceCalcs;
        state.loading = false;
        return state;
    },

    updatePriceCalcs: (state: PriceCalcReducerState, action: PayloadAction<InquiryResource[]>) => {
        const newPriceCalcs = reducePriceCalcArrayToMap(action.payload);

        const cState = { ...state };
        cState.updatedAt = new Date().toISOString();
        cState.loading = false;
        cState.priceCalcs = { ...cState.priceCalcs, ...newPriceCalcs };
        return cState;
    },
    deletePriceCalc: (state: PriceCalcReducerState, action: PayloadAction<number>) => {
        state.updatedAt = new Date().toISOString();
        delete state.priceCalcs[action.payload];
    },

    updatePageSize: (state: PriceCalcReducerState, action: PayloadAction<{ pageSize: number }>) => {
        state.priceCalcsTablePaging = { ...state.priceCalcsTablePaging, ...action.payload };
    },
    updatePage: (state: PriceCalcReducerState, action: PayloadAction<{ page: number }>) => {
        state.priceCalcsTablePaging = { ...state.priceCalcsTablePaging, ...action.payload };
    },
    addFetchedPage: (state: PriceCalcReducerState, action: PayloadAction<number>) => {
        state.priceCalcsTablePaging = {
            ...state.priceCalcsTablePaging,
            fetchedPages: [...state.priceCalcsTablePaging.fetchedPages, action.payload]
        };
    },
    updateRowCount: (state: PriceCalcReducerState, action: PayloadAction<number>) => {
        state.priceCalcsTablePaging = { ...state.priceCalcsTablePaging, rowCount: action.payload };
    },

    updateGridSortModel: (state: PriceCalcReducerState, action: PayloadAction<GridSortModel>) => {
        state.priceCalcsTableSort = action.payload;
    }
};

//EXPORTS
export const slice = createSlice({
    name: 'priceCalc',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
