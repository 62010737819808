import { Box, IconButton, Stack, styled, SxProps, Theme, Typography } from '@mui/material';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';
import { getCurrentRouteConstant } from 'src/utils/RouteHelper';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const HeaderBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        align-items: center;
        height: 115px;
        padding: 16px 36px;
        margin: 0 -36px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: rgb(34 51 84 / 10%) 0px 2px 4px -3px, rgb(34 51 84 / 5%) 0px 5px 12px -4px;
        overflow: hidden;
        ${theme.breakpoints.down('md')} {
            margin: 15px -10px;
            margin-top: -10px;
            padding: 10px 20px;
            .MuiTypography-caption {
                font-size: 12px;
            }
            .MuiTypography-subtitle2 {
                font-size: 13px;
            }
            .back-button {
                margin-right: ${theme.spacing(1)};
                margin-left: ${theme.spacing(-1)};
            }
        }
    `
);

interface Props {
    handleBack?: Function;
    mainTitle?: string | JSX.Element;
    secondaryTitle?: string | JSX.Element;
    secondaryTitlePosition?: 'above' | 'below';
    tabTitle?: string;
    sx?: SxProps<Theme>;
}

const PageHeader: React.FC<Props> = ({ handleBack, mainTitle, secondaryTitle, secondaryTitlePosition = 'above', tabTitle, sx, children }) => {
    const {t} = useTranslation();

    if(!mainTitle || !tabTitle) {
        const routeConstant = getCurrentRouteConstant();
        if(!mainTitle && routeConstant?.caption) mainTitle = t(routeConstant.caption);
        if(!tabTitle && routeConstant?.tabTitle) tabTitle = t(routeConstant.tabTitle);
    }

    return <>
        {tabTitle && <Helmet><title>{tabTitle}</title></Helmet>}
        <HeaderBox sx={sx}>
            {(handleBack || mainTitle || secondaryTitle) &&
                <Stack direction="row" alignItems="center" flexGrow={1} maxWidth="100%">
                    {handleBack &&
                        <IconButton sx={{ mr: 2 }} className="back-button" onClick={() => { handleBack() } }>
                            <KeyboardArrowLeftTwoToneIcon fontSize="large" />
                        </IconButton>
                    }

                    {(mainTitle || secondaryTitle) &&
                        <Stack overflow="hidden" mr="auto" className="title-stack">
                            {(secondaryTitle && secondaryTitlePosition === 'above') && <Typography variant="caption" noWrap>{secondaryTitle}</Typography>}
                            {mainTitle && <Typography variant="h3" noWrap>{mainTitle}</Typography>}
                            {(secondaryTitle && secondaryTitlePosition === 'below') && <Typography variant="subtitle2">{secondaryTitle}</Typography>}
                        </Stack>
                    }
                    
                    {children}
                </Stack>
            }
        </HeaderBox>
    </>
}

export default PageHeader;