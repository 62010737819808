import {
    Typography,
    Box,
    Avatar,
    Card,
    CardContent,
    Grid,
    styled,
    alpha,
    useTheme,
    Stack
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';


const DotLegend = styled('span')(
    ({ theme }) => `
        border-radius: 22px;
        width: ${theme.spacing(1.5)};
        height: ${theme.spacing(1.5)};
        display: inline-block;
        margin-right: ${theme.spacing(0.5)};
    `
);


interface Props {
    regularCustomersPercent: number;
    isNoData: boolean;
}

const InternalCalcsRegularCustomers: React.FC<Props> = ({ regularCustomersPercent, isNoData }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();

    const chartData = {
        datasets: [{ backgroundColor: [theme.palette.primary.main, theme.palette.info.main] }],
        labels: [t('regularCustomers'), t('walkInCustomer')]
    };

    const chartOptions: ApexOptions = {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            }
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    return val + '%';
                },
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '58%'
                }
            }
        },
        colors: [
            theme.palette.primary.main,
            theme.palette.info.main
        ],
        dataLabels: {
            enabled: true,
            formatter: (val) => {
                return val + '%';
            },
            dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                color: theme.colors.alpha.black[50],
                opacity: 0.5
            }
        },
        fill: {
            opacity: 1
        },
        labels: chartData.labels,
        legend: {
            labels: {
                colors: theme.colors.alpha.trueWhite[100]
            },
            show: false
        },
        stroke: {
            width: 0
        },
        theme: {
            mode: theme.palette.mode
        },
        xaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          }
    };

    const chartSeries = [regularCustomersPercent, 100 - regularCustomersPercent];

    return (
        <Card sx={{ p: 2.7, height: '100%' }} className="internal-calcs-regular-customers">
            <Typography gutterBottom component="div" variant="caption">
                {t('customerType')}
            </Typography>
            {isNoData ?
                <Typography variant="subtitle2" sx={{ py: 1.2 }}>{t('noDataYet')}</Typography>
            :
                <Stack justifyContent="center" alignItems="center" pt={1}>
                    <Chart height={190} options={chartOptions} series={chartSeries} type="donut"/>
                    <Box pt={0.8}>
                        {chartData.labels.map((label: string, i: number) => (
                            <Typography key={label} variant="body2" sx={{ py: 0.6, display: 'flex', alignItems: 'center', mr: 2}}>
                                <DotLegend style={{ background: `${chartData.datasets[0].backgroundColor[i]}` }}/>
                                <span style={{ paddingRight: 10, color: `${chartData.datasets[0].backgroundColor[i]}`}}>
                                    {chartSeries[i]}%
                                </span>
                                {label}
                            </Typography>
                        ))}
                    </Box>
                </Stack>
            }
        </Card>
    );
}

export default InternalCalcsRegularCustomers;
