import { Box, IconButton, Tooltip, styled, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculationParameterResource } from 'src/backend/market';
import { useDispatch } from 'react-redux';
import { FRAGMENT_PARAMETER_SUFFIX, QUANTITY } from 'src/statics/statics';
import { CalculationFragmentResource } from 'src/backend/internalCalc/models/CalculationFragmentResource';
import { AdditionalStorageResource, IntegerCalcParamResource } from 'src/backend/internalCalc';
import { ParameterArray } from 'src/utils/CalcHelpers';
import NumberInput from 'src/components/input/NumberInput';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

const StyledNumberInput = styled(NumberInput)(
    ({ theme }) => `
        .MuiInputBase-input {
            padding: 6px 0;
            font-weight: 700;
            font-size: 16px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: ${theme.colors.alpha.black[10]};
        }
        .MuiTypography-subtitle1 {
            font-weight: 700;
            font-size: 16px;
            color: unset;
        }

        &.small .MuiButtonBase-root.plus,
        &.small .MuiButtonBase-root.minus {
            min-width: 1.7rem;
        }
        &.small .MuiInputBase-input,
        &.small .MuiTypography-subtitle1 {
            font-size: 14px;
        }
    `
);

interface Props {
    partId: number;
    itemId?: number;
    parameters?: ParameterArray;
    setParameters?: Array<CalculationParameterResource>;
    fragment?: CalculationFragmentResource;
    disabled?: boolean;
    isSmall?: boolean;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const Item: React.FC<Props> = ({ partId, itemId, parameters, setParameters, fragment, disabled, isSmall, additionalStorage = [] }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    const quantityParameterName = useMemo(() => {
        return fragment != null ? QUANTITY + FRAGMENT_PARAMETER_SUFFIX : QUANTITY;
    }, [fragment]);

    const quantityParameter = useMemo(() => {
        const param = parameters || [];
        return param.find((parameter) => parameter.name === quantityParameterName) as IntegerCalcParamResource;
    }, [parameters]);

    const quantitySetParameter = useMemo(() => {
        return setParameters.find((parameter) => parameter.name === quantityParameterName);
    }, [setParameters]);

    const [quantity, setQuantity] = useState<string>((quantitySetParameter?.value || quantityParameter?.defaultValue || 1) + '');
    const [width, setWidth] = useState<number>(quantity.length);
    const min = quantityParameter?.min || 1;
    const max = quantityParameter?.max || 1000;

    const extractData = useMemo(() => {
        return additionalStorage.find((entry) => entry.key === 'extracted_' + quantityParameter.name);
    }, [additionalStorage]);
    const isExtracted = !!extractData;
    const isUsingExtracted = extractData?.value == quantity;

    const changeQuantity = (value): void => {
        setQuantity(value);
        saveQuantity(value);
        setWidth(value.length);
    };
    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>): void => {
        changeQuantity(event.target.value);
        setWidth(event.target.value.length);
    };

    const saveQuantity = (newQuantity) => {
        if (!newQuantity) return;
        const parsedQuantity = parseInt(newQuantity);
        if (parsedQuantity < min) return;
        if (parsedQuantity > max) return;
        const newQuantitySetParameter = { ...quantitySetParameter, value: newQuantity };

        if (fragment != null) {
            dispatch(updateProCalc(ProCalcUpdateTypes.FRAGMENT_PARAMETER, { partId, fragment: fragment, parameter: newQuantitySetParameter }));
        } else {
            dispatch(updateProCalc(ProCalcUpdateTypes.PART_PARAMETER, { partId, itemId, parameter: newQuantitySetParameter }));
        }
    };

    const handleQuantityBlur = (): void => {
        if (!quantity || isNaN(+quantity)) return changeQuantity(min + '');
        const parsedQuantity = parseInt(quantity);
        if (parsedQuantity < min) changeQuantity(min + '');
        if (parsedQuantity > max) changeQuantity(max + '');
    };

    const resetQuantity = (): void => {
        return changeQuantity(extractData.value);
    };

    useEffect(() => {
        setQuantity((quantitySetParameter?.value || quantityParameter?.defaultValue || 1) + '');
    }, [setParameters]);

    return (
        <StyledNumberInput
            size="small"
            inputProps={{ min: quantityParameter?.min, max: quantityParameter?.max }}
            value={quantity}
            onChange={handleQuantityChange}
            onBlur={handleQuantityBlur}
            onKeyDown={(event) => {
                if (event.key === '.' || event.key === ',') event.preventDefault();
            }}
            sx={{ width: (width + t('pcs').length + (isSmall ? 7 : 9) + (isExtracted ? 2 : 0)) * 10 + 'px', minWidth: fragment != null ? '9rem' : 'unset' }}
            startIcon={
                isExtracted && (
                    <Box ml={0.8} mb="-2px">
                        {isUsingExtracted ? (
                            <Tooltip title={t('valueWasExtracted')}>
                                <AutoFixHighTwoToneIcon sx={{ cursor: 'help', color: theme.colors.success.dark, mb: -0.3 }} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={t('resetToExtractedValue')}>
                                <IconButton onClick={resetQuantity} sx={{ m: '-8px' }}>
                                    <CachedTwoToneIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )
            }
            unit={t('pcs')}
            onFocus={(e) => e.target.select()}
            disabled={disabled}
            className={isSmall ? 'small' : ''}
        />
    );
};

export default Item;
