import {
    Box,
    Typography,
    Grid,
    useTheme,
    styled,
    Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { UserResource } from 'src/backend/market';
import { formatDate } from 'src/utils/FormatHelpers';


const WeatherBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        ${theme.breakpoints.down('md')} {
            .MuiTypography-h4 {
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 16px;
            }
            .MuiTypography-h5 {
                font-size: 13px;
            }
        }
    `
);
const WeatherIcon = styled(Box)(
    ({ theme }) => `
        height: auto;
        text-align: center;
        width: 40%;

        img {
            min-width: 25px;
            width: 100%;
            max-width: 158px;
        }

        ${theme.breakpoints.down('md')} {
            width: 28%;
        }
    `
);


interface Props {
    user: UserResource;
}

const Weather: React.FC<Props> = ({ user }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [weather, setWeather] = useState<any>(null);

    const openWeatherUrl = 'https://api.openweathermap.org/data/2.5/weather';
    const openWeatherApiKey = '90aa2dae62da7a27713210493b8fda66';


    useEffect(() => {
        fetch(`${openWeatherUrl}?lat=${user.company.latitude}&lon=${user.company.longitude}&lang=${user.languageCode}&units=metric&appid=${openWeatherApiKey}`)
        .then((result) => {
            return result.json();
        })
        .then((weather) => {
            setWeather(weather);
        })
    }, []);

    const getWeatherIcon = (iconCode) => {
        const url = '/static/images/placeholders/illustrations';
        switch(iconCode) {
            case '01d': return `${url}/clear-day.svg`;
            case '01n': return `${url}/clear-night.svg`;
            case '02d': return `${url}/partly-cloudy-day.svg`;
            case '02n': return `${url}/partly-cloudy-night.svg`;
            case '03d': return `${url}/cloudy.svg`;
            case '03n': return `${url}/cloudy.svg`;
            case '04d': return `${url}/overcast.svg`;
            case '04n': return `${url}/overcast.svg`;
            case '09d': return `${url}/rain.svg`;
            case '09n': return `${url}/rain.svg`;
            case '10d': return `${url}/partly-cloudy-day-rain.svg`;
            case '10n': return `${url}/partly-cloudy-night-rain.svg`;
            case '11d': return `${url}/thunderstorms.svg`;
            case '11n': return `${url}/thunderstorms.svg`;
            case '13d': return `${url}/snow.svg`;
            case '13n': return `${url}/snow.svg`;
            case '50d': return `${url}/mist.svg`;
            case '50n': return `${url}/mist.svg`;
        }
    }
    

    return <WeatherBox mr={{ xs: 'auto', md: 0, xl: 'auto' }} minHeight={{ xs: 111, sm: 153 }}>
        {weather && <>
            <WeatherIcon>
                <img src={getWeatherIcon(weather.weather?.[0]?.icon)} alt={weather.weather?.[0]?.description}/>
            </WeatherIcon>
            <Stack overflow="hidden" ml={1} width="90%">
                <Typography sx={{ fontWeight: 'bold', fontSize: '20px', color: theme.colors.alpha.white[100] }} variant="h1">
                    {Math.trunc(weather.main?.temp)}<sup>°</sup>
                </Typography>
                <Typography sx={{ pt: { md: 1 }, pb: 1, color: theme.colors.alpha.white[100] }} variant="h4">
                    {`${weather.weather?.[0]?.description}`}
                </Typography>
                <Typography fontWeight="normal" variant="h5" style={{ color: theme.colors.alpha.white[100] }}>
                    {t('today')}, {formatDate(new Date(), 'DD')}
                </Typography>
                <Box display="flex" alignItems="center" sx={{ pt: { md: '5px' }, opacity: 0.7}} overflow="hidden">
                    <MyLocationTwoToneIcon style={{width: '15px', color: theme.colors.alpha.white[100]}}/>
                    <Typography sx={{ pl: 0.5, fontSize: '12px', color: theme.colors.alpha.white[100]}} noWrap>
                        {weather.name}
                    </Typography>
                </Box>
            </Stack>
        </>}
    </WeatherBox>
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user.currentUser
    }
}
export default connect(mapStateToProps)(Weather);
