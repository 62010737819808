import { NavItems } from './navigations';
import RequestDetail from 'src/pages/Core/Inquiries/RequestDetail';
import PriceCalcs from 'src/pages/Core/PriceCalcs/PriceCalcs';
import PriceCalcDetail from 'src/pages/Core/PriceCalcs/PriceCalcDetail';
import WizardSettings from 'src/pages/Core/WizardSettings';

const wizardNavigation: NavItems = {
    // BASE: {
    //     caption: 'wizard',
    //     tabTitle: 'wizardTabTitle',
    //     link: '/wizard/',
    //     path: '/',
    //     element: <Requests/>,
    // },
    DETAIL: {
        caption: 'wizardDetail',
        tabTitle: 'wizardDetailTabTitle',
        path: '/wizard/detail/:id',
        element: <RequestDetail />
    },
    SETTINGS: {
        caption: 'wizardSettings',
        tabTitle: 'wizardSettingsTabTitle',
        path: '/wizard/settings/',
        element: <WizardSettings />
    },
    PRICE_CALCS: {
        caption: 'priceCalcs',
        tabTitle: 'priceCalcsTabTitle',
        path: '/wizard/price-calcs/',
        sub: {
            BASE: {
                caption: 'priceCalcs',
                tabTitle: 'priceCalcsTabTitle',
                path: '/wizard/price-calcs/',
                element: <PriceCalcs />
            },
            DETAIL: {
                caption: 'priceCalcDetail',
                tabTitle: 'priceCalcDetailTabTitle',
                path: '/wizard/price-calcs/:id',
                element: <PriceCalcDetail />
            }
        }
    }
};

export default wizardNavigation;
