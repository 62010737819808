import { Box, Button, Card, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SummaryPage } from './SummaryPage';
import SurfLoader from '../Loader/SurfLoader';
import { SummaryResource } from 'src/backend/market';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import CreateNewFolderTwoToneIcon from '@mui/icons-material/CreateNewFolderTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';
import { AppDispatch } from 'src/redux/store';
import { SummaryRestControllerService } from 'src/backend/market/services/SummaryRestControllerService'; // Import the service
import { UserResource } from 'src/backend/market';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

const StyledIconButton = styled(IconButton)(
    ({ theme }) => `
        // position: absolute;
        background: ${theme.colors.alpha.white[70]};
        border-radius: 20rem;
        border: 1px solid ${theme.colors.alpha.black[10]};
        pointer-events: all;
    `
);

const SummaryPages = styled(Stack)(
    ({ theme }) => `
        @page {
            margin: 2.5cm;
        }
        @media print {
            padding: 0rem;
            justify-content: stretch;
            color-adjust: exact !important;  
            -webkit-print-color-adjust: exact !important; 
            print-color-adjust: exact !important;

            .summary {
                width: 100%;
                max-width: 100%;
                box-shadow: none;
                padding: 0;
            }
            .summary > div {
                padding: 10px;
                font-size: 0.8em;
            }
            .summary .MuiTypography-root {
                filter: contrast(3);
            }
            .summary .MuiTypography-h5 {
                font-size: 11px;
            }
            .summary .MuiChip-root {
                box-shadow: inset 0 0 0 1px ${theme.colors.alpha.black[50]};
            }
            .summary .part-summary {
                margin-top: ${theme.spacing(-1)};
                padding-top: ${theme.spacing(0)};
            }
            .summary .part-summary-header {
                border-top: 1px solid ${theme.colors.alpha.black[50]};
                border-radius: 0;
                background-color: transparent;
            }
            .summary .part-summary.first {
                margin-top: 0;
            }
            .summary .part-summary.first .part-summary-header {
                border-top: none;
            }
            .summary .MuiDivider-root {
                background: ${theme.colors.alpha.black[50]};
            }
            .summary .contact-wrapper {
                flex-direction: row;
            }
            .summary .contact-wrapper > div {
                margin-top: 0;
            }
            .summary .regular-customer,
            .summary .delivery-percentage {
                display: none;
            }
            .summary .part-parameters .MuiSvgIcon-root {
                font-size: 0.94rem;
            }
            .summary .part-parameters > div:not(style)+:not(style) {
                margin-top: 4px;
            }
        }
    `
);
const AnimatedSummaryPage = styled(SummaryPage)<{ position: string }>(
    ({ theme, position }) => `
        transition: transform 0.6s ease-in-out 0.6s;

        ${
            position === 'main'
                ? `
            opacity: 1;
            transform-origin: center;
        `
                : ''
        }
        ${
            position === 'left'
                ? `
            opacity: 0.5;
            transform: scale(0.8);
            transform-origin: top right;
        `
                : ''
        }
        ${
            position === 'right'
                ? `
            opacity: 0.5;
            transform: scale(0.8);
            transform-origin: top left;
        `
                : ''
        }
    `
);

interface Props {
    summary: SummaryResource;
    isLoading: boolean;
    isPublic?: boolean;
    showPrice?: boolean;
}

export const Summary: React.FC<Props> = ({ summary, isLoading, isPublic, showPrice }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [focusedSummaryIndex, setFocusedSummaryIndex] = useState(0);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isLoadingOfferPdf, setLoadingOfferPdf] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state.user.currentUser);

    const loadOfferPdf = () => async (dispatch: AppDispatch) => {
        const newTab = window.open('', '_blank');
        setLoadingOfferPdf(true);                
        console.log(user);
        console.log(summary);
        const result = await SummaryRestControllerService.getInternalCalculationSummaryOfferPdf(summary.id, user.id);
        newTab.location.href = result;
        console.log(result);
        setLoadingOfferPdf(false);
    };


    useEffect(() => {
        if (location.hash === '#open') {
            setOpen(true);
        }
    }, []);

    return isLoading ? (
        <Box width="100%" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <SurfLoader />
        </Box>
    ) : (
        <>
            <Button onClick={() => {dispatch(loadOfferPdf());}}>
                {!isLoadingOfferPdf && <div>Angebot generieren</div>}
                {isLoadingOfferPdf && <div>Generiere Angebot...</div>}
            </Button>
            <SummaryPages
                direction="row"
                p={{ xs: 0, md: 4 }}
                mx={{ xs: -1, md: -4 }}
                gap={2}
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                position="relative"
                className="summary-pages"
            >
                {/* <AnimatedSummaryPage summary={summary} position={'left'}/>

                <StyledIconButton sx={{ left: '0' }}>
                        <ArrowLeftTwoToneIcon sx={{ color: theme.colors.alpha.black[50], fontSize: '28px'}}/>
                    </StyledIconButton> */}

                {summary && <AnimatedSummaryPage summary={summary} position={'main'} isPublic={isPublic} showPrice={showPrice} className="summary" />}

                {/* <Stack alignSelf="flex-start" gap={2}>
                    <Card>
                        <Stack p={2} pt={1.7}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="h3">Angebote</Typography>
                                <Button size="small" startIcon={<AddBoxTwoToneIcon />} onClick={() => setOpen(true)}>
                                    Angebot erstellen
                                </Button>
                            </Stack>
                            <Stack
                                bgcolor={theme.colors.alpha.black[7]}
                                alignItems="center"
                                justifyContent="center"
                                width="24rem"
                                height="8rem"
                                fontSize={14}
                                sx={{ mt: 1, opacity: 0.6, borderRadius: 1 }}
                            >
                                <RedeemTwoToneIcon sx={{ fontSize: 28, opacity: 0.6 }} />
                                Keine Angebote vorhanden
                            </Stack>
                        </Stack>
                    </Card>
                    <Card>
                        <Stack p={2} pt={1.7}>
                            <Typography variant="h3">Verlauf</Typography>
                            <Stack divider={<Divider />} mt={1}>
                                <Stack direction="row" alignItems="center" gap={1} py={1.2}>
                                    <RedeemTwoToneIcon sx={{ fontSize: 22, opacity: 0.6 }} />
                                    <Stack>
                                        <Typography>Angebot erstellt</Typography>
                                        <Typography variant="subtitle2" fontSize={12} lineHeight={1}>
                                            von Reinhard Schnetzinger
                                        </Typography>
                                    </Stack>
                                    <Typography variant="subtitle2" ml="auto" fontSize={13}>
                                        17.11.2023 11:34
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center" gap={1} py={1.2}>
                                    <CreateNewFolderTwoToneIcon sx={{ fontSize: 22, opacity: 0.6 }} />
                                    <Stack>
                                        <Typography>Kalkulation angelegt</Typography>
                                        <Typography variant="subtitle2" fontSize={12} lineHeight={1}>
                                            Systemaktion
                                        </Typography>
                                    </Stack>
                                    <Typography variant="subtitle2" ml="auto" fontSize={13}>
                                        17.11.2023 11:32
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center" gap={1} py={1.2}>
                                    <EmailTwoToneIcon sx={{ fontSize: 22, opacity: 0.6 }} />
                                    <Stack>
                                        <Typography>Email eingelangt</Typography>
                                        <Typography variant="subtitle2" fontSize={12} lineHeight={1}>
                                            Systemaktion
                                        </Typography>
                                    </Stack>
                                    <Typography variant="subtitle2" ml="auto" fontSize={13}>
                                        17.11.2023 11:31
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>

                <Dialog open={isOpen} fullWidth maxWidth="xl" PaperProps={{ sx: { width: '100%', height: '100%' } }} onClick={() => setOpen(false)}>
                    <DialogTitle sx={{ pr: 7 }}>
                        Vorschau
                        <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                            <CloseTwoToneIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Stack direction="row" height="100%">
                            <object data={'/example.pdf'} type="application/pdf" width="100%" height="100%" />
                            <Stack pl={4}>
                                <Typography variant="h3">Angebot erstellen</Typography>
                                <Typography mt={2}>Ein Angebot wird auf Basis der aktuellen Kalkulation erstellt. Das Ändern der Kalkulation, ändert NICHT das Angebot.</Typography>
                                <Typography variant="subtitle2" my={2}>
                                    Ein neues Angebot auf Basis einer veränderten Kalkulation kann jederzeit erstellen werden!
                                </Typography>
                                Du kannst hier Anzeigeeinstellungen die nur das Angebot betreffen vornehmen:
                                <Typography mt={3} variant="subtitle2" fontWeight="bold" gutterBottom>
                                    Fragmente
                                </Typography>
                                <ButtonSelector
                                    items={[
                                        { id: 'einblenden', name: 'Einblenden' },
                                        { id: 'ausblenden', name: 'Ausblenden' }
                                    ]}
                                    value="einblenden"
                                    setValue={() => null}
                                />
                                <Typography mt={3} variant="subtitle2" fontWeight="bold" gutterBottom>
                                    Preisoptionen
                                </Typography>
                                <ButtonSelector items={[{ id: 'Gesamtpreis hervorheben' }, { id: 'Stückpreis hervorheben' }]} value="Gesamtpreis hervorheben" setValue={() => null} />
                                <Button size="large" variant="contained" sx={{ mt: 'auto' }}>
                                    Erstellen
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog> */}
                {/* <StyledIconButton sx={{ right: '0' }}>
                        <ArrowRightTwoToneIcon sx={{ color: theme.colors.alpha.black[50], fontSize: '28px'}}/>
                    </StyledIconButton>
                <AnimatedSummaryPage summary={summary} position={'right'}/> */}
            </SummaryPages>
            {/* <SummaryBar/> */}
        </>
    );
};
