import { Button, Card, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BackendService } from "src/backend/summary/BackendService";
import i18n from "src/i18n/i18n";

const OptInOut: React.FC = () => {
    const {mail} = useParams();

    const { t } = useTranslation();
    const [mailKnown, setMailKnown] = useState<boolean>(false)
    const [isOptOut, setOptedOut] = useState<boolean>(false)
    const [isMailLoading, setMailLoading] = useState<boolean>(true)
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>(null)
    const [optInOutClicked, setOptInOutClicked] = useState<number>(0)

    function optInOutClick() {
        if(optInOutClicked >= 3) return setErrorMessage('optInOutBlocked')

        if (mail !== undefined) {
            setButtonLoading(true);
            BackendService.setOptInOutState(mail, !isOptOut).then(
                (optInOutResource) => {
                    setOptedOut(optInOutResource.optedOut)
                    setButtonLoading(false)
                    setOptInOutClicked(optInOutClicked + 1)
                }
            ).catch((error) => {
                console.log(error);
                setErrorMessage('optInOutSomethingWentWrong')
            })
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const languageCode = queryParams.get('languageCode');

        if(languageCode) {
            i18n.changeLanguage(languageCode);
        }


        if (mail === undefined) {
            setMailKnown(false)
            setErrorMessage('optInOutUnknownEmail')
        } else {
            BackendService.getOptInOutState(mail).then(
                (optInOutResource) => {
                    setMailKnown(true)
                    setOptedOut(optInOutResource.optedOut)
                }
            ).catch((error) => {
                setMailKnown(false)
                setErrorMessage('optInOutUnknownEmail')
                console.log(error)
            })
            .finally(() => {
                setMailLoading(false)
            });
        }
    }, [mail])

    return <Stack m="auto" spacing={4}>
    {/* {errorMessage && <InfoComponent message={t(errorMessage)} msgType={"error"}/>} */}
    {isMailLoading
        ? <CircularProgress size={35}/>
        : mailKnown && <Card sx={{ m:'auto', p: 4 }}>
            <Stack maxWidth="30rem">
                <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                    <Typography variant="h1" noWrap>{t('notifications')}</Typography>
                    <Chip color={isOptOut ? 'error' : 'success'} label={t(isOptOut ? 'deaktiviert' : 'aktiviert')} sx={{ fontWeight: 'bold', height: 26 }}/>
                </Stack>

                <Typography variant="h4" gutterBottom>{t('setNotificationSettingsTitle')}</Typography>
                <Typography variant="subtitle2" mb={2}>{t('setNotificationSettingsExplanation')}</Typography>

                <Button onClick={optInOutClick} variant="contained" color={isOptOut ? 'primary' : 'error'} disabled={!!errorMessage || isButtonLoading} sx={{ alignSelf: 'flex-start' }}>
                    {isButtonLoading
                        ? <CircularProgress size={24} color="inherit"/>
                        : t(isOptOut ? 'enableNotifications' : 'disableNotifications')}
                </Button>
            </Stack>
        </Card>}
    </Stack>
}

export default OptInOut