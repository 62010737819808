import { Box, Stack, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TermBuilderItem from './TermBuilderItem';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { getTermAsString } from 'src/utils/TermHelper';
import { TermResource, VariableResource } from 'src/backend/coreCalc';

const TermBuilderInputContainer = styled(Box)<{ multiple: number; error: number }>(
    ({ theme, multiple, error }) => `
        width: 15rem;
        font-family: 'Azeret Mono', monospace !important;
        border-radius: ${theme.shape.borderRadius}px;

        ${
            multiple &&
            `
                width: auto;
                flex-grow: 1;
                border: 1px solid ${error ? theme.palette.error.light : theme.palette.grey.A400};
            `
        }

        //hide scrollbar
        ::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    `
);

interface Props {
    isAlwaysFullWidth?: boolean;
    minInputWidth?: string;
    variables: Array<VariableResource>;
    term: TermResource;
    setTerm?: (term: TermResource) => void;
    disabled?: boolean;
}

const TermBuilder: React.FC<Props> = ({ term, setTerm, isAlwaysFullWidth, minInputWidth, variables, disabled }) => {
    const { t } = useTranslation();
    const hasMultipleTerms = !!term['b'];

    const mappedVariables = useMemo(() => {
        const vars: { [name: string]: VariableResource } = {};
        variables.forEach((variable) => {
            vars[variable.translationKey || variable.name] = variable;
        });
        return vars;
    }, [variables]);

    return (
        <Stack>
            <TermBuilderInputContainer multiple={hasMultipleTerms ? 1 : 0} error={0} sx={isAlwaysFullWidth && { width: 'auto' }}>
                <Box display="flex" overflow="auto" sx={hasMultipleTerms ? { px: 1, py: 2 } : {}}>
                    <TermBuilderItem
                        level={0}
                        mappedVariables={mappedVariables}
                        updateSingleTerm={setTerm}
                        updateFullTerm={setTerm}
                        fullTerm={term}
                        singleTerm={term}
                        minInputWidth={minInputWidth}
                        disabled={disabled}
                    />
                </Box>
            </TermBuilderInputContainer>
            {hasMultipleTerms && (
                <Typography color="primary" fontWeight={700} fontSize={14} sx={{ display: 'flex', alignItems: 'center', mt: 1, opacity: 0.7, gap: 0.5 }}>
                    <InfoTwoToneIcon sx={{ fontSize: '1.3rem !important' }} />
                    Preis = {getTermAsString(term, variables)}
                </Typography>
            )}
        </Stack>
    );
};
export default TermBuilder;
