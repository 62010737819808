import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    TextField,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    useTheme,
    Stack,
    CircularProgress,
    IconButton
} from '@mui/material';
import {Formik} from 'formik';
import {useEffect, useState} from 'react';
import {useTranslation, getI18n} from 'react-i18next';
import {useDispatch} from "react-redux";
import {useNavigate} from 'react-router-dom';
import { loadNumberFormats, setCompanyLogo, unsetCompanyLogo, updateUser } from 'src/redux/thunks/user.thunk';
import * as Yup from 'yup';
import {AdditionalUserDataDto, UserManagementRestControllerService, UserResource} from '../../../backend/market';
import {RootState, useSelector} from "../../../redux/store";
import DragAndDropFileInput from '../DragAndDropFileInput';
import { refetchDictionary } from 'src/redux/thunks/constants.thunk';
import { formatValue } from 'src/utils/FormatHelpers';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';



interface AccountDataInputFormProps {
    isFullRegistration?: boolean;
    successCallback: (userResource: UserResource) => void;
    setException?: Function;
}

function AccountDataInputForm(props: AccountDataInputFormProps) {
    const phoneNumberRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    const {t}: { t: any } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user: UserResource = useSelector((state: RootState) => state.user.currentUser);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [logoSrc, setLogoSrc] = useState<string>(user?.company?.logo);
    const [newLogoFile, setNewLogoFile] = useState<File>(null);


    const languageCodes = useSelector((state: RootState) => state.language.languageCodes);
    const numberFormats = useSelector((state: RootState) => state.user.numberFormats) || [];

    useEffect(() => {
        if(!numberFormats || numberFormats.length === 0) {
            dispatch(loadNumberFormats());
        }
    }, []);
    

    const baseInputFields = {
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        phoneNumber: user.phoneNumber ?? '',
        languageCode: user.languageCode ?? getI18n().language,
        numberFormat: user.numberFormat ?? '',
        companyName: user.company.name ?? '',
        companyLanguageCode: user.company.languageCode ?? getI18n().language,
        street: user.company.street ?? '',
        houseNumber: user.company.houseNumber ?? '',
        city: user.company.city ?? '',
        zipcode: user.company.zipcode ?? '',
        uid: user.company.uid ?? '',
        submit: null,
        password: '',
        passwordAgain: ''
    };


    const baseValidationSchema = {
        firstName: Yup.string()
            .min(2, t('The first name is too short.'))
            .max(200, t('The first name is too long.'))
            .required(t('You must enter a first name.')),
        lastName: Yup.string()
            .min(2, t('The last name is too short.'))
            .max(200, t('The last name is too long.'))
            .required(t('You must enter a last name.')),
        phoneNumber: Yup.string()
            .required(t('You must enter a phone number.'))
            .matches(phoneNumberRegEx, t('The phone number is not valid.')),
        companyName: Yup.string()
            .min(2, t('The company name is too short.'))
            .max(200, t('The company name is too long.'))
            .required(t('You must enter a company name.'))
            .test("checkCompanyNameDuplicate", "Dieser Firmenname ist bereits vergeben!", async (value) => {
                if (value === user?.company.name ?? "") {
                    return true;
                }
                if (value === "" || !value) return;
                const result = await UserManagementRestControllerService.getIsCompanyTaken({name: value});
                return !result.taken;
            })
        ,
        street: Yup.string()
            .min(2, t('The name of the street is too short.'))
            .max(200, t('The name of the street is too long.'))
            .required(t('You must enter a street name.')),
        houseNumber: Yup.string()
            .min(1, t('The house number is too short.'))
            .max(10, t('The house number is too long.'))
            .required(t('You must enter a house number.')),
        city: Yup.string()
            .min(1, t('The name of the city is too short.'))
            .max(200, t('The name of the city is too long.'))
            .required(t('You must enter a city name.')),
        zipcode: Yup.string()
            .required(t('You must enter a zipcode.'))
    };

    const [schema, setSchema] = useState(baseValidationSchema);

    const passwordSchema = {
        password: Yup.string().max(255, t('The password is too long.')),
        passwordAgain: Yup.string()
            .oneOf(
                [Yup.ref('password')],
                t('The two passwords entered must match.')
            )
            .when('password', {
                is: (value) => !!value,
                then: Yup.string().required(t('The two passwords entered must match.'))
            })
    };

    useEffect(
        () => {
            let pwdAddition = {};

            if (!props.isFullRegistration) {
                pwdAddition = {...baseValidationSchema, ...passwordSchema};

                setSchema({...baseValidationSchema, ...pwdAddition});
            }
        }
        , []);

    const handleFileSelection = (fileList: FileList) => {
        const files = Array.from(fileList)
        if(!files) return;
        const file = files[0];
        setNewLogoFile(file);
        const fr = new FileReader();
        fr.onload = (event) => {
            const result = event.target.result;
            const base64String = typeof result === 'string' ? result : String.fromCharCode.apply(null, new Uint16Array(result));
            setLogoSrc(base64String);
        }
        fr.readAsDataURL(file);
    }

    return (
        <Formik
            initialValues={baseInputFields}
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape(schema)}
            onSubmit={async (values, formikHelpers) => {
                Yup.object().shape(schema).validate(values, {abortEarly: false})
                    .then(async (data) => {
                        setLoading(true);

                        const additionalUserDataDto: AdditionalUserDataDto = {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            phoneNumber: values.phoneNumber,
                            languageCode: values.languageCode,
                            numberFormat: values.numberFormat,
                            passwordCleartext: null,
                            companyDto: {
                                city: values.city,
                                houseNumber: values.houseNumber,
                                name: values.companyName,
                                street: values.street,
                                zipcode: values.zipcode,
                                uid: values.uid,
                                country: null,
                                languageCode: values.companyLanguageCode,
                            }
                        };

                        if (!props.isFullRegistration && values.password) {
                            additionalUserDataDto.passwordCleartext = values.password;
                        }

                        try {                            
                            if(newLogoFile) await dispatch(setCompanyLogo(newLogoFile));
                            if(!logoSrc && !newLogoFile) await dispatch(unsetCompanyLogo());
                            const userResource: UserResource = await UserManagementRestControllerService.updateUser(user.id, additionalUserDataDto);

                            if(!props.isFullRegistration) {
                                await dispatch(updateUser(userResource));
                                await dispatch(refetchDictionary(userResource.languageCode));
                            }

                            setLoading(false);
                            props.successCallback(userResource);
                        }
                        catch(exception) {
                            if(props.setException) props.setException(exception);
                            setLoading(false);
                            console.log(exception);
                        }
                    });
            }}>
            {({
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isValid,
                  isSubmitting,
                  values,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1} alignItems={"stretch"}>
                        <Grid item xs={12} md={user.userRoleResource.roleType === "ADMIN" ? 6 : 12}
                              sx={{display: "flex"}}>
                            <Card id={"userInformationCard"} sx={{flexGrow: 1}}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={12}>
                                        <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt'
                                             sx={{display: "flex", flexDirection: "column"}}>
                                            <Typography
                                                variant="caption"
                                            >
                                                {t('User caption')}
                                            </Typography>
                                            {t('User information')}
                                        </Box>

                                        <Divider sx={{mt: 1, mb: 2}}/>

                                        <Grid container spacing={3} p={1}>
                                            <Grid item xs={12} md={6}>
                                                <TextField

                                                    error={Boolean(touched.firstName && errors.firstName)}
                                                    fullWidth
                                                    helperText={touched.firstName && errors.firstName}
                                                    label={t('FIRSTNAME')}
                                                    name='firstName'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.firstName}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField

                                                    error={Boolean(touched.lastName && errors.lastName)}
                                                    fullWidth
                                                    helperText={touched.lastName && errors.lastName}
                                                    label={t('LASTNAME')}
                                                    name='lastName'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                    variant='outlined'
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <TextField

                                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                                    fullWidth
                                                    helperText={touched.phoneNumber && errors.phoneNumber}
                                                    label={t('PHONE')}
                                                    name='phoneNumber'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    variant='outlined'
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="language-label">{t('USER_LANGUAGE')}</InputLabel>
                                                    <Select
                                                        labelId='language-label'
                                                        label={t('USER_LANGUAGE')}
                                                        name='languageCode'
                                                        onChange={handleChange}
                                                        fullWidth
                                                        value={values.languageCode}>
                                                        {
                                                            languageCodes.map((languageCode) => {
                                                                return <MenuItem value={languageCode} key={languageCode}>
                                                                    {t(languageCode)}
                                                                </MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} md={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="numberFormat-label">{t('numberFormatSetting')}</InputLabel>
                                                    <Select
                                                        labelId='numberFormat-label'
                                                        label={t('numberFormatSetting')}
                                                        name='numberFormat'
                                                        onChange={handleChange}
                                                        fullWidth
                                                        renderValue={(value) => <>
                                                            <Box display="flex" justifyContent="space-between">
                                                                <Typography>{value}</Typography>
                                                                <Typography fontWeight="bold">{formatValue(123456.789, value)}</Typography>
                                                            </Box>
                                                        </>}
                                                        value={values.numberFormat}>
                                                        {
                                                            numberFormats.map((format) => {
                                                                return <MenuItem value={format.numberFormat} key={format.numberFormat} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Typography>{format.numberFormat}</Typography>
                                                                    <Typography fontWeight="bold">{formatValue(123456.789, format.numberFormat)}</Typography>
                                                                </MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {!props.isFullRegistration &&
                                        <Grid item xs={12}>
                                            <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt'>
                                                {t('Change password')}
                                            </Box>
                                            <Box pt={0} pl={1}>
                                                <Typography variant='subtitle2'>
                                                    {t('The password will only be changed if you enter a new one.')}
                                                </Typography>
                                            </Box>

                                            <Divider sx={{mt: 1, mb: 2}}/>
                                            <Grid container spacing={3} p={1}>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        error={Boolean(touched.password && errors.password)}
                                                        type='password'
                                                        fullWidth
                                                        helperText={touched.password && errors.password}
                                                        label={t('PASSWORD')}
                                                        name='password'
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.password}
                                                        variant='outlined'
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        error={Boolean(touched.passwordAgain && errors.passwordAgain)}
                                                        type='password'
                                                        fullWidth
                                                        helperText={touched.passwordAgain && errors.passwordAgain}
                                                        label={t('Reenter password')}
                                                        name='passwordAgain'
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.passwordAgain}
                                                        variant='outlined'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                </Grid>
                            </Card>
                        </Grid>
                        {user.userRoleResource.roleType === "ADMIN" && <Grid item xs={12} md={6}>
                            <Card id={"companyInformationCard"} sx={{flexGrow: 1}}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={12}>
                                        <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt'
                                             sx={{display: "flex", flexDirection: "column"}}>
                                            <Typography
                                                variant="caption"
                                            >
                                                {t('Company caption')}
                                            </Typography>
                                            {t('Company information')}
                                        </Box>

                                        <Divider sx={{mt: 1, mb: 2}}/>
                                        <Grid container spacing={3} p={1}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    error={Boolean(touched.companyName && errors.companyName)}
                                                    fullWidth
                                                    helperText={touched.companyName && errors.companyName}
                                                    label={t('COMPANY_NAME')}
                                                    name='companyName'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.companyName}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    error={Boolean(touched.uid && errors.uid)}
                                                    fullWidth
                                                    helperText={touched.uid && errors.uid}
                                                    label={t('UID')}
                                                    name='uid'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.uid}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="language-label">{t('COMPANY_LANGUAGE')}</InputLabel>
                                                    <Select
                                                        labelId='language-label'
                                                        label={t('COMPANY_LANGUAGE')}
                                                        name='companyLanguageCode'
                                                        onChange={handleChange}
                                                        fullWidth
                                                        value={values.companyLanguageCode}>
                                                        {
                                                            languageCodes.map((languageCode) => {
                                                                return <MenuItem value={languageCode}
                                                                                 key={languageCode}>
                                                                    {t(languageCode)}
                                                                </MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <TextField
                                                    error={Boolean(touched.street && errors.street)}
                                                    fullWidth
                                                    helperText={touched.street && errors.street}
                                                    label={t('STREET')}
                                                    name='street'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.street}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    error={Boolean(touched.houseNumber && errors.houseNumber)}
                                                    fullWidth
                                                    helperText={touched.houseNumber && errors.houseNumber}
                                                    label={t('HOUSE_NUMBER')}
                                                    name='houseNumber'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.houseNumber}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    error={Boolean(touched.zipcode && errors.zipcode)}
                                                    fullWidth
                                                    helperText={touched.zipcode && errors.zipcode}
                                                    label={t('ZIPCODE')}
                                                    name='zipcode'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.zipcode}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <TextField
                                                    error={Boolean(touched.city && errors.city)}
                                                    fullWidth
                                                    helperText={touched.city && errors.city}
                                                    label={t('CITY')}
                                                    name='city'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.city}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 1 }}/>
                                        <Stack p={1}>
                                            <Typography variant="caption">{t('companyLogo')}</Typography>
                                            {logoSrc && <Box sx={{ display: 'flex', mb: 1, maxHeight: '7rem' }}>
                                                <Box sx={{ position: 'relative' , display: 'flex', maxHeight: '100%' }}>
                                                    <Box sx={{position: 'relative', maxWidth: '100%', maxHeight: '100%' }}>
                                                        <img src={logoSrc} style={{ maxHeight: '100%',  objectFit: 'contain', objectPosition: 'left' }}/>
                                                        <IconButton
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                color: theme.colors.alpha.white[100],
                                                                bgcolor: theme.colors.alpha.black[70],
                                                                borderTopRightRadius: 0,
                                                                borderBottomRightRadius: 0,
                                                                borderTopLeftRadius: 0,
                                                                ':hover': {
                                                                    bgcolor: theme.colors.alpha.black[100],
                                                                }
                                                            }}
                                                            size="large"
                                                            onClick={() => setLogoSrc(null)}
                                                        >
                                                            <CloseTwoToneIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>}
                                            <DragAndDropFileInput fileSelected={handleFileSelection} accept=".png, .jpg, .jpeg" primaryText={t('selectCompanyLogoHere')}/>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>}
                    </Grid>
                    <Box pt={3} display='flex' alignItems='center' justifyContent='center'>
                        <Button
                            id={'saveUserDataButton'}
                            type='submit'
                            disabled={isLoading}
                            variant='contained'>
                            {isLoading ? <CircularProgress size={20}/> : t('SAVE')}
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
}

export default AccountDataInputForm;
