import {CheckCircleTwoTone, WarningTwoTone} from "@mui/icons-material";
import {Button, CircularProgress, Container, Stack, styled, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {motion} from 'framer-motion'
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import { nav } from "src/statics/navigations";
import * as yup from "yup";
import {OpenAPI, UserManagementRestControllerService} from "../../../backend/market";
import LogoHeader from "../../../components/utils/LogoSign";

enum Status {
    LOADING,
    ERROR,
    INVITE_ID_OK,
    PASSWORD_OK,
}

const CenterContainer = styled(Container)`

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  width: 100%;
`;

const appear = (children) => {
    return <motion.div
        animate={{opacity: [0, 1], y: [-100, 0]}}
        transition={{duration: 2, ease: "anticipate"}}
    >
        {children}
    </motion.div>
}

const Invite: React.FC = () => {
    const {t} = useTranslation();
    const {inviteId} = useParams();
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [message, setMessage] = useState();
    const {values, handleChange, errors, handleSubmit} = useFormik({
        initialValues: {
            password: "",
            passwordConfirm: ""
        },
        onSubmit: (values) => {
            OpenAPI.TOKEN = "";
            UserManagementRestControllerService.setFirstPassword({newPassword: values.password, inviteKey: inviteId})
                .then(() => {
                    setStatus(Status.PASSWORD_OK);
                })
                .catch(() => {
                    setStatus(Status.ERROR);
                })
        },
        validationSchema: yup.object().shape({
            password: yup.string().min(7, t("passwordTooShort")).required(t("pleaseEnterAPassword")),
            passwordConfirm: yup.string().required(t("pleaseEnterYourPasswordAgain")).oneOf([yup.ref("password"), null], t('passwordsDoNotMatch'))
        })
    })

    useEffect(() => {
        OpenAPI.TOKEN = "";
        UserManagementRestControllerService.acceptInvite(inviteId).then(() => {
            setStatus(Status.INVITE_ID_OK);
        }).catch(() => {
            setStatus(Status.ERROR);
        });
    }, [])

    const handleLoginNav = () => {
        window.location.href = nav.ACCOUNT.sub.LOGIN.path;
    };

    function renderContent() {
        if (status === Status.LOADING) {
            return <Stack direction={"column"} alignItems={"center"} gap={2}>
                <CircularProgress/>
                <Typography variant={"h4"}>{t('deineEinladungWirdBerprft')}</Typography>
            </Stack>;
        } else if (status === Status.ERROR) {
            return <Stack direction={"column"} alignItems={"center"} gap={2}>
                <WarningTwoTone style={{fontSize: 80}} color={"error"}/>
                <Typography variant={"h4"} maxWidth={500}>{t('einFehlerIstAufgetreten')}</Typography>
                <Typography variant={"body1"}
                            maxWidth={500}>{t('deineEinladungIstNichtMehrGltigOderEtwasAnderesIst')}</Typography>
                <Button variant={"contained"} onClick={handleLoginNav}>{t('to Login')}</Button>
            </Stack>;
        } else if (status === Status.INVITE_ID_OK) {
            return <Stack direction={"column"} gap={2} maxWidth={400} width={"100%"}>
                <Typography variant={"h4"}>{t('bitteGibDeinPasswortEin')}</Typography>

                <form onSubmit={handleSubmit}>
                    <Stack gap={1} maxWidth={400} width={"100%"}>
                        <TextField fullWidth type={"password"}
                                   label={t('Password')}
                                   error={Boolean(errors.password)}
                                   value={values.password}
                                   name={"password"} onChange={handleChange}
                                   helperText={errors.password}/>
                        <TextField fullWidth type={"password"}
                                   error={Boolean(errors.passwordConfirm)}
                                   value={values.passwordConfirm}
                                   label={t('Confirm password')}
                                   name={"passwordConfirm"} onChange={handleChange}
                                   helperText={errors.passwordConfirm}/>
                        <Button type={"submit"}>{t('accountErstellen')}</Button>
                    </Stack>
                </form>
            </Stack>;
        } else if (status === Status.PASSWORD_OK) {
            return <Stack direction={"column"} alignItems={"center"} gap={2}>
                <CheckCircleTwoTone style={{fontSize: 80}} color={"success"}/>
                <Typography variant={"h4"}>{t('deinAccountWurdeErstelltDuKannstDichJetztAnmelden')}</Typography>
                <Button variant={"contained"} onClick={handleLoginNav}>{t('to Login')}</Button>
            </Stack>;
        }
    }

    return (
        <CenterContainer>
            {appear(<Stack direction={"column"} spacing={4} alignItems={"center"} maxWidth={400} width={"100%"}>
                <LogoHeader/>
                {renderContent()}
            </Stack>)}
            <Stack direction={"column"} alignItems={"center"} position={"absolute"} bottom={0}><Typography
                variant={"caption"}>{t('iid', {inviteId: inviteId})}</Typography>

                <Typography
                    variant={"caption"}>{message}</Typography></Stack>
        </CenterContainer>
    )
}
export default Invite