import {Box, styled} from "@mui/material";

export const Container = styled(Box)(
    ({theme}) => `
        margin-top: ${theme.spacing(4)};
        ${theme.breakpoints.down("md")} {
                max-height: unset !important;
        }
`
);
