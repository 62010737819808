import { FormControl, FormHelperText, InputLabel, MenuItem, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalStorageResource, EnumerationCalcParamResource, GuiStatusResource } from 'src/backend/internalCalc';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';
import { StyledSelect } from '../CalcInputs.styles';
import InfoLabel from './InfoLabel';
import ExtractedIndicator from './ExtractedIndicator';

interface Props {
    param: EnumerationCalcParamResource;
    id: number;
    disabled?: boolean;
    isSmall?: boolean;
    hide?: boolean;
    guiStates?: Array<GuiStatusResource>;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const EnumParameter: React.FC<Props> = ({ param, id, disabled, isSmall, hide, guiStates, additionalStorage }) => {
    const { values, handleChange, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const s = store.getState();
    const theme = useTheme();

    useEffect(() => {
        if (hide) setFieldValue(param.name, param.defaultEnumerationItemId);
    }, [hide]);

    const hiddenItems = useMemo(() => {
        const items = [];
        (guiStates || []).forEach((guiState) => {
            if (guiState.affectedObject === param.name && guiState.affectedSubObject != null) {
                items.push(guiState.affectedSubObject);
            }
        });
        return items;
    }, [guiStates]);

    const isValueHidden = useMemo(() => {
        return hiddenItems.includes(values[param.name]);
    }, [values[param.name], hiddenItems]);

    const getName = (name: string) => {
        if (name === 'noSelection') {
            return t('keineAuswahl');
        }
        return wT(name, s);
    };

    return (
        <FormControl fullWidth disabled={disabled} sx={isValueHidden ? { '.MuiFormLabel-root': { color: theme.colors.error.main } } : {}}>
            <InputLabel id={id + param.name + '-label'} sx={{ marginLeft: '-0.35em' }} shrink>
                <InfoLabel label={param.name} guiDescriptor={param.guiDescriptor} guiDescriptorExplanation={param.explanationTranslationKey} />
            </InputLabel>
            <StyledSelect
                labelId={id + param.name + '-label'}
                label={<InfoLabel label={param.name} guiDescriptor={param.guiDescriptor} guiDescriptorExplanation={param.explanationTranslationKey} />}
                value={values[param.name]}
                startAdornment={<ExtractedIndicator param={param} additionalStorage={additionalStorage} />}
                name={param.name}
                onChange={handleChange}
                error={isValueHidden}
                size={isSmall ? 'small' : 'medium'}
            >
                {param.items.map((item, index) => {
                    if (hiddenItems.includes(item.id)) return;
                    return (
                        <MenuItem key={item.id} value={item.id}>
                            {getName(item.id)}
                            {param.defaultEnumerationItemId === item.id && (
                                <span className="default-indicator" style={{ marginLeft: 4, opacity: 0.5, fontSize: 12 }}>
                                    (Default)
                                </span>
                            )}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
            {isValueHidden && <FormHelperText>{t('required')}</FormHelperText>}
        </FormControl>
    );
};
export default EnumParameter;
