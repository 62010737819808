import axios from 'axios';
import jwtDecode from 'jwt-decode';
import i18n from 'src/i18n/i18n';
import { CompanyManagementRestControllerService, CompletedWalkthroughResource, CompletedWalkthroughsDto, DemoAccountRestControllerService, UserManagementRestControllerService, UserResource, WizardCalcMergingRestControllerService } from '../../backend/market';
import { API_CONFIG } from '../../config';
import { slice } from '../slices/user.reducer';
import { AppDispatch, AppThunk, RootState, snackContext } from '../store';


export const updateUser = (currentUser: UserResource): AppThunk => async (dispatch): Promise<void> => {
    console.log("REDUX :: USER :: UPDATE");
    i18n.changeLanguage(currentUser?.languageCode || currentUser?.company?.languageCode || 'en');
    dispatch(slice.actions.setCurrentUser(currentUser));
}
export const loadUser = (currentUserId: number, userLoadedCallback?: () => void, errorCallback?: (e) => void): AppThunk => async (dispatch): Promise<void> => {
    console.log("REDUX :: USER :: LOAD");
    try {
        const user = await UserManagementRestControllerService.getUserById(currentUserId);
        i18n.changeLanguage(user?.languageCode || user?.company?.languageCode || 'en');
        dispatch(slice.actions.setCurrentUser(user));
        if (userLoadedCallback) userLoadedCallback();
    } catch (e) {
        if (errorCallback) errorCallback(e);
    }
}
export const logoutUser = (): AppThunk => async (dispatch): Promise<void> => {
    console.log("REDUX :: USER :: LOGOUT");
    dispatch(slice.actions.setCurrentUser(null));
    dispatch(slice.actions.setFullRegistrationLoading(false));
    dispatch(slice.actions.setFullRegistrationException(null));
    dispatch(slice.actions.setCurrentAuthToken(null));
    dispatch(stopUserLockChecker());
    dispatch({type: "DESTROY_SESSION"});
    location.reload();
}
export const loginUser = (email: string, password: string, navCallback?: () => void, errorCallback?: (e) => void): AppThunk => async (dispatch, state): Promise<void> => {
    console.log("REDUX :: USER :: LOGIN");
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', email);
    formData.append('password', password);

    try {
        console.log(API_CONFIG.MARKETPLACE.API_CLIENT_SECRET)
        const response = await axios({
            method: "post",
            url: API_CONFIG.MARKETPLACE.AUTH_PATH,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Basic ' + API_CONFIG.MARKETPLACE.API_CLIENT_SECRET
            }
        })
        console.log(response);
        const {access_token} = response.data;
        dispatch(slice.actions.setCurrentAuthToken(access_token));
        const {user_name} = jwtDecode<{ user_name: string }>(access_token)
        const userId = Number.parseInt(user_name);
        dispatch(loadUser(userId, navCallback, errorCallback));
        UserManagementRestControllerService.acquireLock(userId);
    } catch (e) {
        console.error(e);
        snackContext?.enqueueSnackbar(i18n.t("Ein Fehler ist aufgetreten! Bitte versuche es erneut!"), {variant: 'error'});
        if (errorCallback) errorCallback(e);
    }
}
export const checkIfUserIsLocked = (): AppThunk => async (dispatch, state): Promise<void> => {
    const user = state().user;
    if(user.isLocked) return;

    try {
        const result = await UserManagementRestControllerService.checkLock(user.currentUser.id);
        dispatch(slice.actions.setUserLock(result.locked));
        if(result.locked) {
            dispatch(stopUserLockChecker());
        }
    } catch (e) {
        console.error(e);
    }
}

export const startUserLockChecker = (): AppThunk => async (dispatch, state): Promise<void> => {
    const user = state().user?.currentUser;
    if(!user) return;
    const checkLockInterval = setInterval(() => {
        dispatch(checkIfUserIsLocked())
    }, 60000);
    dispatch(slice.actions.setCheckLockInterval(checkLockInterval));
}
export const stopUserLockChecker = (): AppThunk => async (dispatch, state): Promise<void> => {
    const intervalId = state().user?.checkLockInterval;
    clearInterval(intervalId);
    dispatch(slice.actions.setCheckLockInterval(null));
}

export const setFullRegistrationLoading = (isLoading: boolean): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setFullRegistrationLoading(isLoading));
}

export const setFullRegistrationLoadingStep = (step: number): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setFullRegistrationLoadingStep(step));
}

export const setFullRegistrationException = (exception: any): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setFullRegistrationException(exception));
}


export const migrateUserCalculations = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        await WizardCalcMergingRestControllerService.createNewMigration();
        dispatch(slice.actions.setMergeTableMigrated(true));
    } catch (e) {
        console.error(e);
    }
}



export const setCompanyLogo = (file: File) => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log(getState().user);
    const result = await CompanyManagementRestControllerService.getCompanyLogoUploadUrl({ name: file.name, fileType: file.type });

    const response = await fetch(result.presignedUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type
        },
        body: file
    })
    if(response.status !== 200) throw new Error(response.statusText);
    const user: UserResource = getState().user.currentUser;
    
    await CompanyManagementRestControllerService.setLogo2(user.company.companyId, {
        name: result.assignedImageName,
        fileType: file.type
    });
}


export const unsetCompanyLogo = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const user: UserResource = getState().user.currentUser;
    await CompanyManagementRestControllerService.setLogo2(user.company.companyId, { name: null, fileType: null });
}

export const setupUser = () => async (dispatch: AppDispatch, state: () => RootState): Promise<void> => {
    const userId = state().user?.currentUser?.id;    
    if(!userId) return;
    try {
        dispatch(setFullRegistrationLoadingStep(0));
        await DemoAccountRestControllerService.createNewWizardDemoSetup(userId);
        dispatch(setFullRegistrationLoadingStep(1));
        await DemoAccountRestControllerService.createInternalCalcModuleSetup(userId);
        dispatch(setFullRegistrationLoadingStep(2));
        await DemoAccountRestControllerService.createRegularCustomerPortalSetup(userId);
        dispatch(setFullRegistrationLoadingStep(3));
        await DemoAccountRestControllerService.createDemoInternalCalculationsSetup(userId);
    }
    catch (exception) {
        dispatch(setFullRegistrationException(exception));
    }
}

export const updateUserWalkthroughs = (walkthroughDto: CompletedWalkthroughsDto): AppThunk => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const user: UserResource = getState().user.currentUser;
    const completedWalkthroughs: CompletedWalkthroughResource[] = await UserManagementRestControllerService.addCompletedWalkthroughs(user.id, walkthroughDto)
    dispatch(slice.actions.setCurrentUser({ ...user, completedWalkthroughs }));
}


export const loadNumberFormats = () => async (dispatch: AppDispatch) => {
    const response = await UserManagementRestControllerService.getAvailableNumberFormats();
    dispatch(slice.actions.setNumberFormats(response));
}