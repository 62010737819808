import axios from "axios";
import {API_CONFIG} from "../../config";
import {CostResultResource} from "./resources/CostResultResource";
import {CustomerResource} from "./resources/CustomerResource";
import {GeometryPackageImageResource} from "./resources/GeometryPackageImageResource";
import {ILayer} from "./resources/ILayer";
import { OptInOutResource } from "./resources/OptInOutResource";
import {ParameterSummaryDataResource} from "./resources/ParameterSummaryDataResource";
import {ProfileResource} from "./resources/ProfileResource";
import {WizardResource} from "./resources/WizardResource";

const ACCESS_CODE = 'NfvGHpaEzqK5E98wUXdqPJu2aT2NUyTBkdSuep7vFHJBxuk7W3HTvwsjwdTP'

const backend = axios.create({
    baseURL: API_CONFIG.SUMMARY.BACKEND_URL,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
})

export class BackendService {
    private static readonly DEFAULT_LANGUAGE_CODE: string = "de"

    public static loadWizard = async (wizardId: string): Promise<WizardResource> => {
        const response = await backend.get(`/wizards/${wizardId}`)
        return response.data
    }

    public static loadDictionary = async (languageCode: String = BackendService.DEFAULT_LANGUAGE_CODE): Promise<any> => {
        const response = await backend.get(`/translations/all?languageCode=${languageCode}`)
        return response.data
    }

    public static loadProfile = async (profileKey: string): Promise<ProfileResource> => {
        const response = await backend.get(`/profiles/${profileKey}`,
            {
                headers: {
                    "X-ACCESS-CODE": ACCESS_CODE
                }
            })

        return response.data
    }

    public static getCost = async (wizardId: string): Promise<CostResultResource> => {
        const response = await backend.get(`/wizards/${wizardId}/storedCost`)
        return response.data
    }

    public static getWizardLanguage = async (wizardId: string): Promise<string> => {
        const response = await backend.get(`/wizards/${wizardId}/defaultLanguageCode`)
        return response.data?.languageCode;
    }

    public static getParameterData = async (
        parameterId: number,
        languageCode: String = BackendService.DEFAULT_LANGUAGE_CODE
    ): Promise<ParameterSummaryDataResource> => {
        const response = await backend.get(`/parts/${parameterId}/parameterData?languageCode=${languageCode}`)
        return response.data
    }

    public static verifyCustomer = async (verificationToken: string, wizardId: string): Promise<CustomerResource> => {
        const response = await backend.get(`/customer/${verificationToken}/wizard/${wizardId}`)
        return response.data
    }

    public static getGeometryPackageImage = async (partId: number): Promise<GeometryPackageImageResource> => {
        const response = await backend.get(`/parts/${partId}/geometryPackageImage`)
        return response.data
    }

    public static getOptInOutState = async (mail: string): Promise<OptInOutResource> => {
        const response = await backend.get(`/customers/optInOutState/${mail}`)
        return response.data
    }

    public static setOptInOutState = async (mail: string, optedOut: boolean): Promise<OptInOutResource> => {
        const response = await backend.put(`/customers/optInOutState/${mail}`, {
            optedOut: optedOut
        });

        return response.data
    }

    public static sendEvent = async (wizardId: string | undefined, event: string, data: (Record<string, string> | null)): Promise<void> => {
        const body = {
            wizardId,
            type: event,
            data
        }
        const response = await backend.post("/events", body)
        return response.data
    }

    public static loadFirstLevel = async (profileId: string): Promise<ILayer[]> => {
        const response = await backend.get(`/items/${profileId}/firstLayer`)
        return response.data
    }

    public static loadSecondLayer = async (profileId: string): Promise<ILayer[]> => {
        const response = await backend.get(`/items/${profileId}/secondLayer`);
        return response.data;
    }

    public static loadChildLayer = async (profileId: string, parentId: number): Promise<ILayer[]> => {
        const response = await backend.get(`/items/${profileId}/subItems/${parentId}`);
        return response.data;
    }
}


