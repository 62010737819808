//INTERFACE AND INITIAL STATE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AttachmentResource,
    CalculationFragmentResource,
    CalculationParameterDto,
    CalculationPartResource,
    InternalCalculationResource,
    SurchargeOverrideStorageResource
} from 'src/backend/internalCalc';
import { AdditionalStorageResource, AiMailConversationResource } from 'src/backend/market';
import { ProCalcUpdateTypes } from '../shared/proCalc.common';
import { TMP_LOADER_FRAGMENT, TMP_LOADER_ITEM } from 'src/statics/statics';
import { set, get } from 'lodash';
import { ILayer } from 'src/backend/summary/resources/ILayer';

export type ProCalcHoleRecognitionResult = {
    holes?: number;
    threads?: number;
    holeDimensions?: Array<{ radius: number }>;
    threadDimensions?: Array<{ radius: number }>;
};
export enum ProCalcSavingStatus {
    LOADING = 'internalCalcSaving',
    LOADED = 'internalCalcSaved',
    ERROR = 'internalCalcSaveError'
}
export type ProCalcError = {
    type: 'badRequest' | 'notAllowed' | 'locked' | 'unknown';
    data?: string;
};
export type ProCalcUpdateLoading = { [updateType in ProCalcUpdateTypes]?: boolean };
export type ProCalcUpdateError = { [updateType in ProCalcUpdateTypes]?: any };

interface IProCalcState {
    calculation: InternalCalculationResource;
    previousCalculations: Array<InternalCalculationResource>;
    categories: Array<ILayer>;
    secondLayerItems: Array<ILayer>;
    surchargeOverrides: Array<SurchargeOverrideStorageResource>;
    mailConversation: AiMailConversationResource;
    savingStatus: ProCalcSavingStatus;
    isLoading: boolean;
    isUpdateLoading: ProCalcUpdateLoading;
    error: ProCalcError;
    updateErrors: ProCalcUpdateError;
    disablePartValidation: boolean;
    holeRecognitionLoaders: { [partId: number]: boolean };
}

const initialState: IProCalcState = {
    calculation: {},
    previousCalculations: [],
    categories: [],
    secondLayerItems: [],
    surchargeOverrides: [],
    mailConversation: null,
    savingStatus: ProCalcSavingStatus.LOADED,
    isLoading: true,
    isUpdateLoading: {},
    error: null,
    updateErrors: {},
    disablePartValidation: false,
    holeRecognitionLoaders: {}
};

export const slice = createSlice({
    name: 'proCalc',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setCategories: (state, action: PayloadAction<Array<ILayer>>) => {
            state.categories = action.payload;
        },
        setSecondLayerItems: (state, action: PayloadAction<Array<ILayer>>) => {
            state.secondLayerItems = action.payload;
        },
        setProCalc: (state, action: PayloadAction<InternalCalculationResource>) => {
            state.calculation = action.payload;
        },
        resetProCalc: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        setProCalcValue: (state, action: PayloadAction<{ path: string; value: any; calcId?: number }>) => {
            if (!state.calculation || isWrongCalcId(state, action)) return;
            set(state.calculation, action.payload.path, action.payload.value);
        },
        setProCalcSelectively: (state, action: PayloadAction<{ updatedCalculation: InternalCalculationResource; paths: Array<string>; calcId?: number }>) => {
            if (!state.calculation || isWrongCalcId(state, action)) return;
            action.payload.paths.forEach((path) => {
                const updatedSection = get(action.payload.updatedCalculation, path);
                set(state.calculation, path, updatedSection);
            });
        },
        setSavingStatus: (state, action: PayloadAction<ProCalcSavingStatus>) => {
            state.savingStatus = action.payload;
        },
        setUpdateLoading: (state, action: PayloadAction<{ updateType: ProCalcUpdateTypes; isLoading: boolean }>) => {
            state.isUpdateLoading[action.payload.updateType] = action.payload.isLoading;
        },
        clearUpdateLoading: (state) => {
            Object.keys(state.isUpdateLoading).forEach((updateType) => {
                state.isUpdateLoading[updateType] = false;
            });
        },
        setDisablePartValidation: (state, action: PayloadAction<boolean>) => {
            state.disablePartValidation = action.payload;
        },
        setSurchargeOverrides: (state, action: PayloadAction<Array<SurchargeOverrideStorageResource>>) => {
            state.surchargeOverrides = action.payload;
        },
        setMailConversation: (state, action: PayloadAction<AiMailConversationResource>) => {
            state.mailConversation = action.payload;
        },
        setHoleRecognitionLoader: (state, action: PayloadAction<{ partId: number; isLoading: boolean }>) => {
            state.holeRecognitionLoaders[action.payload.partId] = action.payload.isLoading;
        },
        addTmpAttachmentToMetadata: (state, action: PayloadAction<File>) => {
            const attachment: AttachmentResource = {
                attachmentId: 'uploadLoading',
                originalFileName: action.payload.name,
                fileTypeAsMimeType: action.payload.type
            };
            slice.caseReducers.setAttachmentInMetadata(state, { payload: { index: -1, attachment }, type: 'setAttachmentInMetadata' });
        },
        setAttachmentInMetadata: (state, action: PayloadAction<{ index: number; attachment: AttachmentResource; calcId?: number }>) => {
            if (!state.calculation || isWrongCalcId(state, action)) return;
            if (!state.calculation.calculationMetadata) state.calculation.calculationMetadata = {};
            const { index, attachment } = action.payload;
            const metadata = state.calculation.calculationMetadata;
            if (!metadata.attachments) metadata.attachments = [attachment];
            else if (index === -1) metadata.attachments.push(attachment);
            else metadata.attachments[index] = attachment;
        },
        deleteAttachmentFromMetadata: (state, action: PayloadAction<string>) => {
            if (!state.calculation?.calculationMetadata) return;
            const metadata = state.calculation.calculationMetadata;
            metadata.attachments = metadata.attachments.filter((attachment) => attachment.attachmentId !== action.payload);
        },
        addTmpPart: (state) => {
            if (!state.calculation?.parts) return;
            state.calculation.parts.push({ itemName: TMP_LOADER_ITEM });
        },
        deletePart: (state, action: PayloadAction<number>) => {
            if (!state.calculation?.parts) return;
            state.calculation.parts = state.calculation.parts.filter((part) => part.id !== action.payload);
        },
        setPart: (state, action: PayloadAction<{ index: number; part: CalculationPartResource; calcId?: number }>) => {
            if (!state.calculation?.parts || isWrongCalcId(state, action)) return;
            const { index, part } = action.payload;
            if (index === -1) state.calculation.parts.push(part);
            else state.calculation.parts[index] = part;
        },
        addTmpAttachmentToPart: (state, action: PayloadAction<{ partId: number; file: File }>) => {
            if (!state.calculation?.parts) return;
            const attachment: AttachmentResource = {
                attachmentId: 'uploadLoading',
                originalFileName: action.payload.file.name,
                fileTypeAsMimeType: action.payload.file.type
            };
            slice.caseReducers.setAttachmentInPart(state, { payload: { partId: action.payload.partId, index: -1, attachment }, type: 'setAttachmentInPart' });
        },
        setAttachmentInPart: (state, action: PayloadAction<{ partId: number; index: number; attachment: AttachmentResource; calcId?: number }>) => {
            if (!state.calculation?.parts || isWrongCalcId(state, action)) return;
            const { partId, index, attachment } = action.payload;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === partId);
            if (partIndex === -1) return;
            if (!state.calculation.parts[partIndex].attachments) state.calculation.parts[partIndex].attachments = [attachment];
            else if (index === -1) state.calculation.parts[partIndex].attachments.push(attachment);
            else state.calculation.parts[partIndex].attachments[index] = attachment;
        },
        deleteAttachmentFromPart: (state, action: PayloadAction<{ partId: number; attachmentId: string }>) => {
            if (!state.calculation?.parts) return;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
            state.calculation.parts[partIndex].attachments = state.calculation.parts[partIndex].attachments.filter((attachment) => attachment.attachmentId !== action.payload.attachmentId);
        },
        addAdditionalStorageToPart: (state, action: PayloadAction<{ partId: number; additionalStorage: Array<AdditionalStorageResource>; calcId?: number }>) => {
            if (!state.calculation?.parts || isWrongCalcId(state, action)) return;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
            state.calculation.parts[partIndex].additionalStorage.push(...action.payload.additionalStorage);
        },
        setPartParameter: (state, action: PayloadAction<{ partId: number; itemId: number; parameter: CalculationParameterDto; calcId?: number }>) => {
            if (!state.calculation?.parts || isWrongCalcId(state, action)) return;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
            const parameterIndex = state.calculation.parts[partIndex].setParameters.findIndex((setParameter) => setParameter.name === action.payload.parameter.name);
            state.calculation.parts[partIndex].setParameters[parameterIndex].value = action.payload.parameter.value;
        },
        setPartParameters: (state, action: PayloadAction<{ partId: number; itemId: number; parameters: Array<CalculationParameterDto> }>) => {
            if (!state.calculation?.parts) return;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
            state.calculation.parts[partIndex].setParameters = action.payload.parameters;
        },
        setPartName: (state, action: PayloadAction<{ partId: number; name: string }>) => {
            if (!state.calculation?.parts) return;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
            state.calculation.parts[partIndex].userDefinedPartName = action.payload.name;
        },
        setPartForcePrice: (state, action: PayloadAction<{ partId: number; force: boolean; price: number }>) => {
            if (!state.calculation?.parts) return;
            const { partId, force, price } = action.payload;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === partId);
            const subCalculationIdentificationKey = state.calculation.parts[partIndex].subCalculationIdentificationKey;
            const partCostResult = (state.calculation?.costResult?.subCalculationResults || []).find((x) => x.additionalInformation === subCalculationIdentificationKey);
            if (partCostResult) partCostResult.priceNonRounded = price;
            state.calculation.parts[partIndex].forcePrice = { force, price };
        },
        addTmpFragment(state, action: PayloadAction<{ partId: number; itemId: number; geometryPackage?: string; geometryPackageName?: string }>) {
            if (!state.calculation?.parts) return;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === action.payload.partId);
            if (!state.calculation.parts[partIndex].fragments) state.calculation.parts[partIndex].fragments = [];
            state.calculation.parts[partIndex].fragments.push({ itemName: TMP_LOADER_FRAGMENT });
        },
        deleteFragment: (state, action: PayloadAction<{ partId: number; fragmentId: number }>) => {
            if (!state.calculation?.parts) return;
            const { partId, fragmentId } = action.payload;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === partId);
            state.calculation.parts[partIndex].fragments = state.calculation.parts[partIndex].fragments.filter((fragment) => fragment.id !== fragmentId);
        },
        setFragment: (state, action: PayloadAction<{ partId: number; index: number; fragment: CalculationFragmentResource; calcId?: number }>) => {
            if (!state.calculation?.parts || isWrongCalcId(state, action)) return;
            const { partId, index, fragment } = action.payload;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === partId);
            if (index === -1) state.calculation.parts[partIndex].fragments.push(fragment);
            else state.calculation.parts[partIndex].fragments[index] = fragment;
        },
        setFragmentParameter: (state, action: PayloadAction<{ partId: number; fragment: CalculationFragmentResource; parameter: CalculationParameterDto }>) => {
            if (!state.calculation?.parts) return;
            const { partId, fragment, parameter } = action.payload;
            const parts = state.calculation.parts;
            const partIndex = parts.findIndex((part) => part.id === partId);
            if (partIndex === -1) return;
            const fragmentIndex = parts[partIndex].fragments.findIndex((f) => f.id === fragment.id);
            if (fragmentIndex === -1) return;
            const parameterIndex = parts[partIndex].fragments[fragmentIndex].setParameters.findIndex((setParameter) => setParameter.name === parameter.name);
            if (parameterIndex === -1) return;
            state.calculation.parts[partIndex].fragments[fragmentIndex].setParameters[parameterIndex].value = parameter.value;
        },
        setFragmentParameters: (state, action: PayloadAction<{ partId: number; fragment: CalculationFragmentResource; parameters: Array<CalculationParameterDto> }>) => {
            if (!state.calculation?.parts) return;
            const { partId, fragment, parameters } = action.payload;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === partId);
            if (partIndex === -1) return;
            const fragmentIndex = state.calculation.parts[partIndex].fragments.findIndex((f) => f.id === fragment.id);
            if (fragmentIndex === -1) return;
            state.calculation.parts[partIndex].fragments[fragmentIndex].setParameters = parameters;
        },
        setFragmentToTmp: (state, action: PayloadAction<{ partId: number; fragment: CalculationFragmentResource }>) => {
            if (!state.calculation?.parts) return;
            const { partId, fragment } = action.payload;
            const partIndex = state.calculation.parts.findIndex((part) => part.id === partId);
            if (partIndex === -1) return;
            const fragmentIndex = state.calculation.parts[partIndex].fragments.findIndex((f) => f.id === fragment.id);
            if (fragmentIndex === -1) return;
            state.calculation.parts[partIndex].fragments[fragmentIndex].itemName = TMP_LOADER_FRAGMENT;
        },
        handleError: (state, action: PayloadAction<any>) => {
            const error = action.payload;
            console.error('handleError', error);

            if (error.status === 423) {
                const userId = (error.body?.message || '').split(':')[1];
                state.error = { type: 'locked', data: userId };
            } else if (error.status === 400) {
                state.error = { type: 'badRequest' };
            } else if (error.status === 403) {
                state.error = { type: 'notAllowed' };
            } else {
                state.error = { type: 'unknown', data: error?.status };
            }
        },
        resetError: (state) => {
            state.error = null;
        },
        saveCalculationRecord: (state) => {
            // Record the previous state to provide rollback on failure
            state.previousCalculations.push(state.calculation);
        },
        handleUpdateSuccess: (state, action: PayloadAction<ProCalcUpdateTypes>) => {
            state.savingStatus = ProCalcSavingStatus.LOADED;
            state.isUpdateLoading[action.payload] = false;
            state.error = null;
        },
        handleUpdateFailure: (state, action: PayloadAction<{ updateType: ProCalcUpdateTypes; error: any }>) => {
            state.savingStatus = ProCalcSavingStatus.ERROR;
            state.isUpdateLoading[action.payload.updateType] = false;
            // Restore previous state and set error
            state.calculation = state.previousCalculations.pop() ?? state.calculation;
            state.isUpdateLoading[action.payload.updateType] = action.payload.error;
            slice.caseReducers.handleError(state, { payload: action.payload.error, type: 'handleError' });
        }
    }
});
export const reducer = slice.reducer;

const isWrongCalcId = (state, action: PayloadAction<any>) => {
    if (!action.payload.calcId) return false;
    return state.calculation?.id !== action.payload.calcId;
};

export const {
    addAdditionalStorageToPart,
    addTmpAttachmentToMetadata,
    addTmpAttachmentToPart,
    addTmpFragment,
    addTmpPart,
    clearUpdateLoading,
    deleteAttachmentFromMetadata,
    deleteAttachmentFromPart,
    deleteFragment,
    deletePart,
    handleError,
    handleUpdateFailure,
    handleUpdateSuccess,
    resetError,
    resetProCalc,
    saveCalculationRecord,
    setAttachmentInMetadata,
    setAttachmentInPart,
    setCategories,
    setDisablePartValidation,
    setFragment,
    setFragmentParameter,
    setFragmentParameters,
    setFragmentToTmp,
    setHoleRecognitionLoader,
    setLoading,
    setMailConversation,
    setPart,
    setPartForcePrice,
    setPartName,
    setPartParameter,
    setPartParameters,
    setProCalc,
    setProCalcSelectively,
    setProCalcValue,
    setSavingStatus,
    setSecondLayerItems,
    setSurchargeOverrides,
    setUpdateLoading
} = slice.actions;
