import axios from 'axios';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import {initReactI18next} from 'react-i18next';
import { TranslationsRestControllerService } from 'src/backend/market';
import {API_CONFIG} from '../config';

const getGroupFromNs = (ns) => ns === 'country' ? 'country' : 'marketplaceFrontend';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
        backend: {
            loadPath: "{{lng}}|{{ns}}",
            request: (options: any, url: any, payload: any, callback: any) => {
                try {
                    const [lng, ns] = url.split("|");
                    const group = getGroupFromNs(ns);
                    const apiUrl = `${API_CONFIG.TRANSLATION.TRANSLATION_PATH}/translations/i18n/languageCodes/${lng}/groups/${group}`;
                    axios.get(apiUrl).then((response) => {
                        callback(null, {
                            data: response.data,
                            status: 200,
                        })
                    })
                } catch (e) {
                    console.error(e)
                    callback(null, {
                        status: 500,
                    })
                }
            }
        },
        saveMissing: true,
        missingKeyHandler: async (lngs, ns, key, fallbackValue) => {
            if(!i18n.language) return;
            TranslationsRestControllerService.reportNewTranslationKey({
                group: getGroupFromNs(ns),
                key,
            }).then(() => {
                console.log('SAVED MISSING KEY:', key);
            });
            i18n.addResource(i18n.language, ns, key, key);
        },
        keySeparator: false,
        lng: 'de',
        debug: false,
        fallbackLng: 'en',
        ns: ['translation', 'country'],
        react: {
            useSuspense: false
        },
        cleanCode: true,
        interpolation: {
            escapeValue: false
        },

    });

export default i18n;
