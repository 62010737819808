// noinspection DuplicatedCode

// @ts-ignore
import store from '../../../redux/store';

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import type { ApiRequestOptions } from './ApiRequestOptions';
// @ts-ignore
import { API_CONFIG } from '../../../config';
import jwtDecode from 'jwt-decode';

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

type Config = {
    BASE: string;
    VERSION: string;
    WITH_CREDENTIALS: boolean;
    CREDENTIALS: 'include' | 'omit' | 'same-origin';
    TOKEN?: string | Resolver<string>;
    USERNAME?: string | Resolver<string>;
    PASSWORD?: string | Resolver<string>;
    HEADERS?: Headers | Resolver<Headers>;
    ENCODE_PATH?: (path: string) => string;
}

const getToken = async () => {
    const token = store.getState().user.currentAuthToken;

    if (token === null) {
        return null;
    }

    try {
        let decoded = jwtDecode(token);

        // @ts-ignore
        if (decoded.exp < Date.now() / 1000) {
            console.log("Jwt token expired")
            return null;
        }
    } catch (jwtDecodeError) {
        console.log('Jwt token not valid!');
        return null;
    }

    return store.getState().user.currentAuthToken;
};

export const OpenAPI: Config = {
    BASE: API_CONFIG.INTERNAL_CALC.INTERNAL_CALC_BASE_PATH,
    VERSION: '0',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: getToken,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: undefined,
    ENCODE_PATH: undefined
};
