import { Stack, useTheme, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProvidedVariableResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: ProvidedVariableResource;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
}

const ProvidedVariableForm: React.FC<Props> = ({ variable, setVariable, setValid }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.name == null || variable.name.trim() === '';
        setValid(!isInvalid);
    }, [variable]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            name: event.target.value
        });
    };

    const isDisabled = variable?.id === -1;

    return (
        variable && (
            <Stack gap={3}>
                <TextField label="Name" value={variable.name} onChange={handleNameChange} variant="outlined" fullWidth disabled={isDisabled} />
            </Stack>
        )
    );
};

export default ProvidedVariableForm;
