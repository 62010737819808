import {Button, Stack, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {BoughtModulesResource, ModuleRestControllerService} from "../../../../backend/market";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {refetchModuleManagement, setModuleManagement} from "../../../../redux/thunks/constants.thunk";

import {openContactForm} from 'src/router/utils/BitrixHelpers';
import BookedPortalAccessDialog
    from "../../../../components/overlays/BookedPortalAccessDialog/BookedPortalAccessDialog";
import _ from "lodash";

import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone';
import {startWalkthrough, stopWalkthrough} from "../../../../redux/thunks/guidethrough.thunk";


function HeaderButtons() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const isDemonstrationAccount: boolean = useSelector((state: RootState) => state.user.currentUser.demonstrationAccount);
    const moduleManagement: BoughtModulesResource | undefined = useSelector((state: RootState) => state.constants.moduleManagement);
    const userId: number = useSelector((state: RootState) => state.user.currentUser.id);

    //region STATE
    const [loading, setLoading] = React.useState<boolean>(false);
    const [bookedPortalAccessFinished, setBookedPortalAccessFinished] = React.useState<boolean>(false);
    const [error, setError] = React.useState();
    //endregion

    //region LIFECYCLE
    useEffect(() => {
        if (!moduleManagement) {
            dispatch(refetchModuleManagement())
        }
    }, [])
    //endregion

    //region HANDLER
    const handleMakeAppointment = () => {
        openContactForm();
    }

    const handleBookAccess = async () => {
        setError(undefined);
        setLoading(true);
        await ModuleRestControllerService.bookPortalAccess(userId)
            .then(async (response) => {
                await dispatch(setModuleManagement({...moduleManagement, portalAccessInitiated: true}));
                setBookedPortalAccessFinished(true);
            }).catch((error) => {
                setBookedPortalAccessFinished(true);
                setError(error);
            });
        setLoading(false);
    }
    //endregion
    return (<>
        {(moduleManagement && isDemonstrationAccount) && <Stack direction={{ xs: 'row', md: 'column', lg: 'row' }} width={{ xs: '100%', md: 'auto' }} gap={{ xs: 0.5, lg: 2 }} mx={{ md: 2, lg: 3}}>
            <BookedPortalAccessDialog open={bookedPortalAccessFinished}
                                      setOpen={(open) => setBookedPortalAccessFinished(open)} onClose={null}
                                      hasError={!_.isEmpty(error)}/>
            <Button
                variant={isSmallScreen ? 'text' : 'contained'}
                size={(isMediumScreen && !isSmallScreen) ? 'small' : 'medium'}
                onClick={handleMakeAppointment}
                startIcon={<EventTwoToneIcon/>}
                sx={{ width: { xs: '100%', md: 'auto' }, justifyContent: { xs: 'flex-end', md: 'center' } }}
            >{t('makeAppointment')}
            </Button>
            <Button
                variant={isSmallScreen ? 'text' : 'contained'}
                size={(isMediumScreen && !isSmallScreen) ? 'small' : 'medium'}
                startIcon={<InventoryTwoToneIcon/>}
                onClick={handleBookAccess}
                disabled={(moduleManagement.portalAccessInitiated ?? false) || loading}
                sx={{ width: { xs: '100%', md: 'auto' }, justifyContent: { xs: 'flex-start', md: 'center' } }}>
                {moduleManagement.portalAccessInitiated ? t('alreadyBookedPortalAccess') : t('bookPortalAccess')}
            </Button>
        </Stack>}
    </>);
}

export default HeaderButtons;
