/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ValueResource = {
    type?: ValueResource.type;
}

export namespace ValueResource {

    export enum type {
        BOOLEAN_VALUE = 'BOOLEAN_VALUE',
        STRING_VALUE = 'STRING_VALUE',
        LIST_VALUE = 'LIST_VALUE',
        VARIABLE_VALUE = 'VARIABLE_VALUE',
    }


}
