import { Avatar, Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormatColorFillTwoToneIcon from '@mui/icons-material/FormatColorFillTwoTone';
import ScaleTwoToneIcon from '@mui/icons-material/ScaleTwoTone';
import LooksTwoTwoToneIcon from '@mui/icons-material/LooksTwoTwoTone';
import Looks3TwoToneIcon from '@mui/icons-material/Looks3TwoTone';
import DryCleaningTwoToneIcon from '@mui/icons-material/DryCleaningTwoTone';
import { defaultLocale, formatValue } from 'src/utils/FormatHelpers';
import { CalculationResultResource, GeneralSettingResource } from 'src/backend/market';
import { getGeneralSetting } from 'src/utils/CalcHelpers';

const AvatarLabelInfo = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.info.lighter};
        color: ${theme.colors.info.main};
        width: ${theme.spacing(3.72)};
        height: ${theme.spacing(3.72)};

        @media print {
            box-shadow: inset 0 0 0 1px ${theme.colors.info.light};
            width: ${theme.spacing(2.95)};
            height: ${theme.spacing(2.95)};

            .MuiSvgIcon-root {
                font-size: 1.1rem;
            }
        }
    `
);

export const renderCalculatedValue = (icon, label, value, suffix = null) => {
    return icon != null ? (
        <Box display="flex" alignItems="center" maxWidth="100%" overflow="hidden" className="calculated-value">
            <AvatarLabelInfo sx={{ mr: 1 }} variant="rounded">
                {icon}
            </AvatarLabelInfo>
            <Box maxWidth="100%" overflow="hidden">
                <Typography variant="subtitle2" noWrap fontSize="0.929em" lineHeight={1.2}>
                    {label}
                </Typography>
                <Stack direction="row" title={suffix ? `${value} (${suffix})` : value}>
                    <Typography variant="h5" lineHeight={1.23} noWrap>
                        {value}
                    </Typography>
                    {suffix && (
                        <Typography variant="subtitle1" ml={0.4} lineHeight={1.23}>
                            {' '}
                            ({suffix})
                        </Typography>
                    )}
                </Stack>
            </Box>
        </Box>
    ) : (
        <Stack className="calculated-value">
            <Typography variant="subtitle2" noWrap fontSize="0.929em" lineHeight={1.2}>
                {label}
            </Typography>
            <Typography variant="h5" lineHeight={1.23} title={value}>
                {value}
            </Typography>
        </Stack>
    );
};

const getFormattedValue = (value: number, quantity: number, costResult: CalculationResultResource, generalSettingName?: string, maximumFractionDigits?: number, alwaysCalculateOne?: boolean) => {
    if (value == null) return {};
    const numberFormat = getGeneralSetting('numberFormat', costResult) || defaultLocale;
    let unit = '';

    if (generalSettingName) {
        unit = getGeneralSetting(generalSettingName, costResult) || '';
        if (unit === 'm2') unit = 'm²';
        if (unit === 'm3') unit = 'm³';
    }

    return {
        value,
        total: value > 0 ? formatValue(value * quantity, numberFormat, maximumFractionDigits) + ' ' + unit : '-',
        one: value > 0 && (quantity > 1 || alwaysCalculateOne) ? formatValue(value, numberFormat, maximumFractionDigits) + ' ' + unit : null
    };
};

interface Props {
    calculatedValues?: {
        powder: number;
        weight: number;
        surface?: number;
        volume?: number;
        jigAmount?: number;
        objectSurface?: number;
        boundingBoxSurface?: number;
        convexHullSurface?: number;
        objectVolume?: number;
    };
    quantity?: number;
    showZero?: boolean;
    showIcon?: boolean;
    showDivider?: boolean;
    costResult?: CalculationResultResource;
}

export const CalculatedValues: React.FC<Props> = ({ calculatedValues, quantity = 1, showZero = false, showIcon = true, costResult, showDivider }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (!calculatedValues) return <></>;

    const isShown = (formattedValue) => formattedValue.value != null && (formattedValue.value > 0 || showZero);

    const powder = getFormattedValue(calculatedValues.powder, quantity, costResult, 'totalAmountOfPowderUnit', 2);
    const weight = getFormattedValue(calculatedValues.weight, quantity, costResult, 'totalWeightUnit', 2);
    const surface = getFormattedValue(calculatedValues.surface, quantity, costResult, 'surfaceUnit', 2);
    const volume = getFormattedValue(calculatedValues.volume, quantity, costResult, 'totalVolumeUnit', 3);
    const jigAmount = getFormattedValue(calculatedValues.jigAmount, 1, costResult, null, 1);
    const objectSurface = getFormattedValue(calculatedValues.objectSurface, quantity, costResult, 'surfaceUnit', 2);
    const boundingBoxSurface = getFormattedValue(calculatedValues.boundingBoxSurface, quantity, costResult, 'surfaceUnit', 2);
    const convexHullSurface = getFormattedValue(calculatedValues.convexHullSurface, quantity, costResult, 'surfaceUnit', 2);
    const objectVolume = getFormattedValue(calculatedValues.objectVolume, quantity, costResult, 'totalVolumeUnit', 3);
    const hasExtractedSurface = objectSurface.value != null || boundingBoxSurface.value != null || convexHullSurface.value != null;
    const hasExtractedVolume = objectVolume.value != null;
    const hasJigOrPowder = isShown(powder) || isShown(jigAmount);

    const surfaceIcon = showIcon ? <LooksTwoTwoToneIcon /> : null;

    return (
        <>
            {hasExtractedSurface ? (
                <>
                    {isShown(objectSurface) && renderCalculatedValue(surfaceIcon, t('objectSurface'), objectSurface.total, objectSurface.one)}
                    {isShown(boundingBoxSurface) && renderCalculatedValue(surfaceIcon, t('boundingBoxSurface'), boundingBoxSurface.total, boundingBoxSurface.one)}
                    {isShown(convexHullSurface) && renderCalculatedValue(surfaceIcon, t('convexHullSurface'), convexHullSurface.total, convexHullSurface.one)}
                </>
            ) : (
                isShown(surface) && renderCalculatedValue(surfaceIcon, t('Area'), surface.total, surface.one)
            )}
            {hasExtractedVolume
                ? isShown(objectVolume) && renderCalculatedValue(showIcon ? <Looks3TwoToneIcon /> : null, t('objectVolume'), objectVolume.total, objectVolume.one)
                : isShown(volume) && renderCalculatedValue(showIcon ? <Looks3TwoToneIcon /> : null, t('Volume'), volume.total, volume.one)}
            {isShown(weight) && (
                <>
                    {showDivider && <div style={{ width: '1px', height: theme.spacing(3.72), background: theme.colors.alpha.black[10] }} />}
                    {renderCalculatedValue(showIcon ? <ScaleTwoToneIcon /> : null, t('Weight'), weight.total, weight.one)}
                    {hasJigOrPowder && showDivider && <div style={{ width: '1px', height: theme.spacing(3.72), background: theme.colors.alpha.black[10] }} />}
                </>
            )}
            {isShown(powder) && renderCalculatedValue(showIcon ? <FormatColorFillTwoToneIcon /> : null, t('Powder'), powder.total, powder.one)}
            {isShown(jigAmount) && renderCalculatedValue(showIcon ? <DryCleaningTwoToneIcon /> : null, t('jigAmount'), jigAmount.total, jigAmount.one)}
        </>
    );
};
