import { useEffect, useState } from "react";

interface Props {
    wait: number;
    loadingComponent: JSX.Element;
    component: JSX.Element;
}

export const DelayedRenderOfComponent = ({ wait, loadingComponent, component }: Props) => {
    const [rendered, setRendered] = useState(wait === 0 ? true : false);

    useEffect(() => {
        if(wait === 0) return;
        if (rendered) setRendered(false);
        const id = requestIdleCallback(() => setRendered(true), { timeout: wait });

        return () => cancelIdleCallback(id);
    }, [wait]);

    return (
        <>
            { !rendered && loadingComponent }
            { rendered && component }
        </>
    );

};