import { CssBaseline } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { RouteObject, useLocation } from 'react-router';
import { useRoutes } from 'react-router-dom';
import router from 'src/router/routes';
import DialogProvider from './contexts/DialogContext';
import { SidebarProvider } from './contexts/SidebarContext';
import store, { saveState, setSnackContext } from './redux/store';
import { resetFilter } from './redux/thunks/inquiries.thunk';
import { loadLanguageCodes } from './redux/thunks/language.thunk';
import ThemeProvider from './theme/ThemeProvider';
import { GlobalDebug } from './utils/GlobalDebug';
import Joyride from 'react-joyride';
import GuidethroughProvider from './contexts/GuidethroughContext';
import axios from 'axios';
import { logoutUser } from './redux/thunks/user.thunk';
import { nav } from './statics/navigations';

function App() {
    const content = useRoutes(router as RouteObject[]);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadLanguageCodes());
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (nav.ACCOUNT.sub.LOGIN.path === location.pathname && error.response?.status === 401) {
                    dispatch(logoutUser());
                }
                return Promise.reject(error);
            }
        );
    }, []);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
            GlobalDebug(false, true);
        }
    }, []);

    useEffect(() => {
        if (!location.pathname.includes('requests')) {
            dispatch(resetFilter());
        }

        //@ts-ignore
        window.gtag('event', 'page_view', {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash
        });
    }, [location]);

    return (
        <ThemeProvider>
            <DialogProvider>
                <CssBaseline />
                <GuidethroughProvider />
                {content}
            </DialogProvider>
        </ThemeProvider>
    );
}

function ReduxProviderWrapper() {
    const snackbar = useSnackbar();

    useEffect(() => {
        setSnackContext(snackbar);
    }, []);

    store.subscribe(() => saveState(store));
    return (
        <>
            <Provider store={store}>
                <SidebarProvider>
                    <App />
                </SidebarProvider>
            </Provider>
        </>
    );
}

export default ReduxProviderWrapper;
