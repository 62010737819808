import {CircularProgress, Grid} from '@mui/material';
import {useEffect, useState} from "react";
import {Helmet} from 'react-helmet-async';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {getRegularCustomerRedirectUrlByCustomerKey} from "../../redux/thunks/customers.thunk";

function RedirectPage() {
    const search = useLocation().search;
    const wid = new URLSearchParams(search).get('wid');
    const {t} = useTranslation();
    const [newUrl, setNewUrl] = useState('');

    useEffect(() => {
        if (wid) {
            getRegularCustomerRedirectUrlByCustomerKey(wid)
                .then(url => {
                    let httpsUrl;

                    if (url.startsWith('http://')) {
                        httpsUrl = url.replace('http://', 'https://');
                    } else if (url.startsWith('https://')) {
                        httpsUrl = url;
                    } else {
                        httpsUrl = `https://${url}`;
                    }
                    setNewUrl(httpsUrl);
                    window.location.href = httpsUrl;
                });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('weiterleitung')}</title>
            </Helmet>

            <Grid container spacing={4} style={{marginTop: "30%"}}>
                <Grid item xs={12} textAlign={"center"}>
                    <img src={"/static/images/logo/surfSolLogo.svg"}/>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                    <CircularProgress/>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                    <p>{t('sieWerdenJetztZurKundenseiteWeitergeleitet')}</p>
                    <p>{t('sollteDieWeiterleitungNichtAutomatischErfolgenKlic')}</p>
                    <a href={newUrl}>{newUrl}</a>
                </Grid>
            </Grid>
        </>
    )
}

export default RedirectPage;
