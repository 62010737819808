import { Box, Button, Container, Divider, Grid, Stack, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { BoughtModulesResource } from '../../../backend/market';
import useDialog from '../../../hooks/useDialog';
import { RootState, useSelector } from '../../../redux/store';
import { buyWizardModule } from '../../../redux/thunks/constants.thunk';

const StyledHeaderImage = styled('img')`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

const NoModule: React.FC<{ module: BoughtModulesResource }> = ({ module }) => {
    const { t }: { t: any } = useTranslation();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const wizardModule = useSelector((state) => state.constants.moduleManagement?.wizardModule);

    if (wizardModule) {
        return <Navigate to={nav.DASHBOARD.path} />;
    }

    return (
        <>
            <Helmet>
                <title>{t('portalWizard')}</title>
            </Helmet>
            <Container maxWidth="xl" sx={{ height: '70vh', mt: 8 }}>
                <Grid container mt={6} alignItems={'center'}>
                    <Grid item md={6} lg={4} xs={12}>
                        <motion.div animate={{ opacity: [0, 1], y: [-100, 0] }}>
                            <Box textAlign="start">
                                <img src={'/static/images/logo/surfSolLogo.svg'} />
                                <Typography
                                    variant="h1"
                                    sx={{
                                        mt: 1,
                                        mb: 2
                                    }}
                                >
                                    {t('nurNochWenigeSchritte')}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mb: 2
                                    }}
                                >
                                    {t('umDirUndDeinemUnternehmenRichtigZeitUndGeldErspare')}
                                </Typography>
                                {module?.wizardModuleBookingInitiated ?? false ? (
                                    <Typography color={'primary'}>{t('dasSurfaceSolutionsTeamWurdeInformiertUndWirdSichI')}</Typography>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            dispatch(buyWizardModule());
                                            dialog.openDialog({
                                                title: t('wizardModulBeantragt'),
                                                component: <Typography>{t('dasSurfaceSolutionsTeamWurdeInformiertUndWirdSichI')}</Typography>,
                                                okText: t('Hört sich gut an!'),
                                                severity: 'success',
                                                okCallback: () => {
                                                    dialog.closeDialog();
                                                }
                                            });
                                        }}
                                        variant={'contained'}
                                    >
                                        {t('jetztWizardBeantragen')}
                                    </Button>
                                )}
                            </Box>
                        </motion.div>
                    </Grid>
                    <Grid item md={6} lg={8} xs={12}>
                        <motion.div animate={{ opacity: [0, 1], y: [-100, 0] }}>
                            <StyledHeaderImage src={'/static/images/noModule/SurfaceWizardScreenshot.png'} alt={'Wizard'} />
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>
            <Divider sx={{ mb: 10 }} />

            <Stack width={'100%'} alignItems={'center'} gap={10}>
                <img width={'60%'} src={'/static/images/noModule/soFunktionierts.png'} alt={"So funktioniert's"} />
                <img width={'60%'} src={'/static/images/noModule/deineVorteile.png'} alt={"So funktioniert's"} />
            </Stack>

            <Divider sx={{ mb: 10, mt: 10 }} />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        module: state.constants.moduleManagement
    };
};
export default connect(mapStateToProps)(NoModule);
