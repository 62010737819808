import { styled, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import store, { RootState } from 'src/redux/store';
import Accordion from 'src/components/display/Accordion/Accordion';
import MuiIcon from 'src/components/display/MuiIcon/MuiIcon';
import CategoryInputField from './CategoryInputField';
import { InputVariableResource, VariableResource } from 'src/backend/coreCalc';

const InputFieldWrapper = styled('div')(
    ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(2.5)} ${theme.spacing(3)};
        padding: ${theme.spacing(2)};
        padding-bottom: ${theme.spacing(2.5)};
        
        & > *,
        .MuiFormControl-root,
        .MuiOutlinedInput-root,
        .dimensions-infos {
            width: 13rem;
            min-width: 0;
        }
    `
);

interface Props {
    section: string;
    inputVariables: Array<InputVariableResource>;
    variables?: Array<VariableResource>;
    calcRelevantVariableNames?: Array<string>;
    saveVariable: (variable: VariableResource) => void;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
}

const CategorySection: React.FC<Props> = ({ section, inputVariables, variables, calcRelevantVariableNames, saveVariable, openAccordionId, setOpenAccordionId }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();

    const shownVariables = useMemo(() => {
        return (inputVariables || []).filter((variable) => {
            if (variable.isAlwaysActive) return true;
            return calcRelevantVariableNames.includes(variable.name);
        });
    }, [inputVariables, calcRelevantVariableNames]);

    return (
        shownVariables.length > 0 && (
            <Accordion
                key={section}
                accordionHeader={
                    <>
                        <MuiIcon icon="StraightenTwoTone" />
                        <Typography ml={1} variant="h4">
                            {section}
                        </Typography>
                    </>
                }
                accordionId={section}
                openAccordionId={openAccordionId}
                setOpenAccordionId={setOpenAccordionId}
            >
                <InputFieldWrapper>
                    {shownVariables.map((variable) => (
                        <CategoryInputField key={variable.id} variable={variable} variables={variables} saveVariable={saveVariable} />
                    ))}
                </InputFieldWrapper>
            </Accordion>
        )
    );
};

const mapStateToProps = (state: RootState) => {
    return {};
};
export default connect(mapStateToProps)(CategorySection);
