import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from 'src/utils/RouteHelper';
import { NavItems } from './navigations';

const Status404 = Loader(lazy(() => import('src/pages/StatusPages/Status404')));
const Status500 = Loader(lazy(() => import('src/pages/StatusPages/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/pages/StatusPages/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/pages/StatusPages/Maintenance')));

const baseNavigation: NavItems = {
    INIT: {
        caption: 'loginRedirect',
        tabTitle: 'loginRedirectTabTitle',
        path: '/*',
        element: <Navigate to="/account/login" />
    },
    STATUS: {
        caption: 'status',
        tabTitle: 'statusTabTitle',
        path: '/*/status/',
        sub: {
            PAGE_404: {
                caption: 'page404',
                tabTitle: 'page404TabTitle',
                path: '/*/status/404/',
                element: <Status404 />
            },
            PAGE_500: {
                caption: 'page500',
                tabTitle: 'page500TabTitle',
                path: '/*/status/500/',
                element: <Status500 />
            },
            MAINTENANCE: {
                caption: 'maintenance',
                tabTitle: 'maintenanceTabTitle',
                path: '/*/status/maintenance/',
                element: <StatusMaintenance />
            },
            COMING_SOON: {
                caption: 'comingSoon',
                tabTitle: 'comingSoonTabTitle',
                path: '/*/status/coming-soon/',
                element: <StatusComingSoon />
            }
        }
    },
    MISSING: {
        caption: 'missing',
        tabTitle: 'missingTabTitle',
        path: '*',
        element: <Status404 />
    }
};

export default baseNavigation;
