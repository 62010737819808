import { Typography, Stack, Button, Dialog, DialogTitle, IconButton, DialogContent, useTheme, Tooltip } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useState } from 'react';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { useDispatch } from 'react-redux';
import VariableForm from './VariableForm';
import { InputVariableResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: VariableResource;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    saveVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
    variables: Array<VariableResource>;
    isCalcRelevant?: boolean;
}

const ChangeVariableDialog: React.FC<Props> = ({ variable, isOpen, setOpen, removeVariable, saveVariable, variables, isCalcRelevant }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [localVariable, setLocalVariable] = useState<VariableResource>(variable);
    const [isValid, setValid] = useState<boolean>(false);

    const handleRemoveClick = () => {
        removeVariable(localVariable.id);
        setOpen(false);
    };

    const handleSaveClick = () => {
        saveVariable(localVariable);
        setOpen(false);
    };

    const isSpecial = variable.type === VariableResource.type.INPUT_VARIABLE && (variable as InputVariableResource).isSpecial;

    const tooltip = isSpecial
        ? 'Diese Variable kann nicht gelöscht werden, weil sie in einer Anzeige verwendet wird'
        : isCalcRelevant
        ? 'Diese Variable wird in einer Kalkulation verwendet und kann deswegen nicht gelöscht werden'
        : variable.type === VariableResource.type.PROVIDED_VARIABLE
        ? 'Bereitgestelle Variablen können nicht gelöscht werden'
        : '';

    return (
        <Dialog
            open={isOpen}
            onClose={() => setOpen(false)}
            maxWidth={variable.type === VariableResource.type.TERM_VARIABLE ? 'xl' : 'sm'}
            fullWidth={variable.type !== VariableResource.type.TERM_VARIABLE}
        >
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, pr: 7 }} gutterBottom>
                    Editiere "{variable.name}"
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack pt={1} gap={3}>
                    <VariableForm type={variable.type} variable={localVariable} variables={variables} setVariable={setLocalVariable} setValid={setValid} />
                    <Stack direction="row" justifyContent="space-between">
                        <Tooltip title={tooltip}>
                            <span>
                                <Button
                                    variant="text"
                                    sx={{ mx: -1.5 }}
                                    color="error"
                                    startIcon={<DeleteTwoTone />}
                                    disabled={isCalcRelevant || variable.type === VariableResource.type.PROVIDED_VARIABLE || isSpecial}
                                    onClick={handleRemoveClick}
                                >
                                    Löschen
                                </Button>
                            </span>
                        </Tooltip>
                        <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} disabled={!isValid} onClick={handleSaveClick}>
                            Änderungen speichern
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeVariableDialog;
