import {slice} from "../slices/language.reducer";
import {AppDispatch, RootState} from "../store";
import { TranslationsRestControllerService } from "../../backend/market";

export const loadLanguageCodes = () => async (dispatch: AppDispatch) => {
    const response = await TranslationsRestControllerService.getLanguageCodes();
    dispatch(slice.actions.setLanguageCodes(response));
}

export const loadAllIso3166LanguageCodes = (callback?: (iso3166Codes: string[]) => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const iso3166 = getState().language?.allIso3166LanguageCodes;
    if(!iso3166) return callback(iso3166);
    const response = await TranslationsRestControllerService.getAllIso3166LanguageCodes();
    dispatch(slice.actions.setAllIso3166LanguageCodes(response));
    return callback(response);
}

export const loadLocales = () => async (dispatch: AppDispatch) => {
    const response = await TranslationsRestControllerService.getLanguageCodes();
    dispatch(slice.actions.setLanguageCodes(response));
}