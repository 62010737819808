import {
    Typography,
    Box,
    Avatar,
    Card,
    useTheme,
    Stack
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import StatisticCard from './StatisticCard';


interface Props {
    value: string | number;
    monthlyIncrease: number;
}

const InquiriesAmount: React.FC<Props> = ({ value, monthlyIncrease }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();

    return (
        <StatisticCard title={t('incomingInquiries')} value={value + ''} monthlyIncrease={monthlyIncrease} icon={<FileUploadTwoToneIcon />} />
    );
}

export default InquiriesAmount;
