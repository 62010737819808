import { Typography, Stack, Divider, Button, Dialog, IconButton, useTheme, TextField } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useMemo, useState } from 'react';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import CalcBuilderVariables from './CalcBuilderVariables';
import TermBuilder from '../TermBuilder/TermBuilder';
import { listVariablesInTerm } from 'src/utils/TermHelper';
import CalcBuilderUsedVariables from './CalcBuilderUsedVariables';
import { TermResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    variables?: Array<VariableResource>;
    calcRelevantVariableNames?: Array<string>;
    hideName?: boolean;
    name?: string;
    term?: TermResource;
    onSave?: (name: string, term: TermResource) => void;
    creationMode?: boolean;
    saveVariable?: (variable: VariableResource) => void;
    createVariable?: (variable: VariableResource) => void;
    removeVariable?: (variableId: number) => void;
}

const CalcBuilderDialog: React.FC<Props> = ({ isOpen, setOpen, variables, calcRelevantVariableNames, hideName, name, term, onSave, creationMode, createVariable, saveVariable, removeVariable }) => {
    const theme = useTheme();
    const [localName, setLocalName] = useState<string>(name || '');
    const [localTerm, setLocalTerm] = useState<TermResource>(term || ({} as TermResource));

    const isNameInvalid = hideName ? false : localName == null || localName.trim() === '';
    const isDisabled = isNameInvalid || localTerm == null || JSON.stringify(localTerm) === '{}';

    const handleClick = () => {
        if (!onSave || isDisabled) return;
        onSave(localName, localTerm);
    };

    const variablesInTerm = useMemo(() => listVariablesInTerm(localTerm, variables), [localTerm, variables]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xl" PaperProps={{ sx: { width: '100%', height: '100%', maxWidth: 'none' } }} onClose={() => setOpen(false)}>
            <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} flexGrow={1}>
                <Stack p={3} width="32rem" flexShrink={0}>
                    <CalcBuilderVariables
                        variables={variables}
                        calcRelevantVariableNames={calcRelevantVariableNames}
                        variablesInTerm={variablesInTerm}
                        createVariable={createVariable}
                        saveVariable={saveVariable}
                        removeVariable={removeVariable}
                    />
                </Stack>
                <Stack p={3} overflow="hidden" flexGrow={1}>
                    <Typography variant="h2" sx={{ pr: 7, mb: 1 }} gutterBottom>
                        {creationMode ? 'Kalkulation erstellen' : 'Kalkulation editieren'}
                    </Typography>
                    <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 4 }} variant="subtitle1">
                        Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation
                        erstellen.
                    </Typography>
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>

                    {!hideName && (
                        <TextField
                            label="Kalkulationsnamen"
                            variant="outlined"
                            sx={{ alignSelf: 'flex-start', width: '15rem' }}
                            value={localName}
                            onChange={(event) => {
                                setLocalName(event.target.value);
                            }}
                        />
                    )}

                    <Typography variant="h4" sx={{ fontSize: 20, mt: 7, mb: 1 }} gutterBottom>
                        Wie stellt sich der Preis zusammen?
                    </Typography>
                    <TermBuilder variables={variables} term={localTerm} setTerm={setLocalTerm} minInputWidth="10rem" />

                    <CalcBuilderUsedVariables variables={variables} variablesInTerm={variablesInTerm} saveVariable={saveVariable} />

                    <Stack mt="auto">
                        <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} sx={{ ml: 'auto', mt: 3 }} onClick={handleClick} disabled={isDisabled}>
                            {creationMode ? 'Kalkulation erstellen' : 'Speichern'}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Dialog>
    );
};

export default CalcBuilderDialog;
