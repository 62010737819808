/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemDTO } from './ItemDTO';

export type InputAttributesDTO = {
    type: InputAttributesDTO.type;
    description?: string;
    sequence?: number;
    section?: string;
    defaultValue?: string;
    image?: string;
    min?: number;
    max?: number;
    unit?: string;
    allowDigits?: boolean;
    items?: Array<ItemDTO>;
}

export namespace InputAttributesDTO {

    export enum type {
        BOOLEAN = 'BOOLEAN',
        NUMBER = 'NUMBER',
        SELECT = 'SELECT',
        MULTI_SELECT = 'MULTI_SELECT',
        TEXT = 'TEXT',
    }


}
