import { Box, styled } from '@mui/material';

export const SidewaysConnector = styled(Box)<{ last?: number }>(
    ({ theme, last }) => `
        position: absolute;
        left: -6rem;
        top: -1px;
        height: ${last ? '50%' : 'calc(100% + 1px)'};
        width: 3px;
        background-color: ${theme.colors.alpha.black[10]};

        ${theme.breakpoints.down('xl')} {
            left: -3rem;
        }
    `
);
export const SidewaysHorizontalConnector = styled(Box)(
    ({ theme }) => `
        position: absolute;
        left: -6rem;
        bottom: 50%;
        height: 3px;
        margin-left: 3px;
        width: calc(6rem - 3px);
        background-color: ${theme.colors.alpha.black[10]};
        transform: translateY(-1px);


        ${theme.breakpoints.down('xl')} {
            left: -3rem;
            width: calc(3rem - 3px);
        }
    `
);
