import _ from "lodash";
import {RootState} from "../redux/store";

export const wT = (key: string, store: RootState, showUnknownTranslation: boolean = true) => {
    if (!_.has(store.constants.wizardTranslations, key) && showUnknownTranslation) return key;
    return store.constants.wizardTranslations[key];
}

export const wTf = (key: string, store: RootState, fallback: string) => {
    if (!_.has(store.constants.wizardTranslations, key)) return fallback;
    return store.constants.wizardTranslations[key];
}
