/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ItemResource } from './ItemResource';

export type InputAttributesResource = {
    type?: InputAttributesResource.type;
    description?: string;
    sequence?: number;
    section?: string;
    defaultValue?: any;
    image?: string;
    min?: number;
    max?: number;
    unit?: string;
    allowDigits?: boolean;
    items?: Array<ItemResource>;
}

export namespace InputAttributesResource {

    export enum type {
        BOOLEAN = 'BOOLEAN',
        NUMBER = 'NUMBER',
        SELECT = 'SELECT',
        MULTI_SELECT = 'MULTI_SELECT',
        TEXT = 'TEXT',
    }


}
