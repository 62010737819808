import { Typography, Stack, styled, useTheme, Button } from '@mui/material';
import { InternalCalculationResource } from 'src/backend/internalCalc';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';

const StyledButton = styled(Button)(
    ({ theme }) => `
        &.MuiButtonBase-root {
            font-weight: 600;
            white-space: nowrap;
        }
        
    `
);

const HelpBanner: React.FC = () => {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            sx={{
                position: 'relative',
                backgroundImage: `linear-gradient(177deg, ${theme.colors.primary.main}, ${theme.colors.info.main})`,
                p: 1.4,
                pl: 2,
                color: theme.colors.alpha.white[100],
                borderRadius: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
                zIndex: 5,
                gap: 2,
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    background: 'inherit',
                    borderRadius: 'inherit',
                    filter: 'blur(4px)',
                    opacity: 0.3,
                    zIndex: -1
                }
            }}
        >
            <Stack sx={{ justifyContent: 'center' }}>
                <Typography sx={{ fontSize: 14, fontWeight: 600, lineHeight: 1.4, mb: 0.2 }}>Benötigst du Hilfe?</Typography>
                <Typography sx={{ fontSize: 12, lineHeight: 1.4 }}>Kontaktiere uns für Unterstützung bei deiner Kalkulation</Typography>
            </Stack>
            <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                <StyledButton size="small" startIcon={<PhoneTwoToneIcon />} color="inherit">
                    (+43) 7242 - 313031
                </StyledButton>
                <StyledButton size="small" startIcon={<ChatTwoToneIcon />} color="inherit">
                    Chat kontaktieren
                </StyledButton>
            </Stack>
        </Stack>
    );
};

export default HelpBanner;
