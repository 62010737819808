import { Dialog, DialogContent, DialogTitle, IconButton, Stack, styled, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { AdditionalStorageResource, GuiStatusResource, SetObjectMetadataResource } from 'src/backend/internalCalc';
import CalcParameters from './CalcParameters';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useFormikContext } from 'formik';
import { ParameterArray, renderParameters } from 'src/utils/CalcHelpers';

const DisplayedParameterStack = styled(Stack)(
    ({ theme }) => `
        flex-direction: row;
        align-items: center;
        overflow: auto;
        max-width: 100%;
        & > *:last-of-type {
            margin-right: 0;
        }
    `
);
const MobileParametersWrapper = styled('div')(
    ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(3.5)} ${theme.spacing(4)};
        padding: ${theme.spacing(1, 0)};
        & > * {
            flex-grow: 1;
            min-width: 0;
            width: auto;
        }
        .MuiOutlinedInput-root {
            width: 15rem;
            min-width: 100%;
            max-width: 100%;
        }
    `
);

interface Props {
    parameters?: ParameterArray;
    id: number;
    partId?: number;
    guiStates: Array<GuiStatusResource>;
    sectionTitle?: JSX.Element;
    dialogTitle?: JSX.Element;
    metadata?: Array<SetObjectMetadataResource>;
    disabled?: boolean;
    numberFormat?: string;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const CalcMobileParameters: React.FC<Props> = ({ parameters, id, partId, guiStates, sectionTitle, dialogTitle, metadata, disabled, numberFormat, additionalStorage }) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);
    const { values } = useFormikContext();

    const getField = (label, value, key = null, unit = null) => {
        if (value === '') return <Fragment key={key}></Fragment>;

        return (
            <Stack mr={2} key={key}>
                <Typography variant="subtitle2" sx={{ fontSize: '12px', lineHeight: '12px' }} noWrap>
                    {label}
                </Typography>
                <Typography noWrap>
                    {value} {unit}
                </Typography>
            </Stack>
        );
    };

    return (
        <Stack spacing={2} p={2} className="mobile-parameters">
            {sectionTitle && (
                <Stack direction="row" alignItems="center">
                    {sectionTitle}
                </Stack>
            )}

            <Stack direction="row" alignItems="center">
                <DisplayedParameterStack className="mobile-parameters-display">
                    {renderParameters(parameters, values as { [key: string]: string }, guiStates, numberFormat, getField)}
                </DisplayedParameterStack>
                {!disabled && (
                    <IconButton onClick={() => setOpen(true)} sx={{ ml: 1 }}>
                        <EditTwoToneIcon color="primary" fontSize="small" />
                    </IconButton>
                )}
            </Stack>

            <Dialog onClose={() => setOpen(false)} open={isOpen} maxWidth="xl">
                <DialogTitle sx={{ pr: 7 }}>
                    <Stack direction="row" alignItems="center">
                        {dialogTitle || sectionTitle}
                    </Stack>
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <MobileParametersWrapper>
                        <CalcParameters
                            id={id}
                            partId={partId}
                            guiStates={guiStates}
                            parameters={parameters}
                            metadata={metadata}
                            isSmall={false}
                            disabled={disabled}
                            additionalStorage={additionalStorage}
                        />
                    </MobileParametersWrapper>
                </DialogContent>
            </Dialog>
        </Stack>
    );
};

export default CalcMobileParameters;
