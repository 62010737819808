/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentDto } from '../models/AttachmentDto';
import type { AttachmentResource } from '../models/AttachmentResource';
import type { CalculationAdditionalDataResource } from '../models/CalculationAdditionalDataResource';
import type { CalculationCommissionDto } from '../models/CalculationCommissionDto';
import type { CalculationCommissionResource } from '../models/CalculationCommissionResource';
import type { CalculationFragmentDto } from '../models/CalculationFragmentDto';
import type { CalculationMetadataDto } from '../models/CalculationMetadataDto';
import type { CalculationNoteDto } from '../models/CalculationNoteDto';
import type { CalculationNoteResource } from '../models/CalculationNoteResource';
import type { CalculationParameterResource } from '../models/CalculationParameterResource';
import type { CalculationPartDto } from '../models/CalculationPartDto';
import type { CalculationPartResource } from '../models/CalculationPartResource';
import type { CalculationRegularCustomerDto } from '../models/CalculationRegularCustomerDto';
import type { CalculationResultResource } from '../models/CalculationResultResource';
import type { CompleteSurchargeOverrideStorageDto } from '../models/CompleteSurchargeOverrideStorageDto';
import type { CreateCalculationFragmentDto } from '../models/CreateCalculationFragmentDto';
import type { CreateCalculationPartDto } from '../models/CreateCalculationPartDto';
import type { ForcePriceDto } from '../models/ForcePriceDto';
import type { ImportFromWizardDto } from '../models/ImportFromWizardDto';
import type { InternalCalculationFilterBoundariesResource } from '../models/InternalCalculationFilterBoundariesResource';
import type { InternalCalculationFilterDto } from '../models/InternalCalculationFilterDto';
import type { InternalCalculationResource } from '../models/InternalCalculationResource';
import type { InternalCalculationStatusResource } from '../models/InternalCalculationStatusResource';
import type { MasterCalculationParametersDto } from '../models/MasterCalculationParametersDto';
import type { NewPriceManipulationParameterValueDto } from '../models/NewPriceManipulationParameterValueDto';
import type { PartThumbnailDto } from '../models/PartThumbnailDto';
import type { ShortInternalCalculationPageResultResource } from '../models/ShortInternalCalculationPageResultResource';
import type { SurchargeOverrideStorageEditDto } from '../models/SurchargeOverrideStorageEditDto';
import type { SurchargeOverrideStorageResource } from '../models/SurchargeOverrideStorageResource';
import type { SurchargeStorageDto } from '../models/SurchargeStorageDto';
import type { ThreeDFileDescriptionDto } from '../models/ThreeDFileDescriptionDto';
import type { UserDefinedPartNameDto } from '../models/UserDefinedPartNameDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InternalCalculationRestControllerService {

    /**
     * @param calculationId
     * @param requestBody
     * @returns SurchargeOverrideStorageResource OK
     * @throws ApiError
     */
    public static updateSurchargeStorage(
        calculationId: number,
        requestBody: SurchargeStorageDto,
    ): CancelablePromise<Array<SurchargeOverrideStorageResource>> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/surchargeStorage`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param surchargeOverrideId
     * @param requestBody
     * @returns SurchargeOverrideStorageResource OK
     * @throws ApiError
     */
    public static editSurchargeOverride(
        calculationId: number,
        surchargeOverrideId: number,
        requestBody: SurchargeOverrideStorageEditDto,
    ): CancelablePromise<SurchargeOverrideStorageResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/surchargeOverrides/${surchargeOverrideId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param surchargeOverrideId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteSurchargeOverride(
        calculationId: number,
        surchargeOverrideId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/internalCalculations/${calculationId}/surchargeOverrides/${surchargeOverrideId}`,
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns CalculationAdditionalDataResource OK
     * @throws ApiError
     */
    public static setRegularCustomer(
        calculationId: number,
        requestBody: CalculationRegularCustomerDto,
    ): CancelablePromise<CalculationAdditionalDataResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/regularCustomer`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param priceManipulationParameterValueId
     * @param requestBody
     * @returns CalculationAdditionalDataResource OK
     * @throws ApiError
     */
    public static setPriceManipulationParameterValue(
        calculationId: number,
        priceManipulationParameterValueId: number,
        requestBody: NewPriceManipulationParameterValueDto,
    ): CancelablePromise<CalculationAdditionalDataResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/priceManipulationParameterValues/${priceManipulationParameterValueId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static updatePart(
        calculationId: number,
        partId: number,
        requestBody: CalculationPartDto,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/parts/${partId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @returns CalculationResultResource OK
     * @throws ApiError
     */
    public static deletePart(
        calculationId: number,
        partId: number,
    ): CancelablePromise<CalculationResultResource> {
        return __request({
            method: 'DELETE',
            path: `/internalCalculations/${calculationId}/parts/${partId}`,
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static updateUserDefinedPartName(
        calculationId: number,
        partId: number,
        requestBody: UserDefinedPartNameDto,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/parts/${partId}/userDefinedPartName`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static addThumbnailToPart(
        calculationId: number,
        partId: number,
        requestBody: PartThumbnailDto,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/parts/${partId}/thumbnail`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static addThreeDFileIdToPart(
        calculationId: number,
        partId: number,
        requestBody: ThreeDFileDescriptionDto,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/parts/${partId}/threeDFileId`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param fragmentId
     * @param requestBody
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static updateFragment(
        calculationId: number,
        partId: number,
        fragmentId: number,
        requestBody: CalculationFragmentDto,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/parts/${partId}/fragments/${fragmentId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param fragmentId
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static deleteFragment(
        calculationId: number,
        partId: number,
        fragmentId: number,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'DELETE',
            path: `/internalCalculations/${calculationId}/parts/${partId}/fragments/${fragmentId}`,
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static setPartForcePrice(
        calculationId: number,
        partId: number,
        requestBody: ForcePriceDto,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/parts/${partId}/forcePrice`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns CalculationNoteResource OK
     * @throws ApiError
     */
    public static setNote(
        calculationId: number,
        requestBody: CalculationNoteDto,
    ): CancelablePromise<CalculationNoteResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/note`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns CalculationAdditionalDataResource OK
     * @throws ApiError
     */
    public static setMetadata(
        calculationId: number,
        requestBody: CalculationMetadataDto,
    ): CancelablePromise<CalculationAdditionalDataResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/metadata`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns CalculationParameterResource OK
     * @throws ApiError
     */
    public static setMasterCalculationParameters(
        calculationId: number,
        requestBody: MasterCalculationParametersDto,
    ): CancelablePromise<Array<CalculationParameterResource>> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/masterCalculationParameters`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static setMasterForcePrice(
        calculationId: number,
        requestBody: ForcePriceDto,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/forcePrice`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns CalculationCommissionResource OK
     * @throws ApiError
     */
    public static setCommission(
        calculationId: number,
        requestBody: CalculationCommissionDto,
    ): CancelablePromise<CalculationCommissionResource> {
        return __request({
            method: 'PUT',
            path: `/internalCalculations/${calculationId}/commission`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @returns SurchargeOverrideStorageResource OK
     * @throws ApiError
     */
    public static getSurchargeOverrides(
        calculationId: number,
    ): CancelablePromise<Array<SurchargeOverrideStorageResource>> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/${calculationId}/surchargeOverrides`,
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns SurchargeOverrideStorageResource OK
     * @throws ApiError
     */
    public static addSurchargeOverride(
        calculationId: number,
        requestBody: CompleteSurchargeOverrideStorageDto,
    ): CancelablePromise<SurchargeOverrideStorageResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/surchargeOverrides`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static addPart(
        calculationId: number,
        requestBody: CreateCalculationPartDto,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/parts`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static addFragment(
        calculationId: number,
        partId: number,
        requestBody: CreateCalculationFragmentDto,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/parts/${partId}/fragments`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param fragmentId
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static duplicateFragment(
        calculationId: number,
        partId: number,
        fragmentId: number,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/parts/${partId}/fragments/${fragmentId}/duplicates`,
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @returns CalculationPartResource OK
     * @throws ApiError
     */
    public static duplicatePart(
        calculationId: number,
        partId: number,
    ): CancelablePromise<CalculationPartResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/parts/${partId}/duplicates`,
        });
    }

    /**
     * @param calculationId
     * @param partId
     * @param requestBody
     * @returns AttachmentResource OK
     * @throws ApiError
     */
    public static addAttachmentToPart(
        calculationId: number,
        partId: number,
        requestBody: AttachmentDto,
    ): CancelablePromise<AttachmentResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/parts/${partId}/attachments`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @param requestBody
     * @returns AttachmentResource OK
     * @throws ApiError
     */
    public static addAttachmentToMetadata(
        calculationId: number,
        requestBody: AttachmentDto,
    ): CancelablePromise<AttachmentResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/metadata/attachments`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static createInternalCalculationDuplicate(
        calculationId: number,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/duplicates`,
        });
    }

    /**
     * @param calculationId
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static createInternalCalculationDuplicateAndUpdateDuplicate(
        calculationId: number,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/${calculationId}/duplicates/updatedInternalCalculation`,
        });
    }

    /**
     * @param userId
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static createCalculation(
        userId: number,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/userCalculations/${userId}`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static createCalculationFromWizard(
        userId: number,
        requestBody: ImportFromWizardDto,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/userCalculations/${userId}/wizardCalculations`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static createCalculationFromWizardAndDuplicateIt(
        userId: number,
        requestBody: ImportFromWizardDto,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/userCalculations/${userId}/wizardCalculationDuplicates`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static importDemoCalculations(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/demoCalculations/${userId}`,
        });
    }

    /**
     * @param companyId
     * @param page
     * @param size
     * @param requestBody
     * @param sortBy
     * @returns ShortInternalCalculationPageResultResource OK
     * @throws ApiError
     */
    public static getAllInternalCalculationsFilter1(
        companyId: number,
        page: number,
        size: number,
        requestBody: InternalCalculationFilterDto,
        sortBy: string = 'idAsc',
    ): CancelablePromise<ShortInternalCalculationPageResultResource> {
        return __request({
            method: 'POST',
            path: `/internalCalculations/companyCalculations/${companyId}/filter`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationId
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static getInternalCalculation(
        calculationId: number,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/${calculationId}`,
        });
    }

    /**
     * @param calculationId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCalculation(
        calculationId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/internalCalculations/${calculationId}`,
        });
    }

    /**
     * @param calculationId
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static getUpdatedInternalCalculation(
        calculationId: number,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/${calculationId}/updatedInternalCalculation`,
        });
    }

    /**
     * @param calculationId
     * @param subCalculationIdentificationKey
     * @returns SurchargeOverrideStorageResource OK
     * @throws ApiError
     */
    public static getSurchargeOverrides1(
        calculationId: number,
        subCalculationIdentificationKey: string,
    ): CancelablePromise<Array<SurchargeOverrideStorageResource>> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/${calculationId}/surchargeOverrides/${subCalculationIdentificationKey}`,
        });
    }

    /**
     * @param calculationId
     * @returns CalculationResultResource OK
     * @throws ApiError
     */
    public static getInternalCalculationCostResult(
        calculationId: number,
    ): CancelablePromise<CalculationResultResource> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/${calculationId}/costResult`,
        });
    }

    /**
     * @returns InternalCalculationStatusResource OK
     * @throws ApiError
     */
    public static getAllPossibleInternalCalculationStates(): CancelablePromise<Array<InternalCalculationStatusResource>> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/states`,
        });
    }

    /**
     * @param companyId
     * @param page
     * @param size
     * @param sortBy
     * @returns ShortInternalCalculationPageResultResource OK
     * @throws ApiError
     */
    public static getAllInternalCalculations(
        companyId: number,
        page: number,
        size: number,
        sortBy: string = 'idAsc',
    ): CancelablePromise<ShortInternalCalculationPageResultResource> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/companyCalculations/${companyId}`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * @param companyId
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static getAllInternalCalculationsFullData(
        companyId: number,
    ): CancelablePromise<Array<InternalCalculationResource>> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/companyCalculations/${companyId}/fullDataCalculations`,
        });
    }

    /**
     * @param companyId
     * @returns InternalCalculationFilterBoundariesResource OK
     * @throws ApiError
     */
    public static getInternalCalculationFilterBoundaries(
        companyId: number,
    ): CancelablePromise<InternalCalculationFilterBoundariesResource> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/companyCalculations/${companyId}/filter/boundaries`,
        });
    }

    /**
     * @param uniqueId
     * @returns InternalCalculationResource OK
     * @throws ApiError
     */
    public static getInternalCalculation1(
        uniqueId: string,
    ): CancelablePromise<InternalCalculationResource> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/calculationsByUniqueId/${uniqueId}`,
        });
    }

    /**
     * @param attachmentId
     * @returns AttachmentResource OK
     * @throws ApiError
     */
    public static getAttachment(
        attachmentId: string,
    ): CancelablePromise<AttachmentResource> {
        return __request({
            method: 'GET',
            path: `/internalCalculations/attachments/${attachmentId}`,
        });
    }

    /**
     * @param attachmentId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAttachment(
        attachmentId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/internalCalculations/attachments/${attachmentId}`,
        });
    }

}