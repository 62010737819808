import { Divider, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { DELIVERY_TIME, VARIABLE_VALUE_DELIVERY_TIME } from 'src/statics/statics';
import { EnumerationCalcParamResource, InternalCalculationResource } from 'src/backend/internalCalc';
import { formatPrice } from 'src/utils/FormatHelpers';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';

interface Props {
    calculation?: InternalCalculationResource;
}

const MasterDelivery: React.FC<Props> = ({ calculation }) => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation();

    const onChange = (newValue: string) => {
        setFieldValue(DELIVERY_TIME, newValue);
    };

    const param = useMemo(() => {
        const parameters = calculation?.masterCalculationParameters?.allParameters || [];
        return parameters.find((param) => param.name === DELIVERY_TIME) as EnumerationCalcParamResource;
    }, [calculation?.masterCalculationParameters]);

    const deliveryPrice = useMemo(() => {
        if (!calculation.costResult) return;
        const price = calculation.costResult.variableValues.find((value) => value.name === VARIABLE_VALUE_DELIVERY_TIME);
        return price?.value;
    }, [calculation.costResult]);

    return (
        param && (
            <>
                <Stack flexGrow={1} mt={1} mb={2}>
                    <Typography variant="subtitle2" gutterBottom display="flex" justifyContent="space-between">
                        {t('deliveryOption')}
                        {deliveryPrice != null && (
                            <Typography color="primary" ml={1}>
                                {formatPrice(deliveryPrice, true)}
                            </Typography>
                        )}
                    </Typography>
                    <ButtonSelector items={param.items} value={values[DELIVERY_TIME]} setValue={onChange} />
                </Stack>
                <Divider flexItem orientation="vertical" sx={{ my: -2 }} />
            </>
        )
    );
};

export default MasterDelivery;
