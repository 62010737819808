import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { AdditionalStorageResource, StringCalcParamResource } from 'src/backend/internalCalc';
import { StyledTextField } from '../CalcInputs.styles';
import InfoLabel from './InfoLabel';
import ExtractedIndicator from './ExtractedIndicator';

interface Props {
    param: StringCalcParamResource;
    disabled?: boolean;
    isSmall?: boolean;
    hide?: boolean;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const StringParameter: React.FC<Props> = ({ param, disabled, isSmall, hide, additionalStorage }) => {
    const { values, handleChange, errors, setFieldValue, handleBlur } = useFormikContext();

    useEffect(() => {
        if (hide) setFieldValue(param.name, param.defaultValue);
    }, [hide]);

    return (
        <StyledTextField
            fullWidth
            label={<InfoLabel label={param.name} guiDescriptor={param.guiDescriptor} guiDescriptorExplanation={param.explanationTranslationKey} />}
            onFocus={(e) => {
                e.target.select();
            }}
            onBlur={handleBlur}
            name={param.name}
            onChange={handleChange}
            value={values[param.name]}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                startAdornment: <ExtractedIndicator param={param} additionalStorage={additionalStorage} />
            }}
            disabled={disabled}
            error={Boolean(errors[param.name])}
            helperText={errors[param.name]}
            size={isSmall ? 'small' : 'medium'}
        />
    );
};
export default StringParameter;
