import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AiMailConversationResource } from 'src/backend/market';
import { createPortal } from 'react-dom';
import AiEmailConversationViewer from 'src/components/pro-calc/AiEmail/AiEmailConversationViewer';
import store from 'src/redux/store';

interface Props {
    mailConversation: AiMailConversationResource;
    calcId: string;
}

const CalcOverviewGridEmail: React.FC<Props> = ({ mailConversation, calcId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const s = store.getState();
    const [isAiEmailViewerOpen, setAiEmailViewerOpen] = useState<boolean>(false);

    if (!mailConversation || mailConversation.conversation.length < 1) return <></>;

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={0.5}>
                <Typography variant="subtitle2" noWrap color="text.secondary">
                    {t('email')}
                </Typography>
                <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    startIcon={<EmailTwoToneIcon />}
                    onClick={(event) => {
                        event.stopPropagation();
                        setAiEmailViewerOpen(true);
                    }}
                >
                    {t('viewEmail')}
                </Button>
            </Stack>
            {createPortal(<AiEmailConversationViewer mailConversation={mailConversation} suffix={'#' + calcId} isOpen={isAiEmailViewerOpen} setOpen={setAiEmailViewerOpen} />, document.body)}
        </>
    );
};

export default CalcOverviewGridEmail;
