/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TermResource = {
    type?: TermResource.type;
}

export namespace TermResource {

    export enum type {
        VARIABLE = 'VARIABLE',
        VALUE = 'VALUE',
        BINARY = 'BINARY',
    }


}
