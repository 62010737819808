import React, { Fragment, useMemo } from 'react';
import _ from 'lodash';
import { COLOR_GUI_IDENTIFIER, DATE_EDITOR_FIELDS, EXCLUDED_EDITOR_FIELDS } from 'src/statics/statics';
import BooleanParameter from './ParameterFields/BooleanParameter';
import ColorParameter from './ParameterFields/ColorParameter';
import DateParameter from './ParameterFields/DateParameter';
import DoubleParameter from './ParameterFields/DoubleParameter';
import StringParameter from './ParameterFields/StringParameter';
import EnumParameter from './ParameterFields/EnumParameter';
import MultipleBooleanParameter from './ParameterFields/MultipleBooleanParameter';
import {
    AdditionalStorageResource,
    BooleanCalcParamResource,
    ConstantDoubleCalcParamResource,
    ConstantIntegerCalcParamResource,
    DoubleCalcParamResource,
    EnumerationCalcParamResource,
    GuiStatusResource,
    IntegerCalcParamResource,
    SetObjectMetadataResource,
    StringCalcParamResource
} from 'src/backend/internalCalc';
import IntegerParameter from './ParameterFields/IntegerParameter';
import GeometryParameter from './ParameterFields/GeometryParameter';
import { ParameterArray } from 'src/utils/CalcHelpers';

interface Props {
    id: number;
    partId?: number;
    guiStates: Array<GuiStatusResource>;
    parameters?: ParameterArray;
    metadata?: Array<SetObjectMetadataResource>;
    isSmall?: boolean;
    disabled?: boolean;
    disableParameterFilter?: boolean;
    createdAt?: string;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const CalcParameters: React.FC<Props> = ({ id, partId, guiStates, parameters, metadata, isSmall, disabled, disableParameterFilter, createdAt, additionalStorage }) => {
    const groupedParams = useMemo(() => {
        const params = [...parameters];
        params.sort((a, b) => {
            if (a.sequence === b.sequence) {
                if (a.subSequence == undefined) return 1;
                return a.subSequence - b.subSequence;
            }
            return a.sequence - b.sequence;
        });

        //Group params per descriptor groups
        const grouped = _.groupBy(params, 'guiDescriptor');
        if (disableParameterFilter) return grouped;

        //remove excluded groups
        return _.omitBy(grouped, (group, key) => EXCLUDED_EDITOR_FIELDS.includes(key));
    }, [parameters]);

    const renderParameters = () => {
        //check if field has error
        const children = _.map(groupedParams, (group, key) => {
            //if every member is a boolean member, render a multiple choice param
            if (group.every((x) => x.type === 'boolean')) {
                return (
                    <MultipleBooleanParameter
                        key={key}
                        id={id}
                        guiDescriptor={key}
                        params={group as BooleanCalcParamResource[]}
                        disabled={disabled}
                        isSmall={isSmall}
                        additionalStorage={additionalStorage}
                    />
                );
            }
            if (group.every((x) => x.geometry)) {
                const fieldState = guiStates?.find((x) => x.affectedObject === key && x.affectedSubObject == null);
                const deactivate = fieldState?.status === 'DISABLED';
                const invisible = fieldState?.status === 'INVISIBLE';
                if (invisible) return <></>;
                return (
                    <GeometryParameter
                        key={key}
                        id={id}
                        partId={partId}
                        group={group}
                        isSmall={isSmall}
                        disabled={deactivate || disabled}
                        metadata={metadata}
                        createdAt={createdAt}
                        additionalStorage={additionalStorage}
                    />
                );
            }

            if (key === COLOR_GUI_IDENTIFIER) {
                return <ColorParameter key={key} group={group} disabled={disabled} isSmall={isSmall} additionalStorage={additionalStorage} />;
            }

            //else render all the params in a single group
            return (
                <Fragment key={key}>
                    {group.map((parameter, index) => {
                        // if (validationError) {
                        //     const hasError = Boolean(validationError?.inner.find(x => x.path === `${activePart.id}.${parameter.name}`));
                        // }
                        return <Fragment key={key + index}>{getField(parameter, id, guiStates, isSmall, null, disabled, createdAt, partId, additionalStorage)}</Fragment>;
                    })}
                </Fragment>
            );
        });

        return <>{children}</>;
    };
    return renderParameters();
};
export default CalcParameters;

export const getField = (
    param:
        | BooleanCalcParamResource
        | ConstantDoubleCalcParamResource
        | ConstantIntegerCalcParamResource
        | DoubleCalcParamResource
        | EnumerationCalcParamResource
        | IntegerCalcParamResource
        | StringCalcParamResource,
    id: number,
    guiStates: Array<GuiStatusResource>,
    isSmall?: boolean,
    geometryDimensionLabel?: string,
    disabled?: boolean,
    createdAt?: string,
    partId?: number,
    additionalStorage?: Array<AdditionalStorageResource>
) => {
    const fieldState = guiStates?.find((x) => x.affectedObject === param.name && x.affectedSubObject == null);
    const deactivate = fieldState?.status === 'DISABLED';
    const invisible = fieldState?.status === 'INVISIBLE';
    if (invisible) return <></>;

    switch (param.type) {
        case 'boolean':
            return <BooleanParameter param={param as BooleanCalcParamResource} disabled={disabled || deactivate} isSmall={isSmall} additionalStorage={additionalStorage} />;
        case 'string':
            return <StringParameter param={param as StringCalcParamResource} disabled={disabled || deactivate} hide={deactivate} isSmall={isSmall} additionalStorage={additionalStorage} />;
        case 'enumeration':
            return (
                <EnumParameter
                    id={id}
                    param={param as EnumerationCalcParamResource}
                    disabled={disabled || deactivate}
                    hide={deactivate}
                    isSmall={isSmall}
                    guiStates={guiStates}
                    additionalStorage={additionalStorage}
                />
            );
        case 'double':
            return (
                <DoubleParameter
                    param={param as DoubleCalcParamResource}
                    disabled={disabled || deactivate}
                    hide={deactivate}
                    isSmall={isSmall}
                    geometryDimensionLabel={geometryDimensionLabel}
                    additionalStorage={additionalStorage}
                />
            );
        case 'integer':
            if (DATE_EDITOR_FIELDS.includes(param.guiDescriptor)) {
                return <DateParameter param={param as IntegerCalcParamResource} disabled={disabled || deactivate} isSmall={isSmall} createdAt={createdAt} />;
            }
            return (
                <IntegerParameter
                    partId={partId}
                    param={param as IntegerCalcParamResource}
                    disabled={disabled || deactivate}
                    hide={deactivate}
                    isSmall={isSmall}
                    geometryDimensionLabel={geometryDimensionLabel}
                    additionalStorage={additionalStorage}
                />
            );
        default:
            return <></>;
    }
};
