import { Typography, Stack, Button, Dialog, DialogTitle, IconButton, DialogContent, useTheme, CircularProgress } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useEffect, useState } from 'react';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { useDispatch } from 'react-redux';
import VariableForm from './VariableForm';
import { TemplateResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    template: TemplateResource;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    createVariable: (variable: VariableResource) => void;
    variables: Array<VariableResource>;
}

const CreateVariableDialog: React.FC<Props> = ({ template, isOpen, setOpen, createVariable, variables }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [variable, setVariable] = useState<VariableResource>();
    const [isValid, setValid] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleClick = () => {
        setLoading(true);
        createVariable({ id: -1, ...variable }); // set temp id
    };

    useEffect(() => {
        if (!isLoading) return;
        const foundTempId = variables.some((variable) => variable.id === -1);

        if (!foundTempId) {
            setLoading(false); // Stop loading when temp ID is gone
            setOpen(false);
        }
    }, [variables, variable, isLoading]);

    return (
        <Dialog
            open={isOpen}
            onClose={() => setOpen(false)}
            maxWidth={template.id === VariableResource.type.TERM_VARIABLE ? 'xl' : 'sm'}
            fullWidth={template.id !== VariableResource.type.TERM_VARIABLE}
        >
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    {template.name}
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    {template.description}
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack pt={1} gap={3}>
                    <VariableForm type={template.id as VariableResource.type} variable={variable} variables={variables} setVariable={setVariable} setValid={setValid} />
                    <Button variant="contained" startIcon={<AutoAwesomeTwoToneIcon />} sx={{ ml: 'auto' }} disabled={!isValid || isLoading} onClick={handleClick}>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : 'Erstellen'}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateVariableDialog;
