//INTERFACE AND INITIAL STATE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TMP_LOADER_CATEGORY } from 'src/statics/statics';
import { set, cloneDeep } from 'lodash';
import { BlueprintUpdateTypes } from '../shared/calcEditor.common';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { BlueprintResource, CalculationBasisResource, CalculationBasisTemplateResource, CategoryDTO, CategoryResource, CategoryTemplateResource, VariableResource } from 'src/backend/coreCalc';
import store from '../store';

export enum CalcEditorSavingStatus {
    LOADING = 'calcEditorSaving',
    LOADED = 'calcEditorSaved',
    ERROR = 'calcEditorSaveError'
}
export type CalcEditorError = {
    type: 'badRequest' | 'notAllowed' | 'locked' | 'unknown';
    data?: string;
};
export type CalcEditorUpdateLoading = { [updateType in BlueprintUpdateTypes]?: boolean };
export type CalcEditorUpdateError = { [updateType in BlueprintUpdateTypes]?: any };

interface ICalcEditorState {
    blueprint: BlueprintResource;
    history: Array<BlueprintResource>;
    savingStatus: CalcEditorSavingStatus;
    isLoading: boolean;
    isUpdateLoading: CalcEditorUpdateLoading;
    error: CalcEditorError;
    updateErrors: CalcEditorUpdateError;
    disableCategoryValidation: boolean;
}

const initialState: ICalcEditorState = {
    blueprint: {},
    history: [],
    savingStatus: CalcEditorSavingStatus.LOADED,
    isLoading: true,
    isUpdateLoading: {},
    error: null,
    updateErrors: {},
    disableCategoryValidation: false
};

export const slice = createSlice({
    name: 'calcEditor',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setBlueprint: (state, action: PayloadAction<BlueprintResource>) => {
            state.blueprint = action.payload;
        },
        setBlueprintChanged: (state, action: PayloadAction<boolean>) => {
            if (!state.blueprint) return;
            state.blueprint.isChanged = action.payload;
        },
        resetBlueprint: (state) => {
            state.blueprint = {};
            state.isLoading = true;
            state.error = null;
        },
        setBlueprintValue: (state, action: PayloadAction<{ path: string; value: any; blueprintId?: number }>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            set(state.blueprint, action.payload.path, action.payload.value);
        },
        setSavingStatus: (state, action: PayloadAction<CalcEditorSavingStatus>) => {
            state.savingStatus = action.payload;
        },
        setUpdateLoading: (state, action: PayloadAction<{ updateType: BlueprintUpdateTypes; isLoading: boolean }>) => {
            state.isUpdateLoading[action.payload.updateType] = action.payload.isLoading;
        },
        clearUpdateLoading: (state) => {
            Object.keys(state.isUpdateLoading).forEach((updateType) => {
                state.isUpdateLoading[updateType] = false;
            });
        },
        setDisablePartValidation: (state, action: PayloadAction<boolean>) => {
            state.disableCategoryValidation = action.payload;
        },
        addMasterVariable: (state, action: PayloadAction<VariableResource>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            state.blueprint.masterVariables.push(action.payload);
        },
        deleteMasterVariable: (state, action: PayloadAction<number>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            const variables = state.blueprint.masterVariables;
            state.blueprint.masterVariables = variables.filter((variable) => variable.id !== action.payload);
        },
        setMasterVariable: (state, action: PayloadAction<VariableResource>) => {
            if (!state.blueprint || isWrongBlueprintId(state, action)) return;
            const variableIndex = state.blueprint.masterVariables.findIndex((variable) => variable.id === action.payload.id);
            if (variableIndex === -1) return;
            state.blueprint.masterVariables[variableIndex] = action.payload;
        },
        addCategory: (state, action: PayloadAction<CategoryResource>) => {
            if (!state.blueprint?.categories) return;
            state.blueprint.categories.push(action.payload);
        },
        addTmpCategory: (state) => {
            if (!state.blueprint?.categories) return;
            state.blueprint.categories.push({ id: -1, name: 'TMP_LOADER' });
        },
        deleteCategory: (state, action: PayloadAction<number>) => {
            if (!state.blueprint?.categories) return;
            state.blueprint.categories = state.blueprint.categories.filter((category) => category.id !== action.payload);
        },
        addCategoryCalculationBasis: (state, action: PayloadAction<{ categoryId: number; calculationBasis: CalculationBasisResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].calculationBases.push(action.payload.calculationBasis);
        },
        setCategoryCalculationBasis: (state, action: PayloadAction<{ categoryId: number; calculationBasis: CalculationBasisResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const basisIndex = state.blueprint.categories[categoryIndex].calculationBases.findIndex((basis) => basis.id === action.payload.calculationBasis.id);
            if (basisIndex === -1) return;
            state.blueprint.categories[categoryIndex].calculationBases[basisIndex] = action.payload.calculationBasis;
        },
        setCategoryCalculationBasisSelection: (state, action: PayloadAction<{ categoryId: number; calculationBasisId: number }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].calculationBasisId = action.payload.calculationBasisId;
        },
        addCategoryVariable: (state, action: PayloadAction<{ categoryId: number; variable: VariableResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            state.blueprint.categories[categoryIndex].variables.push(action.payload.variable);
        },
        deleteCategoryVariable: (state, action: PayloadAction<{ categoryId: number; variableId: number }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const variables = state.blueprint.categories[categoryIndex].variables;
            state.blueprint.categories[categoryIndex].variables = variables.filter((variable) => variable.id !== action.payload.variableId);
        },
        setCategoryVariable: (state, action: PayloadAction<{ categoryId: number; variable: VariableResource }>) => {
            if (!state.blueprint?.categories) return;
            const categoryIndex = state.blueprint.categories.findIndex((category) => category.id === action.payload.categoryId);
            if (categoryIndex === -1) return;
            const variableIndex = state.blueprint.categories[categoryIndex].variables.findIndex((variable) => variable.id === action.payload.variable.id);
            if (variableIndex === -1) return;
            state.blueprint.categories[categoryIndex].variables[variableIndex] = action.payload.variable;
        },
        handleError: (state, action: PayloadAction<any>) => {
            const error = action.payload;
            console.error('handleError', error);

            if (error.status === 423) {
                const userId = (error.body?.message || '').split(':')[1];
                state.error = { type: 'locked', data: userId };
            } else if (error.status === 400) {
                state.error = { type: 'badRequest' };
            } else if (error.status === 403) {
                state.error = { type: 'notAllowed' };
            } else {
                state.error = { type: 'unknown', data: error?.status };
            }
        },
        resetError: (state) => {
            state.error = null;
        },
        saveBlueprintRecord: (state) => {
            // Record the previous state to provide rollback on failure
            state.history.push(state.blueprint);
        },
        handleUpdateSuccess: (state, action: PayloadAction<BlueprintUpdateTypes>) => {
            state.savingStatus = CalcEditorSavingStatus.LOADED;
            state.isUpdateLoading[action.payload] = false;
            state.error = null;
        },
        handleUpdateFailure: (state, action: PayloadAction<{ updateType: BlueprintUpdateTypes; error: any }>) => {
            state.savingStatus = CalcEditorSavingStatus.ERROR;
            state.isUpdateLoading[action.payload.updateType] = false;
            // Restore previous state and set error
            state.blueprint = state.history.pop() ?? state.blueprint;
            state.isUpdateLoading[action.payload.updateType] = action.payload.error;
            slice.caseReducers.handleError(state, { payload: action.payload.error, type: 'handleError' });
        }
    }
});
export const reducer = slice.reducer;

const isWrongBlueprintId = (state, action: PayloadAction<any>) => {
    if (!action.payload.blueprintId) return false;
    return state.blueprint?.id !== action.payload.blueprintId;
};

export const {
    addCategory,
    addCategoryCalculationBasis,
    addCategoryVariable,
    addMasterVariable,
    addTmpCategory,
    clearUpdateLoading,
    deleteCategory,
    deleteCategoryVariable,
    deleteMasterVariable,
    handleError,
    handleUpdateFailure,
    handleUpdateSuccess,
    resetBlueprint,
    resetError,
    saveBlueprintRecord,
    setBlueprint,
    setBlueprintChanged,
    setBlueprintValue,
    setCategoryCalculationBasis,
    setCategoryCalculationBasisSelection,
    setCategoryVariable,
    setDisablePartValidation,
    setLoading,
    setMasterVariable,
    setSavingStatus,
    setUpdateLoading
} = slice.actions;
