import {AppDispatch} from "../store";
import {slice} from "../slices/Guidethrough.reducer";
import { CompletedWalkthroughResource, UserResource } from "src/backend/market";
import { updateUserWalkthroughs } from "./user.thunk";

export const startWalkthrough = (screen: string, callback?: (isStarting: boolean) => void) => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const completedWalkthroughs: CompletedWalkthroughResource[] = state.user?.currentUser?.completedWalkthroughs;
    const screens = state.guidethrough.screens;
    const currentScreen = screens.find(s => s.name === screen);
    const isCompleted = completedWalkthroughs.find((walkthrough) => walkthrough.walkthroughName === screen) || currentScreen.completed;
    const initializedAlreadyClicked = JSON.parse(localStorage.getItem('initializedAlreadyClicked'))?.initializedAlreadyClicked;

    if(state.user.currentUser.demonstrationAccount && !initializedAlreadyClicked){
        return;
    }

    if (isCompleted) {
        if(callback) callback(false);
        return;
    } else {
        if(callback) callback(true);

        if(state.guidethrough.run && state.guidethrough.currentScreen !== screen) {
            dispatch(slice.actions.stopWalkthrough());
        }

        setTimeout(() => {
            dispatch(slice.actions.startWalkthrough(screen));
        }, 1000);

    }

}

export const stopWalkthrough = () => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.stopWalkthrough());
}

export const finishWalkthrough = () => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const user: UserResource= state.user?.currentUser;
    const screens = state.guidethrough.screens;
    const keysOfFinishedScreens = Object.keys(screens).filter(key => screens[key].completed);
    keysOfFinishedScreens.push(state.guidethrough.currentScreen);
    dispatch(updateUserWalkthroughs({ completedWalkthroughs: keysOfFinishedScreens }));
    dispatch(slice.actions.finishWalkthrough());
}

export const nextStep = () => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.nextStep());
}

export const stepBack = () => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.stepBack());
}