import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BasePriceResource, CustomSurchargeResource, PredefinedSurchargeStorageResource} from "../../backend/market";

export interface CustomItemConfiguration {
    itemId: number,
    customSurcharges: CustomSurchargeResource[],
    predefinedSurchargeValues: PredefinedSurchargeStorageResource[],
    basePrices: BasePriceResource[]
}

interface CalculationEditorReducer {
    selectedItem: number | null,
    customItemConfigurations: CustomItemConfiguration[]
}

const initialState: CalculationEditorReducer = {
    selectedItem: null,
    customItemConfigurations: []
}

const reducers = {
    setSelectedItem: (state: CalculationEditorReducer, action: PayloadAction<number | null>) => {
        state.selectedItem = action.payload;
    },
    addCustomItemConfiguration: (state: CalculationEditorReducer, action: PayloadAction<CustomItemConfiguration>) => {
        //if present overwrite
        if (!state.customItemConfigurations) state.customItemConfigurations = [];
        const index = state.customItemConfigurations.findIndex(c => c.itemId === action.payload.itemId);
        if (index >= 0) {
            state.customItemConfigurations[index] = action.payload;
        } else {
            state.customItemConfigurations.push(action.payload);
        }
    },
    addCustomSurchargeToItem: (state: CalculationEditorReducer, action: PayloadAction<{ itemId: number, customSurcharge: CustomSurchargeResource }>) => {
        const index = state.customItemConfigurations.findIndex(c => c.itemId === action.payload.itemId);
        const surchargeIndex = state.customItemConfigurations[index].customSurcharges.findIndex(s => s.id === action.payload.customSurcharge.id);
        if (!state.customItemConfigurations[index].customSurcharges) state.customItemConfigurations[index].customSurcharges = [];

        if (surchargeIndex >= 0) {
            state.customItemConfigurations[index].customSurcharges[surchargeIndex] = action.payload.customSurcharge;
        } else {
            state.customItemConfigurations[index].customSurcharges.push(action.payload.customSurcharge);
        }

    },
    addBasePriceToItem: (state: CalculationEditorReducer, action: PayloadAction<{ itemId: number, basePrice: BasePriceResource}>) => {
        const index = state.customItemConfigurations.findIndex(c => c.itemId === action.payload.itemId);
        const basePriceIndex = state.customItemConfigurations[index].basePrices.findIndex(b => b.basePriceId === action.payload.basePrice.basePriceId);
        if(!state.customItemConfigurations[index].basePrices) state.customItemConfigurations[index].basePrices = [];
        console.log(basePriceIndex);
        if(basePriceIndex >= 0) {
            state.customItemConfigurations[index].basePrices[basePriceIndex] = action.payload.basePrice;
        }else{
            state.customItemConfigurations[index].basePrices.push(action.payload.basePrice);
        }
    },
    removeCustomSurchargeFromItem: (state: CalculationEditorReducer, action: PayloadAction<{ itemId: number, customSurchargeId: number }>) => {
        const index = state.customItemConfigurations.findIndex(c => c.itemId === action.payload.itemId);
        const surchargeIndex = state.customItemConfigurations[index].customSurcharges.findIndex(s => s.id === action.payload.customSurchargeId);
        state.customItemConfigurations[index].customSurcharges.splice(surchargeIndex, 1);
    },
    addPredefinedSurchargeToItem: (state: CalculationEditorReducer, action: PayloadAction<{ itemId: number, predefinedSurcharge: PredefinedSurchargeStorageResource }>) => {
        const index = state.customItemConfigurations.findIndex(c => c.itemId === action.payload.itemId);
        const surchargeIndex = state.customItemConfigurations[index].predefinedSurchargeValues.findIndex(s => s.id === action.payload.predefinedSurcharge.id);
        if (!state.customItemConfigurations[index].predefinedSurchargeValues) state.customItemConfigurations[index].predefinedSurchargeValues = [];

        if (surchargeIndex >= 0) {
            state.customItemConfigurations[index].predefinedSurchargeValues[surchargeIndex] = action.payload.predefinedSurcharge;
        } else {
            state.customItemConfigurations[index].predefinedSurchargeValues.push(action.payload.predefinedSurcharge);
        }
    },
    removePredefinedSurchargeFromItem: (state: CalculationEditorReducer, action: PayloadAction<{ itemId: number, predefinedSurchargeId: number }>) => {
        const index = state.customItemConfigurations.findIndex(c => c.itemId === action.payload.itemId);
        const surchargeIndex = state.customItemConfigurations[index].predefinedSurchargeValues.findIndex(s => s.id === action.payload.predefinedSurchargeId);
        state.customItemConfigurations[index].predefinedSurchargeValues.splice(surchargeIndex, 1);
    },
    removeBasePriceFromItem: (state: CalculationEditorReducer, action: PayloadAction<{ itemId: number, basePriceId: number}>) => {
        const index = state.customItemConfigurations.findIndex(c => c.itemId === action.payload.itemId);
        const basePriceIndex = state.customItemConfigurations[index].basePrices.findIndex(b => b.basePriceId === action.payload.basePriceId);
        state.customItemConfigurations[index].basePrices.splice(basePriceIndex, 1);
    }
}
export const slice = createSlice({
    name: "calculationEditor",
    reducers: reducers,
    initialState: initialState,
})
export const reducer = slice.reducer;