import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import React, {useEffect, useImperativeHandle, useState} from "react"
import {useTranslation} from "react-i18next";
import * as yup from "yup"
import {GeneralMessageResource} from "../../../backend/market";

export interface GeneralMessageFormValues {
    message?: string
    title?: string
}

export interface IGeneralMessageDialogProps {
    finishedCallback?: (values: GeneralMessageFormValues, prevGeneralMessage?: GeneralMessageResource) => void,
}

export interface GeneralMessagesDialogHandle {
    open: (generalMessage?: GeneralMessageResource) => void,
    close: () => void,
}

const GeneralMessageDialog: React.ForwardRefRenderFunction<GeneralMessagesDialogHandle, IGeneralMessageDialogProps> = (props, ref) => {
    //region STATE
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [editMessage, setEditMessage] = useState<GeneralMessageResource | null>(null);
    const {
        values,
        resetForm,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        handleChange,
        setValues,
    } = useFormik<GeneralMessageFormValues>({
        enableReinitialize: true,
        initialValues: {
            message: editMessage?.content ?? "",
            title: editMessage?.heading ?? "",
        },
        onSubmit: (values) => {
            if (props.finishedCallback) props.finishedCallback(values, editMessage);
            handleClose();
        },
        validateOnChange: true,
        validationSchema: yup.object().shape({
            message: yup.string().required(t("Bitte gib eine Nachricht ein!")),
            title: yup.string(),
        })
    })
    //endregion

    //region LIFECYCLE
    useImperativeHandle(ref, () => ({
        open: (generalMessage?: GeneralMessageResource) => {
            console.log("OPEN", generalMessage)

            setEditMessage(generalMessage)
            setOpen(true);
        },
        close: () => {
            setOpen(false);
        }
    }), []);
    useEffect(() => {
        resetForm();
    }, [open])

    //region HANDLE
    const handleClose = () => {
        setOpen(false);
    }
    //endregion

    //region RENDER
    //endregion

    return <Dialog open={open} maxWidth={"sm"} fullWidth onBackdropClick={() => setOpen(false)}>
        <DialogTitle>
            <Typography
                variant={"h4"}>{editMessage ? t("editGeneralMessage") : t("createGeneralMessage")}</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit} key={editMessage ? "editForm" + editMessage.id : "createMessageForm"}>
            <DialogContent>
                <Stack gap={1}>
                    <TextField label={t("generalMessageTitle")} value={values.title}
                               onBlur={handleBlur}
                               onChange={handleChange} name={"title"}
                               error={touched["title"] && Boolean(errors["title"])}
                               helperText={touched["title"] && errors["title"]}/>
                    <TextField label={t("generalMessageContent")} value={values.message}
                               rows={5}
                               multiline
                               onChange={handleChange} name={"message"}
                               onBlur={handleBlur}
                               error={touched["message"] && Boolean(errors["message"])}
                               helperText={touched["message"] && errors["message"]}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {t('abbrechen')}
                </Button>
                <Button type={"submit"} variant={"contained"}>
                    {editMessage ? t("saveGeneralMessageChanges") : t("createNewGeneralMessage")}
                </Button>

            </DialogActions>
        </form>
    </Dialog>
}
const GeneralMessageDialogRef = React.forwardRef(GeneralMessageDialog);
export default GeneralMessageDialogRef
