import { Popover, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import { ParameterLabel, ParameterLabelButton } from '../CalcInputs.styles';
import { wT } from 'src/utils/wizardTranslations';
import store from 'src/redux/store';
import { replaceImgTags } from 'src/utils/wizardImages';

interface Props {
    label: string;
    labelPrefix?: string | JSX.Element;
    labelSuffix?: string | JSX.Element;
    guiDescriptor?: string;
    guiDescriptorExplanation?: string;
    showExplanationFallback?: boolean;
}

const InfoLabel: React.FC<Props> = ({ label, labelPrefix, labelSuffix, guiDescriptor, guiDescriptorExplanation, showExplanationFallback = false }) => {
    const ref = useRef<any>(null);
    const popoverRef = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const s = store.getState();

    const guiDescriptorExplanationProps = useMemo(() => {
        if (!guiDescriptorExplanation) return {};
        const translation = wT(guiDescriptorExplanation, s, showExplanationFallback);

        return {
            dangerouslySetInnerHTML: {
                __html: !showExplanationFallback && translation === guiDescriptorExplanation ? null : replaceImgTags(translation)
            }
        };
    }, [guiDescriptorExplanation]);

    if (labelSuffix != null && typeof labelSuffix === 'string') labelSuffix = ' (' + wT(labelSuffix, s, true) + ')';

    const isImageLoaded = (image) => {
        return new Promise((resolve) => {
            image.onload = (e) => resolve(e);
            image.onerror = (e) => resolve(e);
        });
    };

    const handleOpen = (event) => {
        setOpen(true);

        setTimeout(() => {
            const images = Array.from(document.querySelectorAll('.html-explanation img')) as Array<HTMLImageElement>;

            Promise.all(images.filter((img) => !img.complete).map((img) => isImageLoaded(img))).then(() => {
                if (popoverRef.current) popoverRef.current.updatePosition();
            });
        }, 1);
    };

    return guiDescriptorExplanationProps?.dangerouslySetInnerHTML?.__html ? (
        <>
            <ParameterLabelButton size="small" variant="text" color="secondary" onClick={handleOpen} ref={ref} tabIndex={-1}>
                <Typography fontSize="unset" lineHeight="unset" mr={0.35} noWrap display="flex" title={wT(label, s, true)}>
                    {labelPrefix}
                    {wT(label, s)}
                    {labelSuffix}
                </Typography>
                <HelpOutlineTwoToneIcon />
            </ParameterLabelButton>
            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                action={popoverRef}
            >
                <Stack p={2} maxWidth="45rem" className="html-explanation">
                    <Typography variant="h3" gutterBottom>
                        {wT(guiDescriptor, s)}
                    </Typography>
                    <div {...guiDescriptorExplanationProps}></div>
                </Stack>
            </Popover>
        </>
    ) : (
        <ParameterLabel title={wT(label, s, true)} noWrap>
            {labelPrefix}
            {wT(label, s, true)}
            {labelSuffix}
        </ParameterLabel>
    );
};
export default InfoLabel;
