import {ErrorTwoTone, FavoriteTwoTone, InfoTwoTone, WarningTwoTone} from "@mui/icons-material";
import {Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React, {Context, createContext, useCallback, useState} from "react";
import { useTranslation } from 'react-i18next';

export type DialogWidthType = "small" | "medium" | "large";

type DialogInputType = {
    component: React.ReactNode,
    title: string,
    okCallback: (setOkLoading) => void,
    cancelCallback?: () => void,
    width?: DialogWidthType,
    noMaxWidth?: boolean,
    okText?: string,
    cancelText?: string,
    showCancel?: boolean;
    disableClickAway?: boolean;
    severity?: "inherit" | "info" | "warning" | "error" | "success" | "primary" | "secondary"
}
type DialogType = (args: DialogInputType) => void;
type EmptyFunctionType = () => void;

interface DialogPropTypes {
    openDialog: DialogType;
    closeDialog: EmptyFunctionType;
}

export const DialogContext: Context<DialogPropTypes> = createContext({
    openDialog: (args: {
        component: React.ReactNode;
        title: string;
        okCallback: (setOkLoading) => void;
        cancelCallback?: () => void;
        width?: DialogWidthType;
        okText?: string;
        cancelText?: string;
        disableClickAway?: boolean;
    }) => {
        console.log(args);
    },
    closeDialog: () => {
    }
});

interface StateTypes {
    value: DialogPropTypes;
    isOpen: boolean;
    title: string;
    okText?: string;
    cancelText?: string;
    width?: DialogWidthType;
    component: React.ReactNode;
    okCallback: (setOkLoading) => void;
    cancelCallback?: EmptyFunctionType;
    showCancel?: boolean;
    disableClickAway?: boolean;
}

interface PropTypes {
    children: React.ReactNode;
}

const DialogProvider: React.FC<PropTypes> = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dialogSettings, setDialogSettings] = useState<DialogInputType>();
    const [isOkLoading, setOkLoading] = useState<boolean>(false);
    const {t}: { t: any } = useTranslation();

    const openDialog = useCallback((args: DialogInputType) => {
        setDialogSettings(args);
        setIsOpen(true);
    }, []);
    const closeDialog = useCallback(() => {
        setIsOpen(false);
    }, []);

    const value = React.useMemo(() => ({
        openDialog,
        closeDialog
    }), [openDialog, closeDialog]);

    function renderIcon() {
        switch (dialogSettings.severity ?? "primary") {
            case "inherit":
                return <></>;
            case "info":
                return <InfoTwoTone color="info" sx={{fontSize: 60, ml: 2}}/>;
            case "warning":
                return <WarningTwoTone color="warning" sx={{fontSize: 60, ml: 2}}/>;
            case "error":
                return <ErrorTwoTone color="error" sx={{fontSize: 60, ml: 2}}/>;
            case "success":
                return <FavoriteTwoTone color="success" sx={{fontSize: 60, ml: 2}}/>;
            case "primary":
                return <></>;
            case "secondary":
                return <></>;
        }
    }

    return <DialogContext.Provider value={value}>
        {dialogSettings && <Dialog maxWidth={dialogSettings.noMaxWidth ? false : "sm"} PaperProps={{elevation: 2}} open={isOpen} onClose={() => {
            if(dialogSettings.disableClickAway) return;
            setIsOpen(false)
        }}>
            <Box display="flex" flexDirection="row" alignItems="center">
                {renderIcon()}
                <Box display="flex" flexDirection="column">
                    <DialogTitle>
                        <Typography variant="h4" fontWeight="bolder">{dialogSettings.title}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {dialogSettings.component}
                    </DialogContent>
                </Box>
            </Box>
            <DialogActions>
                {(dialogSettings.showCancel ?? true) &&
                    <Button disabled={isOkLoading} color="secondary" onClick={dialogSettings.cancelCallback ?? (() => {
                        setIsOpen(false)
                    })}>
                        {dialogSettings.cancelText ?? t("Abbrechen")}
                    </Button>
                }
                <Button disabled={isOkLoading} color={dialogSettings.severity ?? "primary"} onClick={() => dialogSettings.okCallback(setOkLoading)} variant="contained">
                    {isOkLoading ? <CircularProgress size={20}/> : dialogSettings.okText ?? t("Okay")}
                </Button>
            </DialogActions>
        </Dialog>}
        {children}
    </DialogContext.Provider>
}
export default DialogProvider;
