//INTERFACE AND INITIAL STATE
import {GridSortModel} from "@mui/x-data-grid";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import _ from "lodash";
import { AiMailConversationResource, WizardCalcMergeFilterDto, WizardCalcMergeResource } from "src/backend/market";


interface ICalculationsState {
    calculations: Array<WizardCalcMergeResource>;
    mailConversations: { [uniqueId: string]: AiMailConversationResource };
    isLoading: boolean;
    paging: {
        page: number;
        pageSize: number;
        total: number;
    }
    filter: WizardCalcMergeFilterDto;
    sort: GridSortModel;
    autoReloadCalculations: boolean;
}

const initFilters: WizardCalcMergeFilterDto = {
    priceFilter: {fromIncl: 0, toIncl: 0},
    status: null,
    searchString: '',
}

//INITAL STATE
const initialState: ICalculationsState = {
    calculations: [],
    mailConversations: {},
    isLoading: false,
    paging: {
        page: 0,
        pageSize: 10,
        total: 0,
    },
    filter: initFilters,
    sort: [{
        field: 'id',
        sort: 'desc'
    }],
    autoReloadCalculations: true,
}

const reducers = {
    setCalculations: (state, action) => {
        state.calculations = action.payload;
    },
    setMailConversations: (state, action) => {
        state.mailConversations = action.payload;
    },
    setLoading: (state, action) => {
        state.isLoading = action.payload;
    },
    setAutoReloadCalculations: (state, action) => {
        state.autoReloadCalculations = action.payload;
    },
    updateFilter: (state: ICalculationsState, action: PayloadAction<WizardCalcMergeFilterDto>) => {
        state.filter = action.payload;
    },
    updatePaging: (state: ICalculationsState, action: PayloadAction<{ page?: number, pageSize?: number, total?: number }>) => {
        state.paging = {...state.paging, ...action.payload};
    },
    updateSortModel: (state: ICalculationsState, action: PayloadAction<GridSortModel>) => {
        state.sort = action.payload;
    },
}

//EXPORTS
export const slice = createSlice({
    name: 'calculations',
    initialState,
    reducers: reducers
})
export const reducer = slice.reducer