import {
    Typography,
    Box,
    Avatar,
    Card,
    CardContent,
    styled,
    alpha,
    useTheme,
    Stack
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import StatisticCard from './StatisticCard';
import { formatPrice } from 'src/utils/FormatHelpers';
import { UserResource } from 'src/backend/market';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';



interface Props {
    value: string | number;
    monthlyIncrease: number;
}

const InquiriesTotalValue: React.FC<Props> = ({ value, monthlyIncrease }) => {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();

    const user: UserResource = useSelector((state: RootState) => state.user.currentUser);
    
    return (
        <StatisticCard title={t('inquiriesTotalValue')} value={formatPrice(value, false, user.numberFormat)} monthlyIncrease={monthlyIncrease} icon={<ShoppingCartCheckoutIcon />} />
    );
}

export default InquiriesTotalValue;