import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IMG_MIME_TYPES, PDF_MIME_TYPE } from 'src/statics/statics';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { SummaryAttachmentResource } from 'src/backend/market';
import { Attachment } from 'src/components/input/AttachmentsInput/AttachmentsInput';
import { loadAttachmentPaths } from 'src/redux/thunks/proCalc.thunk';

interface Props {
    attachment: Attachment | SummaryAttachmentResource;
    displayElement: (openAttachment: Function) => JSX.Element;
}

const OpenAttachment: React.FC<Props> = ({ attachment, displayElement }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openedAttachment, setOpenedAttachment] = useState<Attachment>(null);

    const openAttachment = async () => {
        if ('attachmentId' in attachment) {
            setOpenedAttachment(attachment);
            const paths = await dispatch(loadAttachmentPaths(attachment.attachmentId));

            const originalPath = paths.find((path) => path.fileVariant === 'ORIGINAL');
            setTimeout(() => {
                setOpenedAttachment((currentAttachment) => {
                    // trick to get updated openedAttachment
                    if (!!currentAttachment) setOpenedAttachment({ ...currentAttachment, fileUrl: originalPath?.path, isImageLoaded: false });
                    return currentAttachment;
                });
            }, 1000);
        } else if ('fileMimeType' in attachment) {
            setOpenedAttachment({
                originalFileName: attachment.originalFileName,
                fileTypeAsMimeType: attachment.fileMimeType,
                fileUrl: attachment.url,
                isImageLoaded: false
            });
        }
    };

    return (
        <>
            {displayElement(openAttachment)}
            <Dialog
                onClose={() => setOpenedAttachment(null)}
                open={!!openedAttachment}
                maxWidth="xl"
                PaperProps={openedAttachment?.fileTypeAsMimeType === PDF_MIME_TYPE ? { sx: { width: '100%', height: '100%' } } : {}}
            >
                {!!openedAttachment && (
                    <>
                        <DialogTitle sx={{ pr: 7 }}>
                            {openedAttachment.originalFileName}
                            <IconButton aria-label="close" onClick={() => setOpenedAttachment(null)} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                                <CloseTwoToneIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{ overflow: 'hidden', minHeight: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {openedAttachment.fileUrl ? (
                                IMG_MIME_TYPES.includes(openedAttachment.fileTypeAsMimeType) ? (
                                    <img
                                        src={openedAttachment?.fileUrl}
                                        width="100%"
                                        onLoad={() => {
                                            setOpenedAttachment({ ...openedAttachment, isImageLoaded: true });
                                        }}
                                    />
                                ) : openedAttachment.fileTypeAsMimeType === PDF_MIME_TYPE ? (
                                    <object data={openedAttachment?.fileUrl} type={openedAttachment.fileTypeAsMimeType} width="100%" height="100%">
                                        <a href={openedAttachment?.fileUrl}>{openedAttachment?.fileUrl}</a>
                                    </object>
                                ) : (
                                    <Button href={openedAttachment?.fileUrl} target="_blank" download={true} variant="contained" color="primary">
                                        {t('downloadFile')}
                                    </Button>
                                )
                            ) : (
                                <Stack alignItems="center" justifyContent="center" height="100%">
                                    <SurfLoader />
                                </Stack>
                            )}
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default OpenAttachment;
