import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Popover,
    Select,
    Slider,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import useDialog from 'src/hooks/useDialog';
import PageHeader from 'src/components/display/PageHeader';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import { SearchTwoTone } from '@mui/icons-material';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { InternalCalculationStatusResource } from 'src/backend/internalCalc';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import usePrevious from 'src/utils/usePrevious';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RootState } from 'src/redux/store';
import _ from 'lodash';
import { WizardCalcMergeFilterDto } from 'src/backend/market';
import { updateFilter } from 'src/redux/thunks/calculations.thunk';
import { DateTime } from 'luxon';
import { DIRTY, IMPORTED, INVALID, VALID } from 'src/statics/statics';
import { nav } from 'src/statics/navigations';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';

interface Props {
    mode?: string;
    setMode?: Function;
    isLoading: boolean;
    internalCalculationActivated: boolean;
    filter: WizardCalcMergeFilterDto;
    states: Array<InternalCalculationStatusResource>;
}

const CalculationsHeader: React.FC<Props> = ({ mode, setMode, isLoading, internalCalculationActivated, filter, states }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [cFilter, setCFilter] = useState<WizardCalcMergeFilterDto>(filter);
    const prevFilter = usePrevious(cFilter);
    const ref = useRef<any>(null);
    const [priceFrom, setPriceFrom] = useState<string>('0');
    const [priceTo, setPriceTo] = useState<string>('0');
    const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
    const [filterAmount, setFilterAmount] = useState<number>(0);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const updateFilterWrapper = (filter: WizardCalcMergeFilterDto) => {
        dispatch(updateFilter(filter));
    };
    const debouncedFilterCallback = useCallback(_.debounce(updateFilterWrapper, 500), []);

    useEffect(() => {
        if (prevFilter == null) return;
        debouncedFilterCallback(cFilter);
    }, [cFilter]);

    useEffect(() => {
        if (_.isEqual(cFilter, filter)) return;
        setCFilter(filter);
        setPriceFrom(cFilter.priceFilter?.fromIncl + '');
        setPriceTo(cFilter.priceFilter?.toIncl + '');
    }, [filter]);

    useEffect(() => {
        if (isSmallScreen) setMode('gridView');
    }, [isSmallScreen]);

    const handleViewOrientation = (event: any, newValue: string | null) => {
        if (!newValue) return;
        setMode(newValue);
    };

    const updateFilterProperty = (event, filterName) => {
        setCFilter({ ...cFilter, [filterName]: event.target.value });
    };

    const clearSearch = () => {
        setCFilter({ ...cFilter, searchString: '' });
    };

    const createNewCalc = () => {
        navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', 'new'));
    };

    const resetFilter = () => {
        setCFilter({ searchString: '', status: null, distanceFilter: {}, priceFilter: { fromIncl: 0, toIncl: 0 } });
        setPriceFrom('0');
        setPriceTo('0');
    };

    const onChangePriceFilter = (key, value) => {
        const priceFilter = { ...cFilter.priceFilter };
        priceFilter[key] = value;
        setCFilter({ ...cFilter, priceFilter });
    };

    const onChangeDate = (e, key) => {
        const dateValue = e.target.value;
        if (!dateValue) return;
        const luxonDate = DateTime.fromFormat(dateValue, 'yyyy-MM-dd');
        const daysFromNow = Math.ceil(luxonDate.diffNow('days').days);
        const createdAt = { ...cFilter.createdAt };
        createdAt[key] = _.isNaN(daysFromNow) ? 0 : daysFromNow;
        setCFilter({ ...cFilter, createdAt });
    };

    const getDateFromDays = (daysString: string) => {
        const days = parseInt(daysString);
        if (!days) return;
        const luxonDate = DateTime.now().plus({ days });
        return luxonDate.toFormat('yyyy-MM-dd');
    };

    useEffect(() => {
        let amount = 0;
        if (!cFilter) return;
        if (isSmallScreen && !!cFilter.searchString) amount++;
        if (!!cFilter.status) amount++;
        //@ts-ignore
        if ((!!cFilter.priceFilter?.fromIncl && cFilter.priceFilter?.fromIncl != '0') || (!!cFilter.priceFilter?.toIncl && cFilter.priceFilter?.toIncl != '0')) amount++;
        if (!!cFilter.distanceFilter?.from || !!cFilter.distanceFilter?.to) amount++;
        setFilterAmount(amount);
    }, [cFilter, isSmallScreen]);

    return (
        <PageHeader secondaryTitle={t('internalCalcExplanation')} secondaryTitlePosition="below" sx={{ '.title-stack': { mr: 0 } }}>
            <Divider orientation="vertical" flexItem sx={{ my: -4, mx: 4 }} />

            {!isSmallScreen && (
                <TextField
                    value={cFilter.searchString}
                    inputProps={{ sx: { py: 1.54 } }}
                    InputProps={{
                        startAdornment: isLoading ? <CircularProgress size={24} sx={{ display: 'flex', mr: 1 }} /> : <SearchTwoTone sx={{ opacity: 0.6, mr: 1 }} />,
                        endAdornment: cFilter.searchString && (
                            <IconButton sx={{ opacity: 0.6, ml: 1 }} onClick={clearSearch}>
                                <ClearTwoToneIcon />
                            </IconButton>
                        )
                    }}
                    placeholder={t('interneKalkulationenDurchsuchen')}
                    sx={{ width: '20rem', '.MuiInputBase-root': { pr: '4px' } }}
                    onChange={(event) => updateFilterProperty(event, 'searchString')}
                />
            )}

            <Stack sx={{ mx: 2, position: 'relative' }}>
                <Button
                    ref={ref}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setFilterOpen(true)}
                    endIcon={<ArrowDropDownTwoToneIcon sx={{ height: '1.6rem', width: '1.6rem' }} />}
                    sx={{ py: { xs: 0.15, md: 1.15 }, borderColor: 'rgba(0, 0, 0, 0.23)' }}
                >
                    {t(isSmallScreen ? 'filterAndSearch' : 'filter')}
                </Button>

                <Popover
                    disableScrollLock
                    anchorEl={ref.current}
                    onClose={() => setFilterOpen(false)}
                    open={isFilterOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    PaperProps={{
                        sx: { display: 'flex' }
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setFilterOpen(false);
                        }}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], zIndex: 5000 }}
                    >
                        <CloseTwoToneIcon />
                    </IconButton>
                    <Box sx={{ width: 450 }} p={3}>
                        {isSmallScreen && (
                            <>
                                <Typography variant="h4" gutterBottom textAlign="center" mb={3}>
                                    {t('search')}
                                </Typography>

                                <TextField
                                    value={cFilter.searchString}
                                    inputProps={{ sx: { py: 1.54 } }}
                                    InputProps={{
                                        startAdornment: isLoading ? <CircularProgress size={24} sx={{ display: 'flex', mr: 1 }} /> : <SearchTwoTone sx={{ opacity: 0.6, mr: 1 }} />,
                                        endAdornment: cFilter.searchString && (
                                            <IconButton sx={{ opacity: 0.6, ml: 1 }} onClick={clearSearch}>
                                                <ClearTwoToneIcon />
                                            </IconButton>
                                        )
                                    }}
                                    placeholder={t('interneKalkulationenDurchsuchen')}
                                    sx={{ mb: 5, '.MuiInputBase-root': { pr: '4px' } }}
                                    fullWidth
                                    onChange={(event) => updateFilterProperty(event, 'searchString')}
                                />
                            </>
                        )}

                        <Typography variant="h4" gutterBottom textAlign="center" mb={3}>
                            {t('filter')}
                        </Typography>

                        <FormControl sx={{ flex: 1, mb: 2 }} fullWidth>
                            <Typography variant="caption">{t('status')}</Typography>
                            <Select value={cFilter.status || t('alle')} fullWidth onChange={(event) => updateFilterProperty(event, 'status')} renderValue={(selected) => t(selected)}>
                                <MenuItem value={null}>{t('alle')}</MenuItem>
                                <MenuItem value={DIRTY}>{t(DIRTY)}</MenuItem>
                                <MenuItem value={IMPORTED}>{t(IMPORTED)}</MenuItem>
                                <MenuItem value={INVALID}>{t(INVALID)}</MenuItem>
                                <MenuItem value={VALID}>{t(VALID)}</MenuItem>
                            </Select>
                        </FormControl>

                        {/* <Typography variant="caption">{t('erstellungsdatum')}</Typography>
                    <Stack direction="row" spacing={2} mb={2} mt={0.6}>
                        <TextField type="date"
                            label={t('from')}
                            onChange={(event) => onChangeDate(event, 'from')}
                            value={getDateFromDays(cFilter.createdAt?.from)}
                            onFocus={e => {
                                e.target.select();
                            }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField type="date"
                            label={t('to')}
                            onChange={(event) => onChangeDate(event, 'to')}
                            value={getDateFromDays(cFilter.createdAt?.to)}
                            onFocus={e => {
                                e.target.select();
                            }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Stack> */}

                        <Typography variant="caption">{t('gesamterAnfragewert')}</Typography>
                        <Stack direction="row" spacing={2} mb={2} mt={0.6}>
                            <TextField
                                type="number"
                                label={t('from')}
                                onChange={(event) => setPriceFrom(event.target.value)}
                                onBlur={(event) => {
                                    const value = parseInt(event.target.value);
                                    if (value < 0) {
                                        setPriceFrom('0');
                                        onChangePriceFilter('fromIncl', 0);
                                    } else {
                                        if (value > parseInt(priceTo)) {
                                            setPriceTo(value + '');
                                            onChangePriceFilter('toIncl', value);
                                        }
                                        onChangePriceFilter('fromIncl', value);
                                    }
                                }}
                                value={priceFrom}
                                fullWidth
                                inputProps={{ min: 0 }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                type="number"
                                label={t('to')}
                                onChange={(event) => setPriceTo(event.target.value)}
                                onBlur={(event) => {
                                    const value = parseInt(event.target.value);
                                    if (value < parseInt(priceFrom)) {
                                        setPriceTo(priceFrom);
                                        onChangePriceFilter('toIncl', priceFrom);
                                    } else {
                                        onChangePriceFilter('toIncl', value);
                                    }
                                }}
                                value={priceTo}
                                fullWidth
                                inputProps={{ min: 0 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>

                        <Typography variant="caption">{t('distance')} (km)</Typography>
                        <Slider
                            min={0}
                            max={1000}
                            value={[cFilter.distanceFilter?.from / 1000 || 0, cFilter.distanceFilter?.to / 1000 || 0]}
                            onChange={(event, value) => {
                                setCFilter({
                                    ...cFilter,
                                    distanceFilter: {
                                        from: value[0] * 1000,
                                        to: value[1] * 1000
                                    }
                                });
                            }}
                            valueLabelDisplay="auto"
                        />

                        <Box display="flex" justifyContent="center" mt={2}>
                            <Button variant="contained" startIcon={<RefreshTwoToneIcon />} disabled={filterAmount < 1} onClick={resetFilter}>
                                {t('resetFilter')}
                            </Button>
                        </Box>
                    </Box>
                </Popover>

                {filterAmount > 0 && (
                    <Typography variant="subtitle2" sx={{ position: 'absolute', bottom: 0, transform: 'translateY(100%)', pt: 0.5, fontSize: '0.8rem', display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: theme.colors.primary.main, marginRight: 4, fontSize: '0.55rem' }}>●</span>
                        {filterAmount} {t('activeFilter')}
                    </Typography>
                )}
            </Stack>

            {!isSmallScreen && (
                <ToggleButtonGroup color="primary" value={mode} exclusive onChange={handleViewOrientation} sx={{ mr: 'auto' }}>
                    <ToggleButton disableRipple value="gridView">
                        <GridViewTwoToneIcon />
                    </ToggleButton>
                    <ToggleButton disableRipple value="tableView">
                        <TableRowsTwoToneIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            )}

            <Button size={isSmallScreen ? 'small' : 'large'} variant="contained" onClick={createNewCalc} disabled={!internalCalculationActivated} sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                {t('kalkulationErstellen')}
            </Button>
        </PageHeader>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.calculations.isLoading,
        filter: state.calculations.filter,
        states: state.constants.internalCalculationStates
    };
};
export default connect(mapStateToProps)(CalculationsHeader);
