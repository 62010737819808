import { InternalCalculationRestControllerService } from 'src/backend/internalCalc';
import {
    ApiError,
    BoughtModulesResource,
    InquiryRestControllerService,
    ModuleRestControllerService,
    RegularCustomerRestControllerService,
    UserManagementRestControllerService
} from '../../backend/market';
import { BackendService } from '../../backend/summary/BackendService';
import { slice } from '../slices/constants.reducer';
import { slice as customerSlice } from '../slices/customer.reducer';
import { slice as inquirySlice } from '../slices/inquiries.reducer';
import { AppDispatch, AppThunk, RootState } from '../store';
import { loadNumberFormats } from './user.thunk';

export const fetchConstants =
    (callback: () => void, errorCallback?: (e: ApiError) => void): AppThunk =>
    async (dispatch, getState) => {
        const userId = getState().user.currentUser.id;
        const currentLanguageCode = getState().user.currentUser.languageCode;
        const promises = [];

        //INQUIRIES
        promises.push(InquiryRestControllerService.getUsedItemTypes(userId).then((inquiryTypes) => dispatch(slice.actions.setUsedItemTypes(inquiryTypes))));
        promises.push(InquiryRestControllerService.getAllInquiryStates().then((inquiryStates) => dispatch(slice.actions.setInquiryStates(inquiryStates))));
        promises.push(
            InquiryRestControllerService.getInquiryFilterBoundaries(userId).then((inquiryFilterBoundaries) => {
                dispatch(slice.actions.setInquiryFilterBoundaries(inquiryFilterBoundaries));
                dispatch(
                    inquirySlice.actions.updateGridFilter({
                        searchString: '',
                        itemTypes: [],
                        states: [],
                        prices: [
                            {
                                fromIncl: inquiryFilterBoundaries.priceBoundaries.min === inquiryFilterBoundaries.priceBoundaries.max ? 0 : inquiryFilterBoundaries.priceBoundaries.min,
                                toIncl: inquiryFilterBoundaries.priceBoundaries.max
                            }
                        ],
                        partCount: [
                            {
                                partCountMinIncl:
                                    inquiryFilterBoundaries.partCountBoundaries.min === inquiryFilterBoundaries.partCountBoundaries.max ? 0 : inquiryFilterBoundaries.partCountBoundaries.min,
                                partCountMaxIncl: inquiryFilterBoundaries.partCountBoundaries.max
                            }
                        ]
                    })
                );
            })
        );

        //REGULAR CUSTOMERS
        promises.push(RegularCustomerRestControllerService.getAllCustomerTypes(userId).then((customerTypes) => dispatch(slice.actions.setCustomerTypes(customerTypes))));
        promises.push(
            RegularCustomerRestControllerService.getRegularCustomerFilterBoundaries(userId).then((customerFilterBoundaries) => {
                dispatch(slice.actions.setCustomerFilterBoundaries(customerFilterBoundaries));
                dispatch(
                    customerSlice.actions.updateFilter({
                        searchString: '',
                        customerTypeIds: [],
                        salesVolume: [
                            {
                                minSalesVolumeIncl:
                                    customerFilterBoundaries.salesVolumeBoundaries.min === customerFilterBoundaries.salesVolumeBoundaries.max ? 0 : customerFilterBoundaries.salesVolumeBoundaries.min,
                                maxSalesVolumeIncl: customerFilterBoundaries.salesVolumeBoundaries.max
                            }
                        ],
                        inquiryCount: [
                            {
                                minInquiryCountIncl:
                                    customerFilterBoundaries.inquiryCountBoundaries.min === customerFilterBoundaries.inquiryCountBoundaries.max
                                        ? 0
                                        : customerFilterBoundaries.inquiryCountBoundaries.min,
                                maxInquiryCountIncl: customerFilterBoundaries.inquiryCountBoundaries.max
                            }
                        ]
                    })
                );
            })
        );

        //INTERNAL CALCULATIONS
        promises.push(
            InternalCalculationRestControllerService.getInternalCalculationFilterBoundaries(userId).then((internalCalcBoundaries) => {
                dispatch(slice.actions.setInternalCalculationBoundaries(internalCalcBoundaries));
            })
        );
        promises.push(
            InternalCalculationRestControllerService.getAllPossibleInternalCalculationStates().then((internalCalcStates) => dispatch(slice.actions.setInternalCalculationStates(internalCalcStates)))
        );

        //WIZARD TRANSLATIONS
        promises.push(BackendService.loadDictionary(currentLanguageCode).then((dictionary) => dispatch(slice.actions.setWizardTranslations(dictionary))));

        promises.push(dispatch(loadNumberFormats()));

        try {
            await Promise.all(promises);
            callback();
        } catch (e) {
            if (errorCallback) errorCallback(e as ApiError);
        }
    };

export const setModuleManagement =
    (moduleManagement: BoughtModulesResource): AppThunk =>
    async (dispatch, getState) => {
        dispatch(slice.actions.setModuleManagement(moduleManagement));
    };

export const refetchModuleManagement = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const newModuleManagement = await ModuleRestControllerService.getBoughtModules(getState().user.currentUser.id);
    dispatch(slice.actions.setModuleManagement(newModuleManagement));
};

export const refetchDictionary = (newLanguageCode) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const newDictionary = await BackendService.loadDictionary(newLanguageCode);
    dispatch(slice.actions.setWizardTranslations(newDictionary));
};

export const buyWizardModule = (): AppThunk => async (dispatch, getState) => {
    ModuleRestControllerService.bookWizardModule(getState().user.currentUser.id).then((moduleManagement) => {
        dispatch(slice.actions.setModuleManagement({ wizardModuleBookingInitiated: true, wizardModule: false }));
    });
};
