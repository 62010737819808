import { Typography, Stack, Button, Dialog, DialogTitle, IconButton, DialogContent, useTheme, TextField, Collapse } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useState } from 'react';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';

interface Props {
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    onCreate?: () => void;
}

const CreateCustomCategoryDialog: React.FC<Props> = ({ isOpen, setOpen, onCreate }) => {
    const theme = useTheme();
    const [isCriteriaOpen, setCriteriaOpen] = useState<boolean>(false);

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="md" PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Eigenes Eingabefeld erstellen
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack pt={3}>
                    <TextField label="Name" value={'Gewicht'} variant="outlined" sx={{ alignSelf: 'flex-start' }} />

                    <Typography variant="h4" sx={{ fontSize: 20, mt: 7, mb: 1 }} gutterBottom>
                        Was soll abgefragt werden?
                    </Typography>
                    <ButtonSelector
                        items={[
                            { value: 'number', label: 'Zahl' },
                            { value: 'text', label: 'Text' },
                            { value: 'enum', label: 'Auswahl' }
                        ]}
                        value="number"
                        setValue={() => null}
                    />

                    <Typography variant="h4" sx={{ fontSize: 20, mt: 7 }} gutterBottom></Typography>

                    <HeadlineButton isOpen={isCriteriaOpen} setOpen={setCriteriaOpen}>
                        Kriterien (Optional)
                    </HeadlineButton>

                    <Collapse in={isCriteriaOpen} timeout="auto" unmountOnExit>
                        <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 3 }} variant="subtitle1">
                            Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation
                            erstellen.
                        </Typography>

                        <Stack bgcolor={theme.colors.alpha.black[7]} alignItems="center" justifyContent="center" height="8rem" fontSize={14} sx={{ opacity: 0.6, borderRadius: 1 }}>
                            <GridViewTwoToneIcon sx={{ fontSize: 28, opacity: 0.6 }} />
                            Keine Kriterien gesetzt
                        </Stack>
                    </Collapse>
                    <Stack mt="auto">
                        <Button
                            variant="contained"
                            startIcon={<AutoAwesomeTwoToneIcon />}
                            sx={{ ml: 'auto', mt: 3 }}
                            onClick={() => {
                                if (onCreate) onCreate();
                            }}
                        >
                            Kategorie erstellen
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateCustomCategoryDialog;
