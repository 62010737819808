import { FormikContextType, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';

export interface AutoSaveFieldsProps {
    /**
     * Number of milliseconds to wait after last change before submitting the form
     */
    debounceMs?: number;
    formik?: FormikContextType<unknown>;
}

export const FormikAutoSave = ({ debounceMs = 100, formik = useFormikContext() }: AutoSaveFieldsProps) => {
    const debouncedSubmit = useCallback(debounce(formik.submitForm, debounceMs), [formik.submitForm, debounceMs]);

    useEffect(() => {
        debouncedSubmit();
    }, [debouncedSubmit, formik.values]);

    return <></>;
};
