import {Box, styled} from "@mui/material";

export const StyledBox = styled(Box)`
  padding: 0 36px 36px;

  ${({theme}) => theme.breakpoints.down('md')} {
    padding: 10px;
  }

  @media print {
    padding: 0;
  }
`