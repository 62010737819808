import {GridSortModel} from "@mui/x-data-grid";
import {
    NewGeneralFileDto,
    RegularCustomerDto,
    RegularCustomerFilterDto,
    RegularCustomerResource,
    RegularCustomerRestControllerService,
    RegularCustomerWizardBaseUrlDto
} from "../../backend/market";

import {slice} from "../slices/customer.reducer";
import {AppDispatch, RootState} from "../store";
import {refetchModuleManagement, setModuleManagement} from "./constants.thunk";

//CUSTOMERS CRUD
const promises = [];

export const loadCustomers = (overridePageSize?: number, overrideFilter?: RegularCustomerFilterDto) => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log("LOADING CUSTOMERS!")

    const page = getState().customers.customersTablePaging.page;
    const pageSize = overridePageSize ?? getState().customers.customersTablePaging.pageSize;
    const filter = overrideFilter ?? getState().customers.customerFilter;
    const sortString = buildSortingString(getState().customers.customerSortingModel);

    dispatch(slice.actions.setLoading(true));
    const userId = getState().user.currentUser.id;
    if (!userId) {
        return
    }
    if (pageSize === 0) {
        console.log("LOADING PAGE SIZE WITH 0");
        return
    }
    try {

        const promise = RegularCustomerRestControllerService.getAllRegularCustomersWithFilter(userId, page, pageSize, filter, sortString);
        promises.forEach(x => x.cancel());
        promises.push(promise);

        const customers = await promise;
        console.log("FETCH :: CUSTOMERS :: INITIAL", customers);
        dispatch(slice.actions.addFetchedPage(page));
        dispatch(slice.actions.replace(customers.data));
        dispatch(slice.actions.updateRowCount(customers.totalElements));
    } catch (e) {
        console.log("FETCH :: CUSTOMERS :: ERROR", e);
    }
}
export const updateCustomers = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const fetchedPages = getState().customers.customersTablePaging.fetchedPages;
    const page = getState().customers.customersTablePaging.page;
    const pageSize = getState().customers.customersTablePaging.pageSize;
    const filter = getState().customers.customerFilter;
    const sortString = buildSortingString(getState().customers.customerSortingModel);

    if (pageSize === 0) {
        return
    }

    if (fetchedPages.includes(page)) {
        console.log("UPDATE :: CUSTOMERS :: PAGE ALREADY FETCHED", page);
        return;
    }
    dispatch(slice.actions.setLoading(true));
    const userId = getState().user.currentUser.id;
    try {
        const customers = await RegularCustomerRestControllerService.getAllRegularCustomersWithFilter(userId, page, pageSize, filter, sortString);
        console.log("FETCH :: CUSTOMERS :: UPDATE", customers);
        dispatch(slice.actions.addFetchedPage(page));
        dispatch(slice.actions.update(customers.data));
        dispatch(slice.actions.updateRowCount(customers.totalElements));
    } catch (e) {
        console.log("FETCH :: Customers :: ERROR", e);
    }
}
export const updateCustomer = (customerId: number, customer: RegularCustomerDto, callback?: () => void) => async (dispatch: AppDispatch, getState) => {
    const userId = getState().user.currentUser.id;
    try {
        console.log("UPDATE :: CUSTOMER :: UPDATE", customer);
        await RegularCustomerRestControllerService.updateRegularCustomer(userId, customerId, customer)
            .then((data) => {
                if (data !== null) dispatch(slice.actions.updateCustomer(data));
                dispatch(resetSeenPages());
                dispatch(updatePage(0));
            });
    } catch (e) {
        console.log("UPDATE :: CUSTOMER :: ERROR", e);
    }
    callback();
}
export const deleteCustomer = (customerId: number, callback?: () => void) => async (dispatch: AppDispatch, getState) => {
    const userId = getState().user.currentUser.id;
    dispatch(slice.actions.delete(customerId));
    dispatch(slice.actions.updateRowCount(getState().customers.customersTablePaging.rowCount - 1));
    await RegularCustomerRestControllerService.deleteRegularCustomer(userId, customerId);
    dispatch(refetchModuleManagement());
    callback();
}
export const addCustomer = (customer: RegularCustomerDto, callback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().user.currentUser.id;
    await RegularCustomerRestControllerService.createRegularCustomer(userId, customer)
        .then((data) => {
            if (data !== null) {
                dispatch(slice.actions.add(data))
                dispatch(slice.actions.updateRowCount(getState().customers.customersTablePaging.rowCount + 1));
                dispatch(refetchModuleManagement());
            }
        });
    callback();
}
export const addCustomerWithLogo = (customer: RegularCustomerDto, logoName: string, callback?: () => void) => async (dispatch: AppDispatch, getState) => {
    const userId = getState().user.currentUser.id;
    await RegularCustomerRestControllerService.createRegularCustomer(userId, customer)
        .then((data) => {
            if (data !== null) {
                dispatch(slice.actions.add(data))
                dispatch(slice.actions.updateRowCount(getState().customers.customersTablePaging.rowCount + 1));
                RegularCustomerRestControllerService
                    .setLogo1(
                        userId, (data as RegularCustomerResource).id,
                        {name: logoName} as NewGeneralFileDto
                    ).then(_ => {
                    dispatch(reloadCustomers(data.id, () => {
                    }));
                })
            }
        });
    callback();
}
export const updateCustomerStatus = (customerId: number, status: string, callback?: () => void) => async (dispatch: AppDispatch, getState) => {
    const userId = getState().user.currentUser.id;
    dispatch(slice.actions.updateStatus({id: customerId, status: status}));
    await RegularCustomerRestControllerService.setRegularCustomerState(userId, customerId, {regularCustomerState: status});
    callback();
}

//PAGING
export const updatePage = (page?: number) => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.updatePage({page: page}));
}
export const updatePageSize = (pageSize?: number) => async (dispatch: AppDispatch, getState) => {
    console.log("*** UPDATE PAGE SIZE *** ")
    dispatch(slice.actions.updatePageSize({pageSize: pageSize}));
    dispatch(loadCustomers());
}
export const resetSeenPages = () => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.resetSeenPages());
}

//URL
export const getCustomerRedirectURL = async (customerId: number): Promise<string> => {
    try {
        const customerURL = await RegularCustomerRestControllerService.getRegularCustomerRedirectUrl(customerId);
        return customerURL.url;
    } catch (e) {
        console.error(e);
    }
}
export const getRegularCustomerWizardUrl = async (customerId: number, baseUrl: string): Promise<string> => {
    try {
        const customerURL = await RegularCustomerRestControllerService.getRegularCustomerWizardUrl(customerId, {baseUrl: baseUrl} as RegularCustomerWizardBaseUrlDto);
        return customerURL.url;
    } catch (e) {
        console.error(e);
    }
}
export const getRegularCustomerRedirectUrlByCustomerKey = async (key: string): Promise<string> => {
    try {
        const customerURL = await RegularCustomerRestControllerService.getRegularCustomerRedirectUrlByCustomerKey(key);
        return customerURL.url;
    } catch (e) {
        console.error(e);
    }
}

//FILTER
export const reloadCustomers = (customerId: string | number, callback?: (success: boolean) => void) => async (dispatch: AppDispatch, getState) => {
    try {
        const userId = getState().user.currentUser.id;
        const newCustomer = await RegularCustomerRestControllerService.getRegularCustomer(userId, typeof customerId === "number" ? customerId as number : Number.parseInt(customerId));
        dispatch(slice.actions.updateCustomer(newCustomer));
        if (callback) callback(true);
    }
    catch(exception) {
        if (callback) callback(false);
    }
}
export const updateCustomerFilter = (filter: RegularCustomerFilterDto, callback?: () => void) => async (dispatch: AppDispatch, getState) => {
    console.log("*** UPDATE CUSTOMERS FILTER ***", filter);
    dispatch(slice.actions.updateFilter(filter));
    dispatch(resetSeenPages());
    dispatch(slice.actions.updatePage({page: 0}))
    dispatch(loadCustomers());
}
export const updateFilter = (filterMode: RegularCustomerFilterDto) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.updateFilter(filterMode));
    dispatch(resetPageAndLoadFirst())
}
export const loadFilterBoundary = () => async (dispatch: AppDispatch, getState) => {
    console.log("LOADING BOUNDARY!")
    const userId = getState().user.currentUser.id;
    const boundaries = await RegularCustomerRestControllerService.getRegularCustomerFilterBoundaries(userId);
    dispatch(slice.actions.updateBoundary(boundaries));
    dispatch(slice.actions.updateFilter({
        customerTypeIds: [],
        searchString: '',
        salesVolume: [{
            minSalesVolumeIncl: boundaries.salesVolumeBoundaries.min === boundaries.salesVolumeBoundaries.max ? 0 : boundaries.salesVolumeBoundaries.min,
            maxSalesVolumeIncl: boundaries.salesVolumeBoundaries.max
        }],
        inquiryCount: [
            {
                minInquiryCountIncl: boundaries.inquiryCountBoundaries.min === boundaries.inquiryCountBoundaries.max ? 0 : boundaries.inquiryCountBoundaries.min,
                maxInquiryCountIncl: boundaries.inquiryCountBoundaries.max
            }
        ]
    }))
}

//SORTING
export const updateSort = (sortModel: GridSortModel) => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!getState().user?.currentUser?.id)
        return;
    dispatch(slice.actions.updateSorting(sortModel));
    dispatch(resetAndLoadFirst());
}

//HELPER
export const resetPageAndLoadFirst = () => async (dispatch, getState) => {
    dispatch(updatePage(0));
    dispatch(resetSeenPages());
    dispatch(loadCustomers());
}
export const resetAndLoadFirst = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log("*** RESET AND LOAD FIRST ***")
    dispatch(resetSeenPages());
    dispatch(slice.actions.updatePage({page: 0}))
    dispatch(loadCustomers());
}
export const buildSortingString = (sortModel: GridSortModel): string | null => {
    if (sortModel.length === 0) {
        return null;
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return sortModel[0].field + capitalizeFirstLetter(sortModel[0].sort);
}
