import { combineReducers } from '@reduxjs/toolkit';
import { reducer as adminReducer } from 'src/redux/slices/admin.reducer';
import { reducer as calculationsReducer } from 'src/redux/slices/calculations.reducer';
import { reducer as calculationEditorReducer } from 'src/redux/slices/calculationEditor.reducer';
import { reducer as constantsReducer } from 'src/redux/slices/constants.reducer';
import { reducer as customersReducer } from 'src/redux/slices/customer.reducer';
import { reducer as inquiryReducer } from 'src/redux/slices/inquiries.reducer';
import { reducer as calcEditorReducer } from 'src/redux/slices/calcEditor.reducer';
import { reducer as proCalcReducer } from 'src/redux/slices/proCalc.reducer';
import { reducer as templatesReducer } from 'src/redux/slices/templates.reducer';
import { reducer as priceCalcReducer } from 'src/redux/slices/priceCalc.reducer';
import { reducer as statisticReducer } from 'src/redux/slices/statistics.reducer';
import { reducer as regularUsersReducer } from 'src/redux/slices/user.reducer';
import { reducer as wizardProfileReducer } from 'src/redux/slices/wizardProfile.reducer';
import { reducer as wizardStatiReducer } from 'src/redux/slices/wizardStati.reducer';
import { reducer as languageReducer } from 'src/redux/slices/language.reducer';
import { reducer as guidethroughReducer } from 'src/redux/slices/Guidethrough.reducer';
import { reducer as fileManagerReducer } from 'src/redux/slices/fileManager.reducer';

const appReducer = combineReducers({
    user: regularUsersReducer,
    inquiries: inquiryReducer,
    wizardStati: wizardStatiReducer,
    wizardProfile: wizardProfileReducer,
    constants: constantsReducer,
    customers: customersReducer,
    statistics: statisticReducer,
    calculations: calculationsReducer,
    calcEditor: calcEditorReducer,
    proCalc: proCalcReducer,
    templates: templatesReducer,
    priceCalc: priceCalcReducer,
    admin: adminReducer,
    calculationEditor: calculationEditorReducer,
    language: languageReducer,
    guidethrough: guidethroughReducer,
    fileManager: fileManagerReducer
});

const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === 'DESTROY_SESSION') state = undefined;

    return appReducer(state, action);
};

export default rootReducer;
