import {styled} from "@mui/material";

export const DashboardWrapper = styled("div")`
    padding-top: 40px;

    ${({theme}) => theme.breakpoints.down('md')} {
        padding-top: 10px;
    }

    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;