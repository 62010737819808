/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InquiryPageResultResource } from '../models/InquiryPageResultResource';
import type { PriceCalcResource } from '../models/PriceCalcResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PriceCalcRestControllerService {

    /**
     * @param priceCalcId
     * @returns PriceCalcResource OK
     * @throws ApiError
     */
    public static getPriceCalcSummary(
        priceCalcId: number,
    ): CancelablePromise<PriceCalcResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/priceCalcs/${priceCalcId}`,
        });
    }

    /**
     * @param priceCalcId
     * @returns any OK
     * @throws ApiError
     */
    public static deletePriceCalc(
        priceCalcId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/priceCalcs/${priceCalcId}`,
        });
    }

    /**
     * @param userId
     * @param page
     * @param size
     * @param sortBy
     * @returns InquiryPageResultResource OK
     * @throws ApiError
     */
    public static getAllPriceCalcs(
        userId: number,
        page: number,
        size: number,
        sortBy: string = 'dateDesc',
    ): CancelablePromise<InquiryPageResultResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/priceCalcs/userCalculations/${userId}`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
            },
        });
    }

}