/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MailConfigurationDto = {
    subject?: string;
    mailContentText?: string;
    toAddress?: string;
    senderGender?: MailConfigurationDto.senderGender;
    senderName?: string;
}

export namespace MailConfigurationDto {

    export enum senderGender {
        UNKNOWN = 'UNKNOWN',
        MALE = 'MALE',
        FEMALE = 'FEMALE',
    }


}
