import {
    Checkbox,
    FormHelperText,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {Field, useFormikContext} from 'formik';
import {t} from "i18next";
import _ from "lodash";
import React from "react";
import {CustomerTypeResource, PriceManipulationParameterValueResource} from "../../../backend/market";
import {IFormik, IFormikPriceParam} from "../../../pages/Core/Customers/Customer";

interface IPriceParamInput {
    types: { [key: number]: CustomerTypeResource },
}

const InputParam = (props) => {
    return <TextField
        key={props.key + "field"}
        {...props}
        sx={{mb: 2}}
        helperText={props.param.parameter.description}
        InputProps={{
            endAdornment: <InputAdornment position="end">{props.param.parameter.unit}</InputAdornment>,
        }}>
    </TextField>
}

const PriceParameterInput: React.FC<IPriceParamInput> = ({types}) => {
    const {values, handleChange, handleBlur, errors, touched, setFieldValue} = useFormikContext<IFormik>();

    function changeCustomerType(event) {
        handleChange(event);
        const type = _.find(types, {id: event.target.value});
        const priceValues = (type?.priceParameterValues ?? []).reduce((acc, value) => {
            return {...acc, [value.parameter.name]: {value: value.value, actualPriceParameter: value}}
        }, {} as IFormikPriceParam)
        console.log(priceValues);
        setFieldValue("overwrittenPriceValues", priceValues);
    }

    function getSelectedType() {
        return _.find(types, {id: values.customerTypeId});
    }

    const typesNotLoaded = Boolean(types === null || _.size(types) === 0);

    function renderField(param: PriceManipulationParameterValueResource) {
        if (param.parameter.type === "NUMBER") {
            return <Field
                onChange={(e) => {
                    setFieldValue(`overwrittenPriceValues.${param.parameter.name}.value`, "" + e.target.value);
                }}
                id={`c_${param.parameter.name}`}
                key={param.parameter.name}
                error={_.has(errors, `overwrittenPriceValues.${param.parameter.name}.value`)}
                name={`overwrittenPriceValues.${param.parameter.name}.value`}
                value={values.overwrittenPriceValues[param.parameter.name]?.value ?? ""}
                validateOnChange={false}
                validate={(value) => {
                    if (value === "") {
                        return t('pleaseEnterValue');
                    }
                    if (isNaN(Number(value))) {
                        return t('pleaseEnterNumber');
                    }
                }}
                param={param}
                component={InputParam}
            />;
        }
        if (param.parameter.type === "BOOLEAN") {
            return <span key={param.parameter.name}><Stack
                direction={"row"} alignItems={"center"}>
                <Checkbox
                    checked={values.overwrittenPriceValues[param.parameter.name]?.value === "false" ? false : true}
                    onChange={(e) => {
                        setFieldValue(`overwrittenPriceValues.${param.parameter.name}.value`, "" + e.target.checked);
                    }}/>
                <Typography>{param.parameter.label}</Typography>
            </Stack>
                <FormHelperText>{param.parameter.description}</FormHelperText>
            </span>
        }
    }

    return (<>{
        typesNotLoaded ? <div></div>
            :
            <div><Select sx={{marginTop: "0px !important"}} fullWidth
                         value={values.customerTypeId}
                         placeholder={t('chooseCustomerType')}
                         onChange={changeCustomerType}
                         onBlur={handleBlur}
                         id={"c_customerType"}
                         error={Boolean(errors.customerTypeId && touched.customerTypeId)}
                         name="customerTypeId">
                {
                    _.values(types).map(type =>
                        <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                    )
                }
            </Select>
                {errors.customerTypeId && touched.customerTypeId &&
                    <FormHelperText error={true}>{errors.customerTypeId}</FormHelperText>
                }
                {getSelectedType()?.priceParameterValues && <Grid mt={2}>
                    <Typography variant={"caption"}>{t('customerValues')}</Typography>
                    <Stack direction={"column"} mt={2}>
                        {(getSelectedType()?.priceParameterValues ?? []).map((param, index) =>
                            renderField(param)
                        )}
                    </Stack>
                </Grid>}
            </div>
    }</>);

}
export default PriceParameterInput;