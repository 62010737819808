import { Stack, Typography, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { CalculationResultResource } from 'src/backend/market';
import { VARIABLE_VALUE_GEOMETRY_POWDER, VARIABLE_VALUE_GEOMETRY_SURFACE, VARIABLE_VALUE_GEOMETRY_VOLUME, VARIABLE_VALUE_GEOMETRY_WEIGHT } from 'src/statics/statics';
import { CalculatedValues } from 'src/components/display/CalculatedValues';
import { CalculationPartResource } from 'src/backend/internalCalc';
import { useTranslation } from 'react-i18next';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        padding: ${theme.spacing(2)};
        width: 100%;
        max-width: 35rem;

        .values-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: ${theme.spacing(2)};
            gap: ${theme.spacing(2, 0.5)};
            white-space: nowrap;
        }

        ${theme.breakpoints.down('md')} {
            & > .MuiBox-root {
                margin: ${theme.spacing(0, 1)};
            }
            .MuiAvatar-root {
                width: ${theme.spacing(3)};
                height: ${theme.spacing(3)};
                margin-right: ${theme.spacing(0.5)};
            }
            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }
            .MuiTypography-subtitle2 {
                font-size: 11px;
                line-height: 1;
            }
            .MuiTypography-h5,
            .MuiTypography-subtitle1 {
                font-size: 13px;
            }
        }
    `
);

interface Props {
    costResult?: CalculationResultResource;
    partCostResult?: CalculationResultResource;
    quantity?: number;
    part?: CalculationPartResource;
}

const ItemCalculatedValues: React.FC<Props> = ({ costResult = {}, partCostResult = {}, quantity, part = {} }) => {
    const { t } = useTranslation();
    const pResult = partCostResult;
    const values = pResult?.variableValues || [];

    const powder = useMemo(() => pResult.totalAmountOfPowder || parseFloat(values.find((value) => value.name === VARIABLE_VALUE_GEOMETRY_POWDER)?.value), [values, pResult.totalAmountOfPowder]);
    const weight = useMemo(() => pResult.totalWeight || parseFloat(values.find((value) => value.name === VARIABLE_VALUE_GEOMETRY_WEIGHT)?.value), [values, pResult.totalWeight]);
    const surface = useMemo(() => pResult.surface?.value / quantity ?? parseFloat(values.find((value) => value.name === VARIABLE_VALUE_GEOMETRY_SURFACE)?.value), [values, pResult.surface]);
    const volume = useMemo(() => pResult.totalVolume || parseFloat(values.find((value) => value.name === VARIABLE_VALUE_GEOMETRY_VOLUME)?.value), [values, pResult.totalVolume]);
    const jigAmount = pResult.totalJigAmount;

    return (
        <StyledStack className="item-calculated-values">
            <Typography variant="h3" fontSize={18} textAlign="center">
                {t('stats')}
            </Typography>
            <Stack className="values-wrapper">
                <CalculatedValues
                    calculatedValues={{
                        powder,
                        weight,
                        surface,
                        volume,
                        objectSurface: part.extractedObjectSurfaceArea,
                        boundingBoxSurface: part.extractedBoundingBoxSurfaceArea,
                        convexHullSurface: part.extractedObjectConvexHullSurfaceArea,
                        objectVolume: part.extractedObjectVolume,
                        jigAmount
                    }}
                    costResult={costResult}
                    quantity={quantity}
                    showZero
                />
            </Stack>
        </StyledStack>
    );
};

export default ItemCalculatedValues;
