import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { AttachmentResource, InternalCalculationResource } from 'src/backend/internalCalc';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AttachmentsInput from 'src/components/input/AttachmentsInput/AttachmentsInput';
import { RootState } from 'src/redux/store';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { deleteAttachmentFromMetadata, setAttachmentInMetadata } from 'src/redux/slices/proCalc.reducer';

interface Props {
    calculation?: InternalCalculationResource;
}

const MasterAttachments: React.FC<Props> = ({ calculation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const attachments = calculation?.calculationMetadata?.attachments;

    const addAttachment = (file: File) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.ADD_ATTACHMENT_TO_METADATA, file));
    };

    const removeAttachment = (attachmentId: string) => {
        if (attachmentId === 'unsupportedExtensionError') dispatch(deleteAttachmentFromMetadata(attachmentId));
        else dispatch(updateProCalc(ProCalcUpdateTypes.DELETE_ATTACHMENT_FROM_METADATA, attachmentId));
    };

    const handleWrongFileExtension = (file: File) => {
        const attachment: AttachmentResource = { attachmentId: 'unsupportedExtensionError', originalFileName: file.name, fileTypeAsMimeType: file.type };
        dispatch(setAttachmentInMetadata({ index: -1, attachment }));
    };

    return (
        <>
            <Button sx={{ px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<AttachFileTwoToneIcon />} variant="text" color="secondary" onClick={() => setOpen(true)} ref={ref}>
                {t('attachments')} {attachments?.length > 0 && '(' + attachments.length + ')'}
            </Button>

            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                <Stack p={2} width="25rem" maxWidth="100%">
                    <Typography variant="h3" textAlign="center">
                        {t('attachments')}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom textAlign="center">
                        {t('masterAttachmentsExplanation')}
                    </Typography>
                    <AttachmentsInput attachments={attachments} addAttachment={addAttachment} removeAttachment={removeAttachment} handleWrongFileExtension={handleWrongFileExtension} />
                </Stack>
            </Popover>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(MasterAttachments);
