import { Stack, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { UTILIZATION, VARIABLE_VALUE_UTILIZATION } from 'src/statics/statics';
import { EnumerationCalcParamResource, InternalCalculationResource } from 'src/backend/internalCalc';
import { formatPrice } from 'src/utils/FormatHelpers';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';

interface Props {
    calculation?: InternalCalculationResource;
}

const MasterUtilization: React.FC<Props> = ({ calculation }) => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const theme = useTheme();

    const onChange = (newValue: string) => {
        setFieldValue(UTILIZATION, newValue);
    };

    const param = useMemo(() => {
        const parameters = calculation?.masterCalculationParameters?.allParameters || [];
        return parameters.find((param) => param.name === UTILIZATION) as EnumerationCalcParamResource;
    }, [calculation?.masterCalculationParameters]);

    const utilizationPrice = useMemo(() => {
        if (!calculation.costResult) return;
        const price = calculation.costResult.variableValues.find((value) => value.name === VARIABLE_VALUE_UTILIZATION);
        return price?.value;
    }, [calculation.costResult]);

    return (
        param && (
            <Stack flexGrow={1} mt={1} mb={2}>
                <Typography variant="subtitle2" gutterBottom display="flex" justifyContent="space-between">
                    {t('utilization')}

                    {utilizationPrice != null && (
                        <Typography color="primary" ml={1}>
                            {formatPrice(utilizationPrice, true)}
                        </Typography>
                    )}
                </Typography>
                <ButtonSelector items={param.items} value={values[UTILIZATION]} setValue={onChange} />
            </Stack>
        )
    );
};

export default MasterUtilization;
