import { Typography, Stack, Button, useTheme, Collapse, IconButton } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { Fragment, useMemo, useState } from 'react';
import { UsedInCalcTag, VariableItem } from '../CalcEditor.styles';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import { groupBy, orderBy } from 'lodash';
import { formatVariable } from 'src/utils/FormatHelpers';
import CreateVariableOverviewDialog from '../Variable/CreateVariableOverviewDialog';
import ChangeVariableDialog from '../Variable/ChangeVariableDialog';
import { VariablesInTerm } from '../CalcEditor.types';
import { VariableResource } from 'src/backend/coreCalc';

type GroupedVariableInfo = {
    variables: Array<VariableResource>;
    totalUsedInCalculation: number;
};
type VariableTypesMap = {
    [type in VariableResource.type]?: GroupedVariableInfo;
};
type GroupedVariables = {
    general: GroupedVariableInfo;
} & VariableTypesMap;

interface Props {
    variables?: Array<VariableResource>;
    calcRelevantVariableNames?: Array<string>;
    variablesInTerm: VariablesInTerm;
    createVariable?: (variable: VariableResource) => void;
    saveVariable?: (variable: VariableResource) => void;
    removeVariable?: (variableId: number) => void;
}

const CalcBuilderVariables: React.FC<Props> = ({ variables, calcRelevantVariableNames, variablesInTerm, createVariable, saveVariable, removeVariable }) => {
    const theme = useTheme();
    const [isOpen, setOpen] = useState<{ [type: string]: boolean }>({});
    const [isCreateVariableOpen, setCreateVariableOpen] = useState<boolean>(false);
    const [variableToEdit, setVariableToEdit] = useState<VariableResource>();

    const groupedVariables = useMemo(() => {
        const groupedByType = groupBy(variables, 'type');
        const result: GroupedVariables = { general: { variables: [], totalUsedInCalculation: 0 } };

        Object.entries(groupedByType).forEach(([type, vars]) => {
            const totalUsedInCalculation = vars.reduce((sum, v) => sum + (variablesInTerm.get(v.name)?.count || 0), 0);
            const sortedVars = orderBy(vars, ['usedInCalculation', 'name'], ['desc', 'asc']);

            if (vars.length > 5) {
                result[type] = { variables: sortedVars, totalUsedInCalculation };
            } else {
                result.general.variables.push(...sortedVars);
                result.general.totalUsedInCalculation += totalUsedInCalculation;
            }
        });

        if (result.general.variables) result.general.variables = orderBy(result.general.variables, ['usedInCalculation', 'name'], ['desc', 'asc']);

        return result;
    }, [variables, variablesInTerm]);

    const isCalcRelevant = useMemo(() => {
        if (!variableToEdit?.id) return false;
        if (variablesInTerm.get(variableToEdit.name)) return true;
        return (calcRelevantVariableNames || []).includes(variableToEdit.name);
    }, [variableToEdit, calcRelevantVariableNames, variablesInTerm]);

    return (
        <Stack>
            {Object.entries(groupedVariables).map(([type, group]) =>
                type === 'general' ? (
                    <Fragment key={type}>
                        <Typography variant="h3" mb={2}>
                            Variablen
                        </Typography>
                        <Stack gap={1.2} mb={3}>
                            {group.variables.map(
                                (variable) =>
                                    variable.type !== VariableResource.type.SURCHARGE_VARIABLE && (
                                        <VariableItem direction="row" key={variable.id}>
                                            <Stack mr="auto" overflow="hidden">
                                                <Typography fontWeight={600} noWrap>
                                                    {variable.translationKey || variable.name}
                                                </Typography>
                                                <Typography fontSize={12} sx={{ opacity: 0.7 }} noWrap>
                                                    {formatVariable(variable, variables)}
                                                </Typography>
                                            </Stack>

                                            {variablesInTerm.get(variable.name)?.count > 0 && <UsedInCalcTag>{variablesInTerm.get(variable.name).count} mal verwendet</UsedInCalcTag>}
                                            <IconButton color="secondary" sx={{ mr: -0.8 }} onClick={() => setVariableToEdit(variable)}>
                                                <SettingsTwoToneIcon />
                                            </IconButton>
                                        </VariableItem>
                                    )
                            )}
                        </Stack>
                    </Fragment>
                ) : (
                    <Fragment key={type}>
                        <HeadlineButton isOpen={isOpen[type]} setOpen={(open) => setOpen({ ...isOpen, [type]: open })}>
                            {type === 'input' ? 'Eingabefelder' : type}
                            <span style={{ opacity: 0.6, fontWeight: 400, fontSize: 13, marginLeft: 'auto' }}>In Verwendung: {group.totalUsedInCalculation}</span>
                        </HeadlineButton>

                        <Collapse in={isOpen[type]} timeout="auto" unmountOnExit>
                            <Stack gap={1.2} mb={3}>
                                {group.variables.map((variable) => (
                                    <VariableItem direction="row" key={variable.id}>
                                        <Stack mr="auto" overflow="hidden">
                                            <Typography fontWeight={600} noWrap>
                                                {variable.name}
                                            </Typography>
                                            <Typography fontSize={12} sx={{ opacity: 0.7 }} noWrap>
                                                {formatVariable(variable, variables)}
                                            </Typography>
                                        </Stack>

                                        {variablesInTerm.get(variable.name)?.count > 0 && <UsedInCalcTag>{variablesInTerm.get(variable.name).count} mal verwendet</UsedInCalcTag>}
                                        <IconButton color="secondary" sx={{ mr: -0.8 }} onClick={() => setVariableToEdit(variable)}>
                                            <SettingsTwoToneIcon />
                                        </IconButton>
                                    </VariableItem>
                                ))}
                            </Stack>
                        </Collapse>
                    </Fragment>
                )
            )}

            <Button startIcon={<AddBoxTwoToneIcon />} onClick={() => setCreateVariableOpen(true)}>
                Variable erstellen
            </Button>
            <CreateVariableOverviewDialog variables={variables} isOpen={isCreateVariableOpen} setOpen={setCreateVariableOpen} createVariable={createVariable} />
            {variableToEdit && (
                <ChangeVariableDialog
                    variable={variableToEdit}
                    variables={variables}
                    isOpen={!!variableToEdit}
                    setOpen={() => setVariableToEdit(null)}
                    saveVariable={saveVariable}
                    removeVariable={removeVariable}
                    isCalcRelevant={isCalcRelevant}
                />
            )}
        </Stack>
    );
};

export default CalcBuilderVariables;
