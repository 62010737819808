import { IconButton, InputAdornment, Tooltip, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { AdditionalStorageResource, BooleanCalcParamResource } from 'src/backend/internalCalc';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import { useTranslation } from 'react-i18next';
import { CalcParamResource } from 'src/utils/CalcHelpers';

interface Props {
    param?: CalcParamResource;
    params?: BooleanCalcParamResource[];
    additionalStorage?: Array<AdditionalStorageResource>;
}

const ExtractedIndicator: React.FC<Props> = ({ param, params, additionalStorage = [] }) => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const theme = useTheme();

    const paramsWithExtractData = useMemo(() => {
        const parameters = {};
        if (param) parameters['extracted_' + param.name] = param;
        else if (params) {
            params.forEach((p) => {
                parameters['extracted_' + p.name] = p;
            });
        } else return [];

        const paramsWithExtractData: Array<{ param: CalcParamResource; extractedData: AdditionalStorageResource }> = [];

        additionalStorage.forEach((entry) => {
            if (!parameters[entry.key]) return;
            paramsWithExtractData.push({ param: parameters[entry.key], extractedData: entry });
        });
        return paramsWithExtractData;
    }, [additionalStorage, values]);

    const isExtracted = paramsWithExtractData.length > 0;
    const isUsingExtracted = useMemo(() => {
        const isOneNotUsingExtracted = paramsWithExtractData.some((p) => p.extractedData?.value !== values[p.param?.name]);
        return !isOneNotUsingExtracted;
    }, [additionalStorage, values]);

    const reset = () => {
        paramsWithExtractData.forEach((p) => {
            setFieldValue(p.param.name, p.extractedData.value);
        });
    };

    return (
        isExtracted && (
            <>
                <InputAdornment position="start" sx={params && { ml: '14px', mr: 0 }} className={'extracted-indicator ' + (isUsingExtracted ? 'is-extracted' : '')}>
                    {isUsingExtracted ? (
                        <Tooltip title={t('valueWasExtracted')}>
                            <AutoFixHighTwoToneIcon sx={{ cursor: 'help', color: theme.colors.success.dark }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title={t('resetToExtractedValue')}>
                            <IconButton onClick={reset} sx={{ m: '-8px' }}>
                                <CachedTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </InputAdornment>
            </>
        )
    );
};
export default ExtractedIndicator;
