/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InputAttributesResource } from './InputAttributesResource';
import type { MetadataResource } from './MetadataResource';
import type { VariableResource } from './VariableResource';

export type InputVariableResource = (VariableResource & {
    metadata?: Array<MetadataResource>;
    attributes?: InputAttributesResource;
    isAlwaysActive?: boolean;
    visibility?: InputVariableResource.visibility;
    isSpecial?: boolean;
});

export namespace InputVariableResource {

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }


}
