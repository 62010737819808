/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SummaryResource } from '../models/SummaryResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class SummaryRestControllerService {

    /**
     * @param wizardId
     * @param languageCode
     * @returns SummaryResource OK
     * @throws ApiError
     */
    public static getWizardSummary(
        wizardId: string,
        languageCode: string = 'de',
    ): CancelablePromise<SummaryResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/summary/wizards/customer/${wizardId}`,
            query: {
                'languageCode': languageCode,
            },
        });
    }

    /**
     * @param wizardId
     * @param languageCode
     * @returns SummaryResource OK
     * @throws ApiError
     */
    public static getCoaterWizardSummary(
        wizardId: string,
        languageCode: string = 'de',
    ): CancelablePromise<SummaryResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/summary/wizards/coater/${wizardId}`,
            query: {
                'languageCode': languageCode,
            },
        });
    }

    /**
     * @param internalCalculationUniqueId
     * @returns SummaryResource OK
     * @throws ApiError
     */
    public static getInternalCalculationSummary(
        internalCalculationUniqueId: string,
    ): CancelablePromise<SummaryResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/summary/internalCalculationsByUniqueId/${internalCalculationUniqueId}`,
        });
    }

    /**
     * @param internalCalculationUniqueId
     * @param userId
     * @returns string OK
     * @throws ApiError
     */
    public static getInternalCalculationSummaryOfferPdf(
        internalCalculationUniqueId: string,
        userId: number,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/marketplace/summary/internalCalculationsByUniqueId/offerPdf/${internalCalculationUniqueId}/${userId}`,
        });
    }

    /**
     * @param internalCalculationId
     * @returns SummaryResource OK
     * @throws ApiError
     */
    public static getInternalCalculationSummary1(
        internalCalculationId: number,
    ): CancelablePromise<SummaryResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/summary/internalCalculations/${internalCalculationId}`,
        });
    }

}