import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import CalcEditorHeader from './CalcEditorHeader';
import MasterCalc from 'src/components/calc-editor/MasterCalc/MasterCalc';
import CategoryList from 'src/components/calc-editor/Categories/CategoryList';
import { useEffect } from 'react';
import { loadBlueprint } from 'src/redux/thunks/calcEditor.thunk';
import { resetBlueprint } from 'src/redux/slices/calcEditor.reducer';
import { cancelBlueprintUpdates } from 'src/redux/shared/calcEditor.common';

const CalculationEditor = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        if (id == null) return;
        dispatch(loadBlueprint(id));
        return () => {
            dispatch(resetBlueprint());
            dispatch(cancelBlueprintUpdates());
        };
    }, []);

    return (
        <>
            <CalcEditorHeader />
            <MasterCalc />
            <CategoryList />
        </>
    );
};

export default CalculationEditor;
