import { Navigate } from 'react-router-dom';
import ManageUsers from 'src/pages/Admin/ManageUsers';
import Customer from 'src/pages/Core/Customers/Customer';
import Customers from 'src/pages/Core/Customers/Customers';
import DeveloperSettings from 'src/pages/Core/DeveloperSettings';
import ToolsOverview from 'src/pages/Core/ToolsOverview';
import { NavItems } from './navigations';
import CalcEditorOverview from 'src/pages/Core/CalcEditor/CalcEditorOverview';
import CalcEditor from 'src/pages/Core/CalcEditor/CalcEditor';

export const toolsNavigation: NavItems = {
    BASE: {
        caption: 'tools',
        tabTitle: 'toolsTabTitle',
        path: '/tools/',
        element: <ToolsOverview />
    },
    CALC_EDITOR: {
        caption: 'calcEditor',
        tabTitle: 'calcEditorTabTitle',
        path: '/tools/calc-editor/',
        sub: {
            BASE: {
                caption: 'calcEditor',
                tabTitle: 'calcEditorTabTitle',
                path: '/tools/calc-editor/',
                element: <CalcEditorOverview />
            },
            EDIT: {
                caption: 'calcEditorEdit',
                tabTitle: 'calcEditorEditTabTitle',
                path: '/tools/calc-editor/:id',
                hideFooter: true,
                element: <CalcEditor />
            }
        }
    },
    CUSTOMERS: {
        caption: 'customers',
        tabTitle: 'customersTabTitle',
        path: '/tools/customers',
        sub: {
            BASE: {
                caption: 'customers',
                tabTitle: 'customersTabTitle',
                path: '/tools/customers/',
                element: <Customers />
            },
            DETAIL: {
                caption: 'customer',
                tabTitle: 'customerTabTitle',
                path: '/tools/customers/detail/:id/',
                element: <Customer />
            }
        }
    },
    ADMIN: {
        caption: 'admin',
        tabTitle: 'adminTabTitle',
        path: '/tools/admin/',
        requiredRoles: ['ADMIN'],
        sub: {
            BASE: {
                caption: 'admin',
                tabTitle: 'adminTabTitle',
                path: '/tools/admin/',
                element: <Navigate to="/tools/admin/user-management" />
            },
            USER_MANAGEMENT: {
                caption: 'userManagement',
                tabTitle: 'userManagementTabTitle',
                path: '/tools/admin/user-management/',
                element: <ManageUsers />
            }
        }
    },
    DEVELOPER_SETTINGS: {
        caption: 'developerSettings',
        tabTitle: 'developerSettingsTabTitle',
        path: '/tools/developer-settings/',
        element: <DeveloperSettings />
    }
};

export default toolsNavigation;
