/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AiDataExtraction } from './AiDataExtraction';
import type { AttachmentDto } from './AttachmentDto';
import type { CoaterUserResource } from './CoaterUserResource';
import type { PriceManipulationParameterResource } from './PriceManipulationParameterResource';
import type { TechnicalDrawingFileDescriptionDto } from './TechnicalDrawingFileDescriptionDto';
import type { ThreeDAiDataExtraction } from './ThreeDAiDataExtraction';
import type { ThreeDFileDescriptionDto } from './ThreeDFileDescriptionDto';

export type InternalCalculationFromMailDto = {
    extractionType?: InternalCalculationFromMailDto.extractionType;
    coaterUser?: CoaterUserResource;
    username?: string;
    folderName?: string;
    threeDFileIds?: Array<ThreeDFileDescriptionDto>;
    technicalDrawingFileDescription?: Array<TechnicalDrawingFileDescriptionDto>;
    calcAttachments?: Array<AttachmentDto>;
    priceManipulationParameterResources?: Array<PriceManipulationParameterResource>;
    note?: string;
    deliveryDate?: string;
    aiDataExtraction?: AiDataExtraction;
    threeDAiDataExtraction?: ThreeDAiDataExtraction;
    firstName?: string;
    lastName?: string;
    email?: string;
}

export namespace InternalCalculationFromMailDto {

    export enum extractionType {
        AI = 'AI',
        THREE_D_FILES = 'THREE_D_FILES',
        TECHNICAL_DRAWING_FILES = 'TECHNICAL_DRAWING_FILES',
    }


}
