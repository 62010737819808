import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from 'src/utils/RouteHelper';
import { NavItems } from './navigations';

const LoginBasic = Loader(lazy(() => import('src/pages/Auth/Login/Login/Basic')));

const RegisterWizard = Loader(lazy(() => import('src/pages/Auth/Register/Wizard/RegisterAccount')));

const RecoverPassword = Loader(lazy(() => import('src/pages/Auth/RecoverPassword')));

const SetNewPassword = Loader(lazy(() => import('src/pages/Auth/SetNewPassword')));

const accountNavigation: NavItems = {
    BASE: {
        caption: 'accountRedirect',
        tabTitle: 'accountRedirectTabTitle',
        path: '/account/wizard/',
        element: <Navigate to="/account/login" />
    },
    REGISTER: {
        caption: 'register',
        tabTitle: 'redirectTabTitle',
        path: '/account/register/',
        element: <RegisterWizard />
    },
    LOGIN: {
        caption: 'login',
        tabTitle: 'loginTabTitle',
        path: '/account/login/',
        element: <LoginBasic />
    },
    RECOVER_PASSWORD: {
        caption: 'recoverPassword',
        tabTitle: 'recoverPasswordTabTitle',
        path: '/account/recover-password/',
        element: <RecoverPassword />
    },
    SET_NEW_PASSWORD: {
        caption: 'setNewPassword',
        tabTitle: 'setNewPasswordTabTitle',
        path: '/account/set-new-password/:recoverId',
        element: <SetNewPassword />
    }
};

export default accountNavigation;
