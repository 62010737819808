import {
    BinaryTermResource,
    RuleBasedVariableResource,
    TermResource,
    TermVariableResource,
    ValueResource,
    ValueTermResource,
    VariableResource,
    VariableTermResource,
    VariableValueResource
} from 'src/backend/coreCalc';
import { TermOperators, VariablesInTerm } from 'src/components/calc-editor/CalcEditor.types';
import { getVariable } from './CalcHelpers';

export const getTermAsString = (term: TermResource, variables: Array<VariableResource>, showBrackets = true) => {
    if (!term) return '';

    if (term.type === TermResource.type.BINARY) {
        const binaryTerm = term as BinaryTermResource;
        const operator = TermOperators.find((o) => o.type === binaryTerm.operator);
        const operation = `${getTermAsString(binaryTerm.a, variables)} ${operator.symbol} ${getTermAsString(binaryTerm.b, variables)}`;
        return showBrackets ? `(${operation})` : operation;
    } else if (term.type === TermResource.type.VARIABLE) {
        const variableTerm = term as VariableTermResource;
        return getVariable(variableTerm.variableName, variables)?.translationKey || variableTerm.variableName;
    } else if (term.type === TermResource.type.VALUE) {
        const valueTerm = term as ValueTermResource;
        return valueTerm.value;
    }
    return '';
};

export const recursivelyRemoveTerm = (term: TermResource, termToDelete: TermResource) => {
    if (term.type !== TermResource.type.BINARY) return term;

    const isATermToDelete = [TermResource.type.VALUE, TermResource.type.VARIABLE].includes(term['a'].type) && term['a'] === termToDelete;
    const isBTermToDelete = [TermResource.type.VALUE, TermResource.type.VARIABLE].includes(term['b'].type) && term['b'] === termToDelete;

    if (isATermToDelete) {
        return { ...term['b'] };
    } else if (isBTermToDelete) {
        return { ...term['a'] };
    } else {
        // Recursively update 'a' and 'b' terms
        return {
            ...term,
            a: recursivelyRemoveTerm(term['a'], termToDelete),
            b: recursivelyRemoveTerm(term['b'], termToDelete)
        };
    }
};

export const listVariablesInTerm = (termOrValue: TermResource | ValueResource, variables: Array<VariableResource>, depth = 0, result: VariablesInTerm = new Map()) => {
    if (termOrValue.type === TermResource.type.VARIABLE || termOrValue.type === ValueResource.type.VARIABLE_VALUE) {
        const variableName = termOrValue.type === TermResource.type.VARIABLE ? (termOrValue as VariableTermResource).variableName : (termOrValue as VariableValueResource).variableName;
        const variable = getVariable(variableName, variables);
        const existingEntry = result.get(variableName);

        if (existingEntry) {
            result.set(variableName, { count: existingEntry.count + 1, minDepth: Math.min(existingEntry.minDepth, depth), variable });
        } else {
            result.set(variableName, { count: 1, minDepth: depth, variable });
        }

        // Handle rule-based variables
        if (variable?.type === VariableResource.type.RULE_BASED_VARIABLE) {
            const rules = (variable as RuleBasedVariableResource).rules || [];
            for (const rule of rules) {
                if (rule.value && rule.value.type === ValueResource.type.VARIABLE_VALUE) {
                    listVariablesInTerm(rule.value, variables, depth + 1, result);
                }
            }
        }

        // Handle term variables
        if (variable?.type === VariableResource.type.TERM_VARIABLE) {
            const term = (variable as TermVariableResource).term || {};
            listVariablesInTerm(term, variables, depth + 1, result);
        }
    } else if (termOrValue.type === TermResource.type.BINARY) {
        const binaryTerm = termOrValue as BinaryTermResource;
        listVariablesInTerm(binaryTerm.a, variables, depth, result);
        listVariablesInTerm(binaryTerm.b, variables, depth, result);
    }

    return result;
};
