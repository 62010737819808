import { CalculationPartResource } from 'src/backend/internalCalc';
import { CalculationFragmentResource } from 'src/backend/internalCalc/models/CalculationFragmentResource';
import { API_CONFIG } from '../config';

export const DEFAULT_IMAGE = 'BpvE9Qpvhj.png';
const BASE_URL = API_CONFIG.SUMMARY.BUCKET_URL;
const B = BASE_URL.endsWith('/') ? BASE_URL.slice(0, -1) : BASE_URL;

const thumbImages = (key: string) => {
    return `${B}/material/${key}_thumb.png`;
};

const images = (key: string) => {
    return `${B}/material/${key}.png`;
};

export const getImageFromPart = (part: CalculationPartResource) => {
    const parameters = part?.calculationParameters?.allParameters || [];
    const geometryParameter = parameters.find((parameter) => parameter.geometry && parameter.image);
    return geometryParameter ? groupImages(geometryParameter.image) : part?.itemImageUrl;
};
export const getImageFromFragment = (fragment: CalculationFragmentResource) => {
    const parameters = fragment?.fragmentParameters || [];
    const geometryParameter = parameters.find((parameter) => parameter.geometry && parameter.image);
    return geometryParameter ? groupImages(geometryParameter.image) : fragment?.itemImageUrl;
};

export const itemImage = (imageUri: string, itemUiImage: string) => {
    let key: string = itemUiImage;
    if (imageUri !== '' && itemUiImage === DEFAULT_IMAGE) {
        key = imageUri;
    } else {
        key = '/items/' + itemUiImage;
    }

    return `${B}${key}`;
};

export const replaceImgTags = (html) => {
    if (!html) return;
    return html.replace('<img src="', `<img src="${BASE_URL}/info/`);
};

const groupImages = (group: string) => {
    try {
        new URL(group);
        return group;
    } catch (_) {
        return `${B}/geometry/${group}`;
    }
};

const itemThumbImage = (uri: string | undefined) => {
    if (uri && uri !== '') {
        if (BASE_URL.endsWith('/')) {
            return `${BASE_URL.slice(0, -1)}${uri}`;
        } else {
            return `${BASE_URL}${uri}`;
        }
    } else {
        return `${BASE_URL}BpvE9Qpvhj.png`;
    }
};

export { images, thumbImages, groupImages, itemThumbImage };
