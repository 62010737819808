/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InputAttributesDTO } from './InputAttributesDTO';
import type { VariableDTO } from './VariableDTO';

export type InputVariableDTO = (VariableDTO & {
    attributes?: InputAttributesDTO;
    isAlwaysActive?: boolean;
    visibility?: InputVariableDTO.visibility;
});

export namespace InputVariableDTO {

    export enum visibility {
        HIDDEN = 'HIDDEN',
        DISABLED = 'DISABLED',
        VISIBLE = 'VISIBLE',
    }


}
