import {ThemeProvider} from '@mui/material';
import React, {useState} from 'react';
import {themeCreator} from './base';

const ThemeProviderWrapper: React.FC = (props) => {
    const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
    const [themeName, _setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName);

    return <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
};

export default ThemeProviderWrapper;
