import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useDispatch } from 'react-redux';
import { AttachmentResource } from 'src/backend/market';
import AttachmentsInput from 'src/components/input/AttachmentsInput/AttachmentsInput';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { deleteAttachmentFromPart, setAttachmentInPart } from 'src/redux/slices/proCalc.reducer';

interface Props {
    partId: number;
    attachments: Array<AttachmentResource>;
    isSmall?: boolean;
    disabled?: boolean;
}

const ItemAttachments: React.FC<Props> = ({ partId, attachments, isSmall, disabled }) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);

    const addAttachment = (file: File) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.ADD_ATTACHMENT_TO_PART, { partId, file }));
    };

    const removeAttachment = (attachmentId: string) => {
        if (attachmentId === 'unsupportedExtensionError') dispatch(deleteAttachmentFromPart({ partId, attachmentId }));
        else dispatch(updateProCalc(ProCalcUpdateTypes.DELETE_ATTACHMENT_FROM_PART, { partId, attachmentId }));
    };

    const handleWrongFileExtension = (file: File) => {
        const attachment: AttachmentResource = { attachmentId: 'unsupportedExtensionError', originalFileName: file.name, fileTypeAsMimeType: file.type };
        dispatch(setAttachmentInPart({ partId, index: -1, attachment }));
    };

    return (
        <>
            <Button
                sx={{ px: 1.2, py: 0.5, flexShrink: 0 }}
                startIcon={<AttachFileTwoToneIcon />}
                variant="text"
                color="secondary"
                onClick={() => setOpen(true)}
                size={isSmall ? 'small' : 'medium'}
                disabled={disabled}
                ref={ref}
            >
                {t('attachments')} {attachments?.length > 0 && '(' + attachments.length + ')'}
            </Button>

            <Popover
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
                <Stack p={2} width="25rem" maxWidth="100%">
                    <Typography variant="h3" textAlign="center">
                        {t('attachments')}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom textAlign="center">
                        {t('attachmentsExplanation')}
                    </Typography>
                    <AttachmentsInput attachments={attachments} addAttachment={addAttachment} removeAttachment={removeAttachment} handleWrongFileExtension={handleWrongFileExtension} />
                </Stack>
            </Popover>
        </>
    );
};

export default ItemAttachments;
