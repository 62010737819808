import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useState } from 'react';
import CalculationBasisDynamicPriceItem from './CalculationBasisDynamicPriceItem';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { RuleBasedVariableResource, ValueResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        .accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    categoryId: number;
    priceVariable: RuleBasedVariableResource;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
}

const CalculationBasisDynamicPriceDialog: React.FC<Props> = ({ categoryId, priceVariable, isOpen, setOpen }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();

    const addPrice = () => {
        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, {
                categoryId,
                variable: {
                    ...priceVariable,
                    rules: [...priceVariable.rules, { id: generateUniqueID(), value: { type: ValueResource.type.STRING_VALUE, value: '0' } }]
                } as RuleBasedVariableResource
            })
        );
    };
    const changePrice = (ruleId: number, value: ValueResource) => {
        const rules = cloneDeep(priceVariable.rules);
        const ruleIndex = priceVariable?.rules.findIndex((rule) => rule.id === ruleId);
        rules[ruleIndex].value = value;

        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, {
                categoryId,
                variable: { ...priceVariable, rules } as RuleBasedVariableResource
            })
        );
    };
    const removePrice = (ruleId: number) => {
        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, {
                categoryId,
                variable: { ...priceVariable, rules: priceVariable.rules.filter((rule) => rule.id !== ruleId) } as RuleBasedVariableResource
            })
        );
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Dynamischer m² Preis
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <StyledStack>
                    <Divider />
                    {(priceVariable?.rules || []).map((rule) => (
                        <CalculationBasisDynamicPriceItem
                            key={rule.id}
                            rule={rule}
                            openAccordionId={openAccordionId}
                            setOpenAccordionId={setOpenAccordionId}
                            changeRuleValue={changePrice}
                            removeRule={removePrice}
                        />
                    ))}
                    <Divider sx={{ mt: 'auto' }} />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={addPrice} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        Weiteren Preis hinzufügen
                    </Button>
                </StyledStack>
            </DialogContent>
        </Dialog>
    );
};

export default CalculationBasisDynamicPriceDialog;
