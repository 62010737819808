import { Box, Button, IconButton, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { InquiryResource, InquiryRestControllerService, InquiryStateResource, SummaryResource, SummaryRestControllerService } from 'src/backend/market';
import CalculationStatus from 'src/components/display/CalculationStatus/CalculationStatus';
import PageHeader from 'src/components/display/PageHeader';
import { Summary } from 'src/components/display/Summary/Summary';
import useDialog from 'src/hooks/useDialog';
import { RootState } from 'src/redux/store';
import { importInquiries } from 'src/redux/thunks/inquiries.thunk';
import { nav } from 'src/statics/navigations';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

interface IRequestDetail {
    inquiryStates: InquiryStateResource[];
    inquiries: { [key: string]: InquiryResource };
    loading: boolean;
}

const RequestDetail: React.FC<IRequestDetail> = ({ inquiryStates, inquiries }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dialog = useDialog();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const mediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [overwrittenStatus, setOverwrittenStatus] = useState<string>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [wizardSummary, setWizardSummary] = useState<SummaryResource>(null);
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        console.log(inquiries, !_.has(inquiries ?? [], id));
        if (!_.has(inquiries ?? [], id) && overwrittenStatus == null) {
            console.log('Load inquiryStatus');
            InquiryRestControllerService.getInquiryState(id).then((inquiryState) => {
                console.log('Set overwritten Status');
                setOverwrittenStatus(inquiryState.name);
            });
        }

        if (!id) return;
        setLoading(true);
        SummaryRestControllerService.getCoaterWizardSummary(id).then((summary) => {
            setWizardSummary(summary);
            setLoading(false);
        });

        InquiryRestControllerService.setInquirySeenState(id, { seen: true });
    }, []);

    const handleBack = () => {
        navigate(nav.CALCULATIONS.path);
    };

    const renderTitle = () => {
        const title = t(nav.CALCULATIONS.sub.VIEW.caption, { id });
        if (!wizardSummary) return title;

        return (
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                sx={{
                    '& > div': {
                        xs: {
                            'margin-left': -4
                        },
                        md: {
                            'margin-left': 18
                        }
                    }
                }}
            >
                <span style={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{title}</span>
                <CalculationStatus status={wizardSummary.status} importedWizardId={wizardSummary.calculationFromImportedWizardId} />
            </Stack>
        );
    };

    const handleEditCalc = () => {
        dispatch(
            importInquiries(id, (internalId) => {
                navigate(nav.CALCULATIONS.sub.EDIT.path.replace(':id', internalId));
            })
        );
    };

    return (
        <Box>
            <PageHeader sx={{ mb: 2, displayPrint: 'none' }} handleBack={handleBack} mainTitle={renderTitle()} secondaryTitle={t('details')} tabTitle={t(nav.CALCULATIONS.sub.VIEW.tabTitle, { id })}>
                {/* <Button variant="text" startIcon={<AutoAwesomeTwoToneIcon/>} onClick={handleEditCalc}>
                {t('insights')}
            </Button> */}
                {isSmallScreen ? (
                    <IconButton onClick={handleEditCalc} color="primary">
                        <EditTwoToneIcon />
                    </IconButton>
                ) : (
                    <Button variant="text" startIcon={<EditTwoToneIcon />} onClick={handleEditCalc}>
                        {t('kalkulationEditieren')}
                    </Button>
                )}
            </PageHeader>
            <Summary summary={wizardSummary} isLoading={isLoading} />
        </Box>
    );
};
const mapStateToProps = (state: RootState) => {
    return {
        inquiryStates: state.constants.inquiryStates,
        inquiries: state.inquiries.inquiries,
        loading: state.inquiries.loading
    };
};
export default connect(mapStateToProps)(RequestDetail);
