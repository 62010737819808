/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileResource } from '../models/FileResource';
import type { FolderResource } from '../models/FolderResource';
import type { NewFileDto } from '../models/NewFileDto';
import type { NewFolderDto } from '../models/NewFolderDto';
import type { NewGeneralFileDto } from '../models/NewGeneralFileDto';
import type { PageNodeResource } from '../models/PageNodeResource';
import type { PresignedUrlResource } from '../models/PresignedUrlResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FileManagementRestControllerService {

    /**
     * @param requestBody
     * @returns PresignedUrlResource OK
     * @throws ApiError
     */
    public static createNewPresignedUrlForThumbnail(
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<PresignedUrlResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/fileManagement/thumbnails/presignedUrl`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PresignedUrlResource OK
     * @throws ApiError
     */
    public static createNewPresignedUrlForFile(
        requestBody: NewGeneralFileDto,
    ): CancelablePromise<PresignedUrlResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/fileManagement/presignedUrl`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param folderId
     * @param page
     * @param size
     * @param sortBy
     * @param fileName
     * @returns PageNodeResource OK
     * @throws ApiError
     */
    public static getFilesOfFolder(
        companyId: number,
        userId: number,
        folderId: string,
        page?: number,
        size?: number,
        sortBy: string = 'idAsc',
        fileName?: string,
    ): CancelablePromise<PageNodeResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/folders/${folderId}`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
                'fileName': fileName,
            },
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param folderId
     * @param requestBody
     * @returns FolderResource OK
     * @throws ApiError
     */
    public static addFolderToFolder(
        companyId: number,
        userId: number,
        folderId: string,
        requestBody: NewFolderDto,
    ): CancelablePromise<FolderResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/folders/${folderId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param folderId
     * @param requestBody
     * @returns FileResource OK
     * @throws ApiError
     */
    public static addFileToFolder(
        companyId: number,
        userId: number,
        folderId: string,
        requestBody: NewFileDto,
    ): CancelablePromise<FileResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/folders/${folderId}/files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param page
     * @param size
     * @param sortBy
     * @param fileName
     * @returns PageNodeResource OK
     * @throws ApiError
     */
    public static getRootFolder(
        companyId: number,
        userId: number,
        page?: number,
        size?: number,
        sortBy: string = 'idAsc',
        fileName?: string,
    ): CancelablePromise<PageNodeResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/folders/root`,
            query: {
                'page': page,
                'size': size,
                'sortBy': sortBy,
                'fileName': fileName,
            },
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param requestBody
     * @returns FolderResource OK
     * @throws ApiError
     */
    public static addFolderToRootFolder(
        companyId: number,
        userId: number,
        requestBody: NewFolderDto,
    ): CancelablePromise<FolderResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/folders/root`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param requestBody
     * @returns FileResource OK
     * @throws ApiError
     */
    public static addFileToRootFolder(
        companyId: number,
        userId: number,
        requestBody: NewFileDto,
    ): CancelablePromise<FileResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/folders/root/files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param fileId
     * @returns FileResource OK
     * @throws ApiError
     */
    public static getFile(
        companyId: number,
        userId: number,
        fileId: string,
    ): CancelablePromise<FileResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/files/${fileId}`,
        });
    }

    /**
     * @param companyId
     * @param userId
     * @param fileId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFile(
        companyId: number,
        userId: number,
        fileId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/fileManagement/companies/${companyId}/users/${userId}/files/${fileId}`,
        });
    }

}