import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {Box, Button, Card, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch} from "@mui/material";
import _ from "lodash";
import {useSnackbar} from "notistack";
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import { MarketplaceWizardProfileResource, WizardProfileRestControllerService } from "src/backend/market";
import { BackendService } from "src/backend/summary/BackendService";
import WizardStatus from "src/components/display/WizardStatus/WizardStatus";
import {IWizardStati} from "src/redux/slices/wizardStati.reducer";
import {RootState} from "src/redux/store";
import {setAllWizardStati} from "src/redux/thunks/wizardStati.thunk";

interface Props {
    stati: IWizardStati,
    loading: boolean,
    languageCodes: string[],
    profile: MarketplaceWizardProfileResource,
    reloadWizard: () => void;
}

const WizardActivationInput: FC<Props> = ({loading, stati, languageCodes, profile, reloadWizard}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [savedWizardLanguage, setSavedWizardLanguage] = useState<string>('');
    const [wizardLanguage, setWizardLanguage] = useState<string>('');

    const wizardStati = stati as IWizardStati;
    const [state, setState] = useState({
        regularActive: wizardStati.regularCustomerWizardState,
        walkInActive: wizardStati.walkInCustomerWizardState,
        wholeActive: wizardStati.wholeWizardState,
    });

    useEffect(() => {
        BackendService.getWizardLanguage(profile.wizardKey).then(languageCode => {
            setSavedWizardLanguage(languageCode);
            setWizardLanguage(languageCode);
        });
    }, []);

    const handleWizardLanguageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const languageCode = event.target.value;
        setWizardLanguage(languageCode);
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    }

    function saveChanges() {
        console.log(state, state.regularActive && state.walkInActive);
        dispatch(setAllWizardStati({
            regularCustomerWizardState: state.regularActive,
            walkInCustomerWizardState: state.walkInActive,
            wholeWizardState: state.regularActive && state.walkInActive
        }, wizardLanguage, () => {
            setSavedWizardLanguage(wizardLanguage);
            const key = enqueueSnackbar(t("statusHasBeenChanged"), {
                variant: "success",
                action: <IconButton color="inherit" onClick={() => closeSnackbar(key)}><CloseRoundedIcon/></IconButton>,
            });
            reloadWizard();
        }));
    }

    const handleDisableSave = (): boolean => {
        return _.isEqual({
            regularActive: wizardStati.regularCustomerWizardState,
            walkInActive: wizardStati.walkInCustomerWizardState,
            wholeActive: wizardStati.wholeWizardState,
            languageCode: wizardLanguage
        }, {
            ...state,
            languageCode: savedWizardLanguage
        });
    };

    return <Card sx={{height: "100%"}}>
        <Box p={2}><WizardStatus/></Box>
        <Grid container spacing={3} p={3}>

            <Grid item xs={12} md={6}>
                <FormControlLabel
                    control={
                        <Switch id={"walkInActive"}
                                checked={state.walkInActive} onChange={handleChange} name="walkInActive"/>
                    }
                    label={"" + t('wizardFrNormaleKundenAktivieren')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControlLabel
                    control={
                        <Switch id={'regularActive'}
                                checked={state.regularActive} onChange={handleChange} name="regularActive"/>
                    }
                    label={"" + t('wizardFrStammkundenAktivieren')}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Box pt={3} display='flex' alignItems='center' justifyContent='space-between'>
                    <FormControl sx={{ width: '20rem' }}>
                        <InputLabel id="wizard-language-label" shrink>{t('wizardDefaultLanguage')}</InputLabel>
                        <Select
                            labelId='wizard-language-label'
                            label={t('wizardDefaultLanguage')}
                            onChange={handleWizardLanguageChange}
                            value={wizardLanguage}
                        >
                            {
                                languageCodes.map((languageCode) => {
                                    return <MenuItem value={languageCode} key={languageCode}>
                                        {t(languageCode)}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>

                    <Button
                        id={"statiSubmit"}
                        type='submit'
                        variant='contained'
                        onClick={saveChanges}
                        disabled={handleDisableSave()}
                    >
                        {t('SAVE')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    </Card>
}

const mapStateToProps = (state: RootState) => {
    return {
        stati: state.wizardStati.stati,
        loading: state.wizardStati.loading,
        languageCodes: state.language.languageCodes,
        profile: state.wizardProfile.currentProfile,
    }
}
export default connect(mapStateToProps)(WizardActivationInput);
