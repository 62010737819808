import { FC, ReactNode, createRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button, InputAdornment, Stack, TextField, TextFieldProps, Typography } from '@mui/material';

const StyledTextField = styled(TextField)(
    ({ theme }) => `
        flex-direction: row;
        
        .MuiInputBase-root {
            padding: 0;
        }
        .MuiInputAdornment-root, .minus, .plus {
            height: 100%;
            max-height: 100%;
            padding: 0;
        }
        .MuiInputBase-root .MuiInputAdornment-root {
            padding-right: 0 !important;
        }
        .minus {
            border-right: 1px solid ${theme.colors.alpha.black[10]};
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            min-width: 2rem;
        }
        .plus {
            border-left: 1px solid ${theme.colors.alpha.black[10]};
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            min-width: 2rem;
        }

        input {
            text-align: right;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    `
);

type Props = {
    unit?: string;
    disabled?: boolean;
    startIcon?: ReactNode;
} & TextFieldProps;

const NumberInput = ({ unit, disabled, startIcon, ...props }: Props) => {
    const inputRef = createRef<HTMLInputElement>();

    const triggerInputChange = () => {
        const event = new Event('input', { bubbles: true });
        inputRef.current.dispatchEvent(event);
    };

    const incrementValue = () => {
        inputRef.current.stepUp();
        triggerInputChange();
    };

    const decrementValue = () => {
        inputRef.current.stepDown();
        triggerInputChange();
    };

    return (
        <StyledTextField
            {...props}
            type="number"
            inputRef={inputRef}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Button onClick={decrementValue} className="minus" variant="text" tabIndex={-1} disabled={disabled}>
                            -
                        </Button>
                        {startIcon}
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" sx={{ ml: 0.5 }}>
                        {unit && (
                            <Typography variant="subtitle1" mr={0.5} sx={{ userSelect: 'none', opacity: disabled ? 0.5 : 1 }} onClick={() => inputRef.current.focus()}>
                                {unit}
                            </Typography>
                        )}
                        <Button onClick={incrementValue} className="plus" variant="text" tabIndex={-1} disabled={disabled}>
                            +
                        </Button>
                    </InputAdornment>
                )
            }}
        />
    );
};

export default NumberInput;
