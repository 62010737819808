import { InputAdornment } from '@mui/material';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculationParameterResource } from 'src/backend/market';
import { useDispatch } from 'react-redux';
import { FRAGMENT_RUNNING_METER } from 'src/statics/statics';
import { CalculationFragmentResource } from 'src/backend/internalCalc/models/CalculationFragmentResource';
import { IntegerCalcParamResource } from 'src/backend/internalCalc';
import { ParameterArray } from 'src/utils/CalcHelpers';
import { StyledTextField } from 'src/components/input/CalcInputs/CalcInputs.styles';
import InfoLabel from 'src/components/input/CalcInputs/ParameterFields/InfoLabel';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

interface Props {
    partId: number;
    parameters?: ParameterArray;
    setParameters?: Array<CalculationParameterResource>;
    fragment?: CalculationFragmentResource;
    disabled?: boolean;
}

const ItemFragmentRunningMeter: React.FC<Props> = ({ partId, parameters, setParameters, fragment, disabled }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const runningMeterParameter = useMemo(() => {
        const param = parameters || [];
        return param.find((parameter) => parameter.name === FRAGMENT_RUNNING_METER) as IntegerCalcParamResource;
    }, [parameters]);

    const runningMeterSetParameter = useMemo(() => {
        return setParameters.find((parameter) => parameter.name === FRAGMENT_RUNNING_METER);
    }, [setParameters]);

    const [runningMeter, setRunningMeter] = useState<string>((runningMeterSetParameter?.value || runningMeterParameter?.defaultValue || 1) + '');
    const [width, setWidth] = useState<number>(runningMeter.length);

    const changeRunningMeter = (value): void => {
        setRunningMeter(value);
        saveRunningMeter(value);
        setWidth(value.length);
    };
    const handleRunningMeterChange = (event: ChangeEvent<HTMLInputElement>): void => {
        changeRunningMeter(event.target.value);
        setWidth(event.target.value.length);
    };

    const saveRunningMeter = (newQuantity) => {
        const newQuantitySetParameter = { ...runningMeterSetParameter, value: newQuantity };
        dispatch(updateProCalc(ProCalcUpdateTypes.FRAGMENT_PARAMETER, { partId, fragment: fragment, parameter: newQuantitySetParameter }));
    };

    const handleRunningMeterBlur = (): void => {
        const min = runningMeterParameter?.min ?? 0;
        const max = runningMeterParameter?.max ?? 1000;

        if (!runningMeter || isNaN(+runningMeter)) return changeRunningMeter(min + '');
        const parsedRunningMeter = parseInt(runningMeter);
        if (parsedRunningMeter < min) changeRunningMeter(min + '');
        if (parsedRunningMeter > max) changeRunningMeter(max + '');
    };

    return (
        <StyledTextField
            type="number"
            label={<InfoLabel label={runningMeterParameter.name} guiDescriptor={runningMeterParameter.guiDescriptor} guiDescriptorExplanation={runningMeterParameter.explanationTranslationKey} />}
            onChange={handleRunningMeterChange}
            onBlur={handleRunningMeterBlur}
            value={runningMeter}
            onFocus={(e) => e.target.select()}
            InputProps={{
                endAdornment: <InputAdornment position="end">{runningMeterParameter.unit}</InputAdornment>
            }}
            inputProps={{ min: runningMeterParameter?.min, max: runningMeterParameter?.max }}
            sx={{ width: (width + runningMeterParameter.unit.length + 7) * 10 + 'px', minWidth: '9rem' }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            size="small"
            disabled={disabled}
        />
    );
};

export default ItemFragmentRunningMeter;
