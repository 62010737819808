import { Card, Collapse, Divider, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { InternalCalculationResource } from 'src/backend/internalCalc';
import MasterDeliveryAndPickup from './MasterDeliveryAndPickup';
import MasterButtons from './MasterButtons';
import { transformToCalculationParameter } from 'src/utils/CalcHelpers';
import { CalcValidator } from 'src/utils/CalcValidator';
import { Formik } from 'formik';
import { FormikAutoSave } from 'src/utils/FormikAutoSave';
import { VARIABLE_VALUE_MASTER_PRICE } from 'src/statics/statics';
import { formatPrice } from 'src/utils/FormatHelpers';
import Customer from './Customer/Customer';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import { RootState } from 'src/redux/store';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';

interface Props {
    calculation?: InternalCalculationResource;
}

const Master: React.FC<Props> = ({ calculation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(true);

    const allParameters = calculation?.masterCalculationParameters?.allParameters || [];

    const getDefaultParams = useCallback(() => {
        if (!calculation.masterCalculationSetParameters) return {};
        const defaultParams = {};
        [...calculation.masterCalculationSetParameters].forEach((param) => {
            defaultParams[param.name] = param.value;
        });
        return defaultParams;
    }, [calculation?.id]);

    const saveParams = (params, setParameters) => {
        const newParams = transformToCalculationParameter(params);
        if (_.isEqual(newParams, setParameters)) return;
        dispatch(updateProCalc(ProCalcUpdateTypes.MASTER_PARAMETERS, newParams));
    };

    const masterPrice = useMemo(() => {
        if (!calculation?.costResult) return;
        const master = calculation.costResult.variableValues.find((value) => value.name === VARIABLE_VALUE_MASTER_PRICE);
        return master?.value;
    }, [calculation?.costResult]);

    return (
        calculation && (
            <Stack>
                <HeadlineButton isOpen={isOpen} setOpen={setOpen} variant="page">
                    {t('order')}
                    {masterPrice != null && (
                        <Stack ml="auto">
                            <Typography color="primary" fontSize={20}>
                                {formatPrice(masterPrice, true)}
                            </Typography>
                        </Stack>
                    )}
                </HeadlineButton>

                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Card sx={{ mb: 3 }}>
                        <Stack p={2} px={3} gap={4}>
                            <Formik
                                key={calculation.id}
                                validateOnChange
                                validationSchema={CalcValidator.buildParameterValidator(t, allParameters, calculation.masterCalculationSetParameters, calculation.costResult?.guiStates)}
                                initialValues={getDefaultParams()}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    saveParams(values, calculation.masterCalculationSetParameters);
                                }}
                            >
                                <Stack gap={4} direction="row" alignItems="center">
                                    <Customer regularCustomerId={calculation?.regularCustomer} metadata={calculation?.calculationMetadata} />
                                    <Divider flexItem orientation="vertical" sx={{ my: -2, mr: 'auto' }} />
                                    <MasterDeliveryAndPickup />
                                    <Divider flexItem orientation="vertical" sx={{ my: -2, mr: 'auto' }} />
                                    <MasterButtons />
                                    <FormikAutoSave />
                                </Stack>
                            </Formik>
                        </Stack>
                    </Card>
                </Collapse>
            </Stack>
        )
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculation: state.proCalc.calculation
    };
};
export default connect(mapStateToProps)(Master);
