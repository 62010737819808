import { LocationCity, Menu } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'src/components/display/PageHeader';
import { nav } from 'src/statics/navigations';
import CalculationsTable from '../../../components/display/Tables/CalculationsTable/CalculationsTable';
import useDialog from '../../../hooks/useDialog';
import { useDispatch } from '../../../redux/store';
import { startWalkthrough } from '../../../redux/thunks/guidethrough.thunk';
import { createBlueprint } from 'src/redux/thunks/calcEditor.thunk';

const ProcedureList = styled('div')`
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
    }
`;

type version = {
    id: number;
    name: string;
};

type procedure = {
    id: number;
    name: string;
    versions: version[];
};

type calculationResource = {
    id: number;
    location: string;
    procedures: procedure[];
};

const calculationResources: calculationResource[] = [
    {
        id: 1,
        location: 'Werk 1 / Deutschland',
        procedures: [
            {
                id: 1,
                name: 'Pulverbeschichtung',
                versions: [
                    {
                        id: 1,
                        name: 'Version 1.2'
                    }
                ]
            },
            {
                id: 2,
                name: 'Nasslack',
                versions: [
                    {
                        id: 1,
                        name: 'Version 3.1'
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        location: 'Werk 2 / Deutschland',
        procedures: [
            {
                id: 1,
                name: 'Pulverbeschichtung',
                versions: [
                    {
                        id: 1,
                        name: 'Version 1.2'
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        location: 'Werk 3 / Österreich',
        procedures: [
            {
                id: 1,
                name: 'Pulverbeschichtung',
                versions: [
                    {
                        id: 1,
                        name: 'Version 1.2'
                    }
                ]
            }
        ]
    }
];

const CalcEditorOverview: React.FC = () => {
    const dialog = useDialog();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(startWalkthrough('calculationConfiguration'));
    }, []);

    const handleBack = () => {
        navigate(nav.TOOLS.path);
    };

    const openCreateCalcDialog = () => {
        dialog.openDialog({
            async okCallback(setOkLoading) {
                setOkLoading(true);
                const blueprint = await dispatch(createBlueprint('test'));
                if (!blueprint) return alert('error');
                navigate(nav.TOOLS.sub.CALC_EDITOR.sub.EDIT.path.replace(':id', blueprint.id));
                setOkLoading(false);
                dialog.closeDialog();
            },
            title: 'Neue Kalkulation anlegen',
            okText: 'Kalkulation erstellen',
            cancelText: 'Abbrechen',
            width: 'large',
            noMaxWidth: true,
            component: (
                <Stack>
                    <p>
                        <strong>Name</strong>
                    </p>
                </Stack>
            )
        });
    };

    return (
        <>
            <PageHeader mainTitle="Kalkulationskonfiguration" handleBack={isSmallScreen && handleBack}>
                <Button onClick={openCreateCalcDialog} size="large" variant="contained">
                    {t('createBlueprint')}
                </Button>
            </PageHeader>
            <Stack justifyContent="start" pt={4} gap={2}>
                {calculationResources.map((calculationResource) => {
                    return (
                        <Card key={calculationResource.id} style={{ minHeight: '170px' }}>
                            <CardContent>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <LocationCity color={'primary'} />
                                    <Menu color={'primary'} style={{ fontSize: '18px' }} />
                                </div>
                                <Typography sx={{ mt: 1, mb: 1 }} variant={'h3'}>
                                    {calculationResource.location}
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                                <ProcedureList>
                                    {calculationResource.procedures.map((value) => {
                                        return (
                                            <div key={value.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <strong>
                                                    <p>{value.name}</p>
                                                </strong>
                                                <p>{value.versions.reverse().at(0).name}</p>
                                            </div>
                                        );
                                    })}
                                </ProcedureList>
                            </CardContent>
                        </Card>
                    );
                })}
            </Stack>
        </>
    );
};

export default CalcEditorOverview;
