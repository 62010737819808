/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentPathRequestsDto } from '../models/AttachmentPathRequestsDto';
import type { AttachmentPresignedUrlResource } from '../models/AttachmentPresignedUrlResource';
import type { AttachmentRequestPresignedUrlDto } from '../models/AttachmentRequestPresignedUrlDto';
import type { AttachmentWithPathsResource } from '../models/AttachmentWithPathsResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AttachmentRestControllerService {

    /**
     * @param requestBody
     * @returns AttachmentPresignedUrlResource OK
     * @throws ApiError
     */
    public static createNewPresignedUrlForMetadata1(
        requestBody: AttachmentRequestPresignedUrlDto,
    ): CancelablePromise<AttachmentPresignedUrlResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/attachments/presignedUrl`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AttachmentWithPathsResource OK
     * @throws ApiError
     */
    public static getAttachmentsWithPaths(
        requestBody: AttachmentPathRequestsDto,
    ): CancelablePromise<Array<AttachmentWithPathsResource>> {
        return __request({
            method: 'POST',
            path: `/marketplace/attachments/paths`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}