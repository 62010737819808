/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiMailConversationResource } from '../models/AiMailConversationResource';
import type { AiMailResource } from '../models/AiMailResource';
import type { CalculationIdsDto } from '../models/CalculationIdsDto';
import type { MailConfigurationDto } from '../models/MailConfigurationDto';
import type { UnreadMailCountResource } from '../models/UnreadMailCountResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AiMailConversationRestControllerService {

    /**
     * @param calculationUid
     * @param mailId
     * @returns AiMailResource OK
     * @throws ApiError
     */
    public static setMailRead(
        calculationUid: string,
        mailId: number,
    ): CancelablePromise<AiMailResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/aiMailConversations/${calculationUid}/aiMails/${mailId}/read`,
        });
    }

    /**
     * @param calculationUid
     * @returns AiMailResource OK
     * @throws ApiError
     */
    public static getGeneratedAnswerToMail(
        calculationUid: string,
    ): CancelablePromise<AiMailResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/aiMailConversations/${calculationUid}/aiMails/answer`,
        });
    }

    /**
     * @param calculationUid
     * @param requestBody
     * @returns AiMailResource OK
     * @throws ApiError
     */
    public static updateAnswer(
        calculationUid: string,
        requestBody: MailConfigurationDto,
    ): CancelablePromise<AiMailResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/aiMailConversations/${calculationUid}/aiMails/answer`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param calculationUid
     * @returns AiMailResource OK
     * @throws ApiError
     */
    public static sendAnswer(
        calculationUid: string,
    ): CancelablePromise<AiMailResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/aiMailConversations/${calculationUid}/aiMails/sentAnswers`,
        });
    }

    /**
     * @param requestBody
     * @returns AiMailConversationResource OK
     * @throws ApiError
     */
    public static getConversationStreamOverview(
        requestBody: CalculationIdsDto,
    ): CancelablePromise<Array<AiMailConversationResource>> {
        return __request({
            method: 'POST',
            path: `/marketplace/aiMailConversations/conversationOverview`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @returns UnreadMailCountResource OK
     * @throws ApiError
     */
    public static getUnreadMails(
        companyId: number,
    ): CancelablePromise<UnreadMailCountResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/aiMailConversations/${companyId}/aiMails/unread`,
        });
    }

    /**
     * @param calculationUid
     * @returns AiMailConversationResource OK
     * @throws ApiError
     */
    public static getConversationStreamOfCalculation(
        calculationUid: string,
    ): CancelablePromise<AiMailConversationResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/aiMailConversations/${calculationUid}`,
        });
    }

    /**
     * @param calculationUid
     * @param mailId
     * @returns AiMailResource OK
     * @throws ApiError
     */
    public static getConversationStreamOfCalculation1(
        calculationUid: string,
        mailId: number,
    ): CancelablePromise<AiMailResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/aiMailConversations/${calculationUid}/aiMails/${mailId}`,
        });
    }

}