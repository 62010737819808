import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, Box, Button, Grid, IconButton, Popover, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { CalculationMetadataResource } from 'src/backend/internalCalc';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/store';
import { loadCustomers } from 'src/redux/thunks/customers.thunk';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import CustomerMap from './CustomerMap';
import { LoadScriptNext } from '@react-google-maps/api';
import CustomerDisplay from './CustomerDisplay';
import { FormikAutoSave } from 'src/utils/FormikAutoSave';
import { startWalkthrough, stopWalkthrough } from '../../../../redux/thunks/guidethrough.thunk';
import { RegularCustomerResource, RegularCustomerRestControllerService, UserResource } from 'src/backend/market';
import i18n from 'src/i18n/i18n';
import CountrySelect from 'src/components/input/CountrySelect';
import { API_CONFIG } from 'src/config';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';
import { ProCalcUpdateTypes, updateProCalc } from 'src/redux/shared/proCalc.common';
import { ProCalcUpdateLoading } from 'src/redux/slices/proCalc.reducer';

const libraries: Array<any> = ['places'];

interface Props {
    customers: { [p: string]: RegularCustomerResource };
    user: UserResource;
    isUpdateLoading: ProCalcUpdateLoading;
    regularCustomerId?: number;
    metadata?: CalculationMetadataResource;
}

const Customer: React.FC<Props> = ({ customers, user, isUpdateLoading, regularCustomerId, metadata = {} }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [predictions, setPredictions] = useState<Array<any>>([]);
    const [userPos, setUserPos] = useState<google.maps.LatLngLiteral>(null);
    const [customerPos, setCustomerPos] = useState<google.maps.LatLngLiteral>(null);
    const [sessionToken, setSessionToken] = useState<any>(null);
    const [linkedRegularCustomer, setLinkedRegularCustomer] = useState<RegularCustomerResource>(null);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const autocomplete = useRef<any>(null);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (autocomplete.current || !isLoaded) return;

        autocomplete.current = new google.maps.places.AutocompleteService();
        setSessionToken(new google.maps.places.AutocompleteSessionToken());

        if (user.company.latitude != null && user.company.longitude != null) {
            setUserPos({
                lat: user.company.latitude,
                lng: user.company.longitude
            });
        }
    }, [isLoaded]);

    const getInitCustomer = useCallback(() => {
        return {
            companyName: metadata?.companyName || '',
            contactPerson: {
                firstName: metadata?.contactPerson?.firstName || '',
                lastName: metadata?.contactPerson?.lastName || '',
                email: metadata?.contactPerson?.email || '',
                phone: metadata?.contactPerson?.phone || ''
            },
            contactedVia: metadata?.contactedVia || '',
            placeId: metadata?.placeId || '',
            address: {
                street: metadata?.address?.street || '',
                houseNumber: metadata?.address?.houseNumber || '',
                zipcode: metadata?.address?.zipcode || '',
                city: metadata?.address?.city || '',
                country: metadata?.address?.country || ''
            },
            note: metadata?.note || '',
            attachments: metadata?.attachments || []
        };
    }, [metadata]);

    const baseValidationSchema = {
        companyName: Yup.string().max(200, t('The company name is too long.')),
        contactPerson: Yup.object().shape({}),
        address: Yup.object().shape({
            street: Yup.string().max(200, t('The street name is too long.'))
        })
    };

    const saveCustomer = (values: CalculationMetadataResource) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.METADATA, values));
    };

    useEffect(() => {
        if (!metadata?.address) return;
        if (metadata.address.latitude && metadata.address.longitude) setCustomerPos({ lat: metadata.address.latitude, lng: metadata.address.longitude });
        else setCustomerPos(null);
    }, [metadata]);

    const formik = useFormik({
        initialValues: getInitCustomer(),
        validationSchema: Yup.object().shape(baseValidationSchema),
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            saveCustomer(values);
        }
    });

    const updatePlacePredictions = (companyName) => {
        if (!autocomplete.current) return;
        if (companyName.length < 3) return setPredictions([]);

        autocomplete.current.getQueryPredictions(
            {
                input: companyName,
                types: ['establishment'],
                sessionToken
            },
            (predictions) => {
                setPredictions(predictions);
            }
        );
    };

    const autocompleteOptions = [
        ...Object.values(customers || {}).map((customer) => ({ category: 'regularCustomers', value: customer.companyName, customer })),
        ...(predictions || []).map((prediction) => ({ category: 'googleMapsPredictions', value: prediction.description, prediction }))
    ];

    useEffect(() => {
        if (_.values(customers).length === 0) dispatch(loadCustomers(4));

        if (regularCustomerId) {
            RegularCustomerRestControllerService.getRegularCustomer(user.id, regularCustomerId).then((regularCustomer) => setLinkedRegularCustomer(regularCustomer));
        }
    }, []);

    useEffect(() => {
        dispatch(loadCustomers(4, { searchString: formik.values.companyName }));
        updatePlacePredictions(formik.values.companyName);
    }, [formik.values.companyName]);

    const handleContactedViaChange = (contactedVia: string) => {
        formik.setFieldValue('contactedVia', contactedVia);
    };
    const handleCountrySelected = (event, selectedItem, reason: AutocompleteChangeReason) => {
        if (reason !== 'selectOption' && reason !== 'clear') return;
        formik.setFieldValue('placeId', null, false);
        formik.setFieldValue('address.country', selectedItem);
    };
    const handleCompanyChange = (event: SyntheticEvent, newValue: string, reason: AutocompleteInputChangeReason) => {
        if (reason !== 'input') return;
        formik.setFieldValue('companyName', newValue);
        unlinkRegularCustomer();
    };
    const handleCustomerSelected = (event, selectedItem, reason: AutocompleteChangeReason) => {
        if (reason !== 'selectOption') return;

        if (selectedItem.category === 'regularCustomers') {
            if (linkedRegularCustomer?.companyName === selectedItem.value) return setRegularCustomerValues(linkedRegularCustomer);
            return linkRegularCustomer(selectedItem.customer);
        }
        unlinkRegularCustomer();
        if (selectedItem.category === 'googleMapsPredictions') {
            setGoogleMapsPrediction(selectedItem.prediction);
        }
    };
    const setRegularCustomerValues = (regularCustomer: RegularCustomerResource) => {
        formik.setValues({
            ...formik.values,
            companyName: regularCustomer.companyName,
            contactPerson: {
                firstName: regularCustomer.contactPersonFirstName,
                lastName: regularCustomer.contactPersonLastName,
                email: regularCustomer.email,
                phone: regularCustomer.phone
            },
            address: {
                street: regularCustomer.street,
                houseNumber: regularCustomer.houseNumber,
                zipcode: regularCustomer.zipcode,
                city: regularCustomer.city,
                country: ''
            },
            note: regularCustomer.notes,
            placeId: null
        });
    };
    const linkRegularCustomer = (regularCustomer: RegularCustomerResource) => {
        dispatch(updateProCalc(ProCalcUpdateTypes.REGULAR_CUSTOMER, regularCustomer.id));
        setLinkedRegularCustomer(regularCustomer);
        setRegularCustomerValues(regularCustomer);
    };
    const unlinkRegularCustomer = () => {
        if (!linkedRegularCustomer) return;

        dispatch(updateProCalc(ProCalcUpdateTypes.REGULAR_CUSTOMER, null));
        setLinkedRegularCustomer(null);
    };
    const setGoogleMapsPrediction = (prediction) => {
        const values = {
            ...formik.values,
            companyName: prediction.structured_formatting.main_text,
            address: { street: '', houseNumber: '', zipcode: '', city: '', country: '' },
            placeId: prediction.place_id
        };
        formik.setValues(values, false);
        saveCustomer(values);
    };

    const renderTextField = (label, key, addressInput = false) => {
        return (
            <TextField
                fullWidth
                label={t(label)}
                value={_.get(formik.values, key)}
                onChange={(event) => {
                    if (addressInput) {
                        formik.setFieldValue('placeId', null, false);
                    }
                    formik.handleChange(event);
                }}
                name={key}
                error={Boolean(_.get(formik.errors, key) && _.get(formik.touched, key))}
                helperText={_.get(formik.touched, key) && _.get(formik.errors, key)}
                inputProps={{ maxLength: 350 }}
                InputLabelProps={{ shrink: true }}
            />
        );
    };

    return (
        <>
            <CustomerDisplay
                values={formik.values}
                linkedRegularCustomer={linkedRegularCustomer}
                buttonRef={ref}
                setOpen={() => {
                    setOpen(true);
                    dispatch(startWalkthrough('customerDetails'));
                }}
            />

            <Popover
                anchorEl={ref.current}
                onClose={() => {
                    setOpen(false);
                    dispatch(stopWalkthrough());
                }}
                open={isOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setOpen(false);
                        dispatch(stopWalkthrough());
                    }}
                    sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500], zIndex: 5000 }}
                >
                    <CloseTwoToneIcon />
                </IconButton>
                <form>
                    <Stack direction="row">
                        <Stack spacing={3} p={3} maxWidth={{ xs: '100%', md: '50rem' }}>
                            <Stack>
                                <Typography variant="subtitle2" gutterBottom display="flex" justifyContent="space-between">
                                    {t('contactedVia')}
                                </Typography>

                                <ButtonSelector
                                    items={[
                                        { value: 'PHONE', label: 'phone', icon: <CallTwoToneIcon /> },
                                        { value: 'EMAIL', label: 'email', icon: <AlternateEmailTwoToneIcon /> },
                                        { value: 'IN_PERSON', label: 'inPerson', icon: <RecordVoiceOverTwoToneIcon /> },
                                        { value: 'WEB', label: 'web', icon: <LanguageTwoToneIcon /> }
                                    ]}
                                    value={formik.values.contactedVia}
                                    setValue={handleContactedViaChange}
                                    sx={{ mb: 0 }}
                                />
                            </Stack>

                            <Stack>
                                <Autocomplete
                                    id={'companyNameTextField'}
                                    freeSolo
                                    fullWidth
                                    value={formik.values.companyName}
                                    options={autocompleteOptions}
                                    onChange={handleCustomerSelected}
                                    onInputChange={handleCompanyChange}
                                    groupBy={(option) => option.category}
                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('company')}
                                            name="companyName"
                                            inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                                            InputLabelProps={{ shrink: true }}
                                            error={Boolean(formik.errors.companyName && formik.touched.companyName)}
                                            helperText={formik.touched.companyName && formik.errors.companyName}
                                        />
                                    )}
                                    renderGroup={(params) => (
                                        <li key={params.group}>
                                            <Typography variant="subtitle2" p={1} pt={1.5} pl={1.5}>
                                                {t(params.group)}
                                            </Typography>
                                            <ul style={{ padding: 0 }}>{params.children}</ul>
                                        </li>
                                    )}
                                />
                                {linkedRegularCustomer && (
                                    <Stack direction="row" alignItems="center" mb={-1}>
                                        <LinkTwoToneIcon sx={{ opacity: 0.7 }} />
                                        <Typography variant="subtitle2" ml={1}>
                                            {t('regularCustomerIsLinked', { companyName: linkedRegularCustomer.companyName })}
                                        </Typography>
                                        <Button onClick={unlinkRegularCustomer} sx={{ opacity: 0.7, p: 0.5, m: 0.5, ml: 'auto', mr: -1 }}>
                                            {t('unlink')}
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>

                            <Stack id={'dataBox'} spacing={3} maxWidth="50rem">
                                <Grid container gap={1} wrap={'nowrap'}>
                                    <Grid item xs={8}>
                                        {renderTextField('street', 'address.street', true)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderTextField('houseNumber', 'address.houseNumber', true)}
                                    </Grid>
                                </Grid>
                                <Grid container gap={1} wrap={'nowrap'}>
                                    <Grid item xs={4}>
                                        {renderTextField('zipcode', 'address.zipcode', true)}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {renderTextField('city', 'address.city', true)}
                                    </Grid>
                                </Grid>

                                <CountrySelect
                                    value={formik.values.address.country}
                                    name="address.country"
                                    helperText={formik.touched.address?.country && formik.errors.address?.country}
                                    onChange={handleCountrySelected}
                                />

                                <Grid container gap={1} wrap={'nowrap'}>
                                    <Grid item xs={6}>
                                        {renderTextField('firstName', 'contactPerson.firstName')}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {renderTextField('lastName', 'contactPerson.lastName')}
                                    </Grid>
                                </Grid>
                                {renderTextField('contactPhone', 'contactPerson.phone')}
                                {renderTextField('contactEmail', 'contactPerson.email')}
                                {renderTextField('customerNotes', 'note')}
                            </Stack>
                        </Stack>
                        <Box width="45rem">
                            <LoadScriptNext
                                key={`map-${i18n.language}`}
                                googleMapsApiKey={API_CONFIG.GOOGLE_MAPS_API_KEY}
                                libraries={libraries}
                                language={i18n.language}
                                onLoad={() => setLoaded(true)}
                            >
                                {isLoaded && userPos && !isSmallScreen && (
                                    <CustomerMap isRequestLoading={isUpdateLoading[ProCalcUpdateTypes.METADATA]} user={user} userPos={userPos} customerPos={customerPos} />
                                )}
                            </LoadScriptNext>
                        </Box>
                    </Stack>

                    <FormikAutoSave formik={formik} />
                </form>
            </Popover>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        customers: state.customers.customers,
        user: state.user.currentUser,
        isUpdateLoading: state.proCalc.isUpdateLoading
    };
};
export default connect(mapStateToProps)(Customer);
