import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { FileResource } from "src/backend/market";


export interface IFileManagerReducer {
    files: FileResource[];
    fileNameFilter: string;
    filesPaging: {
        page: number;
        pageSize: number;
        total: number;
    };
    filesSortBy: string;
}

const initialState: IFileManagerReducer = {
    files: [],
    fileNameFilter: null,
    filesPaging: {
        page: 0,
        pageSize: 30,
        total: 0,
    },
    filesSortBy: 'createdAtDesc'
}

const reducers = {
    setFiles: (state: IFileManagerReducer, action: PayloadAction<FileResource[]>) => {
        state.files = action.payload;
    },
    addFile: (state: IFileManagerReducer, action: PayloadAction<FileResource>) => {
        state.files.unshift(action.payload);
    },
    setFileNameFilter: (state: IFileManagerReducer, action: PayloadAction<string>) => {
        state.fileNameFilter = action.payload;
    },
    setPaging: (state: IFileManagerReducer, action: PayloadAction<{ page?: number, pageSize?: number, total?: number }>) => {
        state.filesPaging = { ...state.filesPaging, ...action.payload };
    },
    setFilesSort: (state: IFileManagerReducer, action: PayloadAction<string>) => {
        state.filesSortBy = action.payload;
    },
}

export const slice = createSlice({
    name: 'fileManager',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;