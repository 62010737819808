import { GridSortModel } from '@mui/x-data-grid';
import { AppDispatch, RootState } from '../store';
import { AiMailConversationRestControllerService, WizardCalcMergeFilterDto, WizardCalcMergeResource, WizardCalcMergingRestControllerService } from 'src/backend/market';
import { slice } from '../slices/calculations.reducer';
import { DIRTY } from 'src/statics/statics';
import { duplicateAndUpdateProCalc, duplicateProCalc, duplicateWizardCalc } from './proCalc.thunk';

const getSortingString = (calculationsSort: GridSortModel) => {
    if (calculationsSort.length === 0) return '';
    const sort = calculationsSort[0].sort.charAt(0).toUpperCase() + calculationsSort[0].sort.slice(1);
    return `${calculationsSort[0].field}${sort}`;
};

export const loadCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const autoReloadCalculations = getState().calculations.autoReloadCalculations;
    if (!autoReloadCalculations) return;

    dispatch(slice.actions.setLoading(true));
    const paging = getState().calculations.paging;
    const filter = getState().calculations.filter;
    const sort = getState().calculations.sort;

    try {
        const calculations = await WizardCalcMergingRestControllerService.getWizardCalcsByCompanyFilter(paging.page, paging.pageSize, filter, getSortingString(sort));
        const mailConversationsList = await AiMailConversationRestControllerService.getConversationStreamOverview({
            calculationIds: calculations.data.map((calc) => calc.uniqueId)
        });
        const mailConversations = {};
        mailConversationsList.forEach((mailConversation) => {
            mailConversations[mailConversation.calculationId] = mailConversation;
        });
        dispatch(slice.actions.setCalculations(calculations.data));
        dispatch(slice.actions.setMailConversations(mailConversations));
        dispatch(slice.actions.updatePaging({ total: calculations.totalElements }));
        dispatch(slice.actions.setLoading(false));
    } catch (e) {
        dispatch(slice.actions.setLoading(false));
    }
};
export const duplicateCalculation = (row: WizardCalcMergeResource) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(slice.actions.setLoading(true));
        if (row.calcId && row.status === DIRTY) await dispatch(duplicateAndUpdateProCalc(row.calcId));
        else if (row.calcId) await dispatch(duplicateProCalc(row.calcId));
        else await dispatch(duplicateWizardCalc(row.wizardId));
        dispatch(loadCalculations());
        dispatch(slice.actions.setLoading(false));
    } catch (e) {
        dispatch(slice.actions.setLoading(false));
    }
};
export const deleteCalculation = (uniqueId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(slice.actions.setLoading(true));
        await WizardCalcMergingRestControllerService.deleteMigration(uniqueId);
        dispatch(loadCalculations());
        dispatch(slice.actions.setLoading(false));
    } catch (e) {
        dispatch(slice.actions.setLoading(false));
    }
};

export const enableAutoReloadCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setAutoReloadCalculations(true));
};
export const disableAutoReloadCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setAutoReloadCalculations(false));
};

//PAGING, FILTERING AND SORTING
export const updatePaging = (paging: { page?: number; pageSize?: number; total?: number }) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentPaging = getState().calculations.paging;
    dispatch(
        slice.actions.updatePaging({
            page: paging.page ?? currentPaging.page,
            pageSize: paging.pageSize ?? currentPaging.pageSize,
            total: paging.total ?? currentPaging.total
        })
    );
    dispatch(loadCalculations());
};
export const updateSortModel = (sortModel: GridSortModel) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.updateSortModel(sortModel));
    dispatch(loadCalculations());
};
export const updateFilter = (filter: WizardCalcMergeFilterDto) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.updateFilter(filter));
    dispatch(loadCalculations());
};
