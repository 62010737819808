import { Avatar, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Letter } from 'react-letter';
import { AiMailResource, UserResource } from 'src/backend/market';
import { formatDate } from 'src/utils/FormatHelpers';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import UnfoldLessTwoToneIcon from '@mui/icons-material/UnfoldLessTwoTone';
import { useSelector } from 'src/redux/store';
import { useTranslation } from 'react-i18next';

interface Props {
    mail: AiMailResource;
    senderName?: string;
    isExpandedDefault?: boolean;
}

const AiEmailSingleOutput: React.FC<Props> = ({ mail, senderName, isExpandedDefault = false }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isExpanded, setExpanded] = useState<boolean>(isExpandedDefault);
    const user = useSelector<UserResource>((state) => state.user.currentUser);

    const isOutgoing = mail.mailType === 'OutgoingAiCalculationMail';

    if (isOutgoing && !mail.sent) {
        return <></>;
    }

    return (
        <Stack
            sx={{
                mx: -2.7,
                borderBottom: '1px solid',
                borderColor: theme.colors.alpha.black[7],
                '&:first-of-type': {
                    borderTop: '1px solid',
                    borderColor: theme.colors.alpha.black[7]
                }
            }}
        >
            <Stack direction="row" sx={{ py: 1.5, px: 2.7, alignItems: 'center', cursor: 'pointer' }} onClick={() => setExpanded(!isExpanded)}>
                <Avatar alt={isOutgoing ? user.company.name : senderName || mail.from || '?'} src={'/static/images/avatars/3.jpg'} sx={{ bgcolor: isOutgoing && theme.colors.warning.main }} />
                <Stack ml={1.5}>
                    <Typography variant="subtitle2" fontSize={13} lineHeight={1.4} fontWeight="bold" color={isOutgoing && 'warning.dark'}>
                        {isOutgoing ? user.company.name : senderName || mail.from || t('unidentified')}
                    </Typography>
                    <Typography variant="subtitle2" fontSize={13} lineHeight={1.4}>
                        {formatDate(mail.receivedAt)}
                    </Typography>
                </Stack>
                <IconButton
                    sx={{ ml: 'auto', mr: -1.8 }}
                    onClick={(event) => {
                        event.stopPropagation();
                        setExpanded(!isExpanded);
                    }}
                >
                    {isExpanded ? <UnfoldLessTwoToneIcon /> : <UnfoldMoreTwoToneIcon />}
                </IconButton>
            </Stack>
            {isExpanded && (
                <Box px={2.7} pb={1.5} color={theme.palette.text.primary}>
                    <Letter html={mail.bodyHtml} />
                </Box>
            )}
        </Stack>
    );
};

export default AiEmailSingleOutput;
