import { Typography, Button, useTheme, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiIcon from '../MuiIcon/MuiIcon';
import { TemplateResource } from 'src/backend/coreCalc';

export const StyledButton = styled(Button)(
    ({ theme }) => `
        flex-direction: column;
        align-items: flex-start;
        padding: ${theme.spacing(2)};
        text-align: left;
        border-color: ${theme.colors.alpha.black[30]};
        width: 100%;
        max-width: 20rem;
        box-shadow: ${theme.header.boxShadow};
        &.highlight {
            border-color: ${theme.colors.primary.main};
        }
    `
);

interface Props {
    template: TemplateResource;
    onClick?: () => void;
}

const TemplateSelectionItem: React.FC<Props> = ({ template, onClick }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <StyledButton
            key={template.name}
            variant="outlined"
            className={template.isHighlight ? 'highlight' : ''}
            color={template.isHighlight ? 'primary' : 'inherit'}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <Typography fontWeight={600}>{template.name}</Typography>
            <Typography fontSize={13} sx={{ opacity: 0.7, pb: 1, mb: 'auto' }}>
                {template.description}
            </Typography>
            {template.icon && <MuiIcon icon={template.icon} />}
        </StyledButton>
    );
};

export default TemplateSelectionItem;
