import {AnimatePresence, motion} from 'framer-motion';

export const appear = (children) => {
    return <AnimatePresence><motion.div
        animate={{opacity: [0, 1]}}
        transition={{duration: 0.5, ease: "anticipate"}}
        exit={{opacity: 0}}
    >
        {children}
    </motion.div></AnimatePresence>
}

export const slideInLeft = (children) => {
    return <AnimatePresence><motion.div animate={{x: [-100, 0], opacity: [0, 1]}}
                       transition={{duration: 0.5, ease: "anticipate"}}
                       exit={{opacity: 0}}
    >
        {children}
    </motion.div></AnimatePresence>
}