import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import PriceCalcTable from "src/components/display/Tables/PriceCalcTable/PriceCalcTable";
import {InquiryFilterDto} from "src/backend/market";
import {useDispatch} from "src/redux/store";
import {updateFilter as reduxUpdateFilter} from "src/redux/thunks/inquiries.thunk";
import {slideInLeft} from "src/utils/animationstyles";
import PageHeader from "src/components/display/PageHeader";
import {useEffect} from "react";
import {startWalkthrough} from "../../../redux/thunks/guidethrough.thunk";

function PriceCalcs() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const theme = useTheme();

    return <>
        <PageHeader secondaryTitle={t('priceCalcExplanation')} secondaryTitlePosition="below"/>
        {slideInLeft(
            <Box id={"notFinishedInquiriesBox"} mt={{md: 5, sm: 1, xs: 1}} display={"flex"} flexDirection={"column"} flexGrow={1}>
                <PriceCalcTable isFullList requestCount={"all"} sortable height={1000}/>
            </Box>
        )}
    </>
}

export default PriceCalcs;