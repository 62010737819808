import { Typography, Stack, useTheme, TextField, Autocomplete } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RoundedVariableResource, VariableResource } from 'src/backend/coreCalc';
import { getVariable } from 'src/utils/CalcHelpers';
import { formatVariable } from 'src/utils/FormatHelpers';

interface Props {
    variable: RoundedVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
}

const RoundedVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        if (!variable) setVariable({ type: VariableResource.type.ROUND_VARIABLE, name: '', numberOfDigits: 3 } as RoundedVariableResource);
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.name == null || variable.name.trim() === '' || variable.referenceVariableName == null || isNaN(variable.numberOfDigits);
        setValid(!isInvalid);
    }, [variable]);

    const selectedVariable = useMemo(() => {
        if (!variable) return;
        return getVariable(variable.referenceVariableName, variables) || null;
    }, [variable?.referenceVariableName]);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            name: event.target.value
        } as RoundedVariableResource);
    };
    const handleVariableChange = (event, option) => {
        setVariable({
            ...variable,
            referenceVariableName: option.name
        } as RoundedVariableResource);
    };
    const handleDigitsChange = (event) => {
        setVariable({
            ...variable,
            numberOfDigits: parseInt(event.target.value)
        } as RoundedVariableResource);
    };

    const isDisabled = variable?.id === -1;

    return (
        variable && (
            <Stack gap={3}>
                <TextField label="Name" value={variable.name} onChange={handleNameChange} variant="outlined" fullWidth disabled={isDisabled} />
                <Autocomplete
                    disabled={isDisabled}
                    renderInput={(params) => <TextField {...params} label="Zu rundende Variable" />}
                    noOptionsText={t('noOptions')}
                    disableClearable
                    value={selectedVariable}
                    onChange={handleVariableChange}
                    options={variables}
                    getOptionLabel={(option: VariableResource) => option?.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderOption={(props, option: VariableResource) => (
                        <li {...props} key={option.id}>
                            <Stack alignItems="flex-start" overflow="hidden">
                                <Typography fontSize={14} fontWeight={700} lineHeight={1.3} sx={{ textOverflow: 'ellipsis', maxWidth: '100%', overflow: 'hidden' }}>
                                    {option.name}
                                </Typography>
                                <Typography variant="subtitle2" fontSize={12} lineHeight={1.47} color="inherit" sx={{ opacity: 0.9 }}>
                                    {formatVariable(option, variables)}
                                </Typography>
                            </Stack>
                        </li>
                    )}
                />
                <TextField type="number" label="Auf wie viele Kommastellen runden?" value={variable.numberOfDigits} onChange={handleDigitsChange} variant="outlined" fullWidth disabled={isDisabled} />
            </Stack>
        )
    );
};

export default RoundedVariableForm;
