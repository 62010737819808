import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useMemo, useState } from 'react';
import SurchargesItem from './SurchargesItem';
import { SurchargeVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        .accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    variables: Array<VariableResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    createVariable: (variable: VariableResource) => void;
    saveVariable: (variable: VariableResource) => void;
}

const SurchargesDialog: React.FC<Props> = ({ variables, isOpen, setOpen, saveVariable, createVariable }) => {
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();

    const surchargeVariables = useMemo(() => {
        return (variables || []).filter((variable) => variable.type === VariableResource.type.SURCHARGE_VARIABLE) as Array<SurchargeVariableResource>;
    }, [variables]);

    const addSurcharge = () => {
        const baseName = 'Neuer Aufschlag';
        let newName = baseName;
        let counter = 1;

        // Separate function to check for duplicates
        const isDuplicateName = (name) => {
            return surchargeVariables.some((variable) => variable.name === name);
        };
        // Check for duplicates and adjust the name accordingly
        while (isDuplicateName(newName)) {
            newName = `${baseName} (${counter})`;
            counter++;
        }

        createVariable({
            id: -1,
            type: VariableResource.type.SURCHARGE_VARIABLE,
            name: newName,
            ruleBasedValue: [{ isDefault: true, value: { type: ValueResource.type.STRING_VALUE, stringValue: '0' } }],
            criteria: []
        } as SurchargeVariableResource);
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="md" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Aufschläge
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <StyledStack>
                    <Divider />
                    {surchargeVariables.map((variable, index) => (
                        <SurchargesItem
                            key={variable.id + variable.name + index}
                            variable={variable}
                            variables={variables}
                            openAccordionId={openAccordionId}
                            setOpenAccordionId={setOpenAccordionId}
                            saveVariable={saveVariable}
                        />
                    ))}
                    <Divider sx={{ mt: 'auto' }} />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={addSurcharge} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        Eigenen Aufschlag erstellen
                    </Button>
                </StyledStack>
            </DialogContent>
        </Dialog>
    );
};

export default SurchargesDialog;
