import { Box, Button, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import store, { RootState } from 'src/redux/store';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import CalculationBasisDialog from './CalculationBasis/CalculationBasisDialog';
import { VariablesInTerm } from '../CalcEditor.types';
import { CalculationBasisTemplateResource, CategoryResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    category?: CategoryResource;
    calcRelevantVariableNames?: Array<string>;
    calculationBasisTemplates?: Array<CalculationBasisTemplateResource>;
    saveVariable: (variable: VariableResource) => void;
    createVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const CategoryCalculationBasis: React.FC<Props> = ({ category, calcRelevantVariableNames, calculationBasisTemplates, saveVariable, createVariable, removeVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const s = store.getState();
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState<boolean>(false);

    const calculationBasisName = useMemo(() => {
        const calculationBasis = (category?.calculationBases || []).find((calculationBasis) => calculationBasis.id === category.calculationBasisId);
        return calculationBasis?.name;
    }, [category?.calculationBases, category?.calculationBasisId]);

    return (
        <Stack mx="auto" direction="row" gap={0.8} alignItems="center">
            <Typography variant="subtitle2">Kalkulation:</Typography>
            <Button variant="contained" sx={{ fontSize: 16, lineHeight: 1, minWidth: '8rem' }} color="primary" endIcon={<ArrowDropDownTwoToneIcon />} onClick={() => setOpen(true)}>
                {calculationBasisName}
            </Button>
            <CalculationBasisDialog
                category={category}
                calcRelevantVariableNames={calcRelevantVariableNames}
                calculationBasisTemplates={calculationBasisTemplates}
                createVariable={createVariable}
                saveVariable={saveVariable}
                removeVariable={removeVariable}
                isOpen={isOpen}
                setOpen={(isOpen) => setOpen(isOpen)}
            />
        </Stack>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        calculationBasisTemplates: state.templates.collection?.calculationBasisTemplates
    };
};
export default connect(mapStateToProps)(CategoryCalculationBasis);
