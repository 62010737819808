/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PredefinedSurchargeSettingParameterResource = {
    id?: string;
    dataType?: PredefinedSurchargeSettingParameterResource.dataType;
    description?: string;
    descriptionDictId?: string;
    absolute?: boolean;
    surchargeParameter?: boolean;
    defaultValue?: string;
    unit?: string;
    jsonType: string;
}

export namespace PredefinedSurchargeSettingParameterResource {

    export enum dataType {
        INTEGER = 'INTEGER',
        DOUBLE = 'DOUBLE',
        ENUMERATION = 'ENUMERATION',
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        CONSTANT_INTEGER = 'CONSTANT_INTEGER',
        CONSTANT_DOUBLE = 'CONSTANT_DOUBLE',
    }


}
