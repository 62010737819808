/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdditionalUserDataDto } from '../models/AdditionalUserDataDto';
import type { CaptchaResultResource } from '../models/CaptchaResultResource';
import type { CoaterUserResource } from '../models/CoaterUserResource';
import type { CompanyNameDto } from '../models/CompanyNameDto';
import type { CompanyNameTakenResource } from '../models/CompanyNameTakenResource';
import type { CompanyResource } from '../models/CompanyResource';
import type { CompletedWalkthroughResource } from '../models/CompletedWalkthroughResource';
import type { CompletedWalkthroughsDto } from '../models/CompletedWalkthroughsDto';
import type { FirstPasswordDto } from '../models/FirstPasswordDto';
import type { FrontendCaptchaDataDto } from '../models/FrontendCaptchaDataDto';
import type { InvitedUserResource } from '../models/InvitedUserResource';
import type { LockResource } from '../models/LockResource';
import type { MailAlreadyUsedDto } from '../models/MailAlreadyUsedDto';
import type { MailAlreadyUsedResource } from '../models/MailAlreadyUsedResource';
import type { NewUserRoleDto } from '../models/NewUserRoleDto';
import type { NumberFormatLocaleResource } from '../models/NumberFormatLocaleResource';
import type { PasswordRecoveryIdValidityResource } from '../models/PasswordRecoveryIdValidityResource';
import type { PasswordRecoveryRequestDto } from '../models/PasswordRecoveryRequestDto';
import type { PasswordResetDto } from '../models/PasswordResetDto';
import type { UnverifiedUserResource } from '../models/UnverifiedUserResource';
import type { UserInviteDto } from '../models/UserInviteDto';
import type { UserRegistrationDataDto } from '../models/UserRegistrationDataDto';
import type { UserResource } from '../models/UserResource';
import type { UserRoleResource } from '../models/UserRoleResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserManagementRestControllerService {

    /**
     * @param userId
     * @returns UserRoleResource OK
     * @throws ApiError
     */
    public static getAllUserRoles(
        userId: number,
    ): CancelablePromise<Array<UserRoleResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/${userId}/userRoles`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns UserRoleResource OK
     * @throws ApiError
     */
    public static setNewUserRoleToUser(
        userId: number,
        requestBody: NewUserRoleDto,
    ): CancelablePromise<UserRoleResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/users/${userId}/userRoles`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param invitedUserId
     * @returns any OK
     * @throws ApiError
     */
    public static inviteUserAgain(
        userId: number,
        invitedUserId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/users/${userId}/invitedUsers/${invitedUserId}`,
        });
    }

    /**
     * @param requestBody
     * @returns CompanyNameTakenResource OK
     * @throws ApiError
     */
    public static getIsCompanyTaken(
        requestBody: CompanyNameDto,
    ): CancelablePromise<CompanyNameTakenResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/users/companies/companyNames/taken`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns UserResource OK
     * @throws ApiError
     */
    public static updateUser(
        userId: number,
        requestBody: AdditionalUserDataDto,
    ): CancelablePromise<UserResource> {
        return __request({
            method: 'PUT',
            path: `/marketplace/users/additionalUserData/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns LockResource OK
     * @throws ApiError
     */
    public static checkLock(
        userId: number,
    ): CancelablePromise<LockResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/${userId}/locked`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static acquireLock(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/${userId}/locked`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns InvitedUserResource OK
     * @throws ApiError
     */
    public static inviteUser(
        userId: number,
        requestBody: UserInviteDto,
    ): CancelablePromise<InvitedUserResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/${userId}/invitedUsers`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns CompletedWalkthroughResource OK
     * @throws ApiError
     */
    public static getCompletedWalkthroughs(
        userId: number,
    ): CancelablePromise<Array<CompletedWalkthroughResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/${userId}/completedWalkthroughs`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns CompletedWalkthroughResource OK
     * @throws ApiError
     */
    public static addCompletedWalkthroughs(
        userId: number,
        requestBody: CompletedWalkthroughsDto,
    ): CancelablePromise<Array<CompletedWalkthroughResource>> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/${userId}/completedWalkthroughs`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns UnverifiedUserResource OK
     * @throws ApiError
     */
    public static createNewUser(
        requestBody: UserRegistrationDataDto,
    ): CancelablePromise<UnverifiedUserResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/registeredUsers`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static resetPassword(
        requestBody: PasswordResetDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param recoveryId
     * @returns PasswordRecoveryIdValidityResource OK
     * @throws ApiError
     */
    public static checkIfRecoveryIdIsValid(
        recoveryId: string,
    ): CancelablePromise<PasswordRecoveryIdValidityResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/passwordRecovery`,
            query: {
                'recoveryId': recoveryId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createNewPasswordRecoveryProcess(
        requestBody: PasswordRecoveryRequestDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/passwordRecovery`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns MailAlreadyUsedResource OK
     * @throws ApiError
     */
    public static checkIfMailAlreadyUsed(
        requestBody: MailAlreadyUsedDto,
    ): CancelablePromise<MailAlreadyUsedResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/mailAlreadyUsed`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setFirstPassword(
        requestBody: FirstPasswordDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/firstPassword`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CaptchaResultResource OK
     * @throws ApiError
     */
    public static validateCaptcha(
        requestBody: FrontendCaptchaDataDto,
    ): CancelablePromise<CaptchaResultResource> {
        return __request({
            method: 'POST',
            path: `/marketplace/users/captcha`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserResource OK
     * @throws ApiError
     */
    public static getAllUsers(): CancelablePromise<Array<UserResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users`,
        });
    }

    /**
     * @param userId
     * @returns UserResource OK
     * @throws ApiError
     */
    public static getUserById(
        userId: number,
    ): CancelablePromise<UserResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/${userId}`,
        });
    }

    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteUser(
        userId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/marketplace/users/${userId}`,
        });
    }

    /**
     * @param userId
     * @returns UserResource OK
     * @throws ApiError
     */
    public static getUnderlings(
        userId: number,
    ): CancelablePromise<Array<UserResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/${userId}/underlings`,
        });
    }

    /**
     * @param v
     * @returns any OK
     * @throws ApiError
     */
    public static verifyUser(
        v: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/verifications`,
            query: {
                'v': v,
            },
        });
    }

    /**
     * @returns NumberFormatLocaleResource OK
     * @throws ApiError
     */
    public static getAvailableNumberFormats(): CancelablePromise<Array<NumberFormatLocaleResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/numberFormats`,
        });
    }

    /**
     * @param k
     * @returns any OK
     * @throws ApiError
     */
    public static acceptInvite(
        k: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/invites`,
            query: {
                'k': k,
            },
        });
    }

    /**
     * @returns CompanyResource OK
     * @throws ApiError
     */
    public static getAllCompanies(): CancelablePromise<Array<CompanyResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/companies`,
        });
    }

    /**
     * @returns CompanyResource OK
     * @throws ApiError
     */
    public static getAllCompaniesWithoutWizard(): CancelablePromise<Array<CompanyResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/companiesWithoutWizard`,
        });
    }

    /**
     * @param companyId
     * @returns UserResource OK
     * @throws ApiError
     */
    public static getAdminsOfCompany(
        companyId: number,
    ): CancelablePromise<Array<UserResource>> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/companies/${companyId}/admins`,
        });
    }

    /**
     * @param userId
     * @returns CoaterUserResource OK
     * @throws ApiError
     */
    public static getCoaterUserById(
        userId: number,
    ): CancelablePromise<CoaterUserResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/users/coaterUsers/${userId}`,
        });
    }

}