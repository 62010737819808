import { Typography, Stack, Button, Dialog, DialogTitle, IconButton, DialogContent, useTheme, TextField, Collapse } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useState } from 'react';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';
import { useDispatch } from 'react-redux';
import { updateBlueprint, BlueprintUpdateTypes } from 'src/redux/shared/calcEditor.common';
import DeclarationVariableForm from './Forms/DeclarationVariableForm';
import TermVariableForm from './Forms/TermVariableForm';
import RulesBasedVariableForm from './Forms/RulesBasedVariableForm';
import RoundedVariableForm from './Forms/RoundedVariableForm';
import InputVariableForm from './Forms/InputVariableForm';
import ProvidedVariableForm from './Forms/ProvidedVariableForm';
import SurchargeVariableForm from './Forms/SurchargeVariableForm';
import {
    DeclaredVariableResource,
    InputVariableResource,
    ProvidedVariableResource,
    RoundedVariableResource,
    RuleBasedVariableResource,
    SurchargeVariableResource,
    TermVariableResource,
    VariableResource
} from 'src/backend/coreCalc';

interface Props {
    type: VariableResource.type;
    variable: VariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
    isSmall?: boolean;
}

const VariableForm: React.FC<Props> = ({ type, variable, variables, setVariable, setValid, isSmall }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    return (
        <>
            {type === VariableResource.type.DECLARATION_VARIABLE && <DeclarationVariableForm variable={variable as DeclaredVariableResource} setVariable={setVariable} setValid={setValid} />}
            {type === VariableResource.type.PROVIDED_VARIABLE && <ProvidedVariableForm variable={variable as ProvidedVariableResource} setVariable={setVariable} setValid={setValid} />}
            {type === VariableResource.type.TERM_VARIABLE && <TermVariableForm variable={variable as TermVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} />}
            {type === VariableResource.type.RULE_BASED_VARIABLE && (
                <RulesBasedVariableForm variable={variable as RuleBasedVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} />
            )}
            {type === VariableResource.type.ROUND_VARIABLE && (
                <RoundedVariableForm variable={variable as RoundedVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} />
            )}
            {type === VariableResource.type.INPUT_VARIABLE && <InputVariableForm variable={variable as InputVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} />}
            {type === VariableResource.type.SURCHARGE_VARIABLE && (
                <SurchargeVariableForm variable={variable as SurchargeVariableResource} variables={variables} setVariable={setVariable} setValid={setValid} isSmall={isSmall} />
            )}
        </>
    );
};

export default VariableForm;
