import Joyride, {EVENTS} from "react-joyride";
import React, { useMemo } from "react";
import {useDispatch, useSelector} from "../redux/store";
import {finishWalkthrough, nextStep, stepBack, stopWalkthrough} from "../redux/thunks/guidethrough.thunk";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material";
import ErrorBoundary from "src/utils/ErrorBoundary";

const GuidethroughProvider: React.FC = () => {
    const {screens, run, step, currentScreen} = useSelector(state => state.guidethrough);
    const dispatch = useDispatch();
    const theme = useTheme();
    const {t}: { t: any } = useTranslation();

    const steps = useMemo(() => {
        const screen = screens.find(s => s.name === currentScreen);
        if(!screen) return [];

        return screen.steps
            .filter(step => {
                const doesDomElementExist = document.querySelector(step.target);
                return doesDomElementExist;
            })
            .map(step => {
                return {
                    content: t(step.content),
                    target: step.target,
                    disableBeacon: true,
                    onNextClick: () => {
                        document.querySelector(".MuiDataGrid-virtualScroller")?.scrollTo(10000, 0);
                    },
                }
            });
    }, [currentScreen]);

    return <ErrorBoundary>
        <Joyride
            steps={steps}
            hideCloseButton
            locale={{
                back: t('back'),
                close: t('close'),
                last: t('last'),
                next: t('next'),
                open: t('openTheDialog'),
                skip: t('skip'),
            }}
            stepIndex={step}
            run={run}
            showSkipButton
            continuous
            floaterProps={{autoOpen: true}}
            spotlightClicks={false}
            scrollOffset={500}
            scrollToFirstStep={true}
            styles={{
                options: {
                    primaryColor: theme.colors.primary.main,
                    zIndex: 2000,
                }
            }}
            callback={(data) => {
                if (data.status === 'finished') {
                    dispatch(finishWalkthrough());
                } else if (data.action === 'close' || data.action === 'skip' || data.action === 'done') {
                    dispatch(stopWalkthrough());
                }


                const {action, index, status, type} = data;

                //Todo: This is a fix for a table which doesn't scroll horizontally.
                // @ts-ignore
                if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
                    // Update state to advance the tour
                    document.querySelector(".MuiDataGrid-virtualScroller")?.scrollTo(10000, 0);

                    setTimeout(() => {
                        if (action === "prev") dispatch(stepBack());
                        else if (action === "next") dispatch(nextStep());
                    }, 10);

                }
            }}
        />
    </ErrorBoundary>;
}

export default GuidethroughProvider;