import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { AdditionalStorageResource, BooleanCalcParamResource } from 'src/backend/internalCalc';
import store from 'src/redux/store';
import { wT } from 'src/utils/wizardTranslations';

interface IProps {
    param: BooleanCalcParamResource;
    disabled?: boolean;
    isSmall?: boolean;
    additionalStorage?: Array<AdditionalStorageResource>;
}

const BooleanParameter: React.FC<IProps> = ({ param, disabled, additionalStorage }) => {
    const { handleChange, values, setFieldValue } = useFormikContext();
    const s = store.getState();
    //region STATE
    //endregion

    //region LIFECYCLE
    //endregion

    //region RENDER
    //endregion
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={values[param.name] === 'true'}
                        onChange={(e) => {
                            setFieldValue(param.name, e.target.checked + '');
                        }}
                    />
                }
                label={wT(param.name, s)}
                disabled={disabled}
            />
        </FormGroup>
    );
};
export default BooleanParameter;
