/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlueprintDTO } from '../models/BlueprintDTO';
import type { BlueprintResource } from '../models/BlueprintResource';
import type { CalculationBasisDTO } from '../models/CalculationBasisDTO';
import type { CalculationBasisResource } from '../models/CalculationBasisResource';
import type { CategoryDTO } from '../models/CategoryDTO';
import type { CategoryResource } from '../models/CategoryResource';
import type { DeclaredVariableDTO } from '../models/DeclaredVariableDTO';
import type { DeclaredVariableResource } from '../models/DeclaredVariableResource';
import type { InputVariableDTO } from '../models/InputVariableDTO';
import type { InputVariableResource } from '../models/InputVariableResource';
import type { NewCategoryDTO } from '../models/NewCategoryDTO';
import type { ProvidedVariableDTO } from '../models/ProvidedVariableDTO';
import type { ProvidedVariableResource } from '../models/ProvidedVariableResource';
import type { RoundedVariableDTO } from '../models/RoundedVariableDTO';
import type { RoundedVariableResource } from '../models/RoundedVariableResource';
import type { RuleBasedVariableDTO } from '../models/RuleBasedVariableDTO';
import type { RuleBasedVariableResource } from '../models/RuleBasedVariableResource';
import type { SurchargeVariableDTO } from '../models/SurchargeVariableDTO';
import type { SurchargeVariableResource } from '../models/SurchargeVariableResource';
import type { TermVariableDTO } from '../models/TermVariableDTO';
import type { TermVariableResource } from '../models/TermVariableResource';
import type { VariableDTO } from '../models/VariableDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BlueprintRestControllerService {

    /**
     * @param blueprintId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateMasterVariable(
        blueprintId: string,
        variableId: number,
        requestBody: (VariableDTO | DeclaredVariableDTO | InputVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SurchargeVariableDTO | TermVariableDTO),
    ): CancelablePromise<(DeclaredVariableResource | InputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SurchargeVariableResource | TermVariableResource)> {
        return __request({
            method: 'PUT',
            path: `/blueprints/${blueprintId}/variables/${variableId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param variableId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMasterVariable(
        blueprintId: string,
        variableId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/blueprints/${blueprintId}/variables/${variableId}`,
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param requestBody
     * @returns CategoryResource OK
     * @throws ApiError
     */
    public static updateCategory(
        blueprintId: string,
        categoryId: number,
        requestBody: CategoryDTO,
    ): CancelablePromise<CategoryResource> {
        return __request({
            method: 'PUT',
            path: `/blueprints/${blueprintId}/categories/${categoryId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCategory(
        blueprintId: string,
        categoryId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/blueprints/${blueprintId}/categories/${categoryId}`,
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param variableId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateCategoryVariable(
        blueprintId: string,
        categoryId: number,
        variableId: number,
        requestBody: (VariableDTO | DeclaredVariableDTO | InputVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SurchargeVariableDTO | TermVariableDTO),
    ): CancelablePromise<(DeclaredVariableResource | InputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SurchargeVariableResource | TermVariableResource)> {
        return __request({
            method: 'PUT',
            path: `/blueprints/${blueprintId}/categories/${categoryId}/variables/${variableId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param variableId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCategoryVariable(
        blueprintId: string,
        categoryId: number,
        variableId: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/blueprints/${blueprintId}/categories/${categoryId}/variables/${variableId}`,
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param calculationBasisId
     * @param requestBody
     * @returns CalculationBasisResource OK
     * @throws ApiError
     */
    public static updateCategoryCalculationBasis(
        blueprintId: string,
        categoryId: number,
        calculationBasisId: number,
        requestBody: CalculationBasisDTO,
    ): CancelablePromise<CalculationBasisResource> {
        return __request({
            method: 'PUT',
            path: `/blueprints/${blueprintId}/categories/${categoryId}/calculationBases/${calculationBasisId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param calculationBasisId
     * @param requestBody
     * @returns CalculationBasisResource OK
     * @throws ApiError
     */
    public static updateMasterCalculationBasis(
        blueprintId: string,
        calculationBasisId: number,
        requestBody: CalculationBasisDTO,
    ): CancelablePromise<CalculationBasisResource> {
        return __request({
            method: 'PUT',
            path: `/blueprints/${blueprintId}/calculationBases/${calculationBasisId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns BlueprintResource OK
     * @throws ApiError
     */
    public static createBlueprint(
        requestBody: BlueprintDTO,
    ): CancelablePromise<BlueprintResource> {
        return __request({
            method: 'POST',
            path: `/blueprints`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createMasterVariable(
        blueprintId: string,
        requestBody: (VariableDTO | DeclaredVariableDTO | InputVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SurchargeVariableDTO | TermVariableDTO),
    ): CancelablePromise<(DeclaredVariableResource | InputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SurchargeVariableResource | TermVariableResource)> {
        return __request({
            method: 'POST',
            path: `/blueprints/${blueprintId}/variables`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param requestBody
     * @returns CategoryResource OK
     * @throws ApiError
     */
    public static createCategory(
        blueprintId: string,
        requestBody: NewCategoryDTO,
    ): CancelablePromise<CategoryResource> {
        return __request({
            method: 'POST',
            path: `/blueprints/${blueprintId}/categories`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createCategoryVariable(
        blueprintId: string,
        categoryId: number,
        requestBody: (VariableDTO | DeclaredVariableDTO | InputVariableDTO | ProvidedVariableDTO | RoundedVariableDTO | RuleBasedVariableDTO | SurchargeVariableDTO | TermVariableDTO),
    ): CancelablePromise<(DeclaredVariableResource | InputVariableResource | ProvidedVariableResource | RoundedVariableResource | RuleBasedVariableResource | SurchargeVariableResource | TermVariableResource)> {
        return __request({
            method: 'POST',
            path: `/blueprints/${blueprintId}/categories/${categoryId}/variables`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @returns CategoryResource OK
     * @throws ApiError
     */
    public static duplicateCategory(
        blueprintId: string,
        categoryId: number,
    ): CancelablePromise<CategoryResource> {
        return __request({
            method: 'POST',
            path: `/blueprints/${blueprintId}/categories/${categoryId}/duplicates`,
        });
    }

    /**
     * @param blueprintId
     * @param categoryId
     * @param requestBody
     * @returns CalculationBasisResource OK
     * @throws ApiError
     */
    public static createCategoryCalculationBasis(
        blueprintId: string,
        categoryId: number,
        requestBody: CalculationBasisDTO,
    ): CancelablePromise<CalculationBasisResource> {
        return __request({
            method: 'POST',
            path: `/blueprints/${blueprintId}/categories/${categoryId}/calculationBases`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param blueprintId
     * @returns BlueprintResource OK
     * @throws ApiError
     */
    public static getBlueprint(
        blueprintId: string,
    ): CancelablePromise<BlueprintResource> {
        return __request({
            method: 'GET',
            path: `/blueprints/${blueprintId}`,
        });
    }

}