import { AdminPanelSettingsTwoTone } from '@mui/icons-material';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';

import { Avatar, Box, Button, Divider, List, ListItem, ListItemText, Popover, Stack, styled, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { openChat } from 'src/router/utils/BitrixHelpers';
import { nav } from 'src/statics/navigations';
import { RootState, useSelector } from '../../../redux/store';
import { logoutUser } from '../../../redux/thunks/user.thunk';
import { startWalkthrough, stopWalkthrough } from '../../../redux/thunks/guidethrough.thunk';

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding: ${theme.spacing(0, 0)};
        height: ${theme.spacing(7)};
        margin: ${theme.spacing(0, -0.5)};
        border-radius: 20rem;
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox({ loading, profile }) {
    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.currentUser);
    const dispatch = useDispatch();

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) {
            dispatch(stopWalkthrough());
        }
    }, [isOpen]);

    const defaultImage = `${process.env.PUBLIC_URL}/static/images/avatars/blank-profile-picture.svg`;

    const handleOpen = (): void => {
        dispatch(startWalkthrough('userbox'));
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            dispatch(logoutUser());
            navigate(nav.BASE.sub.INIT.path);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <UserBoxButton ref={ref} color="primary" onClick={handleOpen} id={'userBoxDashboard'}>
                <Avatar variant="circular" sx={{ width: 48, height: 48, fontSize: 17 }} alt={user.fullName} src="/broken-image.jpg" />
            </UserBoxButton>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Stack px={2} pt={1.5} pb={1} minWidth="15rem">
                    <Typography variant="h4">{user.fullName}</Typography>
                    <Typography variant="subtitle2" mb={0}>
                        {user.company.name}
                    </Typography>
                </Stack>
                <Divider sx={{ mb: 1 }} />
                <List sx={{ px: 1, py: 0.2 }} component="nav">
                    <ListItem id={'userBoxPopover'} onClick={handleClose} button to={nav.EDIT_PROFILE.path} component={NavLink} sx={{ pl: 0 }}>
                        <AccountBoxTwoToneIcon fontSize="small" />
                        <ListItemText primary={t('Edit profile')} />
                    </ListItem>
                </List>
                <List sx={{ px: 1, py: 0.2 }}>
                    <ListItem
                        onClick={() => {
                            openChat(user);
                            handleClose();
                        }}
                        button
                        sx={{ pl: 0 }}
                    >
                        <SupportAgentTwoToneIcon fontSize="small" />
                        <ListItemText primary={t('contactUs')} />
                    </ListItem>
                </List>
                <Divider sx={{ mt: 1 }} />
                <Box>
                    <Button id={'logoutUserButton'} style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} color="primary" fullWidth onClick={handleLogout}>
                        <LockOpenTwoToneIcon sx={{ ml: 1 }} fontSize="small" />
                        {t('SIGN_OUT')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        profile: state.wizardProfile.currentProfile,
        loading: state.wizardStati.loading
    };
};
export default connect(mapStateToProps)(HeaderUserbox);
