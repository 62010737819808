//INTERFACE AND INIT STATE
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ApiKeyResource,
    GeneralMessageResource,
    MarketplaceWizardProfileResource,
    UserResource,
    WizardThemeIdDto
} from "../../backend/market";

interface RegularWizardProfileState {
    currentProfile: MarketplaceWizardProfileResource | null;
    generalMessages: GeneralMessageResource[];
    generalMessagesLoading: boolean;
    profileLoading: boolean;
}

const initialState: RegularWizardProfileState = {
    currentProfile: null,
    generalMessages: [],
    generalMessagesLoading: true,
    profileLoading: true
};

//REDUCERS
const reducers = {
    setCurrentProfile: (state: RegularWizardProfileState, action: PayloadAction<UserResource>) => {
        state.currentProfile = action.payload;
        state.profileLoading = false;
    },
    setGeneralMessagesLoading: (state: RegularWizardProfileState, action: PayloadAction<boolean>) => {
        state.generalMessagesLoading = action.payload;
    },
    deleteGeneralMessage: (state: RegularWizardProfileState, action: PayloadAction<GeneralMessageResource>) => {
        state.generalMessages = state.generalMessages.filter(message => message.id !== action.payload.id);
    },
    setGeneralMessages: (state: RegularWizardProfileState, action: PayloadAction<GeneralMessageResource[]>) => {
        state.generalMessages = action.payload;
        state.generalMessagesLoading = false;
    },
    setNewTheme: (state: RegularWizardProfileState, action: PayloadAction<WizardThemeIdDto>) => {
        state.currentProfile.themeId = action.payload.themeId;
    },
    setNewApiKey: (state: RegularWizardProfileState, action: PayloadAction<ApiKeyResource>) => {
        state.currentProfile.apiKey = action.payload.apiKey;
    }
}

//EXPORT
export const slice = createSlice({
    name: 'wizardProfile',
    initialState,
    reducers: reducers
});
export const reducer = slice.reducer;
