/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonNode } from '../models/JsonNode';
import type { TechnicalDrawingDto } from '../models/TechnicalDrawingDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TechnicalDrawingRestControllerService {

    /**
     * @param requestBody
     * @returns JsonNode OK
     * @throws ApiError
     */
    public static extractData(
        requestBody: TechnicalDrawingDto,
    ): CancelablePromise<JsonNode> {
        return __request({
            method: 'POST',
            path: `/marketplace/technical-drawing/extract-data`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}