/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DistanceDurationDto } from '../models/DistanceDurationDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DistanceDurationCacheRestControllerService {

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addDistanceDurationToCache(
        requestBody: DistanceDurationDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/marketplace/distanceDuration`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}