/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BooleanValueResource } from './BooleanValueResource';
import type { ListValueResource } from './ListValueResource';
import type { StringValueResource } from './StringValueResource';
import type { VariableValueResource } from './VariableValueResource';

export type CriterionResource = {
    left?: (BooleanValueResource | ListValueResource | StringValueResource | VariableValueResource);
    operator?: CriterionResource.operator;
    right?: (BooleanValueResource | ListValueResource | StringValueResource | VariableValueResource);
    connector?: CriterionResource.connector;
}

export namespace CriterionResource {

    export enum operator {
        EQUALS = 'EQUALS',
        NOT_EQUALS = 'NOT_EQUALS',
        LESS_THAN = 'LESS_THAN',
        GREATER_THAN = 'GREATER_THAN',
        LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
        GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
    }

    export enum connector {
        AND = 'AND',
        OR = 'OR',
    }


}
