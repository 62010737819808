import { Box, Card, Stack, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CalculationPartResource } from 'src/backend/internalCalc';
import { CalculationResultResource } from 'src/backend/market';
import { selectProCalcSettings } from 'src/redux/shared/proCalc.common';
import store from 'src/redux/store';
import { formatPrice } from 'src/utils/FormatHelpers';
import { wT } from 'src/utils/wizardTranslations';

const StyledTextList = styled(Stack)(
    ({ theme }) => `
        & .MuiTypography-subtitle2 {
            font-size: 13px;
            line-height: 15px;
        }
    `
);

interface Props {
    part?: CalculationPartResource;
    costResult?: CalculationResultResource;
    color: string;
    quantity: number;
    showUnitPrice: boolean;
}

const SummarizedItem: React.FC<Props> = ({ part, costResult, color, quantity, showUnitPrice }) => {
    const { t } = useTranslation();
    const s = store.getState();
    const settings = useSelector(selectProCalcSettings);

    const subCostResult = useMemo(() => (costResult?.subCalculationResults || []).find((x) => x.additionalInformation === part.subCalculationIdentificationKey), [part, costResult]);
    const price = formatPrice(showUnitPrice ? subCostResult?.priceNonRounded / quantity : subCostResult?.priceNonRounded, false, settings.numberFormat, settings.currency);

    return (
        <Card sx={{ p: 2, overflow: 'visible', boxShadow: 'rgb(159 162 191 / 18%) 0px 0px 16px, rgb(159 162 191 / 32%) 0px 0 2px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" overflow="hidden">
                <Stack overflow="hidden" maxWidth="100%">
                    <Typography variant="h4" noWrap>
                        {quantity} × {part.userDefinedPartName || wT(part.itemName, s)}
                    </Typography>
                    <Box display="flex">
                        <Typography color="primary" variant="h3" noWrap title={price}>
                            {price}
                        </Typography>
                        {showUnitPrice && (
                            <Typography fontSize={12} ml={0.4} pt={0.8} alignSelf="center" variant="subtitle2">
                                / {t('pcs')}
                            </Typography>
                        )}
                    </Box>
                </Stack>
                <img src={part.thumbnailUrl || part.itemImageUrl} style={{ maxHeight: '58px' }} alt="Item image" />
            </Stack>

            <StyledTextList pt={1} overflow="hidden">
                <Typography variant="subtitle2" noWrap>
                    {t('geometry')}: {part.geometryDataStringForUser}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                    {t('color')}: {color}
                </Typography>
            </StyledTextList>
        </Card>
    );
};

export default SummarizedItem;
