import { Button, Link, Stack, Typography } from '@mui/material';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { matchPath, useNavigate } from 'react-router-dom';
import { ApiError, ModuleRestControllerService, WizardStatisticRestControllerService } from 'src/backend/market';
import useDialog from 'src/hooks/useDialog';
import i18n from 'src/i18n/i18n';
import { logoutUser, migrateUserCalculations, setFullRegistrationException, setFullRegistrationLoading, startUserLockChecker } from 'src/redux/thunks/user.thunk';
import { nav } from 'src/statics/navigations';
import { canUserAccessRoute } from 'src/utils/RouteHelper';
import AppInit from '../../components/utils/AppInit';
import { RootState, useDispatch } from '../../redux/store';
import { fetchConstants, setModuleManagement } from '../../redux/thunks/constants.thunk';
import { loadDelta, loadStatistic } from '../../redux/thunks/statistic.thunk';
import { loadProfile } from '../../redux/thunks/wizardProfile.thunk';
import { fetchAllWizardStati } from '../../redux/thunks/wizardStati.thunk';
import { initBitrix } from './BitrixHelpers';

const ProtectedRoute = ({ children, user, module }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stateInitialized, setStateInitialized] = useState(false);
    const [isUnavailable, setUnavailable] = useState(false);
    const [error, setError] = useState<ApiError | null>(null);
    const [initCalled, setInitCalled] = useState(false);
    const { t } = useTranslation();
    const dialog = useDialog();

    useEffect(() => {
        if (user.isLocked && stateInitialized) {
            dialog.openDialog({
                severity: 'error',
                okText: t('goToLogin'),
                showCancel: false,
                disableClickAway: true,
                component: <Typography>{t('userLockedExplanation')}</Typography>,
                title: t('userLockedTitle'),
                okCallback: () => {
                    dialog.closeDialog();
                    dispatch(logoutUser());
                    navigate(nav.BASE.sub.INIT.path);
                }
            });
        }
    }, [user.isLocked, stateInitialized]);

    //IF USER HAS NO TOKEN SAVED PUSH TO LOGIN
    if (!user.currentAuthToken || !user.currentUser) {
        return <Navigate to={nav.ACCOUNT.sub.LOGIN.path} state={{ from: location }} replace />;
    }

    const logoutAndToLogin = () => {
        dispatch(logoutUser());
        navigate(nav.BASE.sub.INIT.path);
    };

    let decoded = jwtDecode(user.currentAuthToken);
    //IF TOKEN IS EXPIRED PUSH TO LOGIN
    // @ts-ignore
    if (decoded.exp < Date.now() / 1000) {
        return logoutAndToLogin();
    }

    //PUSH TO FULL REGISTRATION
    if (user.currentUser && user.currentUser.lastName === null) {
        initBitrix(user.currentUser);
        if (location.pathname !== nav.FULL_REGISTRATION?.path) {
            return <Navigate to={nav.FULL_REGISTRATION.path} replace />;
        } else {
            return children;
        }
    }

    const isAllowed = canUserAccessRoute();
    if (!isAllowed) {
        return <Navigate to={nav.DASHBOARD.path} replace />;
    }

    //INIT STATE IF UNINITIALIZED
    if (!initCalled) {
        setInitCalled(true);
        dispatch(setFullRegistrationLoading(false));
        dispatch(setFullRegistrationException(null));
        console.log('FETCH EVERYTHING');
        ModuleRestControllerService.getBoughtModules(user.currentUser.id)
            .then((value) => {
                dispatch(setModuleManagement(value));
                if (value.wizardModule) {
                    //INIT ALL STATE
                    dispatch(
                        fetchConstants(
                            () => {
                                dispatch(fetchAllWizardStati());
                                dispatch(loadStatistic());
                                WizardStatisticRestControllerService.updateWizardDelta(user.currentUser.id).then((_) => dispatch(loadDelta()));
                                dispatch(
                                    loadProfile(() => {
                                        setStateInitialized(true);
                                    })
                                );
                            },
                            (error) => setError(error)
                        )
                    );
                } else {
                    navigate(nav.NO_MODULE.path);
                    setStateInitialized(true);
                }
            })
            .catch((error) => {
                console.log(error);
                navigate(nav.NO_MODULE.path);
                setStateInitialized(true);
                dispatch(
                    setModuleManagement({
                        wizardModule: false
                    })
                );
            });

        if (user.currentUser) {
            i18n.changeLanguage(user.currentUser.languageCode);
            initBitrix(user.currentUser);
            if (user.currentUser.company?.mergeTableMigrated === false) {
                dispatch(migrateUserCalculations());
            }
        }

        axios.interceptors.request.use(
            (config) => {
                if (config.headers?.Authorization) {
                    const authToken = config.headers.Authorization.split('Bearer')[1];
                    if (authToken) {
                        let decoded = jwtDecode(authToken);
                        //IF TOKEN IS EXPIRED PUSH TO LOGIN
                        // @ts-ignore
                        if (decoded.exp < Date.now() / 1000) {
                            return logoutAndToLogin();
                        }
                    }
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    setUnavailable(true);
                }
                if (error.response?.status === 500 && error.response.data?.message && error.response.data.message.startsWith('[401]')) {
                    return logoutAndToLogin();
                }
                return Promise.reject(error);
            }
        );

        dispatch(startUserLockChecker());
    }
    if (stateInitialized) {
        console.log('HAS BOUGHT MODULE ON ROUTE CHANGE', module);
        if (matchPath('/wizard/*', location.pathname)) {
            if (!module?.wizardModule && !matchPath(location.pathname, nav.NO_MODULE.path)) {
                return <Navigate to={nav.NO_MODULE.path} />;
            }
        }
    }

    return (
        <>
            {stateInitialized ? children : <AppInit />}

            {error && (
                <div>
                    {error.message}
                    <pre>{error.stack}</pre>
                </div>
            )}

            {isUnavailable && (
                <Stack position="fixed" left={0} right={0} top={0} bottom={0} p={4} zIndex={5000} bgcolor="white" justifyContent="center" alignItems="center" textAlign="center">
                    <Typography variant="h1" gutterBottom mb={2}>
                        {t('ourServiceIsCurrentlyRestarting')}
                    </Typography>
                    <Typography gutterBottom>{t('pleaseTryToLoginAgainIn5Minutes')}</Typography>
                    <Button onClick={logoutAndToLogin}>{t('back to login')}</Button>
                </Stack>
            )}
        </>
    );
};
const mapStateToProps = (state: RootState) => {
    return {
        user: state.user,
        module: state.constants.moduleManagement
    };
};
export default connect(mapStateToProps)(ProtectedRoute);
