import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    styled,
    Typography
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { t } from 'i18next';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { useDispatch } from 'react-redux';
import { AttachmentResource } from 'src/backend/market';
import SurfLoader from 'src/components/display/Loader/SurfLoader';
import { IMG_MIME_TYPES, PDF_MIME_TYPE } from 'src/statics/statics';
import { wT } from 'src/utils/wizardTranslations';
import store from 'src/redux/store';
import { CalculationPartResource } from 'src/backend/internalCalc';
import { Attachment } from 'src/components/input/AttachmentsInput/AttachmentsInput';
import { loadAttachmentPaths } from 'src/redux/thunks/proCalc.thunk';

const StyledListItemText = styled(ListItemText)(
    ({ theme }) => `
        padding-right: ${theme.spacing(4.5)};
        &.uploadError .MuiListItemText-secondary {
            color: ${theme.colors.error.main};
        }
    `
);

interface Props {
    parts: Array<CalculationPartResource>;
    masterAttachments: Array<AttachmentResource>;
}

const AttachmentsSummary: React.FC<Props> = ({ parts, masterAttachments = [] }) => {
    const dispatch = useDispatch();
    const s = store.getState();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [openedAttachment, setOpenedAttachment] = useState<Attachment>(null);
    const ref = useRef<any>(null);

    const attachmentsCount = useMemo(() => {
        let count = masterAttachments.length;
        parts.forEach((part) => {
            count += part.attachments.length;
        });
        return count;
    }, [masterAttachments, parts]);

    const openAttachment = async (attachment: Attachment) => {
        setOpenedAttachment(attachment);
        const paths = await dispatch(loadAttachmentPaths(attachment.attachmentId));
        const originalPath = paths.find((path) => path.fileVariant === 'ORIGINAL');
        setTimeout(() => {
            setOpenedAttachment((currentAttachment) => {
                // trick to get updated openedAttachment
                if (!!currentAttachment) setOpenedAttachment({ ...currentAttachment, fileUrl: originalPath?.path, isImageLoaded: false });
                return currentAttachment;
            });
        }, 1000);
    };

    return (
        attachmentsCount > 0 && (
            <>
                <Button sx={{ px: 1.2, py: 0.5, flexShrink: 0 }} startIcon={<AttachFileTwoToneIcon />} variant="text" color="secondary" onClick={() => setOpen(true)} ref={ref}>
                    {t('allAttachments')} ({attachmentsCount})
                </Button>

                <Popover
                    anchorEl={ref.current}
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', p: 0.6, right: 0, top: 0, color: (theme) => theme.palette.grey[500] }}>
                        <CloseTwoToneIcon />
                    </IconButton>
                    <Stack p={2} minWidth="25rem">
                        <Typography variant="h3" textAlign="center">
                            {t('allAttachments')}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom textAlign="center">
                            {t('allAttachmentsExplanation')}
                        </Typography>
                        <Divider sx={{ my: 2, mb: 1.5 }}></Divider>
                        <List disablePadding sx={{ maxWidth: '30rem', maxHeight: '50rem', mx: -2, p: 0 }}>
                            {masterAttachments.map((attachment, index) => (
                                <ListItem key={index} sx={{ p: 0 }}>
                                    <ListItemButton sx={{ pl: 2.5, borderRadius: 0 }} onClick={() => openAttachment(attachment)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <InsertDriveFileTwoToneIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <StyledListItemText primary={attachment.originalFileName} secondary={t('masterAttachment')} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            {parts.map((part, index) =>
                                part.attachments.map((attachment, index) => (
                                    <ListItem key={index} sx={{ p: 0 }}>
                                        <ListItemButton sx={{ pl: 2.5, borderRadius: 0 }} onClick={() => openAttachment(attachment)}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <InsertDriveFileTwoToneIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <StyledListItemText primary={attachment.originalFileName} secondary={wT(part.itemName, s)} />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            )}
                        </List>
                    </Stack>
                </Popover>

                <Dialog
                    onClose={() => setOpenedAttachment(null)}
                    open={!!openedAttachment}
                    maxWidth="xl"
                    PaperProps={openedAttachment?.fileTypeAsMimeType === PDF_MIME_TYPE ? { sx: { width: '100%', height: '100%' } } : {}}
                >
                    {!!openedAttachment && (
                        <>
                            <DialogTitle sx={{ pr: 7 }}>
                                {openedAttachment.originalFileName}
                                <IconButton aria-label="close" onClick={() => setOpenedAttachment(null)} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                                    <CloseTwoToneIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent sx={{ overflow: 'hidden', minHeight: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {openedAttachment.fileUrl ? (
                                    IMG_MIME_TYPES.includes(openedAttachment.fileTypeAsMimeType) ? (
                                        <img
                                            src={openedAttachment?.fileUrl}
                                            width="100%"
                                            onLoad={() => {
                                                setOpenedAttachment({ ...openedAttachment, isImageLoaded: true });
                                            }}
                                        />
                                    ) : openedAttachment.fileTypeAsMimeType === PDF_MIME_TYPE ? (
                                        <object data={openedAttachment?.fileUrl} type={openedAttachment.fileTypeAsMimeType} width="100%" height="100%">
                                            <a href={openedAttachment?.fileUrl}>{openedAttachment?.fileUrl}</a>
                                        </object>
                                    ) : (
                                        <Button href={openedAttachment?.fileUrl} target="_blank" download={true} variant="contained" color="primary">
                                            {t('downloadFile')}
                                        </Button>
                                    )
                                ) : (
                                    <Stack alignItems="center" justifyContent="center" height="100%">
                                        <SurfLoader />
                                    </Stack>
                                )}
                            </DialogContent>
                        </>
                    )}
                </Dialog>
            </>
        )
    );
};

export default AttachmentsSummary;
