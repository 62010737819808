import {createSlice} from "@reduxjs/toolkit";
import {UserResource} from "../../backend/market";

export interface IAdminReducer {
    companyUsers: UserResource[];
    companyUsersLoading: boolean;
}

const initialState: IAdminReducer = {
    companyUsers: [],
    companyUsersLoading: false,
}

const reducers = {
    setCompanyUsers: (state, action) => {
        state.companyUsers = action.payload;
    },
    addUser: (state, action) => {
        state.companyUsers.push(action.payload);
    },
    deleteUser: (state, action) => {
        state.companyUsers = state.companyUsers.filter(user => user.id !== action.payload);
    },
    setCompanyUsersLoading: (state, action) => {
        state.companyUsersLoading = action.payload;
    },
}
export const slice = createSlice({
    name: 'admin',
    initialState,
    reducers
})
export const reducer = slice.reducer;