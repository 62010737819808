import { Typography, Stack, Divider, Button, Dialog, DialogTitle, IconButton, DialogContent, styled, useTheme } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useMemo, useState } from 'react';
import CalcBuilderDialog from '../../CalcBuilder/CalcBuilderDialog';
import CalculationBasisItem from './CalculationBasisItem';
import { useDispatch } from 'react-redux';
import { BlueprintUpdateTypes, updateBlueprint } from 'src/redux/shared/calcEditor.common';
import { CategoryResource, CalculationBasisTemplateResource, VariableResource, TermResource } from 'src/backend/coreCalc';

const StyledStack = styled(Stack)(
    ({ theme }) => `
        height: 100%;
        .MuiButton-root.accordion-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    `
);

interface Props {
    category?: CategoryResource;
    calcRelevantVariableNames?: Array<string>;
    calculationBasisTemplates?: Array<CalculationBasisTemplateResource>;
    isOpen: boolean;
    setOpen?: (isOpen: boolean) => void;
    saveVariable: (variable: VariableResource) => void;
    createVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const CalculationBasisDialog: React.FC<Props> = ({ category, calcRelevantVariableNames, calculationBasisTemplates = [], isOpen, setOpen, saveVariable, createVariable, removeVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [openAccordionId, setOpenAccordionId] = useState<string>();
    const [isCalcBuilderOpen, setCalcBuilderOpen] = useState<boolean>(false);

    const mappedCalculationBasisTemplates = useMemo(() => {
        const templates: { [id: string]: CalculationBasisTemplateResource } = {};
        calculationBasisTemplates.forEach((template) => {
            templates[template.id] = template;
        });
        return templates;
    }, [calculationBasisTemplates]);

    const createCalculationBasis = (name: string, term: TermResource) => {
        setCalcBuilderOpen(false);
        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CREATE_CATEGORY_CALCULATION_BASIS, {
                categoryId: category.id,
                calculationBasis: {
                    name,
                    term
                }
            })
        );
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="md" PaperProps={{ sx: { width: '100%' } }} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, mt: 0.5, mb: 1, pr: 7 }} gutterBottom>
                    Kalkulation von "{category.name}"
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }} variant="subtitle1">
                    Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation erstellen.
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 16, top: 16, color: (theme) => theme.palette.grey[500] }}>
                    <CloseTwoToneIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ px: 0, pb: 0 }}>
                <StyledStack>
                    <Divider />
                    {category.calculationBases.map((calculationBasis) => (
                        <CalculationBasisItem
                            key={calculationBasis.id + calculationBasis.name}
                            categoryId={category.id}
                            isSelected={category.calculationBasisId === calculationBasis.id}
                            variables={category.variables}
                            calcRelevantVariableNames={calcRelevantVariableNames}
                            calculationBasis={calculationBasis}
                            calculationBasisTemplate={mappedCalculationBasisTemplates[calculationBasis.templateId]}
                            openAccordionId={openAccordionId}
                            setOpenAccordionId={setOpenAccordionId}
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    ))}
                    <Divider sx={{ mt: 'auto' }} />
                    <Button startIcon={<AddBoxTwoToneIcon />} onClick={() => setCalcBuilderOpen(true)} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        Eigene Kalkulation erstellen
                    </Button>
                    {isCalcBuilderOpen && (
                        <CalcBuilderDialog
                            isOpen={isCalcBuilderOpen}
                            setOpen={setCalcBuilderOpen}
                            variables={category.variables}
                            calcRelevantVariableNames={calcRelevantVariableNames}
                            onSave={createCalculationBasis}
                            creationMode
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    )}
                </StyledStack>
            </DialogContent>
        </Dialog>
    );
};

export default CalculationBasisDialog;
