import { Typography, Stack, useTheme, TextField, Collapse } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import HeadlineButton from 'src/components/display/HeadlineButton/HeadlineButton';
import ButtonSelector from 'src/components/input/ButtonSelector/ButtonSelector';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputAttributesResource, InputVariableResource, ValueResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: InputVariableResource;
    variables: Array<VariableResource>;
    setVariable: (variable: VariableResource) => void;
    setValid: (isValid: boolean) => void;
}

const InputVariableForm: React.FC<Props> = ({ variable, variables, setVariable, setValid }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isCriteriaOpen, setCriteriaOpen] = useState<boolean>(false);

    useEffect(() => {
        if (variable) return;
        setVariable({
            type: VariableResource.type.INPUT_VARIABLE,
            name: '',
            attributes: {
                type: InputAttributesResource.type.TEXT
            },
            value: { type: ValueResource.type.STRING_VALUE, stringValue: '' }
        } as InputVariableResource);
    }, []);

    useEffect(() => {
        if (!variable) return setValid(false);
        const isInvalid = variable.name == null || variable.name.trim() === '';
        setValid(!isInvalid);
    }, [variable]);

    const items = useMemo(() => {
        return Object.entries(InputAttributesResource.type).map(([key, value]) => ({ value, label: value }));
    }, []);

    const handleNameChange = (event) => {
        setVariable({
            ...variable,
            name: event.target.value
        });
    };
    const handleInputTypeChange = (value) => {
        setVariable({
            ...variable,
            attributes: { ...variable.attributes, type: value }
        } as InputVariableResource);
    };

    const isDisabled = variable?.id === -1;

    return (
        variable && (
            <Stack>
                <TextField label="Name" value={variable.name} onChange={handleNameChange} variant="outlined" fullWidth disabled={isDisabled} />

                <Typography variant="h4" sx={{ fontSize: 20, mt: 7, mb: 1 }} gutterBottom>
                    Was soll abgefragt werden?
                </Typography>
                <ButtonSelector items={items} value={variable.attributes.type} setValue={handleInputTypeChange} disabled={isDisabled} />

                <Typography variant="h4" sx={{ fontSize: 20, mt: 7 }} gutterBottom></Typography>

                <HeadlineButton isOpen={isCriteriaOpen} setOpen={setCriteriaOpen}>
                    Aktionen (Optional)
                </HeadlineButton>

                <Collapse in={isCriteriaOpen} timeout="auto" unmountOnExit>
                    <Typography sx={{ fontSize: 14, lineHeight: 1.3, mb: 3 }} variant="subtitle1">
                        Wähle aus, wie der Preis für diese Kategorie berechnet werden soll. Wenn keine der vordefinierten Kalkulationen für dich geeignet ist, kannst du deine eigene Kalkulation
                        erstellen.
                    </Typography>

                    <Stack bgcolor={theme.colors.alpha.black[7]} alignItems="center" justifyContent="center" height="8rem" fontSize={14} sx={{ opacity: 0.6, borderRadius: 1 }}>
                        <GridViewTwoToneIcon sx={{ fontSize: 28, opacity: 0.6 }} />
                        Keine Kriterien gesetzt
                    </Stack>
                </Collapse>
            </Stack>
        )
    );
};

export default InputVariableForm;
