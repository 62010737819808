const MARKETPLACE_BASE_PATH =
    process.env.REACT_APP_STAGE === 'staging'
        ? 'https://api.staging.surface-market.com'
        : process.env.REACT_APP_STAGE === 'production'
        ? 'https://api.surface-solutions.app'
        : process.env.REACT_APP_STAGE === 'development'
        ? 'https://api.development.surface-market.com'
        : 'http://localhost:9000';

const CORE_CALC_BASE_PATH = 'http://localhost:8082';

const INTERNAL_CALC_BASE_PATH =
    process.env.REACT_APP_STAGE === 'staging'
        ? 'https://internalcalc.staging.surface-market.com'
        : process.env.REACT_APP_STAGE === 'production'
        ? 'https://internalcalc.surface-solutions.app'
        : process.env.REACT_APP_STAGE === 'development'
        ? 'https://internalcalc.development.surface-market.com'
        : 'http://localhost:8083';

const TRANSLATION_PATH =
    process.env.REACT_APP_STAGE === 'staging'
        ? 'https://translation.staging.surface-market.com'
        : process.env.REACT_APP_STAGE === 'production'
        ? 'https://translation.surface-solutions.app'
        : process.env.REACT_APP_STAGE === 'development'
        ? 'https://translation.development.surface-market.com'
        : 'http://localhost:9020';

const HOLE_RECOGNITION_PATH =
    process.env.REACT_APP_STAGE === 'staging'
        ? 'https://dev.hole.surface-market.com/afterburner/recognitionResult'
        : process.env.REACT_APP_STAGE === 'production'
        ? 'https://hole.surface-solutions.app/afterburner/recognitionResult'
        : process.env.REACT_APP_STAGE === 'development'
        ? 'https://dev.hole.surface-market.com/afterburner/recognitionResult'
        : 'https://dev.hole.surface-market.com/afterburner/recognitionResult';

const WIZARD_PATH =
    process.env.REACT_APP_STAGE === 'staging'
        ? 'https://test.staging.surface-wizard.com'
        : process.env.REACT_APP_STAGE === 'production'
        ? 'https://test.production.surface-wizard.com'
        : process.env.REACT_APP_STAGE === 'development'
        ? 'https://test.development.surface-wizard.com'
        : 'http://localhost:3000';

const MARKETPLACE_BACKEND = {
    API_CLIENT_SECRET: 'bWFya2V0cGxhY2VjbGllbnQ6WE4yZ3V5WlVITXJLYWM2NVZkbjc=',
    MARKETPLACE_BASE_PATH: MARKETPLACE_BASE_PATH + '/marketplace',
    AUTH_PATH: MARKETPLACE_BASE_PATH + '/oauth/token',
    API_BASE_PATH: MARKETPLACE_BASE_PATH,
    CAPTCHA_KEY: '6Lf6IPkdAAAAAJAw737Jcc8Xj7aWvv7vpdQ-dz2j'
};

const SUMMARY_BACKEND_LOCAL = {
    BACKEND_URL: 'http://localhost:5000/api',
    BUCKET_URL: 'https://test.staging.surface-wizard.com',
    LINK_BASE_URL: 'http://localhost:3001'
};

const SUMMARY_BACKEND_STAGING = {
    BACKEND_URL: 'https://api.wizard.staging.surface-market.com/api',
    BUCKET_URL: 'https://test.staging.surface-wizard.com',
    LINK_BASE_URL: 'https://staging.surface-market.com'
};

const SUMMARY_BACKEND_PRODUCTION = {
    BACKEND_URL: 'https://api.wizard.surface-solutions.app/api/',
    BUCKET_URL: 'https://test.production.surface-wizard.com',
    LINK_BASE_URL: 'https://surface-wizard.com'
};

const SUMMARY_BACKEND_DEVELOPMENT = {
    BACKEND_URL: 'https://api.development.surface-wizard.com/api',
    BUCKET_URL: 'https://test.staging.surface-wizard.com',
    LINK_BASE_URL: 'https://development.surface-market.com'
};

const SUMMARY_BACKEND =
    process.env.REACT_APP_STAGE === 'production'
        ? SUMMARY_BACKEND_PRODUCTION
        : process.env.REACT_APP_STAGE === 'staging'
        ? SUMMARY_BACKEND_STAGING
        : process.env.REACT_APP_STAGE === 'development'
        ? SUMMARY_BACKEND_DEVELOPMENT
        : SUMMARY_BACKEND_LOCAL;

interface IAPI_CONFIG {
    CORE_CALC: {
        CORE_CALC_BASE_PATH: string;
    };
    INTERNAL_CALC: {
        INTERNAL_CALC_BASE_PATH: string;
    };
    MARKETPLACE: {
        API_CLIENT_SECRET: string;
        MARKETPLACE_BASE_PATH: string;
        AUTH_PATH: string;
        API_BASE_PATH: string;
        CAPTCHA_KEY: string;
    };
    TRANSLATION: {
        TRANSLATION_PATH: string;
    };
    WIZARD: {
        WIZARD_PATH: string;
    };
    HOLE_RECOGNITION: {
        HOLE_RECOGNITION_PATH: string;
    };
    SUMMARY: {
        BACKEND_URL: string;
        BUCKET_URL: string;
        LINK_BASE_URL: string;
        LOGOS_FOLDER: string;
        ITEMS_FOLDER: string;
    };
    GOOGLE_MAPS_API_KEY: string;
    MASTER_SURCHARGE_IDENTIFICATION_KEY: string;
}

export const API_CONFIG: IAPI_CONFIG = {
    CORE_CALC: {
        CORE_CALC_BASE_PATH: CORE_CALC_BASE_PATH
    },
    MARKETPLACE: MARKETPLACE_BACKEND,
    INTERNAL_CALC: {
        INTERNAL_CALC_BASE_PATH: INTERNAL_CALC_BASE_PATH
    },
    TRANSLATION: {
        TRANSLATION_PATH: TRANSLATION_PATH
    },
    WIZARD: {
        WIZARD_PATH: WIZARD_PATH
    },
    HOLE_RECOGNITION: {
        HOLE_RECOGNITION_PATH: HOLE_RECOGNITION_PATH
    },
    SUMMARY: {
        ...SUMMARY_BACKEND,
        LOGOS_FOLDER: '/logos',
        ITEMS_FOLDER: '/items'
    },
    GOOGLE_MAPS_API_KEY: 'AIzaSyBDb_5HNp4jGO2ESsPOt0PvTGTpuYygZUA',
    MASTER_SURCHARGE_IDENTIFICATION_KEY: 'master'
};
