import { Typography, Stack, useTheme, Button } from '@mui/material';
import Accordion from 'src/components/display/Accordion/Accordion';
import { formatVariable } from 'src/utils/FormatHelpers';
import { useDispatch } from 'react-redux';
import VariableForm from '../Variable/VariableForm';
import { useState } from 'react';
import { SurchargeVariableResource, VariableResource } from 'src/backend/coreCalc';

interface Props {
    variable: SurchargeVariableResource;
    variables?: Array<VariableResource>;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    saveVariable: (variable: VariableResource) => void;
}

const SurchargesItem: React.FC<Props> = ({ variable, variables, openAccordionId, setOpenAccordionId, saveVariable }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isValid, setValid] = useState<boolean>(false);

    return (
        <Accordion
            accordionHeader={
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Stack alignItems="flex-start">
                        <Typography variant="h4" lineHeight={1.1}>
                            {variable.name}
                        </Typography>
                        <Typography variant="subtitle2" lineHeight={1.2}>
                            {formatVariable(variable, variables)}
                        </Typography>
                    </Stack>
                </Stack>
            }
            accordionId={variable.id + ''}
            openAccordionId={openAccordionId}
            setOpenAccordionId={setOpenAccordionId}
            hideFirstBorder={true}
        >
            <Stack px="24px" py="16px">
                <VariableForm type={VariableResource.type.SURCHARGE_VARIABLE} variable={variable} variables={variables} setVariable={saveVariable} setValid={setValid} isSmall={true} />
            </Stack>
        </Accordion>
    );
};

export default SurchargesItem;
