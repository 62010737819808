import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import { nav, NavItem } from '../../../statics/navigations';

const TopMenuItemLink = styled(Link)<{ active: string }>(
    ({ theme, active }) => `
        display: block;
        transition: all .3s ease;
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
        // text-transform: uppercase;
        color: ${active === 'true' ? theme.colors.alpha.black[100] : theme.colors.alpha.black[70]} !important; 
        // transform: ${active === 'true' ? 'none' : 'translateY(7px)'}; 
        text-decoration: none;
        &:hover {
            color: ${theme.palette.primary.main} !important; 
            text-decoration: none;
        }
  `
);

const ActiveUnderline = styled(Box)<{ active: string }>(
    ({ theme, active }) => `
        height: 4px;
        background: ${active === 'true' ? theme.palette.primary.main : 'white'};
        border-radius: 74px;
        width: 28px;
        margin-top: 5px;
        transition: all .3s ease;
        margin-bottom: -9px;
    `
);

function TopNavigator() {
    const { t } = useTranslation();

    return (
        <Box display="flex" alignItems="center">
            <Box pl={4}>
                <Box>
                    <TopMenuItemLink id={'calculationLink'} to={nav.CALCULATIONS.path} active={useMatch(nav.CALCULATIONS.path + '/*') ? 'true' : 'false'}>
                        {t(nav.CALCULATIONS.caption)}
                    </TopMenuItemLink>
                </Box>
                <ActiveUnderline active={useMatch(nav.CALCULATIONS.path + '/*') ? 'true' : 'false'}></ActiveUnderline>
            </Box>
            <Box pl={4}>
                <Box>
                    <TopMenuItemLink id={'viewer3dLink'} to={nav.VIEWER_3D.path} active={useMatch(nav.VIEWER_3D.path + '/*') ? 'true' : 'false'}>
                        {t(nav.VIEWER_3D.caption)}
                    </TopMenuItemLink>
                </Box>
                <ActiveUnderline active={useMatch(nav.VIEWER_3D.path + '/*') ? 'true' : 'false'}></ActiveUnderline>
            </Box>
        </Box>
    );
}

export default TopNavigator;
