import type { RouteObject } from 'react-router';
import AccentSidebarLayout from 'src/layouts/NavigationLayout/Navigator';
import ProtectedRoute from '../utils/ProtectedRoute';
import { nav, NavItems } from 'src/statics/navigations';
import InitialDemoUserRoute from '../utils/InitialDemoUserRoute';

export interface ExtendedRouteObject extends Omit<RouteObject, 'children'> {
    meta?: any;
    children?: ExtendedRouteObject[];
}

const convertToRoutes = (navItems: NavItems, isParentProtected: boolean = false): { routes: RouteObject[]; protectedRoutes: RouteObject[] } => {
    const routes: RouteObject[] = [];
    const protectedRoutes: RouteObject[] = [];
    const items = Object.values(navItems);
    items.forEach((item) => {
        const route: ExtendedRouteObject = { path: item.path, element: item.element, meta: item };

        if (isParentProtected || item.protected) {
            if (item.sub) route.children = convertToRoutes(item.sub, true).protectedRoutes;
            protectedRoutes.push(route as RouteObject);
        } else {
            if (item.sub) route.children = convertToRoutes(item.sub).routes;
            routes.push(route as RouteObject);
        }
    });

    return { routes, protectedRoutes };
};

const convertedRoutes = convertToRoutes(nav);

const router: ExtendedRouteObject[] = [
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <InitialDemoUserRoute>
                    <AccentSidebarLayout />
                </InitialDemoUserRoute>
            </ProtectedRoute>
        ),
        children: convertedRoutes.protectedRoutes
    },
    ...convertedRoutes.routes
];

export default router;
