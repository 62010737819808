import { Typography, Stack, Button, IconButton, styled, useTheme, TextField, InputAdornment, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import Accordion from 'src/components/display/Accordion/Accordion';
import { useMemo, useState } from 'react';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import PriceFinderDialog from 'src/components/price-finder/PriceFinderDialog';
import { formatPrice } from 'src/utils/FormatHelpers';
import CalculationBasisDynamicPriceDialog from './CalculationBasisDynamicPriceDialog';
import { updateBlueprint, BlueprintUpdateTypes } from 'src/redux/shared/calcEditor.common';
import { useDispatch } from 'react-redux';
import { cloneDeep, isEqual, get, set } from 'lodash';
import { getTermAsString } from 'src/utils/TermHelper';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import CalcBuilderDialog from '../../CalcBuilder/CalcBuilderDialog';
import {
    BinaryTermResource,
    CalculationBasisResource,
    CalculationBasisTemplateResource,
    RuleBasedVariableResource,
    StringValueResource,
    TermResource,
    TermVariableResource,
    VariableResource
} from 'src/backend/coreCalc';

const StyledSelect = styled(Select)(
    ({ theme }) => `
        flex-grow: 1;
        width: 15rem;
        color: ${theme.colors.alpha.white[100]};
        background-color: ${theme.colors.primary.main};

        .MuiSelect-select.MuiOutlinedInput-input {
            display: flex;
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;
            min-height: 3.4rem;
        }
        .MuiSvgIcon-root {
            color: inherit;
        }
        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    `
);

interface Props {
    categoryId?: number;
    variables?: Array<VariableResource>;
    calcRelevantVariableNames?: Array<string>;
    isSelected?: boolean;
    calculationBasis: CalculationBasisResource;
    calculationBasisTemplate: CalculationBasisTemplateResource;
    openAccordionId: string;
    setOpenAccordionId: (newId: string) => void;
    saveVariable: (variable: VariableResource) => void;
    createVariable: (variable: VariableResource) => void;
    removeVariable: (variableId: number) => void;
}

const CalculationBasisItem: React.FC<Props> = ({
    categoryId,
    variables,
    calcRelevantVariableNames,
    isSelected,
    calculationBasis,
    calculationBasisTemplate,
    openAccordionId,
    setOpenAccordionId,
    createVariable,
    saveVariable,
    removeVariable
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isPriceFinderOpen, setPriceFinderOpen] = useState<boolean>(false);
    const [isDynamicPriceOpen, setDynamicPriceOpen] = useState<boolean>(false);
    const [isCalcBuilderOpen, setCalcBuilderOpen] = useState<boolean>(false);

    const pathToReferenceVariable = 'a.a.a.a.variableName';
    const pathToPriceVariable = 'a.a.a.b.variableName';

    const referenceVariables = useMemo(() => {
        if (!calculationBasisTemplate) return [];
        return (variables || []).filter((variable) => calculationBasisTemplate.referenceVariableNames.includes(variable.name));
    }, [variables, calculationBasisTemplate]);

    const priceVariable = useMemo(() => {
        const variableName = get(calculationBasis?.term, pathToPriceVariable);
        const variable = (variables || []).find((variable) => variable.name === variableName) as RuleBasedVariableResource;
        if (!variable?.rules) return;
        return variable;
    }, [calculationBasis, variables]);

    const referenceVariable = useMemo(() => {
        const variableName = get(calculationBasis?.term, pathToReferenceVariable);
        return referenceVariables.find((variable) => variable.name === variableName);
    }, [calculationBasis, variables]);

    const isEasyMode = useMemo(() => {
        if (!calculationBasis?.templateId) return false;
        if (!priceVariable || !referenceVariable || !calculationBasisTemplate) return false;

        // Check if variableName is in referenceVariableNames
        if (!calculationBasisTemplate.referenceVariableNames.includes(referenceVariable.name)) {
            return false;
        }
        const templateTerm = cloneDeep(calculationBasisTemplate.term);

        // Set the variableName of the template term to match term's variable name for comparison
        set(templateTerm, pathToReferenceVariable, referenceVariable.name);

        return isEqual(templateTerm, calculationBasis.term);
    }, [priceVariable, referenceVariable, calculationBasis, calculationBasisTemplate]);

    const getVariableDescription = (variable: VariableResource) => {
        if (variable.type === VariableResource.type.TERM_VARIABLE) {
            return getTermAsString((variable as TermVariableResource).term, variables, false);
        }
        return variable.type.toString();
    };

    const selectCalculationBasis = () => {
        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_CALCULATION_BASIS_SELECTION, {
                categoryId,
                calculationBasisId: calculationBasis.id
            })
        );
    };

    const saveCalculationBasis = (name: string, term: TermResource) => {
        setCalcBuilderOpen(false);
        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_CALCULATION_BASIS, {
                categoryId,
                calculationBasis: { ...calculationBasis, name, term }
            })
        );
    };

    const renderPriceFinderHelper = () => {
        return (
            <>
                <Button
                    color="secondary"
                    sx={{
                        textAlign: 'left',
                        p: 0.4,
                        fontSize: 12,
                        lineHeight: 1.2,
                        fontWeight: 500,
                        '.MuiButton-startIcon': { mr: 0.4 }
                    }}
                    startIcon={<HelpTwoToneIcon sx={{ fontSize: '18px !important' }} />}
                    onClick={() => setPriceFinderOpen(true)}
                >
                    Für Hilfe bei der m²-Preisfindung, hier klicken
                </Button>
                <PriceFinderDialog isOpen={isPriceFinderOpen} setOpen={setPriceFinderOpen} />
            </>
        );
    };
    const handleChange = (event: SelectChangeEvent<any>) => {
        if (!isEasyMode) return;
        const term = cloneDeep(calculationBasis.term);
        set(term, pathToReferenceVariable, event.target.value);

        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_CALCULATION_BASIS, {
                categoryId,
                calculationBasis: { ...calculationBasis, term }
            })
        );
    };

    const isDynamicPrice = priceVariable?.rules?.length > 1;
    const price = useMemo(() => {
        if (isDynamicPrice) {
            const values = priceVariable?.rules.map((rule) => (rule.value as StringValueResource).stringValue);
            return values.map((price) => formatPrice(price)).join(' | ');
        }
        const defaultRule = priceVariable?.rules.find((rule) => rule.isDefault);
        return (defaultRule?.value as StringValueResource)?.stringValue;
    }, [priceVariable]);

    const handlePriceChange = (event) => {
        if (isDynamicPrice) return;
        const newValue = event.target.value;

        const rules = cloneDeep(priceVariable.rules);
        const defaultRuleIndex = priceVariable?.rules.findIndex((rule) => rule.isDefault);
        (rules[defaultRuleIndex].value as StringValueResource).stringValue = newValue;

        dispatch(
            updateBlueprint(BlueprintUpdateTypes.CATEGORY_VARIABLE, {
                categoryId,
                variable: { ...priceVariable, rules } as RuleBasedVariableResource
            })
        );
    };

    const description = getTermAsString(isEasyMode ? calculationBasis.term['a']?.['a']?.['a'] : calculationBasis.term, variables, false);

    return (
        <Accordion
            accordionHeader={
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Stack alignItems="flex-start">
                        <Typography variant="h4" lineHeight={1.1}>
                            {calculationBasis.name}
                        </Typography>
                        <Typography variant="subtitle2" lineHeight={1.2}>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>
            }
            isSelected={isSelected}
            onSelection={selectCalculationBasis}
            accordionId={calculationBasis.id + ''}
            openAccordionId={openAccordionId}
            setOpenAccordionId={setOpenAccordionId}
            hideFirstBorder={true}
        >
            <Stack px="24px" py="16px">
                {isEasyMode ? (
                    <Stack direction="row" alignItems="flex-start" gap={3}>
                        <StyledSelect value={referenceVariable?.id ?? ''} onChange={handleChange}>
                            {referenceVariables.map((variable) => (
                                <MenuItem key={variable.id} value={variable.id}>
                                    <Stack alignItems="flex-start" overflow="hidden">
                                        <Typography fontSize={14} fontWeight={700} lineHeight={1.3}>
                                            {variable.translationKey || variable.name}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={12} lineHeight={1.47} color="inherit" sx={{ opacity: 0.9, maxWidth: '100%' }} noWrap>
                                            {getVariableDescription(variable) || '-'}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <Typography color="primary" sx={{ fontSize: 74, fontWeight: 200, mt: '-32px' }}>
                            ×
                        </Typography>
                        <TextField
                            label={priceVariable.name}
                            value={price}
                            variant="outlined"
                            helperText={renderPriceFinderHelper()}
                            sx={{ width: '15rem', flexGrow: 1, '.MuiFormHelperText-root': { m: 0 } }}
                            disabled={isDynamicPrice}
                            onChange={handlePriceChange}
                            InputProps={{
                                sx: { pr: 0, minHeight: '3.4rem' },
                                startAdornment: isDynamicPrice ? null : <InputAdornment position="start">€</InputAdornment>,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={() => setDynamicPriceOpen(true)}>
                                            <SettingsTwoToneIcon />
                                        </IconButton>
                                        <CalculationBasisDynamicPriceDialog categoryId={categoryId} priceVariable={priceVariable} isOpen={isDynamicPriceOpen} setOpen={setDynamicPriceOpen} />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Typography color="primary" sx={{ fontSize: 74, fontWeight: 200, mt: '-32px' }}>
                            =
                        </Typography>
                        <Typography color="primary" sx={{ fontSize: 47, fontWeight: 400, mt: '-6px' }}>
                            Preis
                        </Typography>
                    </Stack>
                ) : (
                    <Typography color="primary" fontWeight={600} fontSize={22} mt={-1} mb={-1}>
                        Preis = {getTermAsString(calculationBasis.term, variables)}
                    </Typography>
                )}
                <Stack mt={2}>
                    <Button color="secondary" sx={{ mr: 'auto', ml: -1.5, mb: isEasyMode ? -2 : -0.5 }} startIcon={<HandymanTwoToneIcon />} onClick={() => setCalcBuilderOpen(true)}>
                        Kalkulation anpassen
                    </Button>

                    {isCalcBuilderOpen && (
                        <CalcBuilderDialog
                            isOpen={isCalcBuilderOpen}
                            setOpen={setCalcBuilderOpen}
                            variables={variables}
                            calcRelevantVariableNames={calcRelevantVariableNames}
                            name={calculationBasis.name}
                            term={calculationBasis.term}
                            onSave={saveCalculationBasis}
                            createVariable={createVariable}
                            saveVariable={saveVariable}
                            removeVariable={removeVariable}
                        />
                    )}
                </Stack>
            </Stack>
        </Accordion>
    );
};

export default CalculationBasisItem;
