import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    styled,
    TextField,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import _ from "lodash";
import {useSnackbar} from "notistack";
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import * as Yup from "yup";
import {
    MarketplaceWizardProfileResource,
    NewGeneralFileDto,
    UserResource,
    WizardProfileRestControllerService
} from "src/backend/market";
import useDialog from "src/hooks/useDialog";
import {RootState, useSelector} from "src/redux/store";
import {loadProfile, updateProfile} from "src/redux/thunks/wizardProfile.thunk";
import {LoadingImageBox} from "../Customers/Customer.styles";

const DividerGrid = styled(Grid)`
`

function BaseInformationDataInput({loading, profile}) {
    const phoneNumberRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    const websiteRegex = /^([\w\d-_]+)\.([\w\d-_\.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/gm;

    const {t}: { t: any } = useTranslation();
    const dispatch = useDispatch();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [logoLoading, setLogoLoading] = useState(false);
    const dialog = useDialog();


    let user: UserResource = useSelector((state: RootState) => state.user.currentUser);

    const baseInputFields = useMemo(() => ({
        profileName: profile.name,
        deliveryTime: profile.defaultDeliveryTimeInDays,
        companyPhoneNumber: profile.phoneNumber ?? "",
        email: profile.email ?? "",
        emailValidation: profile.email,
        contactName: profile.contactPersonName ?? "",
        contactEmail: profile.contactPersonEmail ?? "",
        contactPhoneNumber: profile.contactPersonPhone ?? "",
        submit: null,
        websiteUrl: profile.websiteUrl ?? "",
        logoUri: profile.logoUri ?? "",
    }), [profile]);

    const baseValidationSchema = {
        profileName: Yup.string()
            .min(2, t('Der Profilname ist zu kurz'))
            .max(200, t('Der Profilname ist zu lang'))
            .required(t('Du musst einen Profilnamen eingeben')),
        deliveryTime: Yup.number()
            .required(t('Du musst eine Zustellungszeit eingeben'))
            .positive(t('Die Zustellungszeit muss größer als null sein'))
            .integer(t('Die Zustellungszeit muss in ganzen Tagen eingegeben werden')),
        companyPhoneNumber: Yup.string()
            .required(t('Du musst eine Telefonnummer eingeben'))
            .matches(phoneNumberRegEx, t('Die eingegebene Telefonnummer ist nicht valide!')),
        contactName: Yup.string()
            .min(2, t('Der Name des Ansprechpartners ist zu kurz'))
            .max(200, t('Der Name des Ansprechpartners ist zu lang'))
            .required(t('Du musst einen Namen des Ansprechpartners angeben')),
        contactPhoneNumber: Yup.string()
            .required(t('Du musst eine Telefonnummer eingeben'))
            .matches(phoneNumberRegEx, t('Die eingegebene Telefonnummer ist nicht valide!'))
        , websiteUrl: Yup.string()
            .required(t("Du musst eine Website angeben"))
            .matches(websiteRegex, t('Die eingegebene Website ist nicht valide! (Bitte ohne \"https://\" eingeben!)')),
        contactEmail: Yup.string().email(t("Bitte geben Sie eine valide E-Mail ein!")).required(t('Du musst eine Email-Adresse eingeben')),
        email: Yup.string().email(t("Bitte geben Sie eine valide E-Mail ein!")).required(t('Du musst eine Email-Adresse eingeben'))
    };

    const [schema, setSchema] = useState(baseValidationSchema);

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setLogoLoading(true);
            let img = event.target.files[0];
            WizardProfileRestControllerService
                .getRegularCustomerUploadUrl({fileType: img.type} as NewGeneralFileDto)
                .then(response => {
                    const imageURL = response.presignedUrl;
                    const requestMetadata = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': img.type
                        },
                        body: img
                    };
                    fetch(imageURL, requestMetadata).then(_ => {
                        WizardProfileRestControllerService
                            .setLogo(
                                user.id, {name: response.assignedImageName} as NewGeneralFileDto
                            ).then(_ => {
                            dispatch(loadProfile(() => {
                                setLogoLoading(false);
                            }));
                        })

                    });
                });
        }
    };

    const handleDisableSave = (values): boolean => {
        return _.isEqual(baseInputFields, values);
    };


    return (
        <Formik
            initialValues={baseInputFields}
            validateOnChange
            validateOnMount
            key={"settings-base-information-data-input"}
            validationSchema={Yup.object().shape(schema)}
            onSubmit={async (values) => {
                const newProfile: MarketplaceWizardProfileResource =
                    {
                        ...profile,
                        name: values.profileName,
                        contactPersonEmail: values.contactEmail,
                        contactPersonName: values.contactName,
                        email: values.email,
                        defaultDeliveryTimeInDays: values.deliveryTime,
                        phoneNumber: values.companyPhoneNumber,
                        contactPersonPhone: values.contactPhoneNumber,
                        websiteUrl: values.websiteUrl,
                    }
                if (values.email === values.emailValidation) {
                    if (profile.email === values.email) {
                        dispatch(updateProfile(newProfile, () => {
                            const key = enqueueSnackbar(t("Profil wurde geändert!"), {
                                variant: "success",
                                action: <IconButton color="inherit"
                                                    onClick={() => closeSnackbar(key)}><CloseRoundedIcon/></IconButton>,
                            });
                        }));
                    } else {
                        dialog.openDialog({
                            severity: "primary",
                            okCallback(): void {
                                dispatch(updateProfile(newProfile, () => {
                                    const key = enqueueSnackbar(t("Profil wurde geändert!"), {
                                        variant: "success",
                                        action: <IconButton color="inherit"
                                                            onClick={() => closeSnackbar(key)}><CloseRoundedIcon/></IconButton>,
                                    });
                                }));
                                dialog.closeDialog();
                            },
                            title: t("E-Mail wurde geändert."),
                            okText: t("E-Mail bestätigen!"),
                            component: <Typography
                                variant={"body2"}>{t('bitteBerprfenSieObDieAngegebeneEmailKorrektIst', {email: values.email})}</Typography>
                        });
                    }
                } else {
                    const key = enqueueSnackbar(t("E-Mail stimmt nicht überein!"), {
                        variant: "error",
                        action: <IconButton color="inherit"
                                            onClick={() => closeSnackbar(key)}><CloseRoundedIcon/></IconButton>,
                    });
                }
            }}>
            {({
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  values,
                  isValid
              }) => (
                <form onSubmit={handleSubmit} id={"settings-base-information-form"}>
                    <Card>
                        <Grid container spacing={1} p={1}>
                            <DividerGrid item xs={12}>
                                <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt'
                                     sx={{display: "flex", flexDirection: "column"}}>
                                    <Typography
                                        variant="caption"
                                    >
                                        {t('infosZumUnternehmen')}
                                    </Typography>
                                    {t('profildaten')}
                                </Box>
                                <Divider sx={{mt: 1, mb: 2}}/>
                                <Grid container spacing={3} p={1}>
                                    <Grid item xs={12} md={12}>
                                        <Box display={"flex"} flexDirection={"column"}>
                                            <Typography variant={"caption"}>{t('firmenlogo')}</Typography>
                                            <Box gap={2} display={"flex"}
                                                 flexDirection={"column"}
                                                 alignItems={"flexStart"}>
                                                {logoLoading ? <LoadingImageBox>
                                                        <CircularProgress/>
                                                    </LoadingImageBox> :
                                                    <img style={{
                                                        borderRadius: '10px',
                                                        maxHeight: '125px',
                                                        maxWidth: '300px',
                                                        objectFit: "cover"
                                                    }} loading={"lazy"}
                                                         src={baseInputFields.logoUri}/>}
                                                <input disabled={false} type="file"
                                                       accept="image/*"
                                                       onChange={onImageChange}/>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id={"profileName"}
                                            error={Boolean(errors.profileName)}
                                            fullWidth
                                            helperText={errors.profileName}
                                            label={t('Profilname')}
                                            name='profileName'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.profileName}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id={"deliveryTime"}
                                            type="number"
                                            error={Boolean(errors.deliveryTime)}
                                            fullWidth
                                            helperText={errors.deliveryTime}
                                            label={t('defaultDeliveryTimeInDays')}
                                            name='deliveryTime'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.deliveryTime}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id={"companyPhoneNumber"}
                                            error={Boolean(errors.companyPhoneNumber)}
                                            fullWidth
                                            helperText={errors.companyPhoneNumber}
                                            label={t('PHONE')}
                                            name='companyPhoneNumber'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.companyPhoneNumber}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id={"email"}
                                            error={Boolean(errors.email)}
                                            fullWidth
                                            helperText={errors.email ? errors.email : t('emailRequestsHere')}
                                            label={t('EMAIL')}
                                            name='email'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            variant='outlined'
                                        />
                                        {
                                            values.emailValidation !== values.email ?
                                                <TextField
                                                    id={"emailValidation"}
                                                    sx={{marginTop: 2}}
                                                    error={Boolean(values.email !== values.emailValidation)}
                                                    fullWidth
                                                    helperText={errors.email}
                                                    label={t('repeatEMail')}
                                                    name='emailValidation'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.emailValidation}
                                                    variant='outlined'
                                                /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id={"websiteUrl"}
                                            error={Boolean(errors.websiteUrl)}
                                            fullWidth
                                            helperText={errors.websiteUrl ? errors.websiteUrl : t('siteToWizard')}
                                            label={t('website')}
                                            name='websiteUrl'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.websiteUrl}
                                            variant='outlined'
                                        />
                                    </Grid>

                                </Grid>
                            </DividerGrid>
                            <Grid item xs={12}>
                                <Box pt={2} pl={1} fontWeight='bold' fontSize='14pt'
                                     sx={{display: "flex", flexDirection: "column"}}>
                                    <Typography
                                        variant="caption"
                                    >
                                        {t('infosZumAnsprechpartner')}
                                    </Typography>
                                    {t('profildaten')}
                                </Box>
                                <Divider sx={{mt: 1, mb: 2}}/>

                                <Grid container spacing={3} p={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id={"contactName"}
                                            error={Boolean(errors.contactName)}
                                            fullWidth
                                            helperText={errors.contactName}
                                            label={t('name')}
                                            name='contactName'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.contactName}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id={"contactEmail"}
                                            error={Boolean(errors.contactEmail)}
                                            fullWidth
                                            helperText={errors.contactEmail}
                                            label={t('EMAIL')}
                                            name='contactEmail'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.contactEmail}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id={"contactPhoneNumber"}
                                            error={Boolean(errors.contactPhoneNumber)}
                                            fullWidth
                                            helperText={errors.contactPhoneNumber}
                                            label={t('PHONE')}
                                            name='contactPhoneNumber'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.contactPhoneNumber}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Box pt={3} display='flex' alignItems='center' justifyContent='flex-end'>
                                            <Button
                                                id={"submitForm"}
                                                type='submit'
                                                disabled={!isValid || Boolean(errors.submit) || isSubmitting || handleDisableSave(values)}
                                                variant='contained'>
                                                {t('SAVE')}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            )}
        </Formik>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        profile: state.wizardProfile.currentProfile,
        loading: state.wizardStati.loading,
    }
}
export default connect(mapStateToProps)(BaseInformationDataInput);
