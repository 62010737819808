import { AppDispatch, RootState } from '../store';
import {
    addCategory,
    addCategoryVariable,
    addMasterVariable,
    deleteCategory,
    deleteCategoryVariable,
    deleteMasterVariable,
    handleError,
    setBlueprint,
    setBlueprintValue,
    setLoading
} from '../slices/calcEditor.reducer';
import {
    BlueprintResource,
    BlueprintRestControllerService,
    CalculationBasisResource,
    CategoryDTO,
    CategoryTemplateResource,
    NewCategoryDTO,
    TermDTO,
    VariableDTO,
    VariableResource
} from 'src/backend/coreCalc';
import { loadTemplates } from './templates.thunk';

// const testVariables: Array<VariableResource> = [
//     {
//         id: 'largestLength',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Größte Länge',
//         metadata: [{ key: 'dimensionLabel', value: '1' }],
//         attributes: {
//             type: Inp InputTypes.NUMBER,
//             sequence: 1,
//             section: 'geometryPackageSection',
//             image: 'C-Profil_komp.png',
//             defaultValue: null,
//             min: 0,
//             max: 2147483647,
//             unit: 'mm',
//             allowDigits: false
//         },
//         value: '0'
//     },
//     {
//         id: 'largestWidth',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Größte Breite',
//         metadata: [{ key: 'dimensionLabel', value: '2' }],
//         attributes: {
//             type: InputTypes.NUMBER,
//             sequence: 1,
//             section: 'geometryPackageSection',
//             image: 'C-Profil_komp.png',
//             defaultValue: null,
//             min: 0,
//             max: 2147483647,
//             unit: 'mm',
//             allowDigits: false
//         },
//         value: '0'
//     },
//     {
//         id: 'largestHeight',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Größte Höhe',
//         metadata: [{ key: 'dimensionLabel', value: '3' }],
//         attributes: {
//             type: InputTypes.NUMBER,
//             sequence: 1,
//             section: 'geometryPackageSection',
//             image: 'C-Profil_komp.png',
//             defaultValue: null,
//             min: 0,
//             max: 2147483647,
//             unit: 'mm',
//             allowDigits: false
//         },
//         value: '0'
//     },
//     {
//         id: 'unitWeight',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Einzelgewicht',
//         metadata: null,
//         attributes: {
//             type: InputTypes.NUMBER,
//             sequence: 300,
//             section: 'sec2',
//             image: null,
//             defaultValue: null,
//             min: 0,
//             max: 300,
//             unit: 'kg',
//             allowDigits: true
//         },
//         value: '0.0'
//     },
//     {
//         id: 'condition',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Zustand',
//         metadata: null,
//         attributes: {
//             type: InputTypes.MULTI_SELECT,
//             sequence: 250,
//             section: 'sec2',
//             image: null,
//             defaultValue: ['blank'],
//             items: [
//                 { id: 'blank', name: 'Blank' },
//                 { id: 'rusty', name: 'Rostig' },
//                 { id: 'damaged', name: 'Beschädigt' },
//                 { id: 'stripped', name: 'Entlackt' },
//                 { id: 'coated', name: 'Beschichtet' }
//             ]
//         },
//         value: ['blank']
//     },
//     {
//         id: 'threads',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Gewinde',
//         metadata: null,
//         attributes: {
//             type: InputTypes.NUMBER,
//             sequence: 410,
//             section: 'sec4',
//             image: null,
//             defaultValue: '0',
//             min: 0,
//             max: 2147483647,
//             unit: 'Stück',
//             allowDigits: false
//         },
//         value: '0'
//     },
//     {
//         id: 'dbColor',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'DB Farbe',
//         metadata: null,
//         attributes: {
//             type: InputTypes.TEXT,
//             sequence: 200,
//             section: 'sec3',
//             image: null,
//             defaultValue: '301',
//             items: []
//         },
//         value: '301'
//     },
//     {
//         id: 'quantity',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Stückzahl',
//         metadata: null,
//         attributes: {
//             type: InputTypes.NUMBER,
//             sequence: 200,
//             section: null,
//             image: null,
//             defaultValue: '1',
//             min: 1,
//             max: 2147483647,
//             unit: 'Stück',
//             allowDigits: false
//         },
//         value: '1',
//         special: true
//     },
//     {
//         id: 'structure',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Struktur',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 350,
//             section: 'sec3',
//             image: null,
//             defaultValue: 'glatt',
//             items: [
//                 { id: 'glatt', name: 'Glatt' },
//                 { id: 'feinstruktur', name: 'Feinstruktur' },
//                 { id: 'grobstruktur', name: 'Grobstruktur' }
//             ]
//         },
//         value: 'glatt'
//     },
//     {
//         id: 'otherMaterialComment',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Sonstiges',
//         metadata: null,
//         attributes: {
//             type: InputTypes.TEXT,
//             sequence: 220,
//             section: 'sec2',
//             image: null,
//             defaultValue: '-'
//         },
//         value: '-'
//     },
//     {
//         id: 'ncsColor',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'NCS Farbe',
//         metadata: null,
//         attributes: {
//             type: InputTypes.TEXT,
//             sequence: 220,
//             section: 'sec3',
//             image: null,
//             defaultValue: '0300-N',
//             items: []
//         },
//         value: '0300-N'
//     },
//     {
//         id: 'pantoneColor',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Pantone Farbe',
//         metadata: null,
//         attributes: {
//             type: InputTypes.TEXT,
//             sequence: 220,
//             section: 'sec3',
//             image: null,
//             defaultValue: '100',
//             items: []
//         },
//         value: '100'
//     },
//     {
//         id: 'mettalicColor',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Metallic',
//         metadata: null,
//         attributes: {
//             type: InputTypes.TEXT,
//             sequence: 220,
//             section: 'sec3',
//             image: null,
//             defaultValue: '',
//             items: []
//         },
//         value: ''
//     },
//     {
//         id: 'materialStrength',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Materialstärke',
//         metadata: null,
//         attributes: {
//             type: InputTypes.NUMBER,
//             sequence: 230,
//             section: 'sec2',
//             image: null,
//             defaultValue: '1.0',
//             min: 1,
//             max: 2147483647,
//             unit: 'mm',
//             allowDigits: true
//         },
//         value: '1.0'
//     },
//     {
//         id: 'ralColor',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'RAL Farbe',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 200,
//             section: 'sec3',
//             image: null,
//             defaultValue: '7016',
//             items: []
//         },
//         value: '7016'
//     },
//     {
//         id: 'application',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Anwendung',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 310,
//             section: 'sec3',
//             image: null,
//             defaultValue: 'innen',
//             items: [
//                 { id: 'innen', name: 'Innenbereich' },
//                 { id: 'aussen', name: 'Außenbereich' }
//             ]
//         },
//         value: 'innen'
//     },
//     {
//         id: 'glossiness',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Glanzgrad',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 360,
//             section: 'sec3',
//             image: null,
//             defaultValue: 'glaenzend',
//             items: [
//                 { id: 'glaenzend', name: 'Glänzend' },
//                 { id: 'seidenglaenzend', name: 'Seidenglänzend' },
//                 { id: 'matt', name: 'Matt' },
//                 { id: 'stumpfmatt', name: 'Stumpfmatt' }
//             ]
//         },
//         value: 'glaenzend'
//     },
//     {
//         id: 'manufacturer',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Hersteller',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 320,
//             section: 'sec3',
//             image: null,
//             defaultValue: 'Hersteller Egal',
//             items: [
//                 { id: 'Hersteller Egal', name: 'Hersteller Egal' },
//                 { id: 'AKZO Nobel', name: 'AKZO Nobel' },
//                 { id: 'AXALTA', name: 'AXALTA' },
//                 { id: 'BASF COATINGS', name: 'BASF COATINGS' },
//                 { id: 'ECO POLFIX', name: 'ECO POLFIX' },
//                 { id: 'FREI LACKE', name: 'FREI LACKE' },
//                 { id: 'JOTUN', name: 'JOTUN' },
//                 { id: 'BRILLUX', name: 'BRILLUX' },
//                 { id: 'TIGER COATINGS', name: 'TIGER COATINGS' },
//                 { id: 'TEKNOS', name: 'TEKNOS' },
//                 { id: 'PULVERIT', name: 'PULVERIT' },
//                 { id: 'PULVER', name: 'PULVER' },
//                 { id: 'PPG COATINGS', name: 'PPG COATINGS' },
//                 { id: 'REMBRANTIN', name: 'REMBRANTIN' },
//                 { id: 'IGP POWDER COATINGS', name: 'IGP POWDER COATINGS' },
//                 { id: 'GRIMM PULVERLACK', name: 'GRIMM PULVERLACK' }
//             ]
//         },
//         value: 'Hersteller Egal'
//     },
//     {
//         id: 'colorSystem',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Farbsystem',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 330,
//             section: 'sec3',
//             image: null,
//             defaultValue: 'RAL_color',
//             items: [
//                 { id: 'ralColor', name: 'RAL' },
//                 { id: 'ncsColor', name: 'NCS' },
//                 { id: 'dbColor', name: 'DB' },
//                 { id: 'pantoneColor', name: 'Pantone' },
//                 { id: 'metallicColor', name: 'Metallic' }
//             ]
//         },
//         value: 'RAL_color'
//     },
//     {
//         id: 'holes',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Bohrungen',
//         metadata: null,
//         attributes: {
//             type: InputTypes.NUMBER,
//             sequence: 420,
//             section: 'sec4',
//             image: null,
//             defaultValue: '0',
//             min: 0,
//             max: 2147483647,
//             unit: 'Stück',
//             allowDigits: false
//         },
//         value: '0'
//     },
//     {
//         id: 'material',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Material',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 420,
//             section: 'sec4',
//             image: null,
//             defaultValue: 'steel',
//             items: [
//                 { id: 'aluminum', name: 'Aluminium' },
//                 { id: 'anodizedAluminum', name: 'Aluminium eloxiert' },
//                 { id: 'steel', name: 'Stahl blank' },
//                 { id: 'fireGalvanizedSteel', name: 'Stahl feuerverzinkt' },
//                 { id: 'sendzimirGalvanizedSteel', name: 'Stahl sendzimirverzinkt' },
//                 { id: 'stainlessSteel', name: 'Edelstahl' },
//                 { id: 'other', name: 'Sonstiges' }
//             ]
//         },
//         value: 'steel'
//     },
//     {
//         id: 'coatingThickness',
//         type: VariableResource.type.INPUT_VARIABLE,
//         name: 'Schichtdicke',
//         metadata: null,
//         attributes: {
//             type: InputTypes.SELECT,
//             sequence: 420,
//             section: 'sec3',
//             image: null,
//             defaultValue: '70µm - 90µm',
//             items: [
//                 { id: '70µm - 90µm', name: '70µm - 90µm' },
//                 { id: '100µm - 120µm', name: '100µm - 120µm' }
//             ]
//         },
//         value: '0'
//     },
//     {
//         id: 'boundingBox',
//         type: VariableTypes.TERM_VARIABLE,
//         name: 'Bounding Box',
//         term: {
//             termType: TermType.MULTIPLY,
//             a: {
//                 termType: TermType.VALUE,
//                 value: 2
//             } as VariableTermResource,
//             b: {
//                 termType: TermType.ADD,
//                 a: {
//                     termType: TermType.ADD,
//                     a: {
//                         termType: TermType.MULTIPLY,
//                         a: { termType: TermType.VARIABLE, variableId: 'largestLength' },
//                         b: { termType: TermType.VARIABLE, variableId: 'largestWidth' }
//                     },
//                     b: {
//                         termType: TermType.MULTIPLY,
//                         a: { termType: TermType.VARIABLE, variableId: 'largestWidth' },
//                         b: { termType: TermType.VARIABLE, variableId: 'largestHeight' }
//                     }
//                 } as BinaryTermResource,
//                 b: {
//                     termType: TermType.MULTIPLY,
//                     a: { termType: TermType.VARIABLE, variableId: 'largestHeight' },
//                     b: { termType: TermType.VARIABLE, variableId: 'largestLength' }
//                 }
//             } as BinaryTermResource
//         }
//     },
//     {
//         id: 'objectSurfaceArea', // extractedObjectSurfaceArea
//         type: VariableTypes.PROVIDED_VARIABLE,
//         name: 'Genaue Oberfläche aus 3D Datei',
//         value: '0'
//     },
//     {
//         id: 'objectVolume', // extractedObjectVolume
//         type: VariableTypes.PROVIDED_VARIABLE,
//         name: 'Genaues Volumen aus 3D Datei',
//         value: '0'
//     },
//     {
//         id: 'objectConvexHullSurfaceArea', // extractedObjectConvexHullSurfaceArea
//         type: VariableTypes.PROVIDED_VARIABLE,
//         name: 'Konvexe Hülle aus 3D Datei',
//         value: '0'
//     },
//     {
//         id: 'relativeSurcharges',
//         type: VariableTypes.PROVIDED_VARIABLE,
//         name: 'Prozentuale Aufschläge',
//         value: '1'
//     },
//     {
//         id: 'absoluteSurcharges',
//         type: VariableTypes.PROVIDED_VARIABLE,
//         name: 'Absolute Aufschläge',
//         value: '0'
//     },
//     {
//         id: 'qmPrice',
//         type: VariableTypes.RULE_BASED_VARIABLE,
//         name: 'm² Preis',
//         rules: [{ id: '1', ruleValue: { type: 'value', value: '0' }, default: true }]
//     },
//     {
//         id: 'kgPrice',
//         type: VariableTypes.RULE_BASED_VARIABLE,
//         name: 'kg Preis',
//         rules: [{ id: '1', ruleValue: { type: 'value', value: '0' }, default: true }]
//     },
//     {
//         id: 'surcharge1',
//         type: VariableTypes.SURCHARGE_VARIABLE,
//         name: 'Aufschlag 1',
//         description: 'Aufschlag 1 Beschreibung',
//         ruleBasedValue: [{ id: '1', ruleValue: { type: 'value', value: '0' }, default: true }],
//         criteria: [],
//         absoluteSurcharge: true
//     }
// ];

// const testBlueprint = {
//     id: 1109,
//     name: 'Test Blueprint',
//     categories: [],
//     createdAt: '2023-12-22T10:15:11',
//     updatedAt: '2024-01-02T08:24:37',
//     calculationBasis: {
//         id: 'masterCalculation',
//         name: 'Gesamt Kalkulation',
//         term: {
//             termType: TermType.MULTIPLY,
//             a: {
//                 termType: TermType.ADD,
//                 a: {
//                     termType: TermType.VARIABLE,
//                     variableId: 'categories'
//                 },
//                 b: {
//                     termType: TermType.VARIABLE,
//                     variableId: 'absoluteSurcharges'
//                 }
//             } as BinaryTermResource,
//             b: {
//                 termType: TermType.VARIABLE,
//                 variableId: 'relativeSurcharges'
//             } as VariableTermResource
//         },
//         value: '0'
//     },
//     status: 'VALID',
//     invalidStatus: null,
//     masterVariables: [
//         {
//             id: 'deliveryTime',
//             type: VariableResource.type.INPUT_VARIABLE,
//             name: 'Lieferzeit',
//             metadata: null,
//             attributes: {
//                 type: InputTypes.SELECT,
//                 sequence: 310,
//                 section: 'sec3',
//                 image: null,
//                 defaultValue: 'innen',
//                 items: [
//                     { id: 'express', name: 'Express' },
//                     { id: 'standard', name: 'Standard' }
//                 ]
//             },
//             value: 'express'
//         },
//         {
//             id: 'categories',
//             type: VariableTypes.PROVIDED_VARIABLE,
//             name: 'Summe aller Kategorien',
//             value: '0'
//         },
//         {
//             id: 'relativeSurcharges',
//             type: VariableTypes.PROVIDED_VARIABLE,
//             name: 'Prozentuale Aufschläge',
//             value: '1'
//         },
//         {
//             id: 'absoluteSurcharges',
//             type: VariableTypes.PROVIDED_VARIABLE,
//             name: 'Absolute Aufschläge',
//             value: '0'
//         }
//     ],
//     categoryTemplates: [
//         {
//             id: 'threeDExactSurface',
//             name: '3D Genaue Oberfläche',
//             description: 'Lege eine feste Variable an, um Werte einfach wiederverwenden zu können',
//             icon: 'TextureTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'threeDConvexHull',
//             name: '3D Konvexe Hülle',
//             description: 'Hier kannst du Berechnungen durchführen wie zum Beispiel a mal b rechnen',
//             icon: 'ViewInArTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'boundingBox',
//             name: '3D/PDF Bounding Box',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'CheckBoxOutlineBlankTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'generalProfiles',
//             name: 'Allgemeine Profile',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'ViewModuleTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'roundTube',
//             name: 'Rundrohr',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'CircleTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'railing',
//             name: 'Geländer',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'LinearScaleTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'screws',
//             name: 'Schrauben',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'BuildTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'nut',
//             name: 'Mutter',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'DonutSmallTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'flatBars',
//             name: 'Flachstangen',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'HorizontalRuleTwoTone',

//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'smoothMetalSheet',
//             name: 'Blech glatt',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'ViewWeekTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         },
//         {
//             id: 'channeledMetalSheet',
//             name: 'Blech gekanntet',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'CallMadeTwoTone',
//             variables: testVariables,
//             supportedCalculationBasisTemplateIds: ['areaCalculation', 'weightCalculation'],
//             defaultCalculationBasisTemplateId: 'areaCalculation'
//         }
//     ],
//     variableTemplates: [
//         { id: VariableTypes.DECLARATION_VARIABLE.toString(), name: 'Fixer Wert', description: 'Lege eine feste Variable an, um Werte einfach wiederverwenden zu können', icon: 'GpsFixedTwoTone' },
//         {
//             id: VariableTypes.TERM_VARIABLE.toString(),
//             name: 'Berechneter Wert (Formel)',
//             description: 'Hier kannst du Berechnungen durchführen wie zum Beispiel a mal b rechnen',
//             icon: 'CalculateTwoTone'
//         },
//         {
//             id: VariableTypes.RULE_BASED_VARIABLE.toString(),
//             name: 'Wert mit Kondition',
//             description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen',
//             icon: 'CallSplitTwoTone'
//         },
//         { id: VariableTypes.ROUND_VARIABLE.toString(), name: 'Wert runden', description: 'Mit dieser Regel kann man "Wenn - Dann - Sonst" Entscheidungen treffen', icon: 'DonutSmallTwoTone' },
//         { id: VariableResource.type.INPUT_VARIABLE.toString(), name: 'Eingabewert', description: 'Wert aus dem Eingabefeld', icon: 'InputTwoTone' }
//     ],
//     calculationBasisTemplates: [
//         {
//             id: 'areaCalculation',
//             name: 'Flächenkalkulation',
//             description: 'Genaue Oberfläche × m² Preis',
//             icon: null,
//             referenceVariableIds: ['boundingBox', 'exactSurfaceArea'],
//             term: {
//                 // The term structure is always the same. If it differs from this exact structure, the "advanced mode view" is automatically used
//                 // In this case the "template view" will be used if it the strucutre is:
//                 //
//                 //                   ( ( (referanceVariableId x qmMeterPrice) x quantity) + absoluteSurcharges) x relativeSurcharges
//                 //                          /         \
//                 //                   boundingBox     exactSurfaceArea
//                 termType: TermType.MULTIPLY,
//                 a: {
//                     termType: TermType.ADD,
//                     a: {
//                         termType: TermType.MULTIPLY,
//                         a: {
//                             termType: TermType.MULTIPLY,
//                             a: {
//                                 termType: TermType.VARIABLE,
//                                 variableId: 'boundingBox'
//                             },
//                             b: {
//                                 termType: TermType.VARIABLE,
//                                 variableId: 'qmPrice'
//                             }
//                         },
//                         b: {
//                             termType: TermType.VARIABLE,
//                             variableId: 'quantity'
//                         }
//                     },
//                     b: {
//                         termType: TermType.VARIABLE,
//                         variableId: 'absoluteSurcharges'
//                     }
//                 } as BinaryTermResource,
//                 b: {
//                     termType: TermType.VARIABLE,
//                     variableId: 'relativeSurcharges'
//                 } as VariableTermResource
//             }
//         },
//         {
//             id: 'weightCalculation',
//             name: 'Gewichtskalkulation',
//             description: 'Gewicht × Gewichtspreis',
//             icon: null,
//             referenceVariableIds: ['unitWeight'],
//             term: {
//                 termType: TermType.MULTIPLY,
//                 a: {
//                     termType: TermType.ADD,
//                     a: {
//                         termType: TermType.MULTIPLY,
//                         a: {
//                             termType: TermType.MULTIPLY,
//                             a: {
//                                 termType: TermType.VARIABLE,
//                                 variableId: 'unitWeight'
//                             },
//                             b: {
//                                 termType: TermType.VARIABLE,
//                                 variableId: 'kgPrice'
//                             }
//                         },
//                         b: {
//                             termType: TermType.VARIABLE,
//                             variableId: 'quantity'
//                         }
//                     },
//                     b: {
//                         termType: TermType.VARIABLE,
//                         variableId: 'absoluteSurcharges'
//                     }
//                 } as BinaryTermResource,
//                 b: {
//                     termType: TermType.VARIABLE,
//                     variableId: 'relativeSurcharges'
//                 } as VariableTermResource
//             }
//         }
//     ]
// };

/*
###############
##  GENERAL  ##
###############
*/
export const createBlueprint = (name: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(true));
        const blueprint = await BlueprintRestControllerService.createBlueprint({ name });
        dispatch(setLoading(false));
        return blueprint;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const loadBlueprint = (blueprintId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        await dispatch(loadTemplates());
        const blueprint = await BlueprintRestControllerService.getBlueprint(blueprintId);
        dispatch(setBlueprint(blueprint));
        dispatch(setLoading(false));
        return blueprint;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

export const deleteBlueprint = (blueprintId: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));
        // await InternalCalculationRestControllerService.deleteCalculation(calcId);
        // snackContext.enqueueSnackbar(i18n.t('Kalkulation wurde gelöscht'), { variant: 'success' });
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(handleError(error));
    }
};

/*
###############
##  UPDATES  ##
###############
*/
export const updateMasterCalculationBasis = (blueprint: BlueprintResource, value: CalculationBasisResource) => async () => {
    await BlueprintRestControllerService.updateMasterCalculationBasis(blueprint.id, value.id, { name: value.name, term: value.term as TermDTO });
};

export const createMasterVariable = (blueprint: BlueprintResource, value: VariableResource) => async (dispatch: AppDispatch) => {
    const variable = await BlueprintRestControllerService.createMasterVariable(blueprint.id, value as VariableDTO);
    dispatch(deleteMasterVariable(-1)); // delete the temp variable
    dispatch(addMasterVariable(variable));
};

export const updateMasterVariable = (blueprint: BlueprintResource, value: VariableResource) => async () => {
    await BlueprintRestControllerService.updateMasterVariable(blueprint.id, value.id, value as VariableDTO);
};

export const removeMasterVariable = (blueprint: BlueprintResource, value: number) => async () => {
    await BlueprintRestControllerService.deleteMasterVariable(blueprint.id, value);
};

export const createCategory = (blueprint: BlueprintResource, value: CategoryTemplateResource) => async (dispatch: AppDispatch) => {
    const categoryDto: NewCategoryDTO = {
        name: value.name,
        defaultCalculationBasisTemplateId: value.defaultCalculationBasisTemplateId,
        supportedCalculationBasisTemplateIds: value.supportedCalculationBasisTemplateIds,
        variables: []
    };

    value.variableTemplates.forEach((template) => {
        const variable = { ...template.variable, templateId: template.id };
        delete variable.id;
        categoryDto.variables.push(variable as VariableDTO);
    });
    const category = await BlueprintRestControllerService.createCategory(blueprint.id, categoryDto);
    dispatch(deleteCategory(-1));
    dispatch(addCategory(category));
};

export const duplicateCategory = (blueprint: BlueprintResource, value: number) => async (dispatch: AppDispatch) => {
    const category = await BlueprintRestControllerService.duplicateCategory(blueprint.id, value);
    dispatch(deleteCategory(-1));
    dispatch(addCategory(category));
};

export const removeCategory = (blueprint: BlueprintResource, value: number) => async () => {
    await BlueprintRestControllerService.deleteCategory(blueprint.id, value);
};

export const createCategoryCalculationBasis = (blueprint: BlueprintResource, value: { categoryId: number; calculationBasis: CalculationBasisResource }) => async () => {
    await BlueprintRestControllerService.createCategoryCalculationBasis(blueprint.id, value.categoryId, {
        name: value.calculationBasis.name,
        term: value.calculationBasis.term as TermDTO
    });
};

export const updateCategoryCalculationBasis = (blueprint: BlueprintResource, value: { categoryId: number; calculationBasis: CalculationBasisResource }) => async () => {
    await BlueprintRestControllerService.updateCategoryCalculationBasis(blueprint.id, value.categoryId, value.calculationBasis.id, {
        name: value.calculationBasis.name,
        term: value.calculationBasis.term as TermDTO
    });
};

export const updateCategoryCalculationBasisSelection = (blueprint: BlueprintResource, value: { categoryId: number; calculationBasisId: number }) => async () => {
    const category = blueprint.categories.find((category) => category.id === value.categoryId);
    await BlueprintRestControllerService.updateCategory(blueprint.id, value.categoryId, { name: category.name, calculationBasisId: value.calculationBasisId });
};

export const createCategoryVariable = (blueprint: BlueprintResource, value: { categoryId: number; variable: VariableResource }) => async (dispatch: AppDispatch) => {
    const variable = await BlueprintRestControllerService.createCategoryVariable(blueprint.id, value.categoryId, value.variable as VariableDTO);
    dispatch(deleteCategoryVariable({ categoryId: value.categoryId, variableId: -1 }));
    dispatch(addCategoryVariable({ categoryId: value.categoryId, variable }));
};

export const updateCategoryVariable = (blueprint: BlueprintResource, value: { categoryId: number; variable: VariableResource }) => async () => {
    await BlueprintRestControllerService.updateCategoryVariable(blueprint.id, value.categoryId, value.variable.id, value.variable as VariableDTO);
};

export const removeCategoryVariable = (blueprint: BlueprintResource, value: { categoryId: number; variableId: number }) => async (dispatch: AppDispatch) => {
    await BlueprintRestControllerService.deleteCategoryVariable(blueprint.id, value.categoryId, value.variableId);
};
