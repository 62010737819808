import { Box, LinearProgress, LinearProgressProps, Typography, useTheme } from "@mui/material";



export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} className={props.className}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} sx={{
                    backgroundColor: theme.colors.secondary.lighter,
                    '.MuiLinearProgress-barColorPrimary': { borderRadius: 8 },
                    '@media print': {
                        backgroundColor: theme.colors.secondary.light,
                    }
                }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary" textAlign="right">{Math.round(props.value)}%</Typography>
            </Box>
        </Box>
    );
}  
