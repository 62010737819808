import { GridSortModel } from '@mui/x-data-grid';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalCalculationRestControllerService } from 'src/backend/internalCalc';
import { CancelablePromise, InquiryFilterDto, InquiryPageResultResource, InquiryRestControllerService } from '../../backend/market';
import { slice } from '../slices/inquiries.reducer';
import { AppDispatch, AppThunk, RootState, snackContext } from '../store';

//INQUIRIES CRUD
const runningPromises = [];
export const loadInquiries = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log('INQUIRIES :: LOAD');
    const sortModel = getState().inquiries.inquiriesTableSort;
    const inquiryFilter = getState().inquiries.inquiryFilter;
    const page = getState().inquiries.inquiriesTablePaging.page;
    const pageSize = getState().inquiries.inquiriesTablePaging.pageSize;

    dispatch(slice.actions.setLoading(true));
    const userId = getState().user.currentUser?.id;
    if (!userId) {
        console.log('User id not there');
        return;
    }
    if (pageSize === 0) {
        console.log('No Page size!');
        return;
    }
    try {
        let sortingString;
        if (sortModel) {
            sortingString = buildSortingString(sortModel);
            console.log('sortingString', sortingString);
        }
        let promise: CancelablePromise<InquiryPageResultResource>;
        if (inquiryFilter) {
            promise = InquiryRestControllerService.getAllInquiriesOfUser(userId, page, pageSize, inquiryFilter, sortingString);
        } else {
            promise = InquiryRestControllerService.getAllInquiriesOfUser1(userId, page, pageSize, sortingString);
        }
        //Cancel all running promises!
        if (runningPromises.length > 0) {
            runningPromises.forEach((p) => p.cancel());
        }
        runningPromises.push(promise);
        const inquiries = await promise;
        console.log('FETCH :: INQUIRIES :: INITIAL', inquiries);
        dispatch(slice.actions.addFetchedPage(page));
        dispatch(slice.actions.replaceInquiries(inquiries.data));
        dispatch(slice.actions.updateRowCount(inquiries.totalElements));
    } catch (e) {
        console.log('FETCH :: INQUIRIES :: ERROR', e);
    }
};
export const updateInquiries =
    (page: number = 0, pageSize: number = 10) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const sortModel = getState().inquiries.inquiriesTableSort;
        const inquiryFilter = getState().inquiries.inquiryFilter;

        const fetchedPages = getState().inquiries.inquiriesTablePaging.fetchedPages;
        if (pageSize === 0) {
            return;
        }

        if (fetchedPages.includes(page)) {
            return;
        }
        dispatch(slice.actions.setLoader({ page, isLoading: true }));
        const userId = getState().user.currentUser.id;
        try {
            let sortingString;
            if (sortModel) {
                sortingString = buildSortingString(sortModel);
            }
            const inquiries = await InquiryRestControllerService.getAllInquiriesOfUser(userId, page, pageSize, inquiryFilter, sortingString);
            console.log('FETCH :: INQUIRIES :: UPDATE', inquiries);
            dispatch(slice.actions.updateInquiries(inquiries.data));
            dispatch(slice.actions.updateRowCount(inquiries.totalElements));
            dispatch(slice.actions.addFetchedPage(page));
            dispatch(slice.actions.setLoader({ page, isLoading: false }));
        } catch (e) {
            console.log('FETCH :: INQUIRIES :: ERROR', e);
        }
    };
export const deleteInquiry = (inquiryId: string, callback?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().user.currentUser.id;
    dispatch(slice.actions.deleteInquiry(inquiryId));
    dispatch(slice.actions.updateRowCount(getState().inquiries.inquiriesTablePaging.rowCount - 1));
    await InquiryRestControllerService.deleteInquiry(userId, inquiryId);
    dispatch(loadInquiries());
    callback();
};
export const updateSeenInquiry = (inquiryId: string, seen: boolean) => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.updateSeen({ inquiryId: inquiryId, seen: seen }));
    await InquiryRestControllerService.setInquirySeenState(inquiryId, { seen: seen });
};
export const updateStatus = (inquiryId: string, status: string, callback?: () => void) => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.updateStatus({ inquiryId: inquiryId, status: status }));
    try {
        await InquiryRestControllerService.setInquiryState(inquiryId, { inquiryState: status });
    } catch (e) {
        console.log('FETCH :: INQUIRIES :: ERROR', e);
    }
    callback();
};

//PAGING
export const updatePage = (page?: number) => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.updatePage({ page: page }));
};
export const updatePageSize = (pageSize?: number) => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.updatePageSize({ pageSize: pageSize }));
    dispatch(loadInquiries());
};
export const resetSeenPages = () => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.resetSeenPages());
};
export const resetLoaders = () => async (dispatch: AppDispatch, getState) => {
    dispatch(slice.actions.resetLoaders());
};

//SORTING
export const updateSort = (sortModel: GridSortModel) => async (dispatch: AppDispatch, getState) => {
    console.log('UPDATE SORT', sortModel);
    dispatch(slice.actions.updateGridSortModel(sortModel));
    dispatch(resetPageAndLoadFirst());
};

//FILTERING
export const updateFilter = (filterMode: InquiryFilterDto) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.updateGridFilter(filterMode));
    dispatch(resetPageAndLoadFirst());
};
export const resetFilter = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    const filterBoundaries = getState().constants.inquiryFilterBoundaries;
    if (!filterBoundaries) return;
    dispatch(
        slice.actions.updateGridFilter({
            searchString: '',
            itemTypes: [],
            states: [],
            prices: [
                {
                    fromIncl: filterBoundaries.priceBoundaries.min === filterBoundaries.priceBoundaries.max ? 0 : filterBoundaries.priceBoundaries.min,
                    toIncl: filterBoundaries.priceBoundaries.max
                }
            ],
            partCount: [
                {
                    partCountMinIncl: filterBoundaries.partCountBoundaries.min === filterBoundaries.partCountBoundaries.max ? 0 : filterBoundaries.partCountBoundaries.min,
                    partCountMaxIncl: filterBoundaries.partCountBoundaries.max
                }
            ]
        })
    );
};

//IMPORT
export const importInquiries = (inquiryId: string, callback?: (internalId: string) => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(slice.actions.updateImportLoading(true));
        const userId = getState().user.currentUser.id;
        const importResponse = await InternalCalculationRestControllerService.createCalculationFromWizard(userId, {
            wizardId: inquiryId
        });
        dispatch(slice.actions.updateImportLoading(false));
        console.log('importResponse', importResponse);

        if (callback) callback(importResponse.uniqueId);
    } catch (e) {
        console.log('FETCH :: INQUIRIES :: ERROR', e);
        snackContext.enqueueSnackbar(e.message, { variant: 'error' });
        dispatch(slice.actions.updateImportLoading(false));
    }
};
//HELPER
export const buildSortingString = (sortModel: GridSortModel): string | null => {
    if (sortModel.length === 0) {
        return null;
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return sortModel[0].field + capitalizeFirstLetter(sortModel[0].sort);
};
export const resetPageAndLoadFirst = createAsyncThunk<void, null, { state: RootState }>('inquiries/resetPageAndLoadFirst', async (_, { dispatch }) => {
    dispatch(updatePage(0));
    dispatch(resetSeenPages());
    dispatch(loadInquiries());
});
