import { ArrowCircleDownTwoTone, CalendarTodayTwoTone, KeyboardArrowRightRounded, LocalShippingTwoTone } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Badge, Box, Button, Card, Divider, IconButton, LinearProgress, MenuItem, Select, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { DataGrid, deDE, GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowParams, GridSortModel, MuiEvent } from '@mui/x-data-grid';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nav } from 'src/statics/navigations';
import { formatDate } from 'src/utils/FormatHelpers';
import { InquiryResource } from '../../../../backend/market';
import useDialog from '../../../../hooks/useDialog';
import { RootState } from '../../../../redux/store';
import { deletePriceCalc, updatePriceCalcs, updatePage, updatePageSize, updateSort } from '../../../../redux/thunks/priceCalc.thunk';
import usePrevious from '../../../../utils/usePrevious';
import { Container } from './PriceCalcTable.styles';

interface IPriceCalcTable {
    sortable?: boolean;
    requestCount?: 'all' | number;
    priceCalcs: { [key: string]: InquiryResource };
    loading: boolean;
    height?: number;
    isFullList?: boolean;
    page?: number;
    pageSize?: number;
    rowCount?: number;
}

const PriceCalcTable: React.FC<IPriceCalcTable> = ({ sortable = true, requestCount = 'all', priceCalcs, loading, height, isFullList = false, page, pageSize, rowCount }) => {
    const dialog = useDialog();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'date', sort: 'desc' }]);
    const previousSortModel = usePrevious(sortModel);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        return () => {
            dispatch(updatePage(0));
        };
    }, []);

    useEffect(() => {
        console.log('-- UPDATE SORT MODEL CALL --');
        console.log(sortModel, previousSortModel);
        if (previousSortModel === undefined) return;
        if (_.isEqual(sortModel, previousSortModel)) return;
        dispatch(updateSort(sortModel));
    }, [sortModel]);

    useEffect(() => {
        if (mediaQuery) {
            dispatch(updatePageSize(5));
        }
    }, [mediaQuery]);

    //region VALUE GETTERS
    function getPrice(params) {
        const priceCalc = params.row as InquiryResource;
        const price = priceCalc.price;
        if (price.priceOnRequest) {
            return 'ANFRAGE';
        }
        if (!price.priceAvailable) {
            return 'N/A';
        }
        return `€ ${price.minPrice} - ${price.maxPrice}`;
    }

    function getDate(params, row) {
        return formatDate(params.row[row]);
    }

    //endregion

    //region COL RENDERERS

    function renderActions(params: GridRenderCellParams<InquiryResource>) {
        return (
            <Box>
                <Tooltip title={t('deleteRequest')}>
                    <IconButton onClick={handleDeleteClicked(params)} color={'error'}>
                        <DeleteTwoToneIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        );
    }

    function renderWithIcon(icon: ReactElement, params: GridRenderCellParams<string>) {
        return (
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                {icon}
                <Typography variant={'body2'}>{params.value}</Typography>
            </Box>
        );
    }

    function renderBold(params: GridRenderCellParams<string>) {
        return (
            <Typography textAlign={'left'} variant={'body2'} fontWeight={'bold'}>
                {params.value}
            </Typography>
        );
    }

    const renderTypographyWithIcon = (icon: ReactElement, text: string) => {
        return (
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                {icon}
                <Typography variant={'body2'}>{text}</Typography>
            </Box>
        );
    };
    //endregion

    //COLUMNS
    const requestGridColumns = (): GridColDef[] => {
        let columns: GridColDef[] = [
            { field: 'id', headerName: t('priceCalcId'), flex: 0.6, width: 60, sortable: false },
            {
                field: 'date',
                headerName: t('priceCalcDate'),
                flex: 2,
                minWidth: 150,
                valueGetter: (params) => getDate(params, 'date'),
                renderCell: (params) => renderWithIcon(<CalendarTodayTwoTone />, params)
            },
            { field: 'partCount', headerName: t('positionen'), flex: 2, minWidth: 100, sortable: false },
            { field: 'price', headerName: t('preis'), flex: 2, valueGetter: getPrice, minWidth: 150, sortable: false },
            { field: 'actions', headerName: '', width: 60, renderCell: renderActions, sortable: false }
        ];
        //COLUMN MODIFIERS
        columns = columns.map((column: GridColDef) => {
            if (column.sortable == null) column.sortable = sortable;
            return column;
        });
        return columns;
    };

    //HANDLER AND CALLBACKS
    const handleGoToDetail = (id: string) => {
        navigate(nav.WIZARD.sub.PRICE_CALCS.sub.DETAIL.path.replace(':id', id), { replace: false });
    };

    const handleRowClick = (params: GridRowParams<InquiryResource>, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        handleGoToDetail(params.row.id);
    };

    function handleDeleteClicked(params: GridRenderCellParams<any>) {
        return (e) => {
            e.stopPropagation();
            dialog.openDialog({
                severity: 'error',
                okCallback(): void {
                    dispatch(
                        deletePriceCalc(params.row.id, () => {
                            const key = enqueueSnackbar(t('priceCalcDeleted'), {
                                variant: 'success',
                                action: (
                                    <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                )
                            });
                        })
                    );
                    console.log('delete price calc', params.row.id);
                    dialog.closeDialog();
                },
                title: t('deletePriceCalc'),
                okText: t('deletePriceCalcOk'),
                component: <Typography variant={'body2'}>{t('deletePriceCalcAreYouSure')}</Typography>
            });
        };
    }

    function onPageChangedCallback(page: number, details: GridCallbackDetails) {
        dispatch(updatePriceCalcs(page, pageSize));
        dispatch(updatePage(page));
    }

    function onPageSizeChanged(pageSize) {
        dispatch(updatePageSize(pageSize));
    }

    function handleSortModelChange(newModel) {
        setSortModel(newModel);
    }

    function handleLoadNext() {
        console.log('HANDLING NEXT!', page + 1);
        dispatch(updatePage(page + 1));
        dispatch(updatePriceCalcs(page + 1, 5));
    }

    //RENDER
    function renderGrid() {
        const rows = _.values(priceCalcs).slice(page * pageSize, requestCount == 'all' ? (page + 1) * pageSize : requestCount);
        return (
            <DataGrid
                //STYLING
                sx={{
                    height: height ?? '100%',
                    border: 0,
                    backgroundColor: 'background.paper',
                    '& .MuiDataGrid-columnHeaders': {
                        // backgroundColor: "background.default",
                        borderBottom: (theme: Theme) => `2px solid ${theme.palette.divider}`
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: (theme: Theme) => theme.typography.fontWeightBold,
                        color: (theme: Theme) => theme.palette.text.secondary
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none',
                        '&:focus': {
                            outline: 'none'
                        },
                        '&:focus-within': {
                            outline: 'none'
                        }
                    },
                    '& .MuiDataGrid-row': {
                        borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`
                    }
                }}
                //CONFIG
                headerHeight={50}
                density={'comfortable'}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                disableDensitySelector
                hideFooter={!isFullList}
                isRowSelectable={() => false}
                localeText={{
                    noRowsLabel: t('dgNoRowsLabel'),
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) => (
                            <>
                                <Typography component="span" display={'inline'}>
                                    {' '}
                                    {`${from} - ${to} ${t('von')} `}
                                </Typography>
                                <Typography component="span" display={'inline'} variant={'h4'}>
                                    {count}
                                </Typography>
                            </>
                        )
                    }
                }}
                autoPageSize={true}
                //STATE
                rows={loading ? [] : rows}
                columns={requestGridColumns()}
                rowCount={rowCount}
                loading={loading}
                page={page}
                //EVENTS
                onRowClick={handleRowClick}
                //SORTING
                sortModel={sortModel}
                sortingMode={'server'}
                onSortModelChange={handleSortModelChange}
                //PAGINATION
                onPageChange={onPageChangedCallback}
                onPageSizeChange={onPageSizeChanged}
                paginationMode={'server'}
            />
        );
    }

    function renderMobileCards() {
        const rows = _.values(priceCalcs).slice(0, requestCount == 'all' ? _.values(priceCalcs).length : requestCount);
        return (
            <InfiniteScroll
                next={handleLoadNext}
                endMessage={requestCount === 'all' ? <Typography textAlign={'center'}>{t('keineWeiterenAnfragen')}</Typography> : <div />}
                hasMore={(rows.length !== rowCount && requestCount === 'all') || loading}
                loader={<LinearProgress variant={'indeterminate'} />}
                dataLength={rows.length}
            >
                {rows.map((row: InquiryResource) => (
                    <Card key={row.id} sx={{ p: 2, mt: 2, mb: 2 }}>
                        <Box pb={1} display={'flex'} justifyContent={'space-between'}>
                            <Box>
                                <Typography variant={'caption'}>{t('anfragedatum')}</Typography>
                                {renderTypographyWithIcon(<CalendarTodayTwoTone />, formatDate(row.date))}
                            </Box>
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                                <Typography variant={'caption'}>{row.id}</Typography>
                            </Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'start'} gap={4} pb={1} pt={1}>
                            <Box>
                                <Typography variant={'caption'}>{t('preisspanne')}</Typography>
                                <Typography variant={'body1'}>{getPrice({ row: row })}</Typography>
                            </Box>
                            <Box>
                                <Typography variant={'caption'}>{t('positionen')}</Typography>
                                <Typography variant={'body1'}>{row.partCount}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box pt={1} display={'flex'} alignItems={'center'}>
                            <Button onClick={() => handleGoToDetail(row.id)} variant={'text'} sx={{ ml: 2 }}>
                                <Typography variant={'body1'}>{t('anfragedetailsAnzeigen')}</Typography>
                                <KeyboardArrowRightRounded />
                            </Button>
                        </Box>
                    </Card>
                ))}
            </InfiniteScroll>
        );
    }

    return (
        <Container sx={{ mt: 0 }} display={'flex'} flexDirection={'column'} flex={1} minHeight={height}>
            <Box flex={1} sx={{ cursor: 'pointer' }}>
                {mediaQuery ? renderMobileCards() : renderGrid()}
            </Box>
        </Container>
    );
};
const mapStateToProps = (state: RootState) => {
    return {
        priceCalcs: state.priceCalc.priceCalcs,
        loading: state.priceCalc.loading,
        pageSize: state.priceCalc.priceCalcsTablePaging.pageSize,
        page: state.priceCalc.priceCalcsTablePaging.page,
        rowCount: state.priceCalc.priceCalcsTablePaging.rowCount
    };
};
export default connect(mapStateToProps)(PriceCalcTable);
