/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WizardStateDto } from '../models/WizardStateDto';
import type { WizardStateResource } from '../models/WizardStateResource';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WizardStatusRestControllerService {

    /**
     * @param userId
     * @returns WizardStateResource OK
     * @throws ApiError
     */
    public static getWholeWizardState1(
        userId: number,
    ): CancelablePromise<WizardStateResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardStatus/${userId}/wholeWizardState`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static getWholeWizardState(
        userId: number,
        requestBody: WizardStateDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardStatus/${userId}/wholeWizardState`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns WizardStateResource OK
     * @throws ApiError
     */
    public static getWalkInCustomerWizardState(
        userId: number,
    ): CancelablePromise<WizardStateResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardStatus/${userId}/walkInCustomerWizardState`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateWalkInWizardState(
        userId: number,
        requestBody: WizardStateDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardStatus/${userId}/walkInCustomerWizardState`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns WizardStateResource OK
     * @throws ApiError
     */
    public static getRegularCustomerWizardState(
        userId: number,
    ): CancelablePromise<WizardStateResource> {
        return __request({
            method: 'GET',
            path: `/marketplace/wizardStatus/${userId}/regularCustomerWizardState`,
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateRegularCustomerWizardState(
        userId: number,
        requestBody: WizardStateDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/marketplace/wizardStatus/${userId}/regularCustomerWizardState`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}