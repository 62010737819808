/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DistanceDurationResource } from './DistanceDurationResource';

export type SummaryPersonDataResource = {
    type?: SummaryPersonDataResource.type;
    company?: string;
    name?: string;
    email?: string;
    phone?: string;
    street?: string;
    houseNumber?: string;
    city?: string;
    zipcode?: string;
    country?: string;
    contactedVia?: string;
    note?: string;
    distanceDuration?: DistanceDurationResource;
}

export namespace SummaryPersonDataResource {

    export enum type {
        COATER = 'COATER',
        WIZARD_CUSTOMER = 'WIZARD_CUSTOMER',
        INTERNAL_CALCULATION_CONTACT = 'INTERNAL_CALCULATION_CONTACT',
        REGULAR_CUSTOMER = 'REGULAR_CUSTOMER',
    }


}
